import { useQuery, useMutation, useQueryClient } from "react-query";
import {
  getAllReceptions,
  addReception,
  updateReception,
  deleteReception,
  getReception,
} from "../../helper/fetchers/Reception";

export const useGetAllReceptions = (params) => {
  const query = useQuery(["reception",params],()=> getAllReceptions(params));
  return query;
};
export const useGetReception = (receptionId) => {
  const query = useQuery(["reception",receptionId],()=> getReception(receptionId),{
    enabled: !!receptionId
  });
  return query;
};

export const useAddReception = (onSuccess,onError) => {
  const queryClient = useQueryClient();
  return useMutation(addReception, {
    onSuccess: ()=>{
      queryClient.invalidateQueries("reception");
      onSuccess()
    },
    onError: (error) => {
      onError()
    },
  });
};
export const useUpdateReception = (onSuccess,onError, id) => {
  const queryClient = useQueryClient();
  return useMutation((data) => updateReception(id, data), {
    onSuccess: ()=>{
      queryClient.invalidateQueries("reception");
      onSuccess()
    },
    onError: (error) => {
      onError()
    },
  });
};
export const useDeleteReception = (onSuccess,onError) => {
  const queryClient = useQueryClient();
  return useMutation((data)=>deleteReception(data), {
    onSuccess: () => {
      queryClient.invalidateQueries("reception");
      onSuccess()
    },
    onError: (error) => {
      onError()
    },
  });
};
