let KSACites = [
  {
    value: "Abha",
    label: "Abha",
    arLabel: "أبها",
  },
  {
    value: "Abqaiq",
    label: "Abqaiq",
    arLabel: "أبقيق",
  },
  {
    value: "Al Bahah",
    label: "Al Bahah",
    arLabel: "الباحة",
  },
  {
    value: "Al Faruq",
    label: "Al Faruq",
    arLabel: "الفاروق",
  },
  {
    value: "Al Hufuf",
    label: "Al Hufuf",
    arLabel: "الهفوف",
  },
  {
    value: "Al Qatif",
    label: "Al Qatif",
    arLabel: "القطيف",
  },
  {
    value: "Al Yamamah",
    label: "Al Yamamah",
    arLabel: "اليمامة",
  },
  {
    value: "At Tuwal",
    label: "At Tuwal",
    arLabel: "الطوال",
  },
  {
    value: "Buraidah",
    label: "Buraidah",
    arLabel: "بريدة",
  },
  {
    value: "Dammam",
    label: "Dammam",
    arLabel: "الدمام",
  },
  {
    value: "Dhahran",
    label: "Dhahran",
    arLabel: "الظهران",
  },
  {
    value: "Hayil",
    label: "Hayil",
    arLabel: "حائل",
  },
  {
    value: "Jazirah",
    label: "Jazirah",
    arLabel: "الجزيرة",
  },
  {
    value: "Jeddah",
    label: "Jeddah",
    arLabel: "جدة",
  },
  {
    value: "Jizan",
    label: "Jizan",
    arLabel: "جيزان",
  },
  {
    value: "Jubail",
    label: "Jubail",
    arLabel: "الجبيل",
  },
  {
    value: "Khamis Mushait",
    label: "Khamis Mushait",
    arLabel: "خميس مشيط",
  },
  {
    value: "Khobar",
    label: "Khobar",
    arLabel: "الخبر",
  },
  {
    value: "Khulays",
    label: "Khulays",
    arLabel: "خليص",
  },
  {
    value: "Linah",
    label: "Linah",
    arLabel: "لينة",
  },
  {
    value: "Madinat Yanbu` as Sina`iyah",
    label: "Madinat Yanbu` as Sina`iyah",
    arLabel: "مدينة ينبع الصناعية",
  },
  {
    value: "Mecca",
    label: "Mecca",
    arLabel: "مكة المكرمة",
  },
  {
    value: "Medina",
    label: "Medina",
    arLabel: "المدينة المنورة",
  },
  {
    value: "Mina",
    label: "Mina",
    arLabel: "منى",
  },
  {
    value: "Najran",
    label: "Najran",
    arLabel: "نجران",
  },
  {
    value: "Rabigh",
    label: "Rabigh",
    arLabel: "رابغ",
  },
  {
    value: "Rahimah",
    label: "Rahimah",
    arLabel: "رحيمة",
  },
  {
    value: "Rahman",
    label: "Rahman",
    arLabel: "الرحمن",
  },
  {
    value: "Ramdah",
    label: "Ramdah",
    arLabel: "رمضاء",
  },
  {
    value: "Ras Tanura",
    label: "Ras Tanura",
    arLabel: "رأس تنورة",
  },
  {
    value: "Riyadh",
    label: "Riyadh",
    arLabel: "الرياض",
  },
  {
    value: "Sabya",
    label: "Sabya",
    arLabel: "صبيا",
  },
  {
    value: "Safwa",
    label: "Safwa",
    arLabel: "صفوى",
  },
  {
    value: "Sakaka",
    label: "Sakaka",
    arLabel: "سكاكا",
  },
  {
    value: "Sambah",
    label: "Sambah",
    arLabel: "سمباح",
  },
  {
    value: "Sayhat",
    label: "Sayhat",
    arLabel: "سيهات",
  },
  {
    value: "Tabuk",
    label: "Tabuk",
    arLabel: "تبوك",
  },
  {
    value: "Yanbu` al Bahr",
    label: "Yanbu` al Bahr",
    arLabel: "ينبع البحر",
  },
];

export default KSACites;
