import React from "react";

const DeleteButton = ({
  onClick,
  type = "Button",
  text = "حذف",
  customPosition,
}) => {
  return (
    <div
      className={`d-flex justify-content-center ${
        customPosition ? "self-end" : ""
      }`}
    >
      <button
        onClick={onClick}
        type={type}
        className="bg-[#BA0A0A]  text-base border-solid text-white !w-[139px] !h-[28px] !rounded-md  hover:border-2 hover:border-[#BA0A0A] "
      >
        {text}
      </button>
    </div>
  );
};
export default DeleteButton;

// .sumbmitAddUpdateUser:hover {
//     background-color: unset !important;
//     border: 1px solid #EFAA20 !important;
//     color: white !important;
// }
