import React from "react";
import { Accordion } from "react-bootstrap";
import { MdKeyboardArrowDown } from "react-icons/md";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";

const HrDropdown = ({
  setOpen,
  setActive,
  active,
  setAllEmplyees,
  allEmployees,
}) => {
  const myCountry = Cookies.get("country");
  return (
    <div className="absolute top-[3rem] right-[12rem] z-[1000000] bg-mainNavy rounded-xl">
      <div className="flex items-start flex-col  bg-mainItems w-[300px] h-[300px]  p-2 overflow-auto shadow-2xl ">
        <div
          className="w-screen h-screen fixed inset-0  z-[-1]"
          onClick={() => {
            setOpen(false);
          }}
        />
        <Accordion className="!w-full" defaultActiveKey={null}>
          <div className="py-3">
            <h2 className="text-mainText text-center">الموارد البشرية</h2>
          </div>

          <Accordion.Item eventKey="0">
            <Link to={"/System/Hr/EmployeesManagment"}>
              <Accordion.Header
                onClick={() => {
                  setActive(0);
                }}
                className={`   border-2  rounded-md hover:!border-[#EFAA20] ${
                  active === 0
                    ? " !border-[#EFAA20]  "
                    : "border-mainBorder rounded-md rounded-b-lg"
                }      pointer`}
              >
                <p> إدارة الموظفين</p>

                <MdKeyboardArrowDown size={20} />
              </Accordion.Header>
            </Link>
            <Accordion.Body>
              <div className="tabs d-flex justify-content-center align-items-center flex-column">
                <Link to={`/System/Hr/Employees/${myCountry === "مصر" ? "egypt":"Saudia"}`}>
                  <div className="w-full">
                    <div
                      onClick={() => {
                        setAllEmplyees(true);
                        setActive(0);
                      }}
                      className={` tab  text-end w-full  border  rounded-md hover:!border-[#EFAA20] ${
                        active === 0 && allEmployees
                          ? " !border-[#EFAA20]  "
                          : "!border-transparent"
                      }   p-2 bg-mainItems mt-2   pointer`}
                    >
                      <span>كل الموظفين</span>
                    </div>
                  </div>
                </Link>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>

        <Accordion defaultActiveKey={true}></Accordion>

        <div className="tabs d-flex justify-content-center align-items-center flex-column w-full">
          <div className="w-full">
            <Link to={"/System/Hr/HolidayMangment"}>
              <div
                onClick={() => {
                  setAllEmplyees(false);
                  setActive(1);
                }}
                className={` tab  text-end w-full  border  rounded-md hover:!border-[#EFAA20] ${
                  active === 1 ? " !border-[#EFAA20]  " : "!border-transparent"
                }   p-2 bg-mainItems mt-2   pointer`}
              >
                <span>إدارة الاجازات</span>
              </div>
            </Link>
          </div>
          <div className="w-full">
            <Link to={"/System/Hr/EmployeesServices"}>
              <div
                onClick={() => {
                  setAllEmplyees(false);
                  setActive(2);
                }}
                className={` tab  text-end w-full  border  rounded-md hover:!border-[#EFAA20] ${
                  active === 2 ? " !border-[#EFAA20]  " : "!border-transparent"
                }    p-2 bg-mainItems mt-2  pointer`}
              >
                <span>خدمات الموظفين</span>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HrDropdown;
