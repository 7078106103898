import React, { Fragment, useContext } from "react";
import { NavDropdown } from "react-bootstrap";
import { DashboardFilterMenuIcon } from "../../../../utiltis/Icons";
import style from "../MainSystem.module.css";
import { DashboardCategoriesTableContext } from "./CategoriesTable";
import { CheckRole } from "../../../../helper/utils";
import { roles } from "../../../../helper/fetchers/Tasks";

const filterKeys = {
  CLIENTS: 0,
  USERS: 1,
  PROJECTS: 2,
  PLANS: 3,
  TASKS: 4,
};

export default function CategoryFilterMenu() {
  const { setFilterKey } = useContext(DashboardCategoriesTableContext);

  return (
    <NavDropdown
      style={{ width: "115px" }}
      title={
        <div
          className={` ${style.chooseDeprtmentICons}   d-flex justify-content-between align-items-center pointer `}
        >
          <p className="text-sm text-white">
            {CheckRole(roles["manager"]) ? "اختر المخطط" : "اختر القسم"}
          </p>
          <DashboardFilterMenuIcon />
        </div>
      }
      className="fs-5 "
      
    >
      {CheckRole(roles["manager"]) ? (
        <Fragment>
          <NavDropdown.Item
            onClick={() => setFilterKey(filterKeys["PLANS"])}
            className="text-end  d-flex justify-content-between align-items-center hover:!text-black"
          >
            <span>كل المخططات</span>
          </NavDropdown.Item>
          <NavDropdown.Item
            onClick={() => setFilterKey(filterKeys["TASKS"])}
            className="text-end  d-flex justify-content-between align-items-center hover:!text-black"
          >
            <span>كل المهام</span>
          </NavDropdown.Item>
        </Fragment>
      ) : (
        <Fragment>
          <NavDropdown.Item
            onClick={() => setFilterKey(filterKeys["CLIENTS"])}
            className="text-end  d-flex justify-content-between  align-items-center hover:!text-black"
          >
            <span> عملاء</span>
          </NavDropdown.Item>
          <NavDropdown.Item
            onClick={() => setFilterKey(filterKeys["USERS"])}
            className="text-end  d-flex justify-content-between align-items-center hover:!text-black"
          >
            <span> مستخدمين</span>
          </NavDropdown.Item>
          <NavDropdown.Item
            onClick={() => setFilterKey(filterKeys["PROJECTS"])}
            className="text-end  d-flex justify-content-between align-items-center hover:!text-black"
          >
            <span> مشاريع</span>
          </NavDropdown.Item>
          <NavDropdown.Item
            onClick={() => setFilterKey(filterKeys["PLANS"])}
            className="text-end  d-flex justify-content-between align-items-center hover:!text-black"
          >
            <span> مخططات</span>
          </NavDropdown.Item>
        </Fragment>
      )}
    </NavDropdown>
  );
}
