import React from "react";
import { Button, Form } from "react-bootstrap";
import style from "./AddItems.module.css";

import Input from "../../../FormHandler/Input";
import { useState } from "react";
import DatePicker from "react-datepicker";
import AddAttachment from "../../AddAttachment";
import moment from "moment";
import { addExpensesItem } from "../../../../helper/fetchers/Expenses-Items";
import { toast } from "react-toastify";
import Progress from "../../../Progress";
import { InputLabel } from "../../../../Pages/System/PlanModel/components/InputLabel";
import { useForm } from "react-hook-form";
import { FormModal } from "../../../../Pages/System/PlanModel/components/FormModal";
import {
  CustomDatePicker,
  CustomInput,
  CustomeInputFile,
} from "../../../FormUi";
import { ErrorMessage } from "@hookform/error-message";
import {
  useAddExpensesItem,
  useGetAllExpensesItems,
} from "../../../../hooks/fetchers/Expenses-items";
import useSuccessfullModal from "../../../Modals/hooks/useSuccessfullModal";
import SuccessfullModal from "../../../Modals/SuccessfullModal";
import { useNavigate } from "react-router-dom";

const validateFileLength = (value) => {
  if (value.length > 5) {
    return "أقصى عدد 5 ملفات";
  }
  return true;
};

const AddItems = () => {
  const [description, setDescription] = useState("");
  const [createdDate, setCreatedDate] = useState("");
  const [name, setName] = useState("");
  // const [code, setCode] = useState("");
  const [attachment, setAttachment] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);

  const files = attachment ? [...attachment] : [];

  // const sendData = async()=>{
  //   try {
  //     fe
  //   }
  // }

  // const handleAddItems = async () => {
  //   setLoading(true);
  //   const formData = new FormData();
  //   formData.append("name", name);
  //   formData.append("description", description);
  //   formData.append("dateCreated", moment(createdDate).format("YYYY-MM-DD"));
  //   if (attachment.length > 0) {
  //     files.forEach((file) => {
  //       formData.append(`image`, file);
  //     });
  //   }

  //   const newErrors = [];
  //   for (var pair of formData.entries()) {
  //     if (pair[0] === "name" || pair[0] === "description") {
  //       const value = pair[1];
  //       if (value.length < 5 || value.length > 500) {
  //         newErrors.push(
  //           `${
  //             pair[0] === "name" ? "اسم الصنف" : "الوصف"
  //           } يجب ان لا يقل علي 5 احرف ولا يزيد عن 500 حرف`
  //         );
  //       }
  //     }

  //     if (
  //       pair[0] === "dateCreated" &&
  //       !moment(pair[1], "YYYY-MM-DD", true).isValid()
  //     ) {
  //       newErrors.push("تاريخ الانشاء غير صحيح");
  //     }

  //     setErrors(newErrors);
  //     setLoading(false);
  //   }
  //   if (newErrors.length === 0) {
  //     try {
  //       const { data } = await addExpensesItem(formData);
  //       if (data?.success) {
  //         toast.success("تم اضافة الصنف بنجاح");
  //         setCreatedDate("");
  //         setDescription("");
  //         setName("");
  //         // setCode("");
  //         setAttachment("");
  //         setLoading(false);
  //         getData();
  //       } else {
  //         setLoading(false);
  //       }
  //     } catch (error) {
  //       setLoading(false);
  //       toast.error(error?.response?.data?.message);
  //     }
  //   }
  // };

  return (
    <div className={`${style.AddItems} p-3`}>
      <p className="text-xl  my-3 golden">إضافة اصناف جديدة</p>

      <AddItemForm />

      {/* <fieldset className={`${style.description} mt-3`}>
        <legend className="text-center text-mainText">الوصف</legend>
        <div className="row p-3">
          <div className="col-md-6 mb-4">
            <InputLabel size={18} label={"اسم الصنف"} className={"mb-1"} />
            <Input
              placeholder="اكتب اسم الصنف"
              // label="1- اسم الصنف"
              value={name}
              errorMassage={errors.find((error) => error.includes("اسم الصنف"))}
              defaultValue={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="col-md-12 mb-4">
            <Form.Group>
              <InputLabel size={18} label={"الوصف"} className={"mb-1"} />
              <textarea
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
                value={description}
                className="form-control"
                placeholder="اكتب الوصف المرفق ..."
                rows={5}
                cols={30}
              />
              {errors.find((error) => error.includes("الوصف")) && (
                <span className="text-xs text-red-700">
                  {errors.find((error) => error.includes("الوصف"))}
                </span>
              )}
            </Form.Group>
          </div>
          <div className="col-md-6   mb-4">
            <Form.Group className="d-flex flex-column">
              <InputLabel
                size={18}
                label={"تاريخ الانشاء"}
                className={"mb-1"}
              />

              <DatePicker
                selected={createdDate}
                placeholderText=" ادخل تاريخ انشاء الصنف "
                onChange={(date) => setCreatedDate(date)}
                dateFormat="dd-MM-yyyy"
                className="w-100 form-control"
              />
              {errors.find((error) => error.includes("تاريخ الانشاء")) && (
                <span className="text-xs text-red-700">
                  {errors.find((error) => error.includes("تاريخ الانشاء"))}
                </span>
              )}
            </Form.Group>
          </div>
        </div>
      </fieldset> */}
      {/* <fieldset
        className={`${style.Attachment} mt-4 p-2 flex items-center justify-start gap-4 overflow-x-auto custom-scrollbar`}
      >
        <legend className="text-center text-mainText">الملف المرفق </legend>
        <AddAttachment
          attachment={attachment}
          setAttachment={setAttachment}
          multi={true}
        />
        {attachment &&
          Array.from(attachment).map((image, index) => (
            <img
              key={index}
              src={URL.createObjectURL(image)}
              className="pointer w-ful h-[90px] object-cover rounded-lg"
              alt="view icon"
            />
          ))}
      </fieldset> */}
      {/* <div className="d-flex my-5 w-90  justify-content-end">
        <Button
          disabled={loading}
          onClick={() => {
            handleAddItems();
          }}
          className="sumbmitAddUpdateUser"
        >
          {loading ? <Progress isSmall /> : "حفظ"}
        </Button>
      </div> */}
    </div>
  );
};

const AddItemForm = () => {
  const navigate = useNavigate()
  const {
    handleShowSuccess,
    handleShowError,
    handleCloseSuccess,
    handleCloseError,
    showSuccess,
    showError,
  } = useSuccessfullModal();
  const { mutate, isLoading } = useAddExpensesItem(
    handleShowSuccess,
    handleShowError
  );
  // const [isLoading , setIsLoading] = useState(false)
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = async (data) => {
    const formData = new FormData();
    data.dateCreated = moment(data?.dateCreated).format("YYYY-MM-DD");
    console.log("submitted Data:", data);
    Object.keys(data).forEach((item) => {
      if(data[item]){
        if (item !== "image") {
          formData.append(item, data[item]);
        }

      }
      // console.log([item, data[item]]);
    });
    for (const iterator of data.image) {
      formData.append("image", iterator);
    }
    mutate(formData);
    // try {
    //   setIsLoading(true)
    //   const { data } = await addExpensesItem(formData);
    //   if (data?.success) {
    //     toast.success("تم اضافة الصنف بنجاح");

    //     getData();
    //   }

    // } catch (error) {
    //   toast.error(error?.response?.data?.message);
    // }
    // setIsLoading(false)
    // console.log(formData.getAll("image"));
  };
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormModal title={"الوصف"} className={"mb-4 flex flex-col gap-4"}>
          <div className="flex flex-col w-full md:w-1/2 mb-2">
            <InputLabel size={18} label={"اسم الصنف"} className={"mb-1"} />
            <CustomInput
              control={control}
              name={"name"}
              placeholder={"ادخل اسم الصنف"}
              rules={{
                required: "يجب ادخال اسم الصنف",
                pattern: {
                  value: /^([A-Za-z ]+|[\u0600-\u06FF ]+)$/,
                  message: "نمط غير صحيح",
                },
                minLength: {
                  value: 5,
                  message: "يجب ان لا يقل علي 5 احرف",
                },
                maxLength: {
                  value: 500,
                  message: "يجب ان لا تزيد علي 500 حرف",
                },
              }}
            />
            <ErrorMessage
              name="name"
              errors={errors}
              render={({ message }) => (
                <span className="text-red-500">{message}</span>
              )}
            />
          </div>
          <div className="flex flex-col w-full">
            <InputLabel size={18} label={"الوصف"} className={"mb-1"} />
            <CustomInput
              control={control}
              name={"description"}
              placeholder="اكتب الوصف المرفق ..."
              multiline
              rows={5}
              rules={{
                pattern: {
                  value: /^([A-Za-z ]+|[\u0600-\u06FF ]+)$/,
                  message: "نمط غير صحيح",
                },
                minLength: {
                  value: 5,
                  message: "يجب ان لا يقل علي 5 احرف",
                },
                maxLength: {
                  value: 5000,
                  message: "يجب ان لا تزيد علي 5000 حرف",
                },
              }}
            />
            <ErrorMessage
              name="description"
              errors={errors}
              render={({ message }) => (
                <span className="text-red-500">{message}</span>
              )}
            />
          </div>
          <div className="flex flex-col w-full md:w-1/2">
            <InputLabel size={18} label={"تاريخ الانشاء"} className={"mb-1"} />
            <CustomDatePicker
              control={control}
              name={"dateCreated"}
              rules={{
                required: "يجب اختيار التاريخ",
              }}
            />
            <ErrorMessage
              name="dateCreated"
              errors={errors}
              render={({ message }) => (
                <span className="text-red-500">{message}</span>
              )}
            />
          </div>
        </FormModal>
        <FormModal title={"الملف المرفق"}>
          <CustomeInputFile
            register={{
              ...register("image", {
                validate: validateFileLength,
              }),
            }}
            name="image"
            multiple={true}
            accept={"image/*"}
          />
          <ErrorMessage
            name="image"
            errors={errors}
            render={({ message }) => (
              <span className="text-red-500">{message}</span>
            )}
          />
        </FormModal>

        <div className="d-flex my-5 w-90  justify-content-end">
          <Button
            disabled={isLoading}
            type="submit"
            className="sumbmitAddUpdateUser"
          >
            {isLoading ? <Progress isSmall /> : "حفظ"}
          </Button>
        </div>
      </form>
      <SuccessfullModal
        handleClose={() => {
          handleCloseSuccess();
          navigate("/System/Accounating/index")
        }}
        message={"تمت الاضافة بنجاح"}
        show={showSuccess}
      />
      <SuccessfullModal
        handleClose={handleCloseError}
        message={"حدث خطأ ما"}
        show={showError}
        status="error"
      />
    </>
  );
};

export default AddItems;
