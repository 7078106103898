import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  acceptHumanResuroeService,
  createHumanResuroeService,
  deleteHumanResuroeService,
  getAllHumanResoursces,
  getHumanById,
  getHumanResourscesResoursces,
  rejectHumanResuroeService,
  updateHumanResuroeService,
} from "../../helper/fetchers/HumanResoursce";

export const useGetAllHumanResoursces = (params) => {
  return useQuery(["human-resoursce", params], () =>
    getAllHumanResoursces(params)
  );
};
export const useGetHumanResourscesStatistics = () => {
  return useQuery("human-resoursce-statistics", getHumanResourscesResoursces);
};

export const useGetHumanResoursce = (humanId) => {
  return useQuery(["human-resoursce", humanId], () => getHumanById(humanId), {
    enabled: !!humanId,
  });
};

export const useAddHumanResoursce = (onSuccess, onError) => {
  const queryClient = useQueryClient();
  return useMutation(createHumanResuroeService, {
    onSuccess: () => {
      queryClient.invalidateQueries("human-resoursce");
      onSuccess();
    },
    onError: (error) => {
      onError();
    },
  });
};

export const useUpdateHumanResoursce = (onSuccess, onError, humanId) => {
  const queryClient = useQueryClient();
  return useMutation((data) => updateHumanResuroeService(humanId, data), {
    onSuccess: (data) => {
      // console.log("success data is:",data);
      queryClient.invalidateQueries("human-resoursce");
      onSuccess();
    },
    onError: (error) => {
      console.log("Errrrrrrror Is:", error);
      onError();
    },
  });
};

export const useDeleteHumanResoursce = (onSuccess, onError) => {
  const queryClient = useQueryClient();
  return useMutation(deleteHumanResuroeService, {
    onSuccess: () => {
      queryClient.invalidateQueries("human-resoursce");
      onSuccess();
    },
    onError: (error) => {
      onError();
    },
  });
};

export const useConfirmHumanResoursce = (onSuccess, onError) => {
  const queryClient = useQueryClient();
  return useMutation(acceptHumanResuroeService, {
    onSuccess: () => {
      queryClient.invalidateQueries("human-resoursce");
      onSuccess();
    },
    onError: (error) => {
      onError();
    },
  });
};

export const useRejectHumanResoursce = (onSuccess, onError, humanId) => {
  const queryClient = useQueryClient();
  return useMutation((data) => rejectHumanResuroeService(humanId, data), {
    onSuccess: () => {
      queryClient.invalidateQueries("human-resoursce");
      onSuccess();
    },
    onError: (error) => {
      onError();
    },
  });
};
