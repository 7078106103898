import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  useDeleteRequest,
  useGetAllRequests,
} from "../../../../hooks/fetchers/Requests";
import useSuccessfullModal from "../../../../Components/Modals/hooks/useSuccessfullModal";
import Image from "../../../../Components/Image";
import { getAllRequestsStats } from "../../../../helper/fetchers/Requests";

const DesignRequestContext = createContext(null);

const DesignRequestProvider = ({ children }) => {
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState(null);
  const [checked, setChecked] = useState([]);
  const [DesignProjectType, SetDesignProjectType] = useState("");
  const [ConfirmUpdate, setConfirmUpdate] = useState(false);
  const [showProject, setShowProject] = useState(false);
  const [editRequest, setEditRequest] = useState(false);

  const [id, setId] = useState(null);
  const [stats, setStats] = useState();
  const getRequests = useCallback(async () => {
    const response = await getAllRequestsStats();
    if (response?.status === 200) {
      setStats(response?.data);
    }
  }, []);
  useEffect(() => {
    getRequests();
  }, [getRequests]);

  const requestApi = useGetAllRequests({
    page,
    projectType: 1,
    search,
  });
  const handleChange = (event, value) => {
    setPage(value);
  };
  const handleSearch = (val) => {
    setSearch(val);
    console.log(`Search:`, val);
  };
  const handleChecked = (val) => {
    if (checked.includes(val)) {
      setChecked(checked.filter((item) => item !== val));
    } else {
      setChecked([...checked, val]);
    }
  };
  const successfullModal = useSuccessfullModal();
  const deleteApi = useDeleteRequest(
    () => {
      successfullModal.handleShowSuccess();
      setChecked([]);
    },
    successfullModal.handleShowError
  );

  const handleDeleteAll = () => {};
  const handleDeleteSelected = () => {
    deleteApi.mutate({requests: checked});
  };

  const DesignProjects = Array.from({ length: 10 }).map((_, index) => {
    return {
      id: index + 1,
      ProjectName: "BSA",
      ProjectNumber: "53543",
      createdAt: "12-10-2023",
      ProjectType: "تصميم",
      status: "قيد الا نتظار",
      enStatus: "inProgress",
      display: (
        <Image
          src={process.env.PUBLIC_URL + "/icons/view.svg"}
          onClick={() => {
            setShowProject(true);
            SetDesignProjectType(DesignProjects[index]?.enStatus);
          }}
          className="display_project  rounded"
          alt=" display project"
        />
      ),
      edit: (
        <Image
          src={process.env.PUBLIC_URL + "/icons/edit.svg"}
          onClick={() => {
            setEditRequest(true);
          }}
          className=" edit_project  rounded"
          alt=" edit project"
        />
      ),
    };
  });

  return (
    <DesignRequestContext.Provider
      value={{
        requestApi,
        deleteApi,
        successfullModal,
        page,
        search,
        handleChange,
        handleSearch,
        checked,
        handleChecked,
        handleDeleteAll,
        handleDeleteSelected,
        showProject,
        setShowProject,
        editRequest,
        setEditRequest,
        DesignProjectType,
        DesignProjects,
        SetDesignProjectType,
        id,
        setId,
        stats,
        ConfirmUpdate,
        setConfirmUpdate
      }}
    >
      {children}
    </DesignRequestContext.Provider>
  );
};

export const useDesignRequestContext = () => {
  const ctx = useContext(DesignRequestContext);
  if (!ctx) {
    throw Error("you must use inside context!");
  }
  return ctx;
};
export default DesignRequestProvider;
