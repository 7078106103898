import React from "react";
import { SearchComponent } from "../../../../../../Components/SearchComponent/SearchComponent";
import { NavDropdown } from "react-bootstrap";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FaRegShareSquare } from "react-icons/fa";
import { MdDeleteOutline } from "react-icons/md";
import { FilterIcon } from "../../../../../../utiltis/Icons";
import { GrTransaction } from "react-icons/gr";
import useModal from "../../../../../../hooks/useModal";
import { useGetAllUsersReports } from "../../../../../../hooks/fetchers/Users";
import { Link } from "react-router-dom";
import { Avatar, Stack } from "@mui/material";
import {
  ConnectedBadge,
  NotConnectedBadge,
} from "../../../../../../Components/Users/StatusBadge";
import { defaultImage } from "../../../../../../Config/Config";
import List from "../../../../../../Components/List";
import HoverLink from "../../../../../../Components/List/HoverLink";
import UserListItemCard from "./UserListItemCard";

const ManagersSidebar = () => {
  const { open, handleOpenModal, handleCloseModal } = useModal();
  const { data } = useGetAllUsersReports({ role: "مدير المكتب" });
  const handleASearch = () => {};
  return (
    <div className="h-full rounded-[19px] p-2 space-y-4">
      <SearchComponent
        handleChange={handleASearch}
        background="lighter"
        border="border !border-[#E4A11B]"
      />
      <div className="flex justify-between items-center border-b border-gray-100 py-2">
        <div className="w-full">{"مدير المكتب"}</div>
        <div className="">
          <NavDropdown
            title={<FilterIcon />}
            className="fs-5"
            id="collapsible-nav-dropdown"
          >
            <NavDropdown.Item
              onClick={handleOpenModal}
              className="flex items-center gap-1 !bg-white !text-black overflow-hidden rounded-lg"
            >
              <GrTransaction color="black" />
              <span className="!text-black">من...الي</span>
            </NavDropdown.Item>
          </NavDropdown>
        </div>
      </div>
      <div className="max-h-[700px] overflow-y-auto scrollbar-thin scrollbar-track-transparent scrollbar-thumb-[#EFAA20]">
        <List
          data={data?.users}
          renderItem={(props) => (
            <HoverLink to={`../users/${props?._id}`} key={props._id}>
              <UserListItemCard {...props} />
            </HoverLink>
          )}
        />
      </div>
    </div>
  );
};

export default ManagersSidebar;
