import { Avatar, Checkbox, styled } from "@mui/material";

const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: 3,
  width: 25,
  height: 25,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  boxShadow:
    theme.palette.mode === "dark"
      ? "0 0 0 1px rgb(16 22 26 / 40%)"
      : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  backgroundColor: "var(--bg-item)",

  ".Mui-focusVisible &": {
    outline: "2px auto rgba(19,124,189,.6)",
    outlineOffset: 2,
  },
  "input:hover ~ &": {
    backgroundColor: "var(--bg-item)",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background:
      theme.palette.mode === "dark"
        ? "rgba(57,75,89,.5)"
        : "rgba(206,217,224,.5)",
  },
}));
const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "#efaa20",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&::before": {
    display: "block",
    width: 22,
    height: 22,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "#efaa20",
  },
});

function BpCheckbox(props) {
  return (
    <Checkbox
      sx={{
        "&:hover": { bgcolor: "transparent" },
      }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      inputProps={{
        "aria-label": "Checkbox demo",
        sx: {
          bgcolor: "red",
        },
      }}
      size="medium"
      {...props}
    />
  );
}

export const CustomOption = ({
  data,
  innerRef,
  innerProps,
  getValue,
  options,
  ...props
}) => {
  return (
    <div
      ref={innerRef}
      {...innerProps}
      className="flex items-center gap-2 text-mainText hover:bg-mainNavyDarker cursor-pointer p-2"
    >
      <div className="">
        <Avatar alt={data?.label} src={data?.image} />
      </div>
      <div className="flex-1 flex justify-between items-center">
        <div className="">
          <p>{data?.label}</p>
          <p className="text-[#EFAA20]">{data?.role}</p>
        </div>
        <div
          className="p-2"
          onClick={(e) => {
            e.stopPropagation();
            props.setValue([...getValue(), data]);
          }}
        >
          <BpCheckbox />
        </div>
      </div>
    </div>
  );
};
