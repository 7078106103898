import { useContext, useState } from "react";
import { TechnicalSupportContext } from "./TechnicalSupportProvider";
import { CheckRole, GenerateColumnsNames } from "../../../../helper/utils";
import useSuccessfullModal from "../../../../Components/Modals/hooks/useSuccessfullModal";
import DeleteModal from "../../Settings/RemoveModal";
import SuccessfullModal from "../../../../Components/Modals/SuccessfullModal";
import { SearchComponent } from "../../../../Components/SearchComponent/SearchComponent";
import CustomTable from "../../../../Components/Table";
import { TableRow } from "../../../../Components/Table/TableRow";
import { TableCell } from "../../../../Components/Table/TableCell";
import Image from "../../../../Components/Image";
import TableDropdown from "./TableDropdown";
import TableDeleteButton from "../../../../Components/Buttons/TableDeleteButton";
import CustomCheckboxTable from "../../../../Components/CustomCheckboxTable";
import { useDeletePurchaseSelected } from "../../../../hooks/fetchers/TechnicalSupportPurcheseOrder";
import LoadingPage from "../../../../Components/feedback/LoadingPage";
import { roles } from "../../../../helper/fetchers/Tasks";
const columns = GenerateColumnsNames(
  "",
  "م",
  "نوع الطلب",
  "نوع المنتج",
  // "رقم الطلب",
  // "الطلب",
  "الحالة",
  "عرض"
);
const TechnicalSupportOrderTable = ({
  data,
  isError,
  isLoading,
  status,
  search,
  setSearch,
}) => {
  const [page, setPage] = useState("");
  const handleSearch = (val) => {
    setSearch(val);
  };
  const { setShowOrdersOpen, setOrder, ordersTitle } = useContext(
    TechnicalSupportContext
  );

  // console.log(data, "data");

  const [showDelete, setShowDelete] = useState(false);
  const {
    showSuccess,
    showError,
    handleShowSuccess,
    handleShowError,
    handleCloseSuccess,
    handleCloseError,
  } = useSuccessfullModal();

  const handleChange = (event, value) => setPage(value);
  const [checked, setChecked] = useState([]);
  const handleChecked = (val) => {
    if (checked.includes(val)) {
      setChecked(checked.filter((item) => item !== val));
    } else {
      setChecked([...checked, val]);
    }
  };
  // You have array of ids to delete
  const { mutate: deleteMutation, isLoading: isDeleteLoading } =
    useDeletePurchaseSelected(status, handleShowSuccess, handleShowError);
  const handleDeleteAll = () => {};
  const handleDeleteSelected = () => {
    deleteMutation({
      offers: checked,
    });
  };
  return isDeleteLoading ? (
    <LoadingPage />
  ) : (
    <div className="w-full pt-4 flex flex-col gap-6 ">
      <DeleteModal
        title={"التاكيد"}
        show={showDelete}
        handleClose={() => setShowDelete(false)}
        // onSave={() => {
        //   deleteMutation(reminder?._id);
        // }}
        stillOpen={true}
      />
      <SuccessfullModal
        handleClose={() => {
          setShowDelete(false);
          handleCloseSuccess();
        }}
        message={"تمت الحذف بنجاح"}
        show={showSuccess}
      />
      <SuccessfullModal
        handleClose={handleCloseError}
        message={"حدث خطأ ما"}
        show={showError}
        status="error"
      />
      {/* Header */}
      <div className="w-full grid grid-cols-4 ">
        <h1 className="text-[#E4A11B] text-2xl font-semibold col-span-2">
          {ordersTitle}
        </h1>
        <div className="col-span-2 w-full">
          <SearchComponent
            handleChange={handleSearch}
            value={search}
            className={""}
            // border="border !border-[#E4A11B] "
          />
        </div>
      </div>
      {CheckRole(roles["admin"]) ? (
        <TableDeleteButton
          checked={checked}
          dataLength={data?.priceOffers.length}
          onClickDeleteAll={handleDeleteAll}
          onClickDeleteSelected={handleDeleteSelected}
        />
      ) : null}
      {/* Table */}
      <div className="border !border-[#efaa20] w-full py-8 px-4 rounded-md bg-mainItems h-[420px] overflow-auto">
        <CustomTable
          columns={CheckRole(roles["admin"]) ? columns : columns.slice(1)}
          data={data?.priceOffers}
          paginationProps={{
            count: data?.pages,
            page: page,
            onChange: handleChange,
          }}
          responsive={false}
        >
          {data?.priceOffers && data?.priceOffers.length > 0
            ? data?.priceOffers.map((item, index) => (
                <TableRow
                  className={`my-2 border !border-[#efaa207f] ${
                    index % 2 === 0 ? "bg-mainNavy" : ""
                  }`}
                  key={item?._id}
                >
                  {CheckRole(roles["admin"]) ? (
                    <TableCell>
                      <CustomCheckboxTable
                        isChecked={checked?.some((x) => x === item?._id)}
                        handleClick={() => handleChecked(item?._id)}
                      />
                    </TableCell>
                  ) : null}
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>
                    <div className="flex items-center gap-3 ">
                      <p className="text-xs">{item?.offerType[0]?.name}</p>
                      <TableDropdown data={item?.offerType?.slice(1)} />
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className="flex items-center gap-3 ">
                      <p className="text-xs">{item?.product[0]?.name}</p>
                      <TableDropdown data={item?.product?.slice(1)} />
                    </div>
                  </TableCell>

                  <TableCell>{item.statusText}</TableCell>
                  <TableCell>
                    <Image
                      src={process.env.PUBLIC_URL + "/icons/view.svg"}
                      onClick={() => {
                        setShowOrdersOpen(true);
                        setOrder(item);
                      }}
                      className="display_project  rounded"
                      alt=" display project"
                    />
                  </TableCell>
                </TableRow>
              ))
            : null}
        </CustomTable>
      </div>
    </div>
  );
};

export default TechnicalSupportOrderTable;
