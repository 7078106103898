import React, { Fragment, useEffect, useState } from "react";
import { FormControl, IconButton, MenuItem } from "@mui/material";
import { FormModal } from "../components/FormModal";
import { IoMdArrowDropright } from "react-icons/io";

import { ModalTitle } from "../components/ModalTitle";
import { InputLabel } from "../components/InputLabel";
import { useNavigate, useParams } from "react-router-dom";
import SystemControler from "../../../../Components/System/SystemControler/SystemControler";

import { useForm, Controller } from "react-hook-form";
import {
  CustomDatePicker,
  CustomeInputFile,
  CustomSelect,
  CustomInput,
} from "../../../../Components/FormUi";
import { useGetProjects } from "../../Tasks/hooks/useGetProjects";
import { useGetSupervisors } from "../../Tasks/hooks/useGetSupervisors";

import { format } from "date-fns";
import { useGetPlan, useUpdatePlan } from "../../../../hooks/fetchers/Plans";
import { FaCheck } from "react-icons/fa6";
import myAxiosInstance from "../../../../helper/https";
import useSuccessfullModal from "../../../../Components/Modals/hooks/useSuccessfullModal";
import SuccessfullModal from "../../../../Components/Modals/SuccessfullModal";
import Select from "../../../../Components/FormHandler/Select";
import moment from "moment";
import axios from "axios";
import { Button, Modal } from "react-bootstrap";
import { FaFileDownload } from "react-icons/fa";
import { PdfIcon } from "../../../../utiltis/Icons";
import { clientTypeEnum } from "../../../../helper/utils";
import LoadingPage from "../../../../Components/feedback/LoadingPage";
import { ListErrorComponent } from "../../../../Components/Errors/ListErrorComponent";
import { CustErrorMessage } from "../../../../Components/Errors/CustErrorMessage";
import CustomTextArea from "../../../../Components/FormUi/CustomTextArea";
const manipulateDataFormat = (data) => {
  data.isExist = Boolean(parseInt(data.isExist));
  data.startDate = format(data.startDate, "MM/dd/yyyy");
  data.endDate = format(data.endDate, "MM/dd/yyyy");
  if (data.projectType) {
    data.projectType = data.projectType.value;
  }
  if (data.projectId) {
    data.projectId = data.projectId.value;
  }
  data.owner = data.owner.map((person) => person.value);
  return data;
};
const projectTypes = [
  {
    value: "1",
    label: "تصميم",
  },
  {
    value: "2",
    label: "اشراف علي التنفيذ",
  },
];

const validateFiles = (value) => {
  if (value.length > 8) {
    return "يمكنك تحميل ما يصل إلى 8 صور فقط";
  }
  return true;
};

export default function EditProject() {
  const [errorMessage, setErrorMessage] = useState("حدث خطأ ما");
  const navigate = useNavigate();
  const { projectId } = useParams();
  const { data: projects } = useGetProjects();
  const { data: supervisors } = useGetSupervisors();
  const { data: plan } = useGetPlan(projectId);
  const {
    showSuccess,
    showError,
    handleShowSuccess,
    handleShowError,
    handleCloseSuccess,
    handleCloseError,
  } = useSuccessfullModal();
  const updatePlanMutation = useUpdatePlan(
    handleShowSuccess,
    handleShowError,
    projectId
  );
  const {
    register,
    control,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: async () => {
      // let projectName = "";
      // let projectType = "";
      let clientType = "";
      const res = await myAxiosInstance(`plan/${projectId}`);
      const data = res.data.plan;
      if (data.isExist === true) {
        // projectName = data?.projectId?.projectName
        // projectType =   projectTypes.find(project => project.value == data?.projectId?.projectType)
        clientType = clientTypeEnum[data?.projectId?.clientType];
      } else {
        // projectName = data?.projectName
        // projectType = projectTypes.find(project => project.value == data?.projectType)
        clientType = data?.clientType;
      }
      // console.log("Hello Data",
      // {...data,
      //   startDate: new Date(data?.startDate),
      //   endDate: new Date(data?.endDate)
      // })
      return {
        isExist: `${+data?.isExist}`,
        projectId: {
          label: data?.projectId?.requestId?.projectName,
          value: data?.projectId?._id,
        },
        buildingLocation: data?.buildingLocation,
        clientType: clientType,
        projectName: data?.projectName,
        projectType: projectTypes.find(
          (project) => project.value == data?.projectType
        ),
        planDescription: data?.planDescription,
        startDate: new Date(data?.startDate),
        endDate: new Date(data?.endDate),
        noteClient: data?.noteClient,
        // fileProject: data.fileProject,
        // papersProject: data.papersProject,
        owner: data?.assignTo?.map((item) => ({
          label: `${item.firstName} ${item.lastName}`,
          value: item._id,
        })),
      };
    },
  });
  const onSubmit = (data) => {
    data = manipulateDataFormat(data);

    var formdata = new FormData();
    formdata.append("isExist", data.isExist);
    if (data.isExist === true) {
      formdata.append("projectId", data.projectId);
    } else if (data.isExist === false) {
      formdata.append("projectName", data.projectName);
      formdata.append("projectType", +data.projectType);
      formdata.append("buildingLocation", data.buildingLocation);
      formdata.append("clientType", data.clientType);
    }
    formdata.append("noteClient", data.noteClient);
    formdata.append("planDescription", data.planDescription);
    formdata.append("startDate", data.startDate);
    formdata.append("endDate", data.endDate);
    for (let i = 0; i < data.owner.length; i++) {
      formdata.append(`assignTo[${i}]`, data.owner[i]);
    }
    for (let item of data.fileProject) {
      formdata.append("fileProject", item);
    }
    for (let item of data.papersProject) {
      formdata.append("papersProject", item);
    }

    updatePlanMutation.mutate(formdata);
    for (const [key, val] of formdata.entries()) {
      // console.log(key,val);
    }
    // console.log(data);
  };
  const selectedOption = watch("isExist");
  const selectedFileProject = watch("fileProject");
  const selectedPapersProject = watch("papersProject");
  useEffect(() => {
    if (updatePlanMutation?.isError) {
      if (updatePlanMutation?.error?.response?.data?.validationErrors) {
        setErrorMessage(
          <ListErrorComponent
            messages={updatePlanMutation?.error?.response?.data?.validationErrors?.split(
              ","
            )}
          />
        );
      } else if (updatePlanMutation?.error?.response?.data?.message) {
        setErrorMessage(updatePlanMutation?.error?.response?.data?.message);
      }
    }
  }, [updatePlanMutation?.isError]);
  return (
    <EditProjectPageWrapper>
      {updatePlanMutation.isLoading ? <LoadingPage /> : null}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Form>
          <FormModal title={"بحث عن المشروع"}>
            <div className="grid grid-cols-2 mb-3">
              <div className="flex items-center gap-2">
                <label
                  style={{
                    borderWidth: "1px",
                    borderStyle: "solid",
                    borderColor: selectedOption === "1" ? "#EFAA20" : "#414162",
                  }}
                  className="bg-mainItems rounded w-5 h-5 flex items-center justify-center"
                >
                  {selectedOption === "1" && (
                    <FaCheck color="var(--main-text)" />
                  )}
                  <input
                    className="appearance-none"
                    type="radio"
                    id="exist-project"
                    value={1}
                    {...register("isExist")}
                  />
                </label>
                <label htmlFor="exist-project">مشروع متاح</label>
              </div>

              <div className="flex items-center gap-2">
                <label
                  style={{
                    borderWidth: "1px",
                    borderStyle: "solid",
                    borderColor: selectedOption === "0" ? "#EFAA20" : "#414162",
                  }}
                  className="bg-mainItems rounded w-5 h-5 flex items-center justify-center"
                >
                  {selectedOption === "0" && (
                    <FaCheck color="var(--main-text)" />
                  )}
                  <input
                    className="appearance-none"
                    type="radio"
                    id="new-project"
                    value={0}
                    {...register("isExist", {
                      required: "يجب اختيار حالة المشروع",
                    })}
                  />
                </label>
                <label htmlFor="new-project">مشروع جديد</label>
              </div>
            </div>

            {selectedOption === "1" ? (
              <div className="grid grid-cols-2">
                <FormControl fullWidth>
                  <Controller
                    control={control}
                    name="projectId"
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Select
                        fullWidth
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        label={"اسم المشروع"}
                        placeholder="ادخل اسم المشروع"
                        OptionbackgroundColor="#414162"
                        options={projects?.map(({ _id, projectName }) => ({
                          label: projectName,
                          value: _id,
                        }))}
                      />
                    )}
                  />
                </FormControl>
              </div>
            ) : selectedOption === "0" ? (
              <>
                <div className="grid grid-cols-2 gap-4 mb-3">
                  <FormControl fullWidth>
                    <InputLabel id="new-project" label={"اسم مشروع جديد"} />
                    <CustomInput
                      control={control}
                      name="projectName"
                      placeholder="اسم مشروع جديد"
                      id="new-project"
                      rules={{
                        pattern: {
                          value: /^[A-Za-z\u0600-\u06FF ]{3,}$/i,
                          message:
                            "يجب ان تتكون من حروف عربى فقط او انجليزية فقط رقم",
                        },
                        minLength: {
                          value: 3,
                          message: "لا يقل عن 3 حروف",
                        },
                        maxLength: {
                          value: 100,
                          message: "لا يقل عن 100 حروف",
                        },
                      }}
                    />
                    <CustErrorMessage name={"projectName"} errors={errors} />
                  </FormControl>

                  <FormControl fullWidth>
                    <Controller
                      control={control}
                      name="projectType"
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Select
                          fullWidth
                          value={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          label={"نوع المشروع جديد"}
                          placeholder="اخل نوع المشروع"
                          OptionbackgroundColor="#414162"
                          options={projectTypes}
                        />
                      )}
                    />
                  </FormControl>
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <FormControl fullWidth>
                    <InputLabel id="new-project" label={"موقع المشروع"} />
                    <CustomInput
                      name={"buildingLocation"}
                      control={control}
                      rules={{
                        required: "يجب اضافة موقع المشروع",
                        minLength: 3,
                      }}
                    />
                    <CustErrorMessage
                      name={"buildingLocation"}
                      errors={errors}
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <InputLabel id="new-project" label={"نوع العميل"} />
                    <CustomInput
                      name={"clientType"}
                      control={control}
                      rules={{
                        required: "يجب اضافة نوع العميل",
                        minLength: {
                          value: 3,
                          message: "لا يقل عن 3 حروف",
                        },
                        maxLength: {
                          value: 100,
                          message: "لا يقل عن 100 حروف",
                        },
                      }}
                    />
                    <CustErrorMessage name={"clientType"} errors={errors} />
                  </FormControl>
                </div>
              </>
            ) : null}
          </FormModal>
          <FormModal title={"تفاصيل المهمة"}>
            <div className="grid grid-cols-12 mb-5">
              <div className="col-span-5">
                <FormControl fullWidth>
                  <Controller
                    control={control}
                    name="owner"
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Select
                        fullWidth
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        label={"اسم المسؤل"}
                        placeholder="ادخل اسم المسؤل"
                        OptionbackgroundColor="#414162"
                        isMulti={true}
                        options={supervisors?.map(
                          ({ _id, firstName, lastName }) => ({
                            label: `${firstName} ${lastName}`,
                            value: _id,
                          })
                        )}
                      />
                    )}
                  />
                </FormControl>
              </div>
            </div>
            <div className="grid grid-cols-12 mb-5">
              <div className="col-span-5">
                <FormControl fullWidth>
                  <InputLabel id="recieved-date" label={"تاريخ الاستلام"} />
                  <CustomDatePicker control={control} name="startDate" />
                </FormControl>
              </div>
              <div className="col-span-2"></div>
              <div className="col-span-5">
                <FormControl fullWidth>
                  <InputLabel id="recieving-date" label={"تاريخ التسليم"} />
                  <CustomDatePicker control={control} name="endDate" />
                </FormControl>
              </div>
            </div>

            <div className="">
              <InputLabel id="new-project-name" label={"وصف المهمة"} />

              <CustomTextArea
                control={control}
                name="planDescription"
                placeholder={"اكتب ملاحظات .................................."}
              />
            </div>
          </FormModal>

          <FormModal title={"ملاحظات العميل"}>
            <div className="">
              <InputLabel id="new-project-name" label={"ملاحظات العميل"} />

              <CustomTextArea
                control={control}
                name="noteClient"
                placeholder={"اكتب ملاحظات .................................."}
              />
            </div>
          </FormModal>

          <FormModal title={"ملفات المشروع"}>
            <div className="flex items-start gap-2">
              <CustomeInputFile
                register={{
                  ...register("fileProject", {
                    validate: validateFiles,
                  }),
                }}
                name="fileProject"
                multiple={true}
              />
              {!selectedFileProject?.length && plan?.fileProject?.length ? (
                <ShowFiles files={plan?.fileProject} />
              ) : null}
            </div>
            <CustErrorMessage name={"fileProject"} errors={errors} />
          </FormModal>
          <FormModal title={"مرفقات المشروع"}>
            <div className="flex items-start gap-2">
              <CustomeInputFile
                register={{
                  ...register("papersProject", {
                    validate: validateFiles,
                  }),
                }}
                name="papersProject"
                multiple={true}
              />
              {!selectedPapersProject?.length && plan?.papersProject?.length ? (
                <ShowFiles files={plan?.papersProject} />
              ) : null}
            </div>
            <CustErrorMessage name={"papersProject"} errors={errors} />
          </FormModal>
        </Form>
      </form>
      <SuccessfullModal
        handleClose={() => {
          handleCloseSuccess();
          navigate("/System/tasks/plans");
        }}
        message={"تمت تعديل المشروع بنجاح"}
        show={showSuccess}
      />
      <SuccessfullModal
        handleClose={handleCloseError}
        message={errorMessage}
        show={showError}
        status="error"
      />
    </EditProjectPageWrapper>
  );
}

const Form = ({ children }) => {
  return (
    <>
      <div className="h-[800px] overflow-auto scrollbar-none flex flex-col gap-4 ">
        {children}
      </div>
      <div className="flex justify-end mt-4">
        <button
          type="submit"
          className="w-[140px] h-[30px]  bg-[#EFAA20] rounded-[6px] text-white text-[15px] font-medium"
        >
          حفظ
        </button>
      </div>
    </>
  );
};

const EditProjectPageWrapper = ({ children }) => {
  const navigate = useNavigate();
  return (
    <Fragment>
      <SystemControler
        child={
          <IconButton
            onClick={() => {
              navigate(-1);
            }}
          >
            <IoMdArrowDropright color="white" fontSize={25} />
          </IconButton>
        }
      />
      <div className="bg-mainItems mb-2 p-5 border rounded-[19px] text-mainText !border-[#EFAA20] h-full">
        <div className="my-3">
          <ModalTitle title={" تعديل مشروع "} />
        </div>
        {children}
      </div>
    </Fragment>
  );
};
const ShowFiles = ({ files }) => {
  const { show, handleClose, handleShow } = useOpenModal();
  const [content, setContent] = useState(null);

  // console.log("files: ", files);
  return (
    <>
      <Example show={show} handleClose={handleClose} content={content} />
      {files?.map((file, index) => {
        const fileType = getType(file);
        switch (fileType) {
          case "jpg":
          case "jpeg":
          case "png":
          case "gif":
            return (
              <div
                key={index}
                onClick={() => {
                  setContent(
                    <>
                      <Button
                        className="bg-mainItems"
                        download={true}
                        href={file}
                      >
                        <FaFileDownload fontSize={25} color="#EFAA20" />
                      </Button>
                      <img
                        className="w-96  rounded-xl border"
                        src={file}
                        alt=""
                      />
                    </>
                  );
                  handleShow();
                }}
              >
                <img
                  className="w-24 h-24 rounded-xl border"
                  src={file}
                  alt=""
                />
              </div>
            );
          case "pdf":
            return (
              <div
                key={index}
                onClick={() => {
                  setContent(
                    <>
                      <Button
                        className="bg-mainItems"
                        download={true}
                        href={file}
                      >
                        <FaFileDownload fontSize={25} color="#EFAA20" />
                      </Button>
                      <embed
                        src={file}
                        type="application/pdf"
                        width="400"
                        height="600"
                        className="w-96 relative rounded-xl border"
                      />
                    </>
                  );
                  handleShow();
                }}
                className="flex justify-center items-center w-24 h-24 rounded-xl border"
              >
                <PdfIcon />
              </div>
            );
          case "zip":
            return (
              <div
                key={index}
                onClick={() => {
                  setContent(
                    <>
                      <Button
                        className="bg-mainItems"
                        download={true}
                        href={file}
                      >
                        <FaFileDownload fontSize={25} color="#EFAA20" />
                      </Button>
                      <img
                        className="w-96   rounded-xl border"
                        src="https://p7.hiclipart.com/preview/258/300/47/computer-icons-zip-truetype-icon-png-file-zip.jpg"
                        alt=""
                      />
                    </>
                  );
                  handleShow();
                }}
              >
                <img
                  className="w-24 h-24 rounded-xl border"
                  src="https://p7.hiclipart.com/preview/258/300/47/computer-icons-zip-truetype-icon-png-file-zip.jpg"
                  alt=""
                />
              </div>
            );
          case "xlsx":
            return (
              <div
                key={index}
                onClick={() => {
                  setContent(
                    <>
                      <Button
                        className="bg-mainItems"
                        download={true}
                        href={file}
                      >
                        <FaFileDownload fontSize={25} color="#EFAA20" />
                      </Button>
                      <img
                        className="w-96   rounded-xl border"
                        src="https://logodownload.org/wp-content/uploads/2020/04/excel-logo-0.png"
                        alt=""
                      />
                    </>
                  );
                  handleShow();
                }}
              >
                <img
                  className="w-24 h-24 rounded-xl border"
                  src="https://logodownload.org/wp-content/uploads/2020/04/excel-logo-0.png"
                  alt=""
                />
              </div>
            );
          case "rar":
            return (
              <div
                key={index}
                onClick={() => {
                  setContent(
                    <>
                      <Button
                        className="bg-mainItems"
                        download={true}
                        href={file}
                      >
                        <FaFileDownload fontSize={25} color="#EFAA20" />
                      </Button>
                      <img
                        className="w-96  rounded-xl border"
                        src="https://cdn-icons-png.freepik.com/512/9158/9158683.png"
                        alt=""
                      />
                    </>
                  );
                  handleShow();
                }}
              >
                <img
                  className="w-24 h-24 rounded-xl border"
                  src="https://cdn-icons-png.freepik.com/512/9158/9158683.png"
                  alt=""
                />
              </div>
            );
          default:
            return null;
        }
      })}
    </>
  );
};
function Example({ show, handleClose, content }) {
  return (
    <Modal
      size="lg"
      centered
      show={show}
      onHide={handleClose}
      contentClassName="p-0 bg-mainNavy w-fit mx-auto"
      className="bg-black/50"
    >
      <Modal.Body className="flex flex-col items-center">{content}</Modal.Body>
    </Modal>
  );
}

const getType = (fileName) => {
  const fileExtension = fileName.split(".").pop().toLowerCase();
  return fileExtension;
};
const useOpenModal = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return {
    show,
    handleClose,
    handleShow,
  };
};
