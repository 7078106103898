import { createContext, useContext, useState } from "react";
export const ChatsContext = createContext();

export default function ChatsContextProvider({ children }) {
  const [chatItem, setChatItem] = useState(null);
  return (
    <ChatsContext.Provider
      value={{
        setChatItem,
        chatItem,
      }}
    >
      {children}
    </ChatsContext.Provider>
  );
}

export const useChatsContext = () => useContext(ChatsContext);
