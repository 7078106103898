import { Fragment, createContext, useContext, useMemo, useState } from "react";

import { NavDropdown } from "react-bootstrap";
import "./index.css";
import { SearchComponent } from "../../../SearchComponent/SearchComponent";
import { defaultImage } from "../../../../Config/Config";
import { useGetAllUsers } from "../../../../hooks/fetchers/Users";
import { FilterIcon } from "../../../../utiltis/Icons";
import { Avatar, Stack } from "@mui/material";
import { ConnectedBadge, NotConnectedBadge } from "../../../Users/StatusBadge";
import HoverLink from "../../../List/HoverLink";
import List from "../../../List";

const UsersActiveContext = createContext(null);

const useUsersActiveContext = () => {
  const ctx = useContext(UsersActiveContext);
  if (!ctx) {
    throw new Error(
      "useUsersActiveContext must be used within UsersActiveContextProvider"
    );
  }
  return ctx;
};

const UsersActiveProvider = ({ children }) => {
  const [status, setStatus] = useState(undefined);
  const [searchParams, setSearchParams] = useState({});
  const { data, isLoading } = useGetAllUsers(searchParams);
  const handleASearch = (value) => {
    setSearchParams({ search: value });
  };

  const activeUsers = useMemo(
    () => data?.allUsers?.filter((user) => user?.status === +status),
    [status]
  );
  // console.log(activeUsers);
  return (
    <UsersActiveContext.Provider
      value={{
        status,
        setStatus,
        handleASearch,
        data: status ? activeUsers : data?.allUsers,
        isLoading,
      }}
    >
      {children}
    </UsersActiveContext.Provider>
  );
};

const DisplayUsers = () => {
  const { data, isLoading } = useUsersActiveContext();
  let content;

  if (data && data.length) {
    content = (
      <List
        isLoading={isLoading}
        data={data}
        renderItem={(props) => (
          <HoverLink to={`/System/Hr/Users/${props._id}`} key={props._id}>
            <UserCard {...props} />
          </HoverLink>
        )}
      />
    );
  } else {
    content = (
      <p className="text-mainText text-lg text-center py-3">
        لا يوجد بيانات لعرضها
      </p>
    );
  }

  return <Fragment>{content}</Fragment>;
};

const SearchForUser = () => {
  const { handleASearch } = useUsersActiveContext();

  return (
    <div className="">
      <SearchComponent
        handleChange={handleASearch}
        background="lighter"
        border="border !border-[#E4A11B]"
      />
    </div>
  );
};

const SearchUsers = () => {
  // const {data,handleASearch} = useUsersActiveContext()
  return (
    <UsersActiveProvider>
      <div className="search-users p-2">
        <div className="flex justify-between items-center">
          <p className="text-center my-3 text-mainText">كل الموظفين</p>
          <UserStatusMenu />
        </div>
        <SearchForUser />
        <div className="max-h-[700px] overflow-y-auto scrollbar-thin scrollbar-track-transparent scrollbar-thumb-[#EFAA20]">
          <DisplayUsers />
        </div>
      </div>
    </UsersActiveProvider>
  );
};

const UserStatusMenu = () => {
  const { setStatus } = useUsersActiveContext();

  return (
    <NavDropdown
      title={<FilterIcon />}
      className="fs-5"
      id="collapsible-nav-dropdown"
    >
      <NavDropdown.Item
        onClick={() => setStatus(undefined)}
        className="text-end  d-flex justify-content-between  align-items-center hover:rounded-lg"
      >
        <span> الكل</span>
      </NavDropdown.Item>
      <NavDropdown.Item
        onClick={() => setStatus("1")}
        className="text-end  d-flex justify-content-between  align-items-center hover:rounded-lg"
      >
        <span> متصل</span>
        <div className="connected" />
      </NavDropdown.Item>
      <NavDropdown.Item
        onClick={() => setStatus("0")}
        className="text-end  d-flex justify-content-between align-items-center hover:rounded-lg"
      >
        <span>غير متصل</span>
        <div className="not-connected " />
      </NavDropdown.Item>
    </NavDropdown>
  );
};

const UserCard = ({ status, firstName, lastName, image, email }) => {
  return (
    <>
      <Stack direction="row" spacing={2}>
        {status === 1 ? (
          <ConnectedBadge
            overlap="circular"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            variant="dot"
          >
            <Avatar
              alt={`${firstName} ${lastName}`}
              src={image ? image : defaultImage}
            />
          </ConnectedBadge>
        ) : (
          <NotConnectedBadge
            overlap="circular"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            variant="dot"
          >
            <Avatar
              alt={`${firstName} ${lastName}`}
              src={image ? image : defaultImage}
            />
          </NotConnectedBadge>
        )}
      </Stack>
      <div className="d-flex flex-column me-3 ">
        <h2 className="text-mainText text-base font-medium">
          {`${firstName} ${lastName}`}
        </h2>
        <p className="text-mainCustomGray text-sm font-normal">{email}</p>
      </div>
    </>
  );
};

export default SearchUsers;
