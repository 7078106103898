import { createContext, useContext, useEffect, useState } from "react";
import SuccessfullModal from "../Components/Modals/SuccessfullModal";
import LoadingPage from "../Components/feedback/LoadingPage";
import { useAddRequest } from "../hooks/fetchers/Requests";
import useSuccessfullModal from "../Components/Modals/hooks/useSuccessfullModal";

export const MultiStepContext = createContext();
const StepContext = ({ children }) => {
  const [message, setMessage] = useState("حدث خطأ ما");
  const [orderNumber, setOrderNumber] = useState("");

  const [selectProjectType, setSelectedProjectType] = useState("");
  const { showAddRequest, setShowAdddRequest } = useState(false);
  const [openDesignSteps, setOpenDesignSteps] = useState(true);
  const [openReviewSteps, setOpenReviewSteps] = useState(true);
  const [view, setView] = useState(false);
  const [userData, setUserData] = useState([]);
  const [openDesign, setOpenDesign] = useState(false);
  const [currentStep, setStep] = useState(1);
  const [finalData, setFinalData] = useState([]);
  const [Submitted, setSubmitted] = useState(false);
  const [openCongrats, setOpenCongrats] = useState(false);
  const {
    handleShowSuccess,
    handleShowError,
    handleCloseSuccess,
    handleCloseError,
    showSuccess,
    showError,
  } = useSuccessfullModal();
  const { mutate, isLoading, isError, error } = useAddRequest((data) => {
    setOrderNumber(data?.data?.request?.orderNumber);
    setSubmitted(false);
    setOpenCongrats(true);
    // setConfirmSubmit(true);
    setView(false);
    setUserData([]);
    handleShowSuccess();
  }, handleShowError);
  const formData = new FormData();
  const submitRequest = async (e) => {
    e.preventDefault();
    setSubmitted(true);
    let request = new FormData();

    if (selectProjectType?.value === 1) {
      request.append("ownerName", userData?.ownerName);
      request.append(
        "servicenumber",
        userData?.serviceNumber
      );
      request.append(
        "transactionNumber",
        userData?.transactionNumber
      );
      request.append("projectName", userData?.projectName);
      request.append(
        "buildingLocation",
        userData?.buildingLocation
      );
      request.append("locationMap", userData?.locationMap );
      request.append("city", userData?.city);
      request.append("area", userData?.area);
      request.append("pieceNumber", userData?.pieceNumber);
      request.append("chartNumber", userData?.chartNumber);
      userData?.categoryId &&
        request.append("categoryId", userData?.categoryId);
      userData?.subcategoryId &&
        request.append("subcategoryId", userData?.subcategoryId);
      userData?.servicesId &&
        request.append("servicesId", userData?.servicesId);
      userData?.subservicesId &&
        request.append("subservicesId", userData?.subservicesId);
      userData?.projectType &&
        request.append("projectType", userData?.projectType);
      userData?.clientType &&
        request.append("clientType", userData?.clientType);
      userData?.identityType &&
        request.append("identityType", userData?.identityType);
      userData?.commercialNumber &&
        request.append("commercialRegNo", userData?.commercialNumber);
      userData?.idPhoto && request.append("idPhoto", userData?.idPhoto);
      request.append("taxNumber", userData?.taxNumber);
      request.append("phone", userData?.phone);
      userData?.instrumentImage &&
        request.append("instrumentImage", userData?.instrumentImage);
      // request.append("phone", userData?.phone);
      request.append("agent", userData?.agent);
      request.append(
        "agencyNumber",
        userData?.agencyNumber
      );
      request.append(
        "instrumentNumber",
        userData?.instrumentNumber
      );
      request.append("notes", userData?.notes);
      userData?.agencyAttachments &&
        request.append("agencyAttachments", userData?.agencyAttachments);
      request.append("email", userData?.email);
      request.append(
        "identityNumber",
        userData?.identityNumber
      );
    } else {
      request.append("ownerName", userData?.ownerName);
      request.append(
        "servicenumber",
        userData?.serviceNumber
      );
      request.append(
        "transactionNumber",
        userData?.transactionNumber
      );
      request.append("projectName", userData?.projectName);
      request.append(
        "buildingLocation",
        userData?.buildingLocation
      );
      request.append("locationMap", userData?.locationMap );
      request.append("city", userData?.city);
      request.append("area", userData?.area);
      request.append("pieceNumber", userData?.pieceNumber);
      request.append("chartNumber", userData?.chartNumber);
      userData?.projectType &&
        request.append("projectType", userData?.projectType);
      userData?.clientType &&
        request.append("clientType", userData?.clientType);
      userData?.identityType &&
        request.append("identityType", userData?.identityType);
      userData?.commercialNumber &&
        request.append("commercialRegNo", userData?.commercialNumber);
      request.append("email", userData?.email);
      userData?.idPhoto && request.append("idPhoto", userData?.idPhoto);
      request.append("phone", userData?.phone);
      // request.append("phone", userData?.phone);
      userData?.instrumentImage &&
        request.append("instrumentImage", userData?.instrumentImage);
      request.append("taxNumber", userData?.taxNumber);
      request.append(
        "licenseNumber",
        userData?.licenseNumber
      );

      request.append("licenseDeed", userData?.licenseDeed);
      userData?.licenseDate &&
        request.append("licenseDate", userData?.licenseDate);
      request.append(
        "identityNumber",
        userData?.identityNumber
      );
      userData?.licenseAttachments &&
        request.append("licenseAttachments", userData?.licenseAttachments);
      userData?.notes && request.append("notes", userData?.notes);
    }
    mutate(request);
    // console.log(userData);
    // try {
    //   // setIsLoading(true);
    //   const { data } = await addRequest(request);
    //   if (data.success) {
    // setOrderNumber(data?.request?.orderNumber);
    // setSubmitted(false);
    // setOpenCongrats(true);
    // // setConfirmSubmit(true);
    // setView(false);
    // setUserData([]);
    //   } else {
    //     setSubmitted(false);
    //     toast.error(data?.message);
    //     setSubmitted(false);
    //   }
    // } catch ({ response }) {
    //   setSubmitted(false);
    //   toast.error(response?.data?.message);
    //   setSubmitted(false);
    // }
    // setIsLoading(false);
  };
  useEffect(() => {
    if (isError) {
      setMessage(error?.response?.data?.message);
    }
  }, [isError, error]);
  return (
    <>
      {isLoading ? <LoadingPage /> : null}
      <SuccessfullModal
        show={showSuccess}
        message={`تم اضافة الطلب ${orderNumber} فى المشاريع بنجاح  `}
        handleClose={() => {
          handleCloseSuccess();
          setView(false);
        }}
      />
      <SuccessfullModal
        show={showError}
        message={message}
        handleClose={handleCloseError}
        status="error"
      />
      <MultiStepContext.Provider
        value={{
          showAddRequest,
          formData,
          setShowAdddRequest,
          submitRequest,
          setOpenReviewSteps,
          selectProjectType,
          view,
          setView,
          setSelectedProjectType,
          openReviewSteps,
          setOpenDesignSteps,
          openDesignSteps,
          openCongrats,
          setOpenCongrats,
          userData,
          setUserData,
          finalData,
          setFinalData,
          currentStep,
          setStep,
          openDesign,
          setOpenDesign,
          Submitted,
        }}
      >
        {children}
      </MultiStepContext.Provider>
    </>
  );
};

export const useStepContext = () => {
  const ctx = useContext(MultiStepContext);
  if (ctx) {
    return ctx;
  } else {
    throw Error("Use Hook Inside Step Context Provider!");
  }
};

export default StepContext;
