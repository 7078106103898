import { createContext, useContext, useState } from "react";
import { useGetAllMeetings } from "../../../../hooks/fetchers/Meetings";
import useModal from "../../../../hooks/useModal";

const MeetingContext = createContext(null);

const MeetingProvider = ({ children }) => {
  const [showEditDeleteModal, setShowEditDeleteModal] = useState(false);
  const [selectedEventId, setSelectedEventId] = useState(null);
  const MeetingsApi = useGetAllMeetings();
  const AddModalActions = useModal();
  const EditModalActions = useModal();
  const mappedEvents = MeetingsApi?.data?.events?.map((item) => ({
    id: item?._id,
    title: item?.descraption,
    start: new Date(item?.startDate),
    end: new Date(item?.startDate),
    desc: item?.descraption,
    allDay: true,
  }));
  const handleEditMeeting = (event) => {
    setSelectedEventId(event.id);
    setShowEditDeleteModal(true);
  };
  return (
    <MeetingContext.Provider
      value={{
        MeetingsApi,
        AddModalActions,
        EditModalActions,
        mappedEvents,
        handleEditMeeting,
        selectedEventId,
        showEditDeleteModal,
        setShowEditDeleteModal,
      }}
    >
      {children}
    </MeetingContext.Provider>
  );
};

export const useMeetingContext = () => {
  const ctx = useContext(MeetingContext);
  if (!ctx) {
    throw Error("You Must use inside Provider");
  }
  return ctx;
};

export default MeetingProvider;
