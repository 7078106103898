import React, { useState } from "react";

const ReceptionDropdown = ({ setOpen }) => {
  const [active, setActive] = useState(false);
  return (
    <div className="absolute top-[2.8rem] right-12 z-[1000000] bg-mainNavy rounded-xl">
      <div className="flex items-start flex-col  bg-mainItems w-[300px] h-[300px]  p-2 overflow-auto shadow-2xl ">
        <div
          className="w-screen h-screen fixed inset-0  z-[-1]"
          onClick={() => {
            setOpen(false);
          }}
        />
        <div className="bg-mainItems w-full  !h-full rounded-[19px]">
          <div className="p-3 ">
            <div className="my-3">
              <p className="text-mainText text-[15px] ">الاستقبال</p>
            </div>
            <div>
              <OrderBtn
                title={"البنود"}
                index={1}
                active={1}
                setActive={setActive}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReceptionDropdown;
const OrderBtn = ({ title, active, setActive, index }) => {
  return (
    <button
      onClick={() => setActive(index)}
      className={`p-2 add-user-button text-right !w-full border hover:!border-[#EFAA20] rounded-md ${
        active === index
          ? "text-[#EFAA20] text-base  !border-[#EFAA20]"
          : "text-[#ffffff80] !text-xs !border-transparent"
      } `}
    >
      {title}
    </button>
  );
};
