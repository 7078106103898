import React from "react";

const ClientListItemCard = ({ ownerName, clientType }) => {
  return (
    <div className="d-flex flex-column me-3 ">
      <h2 className="text-mainText text-base font-medium">{`${ownerName}`}</h2>
      <p className="text-mainCustomGray text-sm font-normal">{clientType}</p>
    </div>
  );
};

export default ClientListItemCard;
