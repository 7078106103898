import React from 'react'
import Cookies from "js-cookie";
import UnAuthorizedMessage from '../Components/UnAuthorizedMessage';
const roles = {
    admin: "مدير",
    senior: "مدير قسم",
    office: "مدير المكتب",
    employee: "موظف",
  };


// export default function withGuard(Component,acceptedRoles) {
//     // const roles = ["مدير","مدير قسم","مدير المكتب","موظف"]
//     const role =  Cookies.get("role")
//     const isAllowed = acceptedRoles.includes(role)
//     console.log("acceptedRoles: ",acceptedRoles);
//     console.log("role: ",role);
//     console.log("isAllowed: ",acceptedRoles.includes(role));
//     return () => isAllowed ? <Component/> : <UnAuthorizedMessage/>
  
// }
export default function withGuard(Component, acceptedRoles) {
  const acceptedRolesSet = new Set(acceptedRoles);

  return () => {
      const role = Cookies.get("role");
      const isAllowed = acceptedRolesSet.has(role);

      // console.log("acceptedRoles: ", acceptedRoles);
      // console.log("role: ", role);
      // console.log("isAllowed: ", isAllowed);

      return isAllowed ? <Component /> : <UnAuthorizedMessage />;
  };
}