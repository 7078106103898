import { toast } from "react-toastify";
import myAxiosInstance, { myAxiosJson } from "../https";
import { useQuery } from "react-query";

export const getAllFinancialProject = () =>
  myAxiosInstance("projects/dropdown").catch(error=>{
    toast.error(error?.response?.data?.message)
});

export const addFinancial = (data) => myAxiosInstance.post("finaical/", data);

export const getAllFinancial = (params) =>
  myAxiosInstance("finaical", { params })
    .then(({ data }) => data)
    .catch(error=>{
      toast.error(error?.response?.data?.message)
  })
export const getFinancialById = (id) => myAxiosInstance.get(`finaical/${id}`);

export const updateFinancial = (id, data) =>
  myAxiosInstance.patch(`finaical/${id}`, data);

export const deleteFinancial = (data) => myAxiosJson.delete(`finaical/`,{data});

export const useGetAllFinancial = (params) => {
  return useQuery(["request", params], () => getAllFinancial(params));
};

export const getFinancialStats = () => myAxiosInstance.get(`statics/financial`).catch(error=>{
  toast.error(error?.response?.data?.message)
});
export const getFinancialReportMonthly = () =>
  myAxiosInstance
    .get(`statics/financial/report/monthly`)
    .then(({ data }) => {
      const transformedData = Object.values(data);
      return [...transformedData];
    })
    .catch(error=>{
      toast.error(error?.response?.data?.message)
  });
export const getAllAccountStats = () =>
  myAxiosInstance.get(`statics/accountant`).catch(error=>{
    toast.error(error?.response?.data?.message)
});
export const getAllAccountMonthlyReport = () =>
  myAxiosInstance
    .get(`statics/accountant/report/monthly`)
    .then(({ data }) => data)
    .catch(error=>{
      toast.error(error?.response?.data?.message)
  });

export const getFinancialPDF = (id) =>
  myAxiosInstance.get(`finaical/pdf/${id}`).catch(error=>{
    toast.error(error?.response?.data?.message)
});
