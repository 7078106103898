import ReactApexChart from "react-apexcharts";
import Progress from "../../../Progress";

const AllRequestsPieChart = ({ data }) => {
  const series = [
    parseInt(data?.totalStatusCounts?.IN_PROGRESS),
    parseInt(data?.totalStatusCounts?.PENDING),
    parseInt(data?.totalStatusCounts?.REJECTED),
    parseInt(data?.totalStatusCounts?.TO_PROJECT),
  ];
  const options = {
    chart: {
      width: 380,
      type: "donut",
    },
    labels: [
      `قيد التنفيذ ${parseInt(data?.totalStatusCounts?.IN_PROGRESS)} `,
      `فى الانتظار ${parseInt(data?.totalStatusCounts?.PENDING)} `,
      `مرفوضة ${parseInt(data?.totalStatusCounts?.REJECTED)} `,
      `محولة ${parseInt(data?.totalStatusCounts?.TO_PROJECT)} `,
    ],
    colors: ["#D59921", "#03795D", "#E40038", "#6F4E37"],
    stroke: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            total: {
              show: true,
              label: " كل الطلبات",
              color: "var(--main-text)",
              formatter: (val) => {
                return val?.config?.series.reduce((acc, curr) => acc + curr, 0);
              },
            },
          },
        },
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            show: false,
          },
        },
      },
    ],
    legend: {
      position: "left",
      offsetY: 0,
      height: 230,
    },
  };

  return (
    <div>
      <div className="chart-wrap">
        <div id="chart">
          {data ? (
            <ReactApexChart
              options={options}
              series={series}
              type="donut"
              width={400}
            />
          ) : (
            <Progress />
          )}
        </div>
      </div>
    </div>
  );
};

export default AllRequestsPieChart;
