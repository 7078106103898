import React, {
  useCallback, useEffect, useState
} from "react";
import ConfirmPoper from "../../../../../Components/System/ConfirmPoper";
import EditDesignRequest from "../../../../../Components/System/Requests/EditRequest/EditDesignRequest";
import { useParams } from "react-router-dom";
import ShowDesignRequest from "../../../../../Components/System/ShowRequest/ShowDesignRequest";
import "./index.css";
import DesignCasesChart from "../../../../../Components/System/Requests/DesignRequestChart/DesignCasesChart/DesignCasesChart";
import Image from "../../../../../Components/Image.jsx";
import { TableCell } from "../../../../../Components/Table/TableCell.jsx";
import { TableRow } from "../../../../../Components/Table/TableRow.jsx";
import CustomTable from "../../../../../Components/Table/index.jsx";
import {
  getAllRequestsStats
} from "../../../../../helper/fetchers/Requests.jsx";
import moment from "moment";
import Progress from "../../../../../Components/Progress.jsx";
import { useRequestsContext } from "../../../../../Context/requests.js";
import { CheckRole, GenerateColumnsNames } from "../../../../../helper/utils.jsx";
import {
  useDeleteRequest,
  useGetAllRequests,
} from "../../../../../hooks/fetchers/Requests.jsx";
import { SearchComponent } from "../../../../../Components/SearchComponent/SearchComponent.jsx";
import CustomCheckboxTable from "../../../../../Components/CustomCheckboxTable.jsx";
import { RiDeleteBin6Line } from "react-icons/ri";
import useSuccessfullModal from "../../../../../Components/Modals/hooks/useSuccessfullModal.jsx";
import LoadingPage from "../../../../../Components/feedback/LoadingPage.jsx";
import SuccessfullModal from "../../../../../Components/Modals/SuccessfullModal.jsx";
import TableDeleteButton from "../../../../../Components/Buttons/TableDeleteButton.jsx";
import { roles } from "../../../../../helper/fetchers/Tasks.jsx";
const columns = GenerateColumnsNames(
  "تحديد",

  "م",
  "اسم المشروع",
  "رقم الطلب",
  "تاريخ الانشاء",
  "نوع المشروع",
  "الحالة",
  "عرض",
  "تعديل"
);
const DesignCasesRequest = () => {
  // const [showProject, setShowProject] = useState(false);
  const [editRequest, setEditRequest] = useState(false);
  const [ConfirmUpdate, setConfirmUpdate] = useState(false);
  const [projectType, setProjectType] = useState("");
  const [page, setPage] = useState(1);
  // const [status, setStatus] = useState();
  const [projectTypeAR, setProjectTypeAR] = useState("");
  const [chartColor, setChartColor] = useState("");
  // const [designRequests, setDesignRequests] = useState();
  const { showProject, setShowProject } = useRequestsContext();
  const [search, setSearch] = useState("");
  const { status } = useParams();
  // &status=${status}
  const { data, isLoading, isError } = useGetAllRequests({
    page,
    projectType: 1,
    search,
    status,
  });

  const [id, setId] = useState(null);
  const handleChange = (event, value) => {
    setPage(value);
    // setFilterTasksParams({ page: value });
  };
  // const getDesignRequests = async () => {
  //   try {
  //     const { data } = await getDesignRequestsWithStatus(status,page);
  //     // console.log("design requests based on status ");
  //     // console.log(data);
  //     console.log("data: ",data);
  //     if (data) {
  //       setDesignRequests(data);

  //     } else {
  //       console.log("Data retrieval failed");
  //     }
  //   } catch (error) {
  //     toast.error(error?.response?.data?.message);
  //   }
  // };

  useEffect(() => {
    switch (status) {
      case "1":
        setProjectType("inProgress");
        setProjectTypeAR("طلبات قيد التنفيذ");
        setChartColor("#4200FF");
        // setStatus(1);

        break;
      case "66":
        setProjectType("converted");
        setProjectTypeAR("طلبات تم تحويلها الي مشاريع");
        setChartColor("#4200FF");
        // setStatus(1);

        break;
      case "0":
        setProjectType("pending");
        setProjectTypeAR("طلبات فى انتظار الموافقة");
        setChartColor("#D59921");
        // setStatus(0);

        break;

      case "5":
        setProjectType("rejected");
        setProjectTypeAR("طلبات مرفوضة");
        setChartColor("#E40038  ");
        // setStatus(2);

        break;
      default:
        break;
    }
  }, [status]);
  // useEffect(() => {
  //   getDesignRequests();
  // }, [status,page]);

  const [stats, setStats] = useState();
  const getRequests = useCallback(async () => {
    const response = await getAllRequestsStats();
    if (response?.status === 200) {
      setStats(response?.data);
    }
  }, []);
  useEffect(() => {
    getRequests();
  }, [getRequests]);

  const handleSearch = (val) => {
    setSearch(val);
    console.log(`Search:`, val);
  };
  const [checked, setChecked] = useState([]);
  const {
    handleShowSuccess,
    handleShowError,
    handleCloseSuccess,
    handleCloseError,
    showSuccess,
    showError,
  } = useSuccessfullModal();
  const {
    mutate: deleteMutation,
    isLoading: deleteIsLoading,
    isError: deleteIsError,
  } = useDeleteRequest(
    () => {
      handleShowSuccess();
      setChecked([]);
    },
    handleShowError
  );
  const handleChecked = (val) => {
    if (checked.includes(val)) {
      setChecked(checked.filter((item) => item !== val));
    } else {
      setChecked([...checked, val]);
    }
  };
  // You have array of ids to delete

  const handleDeleteAll = () => {};
  const handleDeleteSelected = () => {
    deleteMutation({requests: checked});
  };
  return (
    <>
      {deleteIsLoading ? <LoadingPage /> : null}
      <SuccessfullModal
        handleClose={handleCloseSuccess}
        message={"تم الحذف بنجاح"}
        show={showSuccess}
      />
      <SuccessfullModal
        handleClose={handleCloseError}
        message={"حدث خطأ ما"}
        show={showError}
        status="error"
      />
      {showProject ? (
        <div className="AllRequests-scroll  h-full scrollbar-none">
          <ShowDesignRequest
            status={status}
            setShowProject={setShowProject}
            id={id}
            // getDesignRequests={getDesignRequests}
          />
        </div>
      ) : (
        <div className="AllRequests  h-full p-3">
          <div className="DesignCasesRequest  h-full ">
            <div className="designChartContainer d-flex flex-column justify-content-center align-items-center">
              <p className="my-3 fs-4 text-mainText">{projectTypeAR}</p>
              <DesignCasesChart
                color={chartColor}
                projectType={projectTypeAR}
                stats={stats}
                design={true}
              />
            </div>

            <fieldset className="TableContainer  w-100 py-3 px-2 mx-auto mt-3">
              <legend className="text-center text-mainText">
                {projectType === "inProgress"
                  ? "طلبات قيد التنفيذ ( تصميم )"
                  : projectType === "pending"
                  ? "طلبات فى انتظار الموافقة ( تصميم )"
                  : projectType === "converted"
                  ? "طلبات تم تحويلها الي مشاريع ( تصميم )"
                  : projectType === "confirm"
                  ? "طلبات منتهية ( تصميم )"
                  : projectType === "rejected"
                  ? "طلبات مرفوضة ( تصميم )"
                  : null}
              </legend>
              <div className="">
                <div className="w-3/4 mx-auto ">
                  <SearchComponent
                    handleChange={handleSearch}
                    value={search}
                    className={""}
                    border="border !border-[#E4A11B]"
                  />
                </div>
              </div>
              {!isLoading && !isError && data?.request.length > 0 ? (
                <TableDeleteButton
                checked={checked}
                dataLength={data?.request.length}
                onClickDeleteAll={handleDeleteAll}
                onClickDeleteSelected={handleDeleteSelected}
              />
              ) : null}

              <div className="mt-3 !h-[400px] overflow-y-auto scrollbar-thin scrollbar-track-mainNavy scrollbar-thumb-[#E4A11B]">
                {isLoading ? <Progress /> : null}
                {isError ? (
                  <p className="text-red-500 text-center text-lg">
                    يوجد خطأ ما
                  </p>
                ) : null}

                {CheckRole(roles["admin"]) && !isLoading && !isError && data.request ? (
                  <CustomTable
                  columns={CheckRole(roles["admin"]) ? columns : columns.slice(1)}
                    data={data?.request}
                    paginationProps={{
                      count: data.pages,
                      page: page,
                      onChange: handleChange,
                    }}
                  >
                    {data?.request && data?.request.length > 0 // Check if designRequests has elements
                      ? data?.request.map(
                          (
                            {
                              _id,
                              projectName,
                              orderNumber,
                              createdAt,
                              projectType,
                              status,
                              enStatus,
                              display,
                              edit,
                            },
                            index
                          ) => (
                            <TableRow
                              className={`my-2 border !border-[#efaa207f] ${
                                index % 2 === 0 ? "bg-mainNavy" : ""
                              }`}
                              key={_id}
                            >
                              {CheckRole(roles["admin"]) ? (
                              <TableCell>
                                <CustomCheckboxTable
                                  isChecked={checked?.some((x) => x === _id)}
                                  handleClick={() => handleChecked(_id)}
                                />
                              </TableCell>
                              ) : null}
                              <TableCell>
                                {(page - 1) * 10 + (index + 1)}
                              </TableCell>
                              <TableCell>{projectName}</TableCell>
                              <TableCell>{orderNumber}</TableCell>
                              <TableCell>
                                {moment(createdAt).format("YYYY-MM-DD")}
                              </TableCell>
                              <TableCell>تصميم</TableCell>
                              <TableCell>
                                {status == 0
                                  ? "في الانتظار"
                                  : status == 1
                                  ? "قيد التنفيذ"
                                  : status == 66
                                  ? "تم تحويلها الي مشروع"
                                  : "مرفوضة"}
                              </TableCell>
                              <TableCell>
                                <Image
                                  src={
                                    process.env.PUBLIC_URL + "/icons/view.svg"
                                  }
                                  onClick={() => {
                                    setShowProject(true);
                                    setId(_id);
                                  }}
                                  className="display_project  rounded"
                                  alt=" display project"
                                />
                              </TableCell>
                              <TableCell>
                                <Image
                                  src={
                                    process.env.PUBLIC_URL + "/icons/edit.svg"
                                  }
                                  onClick={() => {
                                    setEditRequest(true);
                                    setId(_id);
                                  }}
                                  className=" edit_project  rounded"
                                  alt=" edit project"
                                />
                              </TableCell>
                            </TableRow>
                          )
                        )
                      : null}
                  </CustomTable>
                ) : null}
              </div>
            </fieldset>
          </div>
        </div>
      )}

      {editRequest && (
        <div className="AllRequests-scroll scrollbar-none">
          <EditDesignRequest
            // getDesignRequests={getDesignRequests}
            id={id}
            editRequest={editRequest}
            setEditRequest={setEditRequest}
            handleClose={() => {
              setEditRequest(false);
            }}
          />
        </div>
      )}
      {ConfirmUpdate && (
        <div>
          <ConfirmPoper
            confirmPoper={ConfirmUpdate}
            setConfirmPoper={setConfirmUpdate}
            setEditRequest={setEditRequest}
            text={"تم تعديل الطلب فى المشاريع بنجاح  "}
          />
        </div>
      )}
    </>
  );
};

export default DesignCasesRequest;
