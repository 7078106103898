import "../style.css";
import { SearchComponent } from "../../../../Components/SearchComponent/SearchComponent";
import { Link, Outlet } from "react-router-dom";
import { projectTypeEnum } from "../../../../helper/utils";
import FilterClients from "../components/FilterClients";
import { useContext, useState } from "react";
import { AllClientContext } from "../components/PageWrapperAllCLients";
import { Tooltip } from "@mui/material";
import HoverLink from "../../../../Components/List/HoverLink";
import List from "../../../../Components/List";
const InsideClients = () => {
  const { data, search, setSearch ,isLoading} = useContext(AllClientContext);
  const handleSearch = (val) => {
    setSearch(val);
    // console.log(`Search:`, val);
  };
  return (
    <div className="grid grid-cols-12 gap-2 h-full">
      <div className="col-span-4 all-clients-search-container">
        <div className="pt-4 pb-2 px-2">
          <SearchComponent
            className="w-full"
            border="border !border-[#E4A11B]"
            value={search}
            handleChange={handleSearch}
          />

          <FilterClients />
        </div>

        <div className="all-clints-search-driver inside-Search"></div>
        <div className="max-h-[700px]  overflow-y-auto scrollbar-thin scrollbar-track-transparent scrollbar-thumb-[#EFAA20]">
          {data && data?.clients?.length > 0 ? (
            <List
              data={data?.clients}
              isLoading={isLoading}
              renderItem={(props) => (
                <HoverLink to={`${props._id}`} key={props._id}>
                  <ClientCard {...props} />
                </HoverLink>
              )}
            />
          ) : (
            <p className="text-lg text-center h-[35px]">لا يوجد عملاء</p>
          )}
        </div>
      </div>
      <Outlet />
    </div>
  );
};

export default InsideClients;

const ClientCard = ({ ownerName, projectType }) => {
  return (
    <div>
      <Tooltip title={ownerName} placement="top">
        <p className="text-mainText text-sm font-medium line-clamp-1">
          {ownerName}
        </p>
      </Tooltip>
      <p className="text-mainCustomGray text-xs font-normal">
        {projectTypeEnum[projectType]}
      </p>
    </div>
  );
};
