import { useEffect, useState } from "react";
import style from "./VacationsTable.module.css";
import Input from "../../../../../Components/FormHandler/Input";
import CustomTable from "../../../../../Components/Table";
import { TableCell } from "../../../../../Components/Table/TableCell";
import { TableRow } from "../../../../../Components/Table/TableRow";
import Image from "../../../../../Components/Image.jsx";
import ShowHolidayComponent from "../../../../../Components/System/Hr/ShowHolidayComponent/ShowHolidayComponent.jsx";
import { FaPrint } from "react-icons/fa";
import moment from "moment";
import { Document, Page, pdfjs } from "react-pdf";

import Cookies from "js-cookie";
import {
  useConfirmVacation,
  useDeleteVacation,
  useGetAllVacations,
  useGetPrintVacation,
  useGetVacation,
  useRejectVaction,
} from "../../../../../hooks/fetchers/Vacations.jsx";
import DisplayStatus from "../../../../../Components/DisplayStatus";
import { FormModal } from "../../../PlanModel/components/FormModal.jsx";
import { SearchComponent } from "../../../../../Components/SearchComponent/SearchComponent.jsx";
import useCustomModal from "../../../../../Components/Modals/hooks/useCustomModal.jsx";
import useSuccessfullModal from "../../../../../Components/Modals/hooks/useSuccessfullModal.jsx";
import CustomModal from "../../../../../Components/Modals/CustomModal.jsx";
import SuccessfullModal from "../../../../../Components/Modals/SuccessfullModal.jsx";
import useCommentModel from "../../../../../Components/Modals/hooks/useCommentModel.jsx";
import CommentModel from "../../../../../Components/Modals/CommentModel.jsx";
import { Modal, NavDropdown } from "react-bootstrap";
import { MdDeleteOutline } from "react-icons/md";
import { IoCheckmark, IoCloseOutline } from "react-icons/io5";
import { BsThreeDotsVertical } from "react-icons/bs";
import { useDispatch } from "react-redux";
import {
  EDITMODE,
  setMode,
  SHOWMODE,
} from "../../../../../Components/System/Hr/ShowHolidayComponent/holidayFormSlice.jsx";
import { FiEdit } from "react-icons/fi";
import config from "../../../../../Config/Config.jsx";
import LoadingPage from "../../../../../Components/feedback/LoadingPage.jsx";
import { CheckRole } from "../../../../../helper/utils.jsx";
import { roles } from "../../../../../helper/fetchers/Tasks.jsx";
import useModal from "../../../../../hooks/useModal.jsx";
import PrintHolidayFile from "../../HolidayMangment/printHolidayFile.jsx";
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.js",
  import.meta.url
).toString();
const columns = [
  {
    name: "م",
  },
  {
    name: "اسم الموظف",
  },
  {
    name: "الكود الوظيفي",
  },
  {
    name: "نوع الاجازة",
  },
  {
    name: "تاريخ الاجازة",
  },

  {
    name: "عرض",
  },
  {
    name: "الحاله",
  },
  {
    name: "",
  },
];

export default function VacationsTable() {
  const dispatch = useDispatch();
  const [ShowHoliday, setShowHoliday] = useState(false);
  const [id, setId] = useState(null);
  const [page, setPage] = useState(1);
  const [searchParams, setSearchParams] = useState({});

  const handleChange = (event, value) => {
    setPage(value);
    // setFilterTasksParams({ page: value });
  };
  const { data } = useGetAllVacations({ page, ...searchParams });
  const handleSearch = (value) => {
    if (value) {
      setSearchParams({ search: value });
    } else {
      setSearchParams({});
    }
  };
  return (
    <>
      <div className={`${style.allhoildays} allhoildaysContainer p-4`}>
        <p className="text-mainText text-xl">كل الاجازات المخصصة</p>
        <div className="flex items-center gap-3 my-3 w-100">
        <div className="w-3/4 mx-auto ">
          <SearchComponent
            handleChange={handleSearch}
            placeholder="اسم الموظف"
            border="border !border-[#E4A11B]"
          />
        </div>
        </div>
        <FormModal className="!p-0 !max-h-[400px] h-full" title={"كل الاجازات"}>
          <div className="py-3 overflow-y-auto !max-h-[400px] h-full scrollbar-thin scrollbar-track-mainItems scrollbar-thumb-[#E4A11B]">
            <CustomTable
              // className="!h-[200px]  !overflow-auto"
              columns={columns}
              data={data?.data?.vacations}
              responsive={false}
              paginationProps={{
                count: data?.data?.pages,
                page: page,
                onChange: handleChange,
              }}
            >
              {data?.data?.vacations && data?.data?.vacations?.length > 0
                ? data?.data?.vacations?.map(
                    (
                      item,
                      index
                    ) => (
                      <TableRow
                        className={`my-2 border !border-[#efaa207f] ${
                          index % 2 === 0 ? "bg-mainNavy" : ""
                        }`}
                        key={item?._id}
                      >
                        <TableCell> {(page - 1) * 10 + (index + 1)}</TableCell>
                        <TableCell>{item?.employeeId?.firstName}</TableCell>
                        <TableCell>{item?.employeeId?.employeCode}</TableCell>
                        <TableCell>
                          {item?.vactionType == 0
                            ? "بدون راتب "
                            : item?.vactionType == 1
                            ? "براتب "
                            : "اخري"}
                        </TableCell>
                        <TableCell>
                          {moment(item?.startDate).format("YYYY-MM-DD")}
                        </TableCell>
                        <TableCell>
                          <div
                            onClick={() => {
                              setShowHoliday(true);
                              dispatch(setMode(SHOWMODE));
                              setId(item?._id);
                            }}
                          >
                            <Image
                              src={process.env.PUBLIC_URL + "/icons/view.svg"}
                              className="pointer"
                            />
                          </div>
                        </TableCell>
                        <TableCell>
                          <DisplayStatus status={item?.status} />
                        </TableCell>
                        <TableCell>
                          <NavDropdown
                            title={
                              <BsThreeDotsVertical className="text-[#efaa20]" />
                            }
                            className="fs-5 w-fit"
                            id="collapsible-nav-dropdown"
                          >
                            {+item?.status === 0 ? (
                              <>
                                <PrintVacation item={item} />
                                <NavDropdown.Item
                                  onClick={() => {
                                    setShowHoliday(true);
                                    dispatch(setMode(EDITMODE));
                                    setId(item?._id);
                                  }}
                                  className="text-end  d-flex gap-1  align-items-center hover:rounded-lg"
                                >
                                  <FiEdit className="text-black" />
                                  <span className="text-black">تعديل</span>
                                </NavDropdown.Item>
                              {CheckRole(roles["admin"]) || CheckRole(roles["manager"]) ? <>
                                <AcceptVacation id={item?._id} />
                                <RejectVacation id={item?._id} />
                                </> : null }
                              {!CheckRole(roles["user"]) ? <>
                                <DeleteVacation id={item?._id} />
                                </> : null }
                              </>
                            ) : (
                              <>
                              <PrintVacation item={item} />
                                <NavDropdown.Item
                                  onClick={() => {
                                    setShowHoliday(true);
                                    dispatch(setMode(EDITMODE));
                                    setId(item?._id);
                                  }}
                                  className="text-end  d-flex gap-1  align-items-center hover:rounded-lg"
                                >
                                  <FiEdit className="text-black" />
                                  <span className="text-black">تعديل</span>
                                </NavDropdown.Item>
                              {!CheckRole(roles["user"]) ? <>
                                <DeleteVacation id={item?._id} />
                              </> : null }
                              </>
                            )}
                          </NavDropdown>
                        </TableCell>
                      </TableRow>
                    )
                  )
                : null}
            </CustomTable>

            {/* <Progress /> */}
          </div>
        </FormModal>
      </div>
      {ShowHoliday && id ? (
        <ShowHolidayComponent
          ShowHoliday={ShowHoliday}
          handleClose={() => setShowHoliday(false)}
          id={id}
        />
      ) : null}
    </>
  );
}

const DeleteVacation = ({ id }) => {
  const { handleShow, handleClose, show } = useCustomModal();
  const {
    handleShowSuccess,
    handleShowError,
    handleCloseSuccess,
    handleCloseError,
    showSuccess,
    showError,
  } = useSuccessfullModal();
  const { mutate } = useDeleteVacation(()=>{
    handleShowSuccess()
    handleClose()
  }, handleShowError);
  return (
    <>
      <NavDropdown.Item
        onClick={handleShow}
        className="text-end  d-flex gap-1  align-items-center hover:rounded-lg"
      >
        <MdDeleteOutline className="text-black" />
        <span className="text-black">حذف</span>
      </NavDropdown.Item>
      <CustomModal
        title="التاكيد"
        show={show}
        message={"هل انت متاكد من حذف طلب الاجازة"}
        handleSave={() => {
          mutate(id);
        }}
        handleClose={handleClose}
      />
      <SuccessfullModal
        handleClose={handleCloseSuccess}
        message={"تم حذف طلب الاجازة بنجاح"}
        show={showSuccess}
      />
      <SuccessfullModal
        handleClose={handleCloseError}
        message={"حدث خطأ ما"}
        show={showError}
        status="error"
      />
    </>
  );
};
const AcceptVacation = ({ id }) => {
  const { handleShow, handleClose, show } = useCustomModal();
  const {
    handleShowSuccess,
    handleShowError,
    handleCloseSuccess,
    handleCloseError,
    showSuccess,
    showError,
  } = useSuccessfullModal();
  const { mutate } = useConfirmVacation(handleShowSuccess, handleShowError);
  return (
    <>
      <NavDropdown.Item
        onClick={handleShow}
        className="text-end d-flex gap-1  align-items-center hover:rounded-lg hover:text-mainNavy"
      >
        <IoCheckmark size={18} className="text-black" />

        <span className="text-black">قبول</span>
      </NavDropdown.Item>

      <CustomModal
        title="التاكيد"
        show={show}
        message={"هل انت متاكد من قبول طلب الاجازة"}
        handleSave={() => {
          mutate(id);
          handleClose();
        }}
        handleClose={handleClose}
      />
      <SuccessfullModal
        handleClose={handleCloseSuccess}
        message={"تم قبول طلب الاجازة بنجاح"}
        show={showSuccess}
      />
      <SuccessfullModal
        handleClose={handleCloseError}
        message={"حدث خطأ ما"}
        show={showError}
        status="error"
      />
    </>
  );
};
const RejectVacation = ({ id }) => {
  const { handleShow, handleClose, show } = useCustomModal();
  const { showComment, handleCloseComment, handleShowComment } =
    useCommentModel();
  const {
    handleShowSuccess,
    handleShowError,
    handleCloseSuccess,
    handleCloseError,
    showSuccess,
    showError,
  } = useSuccessfullModal();
  const { mutate } = useRejectVaction(handleShowSuccess, handleShowError, id);
  return (
    <>
      <NavDropdown.Item
        onClick={handleShow}
        className="text-end d-flex gap-1  align-items-center hover:rounded-lg hover:text-mainNavy"
      >
        <IoCloseOutline size={18} className="text-black" />

        <span className="text-black">رفض</span>
      </NavDropdown.Item>

      <CustomModal
        title="التاكيد"
        show={show}
        message={"هل انت متاكد من رفض طلب الاجازة"}
        handleSave={() => {
          handleShowComment();
          handleClose();
        }}
        handleClose={handleClose}
      />
      <CommentModel
        show={showComment}
        message={"اترك سبب التعليق ...."}
        name={"resonRejected"}
        handleClose={handleCloseComment}
        setComment={() => {}}
        handleSave={(data) => {
          // console.log(data);
          mutate(data);
          handleCloseComment();
        }}
      />
      <SuccessfullModal
        handleClose={handleCloseSuccess}
        message={"تم رفض طلب الاجازة بنجاح"}
        show={showSuccess}
      />
      <SuccessfullModal
        handleClose={handleCloseError}
        message={"حدث خطأ ما"}
        show={showError}
        status="error"
      />
    </>
  );
};


const PrintVacation = ({item}) => {
  // const [pdfLoading, setPdfLoading] = useState(false);
  // const [pdfUrl, setPdfUrl] = useState("");

  // const getFinancialPdf = async (id) => {
  //   setPdfLoading(true);
  //   try {
  //     const response = await fetch(
  //       `${config.apiGateway?.URL}/vacation/pdf/${id}`,
  //       {
  //         method: "GET",
  //         headers: {
  //           "Content-Type": "application/pdf",
  //           authes: `BSA__${Cookies.get("accessToken")}`,
  //         },
  //       }
  //     );
  //     if (response.ok) {
  //       const blob = await response.blob();
  //       const url = URL.createObjectURL(blob);
  //       setPdfUrl(url);
  //     } else {
  //       console.error("Failed to fetch PDF:", response.statusText);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching PDF:", error);
  //   } finally {
  //     setPdfLoading(false);
  //   }
  // };
  const { data , isLoading , isError} = useGetVacation(item?._id);

  const {
    open: openPdf,
    handleOpenModal: handleOpenPdfModal,
    handleCloseModal: handleClosePdfModal,
  } = useModal();
  return (<>
  <NavDropdown.Item
        onClick={handleOpenPdfModal}
        className="text-end  d-flex gap-1  align-items-center hover:rounded-lg"
      >
        <FaPrint className="text-black" />
        <span className="text-black">طباعة</span>
      </NavDropdown.Item>

      {!isLoading && !isError && data ? (
        <PrintHolidayFile
          show={openPdf}
          onHide={handleClosePdfModal}
          data={data?.data?.vacation}
        />
      ) : null}


    {/* {(!isLoading && !isError) ?
    <a href={data?.data} download={"example.pdf"}>ffff</a>
    : null} */}

{/* {pdfLoading ? (
        <LoadingPage />
      ) : (
        pdfUrl && (
          <Modal
            show={true}
            onHide={() => setPdfUrl("")}
            className="h-screen bg-black/50"
            contentClassName="bg-transparent"
          >
            <Modal.Body className="h-full w-full min-w-min">
              <a
                href={pdfUrl}
                download={"file.pdf"}
                className="mb-3 mx-auto block text-center !text-[#efaa20] hover:!text-[#efaa20] border !border-[#efaa20] w-fit px-4 py-1 rounded-full bg-mainNavy"
              >
                تحميل المستند
              </a>
              <Document
                file={pdfUrl}
                onLoadSuccess={() => setPdfLoading(false)}
                className={"w-full min-w-min"}
              >
                <Page pageNumber={1} className={"w-full"} />
              </Document>
            </Modal.Body>
          </Modal>
        )
      )} */}


      {/* <CustomModal
        title="التاكيد"
        show={show}
        message={"هل انت متاكد من طباعة الاجازة"}
        handleSave={() => {
          // mutate(id);
        }}
        handleClose={handleClose}
      />
      <SuccessfullModal
        handleClose={handleCloseSuccess}
        message={"تم طباعة الاجازة بنجاح"}
        show={showSuccess}
      />
      <SuccessfullModal
        handleClose={handleCloseError}
        message={"حدث خطأ ما"}
        show={showError}
        status="error"
      /> */}






  </>)
}