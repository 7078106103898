import { useState } from "react";

import { Button, Form } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Image from "../../../../Components/Image";
import { useForm } from "react-hook-form";
import { IoEyeOffOutline, IoEyeOutline } from "react-icons/io5";
import Cookies from "js-cookie";
import Progress from "../../../../Components/Progress";
import { userLogin } from "../../../../helper/fetchers/Login";
import { socket } from "../../../../utiltis/SockietHelper";
import { jwtDecode } from "jwt-decode";
import AuthWrapper from "../components/AuthWrapper";
import AuthCard from "../components/AuthCard";
import SubmitButton from "../components/SubmitButton";

const SystemSignIn = () => {
  const [openEye, setOpenEye] = useState(false);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { isLoading },
  } = useForm({
    mode: "all",
    defaultValues: {
      userName: "",
      password: "",
    },
  });
  const togglePassword = () => {
    setOpenEye(!openEye);
  };

  const onSubmit = async (userData) => {
    try {
      const { data } = await userLogin(userData);

      if (data.success) {
        const token = data.accessToken;
        const decoded = jwtDecode(token);
        Cookies.set("country", decoded.payload.country);
        Cookies.set("department", decoded.payload.department);
        Cookies.set("firstName", decoded.payload.firstName);
        Cookies.set("userId", decoded.payload.id);
        Cookies.set("lastName", decoded.payload.lastName);
        Cookies.set("role", decoded.payload.role);
        Cookies.set("status", decoded.payload.status);
        Cookies.set("accessToken", token);
        socket.auth = { token };
        socket.connect();
        Cookies.set("socketId", socket.id);
        navigate("/System/index");
      }
    } catch ({ response }) {
      // toast.error(response?.data?.message);
    } finally {
      // write any code here
    }
  };

  return (
    <AuthWrapper>
      <AuthCard>
        <Form onSubmit={handleSubmit(onSubmit)} className="w-75 h-100">
          <Image
            src="https://bsa2011.com/images/systemLogin.gif"
            style={{ width: "379px", height: "214px" }}
            alt="logo image"
            className={"pointer-events-none"}
          />

          <Form.Group>
            <Form.Label className="text-black">أسم المستخدم</Form.Label>
            <input
              {...register("userName", {
                required: "يجب إدخال اسم المستخدم",
              })}
              className="form-control  text-black placeholder:!text-black bg-transparent mb-4 h-[48.99px] border !border-[#94713E] hover:!border-[#94713E]  focus:!border-[#94713E]  focus:bg-transparent active:bg-transparent"
              placeholder="ادخل اسم المستخدم "
              autoComplete="username"
            />
          </Form.Group>
          <Form.Group>
            <Form.Label className="text-black "> كلمة المرور</Form.Label>
            <div className="form-control p-0 flex items-center !bg-transparent mb-4 h-[48.99px] border !border-[#94713E] hover:!border-[#94713E]  focus:!border-[#94713E] focus:bg-transparent active:bg-transparent">
              <input
                {...register("password", {
                  required: "يجب إدخال كلمة السر",
                })}
                className="form-control text-black placeholder:!text-black bg-transparent h-full !shadow-none focus:shadow-none  !border-none !outline-none"
                type={openEye ? "text" : "password"}
                placeholder="ادخل كلمه مرور المستخدم"
                autoComplete="current-password"
              />
              <button className="p-2" type="button" onClick={togglePassword}>
                {openEye ? (
                  <IoEyeOutline color="black" />
                ) : (
                  <IoEyeOffOutline color="black" />
                )}
              </button>
            </div>
          </Form.Group>
          <div className="w-full text-end -mt-3">
            <Link
              to={"/forget-password"}
              className="text-[#6A0411] hover:!text-[#6A0411] text-sm font-normal"
            >
              {"نسيت كلمه السر ؟"}
            </Link>
          </div>

          <SubmitButton>
            {isLoading ? <Progress isSmall /> : " الدخول الي الحساب"}
          </SubmitButton>
        </Form>
      </AuthCard>
    </AuthWrapper>
  );
};

export default SystemSignIn;
