import React from "react";
import DashboardPieChart from "../../../../../Components/charts/DashboardPieChart";
import { twMerge } from "tailwind-merge";

const myColors = [
  "#14B8A6",
  "#F59E0B",
  "#EC4899",
  "#3B82F6",
  "#6C4DC5",
  "#f33d4c",
  "#06FF00",
  "#0D9488",
  "#D97706",
  "#BE185D",
  "#1D4ED8",
  "#553C9A",
  "#D32F2F",
  "#32CD32",
  "#115E59",
  "#B45309",
  "#9D174D",
  "#1E40AF",
  "#3A265C",
  "#B71C1C",
  "#228B22",
];

const getColor = (index) => {
  return myColors[index % myColors.length];
};

const CustomChart = ({
  data,
  title,
  chartTitle,
  bodyProps,
  rowProps,
  showChart,
  labelClass,
  mainColTitle,
  showPercentage = true,
}) => {
  return (
    <ChartWrapper>
      <ChartHeader
        chartTitle={chartTitle}
        rowProps={rowProps}
        showChart={showChart}
        labelClass={labelClass}
        mainColTitle={mainColTitle}
        showPercentage={showPercentage}
      />
      <ChartBody
        data={data}
        title={title}
        bodyProps={bodyProps}
        rowProps={rowProps}
        showChart={showChart}
        labelClass={labelClass}
        showPercentage={showPercentage}
      />
    </ChartWrapper>
  );
};

const ChartWrapper = ({ children }) => {
  return <table className="w-full ">{children}</table>;
};

const ChartHeader = ({
  chartTitle,
  labelClass,
  showChart = true,
  mainColTitle = "",
  showPercentage,
}) => {
  return showChart ? (
    <thead>
      <tr className="h-8 text-mainText text-sm border-b !border-[#E5E5EF]/50">
        <td>
          <p>{chartTitle}</p>
        </td>
        <td className={`${labelClass}`}>
          <p className="mx-3">{mainColTitle}</p>
        </td>
        <td>
          <p>العدد</p>
        </td>
        {showPercentage ? (
          <td>
            <p>النسبة</p>
          </td>
        ) : null}
      </tr>
    </thead>
  ) : (
    <thead>
      <tr className="h-8 text-mainText text-sm border-b !border-[#E5E5EF]/50">
        <td className={`${labelClass}`}>
          <p className="mx-3">الاسم</p>
        </td>
        <td>
          <p>العدد</p>
        </td>
        <td>
          <p>النسبة</p>
        </td>
      </tr>
    </thead>
  );
};

const Item = ({ children, rowProps }) => {
  return (
    <tr
      className={twMerge(
        `h-8 text-mainText text-sm  `,
        rowProps?.className || ""
      )}
      {...rowProps}
    >
      {children}
    </tr>
  );
};

const ChartBody = ({
  data,
  title,
  bodyProps,
  rowProps,
  showChart = true,
  labelClass,
  showPercentage,
}) => {
  data = data?.map((item, index) => ({ ...item, color: getColor(index) }));

  const colors = data?.map(({ color }) => color);
  const labels = data?.map(({ label }) => label);
  const values = data?.map(({ value }) => value);

  return (
    <tbody {...bodyProps}>
      {data?.map(({ id, label, value, percentage, color }, index) => {
        return (
          <Item rowProps={rowProps} key={index}>
            {showChart && index === 0 ? (
              <td rowSpan={data.length} className="xl:w-48 w-36">
                <DashboardPieChart
                  label={title}
                  colors={colors}
                  width={230}
                  labels={labels}
                  series={values}
                />
              </td>
            ) : null}

            <td className={twMerge("min-w-[120px]", labelClass || "")}>
              <div className="flex items-center gap-2 text-xs py-1.5">
                <div
                  className="!w-3 !h-3 rounded-full"
                  style={{ backgroundColor: color }}
                ></div>
                <p className="w-full  text-mainText text-xs">{label}</p>
              </div>
            </td>
            <td className="w-[120px]">
              <p className="text-mainText text-xs">{value}</p>
            </td>
            {showPercentage ? (
              <td className="text-mainText max-w-[200px] text-xs">
                {percentage + " %"}
              </td>
            ) : null}
          </Item>
        );
      })}
    </tbody>
  );
};

export default CustomChart;
