import { twMerge } from "tailwind-merge";

const SectionElement = ({
  title = " ",
  disc = "",
  className = "",
  titleClassName = "",
}) => {
  return (
    <>
      <div className={twMerge("flex items-center gap-3", className)}>
        <p className={twMerge("w-20", titleClassName)}>{title}</p>
        <div className="bg-[#F9EBD5] py-2 px-4 w-full min-h-[2rem]">
          <p>{disc}</p>
        </div>
      </div>
    </>
  );
};
export default SectionElement;
