import React, { useEffect } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import style from "../EditExpenses/EditExpenses.module.css";
import "../EditExpenses/EditExpenses.css";
import { useNavigate } from "react-router-dom";
import Input from "../../../FormHandler/Input";
import Select from "../../../FormHandler/Select";
import { useState } from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
import Progress from "../../../Progress";
import AddAttachment from "../../AddAttachment";
import SuccessfullModal from "../../../Modals/SuccessfullModal";
import useSuccessfullModal from "../../../Modals/hooks/useSuccessfullModal";
import { useGetAllClauses } from "../../../../hooks/fetchers/Clause";
import { useUpdateExpensesItemReport } from "../../../../hooks/fetchers/Expenses-items-reports";
import LoadingPage from "../../../feedback/LoadingPage";

const EditExpensesReport = ({
  selectedItem,
  editExpenses,
  setEditExpenses,
}) => {
  const navigate = useNavigate();
  const {
    handleShowSuccess,
    handleShowError,
    handleCloseSuccess,
    handleCloseError,
    showSuccess,
    showError,
  } = useSuccessfullModal();
  const { mutate, isLoading } = useUpdateExpensesItemReport(
    handleShowSuccess,
    handleShowError,
    selectedItem?._id
  );
  const [dueDate, setDueDate] = useState();
  const [createdDate, setCreatedDate] = useState("");
  const [description, setDescription] = useState("");
  const [clauseID, setClauseId] = useState({});
  const [name, setName] = useState("");
  const [amount, setAmount] = useState("");
  const [totalAmount, setTotalAmount] = useState("");
  const [textAmount, setTextAmount] = useState("");
  // const [code, setCode] = useState("");
  const [attachment, setAttachment] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  useEffect(() => {
    if (selectedItem) {
      if (selectedItem.createdAt) {
        setCreatedDate(new Date(selectedItem.createdAt));
      }
      setDescription(selectedItem.description);
      setName(selectedItem.name);
      setDueDate(new Date(selectedItem?.dueDate));
      setAmount(selectedItem?.amount);
      setTotalAmount(selectedItem?.totalAmount);
      setTextAmount(selectedItem?.textAmount);
      setClauseId({
        label: selectedItem?.clauseId?.name,
        value: selectedItem?.clauseId?._id,
      });
    }
  }, [selectedItem]);

  const files = attachment ? [...attachment] : [];

  const handleUpdateItems = async () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("name", name);
    formData.append("description", description);
    formData.append("textAmount", textAmount);
    formData.append("amount", amount);
    formData.append("clauseId", clauseID?.value);
    formData.append("dueDate", moment(dueDate).format("YYYY-MM-DD"));
    files.forEach((file) => {
      formData.append(`image`, file);
    });
    const newErrors = [];
    for (var pair of formData.entries()) {
      if (pair[0] === "name" || pair[0] === "description") {
        const value = pair[1];
        if (value.length < 5 || value.length > 500) {
          newErrors.push(
            `${
              pair[0] === "name" ? "اسم الصنف" : "الوصف"
            } يجب ان لا يقل علي 5 احرف ولا يزيد عن 500 حرف`
          );
        }
      }

      if (
        pair[0] === "dueDate" &&
        !moment(pair[1], "YYYY-MM-DD", true).isValid()
      ) {
        newErrors.push("تاريخ الانشاء غير صحيح");
      }

      setErrors(newErrors);
      setLoading(false);
    }
    if (newErrors.length === 0) {
      mutate(formData);
    }
  };
  const { data: clauses } = useGetAllClauses();

  return isLoading ? (
    <LoadingPage />
  ) : (
    <div className="EditExpenses">
      <Modal
        className="systemModal EditExpenses"
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        onHide={() => setEditExpenses(false)}
        show={editExpenses}
      >
        <Modal.Body className="w-full">
          <div className="p-1 mx-auto mb-3 edit-header mt-1 w-50">
            <p className="golden text-center">تعديل فى نقارير المصروفات </p>
          </div>
          <fieldset className="mx-auto border-golden w-90 p-4">
            <legend className="text-center text-mainText">تعريفات</legend>
            <Input
              label={"1- اسم التقرير"}
              placeholder="اكتب اسم التقرير"
              value={name}
              defaultValue={name}
              onChange={(e) => setName(e.target.value)}
            />
          </fieldset>
          <fieldset className={`${style.description} mt-3`}>
            <legend className="text-center text-mainText">الوصف</legend>
            <div className="row p-3">
              <div className="col-md-6 mb-4">
                <Select
                  OptionbackgroundColor={"#2B2B40"}
                  options={clauses?.map((x) => ({
                    label: x?.name,
                    value: x?._id,
                  }))}
                  value={clauseID}
                  onChange={(e) => setClauseId(e)}
                  placeholder="اختار البند"
                  label="1- البند"
                />
              </div>
              <div className="col-md-12 mb-4">
                <Form.Group>
                  <Form.Label className="text-mainText">الوصف</Form.Label>
                  <textarea
                    className="form-control"
                    placeholder="اكتب الوصف المرفق ..."
                    rows={5}
                    cols={30}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </Form.Group>
              </div>
              <div className="col-md-8   mb-4">
                <Form.Group className="d-flex flex-column">
                  <Form.Label className="text-mainText">
                    تاريخ الاستحقاق
                  </Form.Label>
                  <DatePicker
                    selected={dueDate}
                    placeholderText=" ادخل تاريخ الرخصة "
                    onChange={(date) => setDueDate(date)}
                    dateFormat="dd-MM-yyyy"
                    className="w-100 form-control"
                  />
                </Form.Group>
              </div>
              <div className="col-md-4 mb-4">
                <Input
                  placeholder={"ادخل المبلغ"}
                  label={"المبلغ"}
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                />
              </div>
              <div className="col-md-6 mb-4">
                <Input
                  placeholder={"1000"}
                  label={"اجمالى المبلغ"}
                  value={totalAmount}
                  onChange={(e) => setTotalAmount(e.target.value)}
                />
              </div>
              <div className="col-md-6 mb-4">
                <Input
                  placeholder={""}
                  label={"اجمالى المبلغ كتابة"}
                  value={textAmount}
                  onChange={(e) => setTextAmount(e.target.value)}
                />
              </div>
            </div>
          </fieldset>
          <fieldset
            className={`${style.Attachment} mt-4 p-2 flex items-center justify-start gap-4 overflow-x-auto custom-scrollbar`}
          >
            <legend className="text-center text-mainText">الملف المرفق</legend>
            <AddAttachment
              attachment={attachment}
              setAttachment={setAttachment}
              multi={true}
            />
            {attachment &&
              Array.from(attachment).map((image, index) => (
                <img
                  key={index}
                  src={URL.createObjectURL(image)}
                  className="pointer w-ful h-[90px] object-cover rounded-lg"
                  alt="view icon"
                />
              ))}

            {selectedItem?.image &&
              selectedItem?.image.map((image, index) => (
                <img
                  key={index}
                  src={`${image}`}
                  className="pointer w-ful h-[90px] object-cover rounded-lg"
                  alt="view icon"
                />
              ))}
          </fieldset>

          <div className="d-flex my-5 w-90  justify-content-end">
            <Button
              disabled={isLoading}
              onClick={() => {
                handleUpdateItems();
              }}
              className="sumbmitAddUpdateUser"
            >
              حفظ
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      <SuccessfullModal
        handleClose={() => {
          handleCloseSuccess();
          setEditExpenses(false);
          // setOpenDisplayDetails(false);
          // navigate("/System/Accounating/index");
        }}
        message={"تم التعديل بنجاح"}
        show={showSuccess}
      />
      <SuccessfullModal
        handleClose={handleCloseError}
        message={"حدث خطأ ما"}
        show={showError}
        status="error"
      />
    </div>
  );
};

export default EditExpensesReport;
