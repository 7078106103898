import { OptionsButtonVacations } from "./OptionsButtonVacations";

export const SubCategoryBtn = ({ title, active, setActive, index }) => {
  return (
    <div
      className={`flex w-full justify-between items-center px-2 text-[#ffffff80] border hover:!border-[#EFAA20] text-base ${
        active === index ? "  !border-transparent" : " !border-transparent"
      }`}
    >
      <button type="button" onClick={() => setActive(index)} className="w-full">
        <p className="w-full text-mainText text-right my-3">{title}</p>
      </button>
      <OptionsButtonVacations id={index} />
    </div>
  );
};
