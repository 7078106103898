import myAxiosInstance, { myAxios, myAxiosJson } from "../https";
import { toast } from "react-toastify";

export const getAllVactions = (params) => myAxios("vacation",{params}).catch(error=>{
    toast.error(error?.response?.data?.message)
});
export const getVactionById = (id) => myAxios(`vacation/${id}`).catch(error=>{
    toast.error(error?.response?.data?.message)
});
export const getPrintVactionById = (id) => myAxios(`vacation/pdf/${id}`).catch(error=>{
    toast.error(error?.response?.data?.message)
});
export const createVaction = (data) => myAxiosInstance.post(`vacation`, data);
export const updateVaction = (vacationId,data) => myAxiosInstance.patch(`vacation/${vacationId}`, data);
export const deleteVaction = (vacationId) => myAxiosInstance.patch(`vacation/delete/${vacationId}`);
export const confirmVaction = (vacationId) => myAxiosInstance.patch(`vacation/confirm/${vacationId}`);
export const rejectVaction = (vacationId,data) => myAxiosJson.patch(`vacation/rejected/${vacationId}`, data);
