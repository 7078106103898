import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  useGetAllTasksManager,
  useGetStatsPurchase,
} from "../../../hooks/fetchers/TechnicalSupportPurcheseOrder";
import { TechnicalSupportContext } from "./components/TechnicalSupportProvider";
import LoadingPage from "../../../Components/feedback/LoadingPage";
import TechnicalSupportShow from "./components/TechnicalSupportShow";
import TasksStats from "./components/TasksStats";
import TechnicalSupportTable from "./components/TechnicalSupportTable";

const TechnicalSupportHelpRequest = () => {
  const [search, setSearch] = useState("");

  const [page, setPage] = useState(0);
  const { type, status } = useParams();

  const taskStatus =
    status === "waiting"
      ? "0"
      : status === "inprogress"
      ? "1"
      : status === "done-check"
      ? "4"
      : status === "complete"
      ? "5"
      : null;

  const { data, isLoading, isError } = useGetAllTasksManager({
    status: taskStatus,
    task: type,
    page,
    search,
  });

  const { data: statsData, isLoading: isStatsLoading } = useGetStatsPurchase({
    type: type,
  });

  const { showTasksOpen } = useContext(TechnicalSupportContext);

  return isStatsLoading ? (
    <LoadingPage />
  ) : showTasksOpen ? (
    <TechnicalSupportShow />
  ) : (
    <div className="h-full bg-mainItems rounded-[22px]">
      <TasksStats data={statsData} />
      <TechnicalSupportTable
        data={data}
        isLoading={isLoading}
        isError={isError}
        search={search}
        setSearch={setSearch}
        page={page}
        setPage={setPage}
      />
    </div>
  );
};

export default TechnicalSupportHelpRequest;
