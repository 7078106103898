import { toast } from "react-toastify";
import myAxiosInstance, { myAxios } from "../https";

export const addRequest = (data) => myAxiosInstance.post("/request/", data);

export const getRequests = (params) =>
  myAxiosInstance("request", { params })
    .then(({ data }) => data)
    .catch((error) => {
      toast.error(error?.response?.data?.message);
    });

export const getRequestsReportMonthly = () =>
  myAxiosInstance("request/report/monthly")
    .then(({ data }) => {
      const transformedData = {};
      transformedData.design = Object.keys(data?.SUPERVISING)?.map(
        (key) => data?.DESIGN[key]
      );
      transformedData.supervising = Object.keys(data?.SUPERVISING)?.map(
        (key) => data?.SUPERVISING[key]
      );
      return { ...transformedData };
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message);
    });

export const getAllRequests = () =>
  myAxiosInstance("/request/").catch((error) => {
    toast.error(error?.response?.data?.message);
  });
export const getAllRequestReports = () =>
  myAxiosInstance("request/reports")
    .then(({ data }) => data)
    .catch((error) => {
      toast.error(error?.response?.data?.message);
    });
export const getAllMainReportsPage = () =>
  myAxiosInstance("statics/reports/main")
    .then(({ data }) => data)
    .catch((error) => {
      toast.error(error?.response?.data?.message);
    });

export const getAllRequestsStats = () =>
  myAxiosInstance("/request/stats/status").catch((error) => {
    toast.error(error?.response?.data?.message);
  });

export const getRequestsWithProjectType = (type, page) =>
  myAxiosInstance(`/request?page=${page}&projectType=${type}`).catch(error=>{
    toast.error(error?.response?.data?.message)
});

export const getDesignRequestsWithStatus = (status, page) =>
  myAxiosInstance(`/request?page=${page}&status=${status}&projectType=1`).catch(error=>{
    toast.error(error?.response?.data?.message)
});

export const getReviewRequestsWithStatus = (status, page) =>
  myAxiosInstance(`/request?page=${page}&status=${status}&projectType=2`).catch(error=>{
    toast.error(error?.response?.data?.message)
});
export const getDesignRequestsWithid = (id) =>
  myAxiosInstance(`/request/${id}`).catch(error=>{
    toast.error(error?.response?.data?.message)
});
export const AcceptRequestwithId = (id, data) =>
  myAxiosInstance.patch(`/request/confirm/${id}`, data);
export const rejecetRequestwithId = (id, data) =>
  myAxiosInstance.patch(`/request/rejected/${id}`, data);
export const finalApprove = (id) =>
  myAxiosInstance.patch(`/request/final/confirm/${id}`);
export const softDeleteRequst = (data) =>
  myAxios.patch(`/request/delete`, data);

export const updateRequst = (id, data) =>
  myAxiosInstance.patch(`/request/${id}`, data);

//   http://bsa2011.com:5000/api/v1/request?status=0&projectType=1
