import moment from "moment";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import useSuccessfullModal from "../../../Modals/hooks/useSuccessfullModal";
import useModal from "../../../../hooks/useModal";
import { useForm } from "react-hook-form";
import { getMeeting } from "../../../../helper/fetchers/meetings";
import { useUpdateMeeting } from "../../../../hooks/fetchers/Meetings";
import { getAllUsers } from "../../../../helper/fetchers/Users";
import Cookies from "js-cookie";
const meetingType = [
  {
    value: 1, // 1
    label: "شامل",
  },
  {
    value: 2, // 2
    label: "مع شخص",
  },
];
const meetingCountries = [
  {
    value: 1, // 1
    label: "السعودية",
  },
  {
    value: 2, // 2
    label: "مصر",
  },
];
const EditMeetingContext = createContext(null);
const EditMeetingProvider = ({ children, events, id }) => {
  const {
    control,
    watch,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const [oldSelectedUsers, setOldSelectedUsers] = useState([]);
//   const [allUser, setAllUser] = useState([]);
  const [newUsers, setNewUsers] = useState([]);
  const [message, setMessage] = useState("حدث خطأ ما");
  const [meetingPlace, setMeetingPlace] = useState("10:00");
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [initialValues, setInitialValues] = useState({});
  const { show, handleOpenModal, handleCloseModal } = useModal();

  useEffect(() => {
    const fetchMeetingData = async () => {
      const res = await getMeeting(id);
      console.log(res, "res===");
      const initialData = {
        country: meetingCountries.find((item) => +item.value === +res.country),
        meetingType: meetingType.find(
          (item) => +item.value === +res.meetingType
        ),
        descraption: res.descraption,
        typeMeeting: res.typeMeeting,
        startDate: new Date(res?.startDate),
        start: res.start,
        end: res.end,
        meetingLink: res.meetingLink,
      };
      setSelectedUsers(res?.employeId?.map((x) => ({ _id: x })));
      setOldSelectedUsers(res?.employeId?.map((x) => ({ _id: x })));
      setInitialValues(initialData);
      setMeetingPlace(res?.typeMeeting === 1 ? "online" : "offline");
      reset(initialData);
    };

    if (id) {
      fetchMeetingData();
    }
  }, [id, reset]);





  const selectedEvent = events?.find((item) => item?._id === id);
useEffect(()=>{
    if(selectedEvent?.employeId){
        setNewUsers(selectedEvent?.employeId)
    }

},[selectedEvent])
  // console.log(selectedUsers, "=====");
  const {
    handleShowSuccess,
    handleShowError,
    handleCloseSuccess,
    handleCloseError,
    showSuccess,
    showError,
  } = useSuccessfullModal();
  const {
    mutate: updateMutation,
    isError,
    error,
    isLoading: updateIsLoading,
  } = useUpdateMeeting(id, handleShowSuccess, handleShowError);

  const manipulateData = (data) => {
    const updatedData = {};
    Object.keys(data).forEach((key) => {
      if (JSON.stringify(data[key]) !== JSON.stringify(initialValues[key])) {
        updatedData[key] = data[key];
      }
    });
    if (updatedData.country) {
      updatedData.country = updatedData.country.value;
    }
    if (updatedData.meetingType) {
      updatedData.meetingType = data.meetingType.value;
    }
    if (updatedData.startDate) {
      updatedData.startDate = moment(updatedData.startDate).format(
        "YYYY-MM-DD"
      );
    }
    if (updatedData.start) {
      updatedData.start = moment(updatedData.start).format("hh:mm A");
    }
    if (updatedData.end) {
      updatedData.end = moment(updatedData.end).format("hh:mm A");
    }
    if (selectedUsers?.length !== oldSelectedUsers?.length) {
      updatedData.employeId = selectedUsers?.map((user) => user._id);
    }
    return updatedData;
  };

  const onSubmit = (data) => {
    data = manipulateData(data);
    updateMutation(data);
  };

//   const getUsers = useCallback(async () => {
//     const res = await getAllUsers();
//     if (res?.allUsers) {
//     //   setAllUser(res?.allUsers);
//       setNewUsers(
//         res?.allUsers?.filter(
//           (user) =>
//             selectedUsers &&
//             selectedUsers.some((selectedUser) => selectedUser._id === user._id)
//         )
//       );
//     }
//   }, [selectedUsers]);

//   useEffect(() => {
//     getUsers();
//   }, [getUsers]);

//   const setAllNewUsers = useCallback(() => {
//     setNewUsers(
//       allUser?.filter(
//         (user) =>
//           selectedUsers &&
//           selectedUsers.some((selectedUser) => selectedUser._id === user._id)
//       )
//     );
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [selectedUsers?.length]);
//   useEffect(() => {
//     setAllNewUsers();
//   }, [setAllNewUsers]);

  useEffect(() => {
    if (isError) {
      if (error.response.data.validationErrors) {
        setMessage(error.response.data.message);
      } else {
        setMessage(error.response.data.message);
      }
    }
  }, [isError, error]);

  const createdByMe = Cookies.get("userId") === selectedEvent?.createdBy;
  console.log("newUsers", newUsers);

  return (
    <EditMeetingContext.Provider
      value={{
        createdByMe,
        updateIsLoading,
        show,
        message,
        handleCloseModal,
        handleOpenModal,
        selectedUsers,
        setSelectedUsers,
        showSuccess,
        showError,
        handleCloseSuccess,
        handleCloseError,
        onSubmit,
        handleSubmit,
        control,
        errors,
        watch,
        selectedEvent,
        newUsers,
        meetingPlace,
        setMeetingPlace
      }}
    >
      {children}
    </EditMeetingContext.Provider>
  );
};

export const useEditMeetingContext = () => {
  const ctx = useContext(EditMeetingContext);
  if (!ctx) {
    throw Error("You Must Use inside provider");
  }
  return ctx;
};

export default EditMeetingProvider;
