import SuccessfullModal from "../../../../../../Components/Modals/SuccessfullModal";
import { useGetSubServiceById, useUpdateSubService } from "../../../../../../hooks/fetchers/SubService";
import AddModal from "../../../AddModal";
import useSuccessfullModal from "../../../../../../Components/Modals/hooks/useSuccessfullModal";
import LoadingPage from "../../../../../../Components/feedback/LoadingPage";
import { joiResolver } from "@hookform/resolvers/joi";
import { updateSubServiceSchema } from "../../../../../../utiltis/Schemas/SubServices/schema";

export const UpdateSubServiceModal = ({ serviceId, subId, show, handleClose }) => {

    const {
      handleShowSuccess,
      handleShowError,
      handleCloseSuccess,
      handleCloseError,
      showSuccess,
      showError,
    } = useSuccessfullModal();
    const { mutate , isLoading } = useUpdateSubService(
      handleShowSuccess,
      handleShowError,
      serviceId,
      subId
    );
    const {data , isError: subServiceIsError , isLoading: subServiceIsLoading} = useGetSubServiceById(serviceId,subId)
    return (
      <>
      {isLoading ? <LoadingPage/> : null}
      {(!subServiceIsError && !subServiceIsLoading) ? 
      
        <AddModal
          title={"تعديل"}
          show={show}
          handleClose={handleClose}
          name={"name"}
          isLoading={isLoading}
          handleSave={(data) => {
            mutate(data);
            handleClose();
          }}
          defaultValue={data?.name || ""}
          formOptions={{
            resolver: joiResolver(updateSubServiceSchema('ar'))
          }}
        />
      : null}
        <SuccessfullModal
            show={showSuccess}
            message={"تمت التعديل بنجاح"}
            handleClose={handleCloseSuccess}
          />
          <SuccessfullModal
            show={showError}
            message={"حدث خطا ما"}
            handleClose={handleCloseError}
            status="error"
          />
      </>
    );
  };