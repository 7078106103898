import React, { useMemo, useState } from "react";
import Badge from "@mui/material/Badge";
import Box from "@mui/material/Box";
import Dropdown from "react-bootstrap/Dropdown";
import Calculator from "../../../Pages/System/Plans/components/Calculator";
import { Link } from "react-router-dom";
import Image from "../../Image";
import Notifications from "../../../Pages/System/Notifications/Notifications";
import { useGetNotifications } from "../../../hooks/fetchers/Notifications";
import { NotificationsIcon } from "../../../utiltis/Icons";
import { CheckRole } from "../../../helper/utils";
import { roles } from "../../../helper/fetchers/Tasks";
import { FiSun } from "react-icons/fi";
import { FaRegMoon } from "react-icons/fa";
import useModeSwitcher from "../../../hooks/useModeSwitcher";
import { PiCalculator } from "react-icons/pi";
import { AiOutlineMessage } from "react-icons/ai";
import Messages from "../../../Pages/System/Messages/Messages";

const SystemControler = ({ child }) => {
  const { data, isLoading } = useGetNotifications();
  const { isDark, handleChangeTheme } = useModeSwitcher();

  const [showCalculator, setShowCalculator] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);
  const handleShowCalculator = () => setShowCalculator(true);
  const handleCloseCalculator = () => setShowCalculator(false);
  const handleToggleNotifications = () =>
    setShowNotifications(!showNotifications);

  const unreadCount = data?.notifications?.filter(
    (notification) => !notification?.read
  )?.length;

  return (
    <>
      <div className="user-control-model px-4 mb-4 d-flex align-items-center justify-content-between bg-mainItems newBorder">
        <div className="w-100">{child}</div>
        <div className="icons flex items-center gap-3">
          <div
            className=""
            onClick={handleShowCalculator}
            aria-label="calculator"
          >
            <PiCalculator className="text-2xl cursor-pointer text-mainText" />
          </div>

          {/* {(CheckRole(roles["admin"]) || CheckRole(roles["manager"])) && (
            <div className="">
              <Link to={"/System/Chat/index"}>
                <Badge
                  overlap="circular"
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  badgeContent={
                    <Box
                      className="flex justify-center items-center rounded-full w-5 h-5 text-xs ml-2 mb-1"
                      sx={{ bgcolor: "#F1416C", color: "white" }}
                    >
                      {data?.unread_chat || "0"}
                    </Box>
                  }
                  className="w-8"
                >
                  <AiOutlineMessage className="cursor-pointer text-2xl text-mainText" />
                </Badge>
              </Link>
            </div>
          )} */}
          {isDark ? (
            <FiSun
              className="text-xl cursor-pointer"
              onClick={handleChangeTheme}
            />
          ) : (
            <FaRegMoon
              className="text-xl cursor-pointer"
              onClick={handleChangeTheme}
            />
          )}

          {/* {(CheckRole(roles["admin"]) || CheckRole(roles["manager"])) && (
            <Dropdown className="d-inline notification-dropdown">
              <Dropdown.Toggle
                className="bg-transparent p-0 -mt-1"
                id="dropdown-autoclose-true"
              >
                <div>
                  <Badge
                    overlap="circular"
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    badgeContent={
                      <Box
                        className="flex justify-center items-center rounded-full w-5 h-5 text-xs"
                        sx={{ bgcolor: "#F1416C", color: "white" }}
                      >
                        {data?.unread_messages || "0"}
                      </Box>
                    }
                    className="w-8"
                  >
                    
                    <AiOutlineMessage className="cursor-pointer text-2xl text-mainText" />
                  </Badge>
                </div>
                <Dropdown.Menu className="p-1 border !border-[#2B2B40] h-[437px] w-[370px] bg-mainItems rounded-[7px] overflow-y-auto scrollbar-thin scrollbar-track-mainNavy scrollbar-thumb-[#E4A11B]">
                  <div className="flex flex-col gap-1">
                    {!isLoading && data && (
                      <Messages
                        show={showNotifications}
                        data={data?.last_messages}
                      />
                    )}
                  </div>
                </Dropdown.Menu>
              </Dropdown.Toggle>
            </Dropdown>
          )} */}

          <Dropdown className="d-inline notification-dropdown">
            <Dropdown.Toggle
              className="bg-transparent p-0 -mt-1"
              id="dropdown-autoclose-true"
              disabled={data?.notifications?.length <= 0}
            >
              <div aria-label="notification">
                <Badge
                  overlap="circular"
                  anchorOrigin={{ vertical: "top", horizontal: "right" }}
                  badgeContent={
                    <Box
                      className="flex justify-center items-center rounded-full w-5 h-5 text-xs"
                      sx={{ bgcolor: "#F1416C", color: "white" }}
                    >
                      {unreadCount}
                    </Box>
                  }
                >
                  <NotificationsIcon />
                </Badge>
              </div>
              <Dropdown.Menu className="py-2 shadow h-[437px] min-w-[370px] bg-mainItems rounded-[7px] overflow-y-auto scrollbar-thin scrollbar-track-mainNavy scrollbar-thumb-[#E4A11B]">
                <div className="flex flex-col gap-1">
                  {!isLoading && data && (
                    <Notifications
                      show={showNotifications}
                      data={data?.notifications}
                    />
                  )}
                </div>
              </Dropdown.Menu>
            </Dropdown.Toggle>
          </Dropdown>
        </div>
      </div>

      <Calculator show={showCalculator} onClose={handleCloseCalculator} />
    </>
  );
};

export default SystemControler;
