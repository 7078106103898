import { useContext } from "react";
import {
  useAddSubCategory,
  useGetSubCategory,
} from "../../../../../hooks/fetchers/SubCategory";
import { useShowMenu } from "../hooks/useShowMenu";
import { AddNewButton } from "./AddNewButton";
import AddModal from "../../AddModal";
import { FormModal } from "../../../PlanModel/components/FormModal";
import SuccessfullModal from "../../../../../Components/Modals/SuccessfullModal";
import { SubCategoriesList } from "./SubCategoriesList";
import { CategoryContext } from "../context/CategoryContext";
import useSuccessfullModal from "../../../../../Components/Modals/hooks/useSuccessfullModal";
import LoadingPage from "../../../../../Components/feedback/LoadingPage";
import { joiResolver } from "@hookform/resolvers/joi";
import { subCategorySchema } from "../../../../../utiltis/Schemas/SubCategories/schema";
export const SubCategoriesDesign = () => {
  const { categoryId } = useContext(CategoryContext);
  const { data } = useGetSubCategory(categoryId);
  const { show, handleShow, handleClose } = useShowMenu();

  const {
    handleShowSuccess,
    handleShowError,
    handleCloseSuccess,
    handleCloseError,
    showSuccess,
    showError,
  } = useSuccessfullModal();
  const { mutate, isLoading } = useAddSubCategory(
    handleShowSuccess,
    handleShowError,
    categoryId
  );
  return (
    <div className="py-4 px-4 h-full flex flex-col">
      {isLoading ? <LoadingPage /> : null}
      <AddNewButton onClick={handleShow} />
      <AddModal
        title={"اضافة جديدة"}
        show={show}
        handleClose={handleClose}
        name={"name"}
        handleSave={(data) => {
          mutate(data);
          handleClose();
        }}
        formOptions={{
          resolver: joiResolver(subCategorySchema('ar'))
        }}
      />
      <div className="p-2 flex-1">
        <FormModal title={data?.categoryId?.name} className={"h-full"}>
          <SubCategoriesList data={data} categoryId={categoryId} />
          <SuccessfullModal
            show={showSuccess}
            message={"تمت الاضافة بنجاح"}
            handleClose={handleCloseSuccess}
          />
          <SuccessfullModal
            show={showError}
            message={"حدث خطا ما"}
            handleClose={handleCloseError}
            status="error"
          />
        </FormModal>
      </div>
    </div>
  );
};
