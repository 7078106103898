import React from "react";
import Style from "./style.module.css"
function Typing() {
  return (
    <div className={Style["chat-bubble"]}>
      <div className={Style["typing"]}>
        <div className={Style["dot"]}></div>
        <div className={Style["dot"]}></div>
        <div className={Style["dot"]}></div>
      </div>
    </div>
  );
}

export default Typing;
