import { useQuery, useMutation, useQueryClient } from "react-query";

import {
  AcceptRequestwithId,
  addRequest,
  finalApprove,
  getAllMainReportsPage,
  getAllRequestReports,
  getAllRequestsStats,
  getDesignRequestsWithid,
  getRequests,
  getRequestsReportMonthly,
  rejecetRequestwithId,
  softDeleteRequst,
  updateRequst,
} from "../../helper/fetchers/Requests";

export const useGetAllRequests = (params) => {
  return useQuery(["request", params], () => getRequests(params));
};
export const useGetRequestsReportMonthly = (params) => {
  return useQuery(["request", "monthly"], getRequestsReportMonthly);
};

export const useGetAllRequestReports = () => {
  return useQuery(["request", "reports"], getAllRequestReports);
};
export const useGetAllMainReportsPage = () => {
  return useQuery(["request", "reports", "main"], getAllMainReportsPage);
};

export const useGetRequestsWithid = (id) => {
  return useQuery(
    ["request", id],
    () => getDesignRequestsWithid(id).then(({ data }) => data?.request),
    {
      enabled: !!id,
    }
  );
};
export const useGetAllRequestsStats = () => {
  return useQuery(["request", "status"], getAllRequestsStats);
};

export const useAddRequest = (onSuccess, onError) => {
  const queryClient = useQueryClient();
  return useMutation(addRequest, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("request");
      onSuccess(data);
    },
    onError: (error) => {
      onError();
    },
  });
};
export const useUpdateRequestwithId = (id, onSuccess, onError) => {
  const queryClient = useQueryClient();
  return useMutation((data) => updateRequst(id, data), {
    onSuccess: () => {
      queryClient.invalidateQueries("request");
      onSuccess();
    },
    onError: (error) => {
      onError();
    },
  });
};

export const useAcceptRequestwithId = (id, onSuccess, onError) => {
  // const queryClient = useQueryClient();
  return useMutation((data) => AcceptRequestwithId(id, data), {
    onSuccess: () => {
      // queryClient.invalidateQueries("request");
      onSuccess();
    },
    onError: (error) => {
      onError();
    },
  });
};
export const useFinalApprove = (id, onSuccess, onError) => {
  // const queryClient = useQueryClient();
  return useMutation(() => finalApprove(id), {
    onSuccess: () => {
      // queryClient.invalidateQueries("request");
      onSuccess();
    },
    onError: (error) => {
      onError();
    },
  });
};
export const useDeleteRequest = (onSuccess, onError) => {
  const queryClient = useQueryClient();
  return useMutation((data) => softDeleteRequst(data), {
    onSuccess: () => {
      queryClient.invalidateQueries("request");
      onSuccess();
    },
    onError: (error) => {
      onError();
    },
  });
};
export const useRejecetRequestwithId = (id, onSuccess, onError) => {
  // const queryClient = useQueryClient();
  return useMutation((data) => rejecetRequestwithId(id, data), {
    onSuccess: () => {
      // queryClient.invalidateQueries("request");
      onSuccess();
    },
    onError: (error) => {
      onError();
    },
  });
};
