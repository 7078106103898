import { useContext } from "react";
import { Modal } from "react-bootstrap";
import Image from "../../../../Components/Image";
import { ReminderPageProvider } from "../../../../Context/ReminderPage";
import { useGetReminders } from "../hooks/reminderForm";
import DescGroup from "../../../../Components/System/ShowRequest/components/DescGroup";
import moment from "moment";
import EmployeeCard from "../../../../Components/System/Meetings/Card";

const ShowReminder = () => {
  const { showReminder, setShowReminder, reminder } =
    useContext(ReminderPageProvider);
  const { data, isLoading, isError } = useGetReminders(reminder?._id);
  if (!reminder || isError) {
    return null;
  }

  return (
    <ShowReminderWrapper
      show={showReminder}
      onHide={() => setShowReminder(false)}
    >
      <div className=" p-8 flex flex-col gap-4 w-full items-start h-full">
        <DescGroup label="اسم المهمه" desc={reminder?.reminderName} />

        <div className="w-full grid grid-cols-2">
          <DescGroup label="اسم العميل" desc={reminder?.clientName} />
          <DescGroup
            label="نوع المشروع"
            desc={
              reminder?.projectType === "1"
                ? "تصميم"
                : reminder?.projectType === "2"
                ? "الاشراف علي التنفيذ"
                : "اخري"
            }
          />
        </div>
        <DescGroup
          label="الاولويه"
          desc={
            reminder?.periority === "2"
              ? "عادية"
              : reminder?.periority === "4"
              ? "متوسطة"
              : "مهمه"
          }
        />

        {reminder?.assignTo ? (
          <div className="w-full flex gap-4">
            {reminder?.assignTo?.map((rem, index) => (
              <div className="w-1/2">
                <EmployeeCard
                  title={`${rem?.firstName} ${rem?.lastName}`}
                  desc={rem?.role}
                  img={rem?.image}
                  key={index}
                />
              </div>
            ))}
          </div>
        ) : null}

        <div className="flex flex-col gap-4 w-full">
          <p className="text-mainText font-normal text-base">التفاصيل</p>
          <div className="w-full border !border-[#efaa20] rounded-md min-h-[40dvh] p-2">
            <div dangerouslySetInnerHTML={{ __html: reminder?.details }} />
          </div>
        </div>
        <div className="w-full grid grid-cols-2">
          <DescGroup
            label="تاريخ التذكير"
            desc={moment(reminder?.reminderDate).format("YYYY-MM-DD")}
          />
          <DescGroup label="وفت التذكير" desc={reminder?.reminderTime} />
        </div>
      </div>
    </ShowReminderWrapper>
  );
};

export default ShowReminder;
const ShowReminderWrapper = ({ children, show, onHide }) => {
  return (
    <Modal
      size="lg"
      show={show}
      onHide={onHide}
      aria-labelledby=" example-modal-sizes-title-lg"
      className="systemModal mettingModal bg-black/50"
      contentClassName="scroll p-0"
    >
      <Modal.Header className="w-full">
        <div className="w-full flex justify-between">
          <p className="text-xl text-mainText">عرض تذكير</p>
          <Image
            src="/Rejected.svg"
            alt="close modal button"
            className="pointer"
            onClick={onHide}
          />
        </div>
      </Modal.Header>
      {children}
    </Modal>
  );
};
