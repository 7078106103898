import {
  useGetCategory,
  useUpdateCategory,
} from "../../../../../../hooks/fetchers/Categories";
import SuccessfullModal from "../../../../../../Components/Modals/SuccessfullModal";
import AddModal from "../../../AddModal";
import useSuccessfullModal from "../../../../../../Components/Modals/hooks/useSuccessfullModal";
import { joiResolver } from "@hookform/resolvers/joi";
import { updateCategorySchema } from "../../../../../../utiltis/Schemas/Categories/schema";
import { displayErrorMessage } from "../../../../../../helper/utils";
export const UpdateModal = ({ id, show, handleClose }) => {
  const {
    handleShowSuccess,
    handleShowError,
    handleCloseSuccess,
    handleCloseError,
    showSuccess,
    showError,
  } = useSuccessfullModal();
  const { mutate, isLoading , error } = useUpdateCategory(
    handleShowSuccess,
    handleShowError,
    id
  );
  const { data, isError, isLoading: categoryIsLoading } = useGetCategory(id);
  return (
    <>
      {!isError && !categoryIsLoading ? (
        <AddModal
          title={"تعديل"}
          show={show}
          handleClose={handleClose}
          name={"name"}
          isLoading={isLoading}
          handleSave={(data) => {
            mutate(data);
            handleClose();
          }}
          defaultValue={data?.name || ""}
          formOptions={{
            resolver: joiResolver(updateCategorySchema("ar")),
          }}
        />
      ) : null}

      <SuccessfullModal
        show={showSuccess}
        message={"تمت التعديل بنجاح"}
        handleClose={handleCloseSuccess}
      />
      <SuccessfullModal
        show={showError}
        message={() => displayErrorMessage(error)}
        handleClose={handleCloseError}
        status="error"
      />
    </>
  );
};
