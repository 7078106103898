import React from "react";

const DescGroup = ({ label = "", desc = "" }) => {
  return (
    <p className="text-mainText flex gap-2 text-lg  items-center ">
      {label} :<span className="text-mainCustomGray text-lg">{desc}</span>
    </p>
  );
};

export default DescGroup;
