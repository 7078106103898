import React from "react";
import { Button, Form, Modal } from "react-bootstrap";
import PdfImage from "../../../PdfImage";
import SaveButton from "../../../SaveButton";
import moment from "moment";
import DeleteButton from "../../../DeleteButton";
import { useDeleteReception } from "../../../../hooks/fetchers/Receptions";
import useSuccessfullModal from "../../../Modals/hooks/useSuccessfullModal";
import SuccessfullModal from "../../../Modals/SuccessfullModal";
import NoFiles from "../../ShowRequest/components/NoFiles";
import ShowFiles from "../../../ShowFiles";

const ViewReception = ({ id = null, viewVisit, setViewVisit, data }) => {
  const {
    handleShowSuccess,
    handleShowError,
    handleCloseSuccess,
    handleCloseError,
    showSuccess,
    showError,
  } = useSuccessfullModal();
  const { mutate: deleteMutation } = useDeleteReception(
    handleShowSuccess,
    handleShowError
  );

  console.log("data is:", data);
  return (
    <div>
      {id && viewVisit && (
        <Modal
          size="lg"
          show={viewVisit}
          onHide={() => setViewVisit(false)}
          aria-labelledby=" example-modal-sizes-title-lg"
          className={`systemModal !overflow-y-auto !scrollbar-none bg-black/50`}
        >
          <Modal.Header className="w-full !justify-between border-none">
            <div className=""></div>
            <ModalTitle
              title={
                data?.typeVisit === 1 ? "الزيارة الصادرة" : "الزيارة الواردة"
              }
            />
            <Button
              className="p-0 bg-transparent hover:bg-transparent"
              onClick={() => setViewVisit(false)}
            >
              <img src="/Rejected.svg" alt="" />
            </Button>
          </Modal.Header>

          <Form className="p-4 w-full">
            <fieldset className="fieldBorder container   mx-auto  p-3 my-3">
              <legend className="text-center text-mainText !text-base">
                معلومات الزيارة
              </legend>
              <div className="grid grid-cols-1  gap-4 mb-3">
                <div className="flex justify-start gap-3">
                  <p className="text-mainText">
                    {data?.typeVisit === 2 ? "اسم الشخص:" : "اسم الموظف:"}
                  </p>
                  <span className="main-text">
                    {data?.typeVisit === 2
                      ? data?.personVisit
                      : data?.employee?.firstName}
                  </span>
                </div>
              </div>
              <div className="grid grid-cols-2 gap-4 mb-3">
                <div className="flex justify-start gap-3">
                  <p className="text-mainText">
                    {data?.typeVisit === 2 ? "مكان الزيارة:" : " الجهة:"}
                  </p>
                  <span className="main-text">{data?.visitLocation} </span>
                </div>
                {data?.typeVisit === 2 ? (
                  <div className="flex justify-start gap-3">
                    <p className="text-mainText"> فئة الزائر:</p>
                    <span className="main-text">{data?.personType} </span>
                  </div>
                ) : null}
              </div>

              <div className="grid grid-cols-3 gap-4 mb-3">
                <div className="flex gap-2">
                  <p className="text-mainText"> تاريخ الزيارة:</p>
                  <span className="main-text">
                    {moment(data?.dateVist).format("YYYY-MM-DD")}
                  </span>
                </div>

                <div className="flex justify-center gap-2 ">
                  <p className="text-mainText"> وقت الزياره من:</p>
                  <span className="main-text">{data?.timeInVist}</span>
                  <div />
                </div>
                <div className="flex justify-end gap-2">
                  <p className="text-mainText"> وقت الزياره الي:</p>
                  <span className="main-text">{data?.timeOutVist}</span>
                </div>
              </div>
            </fieldset>
            <fieldset className="fieldBorder container mx-auto  p-3 my-3 ">
              <legend className="text-center text-mainText !text-base">
                تفاصيل الزيارة
              </legend>
              <Form.Group className="my-3">
                <Form.Label className="text-mainText">سبب الزيارة</Form.Label>
                <p
                  dangerouslySetInnerHTML={{ __html: data?.reasonVisit }}
                  className="form-control h-32"
                ></p>
              </Form.Group>
              <Form.Group className="my-3">
                <Form.Label className="text-mainText">ملاحظات </Form.Label>
                <p
                  dangerouslySetInnerHTML={{ __html: data?.notes }}
                  className="form-control h-32"
                ></p>
              </Form.Group>
            </fieldset>
            <fieldset className="fieldBorder container mx-auto  p-3 my-3 ">
              <legend className="text-center text-mainText !text-base">
                تفاصيل الزيارة
              </legend>

              {data?.fileVist?.length > 0 ? (
                <>
                  <div className="flex gap-3">
                    <ShowFiles files={data?.fileVist} />
                  </div>
                </>
              ) : (
                <NoFiles />
              )}
            </fieldset>
            <div className="flex justify-center gap-4">
              <DeleteButton
                onClick={() => {
                  deleteMutation({
                    "receptions" : [id]
                });
                }}
              />
              <SaveButton
                onClick={() => {
                  setViewVisit(false);
                }}
              />
            </div>
          </Form>
        </Modal>
      )}
      <SuccessfullModal
        handleClose={() => {
          handleCloseSuccess();
          setViewVisit(false);
        }}
        message={"تم الحذف بنجاح"}
        show={showSuccess}
      />
      <SuccessfullModal
        handleClose={handleCloseError}
        message={"حدث خطأ ما"}
        show={showError}
        status="error"
      />
    </div>
  );
};

const ModalTitle = ({ title }) => {
  return (
    <Modal.Title className="text-xl text-[#EFAA20] border !border-[#EFAA20] p-2 rounded-[5px] font-medium ">
      {title}
    </Modal.Title>
  );
};

export default ViewReception;
