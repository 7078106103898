import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import SaveButton from "../../../../Components/SaveButton";
// import CloseBtn from "/assets/icons/Rejected.svg"
function UpdateModalTimeLine({ title, show, handleClose, arr, id }) {
  const [inputVal, setInputVal] = useState("");
  const handleSave = () => {
    arr((prev) => {
      // console.log(prev.subCategories)
      return prev.map((item) =>
        item.id == id ? { ...item, name: inputVal } : item
      );
      // {...prev,subCategories: [...prev.subCategories,{id:prev.subCategories.length+1,name:inputVal}] }
    });
    handleClose();
  };

  return (
    <>
      <Modal
        centered
        contentClassName="bg-mainItems border !max-w-[700px] !border-[#EFAA20] !rounded-[20px] text-mainText"
        show={show}
        onHide={handleClose}
        size="lg"
      >
        <Modal.Header className="!justify-between border-none w-full">
          <Modal.Title className="text-xl">{title}</Modal.Title>
          <Button
            className="p-0 bg-transparent hover:bg-transparent"
            onClick={handleClose}
          >
            <img src="/Rejected.svg" alt="" />
          </Button>
        </Modal.Header>
        <Modal.Body className="w-full">
          <input
            className="w-full bg-mainNavy py-3 px-2 rounded-[7px] outline-none"
            type="text"
            placeholder="ادخل الاسم"
            value={inputVal}
            onChange={(e) => setInputVal(e.target.value)}
          />
        </Modal.Body>
        <Modal.Footer className="border-none w-full">
          <SaveButton onClick={handleSave} />
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default UpdateModalTimeLine;
