import React, { Fragment, useState } from "react";
import { Grid, Box } from "@mui/material";
import { Column } from "../components/Column";
import { ColumnTitle } from "../components/ColumnTitle";
import SearchButton from "../../SearchButton";
import { ServicesList } from "../components/ServicesList";
import { ServiceProvider } from "../context/ServiceContext";
import { useGetAllServices } from "../../../../../hooks/fetchers/Services";
import { SubServicesDesign } from "../components/SubServicesDesign";
import { SearchComponent } from "../../../../../Components/SearchComponent/SearchComponent";

function ServicesPage() {
  const [search,setSearch] = useState("")
  const { data } = useGetAllServices({search:search});
  return (
    <ServiceProvider>
      <Grid item xs={4} lg={3} className="!h-full">
        <Column>
          <Box className="px-3">
            {/* <SearchButton /> */}
            <SearchComponent
              value={search}
              handleChange={(val) => setSearch(val)}
              border="border !border-[#E4A11B]"
            />
          </Box>
          <ColumnTitle title={"كل خدمات المشروع"} />

          <ServicesList services={data} />
        </Column>
      </Grid>
      <Grid item xs={8} lg={6}>
        <Column>
          <SubServicesDesign />
        </Column>
      </Grid>
    </ServiceProvider>
  );
}

export default ServicesPage;
