import React, { useState } from "react";
import { Menu, MenuItem } from "@mui/material";
export const OptionsMenuItem = ({ children, ...props }) => {
  return (
    <MenuItem
      className="border min-w-[133px] text-right text-black"
      sx={{ gap: 1 }}
      {...props}
    >
      {children}
    </MenuItem>
  );
};
export const OptionsMenu = ({ children, ...props }) => {
  return (
    <Menu
      id="basic-menu"
      MenuListProps={{
        "aria-labelledby": "basic-button",
        sx: {
          bgcolor: "white",
        },
      }}
      {...props}
    >
      {children}
    </Menu>
  );
};

export const useOpenMenu = ()=> {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
      };
      const handleClose = () => {
        setAnchorEl(null);
      };
      return ({
        anchorEl,open,handleClick,handleClose
      })
}