import { toast } from "react-toastify";
import myAxiosInstance, { myAxiosJson } from "../https";
import { CheckRole } from "../utils";

////////////// Terms

// Terms order
export const getAllTermsOrder = (params = {}) => {
  return myAxiosInstance("it-category", { params })
    .then((task) => task.data)
    .catch((error) => {
      toast.error(error?.response?.data?.message);
    });
};

export const addTermsOrder = (data) => myAxiosJson.post("it-category", data);

export const updateTermsOrder = (orderId, data) =>
  myAxiosJson.patch(`it-category/${orderId}`, data);
export const deleteTermsOrder = (id) => myAxiosJson.delete(`it-category/${id}`);

//////////////////////// SubCategory
export const getAllTermsOrderSubCategory = (params = {}, id) => {
  return myAxiosInstance(`it-category/${id}/it-subcategory`, { params }).then(
    (response) => response.data?.subCategories
  );
};
export const getSubCategoryByIds = (ids) => {
  return myAxiosJson
    .post("it-subcategory/select", { ids })
    .then((task) => task.data?.subCategories);
};

export const addTermsOrderSubCategory = (id, data) =>
  myAxiosJson.post(`it-category/${id}/it-subcategory`, data);

export const updateTermsOrderSubCategory = (orderId, data) =>
  myAxiosJson.patch(`it-subcategory/${orderId}`, data);
export const deleteTermsOrderSubCategory = (id) =>
  myAxiosJson.delete(`it-subcategory/${id}`);

// Task Issue
export const getAllTaskIssue = (params = {}) => {
  return myAxiosInstance("it-issues", { params })
    .then((task) => task.data)
    .catch((error) => {
      toast.error(error?.response?.data?.message);
    });
};

export const addTaskIssue = (data) => myAxiosJson.post("it-issues", data);

export const updateTaskIssue = (orderId, data) =>
  myAxiosJson.patch(`it-issues/${orderId}`, data);
export const deleteTaskIssue = (id) => myAxiosJson.delete(`it-issues/${id}`);

// Technical Purchase
export const addTechnicalPurchase = (data) =>
  myAxiosInstance.post(`purchase`, data);

export const getAllPurchase = (status, params = {}) =>
  myAxiosInstance(status ? `purchase/${status}` : `purchase`, { params })
    .then((data) => data?.data)
    .catch((error) => {
      toast.error(error?.response?.data?.message);
    });

export const getAllTasksManager = (params = {}) =>
  myAxiosInstance("it-task", { params })
    .then((data) => data?.data)
    .catch((error) => {
      toast.error(error?.response?.data?.message);
    });

export const getAllPurchaseStats = () => {
  return myAxiosInstance("statics/purchase")
    .then((task) => task.data)
    .catch((error) => {
      toast.error(error?.response?.data?.message);
    });
};
export const updatePurchase = (orderId, data) =>
  myAxiosInstance.patch(`purchase/${orderId}`, data);

export const updatePurchaseComplete = (orderId, data) =>
  myAxiosInstance.patch(`purchase/complete/${orderId}`, data);

export const deletePurchase = (id) => myAxiosJson.delete(`purchase/${id}`);

export const auditAcceptPurchase = (id, data) => {
  data
    ? myAxiosInstance.patch(`purchase/accept/${id}`, data)
    : myAxiosJson.patch(`purchase/accept/${id}`);
};
export const auditUpdatePricePurchase = (id, data) => {
  myAxiosInstance.patch(`purchase/upload/price-offer/${id}`, data);
};

export const auditRefusePurchase = (id, data) =>
  myAxiosJson.patch(`purchase/reject/${id}`, data);

export const getAllPurchaseById = (id) => {
  return myAxiosInstance(`purchase/${id}`)
    .then((task) => task.data)
    .catch((error) => {
      toast.error(error?.response?.data?.message);
    });
};
export const getAllStats = (id) => {
  return myAxiosInstance(`statics/it/home`).then((task) => task.data);
};
export const getAllTasksStats = (params = {}) => {
  return myAxiosInstance("statics/it-task", { params })
    .then((task) => task.data)
    .catch((error) => {
      toast.error(error?.response?.data?.message);
    });
};
export const getAllEmployee = () => {
  return myAxiosInstance("user/it-users/select")
    .then((task) => task.data?.allUsers)
    .catch((error) => {
      toast.error(error?.response?.data?.message);
    });
};
export const getAllAdmin = () => {
  return myAxiosInstance("user/admin-mention/select")
    .then((task) => task.data?.allUsers)
    .catch((error) => {
      toast.error(error?.response?.data?.message);
    });
};

export const acceptItTask = (id) =>
  myAxiosInstance.patch(`it-task/accept/${id}`);

export const addItTasks = (data) => myAxiosInstance.post(`it-task`, data);

export const getAllItTasks = (params = {}) =>
  myAxiosInstance("it-task", { params })
    .then((data) => data?.data)
    .catch((error) => {
      toast.error(error?.response?.data?.message);
    });

export const getAllItTasksManager = (params = {}) => {
  return myAxiosInstance("it-task", {
    params,
  })
    .then((task) => task.data)
    .catch((error) => {
      toast.error(error?.response?.data?.message);
    });
};
export const getAllItTasksById = (id) => {
  return myAxiosInstance(`it-task/${id}`)
    .then((task) => task.data)
    .catch((error) => {
      toast.error(error?.response?.data?.message);
    });
};

export const updateTaskToFixed = (orderId, data) =>
  myAxiosInstance.patch(`it-task/fix/${orderId}`, data);

export const updateTaskToComplete = (orderId, data) =>
  myAxiosInstance.patch(`it-task/complete/${orderId}`, data);

export const getTask = (taskId) =>
  myAxiosInstance(`task/${taskId}`)
    .then((task) => task.data.task)
    .catch((error) => {
      toast.error(error?.response?.data?.message);
    });

export const acceptTask = (taskId) =>
  myAxiosInstance.patch(`task/accept/${taskId}`);
export const auditAcceptTask = (taskId) =>
  myAxiosInstance.patch(`task/approve/${taskId}`);

export const auditResendTask = (taskId, data) =>
  myAxiosJson.patch(`task/resend/${taskId}`, data);

export const confirmTask = (taskId, data) =>
  myAxiosInstance.patch(`task/confirm/${taskId}`, data);

export const deleteItTasks = (data) => myAxiosJson.delete(`it-task`, { data });

export const deleteItPurchase = (data) =>
  myAxiosJson.delete(`purchase`, { data });
