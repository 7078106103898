import { useState } from "react";

import { DetectForm } from "../components/Forms/DetectForm";

import AddPlanForm from "../components/Forms/AddPlanForm";
import { PageWrapper } from "../components/Forms/AddPageWrapper";

export default function AddPlan() {
  const [isExist, setIsExist] = useState(null);
  return (
    <PageWrapper title={"إضافة مشروع جديد"}>
      {isExist === null && <DetectForm setIsExist={setIsExist} />}
      {isExist !== null && <AddPlanForm isExist={isExist} />}
    </PageWrapper>
  );
}
