import { useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import Select from "../../../FormHandler/Select";
import { FormControl } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import SaveButton from "../../../SaveButton";
import { FormModal } from "../../../../Pages/System/PlanModel/components/FormModal";
import { InputLabel } from "../../../../Pages/System/PlanModel/components/InputLabel";
import useSuccessfullModal from "../../../Modals/hooks/useSuccessfullModal";
import SuccessfullModal from "../../../Modals/SuccessfullModal";
import {
  useAddReception,
  useUpdateReception,
} from "../../../../hooks/fetchers/Receptions";
import moment from "moment";
import { useGetSelectedUsers } from "../../../../hooks/fetchers/Users";
import {
  CustomDatePicker,
  CustomeInputFile,
  CustomInput,
  CustomTimePicker,
} from "../../../FormUi";
import { ListErrorComponent } from "../../../Errors/ListErrorComponent";
import { CustErrorMessage } from "../../../Errors/CustErrorMessage";

const ModalHeader = ({ title }) => {
  return (
    <Modal.Header className="border-none" closeButton>
      <Modal.Title className="mx-auto border !border-[#EFAA20] rounded-[5px] py-2 px-6">
        <p className="text-[#EFAA20] font-semibold text-xl">{title}</p>
      </Modal.Title>
    </Modal.Header>
  );
};
const options = [
  {
    value: 2,
    label: "واردة",
  },
  {
    value: 1,
    label: "صادرة",
  },
];
const AddUpdateReciption = ({
  ReciptionType,
  show,
  handleCloseModal,
  data,
  id = null,
}) => {
  const [errorMessage, setErrorMessage] = useState("حدث خطأ ما");
  const message = !id ? "تمت الاضافة بنجاح" : "تم التعديل بنجاح";

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({});

  /*
if(typeVisit === 1) {
employeId is required
}else if (typeVisit === 2) {
IdentityNumber is required;
personType is required;
personVisit is required
}

{
"employId": "validationsFelid.id",
"personVisit": "string (min 3 characters, alphabetic characters and spaces only)",
"phone": "validationsFelid.phone",
"IdentityNumber": "string (11 digits)",
"visitLocation": "string (required, min 3 characters, alphabetic characters and spaces only)",
"typeVisit": "number (valid: 1 or 2)",
"resoneVisit": "string (required, min 3 characters, alphabetic characters and spaces only)",
"dateVist": "date (required)",
"timeInVist": "date (required)",
"timeOutVist": "date (required)",
"notes": "string (max 6000 characters, alphabetic characters and spaces only)",
"personType": "string (min 3 characters, alphabetic characters and spaces only)",
"file": {
"fileVist": "array (max 5 items)"
}
}

Regular Expressions:

personVisit: "^[A-Za-z\u0600-\u06FF ]{3,}$"
visitLocation: "^[A-Za-z\u0600-\u06FF ]{3,}$"
resoneVisit: "^[A-Za-z\u0600-\u06FF ]{3,}$"
notes: "^[A-Za-z\u0600-\u06FF ]{0,6000}$"
personType: "^[A-Za-z\u0600-\u06FF ]{3,}$"
*/
  const { data: employees } = useGetSelectedUsers(true);
  const {
    handleShowSuccess,
    handleShowError,
    handleCloseSuccess,
    handleCloseError,
    showSuccess,
    showError,
  } = useSuccessfullModal();
  const {
    mutate: addReceptionMutation,
    isError: addReceptionIsError,
    error: addReceptionErrorMessage,
  } = useAddReception(handleShowSuccess, handleShowError);
  const {
    mutate: updateReceptionMutation,
    isError: updateReceptionIsError,
    error: updateReceptionErrorMessage,
  } = useUpdateReception(handleShowSuccess, handleShowError, id);
  const manipulateFormData = (visitData) => {
    if (ReciptionType === "Exports" || data?.typeVisit === 1) {
      visitData.typeVisit = 1;
      visitData.employee = visitData.employee.value;
    } else if (ReciptionType === "Imports" || data?.typeVisit === 2) {
      visitData.typeVisit = 2;
    }
    visitData.timeInVist = moment(visitData.timeInVist).format("h:m a");
    visitData.timeOutVist = moment(visitData.timeOutVist).format("h:m a");
    return visitData;
  };
  const onSubmit = (visitData) => {
    visitData = manipulateFormData(visitData);
    let formData = new FormData();
    if (visitData.typeVisit === 1) {
      formData.append("employee", visitData.employee);
    } else if (visitData.typeVisit === 2) {
      formData.append("IdentityNumber", visitData.IdentityNumber);
      formData.append("personType", visitData.personType);
      formData.append("personVisit", visitData.personVisit);
    }
    if (!id) {
      formData.append("typeVisit", visitData.typeVisit);
    } else {
      formData.append("typeVisit", data.typeVisit);
    }
    formData.append("phone", visitData.phone);
    formData.append("visitLocation", visitData.visitLocation);
    formData.append("reasonVisit", visitData.resoneVisit);
    formData.append("dateVist", visitData.dateVist);
    formData.append("timeInVist", visitData.timeInVist);
    formData.append("timeOutVist", visitData.timeOutVist);
    formData.append("notes", visitData.notes);
    if(visitData.fileVist){
      for (const file of visitData.fileVist) {
        formData.append("fileVist", file);
      }
    }
    if (!id) {
      addReceptionMutation(formData);
    } else {
      updateReceptionMutation(formData);
    }
  };

  useEffect(() => {
    if (addReceptionIsError) {
      if (addReceptionErrorMessage?.response?.data?.validationErrors) {
        setErrorMessage(
          <ListErrorComponent
            messages={addReceptionErrorMessage?.response?.data?.validationErrors?.split(
              ","
            )}
          />
        );
      } else if (addReceptionErrorMessage?.response?.message) {
        setErrorMessage(addReceptionErrorMessage?.response?.message);
      }
    }
  }, [addReceptionIsError]);
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div>
        <Modal
          size="lg"
          show={show}
          onHide={handleCloseModal}
          aria-labelledby=" example-modal-sizes-title-lg"
          className={`systemModal  scrollbar-none overflow-y-auto`}
          contentClassName="overflow-y-auto scrollbar-thin scrollbar-track-mainNavy scrollbar-thumb-[#E4A11B]"
        >
          {!id ? (
            <ModalHeader
              title={
                ReciptionType === "Exports"
                  ? "اضافة زيارة صادرة"
                  : "اضافة زيارة واردة"
              }
            />
          ) : null}
          {id ? (
            <ModalHeader
              title={
                data?.typeVisit === 1
                  ? "تعديل زيارة صادرة"
                  : "تعديل زيارة واردة"
              }
            />
          ) : null}

          <Modal.Body>
            <Form
              onSubmit={handleSubmit(onSubmit)}
              encType="multipart/form-data"
              className="flex flex-col gap-3"
            >
              <FormModal title={"معلومات الزيارة"}>
                <div className="grid grid-cols-3 gap-4 mb-3">
                  <FormControl>
                    <InputLabel label={"نوع الزيارة"} />
                    <Controller
                      control={control}
                      name="typeVisit"
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Select
                          fullWidth
                          value={options.find((option) =>
                            ReciptionType === "Exports"
                              ? option.value === 1
                              : option.value === 2
                          )}
                          OptionbackgroundColor="#414162"
                          disabled={true}
                          options={options}
                          // onChange={onChange}
                        />
                      )}
                    />
                  </FormControl>
                  <FormControl className="col-span-2">
                    <InputLabel
                      label={
                        ReciptionType === "Exports"
                          ? " اسم الموظف"
                          : "اسم الزائر"
                      }
                    />
                    {ReciptionType === "Exports" ? (
                      <Controller
                        control={control}
                        name="employee"
                        rules={{
                          required: "يجب اختيار الموظف",
                        }}
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <Select
                            fullWidth
                            onChange={onChange}
                            onBlur={onBlur}
                            value={value}
                            ref={ref}
                            placeholder="اكتب اسم الموظف"
                            OptionbackgroundColor="#414162"
                            options={employees}
                            mandatory
                          />
                        )}
                      />
                    ) : (
                      <CustomInput
                        control={control}
                        name="personVisit"
                        placeholder="اكتب اسم الزائر"
                        rules={{
                          required: "من فضلك اكتب اسم الزائر",
                          minLength: {
                            value: 3,
                            message: "لا تقل عن 3 حروف",
                          },
                          pattern: {
                            value: /^[A-Za-z\u0600-\u06FF ]{3,}$/i,
                            message:
                              "يجب ان تتكون من حروف عربى فقط او انجليزية فقط رقم",
                          },
                        }}
                      />
                    )}

                    <CustErrorMessage name={"personVisit"} errors={errors} />
                  </FormControl>
                </div>
                {ReciptionType === "Imports" ? (
                  <div className="grid grid-cols-2 gap-4 mb-3">
                    <FormControl>
                      <InputLabel label={"الهوية"} />
                      <CustomInput
                        control={control}
                        name="IdentityNumber"
                        placeholder="اكتب هوية الزائر"
                        rules={{
                          required: "من فضلك اكتب هوية الزائر",

                          pattern: {
                            value: /^[0-9]{11}$/i,
                            message: "يجب ان تتكون من 11 رقم",
                          },
                        }}
                      />

                      <CustErrorMessage
                        name={"IdentityNumber"}
                        errors={errors}
                      />
                    </FormControl>

                    <FormControl>
                      <InputLabel label={"فئة الزائر"} />
                      <CustomInput
                        control={control}
                        name="personType"
                        placeholder="اكتب فئة الزائر"
                        rules={{
                          required: "من فضلك اكتب فئة الزائر",
                        }}
                      />

                      <CustErrorMessage name={"personType"} errors={errors} />
                    </FormControl>
                  </div>
                ) : null}

                <div className="grid grid-cols-3 mb-3 gap-4">
                  <FormControl className="col-span-2">
                    <InputLabel label={"مكان الزيارة"} />
                    <CustomInput
                      control={control}
                      name="visitLocation"
                      placeholder="اكتب مكان الزياة"
                      rules={{
                        required: "من فضلك اكتب مكان الزياة",
                      }}
                    />

                    <CustErrorMessage name={"visitLocation"} errors={errors} />
                  </FormControl>
                  <FormControl>
                    <InputLabel label={"رقم الجوال"} />
                    <CustomInput
                      control={control}
                      name="phone"
                      placeholder="ادخل رقم الجوال"
                      rules={{
                        required: "من فضلك اكتب رقم الجوال",
                        pattern: {
                          value:
                            /^(\+?966|0)?\s?5\d{8}$|^(\+?20|0)?\s?(10|11|12|15)\d{8}$/i,
                          message:
                            " رقم غير صحيح من فضلك ادخل رقم مصرى او سعودى",
                        },
                      }}
                    />

                    <CustErrorMessage name={"phone"} errors={errors} />
                  </FormControl>
                </div>
                <div className="grid grid-cols-3 gap-4 mb-3">
                  <FormControl>
                    <InputLabel label={"تاريخ الزيارة"} />
                    <CustomDatePicker
                      control={control}
                      name="dateVist"
                      rules={{
                        required: "من فضلك اكتب تاريخ الزيارة",
                      }}
                    />

                    <CustErrorMessage name={"dateVist"} errors={errors} />
                  </FormControl>
                  <FormControl>
                    <InputLabel label={"وقت الزياره من"} />
                    <CustomTimePicker
                      control={control}
                      name="timeInVist"
                      rules={{
                        required: "من فضلك اكتب وقت بدا الزياره",
                      }}
                    />

                    <CustErrorMessage name={"timeInVist"} errors={errors} />
                  </FormControl>
                  <FormControl>
                    <InputLabel label={"وقت الزياره الي"} />
                    <CustomTimePicker
                      control={control}
                      name="timeOutVist"
                      rules={{
                        required: "من فضلك اكتب وقت نهاية الزياره",
                      }}
                    />

                    <CustErrorMessage name={"timeOutVist"} errors={errors} />
                  </FormControl>
                </div>
              </FormModal>
              <FormModal title={"تفاصيل الزيارة"}>
                <FormControl fullWidth>
                  <InputLabel label={"سبب الزيارة"} />
                  <CustomInput
                    control={control}
                    name="resoneVisit"
                    placeholder="اكتب سبب الزيارة .................................."
                    multiline
                    cols={5}
                    rows={5}
                    rules={{
                      required: "من فضلك اكتب سبب الزيارة",
                      minLength: {
                        value: 3,
                        message: "لا تقل عن 3 حروف",
                      },
                      pattern: {
                        value: /^[A-Za-z\u0600-\u06FF ]{3,}$/i,
                        message:
                          "يجب ان تتكون من حروف عربى فقط او انجليزية فقط رقم",
                      },
                    }}
                  />
                  <CustErrorMessage name={"resoneVisit"} errors={errors} />
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel label={"ملاحظات"} />
                  <CustomInput
                    control={control}
                    name="notes"
                    placeholder="اكتب  ملاحظات .................................."
                    multiline
                    cols={5}
                    rows={5}
                    rules={{
                      required: "من فضلك اكتب ملاحظات",
                      maxLength: {
                        value: 6000,
                        message: "لا تقل عن 6000 حروف",
                      },
                      pattern: {
                        value: /^[A-Za-z\u0600-\u06FF ]{0,6000}$/i,
                        message:
                          "يجب ان تتكون من حروف عربى فقط او انجليزية فقط رقم",
                      },
                    }}
                  />
                  <CustErrorMessage name={"notes"} errors={errors} />
                </FormControl>
              </FormModal>
              <FormModal title={"المرفقات"}>
                <CustomeInputFile
                  register={{ ...register("fileVist") }}
                  multiple={true}
                />
              </FormModal>
              <div className="flex my-2 justify-center gap-4">
                {/* <DeleteButton /> */}
                <SaveButton type="submit" />
              </div>
            </Form>
          </Modal.Body>
        </Modal>

        <SuccessfullModal
          handleClose={() => {
            handleCloseSuccess();
            handleCloseModal();
          }}
          message={message}
          show={showSuccess}
        />
        <SuccessfullModal
          handleClose={handleCloseError}
          message={errorMessage}
          show={showError}
          status="error"
        />
      </div>
    </LocalizationProvider>
  );
};

export default AddUpdateReciption;

// const useHandleErrorMessage = (error) => {
//   const [message,setMessage] = useState('حدث خطأ ما')
//   if (error){
//     if(error.response){
//       if(error.response.data){
//         if(error.response.data.validationErrors){
//           setMessage()
//         }
//       }
//     }
//   }
//   return message
// }
