import React, { createContext, useState } from 'react';

export const NetworkErrorContext = createContext();

export const NetworkErrorProvider = ({ children }) => {
  const [networkError, setNetworkError] = useState(null);

  return (
    <NetworkErrorContext.Provider value={{ networkError, setNetworkError }}>
      {children}
    </NetworkErrorContext.Provider>
  );
};
