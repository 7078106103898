import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
// import CloseBtn from "/assets/icons/Rejected.svg"
function CustomModal({ message, title, show, handleClose, handleSave }) {
  return (
    <>
      <Modal
        centered
        contentClassName="bg-mainItems !max-w-[700px] text-mainText"
        className="bg-black/50"
        show={show}
        size="lg"
        onHide={handleClose}
      >
        <Modal.Header className="bg-[#D59921] py-2 rounded-t-md !justify-between border-none w-full">
          <Modal.Title className="text-white text-xl">{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="w-full">
          <p className="w-full py-3  rounded-[7px] outline-none text-mainText">
            {message}
          </p>
        </Modal.Body>
        <Modal.Footer className="border-none gap-2 w-full">
          <Button
            className="mx-0 py-1 px-14 font-semibold text-[13px] text-white bg-[#EFAA20] hover:bg-[#EFAA20]"
            onClick={handleSave}
          >
            نعم
          </Button>
          <Button
            className="mx-0 py-1 px-14 font-semibold text-[13px] text-white  bg-[#A83A3A] hover:bg-[#A83A3A]"
            onClick={handleClose}
          >
            لا
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default CustomModal;
