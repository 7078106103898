import React, { useCallback, useEffect, useMemo, useState } from "react";
import ConfirmPoper from "../../../../../Components/System/ConfirmPoper.jsx";
import { useParams } from "react-router-dom";
import "./index.css";
import DesignCasesChart from "../../../../../Components/System/Requests/DesignRequestChart/DesignCasesChart/DesignCasesChart.js";
import ShowReviewRequest from "../../../../../Components/System/ShowRequest/ShowReviewRequest.jsx";
import EditReviewRequest from "../../../../../Components/System/Requests/EditRequest/EditReviewRequest";
import { TableCell } from "../../../../../Components/Table/TableCell.jsx";
import { TableRow } from "../../../../../Components/Table/TableRow.jsx";
import CustomTable from "../../../../../Components/Table/index.jsx";
import { getAllRequestsStats } from "../../../../../helper/fetchers/Requests.jsx";
import Progress from "../../../../../Components/Progress.jsx";
import Image from "../../../../../Components/Image.jsx";
import moment from "moment";
import { useRequestsContext } from "../../../../../Context/requests.js";
import { CheckRole, GenerateColumnsNames } from "../../../../../helper/utils.jsx";
import {
  useDeleteRequest,
  useGetAllRequests,
} from "../../../../../hooks/fetchers/Requests.jsx";
import { SearchComponent } from "../../../../../Components/SearchComponent/SearchComponent.jsx";
import { RiDeleteBin6Line } from "react-icons/ri";
import CustomCheckboxTable from "../../../../../Components/CustomCheckboxTable.jsx";
import useSuccessfullModal from "../../../../../Components/Modals/hooks/useSuccessfullModal.jsx";
import LoadingPage from "../../../../../Components/feedback/LoadingPage.jsx";
import SuccessfullModal from "../../../../../Components/Modals/SuccessfullModal.jsx";
import TableDeleteButton from "../../../../../Components/Buttons/TableDeleteButton.jsx";
import { roles } from "../../../../../helper/fetchers/Tasks.jsx";
const ReviewCasesRequest = () => {
  // const [showProject, setShowProject] = useState(false);
  const [page, setPage] = useState(1);
  const [editRequest, setEditRequest] = useState(false);
  const [ConfirmUpdate, setConfirmUpdate] = useState(false);
  const [projectType, setProjectType] = useState("");
  const [projectTypeAR, setProjectTypeAR] = useState("");
  const [chartColor, setChartColor] = useState("");
  const { showProject, setShowProject } = useRequestsContext();

  const { status } = useParams();

  useMemo(() => {
    switch (status) {
      case "1":
        setProjectType("inProgress");
        setProjectTypeAR("طلبات قيد التنفيذ");
        setChartColor("#4200FF");

        break;
      case "66":
        setProjectType("converted");
        setProjectTypeAR("طلبات تم تحويلها الي مشاريع");
        setChartColor("#4200FF");
        // setStatus(1);

        break;
      case "0":
        setProjectType("pending");
        setProjectTypeAR("طلبات فى انتظار الموافقة");
        setChartColor("#D59921");

        break;
      case "5":
        setProjectType("rejected");
        setProjectTypeAR("طلبات مرفوضة");
        setChartColor("#E40038");

        break;
      default:
        break;
    }
  }, [status]);

  const columns = GenerateColumnsNames(
    "تحديد",

    "م",
    "اسم المشروع",
    "رقم الطلب",
    "تاريخ الاستلام",
    "نوع المشروع",
    "الحالة",
    "عرض",
    "تعديل"
  );
  const [id, setId] = useState(null);
  // const [reviewRequests, setReviewRequests] = useState();
  const [search, setSearch] = useState("");
  const { data, isLoading, isError } = useGetAllRequests({
    page,
    projectType: 2,
    search,
    status,
  });

  const handleChange = (event, value) => {
    setPage(value);
    // setFilterTasksParams({ page: value });
  };
  // const getReviewRequests = async () => {
  //   try {
  //     const { data } = await getReviewRequestsWithStatus(status);
  //     if (data) {
  //       setReviewRequests(data);
  //     } else {
  //       console.log("Data retrieval failed");
  //     }
  //   } catch (error) {
  //     toast.error(error?.response?.data?.message);
  //   }
  // };
  // useEffect(() => {
  //   getReviewRequests();
  // }, [status,page]);
  const [stats, setStats] = useState();
  const getRequests = useCallback(async () => {
    const response = await getAllRequestsStats();
    if (response?.status === 200) {
      setStats(response?.data);
    }
  }, []);
  useEffect(() => {
    getRequests();
  }, [getRequests]);
  const handleSearch = (val) => {
    setSearch(val);
    console.log(`Search:`, val);
  };
  const [checked, setChecked] = useState([]);
  const {
    handleShowSuccess,
    handleShowError,
    handleCloseSuccess,
    handleCloseError,
    showSuccess,
    showError,
  } = useSuccessfullModal();
  const {
    mutate: deleteMutation,
    isLoading: deleteIsLoading,
    isError: deleteIsError,
  } = useDeleteRequest(() => {
    handleShowSuccess();
    setChecked([]);
  }, handleShowError);

  const handleChecked = (val) => {
    if (checked.includes(val)) {
      setChecked(checked.filter((item) => item !== val));
    } else {
      setChecked([...checked, val]);
    }
  };
  // You have array of ids to delete

  const handleDeleteAll = () => {};
  const handleDeleteSelected = () => {
    deleteMutation({ requests: checked });
  };
  return (
    <>
      {deleteIsLoading ? <LoadingPage /> : null}
      <SuccessfullModal
        handleClose={handleCloseSuccess}
        message={"تم الحذف بنجاح"}
        show={showSuccess}
      />
      <SuccessfullModal
        handleClose={handleCloseError}
        message={"حدث خطأ ما"}
        show={showError}
        status="error"
      />
      {showProject ? (
        <div className="AllRequests-scroll  h-full scrollbar-none">
          <ShowReviewRequest
            status={status}
            setShowProject={setShowProject}
            id={id}
            // getReviewRequests={getReviewRequests}
          />
        </div>
      ) : (
        <div className="AllRequests  h-full">
          <div className="ReviewCasesRequest  ">
            <div className="reviewChartContainer d-flex flex-column justify-content-center align-items-center">
              <p className="my-3 fs-4 text-mainText">{projectTypeAR}</p>
              <DesignCasesChart
                color={chartColor}
                projectType={projectTypeAR}
                stats={stats}
              />
            </div>

            <fieldset className="TableContainer  w-100 py-3 px-2 mx-auto mt-3">
              <legend className="text-center text-mainText">
                {projectType === "inProgress"
                  ? "طلبات قيد التنفيذ ( اشراف )"
                  : projectType === "pending"
                  ? "طلبات فى انتظار الموافقة ( اشراف )"
                  : projectType === "converted"
                  ? "طلبات تم تحويلها الي مشاريع ( اشراف )"
                  : projectType === "rejected"
                  ? "طلبات مرفوضة ( اشراف )"
                  : null}
              </legend>
              <div className="">
                <div className="w-3/4 mx-auto ">
                  <SearchComponent
                    handleChange={handleSearch}
                    value={search}
                    className={""}
                    border="border !border-[#E4A11B]"
                  />
                </div>
              </div>
              {CheckRole(roles["admin"]) && !isLoading && !isError && data?.request?.length > 0 ? (
                <TableDeleteButton
                  checked={checked}
                  dataLength={data?.request?.length}
                  onClickDeleteAll={handleDeleteAll}
                  onClickDeleteSelected={handleDeleteSelected}
                />
              ) : null}
              <div className="mt-3 !h-[400px] overflow-y-auto scrollbar-thin scrollbar-track-mainNavy scrollbar-thumb-[#E4A11B]">
                {isLoading ? <Progress /> : null}
                {isError ? (
                  <p className="text-red-500 text-center text-lg">
                    يوجد خطأ ما
                  </p>
                ) : null}

                {!isLoading && !isError && data.request ? (
                  <CustomTable
                  columns={CheckRole(roles["admin"]) ? columns : columns.slice(1)}
                    data={data?.request}
                    paginationProps={{
                      count: data?.pages,
                      page: page,
                      onChange: handleChange,
                    }}
                  >
                    {data?.request && data?.request?.length > 0 // Check if reviewRequests has elements
                      ? data?.request?.map(
                          (
                            {
                              _id,
                              projectName,
                              orderNumber,
                              createdAt,
                              projectType,
                              status,
                              enStatus,
                              display,
                              edit,
                            },
                            index
                          ) => (
                            <TableRow
                              className={`my-2 border !border-[#efaa207f] ${
                                index % 2 === 0 ? "bg-mainNavy" : ""
                              }`}
                              key={_id}
                            >
                              {CheckRole(roles["admin"]) ? (
                              <TableCell>
                                <CustomCheckboxTable
                                  isChecked={checked?.some((x) => x === _id)}
                                  handleClick={() => handleChecked(_id)}
                                />
                              </TableCell>
                              ) : null}
                              <TableCell>
                                {(page - 1) * 10 + (index + 1)}
                              </TableCell>
                              <TableCell>{projectName}</TableCell>
                              <TableCell>{orderNumber}</TableCell>
                              <TableCell>
                                {moment(createdAt).format("YYYY-MM-DD")}
                              </TableCell>
                              <TableCell>اشراف علي التنفيذ</TableCell>
                              <TableCell>
                                {status == 0
                                  ? "في الانتظار"
                                  : status == 1
                                  ? "قيد التنفيذ"
                                  : status == 66
                                  ? "تم تحويلها الي مشروع"
                                  : "مرفوضة"}
                              </TableCell>
                              <TableCell>
                                <Image
                                  src={
                                    process.env.PUBLIC_URL + "/icons/view.svg"
                                  }
                                  onClick={() => {
                                    setShowProject(true);
                                    setId(_id);
                                  }}
                                  className="display_project  rounded"
                                  alt=" display project"
                                />
                              </TableCell>
                              <TableCell>
                                <Image
                                  src={
                                    process.env.PUBLIC_URL + "/icons/edit.svg"
                                  }
                                  onClick={() => {
                                    setEditRequest(true);
                                    setId(_id);
                                  }}
                                  className=" edit_project  rounded"
                                  alt=" edit project"
                                />
                              </TableCell>
                            </TableRow>
                          )
                        )
                      : null}
                  </CustomTable>
                ) : null}
              </div>
            </fieldset>
          </div>
        </div>
      )}

      {editRequest && (
        <div className="AllRequests-scroll scrollbar-none">
          <EditReviewRequest
            editRequest={editRequest}
            setEditRequest={setEditRequest}
            setConfirmPoper={setConfirmUpdate}
            id={id}
            handleClose={() => {
              setEditRequest(false);
            }}
            // getReviewRequests={getReviewRequests}
          />
        </div>
      )}
      {ConfirmUpdate && (
        <ConfirmPoper
          confirmPoper={ConfirmUpdate}
          setConfirmPoper={setConfirmUpdate}
          setEditRequest={setEditRequest}
          text={"تم تعديل الطلب فى المشاريع بنجاح  "}
        />
      )}
    </>
  );
};

export default ReviewCasesRequest;
