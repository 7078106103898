import { createContext, useContext, useEffect, useState } from "react";
import { useGetAllTasks } from "../../../../hooks/fetchers/Tasks";
import { myAxiosJson } from "../../../../helper/https";
export const TaskContext = createContext();

export default function TaskContextProvier({ children }) {
  const [filterTasksParams, setFilterTasksParams] = useState({});
  const { data, isLoading, isError, error } = useGetAllTasks(filterTasksParams);
  const [selectedTab, setSelectedTab] = useState("كل المهام");

  // const { data,isLoading } = useGetAllTasks(filterTasksParams);

  return (
    <TaskContext.Provider
      value={{ tasks: data,isError,isLoading, setFilterTasksParams, selectedTab, setSelectedTab }}
    >
      {children}
    </TaskContext.Provider>
  );
}

export const useTaskContext = () => useContext(TaskContext);
