import { Fragment, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { Checkbox } from "@mui/material";

import moment from "moment";


import PreviewImage from "../../../../../../Components/Modals/PreviewImage";
import { FormModal } from "../../../../../System/PlanModel/components/FormModal";
import Progress from "../../../../../../Components/Progress";
import { statusEnum } from "../../../../../../helper/utils";
import ShowFiles from "../../../../../../Components/ShowFiles";
import { useGetUserVacationReport } from "../../../../../../hooks/fetchers/Users";

const VacationModal = ({ ShowHoliday, handleClose, userId, vacationId }) => {
  const [showPerview, setShowPerview] = useState(false);
  const [imgSrc, setImgSrc] = useState("");
  const { data } = useGetUserVacationReport(userId, vacationId);
  return (
    <Modal
      className="systemModal bg-black/50"
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={handleClose}
      show={ShowHoliday}
    >
      <ModalHeader title={"طلب اجازة"} handleClose={handleClose} />
      {data ? (
        <Modal.Body className="w-full">
          <PreviewImage
            showImg={showPerview}
            imgSrc={imgSrc}
            onClose={() => setShowPerview(false)}
          />
          <FormModal title={"مخصصة الى"}>
            <div className="row">
              <Fragment>
                <div className="col-md-6">
                  <p className="text-mainText">
                    {"اسم الموظف :"}
                    <span className="main-text p-2">
                      {`${data?.employeeId?.firstName} ${data?.employeeId?.lastName}`}
                    </span>
                  </p>
                </div>
                <div className="col-md-6">
                  <p className="text-mainText">
                    {"الكود الوظيفى :"}

                    <span className="main-text p-2">
                      {data?.employeeId?.employeCode}
                    </span>
                  </p>
                </div>
              </Fragment>
            </div>
          </FormModal>

          <FormModal title={"تفاصيل الاجازة"}>
            <div className="row">
              <div className="col-md-6 mb-4">
                <p className="text-mainText">
                  {"نوع الاجازة :"}
                  <span className="main-text p-2">
                    {data?.vactionCategoryId?.name}
                  </span>
                </p>
              </div>
              <div className="col-md-6  mb-4">
                <p className="text-mainText">
                  <Checkbox checked className="text-mainText" />

                  <span className="main-text p-2">
                    {+data?.vactionType === 0
                      ? "بدون راتب "
                      : +data?.vactionType === 1
                      ? "براتب "
                      : "اخري"}
                  </span>
                </p>
              </div>

              <div className="col-md-4 mb-3">
                <p className="text-mainText">
                  {"مدة الاجازة :"}
                  <span className="main-text p-2">{data?.durationVaction}</span>
                </p>
              </div>
              <div className="col-md-4 mb-3">
                <p className="text-mainText">
                  {"من تاريخ : "}
                  <span className="main-text p-2">
                    {moment(data?.startDate).format("DD-MM-YYYY")}
                  </span>
                </p>
              </div>
              <div className="col-md-4 mb-3">
                <p className="text-mainText">
                  {"الى تاريخ :"}
                  <span className="main-text p-2">
                    {moment(data?.endDate).format("DD-MM-YYYY")}
                  </span>
                </p>
              </div>
              <div className="col-md-12 mb-4">
                <p className="text-mainText">
                  {"حالة الاجازة :"}
                  <span className="main-text p-2">
                    {statusEnum[data?.status]?.title}
                  </span>
                </p>
              </div>
              {data?.resonRejected && (
                <div className="col-md-12 mb-4">
                  <p className="text-mainText">
                    {"سبب الرفض :"}
                    <span className="main-text p-2">{data?.resonRejected}</span>
                  </p>
                </div>
              )}
            </div>
          </FormModal>

          <FormModal title="موجة الى">
            <div className="row">
              <Fragment>
                <div className="col-md-6 mb-4">
                  <p className="text-mainText">
                    تعتمد من :
                    <span className="main-text p-2">
                      {data?.approvalManager?.firstName}
                    </span>
                  </p>
                </div>

                <div className="col-md-6  mb-4">
                  <p className="text-mainText">
                    الكود الوظيفى :
                    <span className="main-text p-2">
                      {data?.approvalManager?.employeCode}
                    </span>
                  </p>
                </div>
              </Fragment>
            </div>
          </FormModal>

          <FormModal title={"المرفقات"}>
            <div className="flex justify-center items-start gap-2">
              <ShowFiles files={data?.image} />
            </div>
          </FormModal>
        </Modal.Body>
      ) : (
        <Progress />
      )}
    </Modal>
  );
};

export default VacationModal;

const ModalHeader = ({ title, handleClose }) => {
  return (
    <Modal.Header className="w-full !justify-between border-none">
      <div className=""></div>
      <Modal.Title className="text-xl text-[#EFAA20] border !border-[#EFAA20] py-1.5 px-6 rounded-[5px] font-medium ">
        {title}
      </Modal.Title>
      <Button
        className="p-0 bg-transparent hover:bg-transparent"
        onClick={handleClose}
      >
        <img src="/Rejected.svg" alt="" />
      </Button>
    </Modal.Header>
  );
};
