import { toast } from "react-toastify";
import myAxiosInstance, { myAxiosJson } from "../https";

export const roles = {
  admin: "مدير",
  administrator: "أدارى",
  senior: "مدير قسم",
  manager: "مدير المكتب",
  user: "موظف",
  auditor: "مدقق",
  accountant: "محاسب",
  HR: "مواردبشرية",
};

export const getAllTasks = (params = {}) => {
  return myAxiosInstance("task", { params }).then((task) => task.data).catch(error=>{
    toast.error(error?.response?.data?.message)
});
};
export const getTask = (taskId) =>
  myAxiosInstance(`task/${taskId}`).then((task) => task.data.task).catch(error=>{
    toast.error(error?.response?.data?.message)
});

export const acceptTask = (taskId) =>
  myAxiosInstance.patch(`task/accept/${taskId}`);
export const auditAcceptTask = (taskId) =>
  myAxiosInstance.patch(`task/approve/${taskId}`);

export const auditResendTask = (taskId, data) =>
  myAxiosJson.patch(`task/resend/${taskId}`, data);

export const confirmTask = (taskId, data) =>
  myAxiosInstance.patch(`task/confirm/${taskId}`, data);
export const deleteTask = (data) => myAxiosJson.delete(`task/`,{data});

export const addTask = (data) => myAxiosInstance.post("task", data);
export const updateTask = (taskId, data) =>
  myAxiosInstance.patch(`task/${taskId}`, data);
