import React from "react";
import { Button } from "react-bootstrap";
import { twMerge } from "tailwind-merge";

const SaveButton = ({ onClick, type = "Button", text = "حفظ",className }) => {
  return (
    <div className=" d-flex justify-content-center">
      <button
        onClick={onClick}
        type={type}
        className={twMerge("bg-[#EFAA20] text-base border-solid text-mainText !w-[139px] !h-[28px] !rounded-md  hover:text-mainText hover:border-2 hover:border-[#EFAA20]",className)}
      >
        {text}
      </button>
    </div>
  );
};
export default SaveButton;
