import { useMutation,useQuery, useQueryClient } from "react-query";
import {
  getAllVactions,
  getVactionById,
  createVaction,
  updateVaction,
  deleteVaction,
  confirmVaction,
  rejectVaction,
  getPrintVactionById,
} from "../../helper/fetchers/Vacations";


export const useGetAllVacations = (params) => {
  return useQuery(["vacation",params],()=> getAllVactions(params));  
};

export const useGetVacation = (vacationId) => {
  return useQuery(["vacation",vacationId],() => getVactionById(vacationId),{
    enabled: !!vacationId
  });  
};
export const useGetPrintVacation = (vacationId) => {  
  return useQuery(["vacation",vacationId],() => getPrintVactionById(vacationId),{
    enabled: !!vacationId
  });  
};

export const useAddVacation = (onSuccess, onError) => {
  const queryClient = useQueryClient();
  return useMutation(createVaction, {
    onSuccess: () => {
      queryClient.invalidateQueries("vacation");
      onSuccess();
    },
    onError: (error) => {
      onError();
    },
  });
};

export const useUpdateVacation = (onSuccess, onError,id) => {
  const queryClient = useQueryClient();
  return useMutation((data) => updateVaction(id,data), {
    onSuccess: (data) => {
      // console.log("success data is:",data);
      queryClient.invalidateQueries("vacation");
      onSuccess();
    },
    onError: (error) => {
      console.log("Errrrrrrror Is:",error);
      onError();
    },
  });
};

export const useDeleteVacation = (onSuccess, onError) => {
  const queryClient = useQueryClient();
  return useMutation(deleteVaction, {
    onSuccess: () => {
      queryClient.invalidateQueries("vacation");
      onSuccess();
    },
    onError: (error) => {
      onError();
    },
  });
};

export const useConfirmVacation = (onSuccess, onError) => {
  const queryClient = useQueryClient();
  return useMutation(confirmVaction, {
    onSuccess: () => {
      queryClient.invalidateQueries("vacation");
      onSuccess();
    },
    onError: (error) => {
      onError();
    },
  });
};

export const useRejectVaction = (onSuccess, onError,id) => {
  const queryClient = useQueryClient();
  return useMutation((data) => rejectVaction(id,data), {
    onSuccess: () => {
      queryClient.invalidateQueries("vacation");
      onSuccess();
    },
    onError: (error) => {
      onError();
    },
  });
};
