import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";
import Progress from "../../Progress";
import { upgradePassword } from "../../../helper/fetchers/Users";
import LoadingPage from "../../feedback/LoadingPage";
import { IoEyeOffOutline, IoEyeOutline } from "react-icons/io5";
import { LuEye } from "react-icons/lu";
import { MdOutlineRemoveRedEye } from "react-icons/md";

function UpdatePasswordForm({ id, onSuccess, oError }) {
  const [isLoading, setIsLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm();

  const onSubmit = async (userData) => {
    setIsLoading(true);
    try {
      const { data } = await upgradePassword(id, userData);
      onSuccess();
    } catch (error) {
      // toast.error(error?.response?.data?.message);
      oError();
    }
    setIsLoading(false);
  };
  const [openEye, setOpenEye] = useState(false);
  const [openEyeNew, setOpenEyeNew] = useState(false);
  const [openEyeRepeat, setOpenEyeRepeat] = useState(false);

  return (
    <>
      {isLoading ? <LoadingPage /> : null}
      <Form className="w-full" onSubmit={handleSubmit(onSubmit)}>
        <div className="grid grid-cols-2 gap-3 mb-4">
          <Form.Group>
            <Form.Label className=" text-mainText !border-none ">
              ادخل الكود الوظيفى
            </Form.Label>
            <input
              {...register("employeCode", { required: true })}
              className="text-mainText form-control bg-mainNavy  p-2 !border-none"
              placeholder="ادخل الكود الوظيفى"
            />
          </Form.Group>
          <Form.Group>
            <Form.Label className=" text-mainText !border-none ">
              ادخل كلمة المرور القديمة
            </Form.Label>
            <div className="flex relative">
              <input
                {...register("oldPssword", { required: true })}
                type={openEye ? "text" : "password"}
                className="text-mainText form-control bg-mainNavy p-2 !border-none"
                placeholder="ادخل كلمة المرور  القديمة"
              />
              <button
                className="p-2 absolute left-0"
                type="button"
                onClick={() => setOpenEye(!openEye)}
              >
                {openEye ? (
                  <MdOutlineRemoveRedEye className="text-mainText text-xl" />
                ) : (
                  <IoEyeOffOutline className="text-mainText text-xl" />
                )}
              </button>
            </div>

            <p></p>
          </Form.Group>
          <Form.Group>
            <Form.Label className=" text-mainText !border-none ">
              ادخل كلمة المرور الجديدة
            </Form.Label>
            <div className="relative flex">
              <input
                {...register("newPassword", {
                  required: true,
                  pattern: {
                    value:
                      /(?:[\w]+\W*){6,}\d/,
                    message: "invalid password ",
                  },
                })}
                className="text-mainText form-control bg-mainNavy p-2 !border-none"
                placeholder="ادخل كلمة المرور الجديدة"
                type={openEyeNew ? "text" : "password"}
              />
              <button
                className="p-2 absolute left-0"
                type="button"
                onClick={() => setOpenEyeNew(!openEyeNew)}
              >
                {openEyeNew ? (
                  <MdOutlineRemoveRedEye className="text-mainText text-xl" />
                ) : (
                  <IoEyeOffOutline className="text-mainText text-xl" />
                )}
              </button>
            </div>
            {errors.newPassword?.message && (
              <p className="text-[#FF0000]">{errors.newPassword?.message} </p>
            )}
          </Form.Group>
          <Form.Group>
            <Form.Label className=" text-mainText !border-none ">
              تأكيد كلمة المرور
            </Form.Label>
            <div className="relative flex">
              <input
                {...register("cPassword", { required: true })}
                className="text-mainText form-control bg-mainNavy   p-2 !border-none"
                placeholder="تأكيد كلمة المرور"
                type={openEyeRepeat ? "text" : "password"}
              />
              <button
                className="p-2 absolute left-0"
                type="button"
                onClick={() => setOpenEyeRepeat(!openEyeRepeat)}
              >
                {openEyeRepeat ? (
                  <MdOutlineRemoveRedEye className="text-mainText text-xl" />
                ) : (
                  <IoEyeOffOutline className="text-mainText text-xl" />
                )}
              </button>
            </div>
          </Form.Group>
        </div>
        <div className="border-none my-3 flex !w-full justify-end">
          <Button
            type="submit"
            className="mx-auto py-1 px-14 font-semibold text-[15px] border !border-[#EFAA20] text-white bg-[#EFAA20] focus:bg-[#EFAA20] focus-visible:bg-[#EFAA20] active:!bg-[#EFAA20] hover:bg-[#EFAA20]"
          >
            {isSubmitting ? <Progress isSmall /> : "تحديث"}
          </Button>
        </div>
      </Form>
    </>
  );
}

export default UpdatePasswordForm;
