import React, { useState } from "react";
import { useEffect } from "react";
import { myAxiosJson,myAxiosInstance ,myAxios } from "./https";
import useSuccessfullModal from "../Components/Modals/hooks/useSuccessfullModal";
import SuccessfullModal from "../Components/Modals/SuccessfullModal";
import { useNavigate } from "react-router-dom";

const AxiosInterceptor = ({ children }) => {
  const [message, setMessage] = useState("ليس لديك صلاحية الوصول");
  const navigate = useNavigate();
  const { handleShowError, handleCloseError, showError } =
    useSuccessfullModal();
  useEffect(() => {
    const responseInterceptor = myAxiosJson.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error?.response?.status === 401) {
          setMessage(error?.response?.data?.message);
          handleShowError();
        }
        return Promise.reject(error);
      }
    );
    return () => {
      myAxiosJson.interceptors.response.eject(responseInterceptor);
    };
  }, []);
  useEffect(() => {
    const responseInterceptor = myAxiosInstance.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error?.response?.status === 401) {
          setMessage(error?.response?.data?.message);
          handleShowError();
        }
        return Promise.reject(error);
      }
    );
    return () => {
      myAxiosInstance.interceptors.response.eject(responseInterceptor);
    };
  }, []);
  useEffect(() => {
    const responseInterceptor = myAxios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error?.response?.status === 401) {
          setMessage(error?.response?.data?.message);
          handleShowError();
        }
        return Promise.reject(error);
      }
    );
    return () => {
      myAxios.interceptors.response.eject(responseInterceptor);
    };
  }, []);
  return (
    <>
      {children}
      <SuccessfullModal
        handleClose={() => {
          navigate("/");
          handleCloseError();
        }}
        message={message}
        show={showError}
        status="error"
      />
    </>
  );
};

export default AxiosInterceptor;
