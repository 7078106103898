import { createContext, useCallback, useEffect, useState } from "react";
import { getUserProfile } from "../helper/fetchers/Profie";
import { toast } from "react-toastify";
import { useGetUserProfile } from "../hooks/fetchers/Users";

export const UsersParamsContext = createContext();
const UsersParamsProvider = ({ children }) => {
  const [params, setParams] = useState({});
  const [user, setUser] = useState(null);

  const {
    data,
    isLoading: getUserIsLoading,
    isError: getUserIsError,
    error: getUserError,
  } = useGetUserProfile();
  useEffect(() => {
    if (!getUserIsLoading && !getUserIsError) {
      setUser(data?.data?.user);
    }
  }, [getUserIsLoading, getUserIsError, data?.data?.user, setUser]);

  // console.log(user?.image);
  useEffect(() => {
    if (getUserIsError && getUserError) {
      toast.error(getUserError?.response?.data?.message);
    }
  }, [getUserIsError, getUserError]);
  return (
    <UsersParamsContext.Provider
      value={{
        params,
        setParams,
        setUser,
        user,
        // getUser,
      }}
    >
      {children}
    </UsersParamsContext.Provider>
  );
};

export default UsersParamsProvider;
