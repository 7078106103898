import React from "react";
import Image from "../../../../Components/Image";
import ProfileImage from "../../../../Components/ProfileImage";

const UserDetails = ({ userData }) => {
  console.log(userData, "userData");
  return (
    <>
      <p className="text-[#D59921] text-center">{userData?.country}</p>
      <div className="my-3 flex gap-3">
        <ProfileImage image={userData?.image} imageClassName="w-[81px] h-[81px] rounded-[50%]" alt="user img" />
        <div>
          <h2 className="text-mainText text-base">
            {userData?.firstName + " "}
            {userData?.lastName}
          </h2>
          <p className="text-mainText text-base">{userData?.email}</p>
          <p className="text-mainText text-base">
            <span dir="ltr">{userData?.phone}</span>
          </p>
        </div>
      </div>
    </>
  );
};

export default UserDetails;
