import React, { useState } from "react";
import { Accordion } from "react-bootstrap";
import { MdKeyboardArrowDown } from "react-icons/md";
import { Link } from "react-router-dom";

const AllRequestsDropdown = ({
  setOpen,
  designCategories,
  setDesignCategories,
  reviewCategories,
  setReviewCategories,
  setShowProject,
}) => {
  const [active, setActive] = useState();

  return (
    <div className="absolute top-[3rem] right-[12rem] z-[1000000] bg-mainNavy rounded-xl">
      <div className="flex items-start flex-col  bg-mainItems w-[300px] h-[300px]  p-2 overflow-auto shadow-2xl ">
        <div
          className="w-screen h-screen fixed inset-0  z-[-1]"
          onClick={() => {
            setOpen(false);
          }}
        />
        <div className="d-flex  flex-column   align-items-center w-full ">
          <div className="mt-4 w-100">
            <Link
              className="w-full"
              onClick={() => {
                setActive(null);
              }}
              to={"/System/Requests/index"}
            >
              <p className=" text-mainText ">كل الطلبات</p>
            </Link>
          </div>
          <div className="pointer !w-full mt-0">
            <div className="   d-flex  justify-content-center flex-column">
              <Accordion defaultActiveKey={null}>
                <Accordion.Item eventKey="0">
                  <Accordion.Header
                    onClick={() => {
                      setActive(0);
                    }}
                    className={`  ${
                      active === 0
                        ? "border-2 rounded-md border-[#EFAA20] hover:border-transparent"
                        : "border-2 border-mainBorder rounded-md"
                    }`}
                  >
                    <Link
                      className="!w-full !text-right"
                      to={"System/Requests/Design"}
                    >
                      تصميم
                    </Link>

                    <MdKeyboardArrowDown size={20} />
                  </Accordion.Header>

                  <Accordion.Body>
                    <div className="tabs d-flex justify-content-center align-items-center flex-column">
                      <Link className="w-full" to={"System/Requests/Design/1"}>
                        <div
                          onClick={() => {
                            setDesignCategories({
                              converted: false,
                              inProgress: true,
                              waiting: false,
                              rejected: false,
                            });
                            setShowProject(false);
                          }}
                          className={`tab ${
                            active === 0 && designCategories.inProgress
                              ? "border border-1 rounded-md hover:!border-[transparent]  !border-[#EFAA20] "
                              : "bg-mainNavy  "
                          } text-end w-100 p-2 mt-2`}
                        >
                          طلبات قيد التنفيذ
                        </div>
                      </Link>
                      <Link className="w-full" to={"System/Requests/Design/1"}>
                        <div
                          onClick={() => {
                            setDesignCategories({
                              inProgress: false,
                              converted: true,
                              waiting: false,
                              rejected: false,
                            });
                            setShowProject(false);
                          }}
                          className={`tab ${
                            active === 0 && designCategories.converted
                              ? "border border-1 rounded-md hover:!border-[transparent]  !border-[#EFAA20] "
                              : "bg-mainNavy  "
                          } text-end w-100 p-2 mt-2`}
                        >
                          طلبات قيد التنفيذ
                        </div>
                      </Link>
                      <Link className="w-full" to={"System/Requests/Design/0"}>
                        <div
                          onClick={() => {
                            setDesignCategories({
                              converted: false,
                              inProgress: false,
                              waiting: true,
                              rejected: false,
                            });
                            setShowProject(false);
                          }}
                          className={`tab ${
                            active === 0 && designCategories.waiting
                              ? "border border-1 rounded-md hover:!border-[transparent]  !border-[#EFAA20] "
                              : "bg-mainNavy  "
                          } text-end w-100 p-2 mt-2`}
                        >
                          طلبات في انتظار الموافقة
                        </div>
                      </Link>
                      <Link className="w-full" to={"System/Requests/Design/5"}>
                        <div
                          onClick={() => {
                            setDesignCategories({
                              converted: false,
                              inProgress: false,
                              waiting: false,
                              rejected: true,
                            });
                            setShowProject(false);
                          }}
                          className={`tab ${
                            active === 0 && designCategories.rejected
                              ? "border border-1 rounded-md hover:!border-[transparent]  !border-[#EFAA20] "
                              : "bg-mainNavy  "
                          } text-end w-100 p-2 mt-2`}
                        >
                          طلبات مرفوضة
                        </div>
                      </Link>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header
                    className={`  ${
                      active === 1
                        ? "border-2 rounded-md border-[#EFAA20] hover:border-transparent"
                        : "border-2 border-mainBorder rounded-md"
                    }`}
                    onClick={() => {
                      setActive(1);
                    }}
                  >
                    <Link
                      className="!w-full !text-right"
                      to={"System/Requests/Review"}
                    >
                      
                      اشراف علي التنفيذ
                    </Link>

                    <MdKeyboardArrowDown size={20} />
                  </Accordion.Header>

                  <Accordion.Body>
                    <div className="tabs d-flex justify-content-center align-items-center flex-column">
                      <Link className="w-full" to={"System/Requests/Review/1"}>
                        <div
                          onClick={() => {
                            setReviewCategories({
                              converted: false,
                              inProgress: true,
                              waiting: false,
                              rejected: false,
                            });
                            setShowProject(false);
                          }}
                          className={`tab ${
                            active === 1 && reviewCategories.inProgress
                              ? "border border-1 rounded-md hover:!border-[transparent]  !border-[#EFAA20] "
                              : "bg-mainNavy  "
                          } text-end w-100 p-2 mt-2`}
                        >
                          طلبات قيد التنفيذ
                        </div>
                      </Link>
                      {/* <Link className="w-full" to={"System/Requests/Review/1"}>
                        <div
                          onClick={() => {
                            setReviewCategories({
                              converted: true,
                              inProgress: false,
                              waiting: false,
                              rejected: false,
                            });
                            setShowProject(false);
                          }}
                          className={`tab ${
                            active === 1 && reviewCategories.converted
                              ? "border border-1 rounded-md hover:!border-[transparent]  !border-[#EFAA20] "
                              : "bg-mainNavy  "
                          } text-end w-100 p-2 mt-2`}
                        >
                          طلبات تم تحويلها الي مشاريع
                        </div>
                      </Link> */}
                      <Link className="w-full" to={"System/Requests/Review/0"}>
                        <div
                          onClick={() => {
                            setReviewCategories({
                              inProgress: false,
                              converted: false,
                              waiting: true,
                              rejected: false,
                            });
                            setShowProject(false);
                          }}
                          className={`tab ${
                            active === 1 && reviewCategories.waiting
                              ? "border border-1 rounded-md hover:!border-[transparent]  !border-[#EFAA20] "
                              : "bg-mainNavy  "
                          } text-end w-100 p-2 mt-2`}
                        >
                          طلبات في انتظار الموافقة
                        </div>
                      </Link>
                      <Link className="w-full" to={"System/Requests/Review/5"}>
                        <div
                          onClick={() => {
                            setReviewCategories({
                              inProgress: false,
                              converted: false,
                              waiting: false,
                              rejected: true,
                            });
                            setShowProject(false);
                          }}
                          className={`tab ${
                            active === 1 && reviewCategories.rejected
                              ? "border border-1 rounded-md hover:!border-[transparent]  !border-[#EFAA20] "
                              : "bg-mainNavy  "
                          } text-end w-100 p-2 mt-2`}
                        >
                          طلبات مرفوضة
                        </div>
                      </Link>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              {/* <Accordion defaultActiveKey={null}>
                    </Accordion> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllRequestsDropdown;
