import React, { useState } from "react";
import { Accordion } from "react-bootstrap";
import { MdKeyboardArrowDown } from "react-icons/md";
import { Link, useLocation } from "react-router-dom";

const RequestSidebar = ({ setDesignCategories, setShowProject }) => {
  const [activeEventKey, setActiveEventKey] = useState(null);
  const location = useLocation();

  const renderSubAccordion = (links, parentIndex) => {
    return (
      <Accordion className="w-100">
        {links.map((subItem, subIndex) => (
          <Accordion.Item
            eventKey={`${parentIndex}-${subIndex}`}
            key={subIndex}
            onClick={subItem.onClick}
          >
            <Link
              to={subItem.path}
              className={`${
                location.pathname === subItem.path
                  ? "text-[#EFAA20]"
                  : subItem.links?.length > 0
                  ? ""
                  : "technicalSupportTab"
              } w-full text-right`}
            >
              <Accordion.Header
                onClick={() => {
                  setActiveEventKey(`${parentIndex}-${subIndex}`);
                  // reset();
                }}
                className={` rounded-md  ${
                  activeEventKey === `${parentIndex}-${subIndex}`
                    ? "border-2 rounded-md border-[#EFAA20] hover:border-transparent"
                    : subItem.links?.length > 0
                    ? "border-2 border-mainBorder rounded-md"
                    : "hover:!border-[#EFAA20]"
                } pointer flex w-full justify-between`}
              >
                {subItem.label}
                {subItem.links?.length > 0 && <MdKeyboardArrowDown size={20} />}
              </Accordion.Header>
            </Link>
            {subItem.links && (
              <Accordion.Body>
                {renderSubAccordion(
                  subItem.links,
                  `${parentIndex}-${subIndex}`
                )}
              </Accordion.Body>
            )}
          </Accordion.Item>
        ))}
      </Accordion>
    );
  };

  const accordionItemsManager = [
    {
      eventKey: "10",
      header: "الداخلى",
      link: "System/Requests/index",
      links: [
        {
          path: "System/Requests/Design",
          label: "تصميم",
          onclick: () => {},
          links: [
            {
              path: "System/Requests/Design/66",
              label: "طلبات تم تحويلها الي مشاريع",
              onclick: () => {
                setDesignCategories({
                  inProgress: false,
                  converted: true,
                  waiting: false,
                  rejected: false,
                });
                setShowProject(false);
              },
            },
            {
              path: "System/Requests/Design/1",
              label: "طلبات قيد التنفيذ",
              onclick: () => {
                setDesignCategories({
                  inProgress: true,
                  converted: false,
                  waiting: false,
                  rejected: false,
                });
                setShowProject(false);
              },
            },
            {
              path: "System/Requests/Design/0",
              label: "طلبات في انتظار الموافقة",
              onclick: () => {
                setDesignCategories({
                  inProgress: false,
                  converted: false,
                  waiting: true,
                  rejected: false,
                });
                setShowProject(false);
              },
            },
            {
              path: "System/Requests/Design/5",
              label: "طلبات مرفوضة",
              onclick: () => {
                setDesignCategories({
                  inProgress: false,
                  converted: false,
                  waiting: false,
                  rejected: true,
                });
                setShowProject(false);
              },
            },
          ],
        },
        {
          path: "System/Requests/Review",
          label: "اشراف علي التنفيذ",
          onclick: () => {},
          links: [
            {
              path: "System/Requests/Review/66",
              label: "طلبات تم تحويلها الي مشاريع",
              onclick: () => {},
            },
            {
              path: "System/Requests/Review/1",
              label: "طلبات قيد التنفيذ",
              onclick: () => {},
            },
            {
              path: "System/Requests/Review/0",
              label: "طلبات في انتظار الموافقة",
              onclick: () => {},
            },
            {
              path: "System/Requests/Review/5",
              label: "طلبات مرفوضة",
              onclick: () => {},
            },
          ],
        },
      ],
    },
    // {
    //   eventKey: "1",
    //   header: "الخارجى",
    //   link: "System/Requests/index",
    //   links: [
    //     {
    //       path: "System/Requests/outcoming/Design",
    //       label: "تصميم",
    //       links: [
    //         {
    //           path: "System/Requests/outcoming/Design/66",
    //           label: "طلبات تم تحويلها الي مشاريع",
    //         },
    //         {
    //           path: "System/Requests/outcoming/Design/1",
    //           label: "طلبات قيد التنفيذ",
    //         },
    //         {
    //           path: "System/Requests/outcoming/Design/0",
    //           label: "طلبات في انتظار الموافقة",
    //         },
    //         {
    //           path: "System/Requests/outcoming/Design/5",
    //           label: "طلبات مرفوضة",
    //         },
    //       ],
    //     },
    //     {
    //       path: "System/Requests/outcoming/Review",
    //       label: "اشراف علي التنفيذ",
    //       links: [
    //         {
    //           path: "System/Requests/outcoming/Review/66",
    //           label: "طلبات تم تحويلها الي مشاريع",
    //         },
    //         {
    //           path: "System/Requests/outcoming/Review/1",
    //           label: "طلبات قيد التنفيذ",
    //         },
    //         {
    //           path: "System/Requests/outcoming/Review/0",
    //           label: "طلبات في انتظار الموافقة",
    //         },
    //         {
    //           path: "System/Requests/outcoming/Review/5",
    //           label: "طلبات مرفوضة",
    //         },
    //       ],
    //     },
    //   ],
    // },
  ];

  const newData = accordionItemsManager;

  return (
    <>
      <Accordion className="!w-full" defaultActiveKey={null}>
        <div className="py-3">
          <Link
            to="/System/Requests/index"
            className="text-mainText text-right"
          >
            كل الطلبات
          </Link>
        </div>

        {newData.map((item, index) => (
          <Accordion.Item eventKey={item.eventKey} key={item.eventKey}>
            {item.eventKey !== "2" ? (
              <Link
                to={`/${item.link}`}
                className={`${
                  location.pathname.startsWith(`/${item.link}`)
                    ? "text-[#EFAA20]"
                    : "text-mainText"
                }`}
              >
                <Accordion.Header
                  onClick={() => {
                    setActiveEventKey(item.eventKey);
                    // reset();
                  }}
                  className={`border-2 rounded-md hover:!border-[#EFAA20] ${
                    activeEventKey === item.eventKey
                      ? "border-2 rounded-md border-[#EFAA20] hover:border-transparent"
                      : "border-2 border-mainBorder rounded-md"
                  } pointer`}
                >
                  {item.header}
                  <MdKeyboardArrowDown size={20} />
                </Accordion.Header>
              </Link>
            ) : (
              <Accordion.Header
                onClick={() => {
                  setActiveEventKey(item.eventKey);
                  // reset();
                }}
                className={`border-2 rounded-md hover:!border-[#EFAA20] ${
                  activeEventKey === item.eventKey
                    ? "border-2 rounded-md border-[#EFAA20] hover:border-transparent"
                    : "border-2 border-mainBorder rounded-md"
                } pointer`}
              >
                {item.header}
                <MdKeyboardArrowDown size={20} />
              </Accordion.Header>
            )}
            <Accordion.Body>
              {renderSubAccordion(item.links, index)}
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
    </>
  );
};

export default RequestSidebar;
