import Cookies from "js-cookie";
import {
  fetcher,
  readMessage,
  readNotification,
} from "../../helper/fetchers/Notifications";
import { useQuery, useMutation, useQueryClient } from "react-query";

export const useGetNotifications = () => {
  const user = Cookies.get("accessToken") ? Cookies.get("accessToken") : null;

  return useQuery("notifications", fetcher, {
    enabled: !!user,
  });
};

export const useReadNotification = () => {
  const queryClient = useQueryClient();
  return useMutation(readNotification, {
    onSuccess: () => {
      queryClient.invalidateQueries("notifications");
    },
    onError: (error) => {},
  });
};

export const useReadMessage = () => {
  const queryClient = useQueryClient();
  return useMutation(readMessage, {
    onSuccess: () => {
      queryClient.invalidateQueries("notifications");
    },
    onError: (error) => {},
  });
};
