import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import SystemControler from "../../../Components/System/SystemControler/SystemControler";
import { FaCaretRight, FaDownload } from "react-icons/fa6";
import { FaFileDownload } from "react-icons/fa";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import { FormControl } from "@mui/material";
// import Button from "react-bootstrap/Button";
import Image from "../../../Components/Image";
import { Modal } from "react-bootstrap";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { InputLabel } from "../PlanModel/components/InputLabel";
import { getUserProfile } from "../../../helper/fetchers/Profie";
import { toast } from "react-toastify";
import Progress from "../../../Components/Progress";
import moment from "moment";
import { FaPlus } from "react-icons/fa6";
import AddAttachment from "../../../Components/System/AddAttachment";
import { updateProfilePic } from "../../../helper/fetchers/Users";
import SuccessfullModal from "../../../Components/Modals/SuccessfullModal";
import { UsersParamsContext } from "../../../Context/UsersParamsContext";
import UpdatePasswordForm from "../../../Components/Forms/UpdatePasswordForm/UpdatePasswordForm";
// import { FaFileDownload } from "react-icons/fa";
import Cookies from "js-cookie";
import UpdateFormModal from "./UpdateFormModal";
import { PdfIcon } from "../../../utiltis/Icons";
import { projectTypeEnum } from "../../../helper/utils";
import useModal from "../../../hooks/useModal";
import ProfileImage from "../../../Components/ProfileImage";
import {
  useGetUserProfile,
  useUpdateMyProfile,
  useUpdateUserPic,
} from "../../../hooks/fetchers/Users";
import useSuccessfullModal from "../../../Components/Modals/hooks/useSuccessfullModal";
import UploadFileModal from "../../../Components/Modals/UploadFileModal";
import { twMerge } from "tailwind-merge";
// AddProject.module.css";

const Profile = () => {
  const [myAttatchments, setMyAttatchments] = useState();

  const {
    open: openUpdateModal,
    handleCloseModal: handleCloseUpdate,
    handleOpenModal: handleOpenUpdate,
  } = useModal();
  const {
    open: showModal,
    handleCloseModal,
    handleOpenModal: handleShowModal,
  } = useModal();
  const navigate = useNavigate();

  // const [user, setUser] = useState(null);
  const [attachment, setAttachment] = useState(null);
  const [openImagePicker, setOpenImagePicker] = useState(false);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const { user } = useContext(UsersParamsContext);
  const [message, setMessage] = useState("");

  // const getUser = useCallback(async () => {
  //   try {
  //     const { data } = await getUserProfile();
  //     if (data?.success) {
  //       setUser(data.user);
  //     } else {
  //       console.log("Data retrieval failed");
  //     }
  //   } catch (error) {
  //     toast.error(error?.response?.data?.message);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);
  const {
    handleShowSuccess,
    handleShowError,
    handleCloseSuccess,
    handleCloseError,
    showSuccess,
    showError,
  } = useSuccessfullModal();
  const {
    mutate: updatePicMutation,
    isLoading: updatePicIsLoading,
    isError: updatePicIsError,
    error: updatePicError,
  } = useUpdateUserPic(handleShowSuccess, handleShowError);
  const handleChangeImage = (submittedData) => {
    const formData = new FormData();
    formData.append("image", submittedData[0]);
    updatePicMutation(formData);
  };

  const myId = Cookies.get("userId");
  return (
    <>
      <SuccessfullModal
        show={showSuccess}
        message={"تم تعديل الصوره الشخصيه بنجاح"}
        handleClose={() => {
          handleCloseSuccess();
          setOpenImagePicker(false);
          // setShow(true);
          // getUser();
        }}
      />
      <SuccessfullModal
        show={showError}
        message={"حدث خطأ ما"}
        handleClose={handleCloseError}
        status="error"
      />

      {/* ************************************************* */}

      <SuccessfullModal
        show={show}
        loading={loading}
        message={message}
        handleClose={() => {
          setShow(false);
          // window.location.reload();
        }}
      />
      <SuccessfullModal
        show={isError}
        loading={loading}
        message={message}
        handleClose={() => {
          setShow(false);
          // window.location.reload();
        }}
        status="error"
      />
      <SystemControler
        child={
          <div className="flex gap-3">
            <FaCaretRight
              onClick={() => navigate(-1)}
              className="pointer"
              color="var(--main-text)"
            />
            <p className="text-mainText">صفحتى الشخصية</p>
          </div>
        }
      />
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        {/* <Container> */}
        <section className="Profile p-2 bg-mainItems  overflow-hidden border !border-[#EFAA20] rounded-[27px]  ">
          <div className="h-[819px] overflow-y-auto scrollbar-thin scrollbar-track-mainNavy scrollbar-thumb-[#E4A11B]">
            <h3 className="text-[#EFAA20] text-xl my-2 ">صفحتى الشخصية</h3>
            {user ? (
              <div className="p-1">
                <fieldset className="border p-3 !border-[#D5992133] w-3/4 mx-auto">
                  <legend className="text-center text-mainText">
                    معلوماتى الشخصية
                  </legend>
                  <CheckFilePath user={user} />
                  <div className="flex justify-between items-center">
                    <div className="mx-2 flex gap-3">
                      {/* should be user img */}
                      <div className="relative">
                        <ProfileImage image={user?.image} alt="user image" />
                        <button
                          className="absolute bottom-0 bg-white w-6 h-6 rounded-full left-[2rem] flex items-center justify-center"
                          onClick={() => setOpenImagePicker(true)}
                        >
                          <FaPlus className="text-base text-black" />
                        </button>
                        {/* 
                        <AddAttachment
                          attachment={attachment}
                          setAttachment={setAttachment}
                          multi={false}
                          open={openImagePicker}
                          setOpen={setOpenImagePicker}
                          modalOnly={true}
                          click={handleClick}
                        /> */}
                        {/* show, handleClose, handleSave, loading, name */}
                        <UploadFileModal
                          show={openImagePicker}
                          handleClose={() => {
                            setOpenImagePicker(false);
                          }}
                          loading={updatePicIsLoading}
                          name="image"
                          handleSave={handleChangeImage}
                        />
                      </div>
                      <div className="flex flex-col">
                        <p className="text-mainText text-xl">
                          {user.firstName}
                        </p>
                        <p className="text-[#EFAA20] text-base">{user.role}</p>
                        <span className="text-[#565674] text-sm">
                          {user.email}
                        </span>
                      </div>
                    </div>
                    <div className="">
                      <Button
                        variant="success"
                        className="bg-success"
                        onClick={handleOpenUpdate}
                      >
                        تعديل الملف الشخصى
                      </Button>
                      <UpdateFormModal
                        handleClose={handleCloseUpdate}
                        show={openUpdateModal}
                        userData={user}
                      />
                    </div>
                  </div>
                  <div className="bg-[#EFAA20] h-[1px]  w-90 text-start mt-3  "></div>
                  <div className="grid grid-cols-3 mt-4 gap-3">
                    <TextDisplay label={"الاسم الاول"} desc={user.firstName} />
                    <TextDisplay label={"الاسم الاخير"} desc={user.lastName} />
                    <TextDisplay label={"اسم المستخدم"} desc={user.userName} />
                    <TextDisplay
                      label={"البريد الالكترونى"}
                      desc={user.email}
                    />
                    <TextDisplay
                      label={"رقم الجوال"}
                      desc={<span dir="ltr">{user.phone}</span>}
                    />
                    <TextDisplay
                      label={"تاريخ بداية العمل "}
                      desc={moment(user.startWork).format("YYYY-MM-DD")}
                    />
                    <TextDisplay label={"المدينة"} desc={user.city} />
                    <TextDisplay label={"الحي"} desc={user.district} />
                    <TextDisplay label={"الصلاحية"} desc={user.role} />
                    <TextDisplay label={"رقم الهوية"} desc={user.idNumber} />
                    <TextDisplay
                      label={"تاريخ الهوية"}
                      desc={moment(user.idDate).format("YYYY-MM-DD")}
                    />
                    <TextDisplay label={"القسم"} desc={user.department} />
                    <TextDisplay
                      label={"الكود الوظيفى"}
                      desc={user.employeCode}
                    />
                    <TextDisplay
                      label={"الفئة"}
                      desc={projectTypeEnum[user?.specialization]}
                    />
                  </div>
                </fieldset>
                <fieldset className="border p-4 my-4 !border-[#D5992133] w-3/4 mx-auto">
                  <legend className="text-center text-mainText">
                    نبذة عنى
                  </legend>

                  <FormControl className="!w-full">
                    <TextDisplay
                      label={"نبذة عني"}
                      desc={user.bio}
                      descClassName="h-[137px]"
                    />
                  </FormControl>
                </fieldset>
                <fieldset className="border p-4 my-4 !border-[#D5992133] w-3/4 mx-auto">
                  <legend className="text-center text-mainText">
                    مرفقات التوظيف
                  </legend>

                  <FormControl className="relative w-full flex gap-6 overflow-y-hidden overflow-x-auto scrollbar-thin scrollbar-track-transparent scrollbar-thumb-[#EFAA20] pb-14">
                    {user.fileUser && (
                      <Fragment>
                        {user.fileUser.length > 0 ? (
                          user.fileUser.map((file, index) => (
                            <div key={index}>
                              <div
                                className="border flex my-3 justify-between shrink-0 bg-mainItems px-2 !border-[#EFAA20] w-full h-[55px] rounded-[3.37px]"
                                key={index}
                              >
                                <div className="flex items-center gap-3">
                                  {/* <PdfImage
                                onClick={() => {
                                  setOpenPdf(true);
                                }}
                                width="99.81px"
                                height="53px"
                                openPdf={openPdf}
                                setOpenPdf={setOpenPdf}
                                pdfSrc={`${file}`}
                              /> */}
                                  {/* <Example
                                    show={showModal}
                                    handleClose={handleCloseModal}
                                    content={file}
                                  /> */}

                                  <div
                                    className="bg-mainNavy grid place-content-center w-full h-full "
                                    onClick={() => {
                                      setMyAttatchments(file);
                                      handleShowModal();
                                    }}
                                  >
                                    <PdfIcon />
                                  </div>

                                  {/* <p className="text-mainText">اسم الملف</p> */}
                                </div>
                                <div className="flex mx-3 items-center gap-3">
                                  {/* <Button
                                    
                                    href={file}
                                    download={true}
                                    className="!bg-[#03795D] hover:!bg-[#03795D] flex justify-center items-center rounded-[3px] pointer w-[22px] h-[25px]"
                                  >
                                    <FaDownload color="var(--main-text)" size={15} />
                                  </Button> */}
                                  <Button
                                    className="!bg-[#03795D] hover:!bg-[#03795D] flex justify-center items-center rounded-[3px] pointer "
                                    download={true}
                                    href={file}
                                  >
                                    <FaDownload size={15} color="#FFF" />
                                  </Button>
                                </div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <p className="text-center text-xl text-[#EFAA20] ">
                            لا يوجد مرفقات لعرضها
                          </p>
                        )}
                      </Fragment>
                    )}
                  </FormControl>
                </fieldset>
                <Example
                  show={showModal}
                  handleClose={handleCloseModal}
                  content={myAttatchments}
                />
                <fieldset className="border p-4 my-4 !border-[#D5992133] w-3/4 mx-auto">
                  <legend className="text-center text-mainText">مرفقاتى</legend>

                  <FormControl className="!w-full">
                    <div className="relative w-full flex gap-6 overflow-y-hidden overflow-x-auto scrollbar-thin scrollbar-track-transparent scrollbar-thumb-[#EFAA20] pb-14">
                      {user.personalAttach.length > 0 ? (
                        user.personalAttach.map((file, index) => {
                          let filePath = file;
                          if (getType(file) === "zip") {
                            filePath =
                              "https://p7.hiclipart.com/preview/258/300/47/computer-icons-zip-truetype-icon-png-file-zip.jpg";
                          }
                          console.log("File is:", file);
                          const fileType = getType(file);
                          console.log("fileType is:", fileType);
                          return (
                            <div
                              className="border shrink-0 flex justify-between bg-[#1E1E2D] overflow-hidden !border-[#EFAA20] w-24 h-24  rounded-md"
                              key={index}
                            >
                              <button className="w-full">
                                {getType(file) === "pdf" ? (
                                  <div
                                    className="bg-mainNavy grid place-content-center w-full h-full "
                                    onClick={() => {
                                      setMyAttatchments(file);
                                      handleShowModal();
                                    }}
                                  >
                                    <PdfIcon />
                                  </div>
                                ) : (
                                  <img
                                    src={filePath}
                                    alt="pdf"
                                    className=" m-auto w-full h-full"
                                    // onClick={handleShowModal}
                                    onClick={() => {
                                      setMyAttatchments(file);
                                      handleShowModal();
                                    }}
                                  />
                                )}
                              </button>
                            </div>
                          );
                        })
                      ) : (
                        <p className="text-center text-xl text-[#EFAA20] ">
                          لا يوجد مرفقات لعرضها
                        </p>
                      )}

                      {/* <div className="">
                        <Image
                          src={"/icons/Pdf.png"}
                          alt="pdf"
                          className=" m-auto w-[26.94px] h-[26.94px] "
                          onClick={handleShowModal}
                        />
                      </div> */}
                    </div>

                    {/* {user.personalAttach && (
                      <Fragment>
                        {user.personalAttach.length > 0 ? (
                          user.personalAttach.map((file, index) => (
                            <div key={index}>
                              <div
                                className="border flex my-3 justify-between bg-[#1E1E2D] px-2 !border-[#EFAA20] w-full h-[55px] rounded-[3.37px]"
                                key={index}
                              >
                                <div className="flex items-center gap-3">
                                  <Example
                                    show={showModal}
                                    handleClose={handleCloseModal}
                                    content={file}
                                  />
                                  <button>
                                    <Image
                                      src={"/icons/Pdf.png"}
                                      alt="pdf"
                                      className=" m-auto w-[26.94px] h-[26.94px] "
                                      onClick={handleShowModal}
                                    />
                                  </button>
                                </div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <p className="text-center text-xl text-[#EFAA20] ">
                            لا يوجد مرفقات لعرضها
                          </p>
                        )}
                      </Fragment>
                    )} */}
                  </FormControl>
                </fieldset>

                {myId ? (
                  <fieldset className="border p-4 my-4 !border-[#D5992133] w-3/4 mx-auto">
                    <legend className="text-center text-mainText">
                      تحديث كلمة السر
                    </legend>
                    <UpdatePasswordForm
                      id={myId}
                      onSuccess={() => {
                        setShow(true);
                        setMessage("تم تحديث كلمة السر بنجاح");
                      }}
                      oError={() => {
                        setMessage("حدث خطأ ما");
                        setIsError(true);
                      }}
                    />
                  </fieldset>
                ) : null}

                {/* <div className="flex justify-end">
                  <SaveButton
                    onClick={() => {
                      navigate(-1);
                    }}
                  />
                </div> */}
              </div>
            ) : (
              <Progress />
            )}
          </div>
        </section>
        {/* </Container> */}
      </LocalizationProvider>
    </>
  );
};

export default Profile;
const CheckFilePath = ({ user }) => {
  const imageExtensions = [
    "jpg",
    "jpeg",
    "png",
    "gif",
    "bmp",
    "svg",
    "webp",
    "tiff",
    "tif",
  ];

  if (user && user.fileUser && user.fileUser.length > 0) {
    for (const file of user.fileUser) {
      const fileExtension = file.split(".").pop().toLowerCase(); // Get the file extension and convert it to lowercase
      const isImage = imageExtensions.includes(fileExtension);

      if (isImage) {
        return true; // If an image is found, return true
      }
    }
  }

  return false; // If no image is found, return false
};
const getType = (fileName) => {
  const fileExtension = fileName?.split(".")?.pop()?.toLowerCase();
  return fileExtension;
};
function Example({ show, handleClose, content }) {
  const fileType = getType(content);

  return (
    <Modal
      size="lg"
      centered
      show={show}
      onHide={handleClose}
      contentClassName="p-0 bg-mainNavy w-fit mx-auto"
      className="bg-black/50"
    >
      <Modal.Body className="flex flex-col items-center">
        {fileType === "jpg" ||
        fileType === "jpeg" ||
        fileType === "png" ||
        fileType === "gif" ? (
          <Fragment>
            {/* <Button className="bg-mainItems" download={true} href={content}>
              <FaFileDownload fontSize={25} color="#EFAA20" />
            </Button> */}
            <Button className="bg-mainItems" download={true} href={content}>
              <FaFileDownload fontSize={25} color="#EFAA20" />
            </Button>
            <img className="w-96  rounded-xl border" src={content} alt="" />
          </Fragment>
        ) : fileType === "pdf" ? (
          <Fragment>
            <Button className="bg-mainItems" download={true} href={content}>
              <FaFileDownload fontSize={25} color="#EFAA20" />
            </Button>
            <embed
              src={content}
              type="application/pdf"
              width="400"
              height="600"
              className="w-96 relative rounded-xl border"
            />
          </Fragment>
        ) : fileType === "zip" ? (
          <Fragment>
            <Button className="bg-mainItems" download={true} href={content}>
              <FaFileDownload fontSize={25} color="#EFAA20" />
            </Button>
            <img
              className="w-96   rounded-xl border"
              src="https://p7.hiclipart.com/preview/258/300/47/computer-icons-zip-truetype-icon-png-file-zip.jpg"
              alt=""
            />
          </Fragment>
        ) : fileType === "rar" ? (
          <Fragment>
            <Button className="bg-mainItems" download={true} href={content}>
              <FaFileDownload fontSize={25} color="#EFAA20" />
            </Button>
            <img
              className="w-96  rounded-xl border"
              src="https://cdn-icons-png.freepik.com/512/9158/9158683.png"
              alt=""
            />
          </Fragment>
        ) : null}

        {/* {content} */}
      </Modal.Body>
    </Modal>
  );
}

const TextDisplay = ({ label, desc, descClassName = "" }) => {
  return (
    <div className="space-y-2">
      <div className="">
        <p className="text-mainText text-xs">{label}</p>
      </div>
      <div
        className={twMerge(
          "text-mainText form-control h-[37px] bg-[#2B2B40] rounded-[7px]",
          descClassName
        )}
      >
        {desc}
      </div>
    </div>
  );
};
