import { useMutation, useQueryClient } from "react-query";
import { myAxiosJson } from "../../../../helper/https";

const postMessage = (data) =>
  myAxiosJson.post("/chat", data).catch((err) => {
    console.log(err);
  });

const useMutateChat = (onSuccess, onError) => {
  const queryClient = useQueryClient();
  return useMutation((data) => postMessage(data), {
    onSuccess: () => {
      queryClient.invalidateQueries("chat");
      queryClient.invalidateQueries("notifications");
      onSuccess();
    },
    onError,
  });
};

export default useMutateChat;
