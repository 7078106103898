import React, { createContext, useState } from "react";

export const ReminderPageProvider = React.createContext();
const ReminderContext = ({ children }) => {
  const [addReminder, setAddReminder] = useState(false);
  const [showReminder, setShowReminder] = useState(false);
  const [EditReminder, setEditReminder] = useState(false);
  const [reminder, setReminder] = useState(null);
  const [showReminderPopup, setShowReminderPopup] = useState(true);

  return (
    <ReminderPageProvider.Provider
      value={{
        addReminder,
        setAddReminder,
        showReminder,
        setShowReminder,
        reminder,
        setReminder,
        EditReminder,
        setEditReminder,
        showReminderPopup,
        setShowReminderPopup,
      }}
    >
      {children}
    </ReminderPageProvider.Provider>
  );
};

export default ReminderContext;
