import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";




function AddModal({ title, show, handleClose, handleSave, name, isLoading , formOptions ,defaultValue}) {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      [name]: defaultValue ? defaultValue : "",
    },
    ...formOptions
  });
  const onSubmit = (data) => {
    // console.log(data);
    handleSave(data);
    reset({
      [name]: "",
    });
  };
  return (
    <Modal
      centered
      contentClassName="bg-mainItems border !max-w-[700px] !border-[#EFAA20] !rounded-[20px] text-mainText"
      show={show}
      onHide={handleClose}
      size="lg"
    >
      <ModalHeader title={title} handleClose={handleClose} />
      <Form onSubmit={handleSubmit(onSubmit)} className="w-full">
        <Modal.Body className="w-full">
          <input
            className="w-full bg-mainNavy py-3 px-2 rounded-[7px] outline-none"
            type="text"
            placeholder="ادخل الاسم"
            {...register(name, 
              {
              required: "يجب إدخال الاسم",
            }
          
          )}
            // value={inputVal}
            // onChange={(e) => setNewValue(e.target.value)}
          />
          <div className="">
            {errors[name] && (
              <span className="text-red-500">{errors[name].message}</span>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer className="border-none">
          <div className="flex justify-center">
            <button
              type={"submit"}
              className="bg-[#EFAA20] text-base border-solid text-white !w-[139px] !h-[28px] !rounded-md  hover:border-2 hover:border-[#EFAA20] "
            >
              {isLoading ? <Spinner size="sm" animation="border" /> : "حفظ"}
            </button>
          </div>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

const ModalHeader = ({ title, handleClose }) => {
  return (
    <Modal.Header className="!justify-between border-none w-full">
      <Modal.Title className="text-xl">{title}</Modal.Title>
      <Button
        className="p-0 bg-transparent hover:bg-transparent"
        onClick={handleClose}
      >
        <img src="/Rejected.svg" alt="" />
      </Button>
    </Modal.Header>
  );
};

export default AddModal;
