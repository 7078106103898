// eslint-disable-next-line no-unused-vars
import { useEffect, useState } from "react";
import { myAxiosJson } from "../../../../helper/https";
import { useQuery } from "react-query";

const fetcher = () =>
  myAxiosJson("projects/projectName").then((data) => data?.data?.projects);

export const useGetProjects = () => {
  return useQuery("request/project/select", fetcher);
};
