import { createContext, useState } from "react";

export const TechnicalSupportContext = createContext(null);

export const TechnicalSupportProvider = ({ children }) => {
  const [addTasksOpen, setAddTasksOpen] = useState(false);
  const [showTasksOpen, setShowTasksOpen] = useState(false);
  const [task, setTask] = useState(null);
  const [addOrdersOpen, setAddOrdersOpen] = useState(false);
  const [showOrdersOpen, setShowOrdersOpen] = useState(false);
  const [order, setOrder] = useState(null);
  const [ordersTitle, setOrdersTitle] = useState("كل الطلبات");
  const [helpRequestsTitle, setHelpRequestsTitle] = useState("كل الطلبات");
  const [addTermsOpen, setAddTermsOpen] = useState(false);
  const [addTermsOpenSubCategory, setAddTermsOpenSubCategory] = useState(false);
  const [terms, setTerms] = useState(null);
  const [activeCategory, setActiveCategory] = useState("");
  const [activeSubCategory, setActiveSubCategory] = useState("");
  const [updatedItem, setUpdatedItem] = useState(null);
  const [activeCategorySubCategory, setActiveCategorySubCategory] =
    useState("");
  const [updatedItemSubCategory, setUpdatedItemSubCategory] = useState(null);

  const [openTerm, setOpenTerm] = useState(false);
  return (
    <TechnicalSupportContext.Provider
      value={{
        addTasksOpen,
        setAddTasksOpen,
        showTasksOpen,
        setShowTasksOpen,
        task,
        setTask,
        addOrdersOpen,
        setAddOrdersOpen,
        showOrdersOpen,
        setShowOrdersOpen,
        order,
        setOrder,
        addTermsOpen,
        setAddTermsOpen,
        terms,
        setTerms,
        activeCategory,
        setActiveCategory,
        updatedItem,
        setUpdatedItem,
        addTermsOpenSubCategory,
        setAddTermsOpenSubCategory,
        activeCategorySubCategory,
        setActiveCategorySubCategory,
        updatedItemSubCategory,
        setUpdatedItemSubCategory,
        activeSubCategory,
        setActiveSubCategory,
        openTerm,
        setOpenTerm,
        ordersTitle,
        setOrdersTitle,
        helpRequestsTitle,
        setHelpRequestsTitle,
      }}
    >
      {children}
    </TechnicalSupportContext.Provider>
  );
};
