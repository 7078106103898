import joi from "joi";
import { getErrorMessages } from "../global";

export const subServiceSchema = (lang) =>
  joi
    .object({
      name: joi
        .string()
        .pattern(new RegExp(/^([A-Za-z ]+|[\u0600-\u06FF ]+)$/))
        .required()
        .min(4)
        .max(500)
        .messages(
          getErrorMessages(lang, "sub service name", "خدمة المشروع الفرعى", 4, 10)
        ),
    })
    .required();
export const updateSubServiceSchema = (lang) =>
  joi
    .object({
      name: joi
        .string()
        .pattern(new RegExp(/^([A-Za-z ]+|[\u0600-\u06FF ]+)$/))
        .required()
        .min(4)
        .max(500)
        .messages(
          getErrorMessages(lang, "sub service name", "خدمة المشروع الفرعى", 4, 10)
        ),
    })
