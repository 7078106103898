import { useQuery, useMutation, useQueryClient } from "react-query";
import {
  getAllServiceCategories,
  addServiceCategory,
  updateServiceCategory,
  deleteServiceCategory,
  getServiceCategory,
} from "../../helper/fetchers/ServiceCategory";

export const useGetAllServiceCategories = (params) => {
  const query = useQuery(["service-category",{params}],()=> getAllServiceCategories(params));
  return query;
};

export const useGetServiceCategory = (id) => {
  const query = useQuery(["service-category", id], () =>
    getServiceCategory(id)
  );
  return query;
};

export const useAddServiceCategory = (onSuccess, onError) => {
  const queryClient = useQueryClient();
  return useMutation(addServiceCategory, {
    onSuccess: () => {
      queryClient.invalidateQueries("service-category");
      onSuccess();
    },
    onError: (error) => {
      onError(error);
    },
  });
};

export const useUpdateServiceCategory = (id, onSuccess, onError) => {
  const queryClient = useQueryClient();
  return useMutation((data) => updateServiceCategory(id, data), {
    onSuccess: () => {
      queryClient.invalidateQueries("service-category");
      onSuccess();
    },
    onError: (error) => {
      onError(error);
    },
  });
};

export const useDeleteServiceCategory = (onSuccess, onError) => {
  const queryClient = useQueryClient();
  return useMutation(deleteServiceCategory, {
    onSuccess: () => {
      queryClient.invalidateQueries("service-category");
      onSuccess();
    },
    onError: (error) => {
      onError(error);
    },
  });
};
