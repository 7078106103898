import "./index.css";
import DesignRequestChart from "../../../../Components/System/Requests/DesignRequestChart/DesignRequestChart";
import ShowDesignRequest from "../../../../Components/System/ShowRequest/ShowDesignRequest";
import EditDesignRequest from "../../../../Components/System/Requests/EditRequest/EditDesignRequest";
import ConfirmPoper from "../../../../Components/System/ConfirmPoper";
import Image from "../../../../Components/Image.jsx";
import CustomTable from "../../../../Components/Table/index.jsx";
import { TableRow } from "../../../../Components/Table/TableRow.jsx";
import { TableCell } from "../../../../Components/Table/TableCell.jsx";
import Progress from "../../../../Components/Progress.jsx";
import moment from "moment";
import { CheckRole, GenerateColumnsNames } from "../../../../helper/utils.jsx";
import { SearchComponent } from "../../../../Components/SearchComponent/SearchComponent.jsx";
import CustomCheckboxTable from "../../../../Components/CustomCheckboxTable.jsx";
import { RiDeleteBin6Line } from "react-icons/ri";
import LoadingPage from "../../../../Components/feedback/LoadingPage.jsx";
import SuccessfullModal from "../../../../Components/Modals/SuccessfullModal.jsx";
import { useNavigate } from "react-router-dom";
import DesignRequestProvider, {
  useDesignRequestContext,
} from "./DesignRequestContext.jsx";
import TableDeleteButton from "../../../../Components/Buttons/TableDeleteButton.jsx";
import { roles } from "../../../../helper/fetchers/Tasks.jsx";
const columns = GenerateColumnsNames(
  "تحديد",
  "م",
  "اسم المشروع",
  "رقم الطلب ",
  "تاريخ الانشاء",
  "نوع المشروع",
  "الحالة",
  "عرض",
  "تعديل"
);
const DesignRequest = () => {
  // const [showProject, setShowProject] = useState(false);
  // const [editRequest, setEditRequest] = useState(false);
  // const [ConfirmUpdate, setConfirmUpdate] = useState(false);
  // const [page, setPage] = useState(1);
  // const [DesignProjectType, SetDesignProjectType] = useState("");
  // const [id, setId] = useState(null);
  // const [search, setSearch] = useState(null);

  // const { data, isLoading, isError } = useGetAllRequests({
  //   page,
  //   projectType: 1,
  //   search,
  // });

  // const DesignProjects = Array.from({ length: 10 }).map((_, index) => {
  //   return {
  //     id: index + 1,
  //     ProjectName: "BSA",
  //     ProjectNumber: "53543",
  //     createdAt: "12-10-2023",
  //     ProjectType: "تصميم",
  //     status: "قيد الا نتظار",
  //     enStatus: "inProgress",
  //     display: (
  //       <Image
  //         src={process.env.PUBLIC_URL + "/icons/view.svg"}
  //         onClick={() => {
  //           setShowProject(true);
  //           SetDesignProjectType(DesignProjects[index]?.enStatus);
  //         }}
  //         className="display_project  rounded"
  //         alt=" display project"
  //       />
  //     ),
  //     edit: (
  //       <Image
  //         src={process.env.PUBLIC_URL + "/icons/edit.svg"}
  //         onClick={() => {
  //           setEditRequest(true);
  //         }}
  //         className=" edit_project  rounded"
  //         alt=" edit project"
  //       />
  //     ),
  //   };
  // });

  // const handleChange = (event, value) => {
  //   setPage(value);
  // };

  // const [stats, setStats] = useState();
  // const getRequests = useCallback(async () => {
  //   const response = await getAllRequestsStats();
  //   if (response?.status === 200) {
  //     setStats(response?.data);
  //   }
  // }, []);
  // useEffect(() => {
  //   getRequests();
  // }, [getRequests]);

  // const handleSearch = (val) => {
  //   setSearch(val);
  //   console.log(`Search:`, val);
  // };
  // const [checked, setChecked] = useState([]);
  // const {
  //   handleShowSuccess,
  //   handleShowError,
  //   handleCloseSuccess,
  //   handleCloseError,
  //   showSuccess,
  //   showError,
  // } = useSuccessfullModal();
  // const {
  //   mutate: deleteMutation,
  //   isLoading: deleteIsLoading,
  //   isError: deleteIsError,
  // } = useDeleteRequest(
  //   checked.join(","),
  //   () => {
  //     handleShowSuccess();
  //     setChecked([]);
  //   },
  //   handleShowError
  // );
  // const handleChecked = (val) => {
  //   if (checked.includes(val)) {
  //     setChecked(checked.filter((item) => item !== val));
  //   } else {
  //     setChecked([...checked, val]);
  //   }
  // };

  // const handleDeleteAll = () => {};
  // const handleDeleteSelected = () => {
  //   deleteMutation();
  // };
  return (
    <DesignRequestProvider>
      <RequestsWrapper>
        <RequestsBody />
      </RequestsWrapper>
    </DesignRequestProvider>
  );
};

const RequestChart = ({ stats }) => {
  return (
    <div className="designChartContainer d-flex justify-content-center align-items-center">
      <DesignRequestChart stats={stats} />
    </div>
  );
};

const RequestsTable = ({
  setShowProject,
  SetDesignProjectType,
  DesignProjects,
  setId,
  setEditRequest,
}) => {
  const navigate = useNavigate();
  const {
    requestApi,
    page,
    search,
    handleChange,
    handleSearch,
    checked,
    handleChecked,
    handleDeleteAll,
    handleDeleteSelected,
  } = useDesignRequestContext();
  return (
    <fieldset className="TableContainer  py-3 px-2 mx-auto mt-3">
      <legend className="text-center text-mainText">طلبات ( تصميم )</legend>
      <div className="">
        <div className="w-3/4 mx-auto ">
          <SearchComponent
            handleChange={handleSearch}
            value={search}
            className={""}
            border="border !border-[#E4A11B]"
          />
        </div>
      </div>
      {CheckRole(roles["admin"]) && !requestApi.isLoading &&
      !requestApi.isError &&
      requestApi.data?.request.length > 0 ? (
        <TableDeleteButton
          checked={checked}
          dataLength={requestApi.data?.request.length}
          onClickDeleteAll={handleDeleteAll}
          onClickDeleteSelected={handleDeleteSelected}
        />
      ) : null}
      <div className="mt-3 !h-[300px] overflow-y-auto scrollbar-thin scrollbar-track-mainNavy scrollbar-thumb-[#E4A11B]">
        {requestApi.isLoading ? <Progress /> : null}
        {requestApi.isError ? (
          <p className="text-red-500 text-center text-lg">يوجد خطأ ما</p>
        ) : null}
        {!requestApi.isLoading &&
        !requestApi.isError &&
        requestApi.data.request ? (
          <CustomTable
          columns={CheckRole(roles["admin"]) ? columns : columns.slice(1)}
            data={requestApi.data?.request}
            paginationProps={{
              count: requestApi.data.pages,
              page: page,
              onChange: handleChange,
            }}
          >
            {requestApi.data?.request && requestApi.data?.request.length > 0
              ? requestApi.data?.request.map(
                  (
                    {
                      _id,
                      projectName,
                      orderNumber,
                      createdAt,
                      projectType,
                      status,
                      enStatus,
                      display,
                      edit,
                    },
                    index
                  ) => (
                    <TableRow
                      className={`my-2 border !border-[#efaa207f] ${
                        index % 2 === 0 ? "bg-mainNavy" : ""
                      }`}
                      key={_id}
                    >
                      {CheckRole(roles["admin"]) ? (
                      <TableCell>
                        <CustomCheckboxTable
                          isChecked={checked?.some((x) => x === _id)}
                          handleClick={() => handleChecked(_id)}
                        />
                      </TableCell>
                      ) : null}
                      <TableCell>{(page - 1) * 10 + (index + 1)}</TableCell>
                      <TableCell>{projectName}</TableCell>
                      <TableCell>{orderNumber}</TableCell>
                      <TableCell>
                        {moment(createdAt).format("YYYY-MM-DD")}
                      </TableCell>
                      <TableCell>تصميم</TableCell>
                      <TableCell>
                        {status == 0
                          ? "في الانتظار"
                          : status == 1
                          ? "قيد التنفيذ"
                          : status == 66
                          ? "تم تحويلها الي مشروع"
                          : "مرفوضة"}
                      </TableCell>
                      <TableCell>
                        <Image
                          src={process.env.PUBLIC_URL + "/icons/view.svg"}
                          onClick={() => {
                            setShowProject(true);
                            SetDesignProjectType(
                              DesignProjects[index]?.enStatus
                            );
                            // setId(_id);
                            navigate(`/System/Requests/${_id}`);
                          }}
                          className="display_project  rounded"
                          alt=" display project"
                        />
                      </TableCell>
                      <TableCell>
                        <Image
                          src={process.env.PUBLIC_URL + "/icons/edit.svg"}
                          onClick={() => {
                            setId(_id);
                            setEditRequest(true);
                          }}
                          className=" edit_project  rounded"
                          alt=" edit project"
                        />
                      </TableCell>
                    </TableRow>
                  )
                )
              : null}
          </CustomTable>
        ) : null}
      </div>
    </fieldset>
  );
};

const RequestsBody = () => {
  const {
    showProject,
    setShowProject,
    DesignProjectType,
    id,
    setId,
    stats,
    DesignProjects,
    setEditRequest,
    SetDesignProjectType,
  } = useDesignRequestContext();
  return (
    <>
      {showProject ? (
        <div className="AllRequests-scroll h-full scrollbar-none">
          <ShowDesignRequest
            DesignProjectType={DesignProjectType}
            setShowProject={setShowProject}
            id={id}
            // getDesignRequests={getDesignRequests}
          />
        </div>
      ) : (
        <div className="AllRequests  h-full p-3 ">
          <RequestChart stats={stats} />
          <RequestsTable
            setShowProject={setShowProject}
            SetDesignProjectType={SetDesignProjectType}
            DesignProjects={DesignProjects}
            setId={setId}
            setEditRequest={setEditRequest}
          />
        </div>
      )}
    </>
  );
};

const RequestsWrapper = ({ children }) => {
  const {
    deleteApi,
    successfullModal,
    editRequest,
    id,
    ConfirmUpdate,
    setEditRequest,
    setConfirmUpdate,
  } = useDesignRequestContext();
  return (
    <>
      {deleteApi.deleteIsLoading ? <LoadingPage /> : null}
      <SuccessfullModal
        handleClose={successfullModal.handleCloseSuccess}
        message={"تم الحذف بنجاح"}
        show={successfullModal.showSuccess}
      />
      <SuccessfullModal
        handleClose={successfullModal.handleCloseError}
        message={"حدث خطأ ما"}
        show={successfullModal.showError}
        status="error"
      />
      {editRequest ? (
        <EditDesignRequest
          id={id}
          editRequest={editRequest}
          setEditRequest={setEditRequest}
          handleClose={() => {
            setEditRequest(false);
          }}
        />
      ) : null}
      {children}
      {ConfirmUpdate && (
        <>
          <ConfirmPoper
            confirmPoper={ConfirmUpdate}
            setConfirmPoper={setConfirmUpdate}
            setEditRequest={setEditRequest}
            text={"تم تعديل الطلب فى المشاريع بنجاح  "}
          />
        </>
      )}
    </>
  );
};

export default DesignRequest;
