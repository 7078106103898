import { toast } from "react-toastify";
import myAxiosInstance, { myAxios, myAxiosJson } from "../https";

export const getHumanResourscesResoursces = () =>
  myAxiosJson("user/hr/statistics").then((res) => res.data).catch(error=>{
    toast.error(error?.response?.data?.message)
});
export const getAllHumanResoursces = (params = {}) =>
  myAxiosJson("human", { params }).then((res) => res.data).catch(error=>{
    toast.error(error?.response?.data?.message)
});
export const getHumanById = (id) => myAxios(`human/${id}`).catch(error=>{
  toast.error(error?.response?.data?.message)
});
export const createHumanResuroeService = (data) =>
  myAxiosInstance.post(`human`, data);
export const updateHumanResuroeService = (vacationId, data) =>
  myAxiosInstance.patch(`human/${vacationId}`, data);
export const acceptHumanResuroeService = (vacationId) =>
  myAxiosInstance.patch(`human/accepted/${vacationId}`);
export const rejectHumanResuroeService = (vacationId, data) =>
  myAxiosJson.patch(`human/rejected/${vacationId}`, data);
export const deleteHumanResuroeService = (vacationId) =>
  myAxiosInstance.delete(`human/${vacationId}`);
