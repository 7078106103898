import { createContext, useState } from "react";

export const ServiceContext = createContext()
export const ServiceProvider = ({children}) => {
    const [serviceId, setServiceId] = useState(null);
    return <ServiceContext.Provider value={{serviceId, setServiceId}}>
        {children}
    </ServiceContext.Provider>
}

