import React, { useContext } from "react";
import { Accordion } from "react-bootstrap";
import { MdKeyboardArrowDown } from "react-icons/md";
import { Link } from "react-router-dom";
import { addAccountType } from "../../../../Context/AddAccountaing";

const AccountantDropdown = ({
  setOpen,
  active,
  setActive,
  RevenuesCategory,
  setRevenuesCategories,
  ExpensesCategory,
  setExpensesCategories,
}) => {
  const renderCategoryLink = (path, label, condition, conditionStyles) => (
    <Link to={path}>
      <div
        onClick={condition}
        className={`tab text-end w-full border pointer flex justify-between px-1 py-2 ${
          conditionStyles
            ? " !border-[#EFAA20] rounded-md hover:!border-transparent"
            : "!border-transparent"
        }`}
      >
        <span>{label}</span>
      </div>
    </Link>
  );
  const { setOpenAddAccountant, setOpenDisplayDetails } =
    useContext(addAccountType);
  const handleItemsReportClick = () => {
    setOpenAddAccountant(false);
    setOpenDisplayDetails(false);
  };
  const handleItemsClick = () => {
    setExpensesCategories({
      ...ExpensesCategory,
      ExpensesReports: false,
      Items: true,
    });
    setOpenDisplayDetails(false);
  };
  return (
    <div className="absolute top-[2.8rem] right-12 z-[1000000] bg-mainNavy rounded-xl">
      <div className="flex items-start flex-col  bg-mainItems w-[300px] h-[300px]  p-2 overflow-auto shadow-2xl ">
        <div
          className="w-screen h-screen fixed inset-0  z-[-1]"
          onClick={() => {
            setOpen(false);
          }}
        />
        <div className="w-full">
          <Link
            onClick={() => {
              setActive(null);
            }}
            to="/System/Accounating/index"
          >
            <h2 className="mx-2 text-mainText my-3">الخزينة</h2>
          </Link>
          <Accordion defaultActiveKey={null}>
            <Accordion.Item eventKey="0">
              <Link className="w-100" to={"/System/Accounating/Revenues"}>
                <Accordion.Header
                  onClick={() => setActive(0)}
                  className={`w-full border-2 pointer flex justify-between ${
                    active === 0
                      ? "!border-[#EFAA20] rounded-md hover:!border-transparent"
                      : "border-2 border-mainBorder rounded-md rounded-b-lg"
                  }`}
                >
                  <p>الايرادات</p>
                  <MdKeyboardArrowDown size={20} />
                </Accordion.Header>
              </Link>
              <Accordion.Body>
                <div className="tabs d-flex justify-content-center align-items-center flex-column">
                  {renderCategoryLink(
                    "System/Accounating/Revenues/FinancialClaims",
                    "المطالبة مالية",
                    () =>
                      setRevenuesCategories({
                        FinancialClaims: true,
                        Invoice: false,
                      })
                  )}
                  {renderCategoryLink(
                    "System/Accounating/Revenues/Invoice",
                    "الفواتير",
                    () =>
                      setRevenuesCategories({
                        FinancialClaims: false,
                        Invoice: true,
                      })
                  )}
                </div>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="1">
              <Accordion.Header
                onClick={() => setActive(1)}
                className={`w-full border-2 pointer flex justify-between ${
                  active === 1
                    ? "!border-[#EFAA20] rounded-md hover:!border-transparent"
                    : "border-2 border-mainBorder rounded-md rounded-b-lg"
                }`}
              >
                <span>المصروفات</span>
                <MdKeyboardArrowDown size={20} />
              </Accordion.Header>
              <Accordion.Body>
                <Accordion>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header
                      onClick={() => {
                        setActive(3);
                        setOpenAddAccountant(false);
                        setOpenDisplayDetails(false);
                      }}
                      className={`w-full border-2 pointer flex justify-between ${
                        active === 3
                          ? "!border-[#EFAA20] rounded-md hover:!border-transparent"
                          : "border-2 border-mainBorder rounded-md rounded-b-lg"
                      }`}
                    >
                      <Link
                        className="w-full flex justify-between"
                        to="System/Accounating/Expenses/saudi"
                      >
                        <span>السعودية</span>
                        <MdKeyboardArrowDown size={20} />
                      </Link>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="tabs d-flex justify-content-center align-items-center flex-column">
                        {renderCategoryLink(
                          "System/Accounating/Expenses/saudi/0",
                          "مصروفات معلقه",
                          () => handleItemsReportClick()
                        )}
                        {renderCategoryLink(
                          "System/Accounating/Expenses/saudi/4",
                          "مصروفات مقبوله",
                          () => handleItemsReportClick()
                        )}
                        {renderCategoryLink(
                          "System/Accounating/Expenses/saudi/5",
                          "مصروفات مرفوضه",
                          () => handleItemsReportClick()
                        )}
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header
                      onClick={() => {
                        setActive(2);
                        setOpenAddAccountant(false);
                        setOpenDisplayDetails(false);
                      }}
                      className={`w-full border-2 pointer flex justify-between ${
                        active === 2
                          ? "!border-[#EFAA20] rounded-md hover:!border-transparent"
                          : "border-2 border-mainBorder rounded-md rounded-b-lg"
                      }`}
                    >
                      <Link
                        className="w-full flex justify-between"
                        to="System/Accounating/Expenses/Egypt"
                      >
                        <span>مصر</span>
                        <MdKeyboardArrowDown size={20} />
                      </Link>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="tabs d-flex justify-content-center align-items-center flex-column">
                        {renderCategoryLink(
                          "System/Accounating/Expenses/Egypt/0",
                          "مصروفات معلقه",
                          () => handleItemsReportClick()
                        )}
                        {renderCategoryLink(
                          "System/Accounating/Expenses/Egypt/4",
                          "مصروفات مقبوله",
                          () => handleItemsReportClick()
                        )}
                        {renderCategoryLink(
                          "System/Accounating/Expenses/Egypt/5",
                          "مصروفات مرفوضه",
                          () => handleItemsReportClick()
                        )}
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
                {renderCategoryLink(
                  "System/Accounating/Expenses/Items",
                  "الاصناف",
                  () => handleItemsClick(),
                  active === 1 && ExpensesCategory.Items
                )}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
    </div>
  );
};

export default AccountantDropdown;
