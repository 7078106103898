import React from "react";

const TaskCard = ({ title, value, onClick }) => {
  return (
    <div
      role="button"
      onClick={onClick}
      className="py-2 px-4 border !border-dashed !border-[#EFAA20] rounded-xl flex flex-col justify-center items-center gap-1 "
    >
      <p>{title}</p>
      <p>{value}</p>
    </div>
  );
};

export default TaskCard;
