import React from "react";

const Section = ({ title = "", children }) => {
  return (
    <fieldset className="border-golden my-4 p-4">
      <legend className="text-center text-mainText">{title}</legend>
      {children}
    </fieldset>
  );
};

export default Section;
