import { FormTitle } from "./FormTitle";

export const FormModal = ({
  children,
  className,
  titleClassName,
  title,
  ...props
}) => {
  return (
    <fieldset
      className={`w-full p-3 border !border-[#d5992133] text-mainText ${className}`}
      {...props}
    >
      {title ? <FormTitle title={title} className={titleClassName} /> : null}

      {children}
    </fieldset>
  );
};
