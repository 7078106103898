import React, { Fragment, useState } from "react";
import SystemControler from "../../../Components/System/SystemControler/SystemControler";
import { IconButton } from "@mui/material";
import { IoIosArrowDown, IoMdArrowDropright } from "react-icons/io";
import { Link, Outlet, useNavigate, useLocation } from "react-router-dom";
import TasksSidebar from "./components/Sidebar";
import { CheckRole } from "../../../helper/utils";
import { roles } from "../../../helper/fetchers/Tasks";
import TasksDropdown from "./TasksDropdown";

export default function TasksModel() {
  return (
    <PageWrapper>
      <div className="hidden xl:flex">
        <TasksSidebar />
      </div>
      <div className="flex-1 bg-mainItems">
        <Outlet />
      </div>
    </PageWrapper>
  );
}

const PageWrapper = ({ children }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  // console.log(pathname);
  const [open, setOpen] = useState(false);

  return (
    <Fragment>
      <SystemControler
        child={
          <div className="h-[88px] flex items-center gap-2 relative">
            <IconButton
              onClick={() => {
                navigate(-1);
              }}
            >
              <IoMdArrowDropright color="var(--main-text)" fontSize={25} />
            </IconButton>
            {pathname === "/System/tasks/plans" ? (
              <Fragment>
                {(CheckRole(roles["admin"]) || CheckRole(roles["manager"])) && (
                  <AddNewButton path={"/System/tasks/plans/add-project"} />
                )}
              </Fragment>
            ) : pathname === "/System/tasks/tasks" ? (
              <Fragment>
                {(CheckRole(roles["admin"]) ||
                  CheckRole(roles["manager"]) ||
                  CheckRole(roles["auditor"]) ||
                  CheckRole(roles["senior"])) && (
                  <AddNewButton path={"/System/tasks/tasks/add-task"} />
                )}
              </Fragment>
            ) : null}
            <div className="relative">
              <button
                className="px-8 py-2 rounded-xl bg-mainNavy text-mainText flex gap-2 items-center justify-between xl:hidden"
                onClick={() => setOpen(!open)}
              >
                المزيد
                <IoIosArrowDown className="text-mainText text-xl" />
              </button>
              {open && <TasksDropdown setOpen={setOpen} />}
            </div>
          </div>
        }
      />
      <div className="h-full flex gap-3">{children}</div>
    </Fragment>
  );
};
const AddNewButton = ({ path }) => {
  return (
    <Link to={path} className="customButton ">
      اضافه جديدة
    </Link>
  );
};
