import { useCallback, useEffect, useState } from "react";
import "./index.css";
import MonthlyApexChart from "./MonthlyApexChart";
import { TableCell } from "../../../Table/TableCell.jsx";
import { TableRow } from "../../../Table/TableRow.jsx";
import CustomTable from "../../../Table/index.jsx";

const columns = [
  {
    name: "م",
    selector: (row) => row.id,
  },
  {
    name: "اسم المشروع",
    selector: (row) => row.ProjectName,
  },
  {
    name: " نوع الامشروع",
    selector: (row) => row.ProjectType,
  },
  {
    name: "  تاريخ الاستلام",
    selector: (row) => row.recivedDate,
  },
  {
    name: "  تاريخ التسليم",
    selector: (row) => row.deliveryDate,
  },
];

const ProfessinollInformation = ({ user }) => {
  const [projectType, setProjectType] = useState("مشاريع منتهية");
  const [data, setData] = useState();
  const setUserData = useCallback(() => {
    setData(user?.confirmedTasks);
  }, [user]);
  useEffect(() => {
    setUserData();
  }, [setUserData]);
  return (
    <div className="ProfessinollInformation">
      <div className="MonthlyApexChart bg-mainNavy ">
        <MonthlyApexChart />
      </div>
      <div className="golden-square p-3 mt-2">
        <div>
          <p className="golden my-3 text-xl"> المشاريع</p>

          <div className={`d-flex   gap-4 justify-content-center`}>
            <div
              className={`project-card flex !justify-center ${
                projectType == "مشاريع منتهية" && "golden-border"
              } `}
              onClick={() => {
                setProjectType("مشاريع منتهية");
                setData(user?.confirmedTasks);
              }}
            >
              <div>
                <p className={`text-center  text-mainCustomGray  mb-0`}>
                  مشاريع منتهية
                </p>
                <h3 className="text-center text-mainCustomGray">
                  
                  {user?.confirmedTasks?.length}
                </h3>
              </div>
            </div>
            <div
              className={`project-card flex !justify-center  ${
                projectType === "مشاريع قيد التنفيذ" && "golden-border"
              }`}
              onClick={() => {
                setProjectType("مشاريع قيد التنفيذ");
                setData(user?.pendingTasks);
              }}
            >
              <div className="">
                <p className=" text-center text-mainCustomGray   mb-0">
                  مشاريع قيد التنفيذ
                </p>
                <h3 className="text-center text-mainCustomGray">
                  
                  {user?.pendingTasks?.length}
                </h3>
              </div>
            </div>
            <div
              className={`project-card flex !justify-center  ${
                projectType == "مشاريع المتأخرة" && "golden-border"
              }`}
              onClick={() => {
                setProjectType("مشاريع المتأخرة");
                setData(user?.lateTasks);
              }}
            >
              <div>
                <p className=" text-center  text-mainCustomGray  mb-0">
                  مشاريع المتاخرة
                </p>
                <h3 className="text-center text-mainCustomGray">
                  
                  {user?.lateTasks?.length}
                </h3>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-3 !h-[400px] overflow-y-auto scrollbar-thin scrollbar-track-mainItems scrollbar-thumb-[#E4A11B]">
          <CustomTable columns={columns} data={data}>
            {data && data?.length > 0
              ? data?.map((item, index) => (
                  <TableRow
                    className={`my-2 border !border-[#efaa207f] ${
                      index % 2 === 0 ? "bg-mainNavy" : ""
                    }`}
                    key={index}
                  >
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{item?.projectName}</TableCell>
                    <TableCell>
                      {item?.status === 1 ? "تصميم" : "اشراف علي التنفيذ"}
                    </TableCell>
                    <TableCell>{item?.startTime}</TableCell>
                    <TableCell>{item?.endTime}</TableCell>
                  </TableRow>
                ))
              : null}
          </CustomTable>
        </div>
      </div>
    </div>
  );
};
export default ProfessinollInformation;
