import React from "react";
import { Outlet } from "react-router-dom";
import withGuard from "../../../utiltis/withGuard";
import { CheckRole } from "../../../helper/utils";
import { roles } from "../../../helper/fetchers/Tasks";

const SettingsModule = () => {
  return (
    <>
      <Outlet />
    </>
  );
}
export default withGuard(SettingsModule,[CheckRole(roles["admin"]),CheckRole(roles["administrator"])])