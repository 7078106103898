import React, { useState } from "react";
import "./index.css";
import { FormControl } from "@mui/material";
import { CgClose } from "react-icons/cg";
import Select from "../../../FormHandler/Select";
import { Button, Form, Modal } from "react-bootstrap";
import SaveButton from "../../../SaveButton";
import Cookies from "js-cookie";

import { FormModal } from "../../../../Pages/System/PlanModel/components/FormModal";
import {
  countryOption,
  departmentOption,
  genderOptions,
  roleOption,
} from "./consts";
import {
  CustomDatePicker,
  CustomInput,
  CustomeInputFile,
} from "../../../FormUi";
import { useForm, Controller } from "react-hook-form";
import { InputLabel } from "../../../../Pages/System/PlanModel/components/InputLabel";
import { useGetUser, useUpdateUser } from "../../../../hooks/fetchers/Users";
import { getUserById } from "../../../../helper/fetchers/Users";
import moment from "moment";
import { EditIcon, PdfIcon } from "../../../../utiltis/Icons";
import useSuccessfullModal from "../../../Modals/hooks/useSuccessfullModal";
import SuccessfullModal from "../../../Modals/SuccessfullModal";
import { useNavigate } from "react-router-dom";
import LoadingPage from "../../../feedback/LoadingPage";
const AddUpdateUser = ({ id = null, setOpenUpdateUser, setOpenModal }) => {
  const [editableDateOfBirth, setEditableDateOfBirth] = useState(false);
  const [editableStartWork, setEditableStartWork] = useState(false);
  const [editableIdDate, setEditableIdDate] = useState(false);
  const { data: userData } = useGetUser(id);
  const {
    handleShowSuccess,
    handleShowError,
    handleCloseSuccess,
    handleCloseError,
    showSuccess,
    showError,
  } = useSuccessfullModal();
  const { mutate: updateUserMutation, isLoading } = useUpdateUser(
    handleShowSuccess,
    handleShowError,
    id
  );
  // console.log("userData: ", userData?.data?.user);

  const { register, control, handleSubmit, watch } = useForm({
    defaultValues: async () => {
      const res = await getUserById(id);
      const data = res?.data?.user;
      console.log("userInfo:", data);
      return {
        firstName: data.firstName,
        lastName: data.lastName,
        userName: data.userName,
        gender: data.gender === 1 ? genderOptions[0] : genderOptions[1],
        email: data.email,
        phone: data.phone,
        country: { label: data.country, value: data.country },
        city: data.city,
        district: data.district,
        // dateOfBirth: moment(data.dateOfBirth).format("YYYY-MM-DD"),
        dateOfBirth: new Date(data.dateOfBirth),
        department: { label: data.department, value: data.department },
        role: { label: data.role, value: data.role },
        // startWork: moment(data.startWork).format("YYYY-MM-DD"),
        startWork: new Date(data.startWork),
        idNumber: data.idNumber,
        // idDate: moment(data.idDate).format("YYYY-MM-DD"),
        idDate: new Date(data.idDate),
        basicSalary: data.basicSalary,
        increaseSalary: data.increaseSalary,
        recruitmentOfficer: data.recruitmentOfficer,
        recruitmentResone: data.recruitmentResone,
      };
    },
  });

  const manipulateFormData = (data) => {
    data.gender = data.gender.value;
    data.country = data.country.value;
    data.department = data.department.value;
    data.role = data.role.value;
    return data;
  };
  const onSubmit = (data) => {
    data = manipulateFormData(data);
    // console.log(data);
    const formData = new FormData();
    formData.append("firstName", data?.firstName);
    formData.append("lastName", data?.lastName);
    formData.append("userName", data?.userName);
    formData.append("gender", data?.gender);
    formData.append("email", data?.email);
    formData.append("phone", data?.phone);
    formData.append("country", data?.country);
    formData.append("city", data?.city);
    formData.append("dateOfBirth", data?.dateOfBirth);
    formData.append("district", data?.district);
    formData.append("role", data?.role);
    formData.append("department", data?.department);
    formData.append("startWork", data?.startWork);
    formData.append("idNumber", data?.idNumber);
    formData.append("idDate", data?.idDate);
    formData.append("basicSalary", data?.basicSalary);
    formData.append("increaseSalary", data?.increaseSalary);
    formData.append("recruitmentResone", data?.recruitmentResone);
    formData.append("recruitmentOfficer", data?.recruitmentOfficer);
    if (data?.fileUser) {
      for (const file of data?.fileUser) {
        formData.append("fileUser", file);
      }
    }

    updateUserMutation(formData);
  };
  const myCountry = Cookies.get("country");
  const navigate = useNavigate();
  const { show, handleClose, handleShow } = useOpenModal();
  const [content, setContent] = useState(null);
  const fileLength = watch("fileUser");
  // console.log("fileLength:", fileLength?.length);
  return (
    <div className="addUpdateUser overflow-y-auto scrollbar-none P-4">
      {isLoading ? <LoadingPage /> : null}
      <SuccessfullModal
        message={"تم تعديل المستخدم بنجاح"}
        show={showSuccess}
        handleClose={() => {
          handleCloseSuccess();
          navigate(`/System/Hr/Employees/${myCountry === "مصر" ? "egypt":"Saudia"}`);
        }}
      />
      <SuccessfullModal
        message={"حدث خطأ ما"}
        show={showError}
        handleClose={handleCloseError}
        status="error"
      />

      <h2 className="golden addupdateheader mt-3 mx-5 mb-1">
        {!id ? "إضافة جديدة" : "تعديل المستخدم"}
      </h2>

      <Form
        className="flex flex-col gap-3 p-3"
        encType="multipart/form-data"
        onSubmit={handleSubmit(onSubmit)}
      >
        <FormModal title={!id ? "اضافة موظف جديد" : " تعديل الموظف"}>
          <div className="grid grid-cols-3 gap-4   mx-2 ">
            <FormControl>
              <InputLabel label={"الاسم الاول"} />
              <CustomInput control={control} name={"firstName"} />
            </FormControl>
            <FormControl>
              <InputLabel label={"الاسم الاخير"} />
              <CustomInput control={control} name={"lastName"} />
            </FormControl>
            <FormControl>
              <InputLabel label={"الاسم المستخدم"} />
              <CustomInput
                control={control}
                name={"userName"}
                placeholder={"example@Bsa12"}
              />
            </FormControl>

            <div className=" mb-2 flex justify-center">
              <FormControl fullWidth>
                <InputLabel label={"النوع"} />
                <Controller
                  control={control}
                  name="gender"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Select
                      fullWidth
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      OptionbackgroundColor="#414162"
                      options={genderOptions}
                    />
                  )}
                />
              </FormControl>
            </div>

            <FormControl>
              <InputLabel label={"البريد الالكتروني"} />
              <CustomInput control={control} name={"email"} />
            </FormControl>
            <FormControl>
              <InputLabel label={"رقم الجوال"} />
              <CustomInput control={control} name={"phone"} />
            </FormControl>
            <div className=" mb-2 flex justify-center">
              <FormControl fullWidth>
                <InputLabel label={"البلد"} />
                <Controller
                  control={control}
                  name="country"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Select
                      fullWidth
                      ref={ref}
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      OptionbackgroundColor="#414162"
                      options={countryOption}
                    />
                  )}
                />
              </FormControl>
            </div>
            <FormControl>
              <InputLabel label={"المدينه"} />
              <CustomInput control={control} name={"city"} />
            </FormControl>
            <FormControl>
              <InputLabel label={"الحي"} />
              <CustomInput control={control} name={"district"} />
            </FormControl>

            <FormControl>
              <InputLabel label={"تاريخ الميلاد"} />
              <div className="flex justify-between items-center">
                {editableDateOfBirth ? (
                  <CustomDatePicker control={control} name={"dateOfBirth"} />
                ) : (
                  moment(userData?.data?.user?.dateOfBirth).format("YYYY-MM-DD")
                )}
                <button
                  style={{
                    backgroundColor: editableDateOfBirth ? "red" : "green",
                  }}
                  className="rounded-full p-1"
                  type="button"
                  onClick={() => setEditableDateOfBirth(!editableDateOfBirth)}
                >
                  {editableDateOfBirth ? <CgClose /> : <EditIcon />}
                </button>
              </div>
            </FormControl>
            <div className=" mb-2 flex justify-center">
              <FormControl fullWidth>
                <InputLabel label={"القسم"} />
                <Controller
                  control={control}
                  name="department"
                  render={({ field: { onChange, onBlur, value, ref } }) => {
                    // console.log("The value of department is:", value);
                    return (
                      <Select
                        ref={ref}
                        fullWidth
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        OptionbackgroundColor="#414162"
                        options={departmentOption}
                      />
                    );
                  }}
                />
              </FormControl>
            </div>
            <div className=" mb-2 flex justify-center">
              <FormControl fullWidth>
                <InputLabel label={"الصلاحية"} />
                <Controller
                  control={control}
                  name="role"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Select
                      ref={ref}
                      fullWidth
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      OptionbackgroundColor="#414162"
                      options={roleOption}
                    />
                  )}
                />
              </FormControl>
            </div>

            <FormControl>
              <InputLabel label={"بدا العمل في"} />
              <div className="flex justify-between items-center">
                {editableStartWork ? (
                  <CustomDatePicker control={control} name={"startWork"} />
                ) : (
                  moment(userData?.data?.user?.startWork).format("YYYY-MM-DD")
                )}
                <button
                  style={{
                    backgroundColor: editableStartWork ? "red" : "green",
                  }}
                  className="rounded-full p-1"
                  type="button"
                  onClick={() => setEditableStartWork(!editableStartWork)}
                >
                  {editableStartWork ? <CgClose /> : <EditIcon />}
                </button>
              </div>
            </FormControl>

            <FormControl>
              <InputLabel label={"رقم الهوية"} />
              <CustomInput control={control} name={"idNumber"} />
            </FormControl>

            <FormControl>
              <InputLabel label={"تاريخ الهويه"} />
              <div className="flex justify-between items-center">
                {editableIdDate ? (
                  <CustomDatePicker control={control} name={"idDate"} />
                ) : (
                  moment(userData?.data?.user?.idDate).format("YYYY-MM-DD")
                )}
                <button
                  style={{
                    backgroundColor: editableIdDate ? "red" : "green",
                  }}
                  className="rounded-full p-1"
                  type="button"
                  onClick={() => setEditableIdDate(!editableIdDate)}
                >
                  {editableIdDate ? <CgClose /> : <EditIcon />}
                </button>
              </div>
            </FormControl>
          </div>
        </FormModal>

        <FormModal title={"معلومات حسابات"}>
          <div className="grid grid-cols-2 gap-3">
            <FormControl>
              <InputLabel label={"المرتب الاساسي"} />
              <CustomInput control={control} name={"basicSalary"} />
            </FormControl>
            <FormControl>
              <InputLabel label={"نسبة الزيادة"} />
              <CustomInput control={control} name={"increaseSalary"} />
            </FormControl>

            {/* <div className="col-md-4 col-sm-6">
              <Form.Group>
                <Form.Label>بداية من شهر</Form.Label>
                <FormDatePicker
                  date={increaseStartDate}
                  setDate={setIncreaseStartDate}
                  placeholderText={"ادخل التاريخ"}
                />
              </Form.Group>
            </div> */}
          </div>
        </FormModal>

        <FormModal title={"التعينات"}>
          <div className="grid grid-cols-2 gap-3">
            <FormControl>
              <InputLabel label={"المسئول عن التوظيف"} />
              <CustomInput control={control} name={"recruitmentOfficer"} />
            </FormControl>
            <FormControl className="col-span-2">
              <InputLabel label={"اسباب التعين"} />
              <CustomInput
                control={control}
                name={"recruitmentResone"}
                multiline
                cols={5}
                rows={5}
              />
            </FormControl>
          </div>
        </FormModal>

        <FormModal title={" مرفقات الموظف"}>
          <div className="flex items-start">
            {/* <AddAttachment
                  attachment={attachment}
                  setAttachment={setAttachment}
                /> */}
            <div className="flex items-start gap-2">
              <CustomeInputFile
                register={{ ...register("fileUser") }}
                name="fileUser"
                multiple={true}
              />

              {/* ********************* */}
              <Example
                show={show}
                handleClose={handleClose}
                content={content}
              />

              {!fileLength?.length
                ? userData?.data?.user?.fileUser?.map((file, index) => {
                    const fileType = getType(file);
                    switch (fileType) {
                      case "jpg":
                      case "jpeg":
                      case "png":
                      case "gif":
                        return (
                          <div
                            key={index}
                            onClick={() => {
                              setContent(
                                <>
                                  <DownloadButton file={file} />

                                  <img
                                    className="w-full  rounded-xl border"
                                    src={file}
                                    alt=""
                                  />
                                </>
                              );
                              handleShow();
                            }}
                          >
                            <img
                              className="w-20 h-20 rounded-xl border"
                              src={file}
                              alt=""
                            />
                          </div>
                        );
                      case "pdf":
                        return (
                          <div
                            key={index}
                            onClick={() => {
                              setContent(
                                <>
                                  <DownloadButton file={file} />
                                  <PdfIcon />
                                  {/* <embed
                          src={URL.createObjectURL(file)}
                          type="application/pdf"
                          width="400"
                          height="600"
                          className="w-full relative rounded-xl border"
                        /> */}
                                </>
                              );
                              handleShow();
                            }}
                            className="flex justify-center items-center w-24 h-24 rounded-xl border"
                          >
                            <PdfIcon />
                          </div>
                        );
                      case "zip":
                        return (
                          <div
                            key={index}
                            onClick={() => {
                              setContent(
                                <>
                                  <DownloadButton file={file} />
                                  <img
                                    className="w-full rounded-xl border"
                                    src="https://p7.hiclipart.com/preview/258/300/47/computer-icons-zip-truetype-icon-png-file-zip.jpg"
                                    alt=""
                                  />
                                </>
                              );
                              handleShow();
                            }}
                          >
                            <img
                              className="w-24 h-24 rounded-xl border"
                              src="https://p7.hiclipart.com/preview/258/300/47/computer-icons-zip-truetype-icon-png-file-zip.jpg"
                              alt=""
                            />
                          </div>
                        );
                      case "xlsx":
                        return (
                          <div
                            key={index}
                            onClick={() => {
                              setContent(
                                <>
                                  <DownloadButton file={file} />
                                  <img
                                    className="w-full rounded-xl border"
                                    src="https://logodownload.org/wp-content/uploads/2020/04/excel-logo-0.png"
                                    alt=""
                                  />
                                </>
                              );
                              handleShow();
                            }}
                          >
                            <img
                              className="w-24 h-24 rounded-xl border"
                              src="https://logodownload.org/wp-content/uploads/2020/04/excel-logo-0.png"
                              alt=""
                            />
                          </div>
                        );
                      case "rar":
                        return (
                          <div
                            key={index}
                            onClick={() => {
                              setContent(
                                <>
                                  <DownloadButton file={file} />
                                  <img
                                    className="w-96  rounded-xl border"
                                    src="https://cdn-icons-png.freepik.com/512/9158/9158683.png"
                                    alt=""
                                  />
                                </>
                              );
                              handleShow();
                            }}
                          >
                            <img
                              className="w-24 h-24 rounded-xl border"
                              src="https://cdn-icons-png.freepik.com/512/9158/9158683.png"
                              alt=""
                            />
                          </div>
                        );
                      default:
                        return null;
                    }
                  })
                : null}
              {/* ********************* */}
            </div>
          </div>
        </FormModal>

        <div className="d-flex my-2 w-75 mx-auto mt-3 justify-content-end">
          <SaveButton type="submit" />
        </div>
      </Form>
    </div>
  );
};

export default AddUpdateUser;

const DownloadButton = ({ file }) => {
  return (
    <Button
      className="w-fit mx-auto text-mainText"
      download={true}
      variant="success"
      href={file}
    >
      {"تحميل"}
      {/* <FaFileDownload fontSize={25} color="#EFAA20" /> */}
    </Button>
  );
};
const useOpenModal = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return {
    show,
    handleClose,
    handleShow,
  };
};

function Example({ show, handleClose, content }) {
  return (
    <Modal
      centered
      show={show}
      onHide={handleClose}
      contentClassName="p-0 bg-mainNavy w-fit mx-auto"
      className="bg-black/50"
    >
      <Modal.Body className="flex flex-col items-center">{content}</Modal.Body>
    </Modal>
  );
}
const getType = (fileName) => {
  const fileExtension = fileName.split(".").pop().toLowerCase();
  return fileExtension;
};
