import { PDFDownloadLink } from "@react-pdf/renderer";
import React from "react";
import { Modal, Spinner } from "react-bootstrap";
import LOGO from "../../assets/logo.png"
import TITLE_BG from "../../assets/pdf-title-bg.png"
import Image from "../Image";
const PdfPreview = ({ show, onHide, children }) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      className="bg-black/50 "
      contentClassName="bg-transparent text-black p-0"
    >
      {children}
    </Modal>
  );
};

const DownloadButton = ({ document, loading, ...props }) => {
  return (
    <PDFDownloadLink
      document={document}
      fileName="document.pdf"
      className="mb-3 mx-auto block text-center !text-[#efaa20] hover:!text-[#efaa20] border !border-[#efaa20] w-40 px-4 py-1 rounded-full bg-mainNavy"
      style={{ pointerEvents: loading ? "none" : "auto" }}
    >
      {({ loading: pdfLoading }) =>
        loading || pdfLoading ? <Spinner size="sm" /> : "تحميل المستند"
      }
    </PDFDownloadLink>
  );
};

const Header = ({ titleAr,titleEn , createdAt = "" }) => {
  return (
    <Modal.Header
      className="p-0 border-none  flex-col rounded-t"
    >
    <div className="bg-[#142840] text-blcak w-full flex justify-between  ">
    <div className="relative top-4 w-1/2 z-10 flex flex-col justify-center items-center ">
        <p className="text-xl font-semibold">{titleEn}</p>
        <p className="text-xl font-semibold">{titleAr}</p>
        <img src={TITLE_BG} alt="" className="pointer-events-none absolute h-full inset-0 -z-10 [filter:_drop-shadow(-10px_10px_4px_#0000003d);]" />
        </div>
    <img src={LOGO} width={180} height={100.8} alt="logo" className="p-4 pointer-events-none"  />


    </div>
    <div className="bg-white w-full flex justify-end">
        <p className="bg-[#E3A32E] w-2/3 text-center p-2 rounded-s-full h-10">{createdAt}</p>
    </div>











      {/* <img
        src={imageSrc}
        alt="pdf-header"
        className="w-full bg-white rounded-t"
      /> */}
    </Modal.Header>
  );
};

const Body = ({ children }) => {
  return (
    <Modal.Body className="w-full py-4 px-0 text-sm font-semibold bg-white">
      {children}
    </Modal.Body>
  );
};


const SectionWrapper = ({ children }) => {
    return <div className="grid md:grid-cols-2 gap-3 py-4 px-2 ">{children}</div>;
  };

const SectionTitle = ({title}) => {
  return (
    <div className="bg-[#1E1E2D] text-base text-white w-fit py-2 px-4">
      <p>{title}</p>
    </div>
  );
};




PdfPreview.DownloadButton = DownloadButton;
PdfPreview.Header = Header;
PdfPreview.Body = Body;
PdfPreview.SectionTitle = SectionTitle;
PdfPreview.SectionWrapper = SectionWrapper;

export default PdfPreview;
