import React, { useContext } from "react";
import SystemControler from "../../../../Components/System/SystemControler/SystemControler";
import { AllCategories } from "../../../../Components/System/AllCategories/AllCategories";
import { Accordion, Button } from "react-bootstrap";
import { MdKeyboardArrowDown } from "react-icons/md";
import { Link, Outlet } from "react-router-dom";
import { AddHrType } from "../../../../Context/AddHr";
import { useState } from "react";
import BackButton from "../../../../Components/BackButton";
import { IoIosArrowDown } from "react-icons/io";
import HrDropdown from "./HrDropdown";
import { CheckRole } from "../../../../helper/utils";
import { roles } from "../../../../helper/fetchers/Tasks";
import Cookies from "js-cookie";

const HrIndex = () => {
  const { handleOpenHr } = useContext(AddHrType);
  const [active, setActive] = useState();
  const [allEmployees, setAllEmplyees] = useState(false);
  const [open, setOpen] = useState(false);
  const myCountry = Cookies.get("country");
  return (
    <>
      <SystemControler
        child={
          <div className="flex items-center gap-2 relative ">
            <BackButton />
            <div
              className="add-user-button cursor-pointer customButton"
              onClick={handleOpenHr}
            >
              إضافة جديدة
            </div>
            <button
              className="px-8 py-2 rounded-xl bg-mainNavy text-mainText flex gap-2 items-center justify-between xl:hidden"
              onClick={() => setOpen(!open)}
            >
              الموارد البشرية
              <IoIosArrowDown className="text-mainText text-xl" />
            </button>
            {open && (
              <HrDropdown
                setOpen={setOpen}
                active={active}
                setActive={setActive}
                allEmployees={allEmployees}
                setAllEmplyees={setAllEmplyees}
              />
            )}
          </div>
        }
      />
      <div className="grid grid-cols-12 gap-2 h-full">
        <div className="hidden xl:flex col-span-3">
          <AllCategories
            child={
              <>
                <Accordion className="!w-full" defaultActiveKey={null}>
                  <div className="py-3">
                    <h2 className="text-mainText text-center">
                      الموارد البشرية
                    </h2>
                  </div>
                  {!CheckRole(roles["user"]) ? (
                    <Accordion.Item eventKey="0">
                      <Link to={"/System/Hr/EmployeesManagment"}>
                        <Accordion.Header
                          onClick={() => {
                            setActive(0);
                          }}
                          className={`   border-2  rounded-md hover:!border-[#EFAA20] ${
                            active === 0
                              ? " !border-[#EFAA20]  "
                              : "border-mainBorder rounded-md rounded-b-lg"
                          }      pointer`}
                        >
                          <p> إدارة الموظفين</p>

                          <MdKeyboardArrowDown size={20} />
                        </Accordion.Header>
                      </Link>
                      <Accordion.Body>
                        <div className="tabs d-flex justify-content-center align-items-center flex-column">
                          <Link
                            to={`/System/Hr/Employees/${
                              myCountry === "مصر" ? "egypt" : "Saudia"
                            }`}
                          >
                            <div className="w-100">
                              <div
                                onClick={() => {
                                  setAllEmplyees(true);
                                }}
                                className={` tab  text-end w-100  border  rounded-md hover:!border-[#EFAA20] ${
                                  active === 0 && allEmployees
                                    ? " !border-[#EFAA20]  "
                                    : "!border-transparent"
                                }      pointer`}
                              >
                                <span>كل الموظفين</span>
                              </div>
                            </div>
                          </Link>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  ) : null}
                </Accordion>

                <Accordion defaultActiveKey={true}></Accordion>

                <div className="tabs d-flex justify-content-center align-items-center flex-column">
                  <div className="w-100"></div>
                  <div className="w-100">
                    <Link to={"/System/Hr/HolidayMangment"}>
                      <div
                        onClick={() => {
                          setAllEmplyees(false);
                          setActive(1);
                        }}
                        className={` tab  text-end w-100  border  rounded-md hover:!border-[#EFAA20] ${
                          active === 1
                            ? " !border-[#EFAA20]  "
                            : "!border-transparent"
                        }      pointer`}
                      >
                        <span>إدارة الاجازات</span>
                      </div>
                    </Link>
                  </div>
                  <div className="w-100">
                    <Link to={"/System/Hr/EmployeesServices"}>
                      <div
                        onClick={() => {
                          setAllEmplyees(false);
                          setActive(2);
                        }}
                        className={` tab  text-end w-100  border  rounded-md hover:!border-[#EFAA20] ${
                          active === 2
                            ? " !border-[#EFAA20]  "
                            : "!border-transparent"
                        }      pointer`}
                      >
                        <span>خدمات الموظفين</span>
                      </div>
                    </Link>
                  </div>
                </div>
              </>
            }
          />
        </div>
        <div className="col-span-12 xl:col-span-9">
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default HrIndex;
