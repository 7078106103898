import io from "socket.io-client";
// export const socket = io.connect('http://10.10.0.232:5000');
// export const socket = io.connect("https://bsa2011.com/api");
// export const socket = io("http://10.10.0.45:5000" ,{
//   autoConnect: false
// });
// export const socket = io("http://26.27.237.200:5000" ,{
//   autoConnect: false
// });
// export const socket = io("http://10.10.0.232:5000" ,{
//   autoConnect: false
// });
export const socket = io("https://bsa2011.com" ,{
  autoConnect: false
});
// export const socket = io("http://bsa2011.com:5000" ,{
//   autoConnect: false
// });

export function sockietEmitMessage(event, value) {
  socket.emit(event, value);
}

export function sockietOnMessage(event, cb) {
  socket.on(event, cb);
}
export function sockietDissconnect() {
  socket.disconnect();
}