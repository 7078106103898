import React, { Fragment } from "react";
import { Modal } from "react-bootstrap";
import { FaExclamationCircle } from "react-icons/fa";

function UnAuthorizedMessageModal({ render , show , onHide}) {
  return (
    <Modal
    show={show}
    onHide={onHide}
    centered
    className="bg-black/50"
    contentClassName="rounded-[19px] border !border-[#EFAA20] p-4 "
    >

        
          {render ? (
            render()
          ) : (
            <div className="flex items-center gap-2">
              <p className="text-3xl text-red-500">
                {"غير مسموح بدخول هذا القسم"}
              </p>
              <span className="text-2xl text-red-500 mt-1">
                <FaExclamationCircle />
              </span>
            </div>
          )}
        

    </Modal>
  );
}

export default UnAuthorizedMessageModal;
