import { GenerateColumnsNames } from "../../../helper/utils";

export const genderEnum = {
  1: "ذكر",
  2: "انثي",
};
export function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}
export const RequestsTableColumns = GenerateColumnsNames(
  "م",
  "اسم المشروع",
  "رقم الطلب ",
  "تاريخ الانشاء",
  "نوع المشروع",
  "الحالة",
  "المزيد"
);
export const ProjectsTableColumns = GenerateColumnsNames(
  "م",
  "اسم المشروع",
  " رقم الطلب ",
  "نوع العميل",
  "تاريخ الانشاء",
  "نوع المشروع",
  "الحالة",
  "المزيد"
);
export const columnsClientsInfo = GenerateColumnsNames(
  "م",
  "اسم العميل",
  "نوع العميل",
  "رقم الجوال",
  "عدد المشروعات",
);
export const columnsClientInfo = GenerateColumnsNames(
  "م",
  "اسم المشروع",
  "رقم الطلب",
  "الحاله ",
  "تاريخ الاستلام",
  "المبلغ الاجمالي ",
  "المبلغ المتبقي ",
  "عدد الدفعات ",
  "الدفعات المتبقيه ",
);
export const columnsUserTasks = GenerateColumnsNames(
  "م",
 "اسم المهمه",
  "الحاله",
  "وقت الاستلام",
  "وقت التسليم",
  "الصلاحيه",
);
