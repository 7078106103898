import { UserImage } from "./UserImage";

export const MentionCard = ({ handleSelectMention, mention }) => {
    return (
      <div
        onClick={() => handleSelectMention(mention)}
        className="flex items-start gap-3 py-2 mention-menu-card cursor-pointer"
      >
        <UserImage name={`${mention.firstName} ${mention.lastName}`} image={mention.image} />
        <div className="flex flex-col gap-1">
          <p className="text-sm">{`${mention.firstName} ${mention.lastName}`}</p>
          <p className="text-xs text-[#565674]">{mention.userName}</p>
        </div>
      </div>
    );
  };