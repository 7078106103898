import React, { useState } from "react";
import { TableCell } from "../../../../../Components/Table/TableCell";
import { TableRow } from "../../../../../Components/Table/TableRow";
import CustomTable from "../../../../../Components/Table";
import {
  statusEnum,
  convertDateFormat,
  projectTypeEnum,
  GenerateColumnsNames,
  approvementEnum,
  CheckRole,
} from "../../../../../helper/utils";
import { FaCheck } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useTaskContext } from "../../../PlanModel/context/TaskContext";
import { StatusDisplay } from "../../../Tasks/components/StatusDisplay";
import Image from "../../../../../Components/Image";
import CustomTableMenu from "../../../../../Components/Table/CustomTableMenu";
import moment from "moment";
import useSuccessfullModal from "../../../../../Components/Modals/hooks/useSuccessfullModal";
import { useDeleteTask } from "../../../../../hooks/fetchers/Tasks";
import { RiDeleteBin6Line } from "react-icons/ri";
import SuccessfullModal from "../../../../../Components/Modals/SuccessfullModal";
import LoadingPage from "../../../../../Components/feedback/LoadingPage";
import Progress from "../../../../../Components/Progress";
import CustomCheckboxTable from "../../../../../Components/CustomCheckboxTable";
import TableDeleteButton from "../../../../../Components/Buttons/TableDeleteButton";
import { roles } from "../../../../../helper/fetchers/Tasks";

const columns2 = GenerateColumnsNames(
  "تحديد",
  "م",
  "اسم المهمة",
  "نوع المشروع ",
  "تاريخ التسليم",
  "وقت التسليم",
  "وقت تسليم المهمه",
  "الحالة",
  "المسؤل",
  "الموظفين",
  "انتهاء",
  "عرض"
);

function TasksTable() {
  const { tasks, setFilterTasksParams, isError, isLoading } = useTaskContext();
  // console.log("useGetAllTasks: ", tasks?.tasks);
  const [page, setPage] = React.useState(1);
  const handleChange = (event, value) => {
    setPage(value);
    setFilterTasksParams({ page: value });
  };

  const [checked, setChecked] = useState([]);
  const {
    handleShowSuccess,
    handleShowError,
    handleCloseSuccess,
    handleCloseError,
    showSuccess,
    showError,
  } = useSuccessfullModal();
  const {
    mutate: deleteMutation,
    isLoading: deleteIsLoading,
    isError: deleteIsError,
  } = useDeleteTask(() => {
    handleShowSuccess();
    setChecked([]);
  }, handleShowError);

  const handleChecked = (val) => {
    if (checked.includes(val)) {
      setChecked(checked.filter((item) => item !== val));
    } else {
      setChecked([...checked, val]);
    }
  };
  // You have array of ids to delete

  const handleDeleteAll = () => {};
  const handleDeleteSelected = () => {
    deleteMutation({
      tasks: checked,
    });
  };

  return (
    <>
      {deleteIsLoading ? <LoadingPage /> : null}
      <SuccessfullModal
        handleClose={handleCloseSuccess}
        message={"تم الحذف بنجاح"}
        show={showSuccess}
      />
      <SuccessfullModal
        handleClose={handleCloseError}
        message={"حدث خطأ ما"}
        show={showError}
        status="error"
      />
      {CheckRole(roles["admin"]) ? (
        <TableDeleteButton
          checked={checked}
          dataLength={tasks?.tasks?.length}
          onClickDeleteAll={handleDeleteAll}
          onClickDeleteSelected={handleDeleteSelected}
        />
      ) : null}
      <div className="mt-3 !h-[400px]  scrollbar-thin scrollbar-track-mainNavy scrollbar-thumb-[#E4A11B]">
        {isError ? (
          <p className="text-red-500 text-center text-lg">يوجد خطأ ما</p>
        ) : null}
        {isLoading ? <Progress /> : null}
        <CustomTable
          columns={CheckRole(roles["admin"]) ? columns2 : columns2.slice(1)}
          data={tasks?.tasks}
          paginationProps={{
            count: tasks?.pages,
            page: page,
            onChange: handleChange,
          }}
        >
          {tasks?.tasks && tasks?.tasks?.length > 0
            ? tasks?.tasks?.map(
                (
                  {
                    _id,
                    projectName,
                    projectType,
                    isLate,
                    endDate,
                    endTime,
                    status,
                    approvement,
                    createdBy,
                    isConfirm,
                    isExisting,
                    assignTo,
                    planId,
                    confirmedAt,
                  },
                  index
                ) => {
                  return (
                    <TableRow
                      className={`my-2 border !border-[#efaa207f] ${
                        index % 2 === 0 ? "bg-mainNavy" : ""
                      }`}
                      key={_id}
                    >
                      {CheckRole(roles["admin"]) ? (
                        <TableCell>
                          <CustomCheckboxTable
                            isChecked={checked?.some((x) => x === _id)}
                            handleClick={() => handleChecked(_id)}
                          />
                        </TableCell>
                      ) : null}
                      <TableCell>{(page - 1) * 10 + (index + 1)}</TableCell>
                      <TableCell>
                        {/* {isExisting === false
                        ? projectName
                        : planId?.isExist === true ?
                        planId?.projectId?.projectName
                        : planId?.projectName
                      } */}
                        {projectName || "لا يوجد مخطط"}
                      </TableCell>
                      <TableCell>
                        {/* {isExisting === true
                        ? projectTypeEnum[planId?.projectId?.projectType]
                        : projectTypeEnum[+projectType]} */}
                        {isExisting === false
                          ? projectTypeEnum[+projectType]
                          : planId?.isExist === true
                          ? projectTypeEnum[planId?.projectId?.projectType]
                          : projectTypeEnum[planId?.projectType]}
                        {/* { planId?.projectType ? projectTypeEnum[planId?.projectType] : "--------"} */}
                      </TableCell>
                      <TableCell>
                        {convertDateFormat(endDate) || "--------"}
                      </TableCell>
                      <TableCell>{endTime || "--------"}</TableCell>
                      {/* <TableCell>{["معلقة", "أولية", "نهائية"][parseInt(status)] || "--------"}</TableCell> */}
                      <TableCell>
                        <CustomTableMenu
                          items={[
                            {
                              title: "تاريخ:",
                              value: moment(confirmedAt).format("YYYY-MM-DD"),
                            },
                            {
                              title: "وقت:",
                              value: moment(confirmedAt).format("h:m a"),
                            },
                          ]}
                          maxItems={1}
                        />
                      </TableCell>
                      <TableCell cellClassName="flex items-center  gap-1">
                        {isLate ? (
                          <StatusDisplay title={"متأخرة"} color={"#9E0C1E"} />
                        ) : (
                          <StatusDisplay
                            title={
                              status === 2
                                ? approvementEnum[approvement]?.title
                                : statusEnum[[parseInt(status)]]?.title
                            }
                            color={statusEnum[[parseInt(status)]]?.color}
                          />
                        )}
                      </TableCell>
                      <TableCell>
                        {createdBy?.firstName || "--------"}
                      </TableCell>
                      <TableCell>
                        <CustomTableMenu
                          items={assignTo?.map((employee, index) => ({
                            value: `${employee?.firstName} ${employee?.lastName}`,
                            profileImage: employee.image,
                            path: `/System/Chat/${employee?._id}`,
                          }))}
                          // items={[
                          //   {title:"تاريخ:" ,value: moment(confirmedAt).format("YYYY-MM-DD")},
                          //   {title:"وقت:" ,value: moment(confirmedAt).format("h:m a")}
                          // ]}
                          maxItems={1}
                        />
                        {/* {assignTo
                        ? assignTo?.map((employee, index) => (
                            <p className="" key={index}>
                              {employee?.firstName}
                            </p>
                          ))
                        : "--------"} */}
                      </TableCell>
                      <TableCell>
                        {isConfirm ? (
                          <div className="bg-[#19B159] py-1.5 px-2 rounded w-fit h-auto">
                            <FaCheck />
                          </div>
                        ) : (
                          "--------"
                        )}
                      </TableCell>
                      <TableCell>
                        <div className=" flex justify-between items-start gap-1">
                          <ShowButton id={_id} />
                          {/* <OptionsButton id={_id} /> */}
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                }
              )
            : null}
        </CustomTable>
      </div>
    </>
  );
}

export default TasksTable;

const ShowButton = ({ id }) => {
  return (
    <Link to={`/System/tasks/tasks/show-task/${id}`} id={id} aria-label="show">
      <Image src="/icons/view.svg" alt="" className="w-full" />
    </Link>
  );
};
// const OptionsButton = ({ id }) => {
//   const itemId = id;
//   const [anchorEl, setAnchorEl] = React.useState(null);
//   const [showDelete, setShowDelete] = useState(false);
//   const [showSuccessDelete, setShowSuccessDelete] = useState(false);

//   const open = Boolean(anchorEl);
//   const handleClick = (event) => {
//     setAnchorEl(event.currentTarget);
//   };
//   const handleClose = () => {
//     setAnchorEl(null);
//   };
//   const handleCloseDelete = () => setShowDelete(false);
//   const handleCloseSuccessDelete = () => setShowSuccessDelete(false);
//   const handleShowDelete = () => {
//     setShowDelete(true);
//   };
//   const handleShowSuccessDelete = () => {
//     setShowSuccessDelete(true);
//   };
//   return (
//     <>
//       <IconButton
//         id="basic-button"
//         aria-controls={open ? "basic-menu" : undefined}
//         aria-haspopup="true"
//         aria-expanded={open ? "true" : undefined}
//         onClick={handleClick}
//         aria-label="options"
//         sx={{ p: 0 }}
//       >
//         <IoMdMore color="#EFAA20" />
//       </IconButton>
//       <Menu
//         id="basic-menu"
//         anchorEl={anchorEl}
//         open={open}
//         onClose={handleClose}
//         MenuListProps={{
//           "aria-labelledby": "basic-button",
//           sx: {
//             borderRadius: "6px",
//             minWidth: "130px",
//             backgroundColor: "#FFFFFF",
//           },
//         }}
//       >
//         {OptionsData?.map(({ id, title, path, icon }) => (
//           <MenuItem
//             sx={{ px: "2px", borderBlock: "1px solid #aaaaaa76" }}
//             onClick={handleClose}
//             key={id}
//           >
//             <Link to={`${path}/${itemId}`} className="w-full">
//               <div className=" flex gap-2">
//                 <img src={icon} alt="" className="w-4 " />
//                 <p className="text-black text-sm font-normal">{title}</p>
//               </div>
//             </Link>
//           </MenuItem>
//         ))}
//         <MenuItem
//           sx={{ px: "2px", borderBlock: "1px solid #aaaaaa76" }}
//           onClick={handleClose}
//           key={id}
//         >
//           <div onClick={handleShowDelete} className=" flex gap-2">
//             <img src={"/menu-icons/delete-icon.svg"} alt="" className="w-4 " />
//             <p className="text-black text-sm font-normal">{"حذف"}</p>
//           </div>
//         </MenuItem>
//       </Menu>
//       <CustomModal
//         title={"التأكيد"}
//         message={"هل انت متأكد من الحذف"}
//         show={showDelete}
//         handleClose={handleCloseDelete}
//         handleSave={() => {
//           handleCloseDelete();
//           handleShowSuccessDelete();
//         }}
//       />
//       <SuccessfullModal
//         message={"تم حذف المشروع بنجاح"}
//         handleClose={handleCloseSuccessDelete}
//         show={showSuccessDelete}
//       />
//     </>
//   );
// };
