import { IoMdArrowDropright } from "react-icons/io";
import { useNavigate } from "react-router-dom";

import { IconButton } from "@mui/material";

import { ModalTitle } from "../../../PlanModel/components/ModalTitle";
import SystemControler from "../../../../../Components/System/SystemControler/SystemControler";
import { Fragment } from "react";

export const PageWrapper = ({ children, title }) => {
  const navigate = useNavigate();
  return (
    <Fragment>
      <SystemControler
        child={
          <IconButton
            onClick={() => {
              navigate(-1);
            }}
          >
            <IoMdArrowDropright color="var(--main-text)" fontSize={25} />
          </IconButton>
        }
      />
      <div className="flex flex-col bg-mainItems text-mainText p-3 border !border-[#EFAA20] rounded-[27px] min-h-screen">
        <div className="pt-3 pb-4">
          <ModalTitle title={title} />
        </div>
        <div className="h-[650px] flex-1 py-3 overflow-y-auto scrollbar-thin scrollbar-track-transparent scrollbar-thumb-[#E4A11B]">
          {children}
        </div>
      </div>
    </Fragment>
  );
};
