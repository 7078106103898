import { useCallback, useEffect } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useState } from "react";
import DatePicker from "react-datepicker";
import Cookies from "js-cookie";
import axios from "axios";
import * as XLSX from "xlsx";
import { Document, Page } from "react-pdf";
import { toast } from "react-toastify";
import "./DisplayFinancialClaims.css";
import Input from "../../../FormHandler/Input";
import EditRevenues from "../EditRevenues/EditRevenues";
import Image from "../../../Image";
import DeleteModal from "../../../../Pages/System/Settings/RemoveModal";
import DownloadButton from "../../../Buttons/DownloadButton";
import {
  deleteFinancial,
  getFinancialById,
} from "../../../../helper/fetchers/finaical";
import moment from "moment";
import ShowFiles from "../../../ShowFiles";
import SuccessfullModal from "../../../Modals/SuccessfullModal";
import { useQueryClient } from "react-query";
import LoadingPage from "../../../feedback/LoadingPage";
import DescGroup from "../../ShowRequest/components/DescGroup";
import { ImPrinter } from "react-icons/im";
import { TiExport } from "react-icons/ti";
import config from "../../../../Config/Config";
import {
  paymenyEnum,
  projectTypeEnum,
  statusEnum,
} from "../../../../helper/utils";
import useExportExcel from "../../../../hooks/useExportExcel";
import PrintFinancialClaimsFile from "./PrintFinancialClaimsFile";
import useModal from "../../../../hooks/useModal";
import { Tooltip } from "@mui/material";

const DisplayFinancialClaims = ({
  displayRevenue,
  RevenueType,
  setDisplayRevenue,
  id,
  getFinancial,
  getAllStats,
}) => {
  const [openDelete, setOpenDelete] = useState(false);
  // const [excelData, setExcelData] = useState();
  // const [fileUrl, setFileUrl] = useState("");
  // const [loading, setLoading] = useState(false);
  const [pdfLoading, setPdfLoading] = useState(false);
  const [pdfUrl, setPdfUrl] = useState("");
  // const [allKeys, setAllKeys] = useState([]);

  const { loading, allKeys, excelData, fileUrl, handleExport, resetExcelData } =
    useExportExcel();

  const [err, setError] = useState("");
  // console.log(client, "client");
  const [ConfirmDelete, setConfirmDelete] = useState(false);
  const [editRevenues, setEditRevenues] = useState(false);
  const [success, setSuccess] = useState(false);
  const [item, setItem] = useState();

  const getItem = useCallback(async () => {
    if (id) {
      const res = await getFinancialById(id);
      if (res?.status === 200) {
        setItem(res?.data?.financial);
      }
    }
  }, [id]);
  useEffect(() => {
    getItem();
  }, [getItem]);

  const handleDelete = () => {
    // after ensuring  the Delete is done
    setOpenDelete(false);
    setConfirmDelete(true);
  };
  const queryClient = useQueryClient();
  const [deleteLoading, setDeleteLoading] = useState(false);

  const handleClickDelete = async () => {
    setDeleteLoading(true);
    const res = await deleteFinancial({
      financials: [item?._id],
    });

    if (res?.status === 204) {
      queryClient.invalidateQueries("request");

      setSuccess(true);
      getAllStats();
      setDeleteLoading(false);
    }
  };

  // const handleExport = async () => {
  //   setLoading(true);
  //   try {
  //     const response = await axios.get(
  //       `${config.apiGateway.URL}/finaical/excel/${id}`,
  //       {
  //         responseType: "arraybuffer",
  //         headers: {
  //           "Content-Type": "application/excel",
  //           authes: `BSA__${Cookies.get("accessToken")}`,
  //         },
  //       }
  //     );

  //     if (response.status === 200) {
  //       const data = new Uint8Array(response.data);
  //       const workbook = XLSX.read(data, { type: "array" });
  //       const sheetName = workbook.SheetNames[0];
  //       const worksheet = workbook.Sheets[sheetName];
  //       const jsonData = XLSX.utils.sheet_to_json(worksheet);
  //       const keysSet = new Set();

  //       jsonData.forEach((row) => {
  //         Object.keys(row).forEach((key) => keysSet.add(key));
  //       });

  //       setAllKeys(Array.from(keysSet));
  //       setExcelData(jsonData);

  //       const blob = new Blob([data], {
  //         type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  //       });
  //       const url = URL.createObjectURL(blob);
  //       setFileUrl(url);
  //       setLoading(false);
  //     } else {
  //       setLoading(false);

  //       console.error("Failed to fetch Excel:", response.statusText);
  //     }
  //   } catch (error) {
  //     setLoading(false);

  //     console.error("Error fetching Excel:", error);
  //   }
  // };

  const getFinancialPdf = async (id) => {
    setPdfLoading(true);
    try {
      const response = await fetch(
        `${config.apiGateway?.URL}/finaical/pdf/${id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/pdf",
            authes: `BSA__${Cookies.get("accessToken")}`,
          },
        }
      );

      if (response.ok) {
        const blob = await response.blob();
        const url = URL.createObjectURL(blob);
        setPdfUrl(url);
      } else {
        const errorData = await response.json();
        console.error("Failed to fetch PDF:", errorData);
        setError(errorData?.message);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      console.error("Error fetching PDF:", error);
    } finally {
      setPdfLoading(false);
    }
  };

  const {
    open: openPdf,
    handleOpenModal: handleOpenPdfModal,
    handleCloseModal: handleClosePdfModal,
  } = useModal();
  return (
    <div className="DisplayFinancialClaims">
      {item ? (
        <PrintFinancialClaimsFile
          show={openPdf}
          onHide={handleClosePdfModal}
          data={item}
        />
      ) : null}

      <DeleteModal
        title={"التاكيد"}
        show={openDelete}
        handleClose={handleDelete}
        onSave={handleClickDelete}
      />
      <SuccessfullModal
        message={"تم حذف المطالبة بنجاح"}
        handleClose={() => {
          setSuccess(false);
          setEditRevenues(false);
          setDisplayRevenue(false);
        }}
        show={success}
      />

      {loading ? (
        <LoadingPage />
      ) : (
        excelData && (
          <Modal
            show={true}
            onHide={resetExcelData}
            className="h-screen bg-black/50 customModel"
            contentClassName="bg-transparent"
          >
            <Modal.Body className="rounded-lg bg-mainItems w-full min-w-min flex items-center justify-center flex-col">
              <a
                href={fileUrl}
                download={`${item?.projectId?.requestId?.ownerName}.xlsx`}
                className="mb-3 mx-auto block text-center !text-[#efaa20] hover:!text-[#efaa20] border !border-[#efaa20] w-fit px-4 py-1 rounded-full bg-mainNavy"
              >
                تحميل المستند
              </a>
              <div className="bg-mainNavy shadow-lg p-4 rounded-lg">
                <table className="">
                  <thead>
                    <tr className="min-w-[100px]flex gap-2">
                      {allKeys.map((key) => (
                        <th
                          className="min-w-[8vw] text-sm text-mainText"
                          key={key}
                        >
                          {key}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {excelData.map((row, index) => (
                      <tr key={index}>
                        {allKeys.map((key) => (
                          <td
                            className=" text-xs text-mainCustomGray"
                            key={key}
                          >
                            {row[key] || ""}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </Modal.Body>
          </Modal>
        )
      )}

      {pdfLoading ? (
        <LoadingPage />
      ) : (
        pdfUrl && (
          <Modal
            show={true}
            onHide={() => setPdfUrl("")}
            className="h-screen bg-black/50"
            contentClassName="w-fit mx-auto"
            size="lg"
          >
            <Modal.Body>
              <a
                href={pdfUrl}
                download={`مطالبة.pdf`}
                // download={`${item?.projectId?.requestId?.ownerName}.pdf`}
                className="mb-3 mx-auto block text-center !text-[#efaa20] hover:!text-[#efaa20] border !border-[#efaa20] w-fit px-4 py-1 rounded-full bg-mainNavy"
              >
                تحميل المستند
              </a>
              <Document
                file={pdfUrl}
                onLoadSuccess={() => setPdfLoading(false)}
              >
                <Page pageNumber={1} />
              </Document>
            </Modal.Body>
          </Modal>
        )
      )}

      {editRevenues && (
        <EditRevenues
          getItem={getItem}
          RevenueType={RevenueType}
          editRevenues={editRevenues}
          setEditRevenues={setEditRevenues}
          item={item}
        />
      )}
      {deleteLoading && <LoadingPage />}
      <div className="border-golden">
        <div className="row px-4 py-3">
          <div className="col-md-8 mb-2">
            <p className="text-mainText">
              اسم المشروع :
              <span>{item?.projectId?.requestId?.projectName}</span>
            </p>
          </div>
          <div className="col-md-4   mb-2">
            <div className=" d-flex gap-3 justify-content-start ">
              <DownloadButton onClick={handleOpenPdfModal}>
                <div className="flex items-center gap-1">
                  <span>طباعة</span>
                  <ImPrinter />
                </div>
              </DownloadButton>
              {/* <DownloadButton
                onClick={() => {
                  getFinancialPdf(item?._id);
                }}
              >
                <div className="flex items-center gap-1">
                  <span> pdf طباعة</span>
                  <ImPrinter />
                </div>
              </DownloadButton> */}
              <DownloadButton
                onClick={() => handleExport(`finaical/excel/${id}`)}
              >
                <div className="flex items-center gap-1">
                  <span>تصدير Excel</span>
                  <TiExport />
                </div>
              </DownloadButton>
            </div>
          </div>
          <div className="col-md-9 mb-3">
            <p className="text-mainText">
              نوع المشروع :
              <span>
                {projectTypeEnum[item?.projectId?.requestId?.projectType]}
              </span>
            </p>
          </div>
          <div className="col-md-3 mb-3">
            <p className="text-mainText">الحالة :{paymenyEnum[item?.status]}</p>
          </div>
          <div className="col-md-9 mb-2">
            <p className="text-mainText">
              رقم الطلب : <span>{item?.projectId?.requestId?.orderNumber}</span>
            </p>
          </div>

          <div className="col-md-3  mb-2">
            <div className="d-flex align-items-center  gap-3">
              <Image
                className="pointer editIcon"
                onClick={() => {
                  setOpenDelete(true);
                }}
                src={process.env.PUBLIC_URL + "/icons/delete.png"}
              />

              <Image
                className="pointer editIcon"
                onClick={() => {
                  setEditRevenues(true);
                }}
                src={process.env.PUBLIC_URL + "/icons/edit.svg"}
              />

              {/* <NavDropdown
                title={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="4"
                    height="16"
                    viewBox="0 0 4 16"
                    fill="none"
                  >
                    <path
                      d="M2 16C0.89543 16 0 15.1046 0 14C0 12.8954 0.89543 12 2 12C3.10457 12 4 12.8954 4 14C4 15.1046 3.10457 16 2 16ZM2 10C0.89543 10 0 9.10457 0 8C0 6.89543 0.89543 6 2 6C3.10457 6 4 6.89543 4 8C4 8.53043 3.78929 9.03914 3.41421 9.41421C3.03914 9.78929 2.53043 10 2 10ZM2 4C0.89543 4 0 3.10457 0 2C0 0.89543 0.89543 0 2 0C3.10457 0 4 0.89543 4 2C4 2.53043 3.78929 3.03914 3.41421 3.41421C3.03914 3.78929 2.53043 4 2 4Z"
                      fill="white"
                    />
                  </svg>
                }
                className="fs-5 "
              >
                <NavDropdown.Item
                  className="text-end  d-flex justify-content-between  align-items-center"
                  href="#action/3.2"
                >
                  <span> المشروع</span>
                </NavDropdown.Item>
                <NavDropdown.Item
                  className="text-end  d-flex justify-content-between align-items-center"
                  href="#action/3.3"
                >
                  <span> العميل</span>
                </NavDropdown.Item>
                <NavDropdown.Item
                  className="text-end  d-flex justify-content-between align-items-center"
                  href="#action/3.3"
                >
                  <span> الحسابات</span>
                </NavDropdown.Item>
              </NavDropdown> */}
            </div>
          </div>
        </div>
      </div>
      <div className="max-h-[700px] overflow-y-auto scrollbar-thin scrollbar-track-transparent scrollbar-thumb-[#EFAA20]">
        <fieldset className="showProjectBorder w-90 mx-auto pb-3 ">
          <legend className="text-center text-mainText"> بيانات العميل</legend>

          <div className="grid grid-cols-2 gap-2 p-2">
            <DescGroup
              label="اسم المشروع"
              desc={item?.projectId?.requestId?.projectName}
            />
            <DescGroup
              label="اسم العميل"
              desc={
                <Tooltip
                  placement="left"
                  title={item?.projectId?.requestId?.ownerName}
                >
                  <span className="main-text line-clamp-1">
                    {item?.projectId?.requestId?.ownerName || "لا توجد بيانات"}
                  </span>
                </Tooltip>
              }
            />

            <DescGroup
              label="نوع المشروع"
              desc={projectTypeEnum[item?.projectId?.requestId?.projectType]}
            />
            <DescGroup
              label="رقم الطلب"
              desc={item?.projectId?.requestId?.orderNumber}
            />
            <DescGroup label="البريد الاكترونى" desc={item?.email} />
            <DescGroup
              label="رقم الجوال"
              desc={<span dir="ltr">{item?.phone || "لا توجد بيانات"}</span>}
            />
          </div>
        </fieldset>
        <fieldset className="showProjectBorder w-90 mx-auto pb-3 ">
          <legend className="text-center text-mainText"> تعريفات </legend>
          <div className="d-flex w-90 m-auto justify-content-between">
            <p className="projectdetails text-mainText w-100">
              التعريف الضريبى:
              <span className="main-text">
                مكتب بدر عبد المحسن بن سليمان لاستشارات الهندسية
              </span>
            </p>
          </div>
          <div className="d-flex w-90 m-auto justify-content-between">
            <p className="projectdetails text-mainText w-50">
              الرقم الضريبي :<span className="main-text"> 300195565100003</span>
            </p>

            <p className="projectdetails text-mainText w-50 ">
              الكود :<span className="main-text"> : {item?.codeFinaical}</span>
            </p>
          </div>
          <div className="d-flex w-90 m-auto justify-content-between">
            <p className="projectdetails text-mainText w-50">
              العنوان :<span className="main-text">الرياض حي النخيل</span>
            </p>

            <p className="projectdetails text-mainText w-50 ">
              <span className="main-text"></span>
            </p>
          </div>
        </fieldset>
        <fieldset className="showProjectBorder w-90 mx-auto pb-3 ">
          <legend className="text-center text-mainText">
            {" "}
            تعريفات العميل{" "}
          </legend>
          <div className="d-flex w-90 m-auto justify-content-between">
            <p className="projectdetails text-mainText w-100">
              تاريخ الفاتورة :
              <span className="main-text">
                {moment(item?.invoiceDate).format("DD-MM-YYYY")}
              </span>
            </p>
          </div>
          <div className="d-flex w-90 m-auto justify-content-between">
            <p className="projectdetails text-mainText w-50">
              الرقم الضريبي :{" "}
              <span className="main-text">{item?.taxNumber}</span>
            </p>

            <p className="projectdetails text-mainText w-50 ">
              السادة :{" "}
              <span className="main-text"> : {item?.letterClinte}</span>
            </p>
          </div>
          <div className="d-flex w-90 m-auto justify-content-between">
            <p className="projectdetails text-mainText w-50">
              البريد الالكتروني :
              <span className="main-text"> {item?.email}</span>
            </p>
          </div>
          <div className="d-flex w-90 m-auto justify-content-between">
            <p className="projectdetails text-mainText w-50">
              رقم الجوال :{" "}
              <span className="main-text" dir="ltr">
                {" "}
                {item?.phone}
              </span>
            </p>
          </div>
        </fieldset>
        <fieldset className="showProjectBorder w-90 mx-auto pb-3 ">
          <legend className="text-center text-mainText"> معلومات الدفع </legend>
          <div className="d-flex w-90 m-auto justify-content-between">
            <DescGroup
              label="المبلغ الاجمالي"
              desc={`${item?.projectId?.amount} ريال`}
            />
            <DescGroup
              label="عدد الدفعات"
              desc={`${item?.projectId?.batchCount} دفعة`}
            />
          </div>
          <div className="d-flex w-90 m-auto justify-content-between">
            <DescGroup
              label="المبلغ المتبقي"
              desc={`${item?.projectId?.remainingAmount} ريال`}
            />
          </div>
          <div className="d-flex w-90 m-auto justify-content-between">
            <DescGroup
              label="عدد الدفعات المتبقي"
              desc={`${item?.projectId?.remainingBatch} دفعة`}
            />
          </div>
        </fieldset>

        <fieldset className="showProjectBorder w-90 mx-auto pb-3 ">
          <legend className="text-center text-mainText"> المرفقات </legend>
          <div className="d-flex w-90 m-auto justify-content-between">
            <div className="row">
              <div className="col-md-12">
                <div className="w-100 form-container">
                  <Input
                    placeholder="اكتب الوصف"
                    className="w-[650px]"
                    label={"1-الوصف"}
                    value={item?.description}
                    disabled
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="w-100 form-container mb-3">
                  <Input
                    placeholder=" الكميه"
                    className="w-100"
                    label={"الكمية"}
                    value={item?.qty}
                    disabled
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="w-100 form-container mb-3">
                  <Input
                    placeholder=" القيمة"
                    className="w-100"
                    label={"القيمة"}
                    value={item?.amount}
                    disabled
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="w-100 form-container mb-3">
                  <Input
                    placeholder="ض.ق.م"
                    className="w-100"
                    label={"ادخل ال ض.ق.م"}
                    value={item?.vat}
                    disabled
                    discount={true}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="w-100 form-container mb-3">
                  <Input
                    placeholder="ض.ق.م"
                    className="w-100"
                    label={"ادخل ال ض.ق.م"}
                    value={item?.amountVat}
                    disabled
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="w-100 form-container mb-3">
                  <Input
                    placeholder="اجمالي المبلغ"
                    className="w-100"
                    label={" اجمالي المبلغ "}
                    value={item?.finalAmount}
                    disabled
                  />
                </div>
              </div>
              {item?.dueDate && (
                <div className="col-md-4 mb-4">
                  <Form.Group
                    className="licenseDate-container "
                    controlId="licenseDate"
                  >
                    <Form.Label className="d-flex gap-2 align-items-center   after:right-[74px]">
                      تاريخ الدفع
                    </Form.Label>
                    <DatePicker
                      selected={new Date(item?.dueDate)}
                      placeholderText=" ادخل تاريخ الدفع"
                      disabled
                      dateFormat="dd-MM-yyyy"
                      className="w-[180px] form-control"
                    />
                  </Form.Group>
                </div>
              )}
              <div className="col-md-12">
                <div className="w-100 form-container mb-3">
                  <Input
                    placeholder=" اجمالي المبلغ كتابة"
                    className="w-[650px]"
                    label={" اجمالي المبلغ كتابة"}
                    value={item?.textAmount}
                    disabled
                  />
                </div>
              </div>
            </div>
          </div>
        </fieldset>
        <fieldset className="showProjectBorder w-90 mx-auto p-2 ">
          <legend className="text-center text-mainText"> الملف المرفق </legend>
          {item?.fileFinaical && <ShowFiles files={[item?.fileFinaical]} />}
        </fieldset>
        <div className="d-flex my-5 w-90  justify-content-end">
          <Button
            onClick={() => {
              setDisplayRevenue(false);
            }}
            className="sumbmitAddUpdateUser"
          >
            حفظ
          </Button>
        </div>
      </div>
    </div>
  );
};

export default DisplayFinancialClaims;
