import { createContext, useContext, useEffect, useState } from "react";
import { myAxiosJson } from "../../../../helper/https";
import { useQuery } from "react-query";
import Cookies from "js-cookie";
import { CheckRole } from "../../../../helper/utils";
import { roles } from "../../../../helper/fetchers/Tasks";

export const ExternalTaskContext = createContext();

const fetcher = (params = {}) =>
  myAxiosJson
    .get("task/audit/confirmed", { params })
    .then((data) => data?.data);

const useGetAuditConfirmedTasks = (params) => {
  return useQuery(["audit-tasks", params], () => fetcher(params) , {
    enabled: CheckRole(roles["auditor"]) || CheckRole(roles["manager"])
  });
};

export default function ExternalTaskContextProvier({ children }) {
  const [filterTasksParams, setFilterTasksParams] = useState({});
  const { data, isLoading, isError, error } =
    useGetAuditConfirmedTasks(filterTasksParams);
  // const { data,isLoading } = useGetAllTasks(filterTasksParams);


  return (
    <ExternalTaskContext.Provider value={{ tasks: data, setFilterTasksParams }}>
      {children}
    </ExternalTaskContext.Provider>
  );
}

export const useExternalTaskContext = () => useContext(ExternalTaskContext);
