import "./index.css";
import { useParams } from "react-router-dom";
import UserSearch from "./UserSearch";
import UserFilter from "./UserFilter";
import UserList from "./UserList";
import SearchCountryUsersWrapper from "./SearchCountryUsersWrapper";

const SearchCountryUsers = () => {
  const { Country, role, departments } = useParams();
  return (
    <SearchCountryUsersWrapper
      Country={Country}
      role={role}
      departments={departments}
    >
      <UserSearch />
      <UserFilter />
      <UserList />
    </SearchCountryUsersWrapper>
  );
};

export default SearchCountryUsers;
