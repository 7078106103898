import React from "react";
import CustomTable from "../../../../../Components/Table";
import { TableRow } from "../../../../../Components/Table/TableRow";
import { TableCell } from "../../../../../Components/Table/TableCell";
import {
  clientTypeEnum,
  projectTypeEnum,
  statusEnum,
} from "../../../../../helper/utils";
import moment from "moment";
const columns = [
  {
    name: "م",
  },
  {
    name: "اسم المشروع",
  },
  {
    name: "رقم الطلب",
  },
  {
    name: "نوع العميل",
  },
  {
    name: "تاريخ الانشاء",
  },
  {
    name: "نوع المشروع",
  },
  {
    name: "الحالة",
  },
];
export default function ProjectsTable({ allProjects }) {
  return (
    <CustomTable
      paginationProps={{
        className: "hidden",
      }}
      bordered={false}
      TableHeaderProps={{
        className: "bg-[#7676764D]",
      }}
      TableHeadProps={{
        className:
          "text-end text-[10px] border-none !font-semibold p-1 text-mainText textColor",
      }}
      columns={columns}
      data={allProjects}
    >
      {allProjects && allProjects.length > 0
        ? allProjects.map(
            (
              {
                _id,
                projectName,
                orderNumber,
                clientType,
                projectType,
                createdAt,
                status,
              },
              index
            ) => (
              <TableRow
                className={`my-2  border-y !border-[#616161]`}
                key={index}
              >
                <TableCell cellClassName={"min-w-[10px]"}>
                  {index + 1}
                </TableCell>
                <TableCell>{projectName || "لا توجد بيانات"}</TableCell>
                <TableCell>{orderNumber || "لا توجد بيانات"}</TableCell>
                <TableCell>{clientTypeEnum[clientType] || "لا توجد بيانات"}</TableCell>
                <TableCell>{moment(createdAt).format("YYYY-MM-DD")}</TableCell>
                <TableCell>{projectTypeEnum[projectType] || "لا توجد بيانات"}</TableCell>
                <TableCell>
                  <div className="flex items-center gap-2">
                    <div
                      className="w-2 h-2 rounded-full"
                      style={{
                        backgroundColor: statusEnum[status]?.color,
                      }}
                    ></div>
                    <p>{statusEnum[status]?.title || "لا توجد بيانات"}</p>
                  </div>
                </TableCell>
              </TableRow>
            )
          )
        : null}
    </CustomTable>
  );
}
