import React, { useEffect } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import style from "./EditExpenses.module.css";
import "./EditExpenses.css";
import { useNavigate, useParams } from "react-router-dom";
import Input from "../../../FormHandler/Input";
import Select from "../../../FormHandler/Select";
import { useState } from "react";
import DatePicker from "react-datepicker";
import SaveButton from "../../../SaveButton";
import moment from "moment";
import { toast } from "react-toastify";
import { updateExpensesItem } from "../../../../helper/fetchers/Expenses-Items";
import Progress from "../../../Progress";
import AddAttachment from "../../AddAttachment";
import { staticImageSrc } from "../../../../Config/Config";
import { InputLabel } from "../../../../Pages/System/PlanModel/components/InputLabel";
import { useUpdateExpensesItem } from "../../../../hooks/fetchers/Expenses-items";
import SuccessfullModal from "../../../Modals/SuccessfullModal";
import useSuccessfullModal from "../../../Modals/hooks/useSuccessfullModal";

const EditExpenses = ({
  selectedItem,
  editExpenses,
  setEditExpenses,
  setOpenDisplayDetails,
}) => {
  const navigate = useNavigate();
  const {
    handleShowSuccess,
    handleShowError,
    handleCloseSuccess,
    handleCloseError,
    showSuccess,
    showError,
  } = useSuccessfullModal();
  const { mutate, isLoading } = useUpdateExpensesItem(
    handleShowSuccess,
    handleShowError,
    selectedItem?._id
  );
  const { ExpensesType } = useParams();
  const [deliverDate, setDeliverDate] = useState("");
  const [createdDate, setCreatedDate] = useState("");
  const [description, setDescription] = useState("");
  const [name, setName] = useState("");
  // const [code, setCode] = useState("");
  const [attachment, setAttachment] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  useEffect(() => {
    if (selectedItem) {
      if (selectedItem.createdAt) {
        setCreatedDate(new Date(selectedItem.createdAt));
      }
      setDescription(selectedItem.description);
      setName(selectedItem.name);
      // setCode(selectedItem.codeBatche);
    }
  }, [selectedItem]);

  const clauseOptions = [
    {
      label: "اساسيه",
      value: " اساسيه",
    },
    {
      label: "ايجارات",
      value: " ايجارات",
    },
  ];

  // handle editing Revenues
  const handelEditRevnue = () => {
    setEditExpenses(false);
  };
  const files = attachment ? [...attachment] : [];

  const handleUpdateItems = async () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("name", name);
    formData.append("description", description);
    formData.append("dateCreated", moment(createdDate).format("YYYY-MM-DD"));
    files.forEach((file) => {
      formData.append(`image`, file);
    });
    const newErrors = [];
    for (var pair of formData.entries()) {
      if (pair[0] === "name" || pair[0] === "description") {
        const value = pair[1];
        if (value.length < 5 || value.length > 500) {
          newErrors.push(
            `${
              pair[0] === "name" ? "اسم الصنف" : "الوصف"
            } يجب ان لا يقل علي 5 احرف ولا يزيد عن 500 حرف`
          );
        }
      }

      if (
        pair[0] === "dateCreated" &&
        !moment(pair[1], "YYYY-MM-DD", true).isValid()
      ) {
        newErrors.push("تاريخ الانشاء غير صحيح");
      }

      setErrors(newErrors);
      setLoading(false);
    }
    if (newErrors.length === 0) {
      mutate(formData);
      // try {
      //   const { data } = await updateExpensesItem(selectedItem?._id, formData);
      //   if (data?.success) {
      //     toast.success("تم تعديل الصنف بنجاح");
      // setEditExpenses(false);
      // setOpenDisplayDetails(false);
      //     setLoading(false);
      //   } else {
      //     setLoading(false);
      //   }
      // } catch (error) {
      //   setLoading(false);
      //   toast.error(error?.response?.data?.message);
      // }
    }
  };
  return (
    <div className="EditExpenses">
      {editExpenses && ExpensesType === "ExpensesReports" ? (
        <Modal
          className="systemModal EditExpenses"
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          onHide={() => setEditExpenses(false)}
          show={editExpenses}
        >
          <Modal.Body className="w-full">
            <div className="p-1 mx-auto mb-3 edit-header mt-1 w-50">
              <p className="golden text-center">تعديل فى المطالبة المالية </p>
            </div>
            <fieldset className="mx-auto border-golden w-90 p-4">
              <legend className="text-center text-mainText">تعريفات</legend>
              <Input label={"1- اسم التقرير"} placeholder="اكتب اسم التقرير" />
            </fieldset>
            <fieldset className={`${style.description} mt-3`}>
              <legend className="text-center text-mainText">الوصف</legend>
              <div className="row p-3">
                <div className="col-md-6 mb-4">
                  <Select
                    OptionbackgroundColor={"#2B2B40"}
                    options={clauseOptions}
                    placeholder="اختار البند"
                    label="1- البند"
                  />
                </div>
                <div className="col-md-12 mb-4">
                  <Form.Group>
                    <Form.Label>الوصف</Form.Label>
                    <textarea
                      className="form-control"
                      placeholder="اكتب الوصف المرفق ..."
                      rows={5}
                      cols={30}
                    />
                  </Form.Group>
                </div>
                <div className="col-md-8   mb-4">
                  <Form.Group className="d-flex flex-column">
                    <Form.Label>تاريخ الاستحقاق</Form.Label>
                    <DatePicker
                      selected={deliverDate}
                      placeholderText=" ادخل تاريخ الرخصة "
                      onChange={(date) => setDeliverDate(date)}
                      dateFormat="dd-MM-yyyy"
                      className="w-100 form-control"
                    />
                  </Form.Group>
                </div>
                <div className="col-md-4 mb-4">
                  <Input placeholder={"ادخل المبلغ"} label={"المبلغ"} />
                </div>
                <div className="col-md-6 mb-4">
                  <Input placeholder={"1000"} label={"اجمالى المبلغ"} />
                </div>
                <div className="col-md-6 mb-4">
                  <Input placeholder={""} label={"اجمالى المبلغ كتابة"} />
                </div>
              </div>
            </fieldset>
            <fieldset className={`${style.Attachment} mt-3 p-3`}>
              <legend className="text-center text-mainText">
                الملف المرفق
              </legend>

              <div className="pdfbg">
                <img
                  src={process.env.PUBLIC_URL + "/icons/Pdf.png"}
                  alt="pdf"
                  className="pdfImage"
                />
                <div
                  style={{ borderRadius: "7px" }}
                  className="bg-[#252538] d-flex justify-content-center "
                >
                  <p className="text-mainText mx-auto   mt-2   ">المصروفات </p>
                </div>
              </div>
            </fieldset>

            <div
              onClick={() => {
                handelEditRevnue();
              }}
              className="my-3 d-flex justify-content-end"
            >
              <SaveButton />
            </div>
          </Modal.Body>
        </Modal>
      ) : (
        <Modal
          className="systemModal EditExpenses"
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          onHide={() => setEditExpenses(false)}
          show={editExpenses}
        >
          <Modal.Body className="w-full">
            <div className="p-1 mx-auto mb-3 edit-header mt-1 w-50">
              <p className="golden text-center">تعديل فى الاصناف </p>
            </div>
            <fieldset className="mx-auto border-golden w-90 p-4">
              <legend className="text-center text-mainText">تعريفات</legend>
              <InputLabel size={18} label={"اسم الصنف"} className={"mb-1"} />
              <Input
                // label={"اسم الصنف"}
                placeholder="اكتب اسم الصنف"
                value={name}
                defaultValue={name}
                onChange={(e) => setName(e.target.value)}
              />
            </fieldset>
            <fieldset className={`${style.itemdescription} mt-3`}>
              <legend className="text-center text-mainText">الوصف</legend>
              <div className="row p-3">
                <div className="col-md-12 mb-4">
                  <Form.Group>
                    {/* <Form.Label>الوصف</Form.Label> */}
                    <InputLabel size={18} label={"الوصف"} className={"mb-1"} />

                    <textarea
                      onChange={(e) => {
                        setDescription(e.target.value);
                      }}
                      value={description}
                      className="form-control"
                      placeholder="اكتب الوصف المرفق ..."
                      rows={5}
                      cols={30}
                    />
                  </Form.Group>
                </div>
                <div className="col-md-8   mb-4">
                  <Form.Group className="d-flex flex-column">
                    {/* <Form.Label>تاريخ الانشاء</Form.Label> */}
                    <InputLabel
                      size={18}
                      label={"تاريخ الانشاء"}
                      className={"mb-1"}
                    />

                    <DatePicker
                      selected={createdDate}
                      placeholderText=" ادخل تاريخ الانشاء "
                      onChange={(date) => setCreatedDate(date)}
                      dateFormat="dd-MM-yyyy"
                      className="w-100 form-control"
                    />
                  </Form.Group>
                </div>
                {/* <div className="col-md-4 mb-4">
                  <Input
                    placeholder={" كودالصنف"}
                    label={"كود الصنف"}
                    value={code}
                    disabled
                    defaultValue={code}
                    onChange={(e) => setCode(e.target.value)}
                  />
                </div> */}
              </div>
            </fieldset>
            <fieldset
              className={`${style.Attachment} mt-4 p-2 flex items-center justify-start gap-4 overflow-x-auto custom-scrollbar`}
            >
              <legend className="text-center text-mainText">
                الملف المرفق
              </legend>
              <AddAttachment
                attachment={attachment}
                setAttachment={setAttachment}
                multi={true}
              />
              {attachment &&
                Array.from(attachment).map((image, index) => (
                  <img
                    key={index}
                    src={URL.createObjectURL(image)}
                    className="pointer w-ful h-[90px] object-cover rounded-lg"
                    alt="view icon"
                  />
                ))}

              {selectedItem?.image &&
                selectedItem?.image.map((image, index) => (
                  <img
                    key={index}
                    src={`${image}`}
                    className="pointer w-ful h-[90px] object-cover rounded-lg"
                    alt="view icon"
                  />
                ))}
            </fieldset>

            <div className="d-flex my-5 w-90  justify-content-end">
              <Button
                disabled={isLoading}
                onClick={() => {
                  handleUpdateItems();
                }}
                className="sumbmitAddUpdateUser"
              >
                {isLoading ? <Progress isSmall /> : "حفظ"}
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      )}
      <SuccessfullModal
        handleClose={() => {
          handleCloseSuccess();
          setEditExpenses(false);
          // setOpenDisplayDetails(false);
          navigate("/System/Accounating/index");
        }}
        message={"تم التعديل بنجاح"}
        show={showSuccess}
      />
      <SuccessfullModal
        handleClose={handleCloseError}
        message={"حدث خطأ ما"}
        show={showError}
        status="error"
      />
    </div>
  );
};

export default EditExpenses;
