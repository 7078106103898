import React from "react";
import Style from "./style.module.css";
import { twMerge } from "tailwind-merge";
export const SearchComponent = ({
  background = "darker",
  width,
  placeholder,
  className,
  value,
  handleChange,
  border = "border-0",
}) => {
  return (
    <div className="flex !w-full justify-center">
      <input
        style={{
          backgroundColor: "var(--bg-item)",
        }}
        className={twMerge(
          `rounded-[7.721px] h-[37.06px] text-mainText placeholder:text-mainText p-3 search w-full`,
          border,
          className ?? ""
        )}
        placeholder={placeholder ?? "بحث..."}
        onChange={(e) => handleChange(e.target.value)}
        value={value}
      />
    </div>
  );
};
