import React from "react";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { socket, sockietDissconnect } from "../utiltis/SockietHelper";
import { myAxiosJson } from "../helper/https";



export const UserProvider = React.createContext();
const UserContext = ({ children }) => {
  
  const user = Cookies.get("accessToken") ? Cookies.get("accessToken") : null;
  const role = Cookies.get("role") ? Cookies.get("role") : null;
  const navigate = useNavigate();
  const logOut = () => {
    myAxiosJson("user/logout")
    .then((res) => {
      socket.disconnect()
      Cookies.remove("accessToken");
      Cookies.remove("role");
      Cookies.remove("socketId");
      Cookies.remove("goals");
      Cookies.remove("userId");
      Cookies.remove("country");
      Cookies.remove("department");
      Cookies.remove("firstName");
      Cookies.remove("lastName");
      Cookies.remove("status");
      // sockietDissconnect()
      // console.log(res);
      navigate("/");
      // window.location.reload();
    })
    .catch((err)=>{
      console.log(err);
    });
  };
  return (
    <UserProvider.Provider value={{ user, logOut , role }}>
      {children}
    </UserProvider.Provider>
  );
};

export default UserContext;
