import React, { useEffect, useState } from "react";
import { toggleWidth, resetWidth } from "../slices/fullWidthSlice";
import { useDispatch } from "react-redux";
import TasksTable from "../../Plans/Projects/components/TasksTable";
import MenuIconButton from "../components/MenuIconButton";

import { useTaskContext } from "../../PlanModel/context/TaskContext";
import { CiSearch } from "react-icons/ci";
import { SearchComponent } from "../../../../Components/SearchComponent/SearchComponent";
function TasksPage() {
  useEffect(() => {
    dispatch(resetWidth());
    return () => dispatch(resetWidth());
  }, []);
  const dispatch = useDispatch();
  const { setFilterTasksParams , selectedTab } = useTaskContext();
  const [search, setSearch] = useState("");
  const handleSearch = (value) => {
    setSearch(value);
    // console.log(e.target.value);
    setFilterTasksParams({ search: value });
  };
  return (
    <div className="flex-1 bg-mainItems">
      <div className="grid grid-cols-3 py-2">
        <div className="flex items-center gap-3 ">
          <MenuIconButton onClick={() => dispatch(toggleWidth())} />
          <p className="text-[#EFAA20] font-semibold task-base lg:text-xl">{selectedTab}</p>
        </div>
        <div className="col-span-2">
          <SearchComponent
            // className="m-auto w-[95%]"
            background={"#2B2B40"}
            border="border !border-[#E4A11B]"
            value={search}
            handleChange={handleSearch}
          />
        </div>
      </div>
      <TasksTable />
    </div>
  );
}

export default TasksPage;

const Search = ({ ...props }) => {
  return (
    <div
      dir="ltr"
      className="bg-mainNavy px-3 py-2 rounded-[7.721px] flex items-center gap-2 w-[500px]"
    >
      <input
        type="text"
        placeholder=".......ابحث"
        className="w-full text-mainText bg-transparent text-end "
        {...props}
      />
      <CiSearch fontSize={20} fontWeight={500} />
    </div>
  );
};
