import React, { createContext, useContext, useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import DownloadButton from "../../../../../../Components/Buttons/DownloadButton";
import "../../../../../../Components/System/Main/MainSystem.css";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { Button, Modal, NavDropdown, Spinner } from "react-bootstrap";
import useExportExcel from "../../../../../../hooks/useExportExcel";
import ExcellModall from "../../../../../../Components/Modals/ExcellModall";
import { FilterIcon } from "../../../../../../utiltis/Icons";
import { GrTransaction } from "react-icons/gr";
import useModal from "../../../../../../hooks/useModal";
import { useForm } from "react-hook-form";
import moment from "moment";
import { InputLabel } from "../../../../../System/PlanModel/components/InputLabel";
import { CustomDatePicker } from "../../../../../../Components/FormUi";
import {
  useGetAllClientsInfo,
  useGetAllClientsInfoDesign,
  useGetAllClientsInfoSupervising,
  useGetAllClientsReports,
  useGetClientsStatisticsDesignReports,
  useGetClientsStatisticsReports,
  useGetClientsStatisticsSupervisorReports,
} from "../../../../../../hooks/fetchers/Clients";
import CustomChart from "../../../../../System/Main/components/charts/CustomChart";
import { useReportContext } from "../../../ReportContext";
import { TiExport } from "react-icons/ti";
import { ImPrinter } from "react-icons/im";
import PrintClientsDetailsFile from "../../../components/PrintClientsDetailsFile";
import PrintClientsDesignDetailsFile from "../../../components/PrintClientsDesignDetailsFile";
import PrintClientsSupervisingDetailsFile from "../../../components/PrintClientsSupervisingDetailsFile";

const MainClientsReportsPage = () => {
  const { projectType, isExternal } = useReportContext();
  const [excelPathName, setExcelPathName] = useState(
    "system-reports/clients/export/excel"
  );
  const [range, setRange] = useState({});
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { open, handleOpenModal, handleCloseModal } = useModal();
  const { loading, allKeys, excelData, fileUrl, handleExport, resetExcelData } =
    useExportExcel();
  useEffect(() => {
    setExcelPathName("system-reports/clients/export/excel");
    if (projectType) {
      setExcelPathName(
        `system-reports/clients/export/excel?projectType=${projectType}`
      );
    }
    if (isExternal) {
      setExcelPathName(
        `system-reports/clients/export/excel?isExternal=${isExternal.toString()}`
      );
    }
    if (projectType && isExternal) {
      setExcelPathName(
        `system-reports/clients/export/excel?projectType=${projectType}&isExternal=${isExternal.toString()}`
      );
    }
    console.log({ projectType, isExternal });
  }, [projectType, isExternal]);

  const {
    open: openPdf,
    handleOpenModal: handleOpenPdfModal,
    handleCloseModal: handleClosePdfModal,
  } = useModal();

  const { data, isLoading, isError } = useGetAllClientsInfo({
    // range,
  });
  const { data:dataSupervising, isLoading:isLoadingSupervising, isError:isErrorSupervising } = useGetAllClientsInfoSupervising({
    // range,
  });
  const { data:dataDesign, isLoading:isLoadingDesign, isError:isErrorDesign } = useGetAllClientsInfoDesign({
    // range,
  });
console.log("dataDesign",dataDesign?.clientsData[0]);
  return (
    <div className="p-2">
      {!loading && excelData && (
        <ExcellModall
          onHide={resetExcelData}
          fileUrl={fileUrl}
          keys={allKeys}
          data={excelData}
        />
      )}

      <FilterModal
        show={open}
        onHide={handleCloseModal}
        onSave={(data) => {
          setRange(`${data.startDate},${data.endDate}`);
          console.log(`${data.startDate}, ${data.endDate}`);
        }}
      />

      {projectType === 1 ? (
        <>
        {!isLoadingDesign && !isErrorDesign ? (
          <PrintClientsDesignDetailsFile
            show={openPdf}
            onHide={handleClosePdfModal}
            data={{
              clients: dataDesign?.clients,
              clientsData: dataDesign?.clientsData[0],
            }}
          />
        ) : null}
      </>
      ) : projectType === 2 ? (
        <>
          {!isLoadingSupervising && !isErrorSupervising ? (
            <PrintClientsSupervisingDetailsFile
              show={openPdf}
              onHide={handleClosePdfModal}
              data={{
                clients: dataSupervising?.clients,
                clientsData: dataSupervising?.clientsData[0],
              }}
            />
          ) : null}
        </>
      ) : (
        <>
          {!isLoading && !isError ? (
            <PrintClientsDetailsFile
              show={openPdf}
              onHide={handleClosePdfModal}
              data={{
                clients: data?.clients,
                clientsData: data?.clientsData[0],
              }}
            />
          ) : null}
        </>
      )}

      <div className="flex justify-end items-center p-3">
        <div className="flex items-center gap-2">
          <DownloadButton onClick={() => handleExport(excelPathName)}>
            <div className="flex items-center gap-1">
              <span>تصدير Excel</span>
              <TiExport />
            </div>
          </DownloadButton>
          <DownloadButton onClick={handleOpenPdfModal}>
            <div className="flex items-center gap-1">
              <span>طباعة</span>
              <ImPrinter />
            </div>
          </DownloadButton>

          <div className="">
            <NavDropdown
              title={<FilterIcon />}
              className="fs-5"
              id="collapsible-nav-dropdown"
            >
              <NavDropdown.Item
                onClick={handleOpenModal}
                className="flex items-center gap-1 !bg-white !text-black overflow-hidden rounded-lg"
              >
                <GrTransaction color="black" />
                <span className="!text-black">من...الي</span>
              </NavDropdown.Item>
            </NavDropdown>
          </div>
        </div>
      </div>
      <TabProvider>
        <Tabs>
          <Tab label="كل عملاء الاشراف" />
          <Tab label="كل العملاء" />
          <Tab label="كل عملاء التصميم" />
        </Tabs>
        <TabPanels>
          <TabPanel index={0}>
            <AllStatisticsSupervisor />
          </TabPanel>
          <TabPanel index={1}>
            <AllStatistics />
          </TabPanel>
          <TabPanel index={2}>
            <AllStatisticsDesign />
          </TabPanel>
        </TabPanels>
      </TabProvider>
    </div>
  );
};

const AllStatistics = () => {
  const { data, isLoading, isError } = useGetClientsStatisticsReports();
  console.log("data", data);

  return (
    <div className="space-y-4">
      <div className="w-full flex justify-center items-center">
        {isLoading ? <Spinner /> : null}
        {isError ? <p className="text-red-400 text-lg">يوجد خطا ما</p> : null}
      </div>
      {!isLoading && !isError ? (
        <div className="shadow rounded-3xl p-2 bg-bgCard">
          <CustomChart
            labelClass={"min-w-fit mt-2"}
            data={data?.transformedData}
            chartTitle="عدد العملاء"
            title="عدد العملاء"
            mainColTitle="الفئة"
            showPercentage={false}
          />
        </div>
      ) : null}

      {/* <FormModal>
        {isLoading ? <Spinner /> : null}
        {isError ? <p className="text-red-400 text-lg">يوجد خطا ما</p> : null}
        {!isLoading && !isError ? (
          <>
            <ApexLine data={data?.transformedData} type="line" />
            <AllDeprtmentSlider chartData={data?.transformedData} />
          </>
        ) : null}
      </FormModal> */}
    </div>
  );
};
const AllStatisticsDesign = () => {
  const { data, isLoading, isError } = useGetClientsStatisticsDesignReports();
  return (
    <div>
      <div className="w-full flex justify-center items-center">
        {isLoading ? <Spinner /> : null}
        {isError ? <p className="text-red-400 text-lg">يوجد خطا ما</p> : null}
      </div>
      {!isLoading && !isError ? (
        <div className="shadow rounded-3xl p-2 bg-bgCard">
          <CustomChart
            labelClass={"min-w-fit mt-2"}
            data={data?.transformedData}
            chartTitle="عدد العملاء"
            title="عدد العملاء"
            mainColTitle="الفئة"
            showPercentage={false}
          />
        </div>
      ) : null}
    </div>
  );
};
const AllStatisticsSupervisor = () => {
  const { data, isLoading, isError } =
    useGetClientsStatisticsSupervisorReports();
  return (
    <div>
      <div className="w-full flex justify-center items-center">
        {isLoading ? <Spinner /> : null}
        {isError ? <p className="text-red-400 text-lg">يوجد خطا ما</p> : null}
      </div>
      {!isLoading && !isError ? (
        <div className="shadow rounded-3xl p-2 bg-bgCard">
          <CustomChart
            labelClass={"min-w-fit mt-2"}
            data={data?.transformedData}
            chartTitle="عدد العملاء"
            title="عدد العملاء"
            mainColTitle="الفئة"
            showPercentage={false}
          />
        </div>
      ) : null}
    </div>
  );
};

{
  /* <ApexColumn data={data?.transformedData} /> */
}
const TabContext = createContext(null);
const TabProvider = ({ children }) => {
  const [tabIndex, setTabIndex] = useState(1);
  const [tabsLength, setTabsLength] = useState(null);
  const handleNextStep = () => {
    setTabIndex((prev) => {
      if (prev >= 2) return 2;
      return prev + 1;
    });
  };
  const handlePrevStep = () => {
    setTabIndex((prev) => {
      if (prev <= 0) return 0;
      return prev - 1;
    });
  };
  console.log("tabIndex", tabIndex);
  return (
    <TabContext.Provider
      value={{
        tabIndex,
        handleNextStep,
        handlePrevStep,
        tabsLength,
        setTabsLength,
      }}
    >
      {children}
    </TabContext.Provider>
  );
};

const useTabCtx = () => {
  const ctx = useContext(TabContext);
  if (!ctx) {
    throw Error("Use inside context ");
  }
  return ctx;
};

const Tabs = ({ children }) => {
  const {
    tabIndex,
    handleNextStep,
    handlePrevStep,
    tabsLength,
    setTabsLength,
  } = useTabCtx();
  useEffect(() => {
    setTabsLength(children?.length);
  }, []);
  console.log("tabsLength", tabsLength);
  return (
    <div className="flex justify-center items-center gap-4 mb-3" dir="ltr">
      {tabIndex !== 0 ? (
        <button className="" onClick={handlePrevStep}>
          <IoIosArrowBack size={25} color="#EFAA20" />
        </button>
      ) : null}

      {children[tabIndex]}

      {tabIndex < tabsLength - 1 ? (
        <button className="" onClick={handleNextStep}>
          <IoIosArrowForward size={25} color="#EFAA20" />
        </button>
      ) : null}
    </div>
  );
};
const Tab = ({ label = "" }) => {
  return <p> {label}</p>;
};
const TabPanels = ({ children }) => {
  const { tabIndex, handleNextStep, handlePrevStep } = useTabCtx();
  // const selectedChild = Children.map((child,index)=> )
  console.log("children", children[tabIndex]);
  return <>{children[tabIndex]}</>;
};
const TabPanel = ({ children, index }) => {
  const { tabIndex, handleNextStep, handlePrevStep } = useTabCtx();
  return <div>{children}</div>;
};

export default MainClientsReportsPage;

const FilterModal = ({ show, onHide, onSave }) => {
  const { handleSubmit, control } = useForm();
  const manipulateData = (data) => {
    data.startDate = moment(data.startDate).format("").split("T")[0];
    data.endDate = moment(data.endDate).format("").split("T")[0];
    return data;
  };
  const onSubmit = (data) => {
    data = manipulateData(data);
    onSave(data);
    onHide();
  };
  return (
    <Modal
      show={show}
      onHide={onHide}
      className="systemModal bg-black/50 "
      contentClassName="border !border-[#EFAA20] rounded-3xl p-0"
      centered
    >
      <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header className="w-full !justify-between border-none">
          <Modal.Title className="text-mainText">{"تصنيف"}</Modal.Title>
          <Button
            className="p-0 bg-transparent hover:bg-transparent"
            onClick={onHide}
          >
            <img src="/Rejected.svg" alt="" />
          </Button>
        </Modal.Header>
        <Modal.Body className="w-full space-y-2">
          <div className="flex flex-col gap-2">
            <InputLabel label={"من تاريخ"} />
            <CustomDatePicker
              control={control}
              name="startDate"
              rules={{
                required: "من فضلك ادخل تاريخ البداية ",
              }}
            />
          </div>
          <div className="flex flex-col gap-2">
            <InputLabel label={"الي تاريخ"} />
            <CustomDatePicker
              control={control}
              name="endDate"
              rules={{
                required: "من فضلك ادخل تاريخ النهاية ",
              }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer className="w-full justify-center border-none">
          <Button type="submit" className="!bg-[#EFAA20]">
            {"حفظ"}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

const ApexLine = ({ data, type = "bar" }) => {
  const series = [
    {
      name: "",
      data: data?.map((emp) => emp?.value),
    },
    {
      name: "",
      data: data?.map((emp) => emp?.value),
    },
  ];

  const options = {
    chart: {
      height: 350,
      type: type,
      sparkline: {
        enabled: false,
      },
      animations: {
        enabled: true,
        easing: "easeinout",
        speed: 800,
        animateGradually: {
          enabled: true,
          delay: 150,
        },
        dynamicAnimation: {
          enabled: true,
          speed: 350,
        },
      },
      // brush: {
      //     enabled: false,
      //     target: undefined,
      //     autoScaleYaxis: false
      //   }
    },
    legend: {
      labels: {
        colors: "var(--main-text)",
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 5,
        dataLabels: {
          position: "top", // top, center, bottom
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val + "%";
      },
      offsetY: 0,
      style: {
        fontSize: "12px",
        colors: ["var(--main-text)"],
      },
    },
    colors: ["#EFAA20"],
    xaxis: {
      categories: data?.map((emp) => emp?.label),
      position: "bottom",
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      // crosshairs: {
      //   fill: {
      //     type: 'gradient',
      //     gradient: {
      //         colorFrom: "var(--main-text)",
      //         colorTo: "var(--main-text)",
      //       stops: [0, 100],
      //       opacityFrom: 0.4,
      //       opacityTo: 0.5,
      //     }
      //   }
      // },
      tooltip: {
        enabled: false,
      },
    },
    yaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
        formatter: function (val) {
          return val + "%";
        },
      },
    },
  };

  return (
    <div>
      <div id="chart" className="allUsersCoulmnChart custom-chart">
        <ReactApexChart
          options={options}
          series={series}
          type={type}
          height={350}
        />
      </div>
      <div id="html-dist"></div>
    </div>
  );
};
