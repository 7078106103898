import React, { useCallback, useEffect } from "react";
import "./AddInvoice.css";
import { useContext, useMemo, useState } from "react";
import Input from "../../../FormHandler/Input";
import { UseInput } from "../../../../hooks";
import { Button, Form, Modal } from "react-bootstrap";
import SaveButton from "../../../SaveButton";
import { addAccountType } from "../../../../Context/AddAccountaing";
import { getAllFinancialProject } from "../../../../helper/fetchers/finaical";
import Select from "../../../FormHandler/Select";
import AddAttachment from "../../AddAttachment";
import ShowLocalFiles from "../../../ShowLocalFiles";
import DatePicker from "react-datepicker";
import {
  addInvoice,
  getAllProjectInvoices,
} from "../../../../helper/fetchers/invoice";
import { toast } from "react-toastify";
import SuccessfullModal from "../../../Modals/SuccessfullModal";
import { PhoneInput } from "react-international-phone";
import LoadingPage from "../../../feedback/LoadingPage";

const AddInvoice = ({ getInvoices }) => {
  // handle Inputs
  const projectDescription = UseInput("", "", true);
  const projectQuantity = UseInput("", "", true);
  const projectValue = UseInput("", "", true);
  const vat = UseInput("", "", true);
  const amountVat = UseInput("", "", true);
  const textAmount = UseInput("", "", true);
  const invoiceNumber = UseInput("", "", true);
  const email = UseInput("", "", true);
  // const phone = UseInput("", "", true);
  const [phone, setPhone] = useState("");
  const [invoiceDate, setInvoiceDate] = useState("");
  const [attachment, setAttachment] = useState(null);

  // handle Add invoice
  const { setOpenAddAccountant } = useContext(addAccountType);
  // handleAddInvoice

  const [requestList, setRequestList] = useState([]);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [invoice, setInvoice] = useState(null);
  const [SelectedInvoice, setSelectedInvoice] = useState(null);

  // #region All About Request
  const getAllRequests = useCallback(async () => {
    const data = await getAllFinancialProject();
    if (data.status === 200) {
      setRequestList(data?.data?.projects);
    }
  }, []);
  useEffect(() => {
    getAllRequests();
  }, [getAllRequests]);

  const MappedRequestList = requestList?.map((request) => ({
    value: request?.id,
    label: request?.requestId?.projectName,
  }));

  // #endregion

  // #region Get All Invoices
  const getProjectInvoices = useCallback(async () => {
    if (selectedRequest) {
      const res = await getAllProjectInvoices(selectedRequest?._id);
      // console.log(res, "ressssss");
      if (res?.status === 200) {
        setInvoice(res?.data?.financials);
      } else {
        console.log(res, "Error");
      }
    }
  }, [selectedRequest]);

  useEffect(() => {
    getProjectInvoices();
  }, [getProjectInvoices]);

  const MappedFinancialList = invoice?.map((request) => ({
    value: request?._id,
    label: request?.codeFinaical,
  }));
  //#endregion
  const files = attachment ? [...attachment] : [];
  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleAdd = async () => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("projectId", selectedRequest?.id);
    formData.append("financialId", SelectedInvoice);
    formData.append("qty", projectQuantity.value);
    formData.append("description", projectDescription.value);
    formData.append("vat", vat?.value);
    formData.append("amountVat", amountVat.value);
    formData.append("phone", phone);
    formData.append("textAmount", textAmount?.value);
    formData.append("email", email?.value);
    formData.append("invoiceDate", invoiceDate);
    formData.append("invoiceNumber", invoiceNumber?.value);
    formData.append("amount", projectValue.value);
    if (attachment && attachment?.length > 0) {
      files.forEach((file) => {
        formData.append(`invoiceFile`, file);
      });
    }
    try {
      const res = await addInvoice(formData);
      if (res?.status === 201) {
        setSuccess(true);
        getInvoices();
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
    setIsLoading(false);
  };

  // console.log(SelectedInvoice, "selectedRequest");
  return isLoading ? (
    <LoadingPage />
  ) : (
    <div className="AddInvoice p-3">
      <p className="text-xl  my-3 golden">إضافة فاتورة جديدة</p>
      <div className="flex items-center justify-between">
        <div className=" col-md-6 mb-4">
          {MappedRequestList && (
            <Select
              label={"بحث عن اسم المشروع"}
              options={MappedRequestList}
              mandatory
              className="w-[350px] text-mainText"
              onChange={(e) => {
                setSelectedRequest(requestList?.find((x) => x.id === e.value));
              }}
            />
          )}
        </div>
        <div className=" col-md-6 mb-4">
          {MappedFinancialList && invoice?.length > 0 && (
            <Select
              label={"كود المطالبة"}
              options={MappedFinancialList}
              mandatory
              className="w-[350px] text-mainText"
              onChange={(e) => {
                setSelectedInvoice(e.value);
              }}
            />
          )}
        </div>
      </div>
      <SuccessfullModal
        message={"تم اضافه فاتوره بنجاح"}
        handleClose={() => {
          setSuccess(false);
          setOpenAddAccountant(false);
        }}
        show={success}
      />
      {selectedRequest && SelectedInvoice && (
        <>
          <fieldset className="showProjectBorder w-90 mx-auto pb-3 ">
            <legend className="text-center text-mainText">
              
              بيانات العميل
            </legend>
            <div className="d-flex w-90 m-auto justify-content-between">
              <p className="projectdetails text-mainText w-50">
                
                اسم المشروع :
                <span className="text-mainText">
                  
                  {selectedRequest?.requestId?.projectName}
                </span>
              </p>
              <p className="projectdetails text-mainText w-50">
                
                اسم العميل:
                <span className="text-mainText">
                  {selectedRequest?.requestId?.ownerName}
                </span>
              </p>
            </div>
            <div className="d-flex w-90 m-auto justify-content-between">
              <p className="projectdetails text-mainText w-50">
                نوع المشروع :
                <span className="text-mainText">
                  {selectedRequest?.requestId?.projectType === 1
                    ? "تصميم"
                    : "اشراف علي التنفيذ"}
                </span>
              </p>

              <p className="projectdetails text-mainText w-50 ">
                رقم الطلب :
                <span className="text-mainText">
                  
                  {selectedRequest?.requestId?.orderNumber}
                </span>
              </p>
            </div>
            <div className="d-flex w-90 m-auto justify-content-between">
              <p className="projectdetails text-mainText w-50">
                البريد الاكترونى :
                <span className="text-mainText">
                  {selectedRequest?.requestId?.email}
                </span>
              </p>

              <p className="projectdetails text-mainText w-50 ">
                رقم الجوال :
                <span className="text-mainText">
                  {selectedRequest?.requestId?.phone}
                </span>
              </p>
            </div>
          </fieldset>
          <fieldset className="showProjectBorder w-90 mx-auto pb-3 ">
            <legend className="text-center text-mainText"> تعريفات </legend>
            <div className="d-flex w-90 m-auto justify-content-between">
              <p className="projectdetails text-mainText w-100">
                
                التعريف الضريبى:
                <span className="text-mainText">
                  
                  مكتب بدر عبد المحسن بن سليمان لاستشارات الهندسية
                </span>
              </p>
              <p className="projectdetails text-mainText w-50">
                
                اسم العميل:
                <span className="text-mainText"> اسلام ايهاب</span>
              </p>
            </div>
            <div className="d-flex w-90 m-auto justify-content-between">
              <p className="projectdetails text-mainText w-50">
                
                الرقم الضريبي :
                <span className="text-mainText"> 300195565100003</span>
              </p>

              <p className="projectdetails text-mainText w-50 ">
                
                الكود :<span className="text-mainText"> : 0003</span>
              </p>
            </div>
            <div className="d-flex w-90 m-auto justify-content-between">
              <p className="projectdetails text-mainText w-50">
                
                العنوان :<span className="text-mainText">الرياض حي النخيل</span>
              </p>

              <p className="projectdetails text-mainText w-50 ">
                <span className="text-mainText"></span>
              </p>
            </div>
          </fieldset>
          <fieldset className="showProjectBorder w-90 mx-auto pb-3 ">
            <legend className="text-center text-mainText"> المرفقات </legend>
            <div className="d-flex w-90 m-auto justify-content-between">
              <div className="row">
                <div className="col-md-4">
                  <div className="w-100 form-container ">
                    <Input
                      {...invoiceNumber.bind}
                      placeholder="رقم الفاتوره"
                      className="w-100"
                      label={"ادخل رقم الفاتوره"}
                    />
                  </div>
                </div>

                <div className="col-md-4 mb-4">
                  <Form.Group
                    className="licenseDate-container "
                    controlId="licenseDate"
                  >
                    <label className="d-flex gap-2 align-items-center text-mainText ">
                      تاريخ الفاتورة
                    </label>
                    <DatePicker
                      mandatory
                      selected={invoiceDate}
                      placeholderText=" ادخل تاريخ الفاتورة "
                      onChange={(date) => setInvoiceDate(date)}
                      dateFormat="dd-MM-yyyy"
                      className="w-100 form-control h-[37px]"
                    />
                  </Form.Group>
                </div>
                <div className="col-md-4">
                  <div className="w-100 form-container mb-3">
                    <Input
                      {...email.bind}
                      placeholder="البريد  الالكتروني"
                      className="w-100"
                      label={"ادخل البريد  الالكتروني"}
                    />
                  </div>
                </div>
                <div className="col-md-6 mb-4">
                  <label className="text-mainText">
                    رقم الجوال <span className="text-danger">*</span>
                  </label>
                  <PhoneInput
                    defaultCountry="sa"
                    placeholder="ادخل رقم الجوال"
                    value={phone}
                    onChange={(phone) => setPhone(phone)}
                  />
                  {/* <Input
            placeholder="ادخل  رقم الجوال"
            label={"رقم الجوال"}
            value={phone}
            onChange={(e) => setPhone(e?.target?.value)}
            mandatory
          /> */}
                </div>
                {/* <div className="col-md-4">
                  <div className="w-100 form-container mb-3">
                    <Input
                      {...phone.bind}
                      placeholder="رقم الجوال"
                      className="w-100"
                      label={"ادخل رقم الجوال"}
                    />
                  </div>
                </div> */}
              </div>
            </div>
          </fieldset>
          <fieldset className="showProjectBorder w-90 mx-auto pb-3 ">
            <legend className="text-center text-mainText">
              
              معلومات الدفع
            </legend>
            <div className="d-flex w-90 m-auto justify-content-between">
              <p className="projectdetails text-mainText w-100">
                المبلغ الاجمالي :
                <span className="text-mainText">{selectedRequest?.amount}</span>
              </p>
            </div>
            <div className="d-flex w-90 m-auto justify-content-between">
              <p className="projectdetails text-mainText w-50">
                عدد الدفعات:
                <span className="text-mainText">
                  {selectedRequest?.batchCount}
                </span>
              </p>

              <p className="projectdetails text-mainText w-50 ">
                المبلغ المتبقي :
                <span className="text-mainText">
                  {selectedRequest?.remainingAmount}
                </span>
              </p>
            </div>
            <div className="d-flex w-90 m-auto justify-content-between">
              <p className="projectdetails text-mainText w-50">
                عدد الدفعات المتبقي :
                <span className="text-mainText">
                  {selectedRequest?.remainingBatch}
                </span>
              </p>
            </div>
          </fieldset>

          <fieldset className="showProjectBorder w-90 mx-auto pb-3 ">
            <legend className="text-center text-mainText"> المرفقات </legend>
            <div className="d-flex w-90 m-auto justify-content-between">
              <div className="row">
                <div className="col-md-12">
                  <div className="w-100 form-container">
                    <Input
                      {...projectDescription.bind}
                      placeholder="اكتب الوصف"
                      className="w-[635px]"
                      label={"1-الوصف"}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="w-100 form-container mb-3">
                    <Input
                      {...projectQuantity.bind}
                      placeholder=" الكميه"
                      className="w-100"
                      label={"الكمية"}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="w-100 form-container mb-3">
                    <Input
                      {...projectValue.bind}
                      placeholder=" القيمة"
                      className="w-100"
                      label={"القيمة"}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="w-100 form-container mb-3">
                    <Input
                      {...vat.bind}
                      placeholder="  ق.م"
                      className="w-100"
                      label={"ادخل ال ق.م"}
                      discount={true}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="w-100 form-container mb-3">
                    <Input
                      {...amountVat.bind}
                      placeholder=" ض .ق.م"
                      className="w-100"
                      label={"ادخل ال ض.ق.م"}
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="w-100 form-container mb-3">
                    <Input
                      {...textAmount.bind}
                      placeholder=" اجمالي المبلغ كتابة"
                      className="w-[637px]"
                      label={" اجمالي المبلغ كتابة"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </fieldset>
          <fieldset className="showProjectBorder w-90 mx-auto p-2 flex flex-row gap-2 ">
            <legend className="text-center text-mainText">
              
              الملف المرفق
            </legend>
            <AddAttachment
              attachment={attachment}
              setAttachment={setAttachment}
              multi={true}
            />
            {attachment && <ShowLocalFiles files={Array.from(attachment)} />}
          </fieldset>
          <div className="d-flex my-5 w-90  justify-content-end">
            <Button
              onClick={() => {
                // setOpenAddAccountant(false);
                handleAdd();
              }}
              className="sumbmitAddUpdateUser"
            >
              حفظ
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default AddInvoice;
