import React from "react";

const NoFiles = () => {
  return (
    <div className="flex justify-center gap-3 w-full">
      <p className="text-2xl text-[#D59921]"> لا يوجد ملفات</p>
    </div>
  );
};

export default NoFiles;
