const specializationOptions = [
  {
    label: "تصميم",
    value: 1,
  },
  {
    label: "اشراف على التنفيذ",
    value: 2,
  },
];
const genderOptions = [
  {
    label: "ذكر",
    value: 1,
  },
  {
    label: "انثي",
    value: 2,
  },
];
const countryOption = [
  {
    value: "السعودية",
    label: "السعودية",
  },
  {
    value: "مصر",
    label: "مصر",
  },
];
const departmentOption = [
  {
    value: "مدنى",
    label: "مدنى",
  },
  {
    value: "معمارى",
    label: "معمارى",
  },
  {
    value: "كهرباء",
    label: "كهرباء",
  },
  {
    value: "ميكانيكا",
    label: "ميكانيكا",
  },
  {
    value: "برمجة",
    label: "برمجة",
  },
  {
    value: "IT",
    label: "IT",
  },
  {
    value: "موارد بشرية",
    label: "موارد بشرية",
  },
  {
    value: "أدارى",
    label: "أدارى",
  },
];
const roleOption = [
  {
    value: "موظف",
    label: "موظف",
  },
  // {
  //   value: "مدير",
  //   label: "مدير",
  // },
  {
    value: "مدقق",
    label: "مدقق",
  },
  {
    value: "مدير المكتب",
    label: "مدير المكتب",
  },
  {
    value: "مدير قسم",
    label: "مدير قسم",
  },
  {
    value: "محاسب",
    label: "محاسب",
  },
  {
    value: "مواردبشرية",
    label: "مواردبشرية",
  },
  {
    value: "أدارى",
    label: "أدارى",
  },
];

const levelOption = [
  {
    value: "مبتدئ",
    label: "مبتدئ",
  },
  {
    value: "متوسط",
    label: "متوسط",
  },
  {
    value: "خبير",
    label: "خبير",
  },
];

export {
  specializationOptions,
  genderOptions,
  countryOption,
  departmentOption,
  roleOption,
  levelOption,
};
