import { useEffect, useRef, useState } from "react";

import { toBlob } from "html-to-image";
import moment from "moment";
import PdfPreview from "../../../../Components/Preview/CustomPdfPreview";
import PdfDocument from "../../../Modules/Reports/components/PdfDocument";
import SectionElement from "../../../../Components/Preview/components/SectionElement";
import { statusEnum } from "../../../../helper/utils";

const PrintServiceFile = ({ show, onHide, data = {} }) => {
  const refs = useRef([]);
  const [imageUrls, setImageUrls] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (show) {
      const captureImages = async () => {
        setLoading(true);
        const blobs = await Promise.all(
          refs.current.map((ref) => toBlob(ref, { pixelRatio: 2 }))
        );
        const urls = blobs.map((blob) => URL.createObjectURL(blob));
        setImageUrls(urls);
        setLoading(false);
      };

      captureImages();
    }
  }, [show]);
  useEffect(() => {
    // setTransformedData(SliceData(10, 15, data?.projectsInfo));
  }, [data]);
  console.log("PrintServiceFile", data);
  return (
    <>
      <PdfPreview show={show} onHide={onHide}>
        {imageUrls.length > 0 && (
          <PdfPreview.DownloadButton
            document={<PdfDocument imageUrls={imageUrls} />}
            loading={loading}
          />
        )}

        <div ref={(el) => (refs.current[0] = el)} className="rounded-t w-full">
          <PdfPreview.Header
            titleAr={"طلب خدمة"}
            titleEn={"Service"}
            createdAt={`تاريخ الانشاء : ${moment(data?.createdAt).format(
              "YYYY-MM-DD"
            )}`}
          />
          <PdfPreview.Body>
            <div className="flex justify-between items-center">
              <PdfPreview.SectionTitle title={"تعريفات"} />
              <SectionElement
                title="كود الموظف"
                disc={data?.employId?.employeCode}
                className="w-1/2 gap-0 px-2"
              />
            </div>

            <PdfPreview.SectionWrapper>
              <SectionElement
                title="رئيس مجلس الإدارة"
                disc={"بدر عبد المحسن بن سليمان"}

              />

              <SectionElement
                title="التعريف الضريبى"
                disc={"مكتب بدر عبد المحسن بن سليمان لاستشارات الهندسية"}

              />
              <SectionElement title="الرقم الضريبى" disc={"300195565100003"} />
              <SectionElement title="العنوان" disc={"الرياض حي النخيل"} />
            </PdfPreview.SectionWrapper>

            <div className="">
              <PdfPreview.SectionTitle title={"بيانات الموظف "} />
              <PdfPreview.SectionWrapper>
              <SectionElement title="انشأت بواسطة" disc={`${data?.employId?.firstName} ${data?.employId?.lastName}`} />
              <SectionElement title="الصلاحية" disc={data?.employId?.role} />
              <SectionElement title="فرع العمل" disc={data?.employId?.country} />
              <SectionElement title="قسم" disc={data?.employId?.department} />
              </PdfPreview.SectionWrapper>
            </div>
            <div className="">
              <PdfPreview.SectionTitle title={"بيانات الخدمة "} />
              <PdfPreview.SectionWrapper>
              <SectionElement title="الحالة" disc={statusEnum[+data?.status]?.title} />
              <SectionElement title="بند الخدمة" disc={data?.humanServicesId?.name} />
                <SectionElement
                  title="تاريخ الخدمة"
                  disc={moment(data?.invoiceDate).format("DD-MM-YYYY")}
                />
              </PdfPreview.SectionWrapper>
            </div>
            <div className="">
              <PdfPreview.SectionTitle title={"موجهة الى "} />
              <PdfPreview.SectionWrapper>
                <SectionElement
                  title="المسئول عن الموافقة"
                  disc={`${data?.approvalManger?.firstName} ${data?.approvalManger?.lastName}`}
                />
                <SectionElement
                  title="كود المسئول"
                  disc={data?.approvalManger?.employeCode}
                />
                <SectionElement
                  title="الصلاحية"
                  disc={data?.approvalManger?.role}
                />
                <SectionElement
                  title="قسم"
                  disc={data?.approvalManger?.department}
                />
                <SectionElement
                  title="فرع العمل"
                  disc={data?.approvalManger?.country}
                />
              </PdfPreview.SectionWrapper>
            </div>
          </PdfPreview.Body>
        </div>
      </PdfPreview>
    </>
  );
};

export default PrintServiceFile;
