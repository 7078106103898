import React from "react";

const ModuleCard = ({ icon, title }) => {
  return (
    <div className="system-card border !border-transparent hover:!border-[#EFAA20]">
      <div className="card__content d-flex justify-content-center align-items-center  flex-column  ">
        {icon}
        <p className="text-mainText">{title}</p>
      </div>
    </div>
  );
};

export default ModuleCard;
