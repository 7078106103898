import style from "./ExpensesDetails.module.css";
import "./ExpensesDetails.css";
import { useState } from "react";
import { Image, Modal, Spinner } from "react-bootstrap";
import Calendar from "react-calendar";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import ShowExpensesDetails from "../../../../../Components/System/Accountaing/showExpensesDetials/ShowExpensesDetials";
import { useContext } from "react";
import { addAccountType } from "../../../../../Context/AddAccountaing";
import AddItems from "../../../../../Components/System/Accountaing/AddItems/AddItems";
import { TableCell } from "../../../../../Components/Table/TableCell.jsx";
import { TableRow } from "../../../../../Components/Table/TableRow.jsx";
import CustomTable from "../../../../../Components/Table/index.jsx";
import { useGetAllExpensesItems } from "../../../../../hooks/fetchers/Expenses-items.js";
import moment from "moment";
import BillIcon from "../../../../../assets/Bill_icon.jsx";
import config from "../../../../../Config/Config.jsx";
import Cookies from "js-cookie";
import { Document, Page, pdfjs } from "react-pdf";
import LoadingPage from "../../../../../Components/feedback/LoadingPage.jsx";
import { SearchComponent } from "../../../../../Components/SearchComponent/SearchComponent.jsx";
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.js",
  import.meta.url
).toString();
const ExpensesDetails = () => {
  // handle search by Date
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");

  const { data, isLoading, isError } = useGetAllExpensesItems({ search });
  const [chooseDate, setChooseDate] = useState(false);
  const [cleanValue, setCleanValue] = useState(new Date());
  const [openBill, setOpenBill] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedItemId, setSelectedItemId] = useState(null);

  const handleChange = (event, value) => {
    setPage(value);
  };
  const handleSearch = (val) => {
    setSearch(val);
  };
  const onViewClick = (item) => {
    setOpenDisplayDetails(true);
    setSelectedItem(item);
    setSelectedItemId(item?._id);
  };

  const handleCleanValue = (e) => {
    setCleanValue(e);
    setChooseDate(false);
  };

  // handleIncomingData
  const { ExpensesType } = useParams();

  const columns = [
    {
      name: "كود الصنف",
      selector: (row) => row.id,
    },
    {
      name: " اسم التقرير ",
      selector: (row) => row.ReportName,
    },
    {
      name: "   تاريخ الاستحقاق  ",
      selector: (row) => row.DueDate,
    },
    {
      name: "  عرض  ",
      selector: (row) => row.display,
    },

    {
      name:
        ExpensesType === "ExpensesReports"
          ? "تقارير المصروفات"
          : "تقارير الاصناف",
      selector: (row) => row.ExpensesReports,
    },
  ];

  // handle Context

  const {
    accountaingType,
    setAccountaingType,
    openAddAccountant,
    setOpenAddAccountant,
    openDisplayDetails,
    setOpenDisplayDetails,
  } = useContext(addAccountType);

  useEffect(() => {
    setOpenDisplayDetails(false);
    setOpenAddAccountant(false);
    setAccountaingType(ExpensesType);
  }, [ExpensesType]);

  const [pdfLoading, setPdfLoading] = useState(false);
  const [pdfUrl, setPdfUrl] = useState("");

  const getFinancialPdf = async (id) => {
    setPdfLoading(true);
    try {
      const response = await fetch(
        `${config.apiGateway?.URL}/batches/pdf/${id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/pdf",
            authes: `BSA__${Cookies.get("accessToken")}`,
          },
        }
      );
      if (response.ok) {
        const blob = await response.blob();
        const url = URL.createObjectURL(blob);
        setPdfUrl(url);
      } else {
        console.error("Failed to fetch PDF:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching PDF:", error);
    } finally {
      setPdfLoading(false);
    }
  };

  return (
    <div className="ExpensesDetails">
      {pdfLoading ? (
        <LoadingPage />
      ) : (
        pdfUrl && (
          <Modal
            show={true}
            onHide={() => setPdfUrl("")}
            className="h-screen bg-black/50"
            contentClassName="bg-transparent"
          >
            <Modal.Body className="h-full w-full min-w-min">
              <a
                href={pdfUrl}
                download={"file.pdf"}
                className="mb-3 mx-auto block text-center !text-[#efaa20] hover:!text-[#efaa20] border !border-[#efaa20] w-fit px-4 py-1 rounded-full bg-mainNavy"
              >
                تحميل المستند
              </a>
              <Document
                file={pdfUrl}
                onLoadSuccess={() => setPdfLoading(false)}
                className={"w-full min-w-min"}
              >
                <Page pageNumber={1} className={"w-full"} />
              </Document>
            </Modal.Body>
          </Modal>
        )
      )}

      {
        // openAddAccountant && accountaingType === "ExpensesReports" ? (
        //   <AddExpensesReports />
        // ) :
        openAddAccountant ? (
          <AddItems />
        ) : (
          <>
            {openBill && (
              <Modal
                className="d-flex claimModal align-items-center jusify-content-center"
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                onHide={() => setOpenBill(false)}
                show={openBill}
              >
                <Modal.Body className="d-flex align-items-center">
                  <Image
                    src={`${process.env.PUBLIC_URL + "/expenses.png"}`}
                    alt="FinancalRequest png"
                    width={650}
                    height={700}
                  />
                </Modal.Body>
              </Modal>
            )}
            {openDisplayDetails ? (
              <ShowExpensesDetails
                data={selectedItem}
                setOpenDisplayDetails={setOpenDisplayDetails}
                id={selectedItemId}

                // setData={setData}
              />
            ) : (
              <>
                {chooseDate && (
                  <Modal
                    className=" InvoiceDate"
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    onHide={() => setChooseDate(false)}
                    show={chooseDate}
                  >
                    <Modal.Body className="d-flex align-items-center">
                      <Calendar
                        onChange={handleCleanValue}
                        className={"bg-mainItems"}
                        value={cleanValue}
                      />
                    </Modal.Body>
                  </Modal>
                )}
                <div
                  className={`w-100 ${style.ExpensesNumbersContainer} mb-4 P-4`}
                >
                  <div className=" d-flex  gap-3">
                    {/* <svg
                    onClick={() => {
                      setChooseDate(true);
                    }}
                    className="pointer"
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="16"
                    viewBox="0 0 18 16"
                    fill="none"
                  >
                    <path
                      d="M17.4375 0.438965H0.4375L7.2375 8.21314V13.5877L10.6375 15.2313V8.21314L17.4375 0.438965Z"
                      fill="#D59921"
                    />
                  </svg> */}
                    {/* {ExpensesType === "ExpensesReports" ? (
                      <p className="text-mainText "> اجمالى عدد المصروفات : </p>
                    ) : ( */}
                    <p className="text-mainText ">اجمالي الاصناف :</p>
                    {/* )} */}
                  </div>
                  <div className="Treasury-container-numbers d-flex justify-content-center text-mainText">
                    <p>{data?.data?.count}</p>
                  </div>
                </div>
                <fieldset className={`${style.ExpensesDataTableContainer}`}>
                  {/* {ExpensesType === "ExpensesReports" ? (
                    <legend className="text-center text-mainText">
                      تقارير المصروفات
                    </legend>
                  ) : ( */}
                  <legend className="text-center text-mainText">الاصناف</legend>
                  {/* )} */}

                  <div className="mt-3 !h-[400px] overflow-auto scrollbar-none flex flex-col gap-3">
                    <div className="w-full md:w-3/4 lg:w-1/2 px-2 mx-auto">
                      <SearchComponent
                        handleChange={handleSearch}
                        value={search}
                        className={""}
                        border="border !border-[#E4A11B]"
                      />
                    </div>
                    <CustomTable
                      columns={columns}
                      data={data?.data?.batches}
                      paginationProps={{
                        count: data?.pages,
                        page: page,
                        onChange: handleChange,
                      }}
                    >
                      {isLoading
                        ? null
                        : data?.data?.batches && data?.data?.batches?.length > 0
                        ? data?.data?.batches?.map(
                            (item, index) =>
                              !item.isisDeleted && (
                                <TableRow
                                  className={`my-2 border !border-[#efaa207f] ${
                                    index % 2 === 0 ? "bg-mainNavy" : ""
                                  }`}
                                  key={index}
                                >
                                  <TableCell>{item?.codeBatche}</TableCell>
                                  <TableCell>{item?.name}</TableCell>
                                  {/* <TableCell>{item?.clause}</TableCell> */}
                                  <TableCell>
                                    {moment(item.dateCreated).format(
                                      "YYYY-MM-DD"
                                    )}
                                  </TableCell>
                                  <TableCell>
                                    <div
                                      className=""
                                      onClick={() => onViewClick(item)}
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/icons/view.png"
                                        }
                                        className="pointer"
                                        alt="view icon"
                                      />
                                    </div>
                                  </TableCell>
                                  <TableCell>
                                    <div
                                      onClick={() => getFinancialPdf(item._id)}
                                      // onClick={() => {
                                      //   setOpenBill(true);
                                      // }}
                                      className="pointer d-flex justfiy-content-center w-100"
                                    >
                                      <div className="p-1 rounded-md mx-3">
                                        <BillIcon fill={"#efaa20"} />
                                      </div>
                                    </div>
                                  </TableCell>
                                </TableRow>
                              )
                          )
                        : null}
                    </CustomTable>
                    {isLoading && (
                      <div className="w-full h-full flex justify-center">
                        <Spinner className="relative top-1/3 text-[#EFAA20]" />
                      </div>
                    )}
                    {isError && (
                      <div className="w-full h-full flex justify-center">
                        <p className="relative top-1/3 text-red-500 text-xl">
                          يوجد خطأ ما
                        </p>
                      </div>
                    )}
                  </div>
                </fieldset>
              </>
            )}
          </>
        )
      }
    </div>
  );
};

export default ExpensesDetails;
