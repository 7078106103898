import { Fragment, useEffect, useState } from "react";

import PieChart from "../../../../Components/pieChart";
import { useCallback } from "react";
import { getAllProjectsStatus } from "../../../../helper/fetchers/Projects";
import Progress from "../../../../Components/Progress";
import MonthlyReport from "../components/MonthlyReport";
const AllProjectsChart = () => {
  const [projectStats, setProjectStats] = useState(null);
  const [loading, setLoading] = useState(false);
  
  const getProjectStats = useCallback(async () => {
    setLoading(true);
    const res = await getAllProjectsStatus();
    if (res?.status === 200) {
      setProjectStats(res?.data);
      // console.log(res?.data);
    }
    setLoading(false);
  }, []);
  useEffect(() => {
    getProjectStats();
  }, [getProjectStats]);


  return (
    <div className="p-4 d-flex gap-5 AllRequests  align-items-center flex-column">
      {loading ? (
        <Progress />
      ) : (
        <Fragment>
          {projectStats && (
            <>
              <div className="AllRequestsPieChartContainer w-100  d-flex align-items-center justify-content-center">
                <PieChart
                  total={projectStats?.total}
                  label="كل المشاريع"
                  colors={["#EFAA20", "#E40038", "#03795D"]}
                  width={500}
                  labels={[
                    `قيد التنفيذ ${parseInt(
                      projectStats?.totalStatusCounts?.IN_PROGRESS
                    )} `,
                    `معلقة ${parseInt(
                      projectStats?.totalStatusCounts?.PENDING
                    )} `,
                    `منتهية ${parseInt(
                      projectStats?.totalStatusCounts?.COMPLETED
                    )}`,
                  ]}
                  series={[
                    parseInt(projectStats?.totalStatusCounts?.IN_PROGRESS),
                    parseInt(projectStats?.totalStatusCounts?.PENDING),
                    parseInt(projectStats?.totalStatusCounts?.COMPLETED),
                  ]}
                />
              </div>
              <MonthlyReport />
            </>
          )}
        </Fragment>
      )}
    </div>
  );
};



export default AllProjectsChart;
