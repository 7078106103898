import { twMerge } from "tailwind-merge";

export const FormTitle = ({ title , className }) => {
  return (
    <legend className={twMerge("text-mainText text-base font-medium mx-auto text-center",className)}>
      {title}
    </legend>
  );
};

