import React, { createContext, useContext, useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { Spinner } from "react-bootstrap";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import {
  useGetUserStatisticsDepartmentsReports,
  useGetUserStatisticsDepartmentsReportsEgy,
  useGetUserStatisticsDepartmentsReportsSaudi,
} from "../../../../../../hooks/fetchers/Users";
import DownloadButton from "../../../../../../Components/Buttons/DownloadButton";
import CustomChart from "../../../../../System/Main/components/charts/CustomChart";
import ExcellModall from "../../../../../../Components/Modals/ExcellModall";
import { TiExport } from "react-icons/ti";
import { ImPrinter } from "react-icons/im";
import useModal from "../../../../../../hooks/useModal";
import useExportExcel from "../../../../../../hooks/useExportExcel";

const UserReportsDepartments = () => {
  const { loading, allKeys, excelData, fileUrl, handleExport, resetExcelData } =
  useExportExcel();
  
const {
  open: openPdf,
  handleOpenModal: handleOpenPdfModal,
  handleCloseModal: handleClosePdfModal,
} = useModal();
  return (
    <div className="p-2">
      {!loading && excelData && (
        <ExcellModall
          onHide={resetExcelData}
          fileUrl={fileUrl}
          keys={allKeys}
          data={excelData}
        />
      )}
      <div className="flex justify-end items-center p-3">
      <div className="flex items-center gap-2">
              <DownloadButton
                onClick={() => handleExport(`system-reports/users/excel`)}
              >
                <div className="flex items-center gap-1">
                  <span>تصدير Excel</span>
                  <TiExport />
                </div>
              </DownloadButton>
              {/* <DownloadButton onClick={handleOpenPdfModal}>
                <div className="flex items-center gap-1">
                  <span>طباعة</span>
                  <ImPrinter />
                </div>
              </DownloadButton> */}
            </div>
      </div>
      <TabProvider>
        <Tabs>
          <Tab label="مصر" />
          <Tab label="كل المستخدمين" />
          <Tab label="السعودية" />
        </Tabs>
        <TabPanels>
          <TabPanel index={0}>
            <AllStatisticsEgy />
          </TabPanel>
          <TabPanel index={1}>
            <AllStatistics />
          </TabPanel>
          <TabPanel index={2}>
            <AllStatisticsSaudi />
          </TabPanel>
        </TabPanels>
      </TabProvider>
    </div>
  );
};

export default UserReportsDepartments;

const AllStatistics = () => {
  const { data, isLoading, isError } = useGetUserStatisticsDepartmentsReports();
  console.log("data", data);

  return (
    <div>
      {isLoading ? <Spinner /> : null}
      {isError ? <p className="text-red-400 text-lg">يوجد خطا ما</p> : null}
      {!isLoading && !isError ? (
        <CustomChart
          chartTitle={"عدد الاقسام"}
          mainColTitle="القسم"
          showPercentage={false}
          labelClass={"min-w-fit mt-2"}
          data={data?.transformedData}
        />
      ) : null}
    </div>
  );
};
const AllStatisticsEgy = () => {
  const { data, isLoading, isError } =
    useGetUserStatisticsDepartmentsReportsEgy();
  return (
    <div>
      {isLoading ? <Spinner /> : null}
      {isError ? <p className="text-red-400 text-lg">يوجد خطا ما</p> : null}
      {!isLoading && !isError ? (
        <CustomChart
          chartTitle={"عدد الاقسام"}
          mainColTitle="القسم"
          showPercentage={false}
          labelClass={"min-w-fit mt-2"}
          data={data?.transformedData}
        />
      ) : null}
    </div>
  );
};
const AllStatisticsSaudi = () => {
  const { data, isLoading, isError } =
    useGetUserStatisticsDepartmentsReportsSaudi();
  return (
    <div>
      {isLoading ? <Spinner /> : null}
      {isError ? <p className="text-red-400 text-lg">يوجد خطا ما</p> : null}
      {!isLoading && !isError ? (
        <CustomChart
          chartTitle={"عدد الاقسام"}
          mainColTitle="القسم"
          showPercentage={false}
          labelClass={"min-w-fit mt-2"}
          data={data?.transformedData}
        />
      ) : null}
    </div>
  );
};

const TabContext = createContext(null);
const TabProvider = ({ children }) => {
  const [tabIndex, setTabIndex] = useState(1);
  const [tabsLength, setTabsLength] = useState(null);
  const handleNextStep = () => {
    setTabIndex((prev) => {
      if (prev >= 2) return 2;
      return prev + 1;
    });
  };
  const handlePrevStep = () => {
    setTabIndex((prev) => {
      if (prev <= 0) return 0;
      return prev - 1;
    });
  };
  console.log("tabIndex", tabIndex);
  return (
    <TabContext.Provider
      value={{
        tabIndex,
        handleNextStep,
        handlePrevStep,
        tabsLength,
        setTabsLength,
      }}
    >
      {children}
    </TabContext.Provider>
  );
};

const useTabCtx = () => {
  const ctx = useContext(TabContext);
  if (!ctx) {
    throw Error("Use inside context ");
  }
  return ctx;
};

const Tabs = ({ children }) => {
  const {
    tabIndex,
    handleNextStep,
    handlePrevStep,
    tabsLength,
    setTabsLength,
  } = useTabCtx();
  useEffect(() => {
    setTabsLength(children?.length);
  }, []);
  console.log("tabsLength", tabsLength);
  return (
    <div className="flex justify-center items-center gap-4" dir="ltr">
      {tabIndex !== 0 ? (
        <button className="" onClick={handlePrevStep}>
          <IoIosArrowBack size={25} color="#EFAA20" />
        </button>
      ) : null}

      {children[tabIndex]}

      {tabIndex < tabsLength - 1 ? (
        <button className="" onClick={handleNextStep}>
          <IoIosArrowForward size={25} color="#EFAA20" />
        </button>
      ) : null}
    </div>
  );
};
const Tab = ({ label = "" }) => {
  return <p> {label}</p>;
};
const TabPanels = ({ children }) => {
  const { tabIndex, handleNextStep, handlePrevStep } = useTabCtx();
  // const selectedChild = Children.map((child,index)=> )
  console.log("children", children[tabIndex]);
  return <>{children[tabIndex]}</>;
};
const TabPanel = ({ children, index }) => {
  const { tabIndex, handleNextStep, handlePrevStep } = useTabCtx();
  return <div>{children}</div>;
};

const CountryPieChart = ({ data }) => {
  const labels = data?.map((item) => item?.label);
  const values = data?.map((item) => item?.value);
  useEffect(() => {
    const text = document.querySelectorAll(
      "text.apexcharts-text.apexcharts-datalabel-value"
    );
    text[0].setAttribute("fill", "var(--main-text)");
  }, []);

  const options = {
    annotations: {
      points: [
        {
          x: "50%",
          y: "50%",
          label: {
            text: "Custom Text",
            style: {
              fontSize: "16px",
              color: "#FF5733",
            },
          },
        },
      ],
    },
    plotOptions: {
      pie: {
        donut: {
          size: "80%",
          labels: {
            show: true,
            total: {
              show: true,
              label: " المستخدمين",
              color: "var(--main-text)",
              formatter: () => {
                return data?.total;
              },
            },
          },
        },
      },
    },
    chart: {
      width: 380,
      type: "donut",
    },
    labels: [...labels],
    stroke: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    colors: ["#03795D", "#EFAA20", "#FD6074"],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            show: false,
          },
        },
      },
    ],
    legend: {
      position: "left",
      offsetY: 0,
      height: 200,
    },
  };

  const series = [...values];
  // console.log("labels:", labels);
  // console.log("series:", series);
  return (
    <div className="countryPiechart my-4">
      <div className="chart-wrap">
        <div id="chart">
          <ReactApexChart
            options={options}
            series={series}
            type="donut"
            width={"400px"}
          />
        </div>
      </div>
    </div>
  );
};
