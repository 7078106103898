import { Controller, useForm } from "react-hook-form";
import { MentionMenu } from "./MentionMenu";
import { useState } from "react";
import sendArrow from "../../../assets/icons/send-arrow.png";
import {
  useAddComment,
  useGetMentions,
  useReplyComment,
} from "../hooks/services";
import { Spinner } from "react-bootstrap";
import { useCommentCtx } from "./CommentCtx";
export const CommentForm = ({ commentId = null, onSave }) => {
  const { task } = useCommentCtx();
  const { mutate: mutateComment } = useAddComment(
    () => {},
    () => {},
    task._id
  );
  const { mutate: mutateReply } = useReplyComment(onSave, () => {}, commentId);
  const { data, isLoading, isError } = useGetMentions(task._id);
  const { handleSubmit, control, setValue, watch } = useForm();

  const [showMentions, setShowMentions] = useState(false);
  const [mentionSuggestions, setMentionSuggestions] = useState([]);
  const onSubmit = (data) => {
    const fieldToUpdate = commentId ? "replayText" : "commentText";
    if (commentId) {
      mutateReply(data);
    } else {
      mutateComment(data);
    }
    setValue(fieldToUpdate, "");
  };

  const handleInputChange = (e) => {
    const fieldToUpdate = commentId ? "replayText" : "commentText";
    const value = e.target.value;
    setValue(fieldToUpdate, value);
    
    
    
    if (value.includes("@")) {
      const mentionText = value.split('@')[1]
      console.log("mentioned",mentionText);
      const filteredSuggestions = data?.data?.users.filter(user =>
        user.firstName.toLowerCase().includes(mentionText.toLowerCase())
      );
      setMentionSuggestions(filteredSuggestions);
      setShowMentions(true);
    } else {
      setShowMentions(false);
    }
  };

  const handleSelectMention = (mention) => {
    const fieldToUpdate = commentId ? "replayText" : "commentText";
    const currentText = watch(fieldToUpdate);
    const regex = /@\w*/;
    const highlightedMention = `${mention.firstName} ${mention.lastName}`;
    const updatedText = currentText.replace(regex, highlightedMention);
    setValue(fieldToUpdate, updatedText);
    setShowMentions(false);
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex justify-between items-center relative"
    >
      <Controller
        name={commentId ? "replayText" : "commentText"}
        control={control}
        render={({ field: { name, onBlur, onChange, value = "" } }) => (
          <input
            type="text"
            name={name}
            onBlur={onBlur}
            value={value}
            onChange={(e) => {
              onChange(e);
              handleInputChange(e);
            }}
            placeholder={commentId ? "اكتب ردك هنا..." : "اكتب رسالتك هنا..."}
            className="w-full bg-transparent outline-none p-1"
          />
        )}
      />

      <button type="submit">
        <img src={sendArrow} alt="send" className="w-5 send-arrow-form " />
      </button>

      {showMentions && (
        <div className="absolute top-full inset-x-0 ">
          {isLoading && <Spinner animation="border" />}
          {!isLoading && !isError ? (
            <MentionMenu
              mentionSuggestions={mentionSuggestions}
              handleSelectMention={handleSelectMention}
            />
          ) : null}
        </div>
      )}
    </form>
  );
};
