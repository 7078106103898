import React, { useContext, useState } from "react";
import Section from "../../../../Components/System/ShowRequest/components/Section";
import DescGroup from "../../../../Components/System/ShowRequest/components/DescGroup";
import { FormModal } from "../../PlanModel/components/FormModal";
import ShowFiles from "../../../../Components/ShowFiles";
import { TechnicalSupportContext } from "./TechnicalSupportProvider";
import { OptionsButton } from "../../Settings/OptionsButton";
import SuccessfullModal from "../../../../Components/Modals/SuccessfullModal";
import useSuccessfullModal from "../../../../Components/Modals/hooks/useSuccessfullModal";
import RemoveModal from "../../Settings/RemoveModal";
import {
  useAcceptPurchase,
  useDeleteOrderPurchase,
  useGetPurchasesById,
  useRefusePurchase,
  useUpdatePricePurchase,
  useUpdatePurchaseComplete,
} from "../../../../hooks/fetchers/TechnicalSupportPurcheseOrder";
import { useParams } from "react-router-dom";
import { FaCheck } from "react-icons/fa";
import CustomModal from "../../../../Components/Modals/CustomModal";
import RejectModel from "../../../../Components/Modals/RejectModel";
import AcceptIngoingForm from "./AcceptIngoingForm";
import NewOptionsButton from "./NewOptionsButton";
import DonePaidForm from "./DonePaidForm";
import OrderChip from "./OrderChip";
import LoadingPage from "../../../../Components/feedback/LoadingPage";
import { getAllPurchaseById } from "../../../../helper/fetchers/TechnicalSupport";
import { CheckRole, CheckUserId } from "../../../../helper/utils";

const TechnicalSupportShowOrder = () => {
  const {
    order,
    setAddOrdersOpen,
    setUpdatedItem,
    updatedItem,
    setShowOrdersOpen,
  } = useContext(TechnicalSupportContext);
  const handleShow = () => {
    setAddOrdersOpen(true);
    setUpdatedItem(order);
  };
  const {
    showSuccess,
    showError,
    handleShowSuccess,
    handleShowError,
    handleCloseSuccess,
    handleCloseError,
  } = useSuccessfullModal();
  const [showDelete, setShowDelete] = useState(false);
  const [showAccept, setShowAccept] = useState(false);
  const [EditPrice, setEditPrice] = useState(false);
  const [showReject, setShowReject] = useState(false);
  const [showDonePaid, setShowDonePaid] = useState(false);
  const [showRejectComment, setShowRejectComment] = useState(false);
  const [showDirectAccept, setShowDirectAccept] = useState(false);
  const [rejectSuccess, setRejectSuccess] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [completeSuccess, setCompleteSuccess] = useState(false);

  const reset = () => {
    setShowDelete(false);
    setShowAccept(false);
    setEditPrice(false);
    setShowReject(false);
    setShowDonePaid(false);
    setShowRejectComment(false);
    setShowDirectAccept(false);
    setRejectSuccess(false);
    setDeleteSuccess(false);
    setCompleteSuccess(false);
  };
  const handleDelete = () => {
    setShowDelete(true);
  };
  // console.log(order, "updatedItem");

  const { type, status } = useParams();

  const { data: itemData, isLoading: isItemLOading } = useGetPurchasesById(
    order?._id
  );
  const [err, setError] = useState("");

  const { mutate: deleteMutation, isLoading: isDeleteLoading } =
    useDeleteOrderPurchase(
      status,
      () => setDeleteSuccess(true),
      (error) => {
        handleShowError();
        setError(
          error?.response?.data?.validationErrors ||
            error?.response?.data?.message
        );
      }
    );

  const [showAcceptSuccess, setShowAcceptSuccess] = useState(false);

  const { mutate: acceptMutate, isLoading: acceptLoading } = useAcceptPurchase(
    itemData?._id,
    handleShowSuccess,
    (error) => {
      handleShowError();
      setError(
        error?.response?.data?.validationErrors ||
          error?.response?.data?.message
      );
    }
  );

  const { mutate: CompleteMutate, isLoading: CompleteLoading } =
    useUpdatePurchaseComplete(
      itemData?._id,
      () => setCompleteSuccess(true),
      (error) => {
        handleShowError();
        setError(
          error?.response?.data?.validationErrors ||
            error?.response?.data?.message
        );
      }
    );

  const { mutate: updatePriceMutate, isLoading: updatePriceLoading } =
    useUpdatePricePurchase(
      itemData?._id,
      status,
      () => setShowAcceptSuccess(true),
      (error) => {
        handleShowError();
        setError(
          error?.response?.data?.validationErrors ||
            error?.response?.data?.message
        );
      }
    );

  const { mutate: rejectMutate, isLoading: rejectLoading } = useRefusePurchase(
    status,
    () => setRejectSuccess(true),
    (error) => {
      handleShowError();
      setError(
        error?.response?.data?.validationErrors ||
          error?.response?.data?.message
      );
    }
  );

  return isDeleteLoading ||
    acceptLoading ||
    rejectLoading ||
    isItemLOading ||
    updatePriceLoading ||
    CompleteLoading ? (
    <LoadingPage />
  ) : (
    <div className="bg-mainItems h-[112vh] overflow-auto py-4">
      <RemoveModal
        title={"التاكيد"}
        show={showDelete}
        handleClose={() => setShowDelete(false)}
        onSave={() => {
          deleteMutation(itemData?._id, {
            onError: (err) => {
              setError(
                err?.response?.data?.validationErrors ||
                  err?.response?.data?.message
              );
            },
          });
        }}
        stillOpen={true}
      />

      <DonePaidForm
        show={showDonePaid}
        handleClose={() => setShowDonePaid(false)}
        handleSave={(data) => CompleteMutate(data)}
      />

      <AcceptIngoingForm
        show={showAccept}
        type={4}
        handleClose={() => setShowAccept(false)}
        handleSave={(newData) => {
          acceptMutate(newData);
        }}
      />
      <AcceptIngoingForm
        show={EditPrice}
        type={5}
        data={itemData?.offerAttachements}
        handleClose={() => setEditPrice(false)}
        handleSave={(newData) => {
          updatePriceMutate(newData);
        }}
      />

      <RejectModel
        show={showRejectComment}
        message={"اترك سبب الرفض"}
        isOnlyComment={true}
        isRejectComment={true}
        handleClose={() => setShowRejectComment(false)}
        handleSave={(data) => rejectMutate({ taskId: itemData?._id, data })}
      />
      <CustomModal
        title={"التأكيد"}
        message={"هل انت متأكد من الرفض"}
        show={showReject}
        handleClose={() => setShowReject(false)}
        handleSave={() => {
          setShowRejectComment(true);
        }}
      />
      <CustomModal
        title={"التأكيد"}
        message={"هل انت متأكد من القبول و تحويل طلب الشراء الي قسم الحسابات"}
        show={showDirectAccept}
        handleClose={() => setShowDirectAccept(false)}
        handleSave={() => {
          acceptMutate();
        }}
      />

      <SuccessfullModal
        handleClose={() => {
          setShowDelete(false);
          setRejectSuccess(false);
          setShowOrdersOpen(false);
          reset();
        }}
        message={"تمت الرفض بنجاح"}
        show={rejectSuccess}
      />
      <SuccessfullModal
        handleClose={() => {
          setShowAcceptSuccess(false);
          handleCloseSuccess();
          reset();
        }}
        message={"تمت القبول بنجاح"}
        show={showSuccess}
      />
      <SuccessfullModal
        handleClose={() => {
          handleCloseSuccess();
          reset();
        }}
        message={"تمت العمليه بنجاح"}
        show={completeSuccess}
      />
      <SuccessfullModal
        handleClose={() => {
          setShowAcceptSuccess(false);
          handleCloseSuccess();
          reset();
        }}
        message={"تمت اضافه عرض السعر  بنجاح"}
        show={showAcceptSuccess}
      />

      <SuccessfullModal
        handleClose={() => {
          setDeleteSuccess(false);
          handleCloseSuccess();
          reset();
        }}
        message={"تمت الحذف بنجاح بنجاح"}
        show={deleteSuccess}
      />
      <SuccessfullModal
        handleClose={handleCloseError}
        message={err}
        show={showError}
        status="error"
      />
      {/* Header */}
      <div className="flex justify-between p-4 border rounded-[19px] !border-[#EFAA20]">
        <div className="flex flex-col justify-center gap-4 w-full">
          <div className="flex items-center justify-between w-full">
            <TitleText
              title={"نوع المهمه"}
              value={itemData?.offerType[0]?.name}
            />
            <TitleText title={"الحاله"} value={itemData?.statusText} />
          </div>
          <div className="w-full flex justify-between">
            <TitleText title={"رقم الطلب"} value={"لا توجد بيانات"} />
            <div className="flex gap-2 items-center justify-between">
              {itemData?.status === "0" &&
                !CheckUserId(itemData?.createdBy) &&
                !CheckRole("مدير") && (
                  <button
                    onClick={() => setShowAccept(true)}
                    className="flex items-center justify-center gap-1 bg-[#19B159] rounded-[3px] text-white text-xs w-[80px] h-[25px]"
                  >
                    قبول
                    <FaCheck />
                  </button>
                )}
              {(itemData?.status === "1" || itemData?.status === "0") &&
                CheckUserId(itemData?.createdBy) &&
                CheckRole("مدير") && (
                  <button
                    onClick={() => setShowDirectAccept(true)}
                    className="flex items-center justify-center gap-1 bg-[#19B159] rounded-[3px] text-white text-xs w-[80px] h-[25px]"
                  >
                    قبول
                    <FaCheck />
                  </button>
                )}
              {itemData?.status === "1" && !CheckRole("مدير") && (
                <button
                  onClick={() => setShowDonePaid(true)}
                  className="flex items-center justify-center gap-1 bg-[#E1A945] rounded-[3px] text-white text-xs w-[80px] h-[25px]"
                >
                  تم الشراء
                  <FaCheck />
                </button>
              )}

              <NewOptionsButton
                isAddNewOffer={false}
                isAcceptAllowed={CheckRole("مدير") && itemData?.status === "0"}
                onAddNewOffer={() => setEditPrice(true)}
                onUpdate={() => handleShow()}
                isEdit={
                  itemData?.status === "0" ||
                  (CheckRole("مدير") && itemData?.status === "1")
                }
                onAccept={() => setShowDirectAccept(true)}
                onReject={() => setShowReject(true)}
                onDelete={() => handleDelete()}
              />
            </div>
          </div>
        </div>
      </div>
      {/* Details */}
      <Section title="نوع الطلب">
        <div className="row px-2">
          <div className="col-md-12 mb-3 flex items-center gap-4">
            <p className="text-mainText  text-lg   ">نوع الطلب :</p>
            <div className="w-[85%] flex overflow-y-hidden overflow-x-auto scrollbar-thin scrollbar-track-transparent scrollbar-thumb-[#EFAA20] gap-2">
              {itemData?.offerType?.map((x) => (
                <OrderChip key={x?._id} item={x} />
              ))}
            </div>
          </div>
          <div className="col-md-12 mb-3 flex items-center gap-4">
            <p className="text-mainText  text-lg   ">اسم المنتج :</p>
            <div className="w-[85%] h-full flex overflow-y-hidden overflow-x-auto scrollbar-thin scrollbar-track-transparent scrollbar-thumb-[#EFAA20] gap-2">
              {itemData?.product?.map((x) => (
                <OrderChip key={x?._id} item={x} />
              ))}
            </div>
          </div>
        </div>
      </Section>
      <fieldset className="showProjectBorder p-2 ">
        <legend className="text-center text-mainText">الوصف المهمه</legend>

        <p className=" text-mainText">الوصف :</p>

        <textarea
          placeholder="اكتب ملاحظات"
          className="form-control my-3 w-100 m-auto"
          cols={30}
          rows={5}
          disabled
          value={itemData?.description || "لا توجد بيانات"}
        />
        <p className="text-mainText flex gap-2 text-lg  items-center ">
          المرفقات
        </p>
        {itemData?.attachements?.length > 0 ? (
          <>
            <div className="relative w-full flex gap-6 overflow-y-hidden overflow-x-auto scrollbar-thin scrollbar-track-transparent scrollbar-thumb-[#EFAA20] pb-14">
              <ShowFiles files={itemData?.attachements} />
            </div>
          </>
        ) : (
          <div className="flex justify-center gap-3">
            <p className="text-2xl text-[#D59921]"> لا يوجد مرفقات</p>
          </div>
        )}
      </fieldset>
      <FormModal title={"عرض السعر"} className="mt-8">
        {itemData?.offerAttachements?.length > 0 ? (
          <>
            <div className="relative w-full flex gap-6 overflow-y-hidden overflow-x-auto scrollbar-thin scrollbar-track-transparent scrollbar-thumb-[#EFAA20] pb-14">
              <ShowFiles files={itemData?.offerAttachements} />
            </div>
          </>
        ) : (
          <div className="flex justify-center gap-3">
            <p className="text-2xl text-[#D59921]"> لا يوجد عرض السعر</p>
          </div>
        )}
      </FormModal>
    </div>
  );
};

export default TechnicalSupportShowOrder;
const TitleText = ({ title, value, isStatus }) => {
  return (
    <p className="font-normal  text-mainText text-lg">
      {title} :
      <span
        className={
          isStatus ? "me-1 text-[#D59921]" : "me-1 text-mainCustomGray"
        }
      >
        {value}
      </span>
    </p>
  );
};
