import React, { createContext, useContext, useState } from "react";
import App from "../App";

export const RequestsContext = createContext();
const RequestsProvider = ({ children }) => {
  const [showProject, setShowProject] = useState(false);

  return (
    <RequestsContext.Provider value={{ showProject, setShowProject }}>
      {children}
    </RequestsContext.Provider>
  );
};

export const useRequestsContext = () => {
  const ctx = useContext(RequestsContext);
  if (!ctx) {
    throw Error("use inside context provider");
  }
  return ctx;
};

export default RequestsProvider;
