import { Document, Page, Image, StyleSheet } from "@react-pdf/renderer";
const styles = StyleSheet.create({
    page: {
      flexDirection: "column",
      backgroundColor: "#ffffff",
    },
    image: {
      width: "100%",
      height: "auto",
    },
  });
const PdfDocument = ({ imageUrls }) => {
    // console.log('imageUrls',imageUrls);
    return (
        <Document>
          {imageUrls.map((imageUrl, index) => (
            <Page key={index} size="A4" style={styles.page}>
              <Image src={imageUrl} style={styles.image} />
            </Page>
          ))}
        </Document>
      )
};
export default PdfDocument;
