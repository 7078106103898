import Table from "react-bootstrap/Table";

const TableElement = ({
  columns,
  data,
  children,
  TableHeaderProps,
  TableRowProps,
  TableHeadProps,
  ...props
}) => {
  return (
    <>
      <Table bordered {...props}>
        <TableHeader className="" {...TableHeaderProps}>
          <TableRow {...TableRowProps}>
            {columns?.map(({ name }, index) => (
              <TableHead
                key={index}
                className="text-end text-[11px] border-none !font-semibold p-2"
                {...TableHeadProps}
              >
                {name}
              </TableHead>
            ))}
          </TableRow>
        </TableHeader>
        <tbody>{children}</tbody>
      </Table>
      {data && data?.length === 0 ? (
        <div className="text-center">
          <p className="w-full text-center text-xl border-none min-w-[50px] p-2 text-mainCustomGray">
            لا يوجد بيانات لعرضها
          </p>
        </div>
      ) : null}
    </>
  );
};

const TableCell = ({ children, textColor = "", cellClassName, ...props }) => {
  return (
    <td
      style={{
        color: textColor,
      }}
      className={`text-[10px] border-none min-w-[50px] p-1  ${
        cellClassName ? cellClassName : ""
      }`}
      {...props}
    >
      <div className="!bg-[#F9EBD5] px-1">{children}</div>
    </td>
  );
};
const TableHead = ({ children, ...props }) => {
  return <th {...props}><div className="px-1">{children}</div></th>;
};
const TableHeader = ({ children, ...props }) => {
  return <thead {...props}>{children}</thead>;
};
const TableRow = ({ children, ...props }) => {
  return <tr {...props}>{children}</tr>;
};

TableElement.TableRow = TableRow;
TableElement.TableCell = TableCell;

export default TableElement;
