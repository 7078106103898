import React, { useState } from "react";
import { AllCategories } from "../../../../Components/System/AllCategories/AllCategories";
import { Link } from "react-router-dom";
import { Accordion } from "react-bootstrap";
import { CheckRole } from "../../../../helper/utils";
import { roles } from "../../../../helper/fetchers/Tasks";
import Cookies from "js-cookie";
import { MdKeyboardArrowDown } from "react-icons/md";
import { IoIosArrowBack } from "react-icons/io";
import AllUsersSearchDropDown from "./AllUsersSearchDropDown";

const departmentOption = [
  "مدنى",
  "معمارى",
  "كهرباء",
  "ميكانيكا",
  "IT",
  "موارد بشرية",
  "أدارى",
  "برمجة",
]

const AllUserDropdown = ({ setOpen }) => {
  const [activeCountry, setActiveCountry] = useState(null);
  const [activeChild, setActiveChild] = useState({});
  const [searchOpen, setSearchOpen] = useState(false);

  const handleCountryToggle = (country) => {
    setActiveCountry((prev) => (prev === country ? null : country));
  };

  const handleChildToggle = (country, child) => {
    setActiveChild((prev) => ({
      ...prev,
      [country]: prev[country] === child ? null : child,
    }));
  };

  return (
    <div className="absolute top-[2.8rem] right-12 z-[1000000] bg-mainNavy rounded-xl">
      <div className="flex items-start flex-col  bg-mainItems w-[300px] h-[300px]  p-2 overflow-auto shadow-2xl ">
        <div
          className="w-screen h-screen fixed inset-0  z-[-1]"
          onClick={() => {
            setSearchOpen(false);
            setOpen(false);
          }}
        />
        <AllCategories
          child={
            <div className="d-flex flex-column align-items-center w-full">
              <Link className="pointer" to={"/System/Users/index"}>
                <p className="text-center text-mainText py-2">كل المستخدمين</p>
              </Link>
              <div className="pointer w-full mt-0">
                <div className="d-flex justify-content-center flex-column">
                  <Accordion activeKey={activeCountry}>
                    {CheckRole(roles["admin"]) ||
                    (CheckRole(roles["manager"]) &&
                      Cookies.get("country") === "السعودية") ||
                    CheckRole(roles["accountant"]) ||
                    CheckRole(roles["HR"]) ? (
                      <Accordion.Item eventKey="saudia">
                        <div className="flex items-center justify-between gap-4">
                          <Accordion.Header
                            className={`${
                              activeCountry === "saudia"
                                ? "border-2 rounded-md border-[#EFAA20] hover:border-transparent"
                                : "border-2 border-mainBorder rounded-md"
                            } w-full`}
                            onClick={() => handleCountryToggle("saudia")}
                          >
                            <Link
                              to="/System/AllUsers/Country/Saudia"
                              className="flex w-full justify-between"
                            >
                              <div>السعودية</div>
                              <MdKeyboardArrowDown size={20} />
                            </Link>
                          </Accordion.Header>
                          <div
                            onClick={() => setSearchOpen(!searchOpen)}
                            className="w-12 h-10 bg-mainNavy flex items-center justify-center rounded-lg cursor-pointer"
                          >
                            <IoIosArrowBack className="text-mainText" />
                          </div>
                        </div>
                        <Accordion.Body>
                          <div className="tabs d-flex justify-content-center align-items-center flex-column">
                            <div className="flex items-center justify-between gap-4 w-full ">
                              <Link
                                onClick={() => setSearchOpen(!searchOpen)}
                                to={
                                  "/System/AllUsers/Country/Saudia/مدير المكتب"
                                }
                                className="w-full"
                              >
                                <div className="tab text-end w-100">
                                  مدير مكتب
                                </div>
                              </Link>
                              <div
                                onClick={() => setSearchOpen(!searchOpen)}
                                className="w-12 h-10 bg-mainNavy mt-2 flex items-center justify-center rounded-lg cursor-pointer"
                              >
                                <IoIosArrowBack className="text-mainText" />
                              </div>
                            </div>
                            <Accordion activeKey={activeChild.saudia}>
                              <Accordion.Item eventKey="مدير قسم">
                                <div className="flex items-center justify-between gap-4 w-full">
                                  <Accordion.Header
                                    className="border-2 border-mainBorder rounded-md rounded-b-lg w-full"
                                    onClick={() =>
                                      handleChildToggle("saudia", "مدير قسم")
                                    }
                                  >
                                    <Link
                                      onClick={() => setSearchOpen(!searchOpen)}
                                      className="flex w-full justify-between"
                                      to={
                                        "/System/AllUsers/Country/Saudia/مدير قسم"
                                      }
                                    >
                                      <div className="">مدير قسم</div>
                                      <MdKeyboardArrowDown size={20} />
                                    </Link>
                                  </Accordion.Header>
                                  <div
                                    onClick={() => setSearchOpen(!searchOpen)}
                                    className="w-12 h-10 bg-mainNavy flex items-center justify-center rounded-lg cursor-pointer"
                                  >
                                    <IoIosArrowBack className="text-mainText" />
                                  </div>
                                </div>
                                <Accordion.Body>
                                  <div className="tabs d-flex flex-column">
                                    {departmentOption?.map((type) => (
                                      <div
                                        key={type}
                                        className="flex items-center justify-between gap-4 w-full"
                                      >
                                        <Link
                                          onClick={() =>
                                            setSearchOpen(!searchOpen)
                                          }
                                          to={`/System/AllUsers/Country/Saudia/مدير قسم/${type}`}
                                        >
                                          <div className="tab">{type}</div>
                                        </Link>
                                        <div
                                          onClick={() =>
                                            setSearchOpen(!searchOpen)
                                          }
                                          className="w-12 h-10 bg-mainNavy mt-2 flex items-center justify-center rounded-lg cursor-pointer"
                                        >
                                          <IoIosArrowBack className="text-mainText" />
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                </Accordion.Body>
                              </Accordion.Item>
                              <Accordion.Item eventKey="موظف">
                                <div className="flex items-center justify-between gap-4 w-full">
                                  <Accordion.Header
                                    className="border-2 border-mainBorder rounded-md rounded-b-lg w-full"
                                    onClick={() =>
                                      handleChildToggle("saudia", "موظف")
                                    }
                                  >
                                    <Link
                                      onClick={() => setSearchOpen(!searchOpen)}
                                      className="flex w-full justify-between"
                                      to={
                                        "/System/AllUsers/Country/Saudia/موظف"
                                      }
                                    >
                                      <div className="text-start">موظف</div>
                                      <MdKeyboardArrowDown size={20} />
                                    </Link>
                                  </Accordion.Header>
                                  <div
                                    onClick={() => setSearchOpen(!searchOpen)}
                                    className="w-12 h-10 bg-mainNavy flex items-center justify-center rounded-lg cursor-pointer"
                                  >
                                    <IoIosArrowBack className="text-mainText" />
                                  </div>
                                </div>
                                <Accordion.Body>
                                  <div className="tabs d-flex flex-column">
                                    {departmentOption?.map((type) => (
                                      <div
                                        key={type}
                                        className="flex items-center justify-between gap-4 w-full"
                                      >
                                        <Link
                                          onClick={() =>
                                            setSearchOpen(!searchOpen)
                                          }
                                          to={`/System/AllUsers/Country/Saudia/موظف/${type}`}
                                        >
                                          <div className="tab">{type}</div>
                                        </Link>
                                        <div
                                          onClick={() =>
                                            setSearchOpen(!searchOpen)
                                          }
                                          className="w-12 h-10 bg-mainNavy mt-2 flex items-center justify-center rounded-lg cursor-pointer"
                                        >
                                          <IoIosArrowBack className="text-mainText" />
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                </Accordion.Body>
                              </Accordion.Item>
                            </Accordion>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    ) : null}
                    {CheckRole(roles["admin"]) ||
                    (CheckRole(roles["manager"]) &&
                      Cookies.get("country") === "مصر") ||
                    CheckRole(roles["accountant"]) ||
                    CheckRole(roles["HR"]) ? (
                      <Accordion.Item eventKey="egypt">
                        <div className="flex items-center justify-between gap-4">
                          <Accordion.Header
                            className={`${
                              activeCountry === "egypt"
                                ? "border-2 rounded-md border-[#EFAA20] hover:border-transparent"
                                : "border-2 border-mainBorder rounded-md rounded-b-lg"
                            } w-full`}
                            onClick={() => handleCountryToggle("egypt")}
                          >
                            <Link
                              to="/System/AllUsers/Country/egypt"
                              className="w-full flex justify-between"
                            >
                              <div>مصر</div>
                              <MdKeyboardArrowDown size={20} />
                            </Link>
                          </Accordion.Header>
                          <div
                            onClick={() => setSearchOpen(!searchOpen)}
                            className="w-12 h-10 bg-mainNavy flex items-center justify-center rounded-lg cursor-pointer"
                          >
                            <IoIosArrowBack className="text-mainText" />
                          </div>
                        </div>
                        <Accordion.Body>
                          <div className="tabs d-flex justify-content-center align-items-center flex-column">
                            <div className="flex items-center justify-between gap-4 w-full">
                              <Link
                                to={
                                  "/System/AllUsers/Country/egypt/مدير المكتب"
                                }
                                onClick={() => setSearchOpen(!searchOpen)}
                                className="w-full"
                              >
                                <div className="tab text-end w-100">
                                  مدير مكتب
                                </div>
                              </Link>
                              <div
                                onClick={() => setSearchOpen(!searchOpen)}
                                className="w-12 h-10 bg-mainNavy mt-2 flex items-center justify-center rounded-lg cursor-pointer"
                              >
                                <IoIosArrowBack className="text-mainText" />
                              </div>
                            </div>
                            <Accordion activeKey={activeChild.egypt}>
                              <Accordion.Item eventKey="مدير قسم">
                                <div className="flex items-center justify-between gap-4 w-full">
                                  <Accordion.Header
                                    className="border-2 border-mainBorder rounded-md rounded-b-lg w-full"
                                    onClick={() =>
                                      handleChildToggle("egypt", "مدير قسم")
                                    }
                                  >
                                    <Link
                                      onClick={() => setSearchOpen(!searchOpen)}
                                      className="w-full flex justify-between"
                                      to={
                                        "/System/AllUsers/Country/egypt/مدير قسم"
                                      }
                                    >
                                      <div className="">مدير قسم</div>
                                      <MdKeyboardArrowDown size={20} />
                                    </Link>
                                  </Accordion.Header>
                                  <div
                                    onClick={() => setSearchOpen(!searchOpen)}
                                    className="w-12 h-10 bg-mainNavy flex items-center justify-center rounded-lg cursor-pointer"
                                  >
                                    <IoIosArrowBack className="text-mainText" />
                                  </div>
                                </div>
                                <Accordion.Body>
                                  <div className="tabs d-flex flex-column">
                                    {departmentOption?.map((type) => (
                                      <div
                                        key={type}
                                        className="flex items-center justify-between gap-4 w-full"
                                      >
                                        <Link
                                          onClick={() =>
                                            setSearchOpen(!searchOpen)
                                          }
                                          to={`/System/AllUsers/Country/egypt/مدير قسم/${type}`}
                                        >
                                          <div className="tab">{type}</div>
                                        </Link>
                                        <div
                                          onClick={() =>
                                            setSearchOpen(!searchOpen)
                                          }
                                          className="w-12 h-10 bg-mainNavy mt-2 flex items-center justify-center rounded-lg cursor-pointer"
                                        >
                                          <IoIosArrowBack className="text-mainText" />
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                </Accordion.Body>
                              </Accordion.Item>
                              <Accordion.Item eventKey="موظف">
                                <div className="flex items-center justify-between gap-4 w-full">
                                  <Accordion.Header
                                    className="border-2 border-mainBorder rounded-md rounded-b-lg w-full"
                                    onClick={() =>
                                      handleChildToggle("egypt", "موظف")
                                    }
                                  >
                                    <Link
                                      onClick={() => setSearchOpen(!searchOpen)}
                                      className="flex w-full justify-between"
                                      to={"/System/AllUsers/Country/egypt/موظف"}
                                    >
                                      <div className="text-start">موظف</div>
                                      <MdKeyboardArrowDown size={20} />
                                    </Link>
                                  </Accordion.Header>
                                  <div
                                    onClick={() => setSearchOpen(!searchOpen)}
                                    className="w-12 h-10 bg-mainNavy flex items-center justify-center rounded-lg cursor-pointer"
                                  >
                                    <IoIosArrowBack className="text-mainText" />
                                  </div>
                                </div>
                                <Accordion.Body>
                                  <div className="tabs d-flex flex-column">
                                    {departmentOption?.map((type) => (
                                      <div
                                        key={type}
                                        className="flex items-center justify-between gap-4 w-full"
                                      >
                                        <Link
                                          onClick={() =>
                                            setSearchOpen(!searchOpen)
                                          }
                                          to={`/System/AllUsers/Country/egypt/موظف/${type}`}
                                        >
                                          <div className="tab">{type}</div>
                                        </Link>
                                        <div
                                          onClick={() =>
                                            setSearchOpen(!searchOpen)
                                          }
                                          className="w-12 h-10 bg-mainNavy mt-2 flex items-center justify-center rounded-lg cursor-pointer"
                                        >
                                          <IoIosArrowBack className="text-mainText" />
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                </Accordion.Body>
                              </Accordion.Item>
                            </Accordion>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    ) : null}
                  </Accordion>
                </div>
              </div>
            </div>
          }
        />
        {searchOpen && <AllUsersSearchDropDown />}
      </div>
    </div>
  );
};

export default AllUserDropdown;
