import React from "react";
import { StatisticsCard } from "../../../../Components/StatisticsCard";
import {
  CompletedIcon,
  InProgressIcon,
  InProgressTechnical,
  LatetIcon,
  ProjectsIcon,
} from "../../../../utiltis/Icons";

const TechnicalSupportStats = ({
  parentTitle,
  parentTotal,
  inProgressTitle,
  inProgressTotal,
  completeTitle,
  completeTotal,
  lateTitle,
  lateTotal,
}) => {
  return (
    <div className="row my-4 ">
      <div className="col-md-3">
        <StatisticsCard
          title={parentTitle}
          total={parentTotal}
          icon={<ProjectsIcon />}
        />
      </div>
      <div className="col-md-3">
        <StatisticsCard
          title={inProgressTitle}
          total={inProgressTotal}
          icon={<InProgressTechnical />}
          nested
        />
      </div>
      <div className="col-md-3">
        <StatisticsCard
          title={completeTitle}
          total={completeTotal}
          icon={<InProgressIcon />}
          nested
        />
      </div>
      <div className="col-md-3">
        <StatisticsCard
          title={lateTitle}
          total={lateTotal}
          icon={<CompletedIcon />}
          nested
        />
      </div>
    </div>
  );
};

export default TechnicalSupportStats;
