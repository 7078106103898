import { useContext } from "react";
import SuccessfullModal from "../../../../../Components/Modals/SuccessfullModal";
import { FormModal } from "../../../PlanModel/components/FormModal";
import AddModal from "../../AddModal";
import { useShowMenu } from "../hooks/useShowMenu";
import { AddNewButton } from "./AddNewButton";
import { SubServicesList } from "./SubServicesList";
import {
  useAddSubService,
  useGetSubServices,
} from "../../../../../hooks/fetchers/SubService";
import { ServiceContext } from "../context/ServiceContext";
import useSuccessfullModal from "../../../../../Components/Modals/hooks/useSuccessfullModal";
import LoadingPage from "../../../../../Components/feedback/LoadingPage";
import { joiResolver } from "@hookform/resolvers/joi";
import { subServiceSchema } from "../../../../../utiltis/Schemas/SubServices/schema";
export const SubServicesDesign = () => {
  const { show, handleShow, handleClose } = useShowMenu();
  const { serviceId } = useContext(ServiceContext);
  const { data } = useGetSubServices(serviceId);

    const {
      handleShowSuccess,
      handleShowError,
      handleCloseSuccess,
      handleCloseError,
      showSuccess,
      showError,
    } = useSuccessfullModal();
    const { mutate , isLoading } = useAddSubService(handleShowSuccess,handleShowError, serviceId);
  
    return (
      <div className="py-4 px-4 h-full flex flex-col">
        {isLoading ? <LoadingPage/> : null}
        <AddNewButton onClick={handleShow} />
  
        <AddModal
          title={"اضافة جديدة"}
          show={show}
          handleClose={handleClose}
          name={"name"}
          isLoading={isLoading}
          handleSave={(data) => {
            mutate(data);
            handleClose();
          }}
          formOptions={{
            resolver: joiResolver(subServiceSchema('ar'))
          }}
        />
        <div className="p-2 flex-1">
          <FormModal title={data?.name} className={"h-full"}>
            <SubServicesList service={data} serviceId={serviceId} />
            <SuccessfullModal
            show={showSuccess}
            message={"تمت الاضافة بنجاح"}
            handleClose={handleCloseSuccess}
          />
          <SuccessfullModal
            show={showError}
            message={"حدث خطا ما"}
            handleClose={handleCloseError}
            status="error"
          />
        </FormModal>
      </div>
    </div>
  );
};
