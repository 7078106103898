import { createContext, useContext, useEffect, useState } from "react";
import { getUserByCountry } from "../../../../helper/fetchers/Users";

const SearchCountryUsersContext = createContext(null);

const SearchCountryUsersProvider = ({
  children,
  Country,
  role,
  departments,
}) => {
  const { status, onlineStatus, offlineStatus, resetStatus } =
    useHandleUserStatus();
  const [filter, setFilter] = useState("");
  const countryName = Country === "Saudia" ? "السعودية" : "مصر";
  const { data } = useGetData({ countryName, role, departments });

  const filteredUsers = data?.filter((user) => {
    const fullName = user?.firstName + " " + user?.lastName;
    return (
      fullName?.toLowerCase()?.includes(filter?.toLowerCase()) ||
      user?.email.toLowerCase()?.includes(filter?.toLowerCase())
    );
  });

  const activeUsers = filteredUsers?.filter((user) => user?.status === status);

  const route = `System/AllUsers/Country/${Country}${role ? `/${role}` : ""}${
    departments ? `/${departments}` : ""
  }`;

  return (
    <SearchCountryUsersContext.Provider
      value={{
        statusActions: { status, onlineStatus, offlineStatus, resetStatus },
        filter,
        setFilter,
        route,
        filteredUsers,
        activeUsers
      }}
    >
      {children}
    </SearchCountryUsersContext.Provider>
  );
};
export const useSearchCountryUsersContext = () => {
  const ctx = useContext(SearchCountryUsersContext);
  if (!ctx) {
    throw new Error(
      "useSearchCountryUsersContext must be used within a SearchCountryUsersProvider"
    );
  }
  return ctx;
};
export default SearchCountryUsersProvider;
const useHandleUserStatus = () => {
  const [status, setStatus] = useState(undefined);
  const onlineStatus = () => {
    setStatus(1);
  };
  const offlineStatus = () => {
    setStatus(0);
  };
  const resetStatus = () => {
    setStatus(undefined);
  };
  return { status, onlineStatus, offlineStatus, resetStatus };
};
const useGetData = ({ countryName, role, departments }) => {
  const [data, setData] = useState([]);
  useEffect(() => {
    const getUsers = async () => {
      if (countryName) {
        const res = await getUserByCountry(countryName, role, departments);
        res?.status === 200 ? setData(res?.data?.allUsers) : setData([]);
      }
    };
    getUsers();
  }, [countryName, role, departments]);
  return { data };
};
