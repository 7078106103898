import { StatisticsCard } from "../../../../../Components/StatisticsCard";
import CustomChart from "../../../../System/Main/components/charts/CustomChart";
import {
  useGetAllMainReportsPage,
} from "../../../../../hooks/fetchers/Requests";
import { useNavigate } from "react-router-dom";

const MainReportsPage = () => {

  const {
    data: dataMainReport,
    isLoading: isLoadingMainReport,
    isError: isErrorMainReport,
  } = useGetAllMainReportsPage();
  const navigate = useNavigate();
  return (
    <div className="p-2 space-y-10 max-h-[800px] overflow-y-auto scrollbar-thin scrollbar-track-transparent scrollbar-thumb-[#efaa20] ">
      <fieldset className="border !border-[#efaa20] rounded-3xl p-4">
        <legend className="mx-auto text-center !w-fit px-3">الحسابات</legend>
        <div className="grid grid-cols-3 gap-4">
          {!isLoadingMainReport && !isErrorMainReport ? (
            <>
              <StatisticsCard
                nested={true}
                // title={dataMainReport?.accounts[0]?.title || ""}
                title={"المطالبات"}
                total={dataMainReport?.accounts[0]?.value}
                icon={
                  <img
                    src={`${process.env.PUBLIC_URL + "/Main/orders.png"}`}
                    alt=""
                  />
                }
              />
              <StatisticsCard
                nested={true}
                // title={dataMainReport?.accounts[1]?.title || ""}
                title={"الفواتير"}
                total={dataMainReport?.accounts[1]?.value}
                icon={
                  <img
                    src={`${process.env.PUBLIC_URL + "/Main/orders.png"}`}
                    alt=""
                  />
                }
              />
              <StatisticsCard
                nested={true}
                // title={dataMainReport?.accounts[2]?.title || ""}
                title={"تقارير المصروفات"}
                total={dataMainReport?.accounts[2]?.value}
                icon={
                  <img
                    src={`${process.env.PUBLIC_URL + "/Main/orders.png"}`}
                    alt=""
                  />
                }
              />
              {/* {dataMainReport && dataMainReport?.accounts ? dataMainReport?.accounts?.map((item,index)=>(
          <StatisticsCard
          nested={true}
          title={item?.title || ""}
          total={item?.value}
          icon={
            <img
              src={`${process.env.PUBLIC_URL + "/Main/orders.png"}`}
              alt=""
            />
          }
        />
        )): null} */}
            </>
          ) : null}
        </div>
      </fieldset>

      <div className="">
        <div
        role="button"
          className={`bg-cardBg [box-shadow:_0px_4px_20px_0px_rgba(0,_0,_0,_0.25);] w-full py-0 px-1.5 rounded-[16.799px] item p-3 flex h-[250px] overflow-y-auto scrollbar-thin scrollbar-track-transparent scrollbar-thumb-[#EFAA20]`}
          onClick={() => navigate("requests")}
        >
          {!isLoadingMainReport && !isErrorMainReport ? (
            <CustomChart
              labelClass={"min-w-fit mt-2"}
              data={[
                {
                  label: "فى انتظار الموافقة",
                  value: dataMainReport?.requests?.PENDING?.count,
                  percentage: dataMainReport?.requests?.PENDING?.percentage,
                },
                {
                  label: "قيد التنفيذ",
                  value: dataMainReport?.requests?.IN_PROGRESS?.count,
                  percentage: dataMainReport?.requests?.IN_PROGRESS?.percentage,
                },
                {
                  label: "تم تحويلها الى مشروع",
                  value: dataMainReport?.requests?.TO_PROJECT?.count,
                  percentage: dataMainReport?.requests?.TO_PROJECT?.percentage,
                },
                {
                  label: "مرفوضة",
                  value: dataMainReport?.requests?.REJECTED?.count,
                  percentage: dataMainReport?.requests?.REJECTED?.percentage,
                },
              ]}
              chartTitle="احصائيات الطلبات"
              title="عدد الطلبات"
              mainColTitle="الحالة"
            />
          ) : null}
        </div>
        <div
        role="button"
          className={`bg-cardBg [box-shadow:_0px_4px_20px_0px_rgba(0,_0,_0,_0.25);] w-full py-0 px-1.5 rounded-[16.799px] item p-3 flex h-[250px] overflow-y-auto scrollbar-thin scrollbar-track-transparent scrollbar-thumb-[#EFAA20]`}
          onClick={() => navigate("projects")}
        >
          {!isLoadingMainReport && !isErrorMainReport ? (
            <CustomChart
              labelClass={"min-w-fit mt-2"}
              data={[
                {
                  label: "فى انتظار الموافقة",
                  value: dataMainReport?.projects?.PENDING?.count,
                  percentage: dataMainReport?.projects?.PENDING?.percentage,
                },
                {
                  label: "قيد التنفيذ",
                  value: dataMainReport?.projects?.IN_PROGRESS?.count,
                  percentage: dataMainReport?.projects?.IN_PROGRESS?.percentage,
                },
                {
                  label: "مكتملة",
                  value: dataMainReport?.projects?.COMPLETED?.count,
                  percentage: dataMainReport?.projects?.COMPLETED?.percentage,
                },
              ]}
              chartTitle="احصائيات المشاريع"
              title="عدد المشاريع"
              mainColTitle="الحالة"
            />
          ) : null}
        </div>
        <div
        role="button"
          className={`bg-cardBg [box-shadow:_0px_4px_20px_0px_rgba(0,_0,_0,_0.25);] w-full py-0 px-1.5 rounded-[16.799px] item p-3 flex h-[250px] overflow-y-auto scrollbar-thin scrollbar-track-transparent scrollbar-thumb-[#EFAA20]`}
          onClick={() => navigate("users")}
        >
          {!isLoadingMainReport && !isErrorMainReport ? (
            <CustomChart
              labelClass={"min-w-fit"}
              data={[
                {
                  label: "كل المستخدمين",
                  value: dataMainReport?.users?.TOTAL,
                  percentage: "100%",
                },
                {
                  label: "السعودية",
                  value: dataMainReport?.users?.Saudi?.count,
                  percentage: dataMainReport?.users?.Saudi?.precentage,
                },
                {
                  label: "مصر",
                  value: dataMainReport?.users?.Egypt?.count,
                  percentage: dataMainReport?.users?.Egypt?.precentage,
                },
              ]}
              chartTitle="احصائيات المستخدمين"
              title="عدد المستخدمين"
              mainColTitle="الحالة"
            />
          ) : null}
        </div>
        <div
        role="button"
          className={`bg-cardBg [box-shadow:_0px_4px_20px_0px_rgba(0,_0,_0,_0.25);] w-full py-0 px-1.5 rounded-[16.799px] item p-3 flex h-[250px] overflow-y-auto scrollbar-thin scrollbar-track-transparent scrollbar-thumb-[#EFAA20]`}
          onClick={() => navigate("clients")}
        >
          {(!isLoadingMainReport && !isErrorMainReport) ? (
            <CustomChart
              labelClass={"min-w-fit"}
              data={[
                {
                  label: "كل العملاء",
                  value: dataMainReport?.clients?.TOTAL,
                  percentage: "100%",
                },
                {
                  label: "عملاء التصميم",
                  value: dataMainReport?.clients?.Design?.count,
                  percentage: dataMainReport?.clients?.Design?.precentage,
                },
                {
                  label: "عملاء الاشراف",
                  value: dataMainReport?.clients?.Supervising?.count,
                  percentage: dataMainReport?.clients?.Supervising?.precentage,
                },
              ]}
              chartTitle="احصائيات العملاء"
              title="عدد العملاء"
              mainColTitle="الحالة"
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default MainReportsPage;
