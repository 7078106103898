import { useContext, useState } from "react";
import { SearchComponent } from "../../../../Components/SearchComponent/SearchComponent";
import CustomTable from "../../../../Components/Table";
import {
  CheckRole,
  GenerateColumnsNames,
  reminderStatusEnum,
} from "../../../../helper/utils";
import { TableRow } from "../../../../Components/Table/TableRow";
import { TableCell } from "../../../../Components/Table/TableCell";
import moment from "moment";
import Image from "../../../../Components/Image";
import { useDeleteReminders } from "../hooks/reminderForm";
import { NavDropdown } from "react-bootstrap";
import { BsThreeDotsVertical } from "react-icons/bs";
import { MdDeleteOutline } from "react-icons/md";
import { FiEdit } from "react-icons/fi";
import { ReminderPageProvider } from "../../../../Context/ReminderPage";
import DeleteModal from "../../Settings/RemoveModal";
import useSuccessfullModal from "../../../../Components/Modals/hooks/useSuccessfullModal";
import SuccessfullModal from "../../../../Components/Modals/SuccessfullModal";
import LoadingPage from "../../../../Components/feedback/LoadingPage";
import CustomCheckboxTable from "../../../../Components/CustomCheckboxTable";
import TableDeleteButton from "../../../../Components/Buttons/TableDeleteButton";
import { roles } from "../../../../helper/fetchers/Tasks";
const columns = GenerateColumnsNames(
  "تحديد",
  "م",
  "اسم المهمه",
  "نوع المشروع",
  "اسم العميل",
  "تاريخ التذكير",
  "وفت التذكير",
  "الحالة",
  "الاولويه",
  "عرض",
  ""
);
const ReminderPageTable = ({
  data,
  isLoading,
  isError,
  search,
  setSearch,
  page,
  setPage,
}) => {
  const handleSearch = (val) => {
    setSearch(val);
  };

  console.log(data, "data");
  const { setShowReminder, setReminder, setEditReminder, reminder } =
    useContext(ReminderPageProvider);
  const [showDelete, setShowDelete] = useState(false);
  const {
    showSuccess,
    showError,
    handleShowSuccess,
    handleShowError,
    handleCloseSuccess,
    handleCloseError,
  } = useSuccessfullModal();

  const { mutate: deleteMutation, isLoading: isDeleteLoading } =
    useDeleteReminders(handleShowSuccess, handleShowError);
  const handleChange = (event, value) => setPage(value);
  const [checked, setChecked] = useState([]);
  const handleChecked = (val) => {
    if (checked.includes(val)) {
      setChecked(checked.filter((item) => item !== val));
    } else {
      setChecked([...checked, val]);
    }
  };

  const handleDeleteAll = () => {};
  const handleDeleteSelected = () => {
    deleteMutation({
      reminders: checked,
    });
  };
  return isDeleteLoading ? (
    <LoadingPage />
  ) : (
    <div className="w-full pt-4 flex flex-col gap-6 ">
      <DeleteModal
        title={"التاكيد"}
        show={showDelete}
        handleClose={() => setShowDelete(false)}
        onSave={() => {
          deleteMutation({ reminders: [reminder?._id] });
        }}
        stillOpen={true}
      />
      <SuccessfullModal
        handleClose={() => {
          setShowDelete(false);
          handleCloseSuccess();
        }}
        message={"تمت الحذف بنجاح"}
        show={showSuccess}
      />
      <SuccessfullModal
        handleClose={handleCloseError}
        message={"حدث خطأ ما"}
        show={showError}
        status="error"
      />
      {/* Header */}
      <div className="w-full grid grid-cols-4 ">
        <h1 className="text-[#E4A11B] text-2xl font-semibold col-span-2">
          كل المهام{" "}
        </h1>
        <div className="col-span-2 w-full">
          <SearchComponent
            handleChange={handleSearch}
            value={search}
            className={""}
            // border="border !border-[#E4A11B] "
          />
        </div>
      </div>
      {CheckRole(roles["admin"]) ? (
        <TableDeleteButton
          checked={checked}
          dataLength={data?.reminders.length}
          onClickDeleteAll={handleDeleteAll}
          onClickDeleteSelected={handleDeleteSelected}
        />
      ) : null}
      {/* Table */}
      <div className="border !border-[#efaa20] w-full py-8 px-4 rounded-md bg-mainItems h-[420px] overflow-auto">
        {!isLoading && !isError && data.reminders ? (
          <CustomTable
          columns={CheckRole(roles["admin"]) ? columns : columns.slice(1)}
            data={data?.reminders}
            paginationProps={{
              count: data?.pages,
              page: page,
              onChange: handleChange,
            }}
            responsive={false}
          >
            {data?.reminders && data?.reminders.length > 0
              ? data?.reminders.map((reminder, index) => (
                  <TableRow
                    className={`my-2 border !border-[#efaa207f] ${
                      index % 2 === 0 ? "bg-mainNavy" : ""
                    }`}
                    key={reminder?._id}
                  >
                    {CheckRole(roles["admin"]) ? (
                      <TableCell>
                        <CustomCheckboxTable
                          isChecked={checked?.some((x) => x === reminder?._id)}
                          handleClick={() => handleChecked(reminder?._id)}
                        />
                      </TableCell>
                    ) : null}
                    <TableCell>{(page - 1) * 10 + (index + 1)}</TableCell>
                    <TableCell>{reminder?.reminderName}</TableCell>
                    <TableCell>
                      {reminder?.projectType === "1"
                        ? "تصميم"
                        : reminder?.projectType === "2"
                        ? "الاشراف علي التنفيذ"
                        : "اخري"}
                    </TableCell>
                    <TableCell>{reminder?.clientName}</TableCell>
                    <TableCell>
                      {moment(reminder?.reminderDate).format("YYYY-MM-DD")}
                    </TableCell>
                    <TableCell>{reminder?.reminderTime}</TableCell>
                    <TableCell>
                      <div className="flex items-center gap-2">
                        {reminder.status === 1 ? (
                          <div className="w-2 h-2 rounded-full bg-[#efaa20]" />
                        ) : reminder.status === 5 ? (
                          <div className="w-2 h-2 rounded-full bg-[#fefefe]" />
                        ) : reminder.status === 2 ? (
                          <div className="w-2 h-2 rounded-full bg-[#19B159]" />
                        ) : null}
                        {reminderStatusEnum[reminder.status]}
                      </div>
                    </TableCell>
                    <TableCell>
                      {reminder?.periority === "2"
                        ? "عادية"
                        : reminder?.periority === "4"
                        ? "متوسطة"
                        : "مهمه"}
                    </TableCell>
                    <TableCell>
                      <Image
                        src={process.env.PUBLIC_URL + "/icons/view.svg"}
                        onClick={() => {
                          setShowReminder(true);
                          setReminder(reminder);
                        }}
                        className="display_project  rounded"
                        alt=" display project"
                      />
                    </TableCell>
                    <TableCell>
                      <NavDropdown
                        title={
                          <BsThreeDotsVertical className="text-[#efaa20]" />
                        }
                        className="fs-5 w-fit"
                        id="collapsible-nav-dropdown"
                      >
                        <NavDropdown.Item
                          onClick={() => {
                            setEditReminder(true);
                            setReminder(reminder);
                          }}
                          className="text-end d-flex gap-2  align-items-center hover:rounded-lg hover:text-mainNavy"
                        >
                          <FiEdit className="text-black" />

                          <span className="text-black">تعديل</span>
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          onClick={() => {
                            setShowDelete(true);
                            setReminder(reminder);
                          }}
                          className="text-end  d-flex gap-2  align-items-center hover:rounded-lg"
                        >
                          <MdDeleteOutline className="text-black" />
                          <span className="text-black">حذف</span>
                        </NavDropdown.Item>
                      </NavDropdown>
                    </TableCell>
                  </TableRow>
                ))
              : null}
          </CustomTable>
        ) : null}
      </div>
    </div>
  );
};

export default ReminderPageTable;
