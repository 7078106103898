import { useState } from "react";

const useModal = () => {
  const [show, setShow] = useState(false);
  const handleOpenModal = () => setShow(true);
  const handleCloseModal = () => setShow(false);
  const handleToggleModal = () => setShow(!show);
  return {
    show,
    handleOpenModal,
    handleCloseModal,
    handleToggleModal,
  };
};

export default useModal;
