import React from 'react'
import { ConnectedBadge, NotConnectedBadge } from '../../../../Components/Users/StatusBadge';
import { Avatar } from '@mui/material';

const Personal = ({ user }) => {
    return (
      <>
        {+user?.status === 1 ? (
          <ConnectedBadge
            overlap="circular"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            variant="dot"
          >
            <Avatar
              alt={`${user?.firstName} ${user?.lastName}`}
              src={user?.image}
            />
          </ConnectedBadge>
        ) : (
          <NotConnectedBadge
            overlap="circular"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            variant="dot"
          >
            <Avatar
              alt={`${user?.firstName} ${user?.lastName}`}
              src={user?.image}
            />
          </NotConnectedBadge>
        )}
      </>
    );
  };

export default Personal