import { useQuery,useMutation ,useQueryClient } from "react-query";
import { addSubCategory, deleteSubCategory, getAllSubCategory, getSubCategoryById, updateSubCategory } from "../../helper/fetchers/SubCategories";



export const useGetSubCategory = (categoryId) => {
  const query = useQuery(["category",categoryId], () => getAllSubCategory(categoryId),{
    enabled: !!categoryId
  });
    
  return query;
};
export const useGetSubCategoryById = (categoryId,subId) => {
  const query = useQuery(["category",categoryId,subId], () => getSubCategoryById(categoryId,subId),{
    enabled: (!!categoryId && !!subId)
  });
  return query;
};

export const useAddSubCategory = (onSuccess,onError,categoryId) => {
    const queryClient = useQueryClient()
    return useMutation((subcategoryData) => addSubCategory(categoryId,subcategoryData),
        {
            onSuccess: ()=> {
                queryClient.invalidateQueries('category')
                onSuccess()
            },
            onError: (error) => {
                onError()
            }
        }
    );
}
export const useUpdateSubCategory = (onSuccess,onError,categoryId,subId) => {
    const queryClient = useQueryClient()
    return useMutation((data) => updateSubCategory(categoryId,subId,data),{
        onSuccess: ()=> {
            queryClient.invalidateQueries('category')
            onSuccess()
        },
            onError: (error) => {
                onError()
            }
        }
    );
}



export const useDeleteSubCategory = (onSuccess,onError) => {
    const queryClient = useQueryClient()
    return useMutation(deleteSubCategory,
        {
            onSuccess: ()=> {
                queryClient.invalidateQueries('category')
                onSuccess()
            },
                onError: (error) => {
                    onError()
                }
            }
    );
}