import { toast } from "react-toastify";
import { myAxios, myAxiosJson } from "../https";

export const getAllMeeting = () =>
  myAxiosJson("event").then((data) => data?.data).catch(error=>{
    toast.error(error?.response?.data?.message)
});

export const getMeeting = (id) =>
  myAxiosJson("event")
    .then(({ data }) => data?.events)
    .then((data) => data?.find((item) => item._id === id))
    .catch(error=>{
      toast.error(error?.response?.data?.message)
  });

export const addMeeting = (data) => myAxios.post("event", data);

export const deleteMeeting = (id) => myAxios.delete(`/event/${id}`);

export const updateMeeting = (id, data) =>
  myAxiosJson.patch(`/event/${id}`, data);
