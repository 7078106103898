import { IoMdTime } from "react-icons/io";
import { CommentReactions } from "./CommentReactions";
import { CommentReplyBody } from "./CommentReplyBody";
import { CiCalendar } from "react-icons/ci";
import "../style.css";
import moment from "moment";
export const CommentBody = ({
  isReply = false,
  comment = {},
  rep = {},
}) => {
  // console.log("comment", comment);
  // console.log("comment createdBy", comment?.createdBy);

  return (
    <div className="flex-1">
      <div className="flex-1">
        <div className="relative CommentBody p-[10px] rounded-lg flex flex-col gap-2">
          <span className="border-[20px] CommentBody-border border-b-transparent border-l-transparent border-r-transparent w-3 absolute top-0 -right-4"></span>
          <p className="text-base font-semibold text-[#E1A945]">
            {isReply ? `${rep?.createdBy?.firstName} ${rep?.createdBy?.lastName}`:`${comment?.createdBy?.firstName} ${comment?.createdBy?.lastName}`}
          </p>
          {/* {comment.replays.map((rep) => (
            <CommentReplyBody comment={rep} />
          ))} */}
          {isReply ? <CommentReplyBody comment={comment} /> : null}
          <p className="text-base ">
            {isReply ? rep.replayText : comment.commentText}
          </p>
        </div>
        <div className="flex items-center justify-between">
          {!isReply && (
            <CommentReactions
              likeUsers={comment.like}
              dislikeUsers={comment.unlike}
              commentId={comment._id}
              comment={comment}
            />
          )}
          {!isReply && (
            <div className="flex items-center gap-4">
              <div className="flex items-center gap-1">
                <p>{moment(comment.createdAt).format("hh:mm a")}</p>{" "}
                <IoMdTime />
              </div>
              <div className="flex items-center gap-1">
                <p>{moment(comment.createdAt).format("YYYY-MM-DD")}</p>
                <CiCalendar />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );

  // return isReply ? (
  //   comment.replays.map((cmt) => (
  //     <div className="flex-1">
  //       <div className="relative CommentBody p-[10px] rounded-lg flex flex-col gap-2">
  //         <span className="border-[20px] CommentBody-border border-b-transparent border-l-transparent border-r-transparent w-3 absolute top-0 -right-4"></span>
  //         <p className="text-base font-semibold text-[#E1A945]">
  //           {`${comment.createdBy.firstName} ${comment.createdBy.lastName}`}
  //         </p>
  //         <CommentReplyBody comment={cmt} />
  //         <p className="text-base ">{comment.commentText}</p>
  //       </div>
  //       <div className="flex items-center justify-between">
  //         <CommentReactions
  //           likeUsers={comment.like}
  //           dislikeUsers={comment.unlike}
  //           commentId={comment._id}
  //           comment={comment}
  //         />
  //         <div className="flex items-center gap-4">
  //           <div className="flex items-center gap-1">
  //             <p>{moment(comment.createdAt).format("hh:mm a")}</p> <IoMdTime />
  //           </div>
  //           <div className="flex items-center gap-1">
  //             <p>{moment(comment.createdAt).format("YYYY-MM-DD")}</p>
  //             <CiCalendar />
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   ))
  // ) : (
  //   <div className="flex-1">
  //     <div className="relative CommentBody p-[10px] rounded-lg flex flex-col gap-2">
  //       <span className="border-[20px] CommentBody-border border-b-transparent border-l-transparent border-r-transparent w-3 absolute top-0 -right-4"></span>
  //       <p className="text-base font-semibold text-[#E1A945]">
  //         {`${comment.createdBy.firstName} ${comment.createdBy.lastName}`}
  //       </p>
  //       {/* {isReply ? <CommentReplyBody/> : null} */}
  //       <p className="text-base ">{comment.commentText}</p>
  //     </div>
  //     <div className="flex items-center justify-between">
  //       <CommentReactions
  //         likeUsers={comment.like}
  //         dislikeUsers={comment.unlike}
  //         commentId={comment._id}
  //         comment={comment}
  //       />
  //       <div className="flex items-center gap-4">
  //         <div className="flex items-center gap-1">
  //           <p>{moment(comment.createdAt).format("hh:mm a")}</p> <IoMdTime />
  //         </div>
  //         <div className="flex items-center gap-1">
  //           <p>{moment(comment.createdAt).format("YYYY-MM-DD")}</p>
  //           <CiCalendar />
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // );
};
