import React from "react";
import { Accordion } from "react-bootstrap";
import { MdKeyboardArrowDown } from "react-icons/md";
import { Link } from "react-router-dom";

const AllProjectDropdown = ({ setOpen, active, setActive }) => {
  return (
    <div className="absolute top-[3rem] right-[12rem] z-[1000000] bg-mainNavy rounded-xl">
      <div className="flex items-start flex-col  bg-mainItems w-[300px] h-[300px]  p-2 overflow-auto shadow-2xl ">
        <div
          className="w-screen h-screen fixed inset-0  z-[-1]"
          onClick={() => {
            setOpen(false);
          }}
        />
        <div className="d-flex  flex-column  align-items-center w-full">
          <div className="mt-4 w-100">
            <Link className="!w-full pointer" to={"/System/Projects/index"}>
              <p
                onClick={() => {
                  setActive(null);
                }}
                className=" text-mainText "
              >
                كل المشاريع
              </p>
            </Link>
          </div>

          <div className="pointer !w-full mt-0">
            
            <div className=" d-flex  justify-content-center flex-column">
              <Accordion defaultActiveKey={null}>
                <Accordion.Item eventKey="0">
                  <Accordion.Header
                    className={`  ${
                      active === 0
                        ? " border-2  rounded-md hover:border-transparent  border-[#EFAA20] "
                        : "border-2 border-mainBorder rounded-md"
                    }`}
                    onClick={() => {
                      setActive(0);
                    }}
                  >
                    <Link to={"System/Projects/Main/inProgress"}>
                      مشاريع قيد التنفيذ
                    </Link>

                    <MdKeyboardArrowDown size={20} />
                  </Accordion.Header>

                  <Accordion.Body>
                    <div className="tabs !w-full d-flex justify-content-center align-items-center flex-column">
                      <Link
                        className="!w-full pointer p-2 bg-mainNavy mt-2 rounded-md"
                        to={"System/Projects/Main/inProgress/Design"}
                      >
                        <div className="tab  text-end !w-full">تصميم</div>
                      </Link>
                      <Link
                        className="!w-full pointer p-2 bg-mainNavy mt-2 rounded-md"
                        to={"System/Projects/Main/inProgress/Review"}
                      >
                        <div className="tab  text-end !w-full">
                          الاشراف علي التنفيذ
                        </div>
                      </Link>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header
                    className={`  ${
                      active === 1
                        ? " border-2 rounded-md hover:border-transparent  border-[#EFAA20] "
                        : "border-2 border-mainBorder rounded-md"
                    }`}
                    onClick={() => {
                      setActive(1);
                    }}
                  >
                    <Link
                      className="!w-full !text-right pointer"
                      to={"System/Projects/Main/Waiting"}
                    >
                      
                      مشاريع معلقة
                    </Link>

                    <MdKeyboardArrowDown size={20} />
                  </Accordion.Header>

                  <Accordion.Body>
                    <div className="tabs !w-full d-flex justify-content-center align-items-center flex-column">
                      <Link
                        className="!w-full pointer p-2 bg-mainNavy mt-2 rounded-md"
                        to={"System/Projects/Main/Waiting/Design"}
                      >
                        <div className="tab  text-end !w-full">تصميم</div>
                      </Link>
                      <Link
                        className="!w-full pointer p-2 bg-mainNavy mt-2 rounded-md"
                        to={"System/Projects/Main/Waiting/Review"}
                      >
                        <div className="tab  text-end !w-full">
                          الاشراف علي التنفيذ
                        </div>
                      </Link>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header
                    className={`  ${
                      active === 2
                        ? " border-2 rounded-md hover:border-transparent  border-[#EFAA20] "
                        : "border-2 border-mainBorder rounded-md"
                    }`}
                    onClick={() => {
                      setActive(2);
                    }}
                  >
                    <Link
                      className="!w-full pointer  !text-right"
                      to={"System/Projects/Main/Done"}
                    >
                      
                      مشاريع منتهية
                    </Link>

                    <MdKeyboardArrowDown size={20} />
                  </Accordion.Header>

                  <Accordion.Body>
                    <div className="tabs !w-full d-flex justify-content-center align-items-center flex-column">
                      <Link
                        className="!w-full pointer p-2 bg-mainNavy mt-2 rounded-md"
                        to={"System/Projects/Main/Done/Design"}
                      >
                        <div className="tab  text-end !w-full">تصميم</div>
                      </Link>
                      <Link
                        className="!w-full pointer p-2 bg-mainNavy mt-2 rounded-md"
                        to={"System/Projects/Main/Done/Review"}
                      >
                        <div className="tab  text-end !w-full">
                          الاشراف علي التنفيذ
                        </div>
                      </Link>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllProjectDropdown;
