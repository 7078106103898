import React, { Fragment } from "react";
import { myAxiosJson } from "../../../../../helper/https";
import { useQuery } from "react-query";
import CustomTable from "../../../../../Components/Table";
import { TableRow } from "../../../../../Components/Table/TableRow";
import { TableCell } from "../../../../../Components/Table/TableCell";
import {
  GenerateColumnsNames,
  approvementEnum,
  convertDateFormat,
  convertTimeFormat,
  projectTypeEnum,
  statusEnum,
} from "../../../../../helper/utils";
import { FaCheck } from "react-icons/fa";
import { Link } from "react-router-dom";
import { StatusDisplay } from "../StatusDisplay";
import moment from "moment";
import CustomTableMenu from "../../../../../Components/Table/CustomTableMenu";
import {useExternalTaskContext} from "../../../PlanModel/context/ExternalTaskContext"

const columns = GenerateColumnsNames(
  "م",
  "اسم المهمة",
  "نوع المشروع ",
  "تاريخ التسليم",
  "وقت التسليم",
  "وقت تسليم المهمه",
  "الحالة",
  "المسؤل",
  "الموظفين",
  "انتهاء",
  "عرض"
);

function ExternalTasksTable() {
  const { tasks, setFilterTasksParams } = useExternalTaskContext();
  // console.log("useGetAllTasks: ", tasks?.tasks);
  const [page, setPage] = React.useState(1);
  const handleChange = (event, value) => {
    setPage(value);
    setFilterTasksParams({ page: value });
  };

  console.log("confirmedTasks" , tasks);
  return (
    <CustomTable
      columns={columns}
      data={tasks?.confirmedTasks}
      paginationProps={{
        count: tasks?.pages,
        page: page,
        onChange: handleChange,
      }}
    >
      {tasks && tasks?.confirmedTasks?.length > 0
        ? tasks?.confirmedTasks?.map(
            (
              {
                _id,
                projectName,
                projectType,
                isLate,
                endDate,
                endTime,
                status,
                approvement,
                createdBy,
                isConfirm,
                isExisting,
                assignTo,
                planId,
                confirmedAt,
              },
              index
            ) => (
              <TableRow
                className={`my-2 border !border-[#efaa207f] ${
                  index % 2 === 0 ? "bg-mainNavy" : ""
                }`}
                key={_id}
              >
                <TableCell>{(page - 1) * 10 + (index + 1)}</TableCell>
                <TableCell>
                  {/* {isExisting === false
                      ? projectName
                      : planId?.isExist === true ?
                      planId?.projectId?.projectName
                      : planId?.projectName
                    } */}
                  {projectName || "لا يوجد مخطط"}
                </TableCell>
                <TableCell>
                  {/* {isExisting === true
                      ? projectTypeEnum[planId?.projectId?.projectType]
                      : projectTypeEnum[+projectType]} */}
                  {isExisting === false
                    ? projectTypeEnum[+projectType]
                    : planId?.isExist === true
                    ? projectTypeEnum[planId?.projectId?.projectType]
                    : projectTypeEnum[planId?.projectType]}
                  {/* { planId?.projectType ? projectTypeEnum[planId?.projectType] : "--------"} */}
                </TableCell>
                <TableCell>
                  {convertDateFormat(endDate) || "--------"}
                </TableCell>
                <TableCell>{endTime || "--------"}</TableCell>
                {/* <TableCell>{["معلقة", "أولية", "نهائية"][parseInt(status)] || "--------"}</TableCell> */}
                <TableCell>
                  <CustomTableMenu
                    items={[
                      {
                        title: "تاريخ:",
                        value: moment(confirmedAt).format("YYYY-MM-DD"),
                      },
                      {
                        title: "وقت:",
                        value: moment(confirmedAt).format("h:m a"),
                      },
                    ]}
                    maxItems={1}
                  />
                </TableCell>
                <TableCell cellClassName="flex items-center  gap-1">
                  {isLate ? (
                    <StatusDisplay title={"متأخرة"} color={"#9E0C1E"} />
                  ) : (
                    <StatusDisplay
                      title={
                        status == 2
                          ? approvementEnum[approvement]?.title
                          : statusEnum[[parseInt(status)]]?.title
                      }
                      color={statusEnum[[parseInt(status)]]?.color}
                    />
                  )}
                </TableCell>
                <TableCell>{createdBy?.firstName || "--------"}</TableCell>
                <TableCell>
                  <CustomTableMenu
                    items={assignTo?.map((employee, index) => ({
                      value: `${employee?.firstName} ${employee?.lastName}`,
                      path: `/System/Chat/${employee?._id}`
                    }))}
                    // items={[
                    //   {title:"تاريخ:" ,value: moment(confirmedAt).format("YYYY-MM-DD")},
                    //   {title:"وقت:" ,value: moment(confirmedAt).format("h:m a")}
                    // ]}
                    maxItems={1}
                  />
                  {/* {assignTo
                      ? assignTo?.map((employee, index) => (
                          <p className="" key={index}>
                            {employee?.firstName}
                          </p>
                        ))
                      : "--------"} */}
                </TableCell>
                <TableCell>
                  {isConfirm ? (
                    <div className="bg-[#19B159] py-1.5 px-2 rounded w-fit h-auto">
                      <FaCheck />
                    </div>
                  ) : (
                    "--------"
                  )}
                </TableCell>
                <TableCell>
                  <div className=" flex justify-between items-start gap-1">
                    <ShowButton id={_id} />
                    {/* <OptionsButton id={_id} /> */}
                  </div>
                </TableCell>
              </TableRow>
            )
          )
        : null}
    </CustomTable>
  );
}

const ShowButton = ({ id }) => {
  return (
    <Link
      to={`/System/tasks/show-external-task/${id}`}
      id={id}
      aria-label="show"
    >
      <img src="/icons/view.svg" alt="" className="w-full" />
    </Link>
  );
};

export default ExternalTasksTable;
