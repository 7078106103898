import React from 'react'
import { Modal } from 'react-bootstrap';
import Image from '../../../Image';

const EditDeleteMeetingWrapper = ({ children, show, onHide }) => {
    return (
      <Modal
        size="lg"
        show={show}
        onHide={onHide}
        aria-labelledby=" example-modal-sizes-title-lg"
        className="systemModal mettingModal bg-black/50"
        contentClassName="scroll p-0"
      >
        <Modal.Header className="w-full">
          <div className="w-full flex justify-between">
            <p className="text-xl text-mainText">تعديل بيانات الاجتماع</p>
            <Image
              src="/Rejected.svg"
              alt="close modal button"
              className="pointer"
              onClick={onHide}
            />
          </div>
        </Modal.Header>
        {children}
      </Modal>
    );
  };

export default EditDeleteMeetingWrapper