import React from "react";
import { Button } from "react-bootstrap";

const SubmitButton = ({ children }) => {
  return (
    <Button
      type="submit"
      className="text-white text-lg mt-3 w-[379.646px] h-[48.987px] !bg-[#EFAA1F] hover:!bg-[#EFAA20] !border-trasnparent shadow-lg shadow-neutral-600 rounded-lg"
    >
      {children}
    </Button>
  );
};

export default SubmitButton;
