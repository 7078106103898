import { useQuery, useMutation, useQueryClient } from "react-query";
import {
  addReminder,
  deleteReminder,
  getAllReminder,
  getReminder,
  getUserReminderSelect,
  updateReminder,
} from "../../../../helper/fetchers/reminder";

export const useGetAllReminders = (params) => {
  const query = useQuery(["reminder", params], () => getAllReminder(params));

  return query;
};
export const useGetReminders = (id) => {
  const query = useQuery(["reminder", id], () => getReminder(id), {
    enabled: !!id,
  });
  return query;
};
export const useGetUserReminderSelect = () => {
  const query = useQuery("user-reminder", getUserReminderSelect);
  return query;
};

export const useAddReminders = (onSuccess, onError) => {
  const queryClient = useQueryClient();
  return useMutation((data) => addReminder(data), {
    onSuccess: (data) => {
      queryClient.invalidateQueries("reminder");
      onSuccess(data);
    },
    onError: (error) => {
      onError(error);
      // Handle error
    },
  });
};
export const useUpdateReminders = (onSuccess, onError, id) => {
  const queryClient = useQueryClient();
  return useMutation((data) => updateReminder(id, data), {
    onSuccess: () => {
      queryClient.invalidateQueries("reminder");
      onSuccess();
    },
    onError: (error) => {
      onError(error);
    },
  });
};
export const useDeleteReminders = (onSuccess, onError) => {
  const queryClient = useQueryClient();
  return useMutation(deleteReminder, {
    onSuccess: () => {
      onSuccess();
      queryClient.invalidateQueries("reminder");
    },
    onError: (error) => {
      onError();
      // Handle error
    },
  });
};
