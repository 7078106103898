import { NavLink, useNavigate } from "react-router-dom";
import Image from "../../../Image";
import { Divider } from "./Divider";
import { useContext, useEffect, useRef, useState } from "react";
import { UserProvider } from "../../../../Context/userProvider";
import { useGetProfileProject } from "../../../../hooks/fetchers/profileProjects";
import "../AsideBar.css";
import { getUserProfile } from "../../../../helper/fetchers/Profie";
import { toast } from "react-toastify";
import {useGetUserProfile} from "../../../../hooks/fetchers/Users"
import { Badge } from "@mui/material";
import { MenuContainer } from "./MenuContainer";
export const ProfileMenu = ({ show, toggleMenu }) => {
    const navigate = useNavigate();
    const { logOut } = useContext(UserProvider);
    const { data } = useGetProfileProject();
    const [lang, setLang] = useState("ar");
    const [showLangMenu, setShowLangMenu] = useState(false);
    const handlelang = () => {
      setShowLangMenu(!showLangMenu);
    };
    const handleChangeLang = () => {
      if (lang === "ar") {
        setLang("en");
      } else {
        setLang("ar");
      }
    };
    const [user, setUser] = useState();
    const {
      data:userData,
      isLoading: getUserIsLoading,
      isError: getUserIsError,
    } = useGetUserProfile();

    // const getUser = async () => {
    //   try {
    //     const { data } = await getUserProfile();
    //     if (data?.success) {
    //       setUser(data.user);
    //     } else {
    //     }
    //   } catch (error) {
    //     toast.error(error?.response?.data?.message);
    //   }
    // };
  
    // useEffect(() => {
    //   getUser();
    // }, []);
  
    const menuRef = useRef(null);
    // useEffect(() => {
    //   const handleOutsideClick = (event) => {
    //     if (menuRef.current && !menuRef.current.contains(event.target)) {
    //       toggleMenu();
    //     }
    //   };
    //   if (show) {
    //     document.addEventListener('click', handleOutsideClick);
    //   }
    //   return () => {
    //     document.removeEventListener('click', handleOutsideClick);
    //   };
    // }, [menuRef,show,toggleMenu]);
    // console.log(menuRef);
    return show ? (
      <MenuContainer ref={menuRef} toggleMenu={toggleMenu}>
        <div className="mx-2 flex gap-2">
          {/* should be user img */}
          <Image
            src={userData?.data?.user?.image}
            alt="user img"
            className={"w-[51px] h-[51px] rounded-[50%]"}
            onClick={() => {
              navigate("/Profile");
              toggleMenu();
            }}
          />
          <div className="flex flex-col">
            <p className="text-mainText text-base">{userData?.data?.user?.firstName} </p>
            <p className="text-[#EFAA20] text-xs"> {userData?.data?.user?.role} </p>
            <span className="text-[#565674] text-xs"> {userData?.data?.user?.email}</span>
          </div>
        </div>
        <Divider />
        <div onClick={toggleMenu} className="mt-2 mb-3 mx-2">
          <NavLink className="w-full" to="/Profile">
            <div>
              <p className="text-sm text-mainText">صفحتى الشخصية</p>
            </div>
          </NavLink>
        </div>
        {/* "/System/Projects/index" */}
        <NavLink
          to={
            data?.projectNumber === 50
              ? "/System/tasks/plans"
              : "/System/tasks/tasks"
          }
          onClick={toggleMenu}
          className="mt-2  flex justify-between items-center pointer h-[33px] mb-3 mx-2"
        >
          <p className=" text-sm text-mainText">
            {data?.projectNumber === 50 ? "مشاريعي" : "مهامى"}
          </p>
  
          <Badge badgeContent={data?.projects || 0} color="error"></Badge>
        </NavLink>
  
        <div onClick={toggleMenu} className="mt-2 pointer h-[33px] mb-3 mx-2">
          <NavLink
            className="flex justify-between items-center"
            to="/System/Chat/index"
          >
            <p className="text-sm text-mainText"> محادثاتى</p>
            {/* <Badge badgeContent={3} color="error"></Badge> */}
          </NavLink>
        </div>
  
        <div className="mt-2 pointer relative h-[33px] mb-3 mx-1 flex items-center justify-between">
          <p className="text-mainText">اللغة </p>
  
          {lang === "ar" ? (
            <div
              // onClick={handlelang}
              dir="rtl"
              className="w-full my-3 mx-2  flex   justify-end gap-3 "
            >
              <p className="text-mainText">العربية (KSA)</p>
              <Image
                src="/saudiFlagicon.png"
                alt="sauida flag"
                className={"rounded-[50%]  w-[21.26px] h-[21.26px] "}
              />
            </div>
          ) : (
            <div
              onClick={handlelang}
              dir="rtl"
              className="w-full my-3 mx-2  flex   justify-end gap-3 "
            >
              <p className="text-mainText"> English (USA)</p>
              <Image
                src="/usa.png"
                alt="usa flag"
                className={"rounded-[50%]  w-[21.26px] h-[21.26px] "}
              />
            </div>
          )}
  
          <div
            className={`lang-meu absolute p-3  border !border-[#2B2B40] transition-all ease-in-out duration-500 right-[110%] top-[100%] w-[0px] h-[0] opacity-0  bg-mainItems rounded-[14px]
              ${showLangMenu && "!w-[172px] !h-[118px] !opacity-100"}
              `}
          >
            <div
              onClick={() => {
                setLang("ar");
                setShowLangMenu(false);
              }}
              dir="rtl"
              className="w-full my-3 mx-2  flex   justify-end gap-3 "
            >
              <p className="text-mainText">العربية (KSA)</p>
              <Image
                src="/saudiFlagicon.png"
                alt="sauida flag"
                className={"rounded-[50%]  w-[21.26px] h-[21.26px] "}
              />
            </div>
            <div
              onClick={() => {
                setLang("en");
                setShowLangMenu(false);
              }}
              dir="rtl"
              className="w-full my-3 mx-2  flex   justify-end gap-3 "
            >
              <p className="text-mainText"> English (USA)</p>
              <Image
                src="/usa.png"
                alt="usa flag"
                className={"rounded-[50%]  w-[21.26px] h-[21.26px] "}
              />
            </div>
          </div>
        </div>
  
        <Divider />
        <div
          onClick={() => {
            logOut();
            toggleMenu();
          }}
          className="mt-2 pointer h-[33px] mx-2"
        >
          <p className="text-sm text-mainText"> تسجيل الخروج</p>
        </div>
      </MenuContainer>
    ) : null;
  };