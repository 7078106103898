import React, { createContext, useContext, useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import DownloadButton from "../../../../../../Components/Buttons/DownloadButton";
// import { Box, Tab, Tabs } from "@mui/material";

import "../../../../../../Components/System/Main/MainSystem.css";

import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import {
  useGetAllUsersReports,
  useGetUserStatisticsDepartmentsReports,
  useGetUserStatisticsReports,
  useGetUserStatisticsReportsEgy,
  useGetUserStatisticsReportsSaudi,
} from "../../../../../../hooks/fetchers/Users";
import { Button, Modal, NavDropdown, Spinner } from "react-bootstrap";
import { FormModal } from "../../../../../System/PlanModel/components/FormModal";

import useExportExcel from "../../../../../../hooks/useExportExcel";
import ExcellModall from "../../../../../../Components/Modals/ExcellModall";
import ApexColumn from "../components/ApexColumn";
import { FilterIcon } from "../../../../../../utiltis/Icons";
import { GrTransaction } from "react-icons/gr";
import useModal from "../../../../../../hooks/useModal";
import { useForm } from "react-hook-form";
import moment from "moment";
import { InputLabel } from "../../../../../System/PlanModel/components/InputLabel";
import { CustomDatePicker } from "../../../../../../Components/FormUi";
import AllDeprtmentSlider from "../../../components/AllDeprtmentSlider";
import CountryPieChart from "../../../components/CountryPieChart";
import { TiExport } from "react-icons/ti";
import { ImPrinter } from "react-icons/im";
import PrintUsersDetailsFile from "../../../components/PrintUsersDetailsFile";

const MainUsersReportsPage = () => {
  const [range, setRange] = useState({});
  const [value, setValue] = React.useState(0);
  const { data, isLoading, isError } = useGetUserStatisticsDepartmentsReports({
    range,
  });
  const { data:statisticsEgyData, isLoading:statisticsEgyIsLoading, isError:statisticsEgyIsError } = useGetUserStatisticsReportsEgy();
  const { data:statisticsSaudiData, isLoading:statisticsSaudiIsLoading, isError:statisticsSaudiIsError } = useGetUserStatisticsReportsSaudi();
  const { data: users } = useGetAllUsersReports({range});
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const { open, handleOpenModal, handleCloseModal } = useModal();
  const { loading, allKeys, excelData, fileUrl, handleExport, resetExcelData } =
    useExportExcel();
  const {
    open: openPdf,
    handleOpenModal: handleOpenPdfModal,
    handleCloseModal: handleClosePdfModal,
  } = useModal();
  return (
    <div className="p-2">
      {!loading && excelData && (
        <ExcellModall
          onHide={resetExcelData}
          fileUrl={fileUrl}
          keys={allKeys}
          data={excelData}
        />
      )}

      <FilterModal
        show={open}
        onHide={handleCloseModal}
        onSave={(data) => {
          setRange(`${data.startDate},${data.endDate}`);
          // console.log(`${data.startDate}, ${data.endDate}`);
        }}
      />
      <PrintUsersDetailsFile
        show={openPdf}
        onHide={handleClosePdfModal}
        data={{
          users:users?.users,
          total:users?.count,
          egy:statisticsEgyData,
          ksa:statisticsSaudiData,
          range: range ? {
            startDate: range,
            endDate: range,
          } : null
        }}
      />
      <div className="flex justify-end items-center p-3">
        <div className="flex items-center gap-2">
          <DownloadButton
            onClick={() => handleExport("system-reports/users/excel")}
          >
            <div className="flex items-center gap-1">
              <span>تصدير Excel</span>
              <TiExport />
            </div>
          </DownloadButton>
          <DownloadButton onClick={handleOpenPdfModal}>
            <div className="flex items-center gap-1">
              <span>طباعة</span>
              <ImPrinter />
            </div>
          </DownloadButton>

          <div className="">
            <NavDropdown
              title={<FilterIcon />}
              className="fs-5"
              id="collapsible-nav-dropdown"
            >
              <NavDropdown.Item
                onClick={handleOpenModal}
                className="flex items-center gap-1 !bg-white !text-black overflow-hidden rounded-lg"
              >
                <GrTransaction color="black" />
                <span className="!text-black">من...الي</span>
              </NavDropdown.Item>
            </NavDropdown>
          </div>
        </div>
      </div>
      <TabProvider>
        <Tabs>
          <Tab label="مصر" />
          <Tab label="كل المستخدمين" />
          <Tab label="السعودية" />
        </Tabs>
        <TabPanels>
          <TabPanel index={0}>
            <AllStatisticsEgy />
          </TabPanel>
          <TabPanel index={1}>
            <AllStatistics />
          </TabPanel>
          <TabPanel index={2}>
            <AllStatisticsSaudi />
          </TabPanel>
        </TabPanels>
      </TabProvider>

      <FormModal title={"كل المستخدمين"}>
      <div className="w-full flex justify-center items-center">
        {isLoading ? <Spinner /> : null}
        {isError ? <p className="text-red-400 text-lg">يوجد خطا ما</p> : null}
        </div>
        {!isLoading && !isError ? (
          <>
            <ApexColumn data={data?.transformedData} />
            {/* <AllUsersColumnChart data={data?.transformedData} /> */}
            <AllDeprtmentSlider chartData={data?.transformedData} />
          </>
        ) : null}
      </FormModal>
    </div>
  );
};

const AllStatistics = () => {
  const { data, isLoading, isError } = useGetUserStatisticsReports();
  // console.log("data", data);

  return (
    <div>
      <div className="w-full flex justify-center items-center">
      {isLoading ? <Spinner /> : null}
      {isError ? <p className="text-red-400 text-lg">يوجد خطا ما</p> : null}
      </div>
      {!isLoading && !isError ? (
        <CountryPieChart data={data?.transformedData} />
      ) : null}
    </div>
  );
};
const AllStatisticsEgy = () => {
  const { data, isLoading, isError } = useGetUserStatisticsReportsEgy();
  return (
    <div>
      <div className="w-full flex justify-center items-center">
      {isLoading ? <Spinner /> : null}
      {isError ? <p className="text-red-400 text-lg">يوجد خطا ما</p> : null}
      </div>
      {!isLoading && !isError ? (
        <CountryPieChart data={data?.transformedData} />
      ) : null}
    </div>
  );
};
const AllStatisticsSaudi = () => {
  const { data, isLoading, isError } = useGetUserStatisticsReportsSaudi();
  return (
    <div>
      <div className="w-full flex justify-center items-center">
      {isLoading ? <Spinner /> : null}
      {isError ? <p className="text-red-400 text-lg">يوجد خطا ما</p> : null}
      </div>
      {!isLoading && !isError ? (
        <CountryPieChart data={data?.transformedData} />
      ) : null}
    </div>
  );
};

{
  /* <ApexColumn data={data?.transformedData} /> */
}
const TabContext = createContext(null);
const TabProvider = ({ children }) => {
  const [tabIndex, setTabIndex] = useState(1);
  const [tabsLength, setTabsLength] = useState(null);
  const handleNextStep = () => {
    setTabIndex((prev) => {
      if (prev >= 2) return 2;
      return prev + 1;
    });
  };
  const handlePrevStep = () => {
    setTabIndex((prev) => {
      if (prev <= 0) return 0;
      return prev - 1;
    });
  };
  // console.log("tabIndex", tabIndex);
  return (
    <TabContext.Provider
      value={{
        tabIndex,
        handleNextStep,
        handlePrevStep,
        tabsLength,
        setTabsLength,
      }}
    >
      {children}
    </TabContext.Provider>
  );
};

const useTabCtx = () => {
  const ctx = useContext(TabContext);
  if (!ctx) {
    throw Error("Use inside context ");
  }
  return ctx;
};

const Tabs = ({ children }) => {
  const {
    tabIndex,
    handleNextStep,
    handlePrevStep,
    tabsLength,
    setTabsLength,
  } = useTabCtx();
  useEffect(() => {
    setTabsLength(children?.length);
  }, []);
  // console.log("tabsLength", tabsLength);
  return (
    <div className="flex justify-center items-center gap-4" dir="ltr">
      {tabIndex !== 0 ? (
        <button className="" onClick={handlePrevStep}>
          <IoIosArrowBack size={25} color="#EFAA20" />
        </button>
      ) : null}

      {children[tabIndex]}

      {tabIndex < tabsLength - 1 ? (
        <button className="" onClick={handleNextStep}>
          <IoIosArrowForward size={25} color="#EFAA20" />
        </button>
      ) : null}
    </div>
  );
};
const Tab = ({ label = "" }) => {
  return <p> {label}</p>;
};
const TabPanels = ({ children }) => {
  const { tabIndex, handleNextStep, handlePrevStep } = useTabCtx();
  // const selectedChild = Children.map((child,index)=> )
  // console.log("children", children[tabIndex]);
  return <>{children[tabIndex]}</>;
};
const TabPanel = ({ children, index }) => {
  const { tabIndex, handleNextStep, handlePrevStep } = useTabCtx();
  return <div>{children}</div>;
};

export default MainUsersReportsPage;

const FilterModal = ({ show, onHide, onSave }) => {
  const { handleSubmit, control } = useForm();
  const manipulateData = (data) => {
    data.startDate = moment(data.startDate).format("").split("T")[0];
    data.endDate = moment(data.endDate).format("").split("T")[0];
    return data;
  };
  const onSubmit = (data) => {
    data = manipulateData(data);
    onSave(data);
    onHide();
  };
  return (
    <Modal
      show={show}
      onHide={onHide}
      className="systemModal bg-black/50 "
      contentClassName="border !border-[#EFAA20] rounded-3xl p-0"
      centered
    >
      <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header className="w-full !justify-between border-none">
          <Modal.Title className="text-mainText">{"تصنيف"}</Modal.Title>
          <Button
            className="p-0 bg-transparent hover:bg-transparent"
            onClick={onHide}
          >
            <img src="/Rejected.svg" alt="" />
          </Button>
        </Modal.Header>
        <Modal.Body className="w-full space-y-2">
          <div className="flex flex-col gap-2">
            <InputLabel label={"من تاريخ"} />
            <CustomDatePicker
              control={control}
              name="startDate"
              rules={{
                required: "من فضلك ادخل تاريخ البداية ",
              }}
            />
          </div>
          <div className="flex flex-col gap-2">
            <InputLabel label={"الي تاريخ"} />
            <CustomDatePicker
              control={control}
              name="endDate"
              rules={{
                required: "من فضلك ادخل تاريخ النهاية ",
              }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer className="w-full justify-center border-none">
          <Button type="submit" className="!bg-[#EFAA20]">
            {"حفظ"}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};
