import { useEffect, useRef, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import useOpenNotifications from "../../Components/Notifications/useOpenNotifications";
import { socket, sockietOnMessage } from "../../utiltis/SockietHelper";
import { toast, ToastContainer } from "react-toastify";
import NotificationCard from "../System/Notifications/NotificationCard";
import NotificationSound from "../../assets/sounds/message.mp3";
import MessageSound from "../../assets/sounds/chatMessage.mp3";

import Cookies from "js-cookie";
import { useQueryClient } from "react-query";
import MessageNotificationCard from "../../Components/Chat/MessageNotificationCard/MessageNotificationCard";
import { useSelector } from "react-redux";
import AxiosInterceptor from "../../helper/AxiosInterceptor";
import ReminderPopup from "../System/reminderPage/components/ReminderPopup";

const socketMessages = [
  "createPlan",
  "confirmPlan",
  "stopPlan",
  "pausePlan",
  "rejectPlan",
  "deletePlan",
  "rejectTask",
  "approveTask",
  "resendTask",
  "reassignTask",
  "deleteTask",
  "completePlan",
  "createEvent",
  "createTask",
  "acceptTask",
  "confirmTask",
  "createFinancial",
  "createInvoice",
  "notify",
  // "reminder",
  "comment",
  "replay",
  "likeComment",
  "unlikeComment",
];

export default function RootLayout() {
  const chatNotificationSelector = useSelector(
    (state) => state.chatNotificationPlace.inChatPage
  );
  const selectedUserSelector = useSelector(
    (state) => state.chatNotificationPlace.selectedUser
  );
  const queryClient = useQueryClient();
  const containerRef = useRef();
  const [notificationData, setNotificationData] = useState(null);
  // console.log("notificationData", notificationData);
  const [messageData, setMessageData] = useState(null);
  const [reminderData, setReminderData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const socketId = Cookies.get("socketId") || null;
  const { open, handleClose } = useOpenNotifications();
  // const { refetch } = useGetNotifications();
  const { pathname } = useLocation();

  useEffect(() => {
    // Socket connection
    socket.on("connect", () => {
      // console.log("socket connected success");
      // console.log("socket connected:", socket.connected); // true
      // console.log("socket disconnected:", socket.disconnected);
      // console.log("socket id:", socket.id);
    });

    socketMessages.forEach((message) => {
      sockietOnMessage(message, (data) => {
        console.log("data",data);
        setNotificationData(data);
      });
    });
    if (selectedUserSelector !== messageData?.sender?.id) {
      sockietOnMessage("chat", (data) => {
        // console.log("Message notification: ",data);
        setMessageData(data);
      });
    }
    sockietOnMessage("reminder", (data) => {
      console.log("reminder notification: ",data);
      setReminderData(data);
      setShowModal(true)
      const sound = new Audio(MessageSound);
      containerRef.current.focus();
      sound.play();
    });

    // sockietOnMessage("reminder", (data) => {
    //   // console.log("Message notification: ",data);
    //   console.log("reminder notification");
    //   setReminderData(data);
    //   setShowModal(true);
    // });
    if (socketId && socketId !== socket.id) {
      const token = Cookies.get("accessToken");
      socket.auth = { token };
      socket.connect();
      Cookies.set("socketId", socket.id);
    }
  }, [socket.id]);


  
  useEffect(() => {
    if (notificationData) {
      const resolveWithSomeData = new Promise((resolve) => {
        setTimeout(() => resolve("world"), 0);

        // console.log("notificationData", notificationData);
      });
      toast.promise(
        resolveWithSomeData,
        {
          success: {
            render(options) {
              return <NotificationCard data={notificationData} />;
            },
            icon: false,
          },
        },
        {
          // position: "top-left",
          hideProgressBar: true,
          className: "min-w-[500px] rounded-[9px]",
          bodyClassName: " ",
          // autoClose: 900002,
          rtl: true,
        }
      );
      const sound = new Audio(NotificationSound);
      containerRef.current.focus();
      sound.play();
      // refetch();
      queryClient.invalidateQueries("notifications");
    }
  }, [notificationData]);

  useEffect(() => {
    if (messageData && selectedUserSelector !== messageData?.sender?.id) {
      const resolveWithSomeData = new Promise((resolve) =>
        setTimeout(() => resolve("world"), 0)
      );
      toast.promise(
        resolveWithSomeData,
        {
          success: {
            render(_) {
              return <MessageNotificationCard data={messageData} />;
            },
            icon: false,
          },
        },
        {
          position: "top-left",
          hideProgressBar: true,
          className: "w-fit  !bg-mainItems rounded-[9px]",
          bodyClassName: "w-fit !bg-mainItems",

          rtl: true,
        }
      );
      const sound = new Audio(MessageSound);
      containerRef.current.focus();
      sound.play();
      setMessageData(null);
    }
    return () => {
      setMessageData(null);
    };
  }, [messageData, selectedUserSelector, pathname]);

  return (
    <div ref={containerRef}>
      {/* <MessageNotificationCard/> */}
      <ToastContainer />
      <AxiosInterceptor>
        {reminderData ? (
          <ReminderPopup
            data={reminderData}
            onHide={() => setShowModal(false)}
            show={showModal}
          />
        ) : null}
        <Outlet />
      </AxiosInterceptor>
    </div>
  );
}
