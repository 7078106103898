import React, { useCallback, useEffect, useRef, useState } from "react";

import { Button, Form, Image, Modal } from "react-bootstrap";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import ChooseDepartmentComponent from "../ChooseDepartmentComponent/ChooseDepartmentComponent";
import { FaCheck } from "react-icons/fa6";
import { Controller, useController, useForm } from "react-hook-form";
import { InputLabel } from "../../../../Pages/System/PlanModel/components/InputLabel";
import Select from "../../../FormHandler/Select";
import moment from "moment";
import SuccessfullModal from "../../../Modals/SuccessfullModal";
import Progress from "../../../Progress";
import EmployeeCard from "../Card";
import LoadingPage from "../../../feedback/LoadingPage";
import { useAddMeeting } from "../../../../hooks/fetchers/Meetings";
import useSuccessfullModal from "../../../Modals/hooks/useSuccessfullModal";
import useModal from "../hooks/useModal";
import { useGetAllUsers } from "../../../../hooks/fetchers/Users";
import { ErrorMessage } from "@hookform/error-message";
import {
  CustomDatePicker,
  CustomInput,
  CustomTimePicker,
} from "../../../FormUi";
import CustomTextArea from "../../../FormUi/CustomTextArea";

const meetingCountries = [
  {
    value: 1, // 1
    label: "السعودية",
  },
  {
    value: 2, // 2
    label: "مصر",
  },
];

const meetingType = [
  {
    value: 1, // 1
    label: "شامل",
  },
  {
    value: 2, // 2
    label: "مع شخص",
  },
];

export const AddMeeting = ({ view, onClose }) => {
  const {
    handleShowSuccess,
    handleShowError,
    handleCloseSuccess,
    handleCloseError,
    showSuccess,
    showError,
  } = useSuccessfullModal();
  const { mutate, isLoading, isError, error } = useAddMeeting(() => {
    handleShowSuccess();
    reset();
  }, handleShowError);

  const {
    control,
    watch,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      typeMeeting: 0,
    },
  });
  const { show, handleOpenModal, handleCloseModal } = useModal();

  let [meetingPlace, setMeetingPlace] = useState("10:00");
  const formRef = useRef();
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [message, setMessage] = useState("حدث خطأ ما");

  const manipulateData = (data) => {
    data.startDate = moment(data.startDate).format("YYYY-MM-DD");
    data.start = moment(data.start).format("hh:mm A");
    data.end = moment(data.end).format("hh:mm A");
    return data;
  };


  const onSubmit = (data) => {
    data = manipulateData(data);
    if (data.meetingType === 2) {
      data.employeId = selectedUsers?.map((user) => user._id);
    }
    mutate(data);
  };
  
  useEffect(() => {
    if (error?.response?.data?.validationErrors) {
      setMessage(error?.response?.data?.validationErrors);
    } else {
      setMessage(error?.response?.data?.message);
    }
  }, [isError, error]);

  return (
    <div>
      {isLoading ? <LoadingPage /> : null}
      <SuccessfullModal
        show={showSuccess}
        message={"تم اضافه الاجتماع بنجاح"}
        handleClose={() => {
          onClose();
          handleCloseSuccess();
          formRef.current.reset();
          handleCloseModal();
          setMeetingPlace("10:00");
        }}
      />
      <SuccessfullModal
        show={showError}
        message={message}
        handleClose={handleCloseError}
        status="error"
      />
      <ChooseDepartmentComponent
        show={show}
        onHide={handleCloseModal}
        selectedUsers={selectedUsers}
        setSelectedUsers={setSelectedUsers}
      />

      <AddMeetingWrapper
        show={view}
        onHide={() => {
          onClose();
          reset();
          setSelectedUsers([]);
        }}
      >
        <Form
          ref={formRef}
          onSubmit={handleSubmit(onSubmit)}
          encType="multipart/form-data"
          className="row my-4 date-input w-100 mx-auto p-3"
        >
          <div className="col-md-12 mb-4">
            <div className="row">
              <div className="col-md-6">
                <InputLabel label={"بلد الاجتماع"} mandatory={true} />
                <Controller
                  control={control}
                  name={`country`}
                  rules={{
                    required: "من فضلك اختر بلد الاجتماع",
                  }}
                  render={({ field: { onChange, onBlur, value = "" } }) => (
                    <Select
                      fullWidth
                      value={value?.value}
                      onChange={(val) => onChange(val?.value)}
                      onBlur={onBlur}
                      placeholder="اختر بلد الاجتماع"
                      options={meetingCountries}
                    />
                  )}
                />
                <ErrorMessage
                  errors={errors}
                  name="country"
                  render={({ message }) => (
                    <p className="text-[#FF0000]">{message}</p>
                  )}
                />
              </div>
            </div>
          </div>
          <div className="col-md-6 mb-4">
            <InputLabel label={"نوع الاجتماع"} mandatory={true} />
            <Controller
              control={control}
              name={`meetingType`}
              rules={{
                required: "من فضلك اختر نوع الاجتماع",
              }}
              render={({ field: { onChange, onBlur, value = "" } }) => (
                <Select
                  fullWidth
                  value={value?.value}
                  onChange={(val) => onChange(val?.value)}
                  onBlur={onBlur}
                  placeholder="اختر نوع الاجتماع"
                  options={meetingType}
                />
              )}
            />
            <ErrorMessage
              errors={errors}
              name="meetingType"
              render={({ message }) => (
                <p className="text-[#FF0000]">{message}</p>
              )}
            />
          </div>

          {watch("meetingType") === 2 && (
            <div className="col-md-6 d-flex align-items-end  justify-content-center mb-4">
              <Button
                type="button"
                className="w-100 ChooseDeprtmant  bg- [#2B2B40]"
                onClick={handleOpenModal}
              >
                اختار الشخص
              </Button>
            </div>
          )}
          {watch("meetingType") === 2 && selectedUsers?.length > 0 && (
            <div className="mb-4">
              <p className="font-semibold text-base mb-3 text-mainText text-center">
                المستخدمين
              </p>

              <div className="grid grid-cols-2 gap-3">
                {selectedUsers?.map((emp, index) => (
                  <EmployeeCard
                    title={`${emp?.firstName} ${emp?.lastName}`}
                    desc={`${emp?.role} ${emp?.country}`}
                    img={emp?.image}
                    key={index}
                  />
                ))}
              </div>
            </div>
          )}
          <div className="col-md-12 mb-4">
            <Form.Group>
              <InputLabel label={"الاجتماع عن"} />
              <CustomTextArea
                control={control}
                name="descraption"
                placeholder={"اكتب ملاحظات .................................."}
              />
            </Form.Group>
          </div>
          <div className="col-md-6 mb-4">
            <FormControl>
              <FormLabel className="text-mainText ">مكان الاجتماع</FormLabel>
              <Controller
                name="typeMeeting"
                control={control}
                render={({ field: { onChange, onBlur, value = "" } }) => (
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                    className="custom-radio"
                  >
                    <FormControlLabel
                      onChange={(e) => {
                        setMeetingPlace(e.target.value);

                        onChange(1);
                      }}
                      className="text-mainText mt-2 flex gap-3 "
                      value="online"
                      control={
                        <label
                          onClick={() => {
                            setMeetingPlace("online");
                            onChange(1);
                          }}
                          htmlFor="online"
                          className={`w-5 h-5 rounded-[5px] bg-mainItems border ${
                            meetingPlace === "online" ? "!border-[#EFAA20]" : ""
                          }`}
                        >
                          {watch("typeMeeting") === 1 ? <FaCheck /> : null}
                          <Radio sx={{ display: "none" }} />
                        </label>
                      }
                      label="online"
                    />
                    <FormControlLabel
                      className="text-mainText flex gap-3 my-2"
                      onChange={(e) => {
                        setMeetingPlace(e.target.value);
                        onChange(0);
                      }}
                      value="offline"
                      control={
                        <label
                          onClick={() => {
                            setMeetingPlace("offline");
                            onChange(0);
                          }}
                          htmlFor="offline"
                          className={`w-5 h-5 rounded-[5px] bg-mainItems border ${
                            meetingPlace === "offline"
                              ? "!border-[#EFAA20]"
                              : ""
                          }`}
                        >
                          {watch("typeMeeting") === 0 ? <FaCheck /> : null}
                          <Radio id="offline" sx={{ display: "none" }} />
                        </label>
                      }
                      label="offline"
                    />
                  </RadioGroup>
                )}
              />
            </FormControl>
          </div>
          <div className="col-md-6  mb-4">
            {/* <div className="!w-1/2"> */}
            <Form.Group
              className="licenseDate-container flex flex-col"
              controlId="licenseDate"
            >
              <InputLabel
                size={16}
                label={"تاريخ الاجتماع"}
                className={"mb-1"}
              />
              <CustomDatePicker
                control={control}
                name={"startDate"}
                rules={{
                  required: "يجب اختيار التاريخ",
                }}
              />
              <ErrorMessage
                name="startDate"
                errors={errors}
                render={({ message }) => (
                  <span className="text-red-500">{message}</span>
                )}
              />
            </Form.Group>
            {/* </div> */}
          </div>
          {meetingPlace === "online" && (
            <div className="col-md-12 meetingLink flex flex-col  mb-4">
              <InputLabel label={"لينك الاجتماع"} mandatory={true} />
              <CustomInput
                control={control}
                name={"meetingLink"}
                rules={{
                  required: "يجب إدخال لينك الاجتماع",
                }}
              />
              <ErrorMessage
                errors={errors}
                name="meetingLink"
                render={({ message }) => (
                  <p className="text-[#FF0000]">{message}</p>
                )}
              />
            </div>
          )}
          <div dir="rtl" className="col-md-6 mb-2 ">
            <div className="flex justify-end">
              <Form.Group
                className="licenseDate-container w-100 flex flex-col"
                controlId="licenseDate"
              >
                <InputLabel label={"توقيت بدا الاجتماع"} />
                <CustomTimePicker
                  control={control}
                  name="start"
                  rules={{
                    required: "من فضلك اكتب وقت بدا الاجتماع",
                  }}
                />
                <ErrorMessage
                  name="start"
                  errors={errors}
                  render={({ message }) => (
                    <span className="text-red-500">{message}</span>
                  )}
                />
              </Form.Group>
            </div>
          </div>
          <div dir="rtl" className="col-md-6 mb-2">
            <div>
              <Form.Group
                className="licenseDate-container w-100 flex flex-col"
                controlId="licenseDate"
              >
                <InputLabel label={"توقيت نهاية الاجتماع"} />
                <CustomTimePicker
                  control={control}
                  name="end"
                  rules={{
                    required: "من فضلك اكتب وقت نهاية الاجتماع",
                  }}
                />
                <ErrorMessage
                  name="end"
                  errors={errors}
                  render={({ message }) => (
                    <span className="text-red-500">{message}</span>
                  )}
                />
              </Form.Group>
            </div>
          </div>

          <div className="d-flex justify-content-center">
            <SaveBtn isLoading={isLoading} />
          </div>
        </Form>
      </AddMeetingWrapper>
    </div>
  );
};

const AddMeetingWrapper = ({ children, show, onHide }) => {
  return (
    <Modal
      size="lg"
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
      className="systemModal mettingModal bg-black/50"
      contentClassName="scroll p-0"
    >
      <Modal.Header className="w-full">
        <div className="w-full flex justify-between">
          <p className="text-xl text-mainText">اضافة اجتماع جديد</p>
          <Image
            src="/Rejected.svg"
            alt="close modal button"
            className="pointer"
            onClick={onHide}
          />
        </div>
      </Modal.Header>

      {children}
    </Modal>
  );
};

const SaveBtn = ({ isLoading }) => {
  return (
    <button
      type="submit"
      className="  mt-4 sumbmitAddUpdateUser border-0 disabled "
    >
      {isLoading ? <Progress isSmall /> : " حفظ"}
    </button>
  );
};
