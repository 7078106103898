import React, { useCallback, useEffect } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import "./EditProject.css";
import style from "./EditProject.module.css";
import { useState } from "react";
import Select from "../../../FormHandler/Select";
import Input from "../../../FormHandler/Input";
import { UseInput, UseSelect } from "../../../../hooks";
import DatePicker from "react-datepicker";
import {
  getAllBatchNameList, updateAllProject
} from "../../../../helper/fetchers/Projects";
import { toast } from "react-toastify";
import AddAttachment from "../../AddAttachment";
import Progress from "../../../Progress";
import SuccessfullModal from "../../../Modals/SuccessfullModal";
import ShowFiles from "../../../ShowFiles";
import ShowLocalFiles from "../../../ShowLocalFiles";

const EditProject = ({
  editProject,
  setEditProject,
  setConfirmUpdate,
  selectedItem,
  // getAllProject,
}) => {
  const [attachment, setAttachment] = useState(null);
  const [paperProject, setPaperProject] = useState(null);
  const [openAddAttachemnt, setOpenAddAttachemnt] = useState(false);
  const [projectsNotes, setProjectsNotes] = useState(false);
  const [loading, setLoading] = useState(false);
  // console.log(selectedItem, "selectedItem");
  // payment details
  console.log("selectedItem:", selectedItem);
  let totalAmount = UseInput(selectedItem?.amount, "positiveNumber", true);
  let paymentsNumber = UseInput(
    selectedItem?.batchCount,
    "positiveNumber",
    true
  );
  let discountAmount = UseInput(selectedItem?.discount, "", true);
  let initialNumber = UseInput(
    selectedItem?.depositAmount,
    "positiveNumber",
    true
  );

  let paymentstatus = UseSelect(selectedItem?.projectStatus, "Select", true);
  let paymentNotes = UseInput(selectedItem?.notesAmount, "", true);
  const [paymentDate, setPaymentDate] = useState(null);
  const [batchList, setBatchList] = useState([]);
  const [selectedBatch, setSelectedBatch] = useState(null);
  const [selcetedPaymentStatus, setSelectedPaymentStatus] = useState(null);

  const paymentStatusOptions = [
    {
      label: "تم الدفع",
      value: 1,
    },
    {
      label: "لم يتم الدفع",
      value: 0,
    },
  ];

  // #region All About Batch Name
  const getAllBatchName = useCallback(async () => {
    const data = await getAllBatchNameList();
    if (data.status === 200) {
      setBatchList(data?.data?.batches);
    }
  }, []);
  useEffect(() => {
    getAllBatchName();
  }, []);
  const MappedBatchNameList = batchList?.map((request) => ({
    value: request?._id,
    label: request?.name,
  }));
  // #endregion
  const oldProjectStatus = {
    label: selectedItem?.projectStatus === 1 ? "تم الدفع" : "لم يتم الدفع",
    value: selectedItem?.projectStatus,
  };
  const batch = {
    label: selectedItem?.batcheId?.name,
    value: selectedItem?.batcheId?._id,
  };
  const getProjectDetails = () => {
    if (selectedItem) {
      setPaymentDate(new Date(selectedItem?.createdDate));
      setSelectedBatch(batch);
      setSelectedPaymentStatus(oldProjectStatus);
      setProjectsNotes(selectedItem?.projectsNotes);
    }
  };
  useEffect(() => {
    getProjectDetails();
  }, [selectedItem]);

  const handleEditProject = (e) => {
    /// after editing Project
    e.preventDefault();
    handleUpdateProject();
    // setEditProject(false);
    // setConfirmUpdate(true);
  };
  const files = attachment ? [...attachment] : [];

  const [updateSuccess, setUpdateSuccess] = useState(false);

  const handleUpdateProject = async () => {
    setLoading(true);
    const formData = new FormData();
    // formData.append("requestId", selectedItem?.id);
    formData.append("amount", totalAmount.value);
    formData.append("discount", discountAmount.value || "0");
    formData.append("batcheId", selectedBatch.value);
    formData.append("notesProject", projectsNotes);
    formData.append("batchCount", paymentsNumber.value);
    formData.append("notesAmount", paymentNotes.value);
    formData.append("createdDate", paymentDate);
    formData.append("depositAmount", initialNumber.value);
    formData.append("batchStatus", selcetedPaymentStatus.value);

    if (attachment && attachment?.length > 0) {
      files.forEach((file) => {
        formData.append(`ProjectOffers`, file);
      });
    }
    if (paperProject && paperProject?.length > 0) {
      files.forEach((file) => {
        formData.append(`paperProject`, file);
      });
    }
    try {
      const { data } = await updateAllProject(selectedItem?.id, formData);
      if (data?.success) {
        setLoading(false);
        // toast.success("تم تعديل الصنف بنجاح");
        // handleClose(true);
        setUpdateSuccess(true);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error?.response?.data?.message);
    }
  };
  const handlePdfDownload = (pdfSrc) => {
    const anchor = document.createElement("a");
    anchor.href = pdfSrc;
    anchor.target = "_blank";
    anchor.download = "project_offer.pdf";

    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  };
  return (
    <>
      <div>
        {editProject && (
          <Modal
            size="lg"
            show={editProject}
            onHide={() => setEditProject(false)}
            aria-labelledby=" example-modal-sizes-title-lg"
            className="systemModal  editProject  "
          >
            <div
              className="p-1 mx-auto my-3 edit-header
         w-50"
            >
              <p className="golden   text-center">تعديل فى المشروع</p>
            </div>
            <fieldset className="fieldBorder  p-2 w-90 mx-auto  ">
              <legend className="text-center text-mainText">المرفقات</legend>
              <div className="row">
                <div className="col-md-3 flex items-center  justify-start gap-4 w-full">
                  <div
                    className={`addFileShape pointer my-3 bg-mainItems p-0  d-flex flex-column align-items-center justfiy-content-center`}
                  >
                    <AddAttachment
                      attachment={attachment}
                      setAttachment={setAttachment}
                      multi={true}
                    />
                  </div>
                  {attachment && (
                    <ShowLocalFiles files={Array.from(attachment)} />
                  )}

                  {selectedItem?.ProjectOffers && (
                    <div className="flex gap-3">
                      <ShowFiles files={selectedItem?.ProjectOffers} />
                    </div>
                  )}
                </div>
              </div>
            </fieldset>
            <fieldset className="fieldBorder  p-2 w-90 mx-auto  ">
              <legend className="text-center text-mainText">
                اوراق المشروع
              </legend>
              <div className="row">
                <div className="col-md-3 flex items-center  justify-start gap-4 w-full">
                  <div
                    className={`addFileShape pointer my-3 bg-mainItems p-0  d-flex flex-column align-items-center justfiy-content-center`}
                  >
                    <AddAttachment
                      attachment={paperProject}
                      setAttachment={setPaperProject}
                      multi={true}
                    />
                  </div>
                  {paperProject && (
                    <ShowLocalFiles files={Array.from(paperProject)} />
                  )}

                  {selectedItem?.ProjectOffers && (
                    <div className="flex gap-3">
                      <ShowFiles files={selectedItem?.paperProject} />
                    </div>
                  )}
                </div>
              </div>
            </fieldset>
            <fieldset className={` fieldBorder  w-90 mx-auto p-3 mt-4 `}>
              <legend className="text-center text-mainText">
                ارفاق الملاحظات
              </legend>

              <p className=" text-mainText">ملاحظات المشروع</p>
              <textarea
                onChange={(e) => {
                  setProjectsNotes(e.target.value);
                }}
                className="form-control mt-3"
                placeholder="اكتب ملاحظات المشروع"
                rows={5}
              />
            </fieldset>

            <fieldset className={` fieldBorder  w-90 mx-auto p-3 mt-4 `}>
              <legend className="text-center text-mainText">
                معلومات الدفع
              </legend>
              <Form onSubmit={handleEditProject}>
                <div className="row">
                  <div className="col-md-4 mb-4">
                    <Input
                      {...totalAmount.bind}
                      mandatory
                      className={`${style.searchOrder} w-100`}
                      label={"المبلغ الاجمالى"}
                    />
                  </div>
                  <div className="col-md-4 mb-4">
                    <Input
                      {...paymentsNumber.bind}
                      mandatory
                      className={`${style.searchOrder} w-100`}
                      label={"عدد الدفعات المتفق عليها "}
                    />
                  </div>
                  <div className="col-md-4 mb-4">
                    <Input
                      {...discountAmount.bind}
                      className={`${style.searchOrder} w-100`}
                      label={"قيمة الخصم "}
                      discount={true}
                    />
                  </div>
                  <div className="col-md-4 mb-4">
                    <Input
                      {...initialNumber.bind}
                      mandatory
                      className={`${style.searchOrder} w-100`}
                      label={"القيمه الافتتاحية "}
                    />
                  </div>

                  <div className="col-md-4 mb-4">
                    <Select
                      options={MappedBatchNameList}
                      mandatory
                      onChange={(e) => {
                        setSelectedBatch(e);
                      }}
                      value={selectedBatch}
                      className={`${style.searchOrder} w-100`}
                      label={" نوع الدفعة    "}
                    />
                  </div>
                  <div className="col-md-4 mb-4">
                    <Form.Group
                      className="licenseDate-container "
                      controlId="licenseDate"
                    >
                      <Form.Label className="d-flex gap-2 align-items-center text-mainText">
                        تاريخ الدفع
                      </Form.Label>

                      <DatePicker
                        selected={paymentDate}
                        placeholderText=" ادخل تاريخ الدفع "
                        onChange={(date) => setPaymentDate(date)}
                        dateFormat="dd-MM-yyyy"
                        className="w-100 form-control"
                      />
                    </Form.Group>
                  </div>

                  <div className="col-md-4 mb-4">
                    <Select
                      options={paymentStatusOptions}
                      value={selcetedPaymentStatus}
                      onChange={(selectedOption) =>
                        setSelectedPaymentStatus(selectedOption)
                      }
                      mandatory
                      className={`${style.searchOrder} w-100`}
                      placeholder="اختار الحالة"
                      label={"الحالة"}
                    />
                  </div>
                  <div className="col-md-12 mb-4">
                    <Form.Group>
                      <Form.Label className="d-flex gap-2 align-items-center text-mainText text-base">
                        ملاحظات الدفع
                      </Form.Label>

                      <textarea
                        {...paymentNotes.bind}
                        placeholder="ملاحظات الدفع"
                        className="form-control"
                        rows={5}
                      />
                    </Form.Group>
                  </div>
                </div>
                <div className=" d-flex my-2 justify-content-center">
                  <Button
                    type="submit"
                    className="sumbmitAddUpdateUser"
                    // onClick={handleUpdateProject}
                  >
                    {loading ? <Progress isSmall /> : "حفظ"}
                  </Button>
                </div>
              </Form>
            </fieldset>
          </Modal>
        )}
      </div>
      <SuccessfullModal
        show={updateSuccess}
        message={"تم تعديل المشروع بنجاح"}
        handleClose={() => {
          setUpdateSuccess(false);
          setEditProject(false);
          // getAllProject();
        }}
      />
    </>
  );
};

export default EditProject;
