import React from "react";
import { statusEnum } from "../helper/utils";

export default function DisplayStatus({status}) {
  return (
    <div className="flex items-center gap-2">
      <div
        className="w-2 h-2 rounded-full"
        style={{
          backgroundColor: statusEnum[status]?.color,
        }}
      ></div>
      <p>{statusEnum[status]?.title}</p>
    </div>
  );
}
