import React from 'react'
import { useGetProjectsMonthlyReports } from '../../../../../hooks/fetchers/Projects';
import LineChart from '../../../../../Components/LineChart';

const MonthlyReport = () => {
    const { data, isLoading, isError } = useGetProjectsMonthlyReports();    
    return (
      <div className="AllRequestsLineChartContainer w-100  d-flex align-items-center justify-content-center">
        {!isLoading && !isError ? (
          <LineChart
            colors={["#00F9FF", "#D59921"]}
            series1={{
              name: "تصميم",
              data: Object.values(data?.DESIGN),
            }}
            series2={{
              name: "الاشراف على التنفيذ",
              data: Object.values(data?.SUPERVISING),
            }}
          />
        ) : null}
      </div>
    );
  };

export default MonthlyReport