export const TableCell = ({
  children,
  textColor = "var(--main-text)",
  cellClassName,
  ...props
}) => {
  return (
    <td
      style={{
        color: textColor,
      }}
      className={`text-[10px] border-none min-w-[50px] p-2  text-mainText ${
        cellClassName ? cellClassName : ""
      }`}
      {...props}
    >
      {children}
    </td>
  );
};
