import React from "react";
import { Button, Modal } from "react-bootstrap";
import Image from "../Image";

export default function PreviewImage({ onClose, showImg, imgSrc }) {
  console.log("imgSrc", imgSrc);
  return (
    <Modal
      size="lg"
      show={showImg}
      onHide={onClose}
      aria-labelledby="example-modal-sizes-title-lg"
      className="showRequestImg bg-black/60"
      contentClassName=""
      centered
    >
      <Button
        className="w-fit mx-auto text-mainText"
        download={true}
        variant="success"
        href={imgSrc}
      >
        {"تحميل"}
        {/* <FaFileDownload fontSize={25} color="#EFAA20" /> */}
      </Button>
      <Image
        className="pointer w-100 h-100  instrutmentimg"
        src={imgSrc}
        alt="owner img"
      />
    </Modal>
  );
}
