import React from "react";
import { RiDeleteBin6Line } from "react-icons/ri";

const TableDeleteButton = ({
  checked,
  onClickDeleteAll,
  onClickDeleteSelected,
  dataLength,
}) => {
  return (
    dataLength ? (
      <>
        {checked?.length > 0 ? (
          <button className="customRedButton" onClick={onClickDeleteSelected}>
            حذف المحدد
            <RiDeleteBin6Line className="text-white text-base" />
          </button>
        ) : (
          <button className="customRedButton" onClick={onClickDeleteAll}>
            حذف الكل
            <RiDeleteBin6Line className="text-white text-base" />
          </button>
        )}
      </>
    ) : null
  );
};

export default TableDeleteButton;
