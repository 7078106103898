import { Form, Modal } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";

import Select from "../../../FormHandler/Select";
import { FormControl } from "@mui/material";
import SaveButton from "../../../SaveButton";
import { FormModal } from "../../../../Pages/System/PlanModel/components/FormModal";
import { InputLabel } from "../../../../Pages/System/PlanModel/components/InputLabel";
import useSuccessfullModal from "../../../Modals/hooks/useSuccessfullModal";
import SuccessfullModal from "../../../Modals/SuccessfullModal";
import { useUpdateReception } from "../../../../hooks/fetchers/Receptions";
import { useGetSelectedUsers } from "../../../../hooks/fetchers/Users";
import {
  CustomDatePicker,
  CustomeInputFile,
  CustomInput,
  CustomTimePicker,
} from "../../../FormUi";
import { Fragment, useEffect, useState } from "react";
import { ModalHeader } from "./components/ModalHeader";
import moment from "moment";
import { ListErrorComponent } from "../../../Errors/ListErrorComponent";
import { CustErrorMessage } from "../../../Errors/CustErrorMessage";
import dayjs from "dayjs";
import { myAxios } from "../../../../helper/https";
import NoFiles from "../../ShowRequest/components/NoFiles";
import ShowFiles from "../../../ShowFiles";

const ValidateFileSize = (value) => {
  if (value.length > 4) {
    return "لا يجب ان تزيد عدد الملفات عن 4";
  }
  return true;
};

const UpdateReception = ({ show, handleCloseModal, id, data }) => {
  const [errorMessage, setErrorMessage] = useState("حدث خطأ ما");
  const { data: employees } = useGetSelectedUsers(true);
  const [old,setOld] = useState()

  const options = [
    {
      value: 2,
      label: "واردة",
    },
    {
      value: 1,
      label: "صادرة",
    },
  ];
  const conditions =
    data.data.reception.typeVisit === 1
      ? {
          employee: employees?.find(
            (emp) => emp.value === data.data.reception.employee._id
          ),
        }
      : {
          personVisit: data.data.reception.personVisit,
          personType: data.data.reception.personType,
          IdentityNumber: data.data.reception.IdentityNumber,
        };
  
  // console.log("hello from update");
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    watch
  } = useForm({
    defaultValues: {
      typeVisit: data.data.reception.typeVisit,
      phone: data.data.reception.phone,
      visitLocation: data.data.reception.visitLocation,
      dateVist: new Date(data.data.reception.dateVist), // Ensure this is a Date object
      notes: data.data.reception.notes,
      reasonVisit: data.data.reception.reasonVisit,
      timeInVist: dayjs(data?.data?.reception?.timeInVist,"h:mm a").toDate(),
      timeOutVist: dayjs(data?.data?.reception?.timeOutVist,"h:mm a").toDate(),
      // fileVist: data.data.reception.fileVist,
      ...conditions,
    },
  });

  const {
    handleShowSuccess,
    handleShowError,
    handleCloseSuccess,
    handleCloseError,
    showSuccess,
    showError,
  } = useSuccessfullModal();

  /****************************/
  const {
    mutate: updateReceptionMutation,
    isError: updateReceptionIsError,
    error: updateReceptionErrorMessage,
  } = useUpdateReception(handleShowSuccess, handleShowError, id);
  /****************************/
  const manipulateFormData = (visitData) => {
    // if (ReciptionType === "Exports" || data?.typeVisit === 1) {
    //   visitData.typeVisit = 1;
    //   visitData.employee = visitData.employee.value;
    // } else if (ReciptionType === "Imports" || data?.typeVisit === 2) {
    //   visitData.typeVisit = 2;
    // }
    if (visitData.typeVisit === 1) {
      visitData.employee = visitData.employee.value;
    }
    console.log("visitData.employee",visitData.employee);
    visitData.dateVist = moment(visitData.dateVist).format("YYYY-MM-DD");
    visitData.timeInVist = moment(visitData.timeInVist).format("h:m a");
    visitData.timeOutVist = moment(visitData.timeOutVist).format("h:m a");
    return visitData;
  };
  const onSubmit = (visitData) => {
    visitData = manipulateFormData(visitData);
    console.log("Submitted Data",visitData);
    const formData = new FormData();
    for (const file of visitData?.fileVist) {
      formData.append("fileVist", file);
    }
    for (const [key, value] of Object.entries(visitData)) {
      if (key !== "fileVist") {
        formData.append(key, value);
      }
      // console.log([key,value]);
    }

    // console.log(formData.getAll('fileVist'));
    updateReceptionMutation(formData);
    // console.log("visitData",visitData);
  };
  useEffect(() => {
    if (updateReceptionIsError) {
      if (updateReceptionErrorMessage?.response?.data?.validationErrors) {
        setErrorMessage(
          <ListErrorComponent
            messages={updateReceptionErrorMessage?.response?.data?.validationErrors?.split(
              ","
            )}
          />
        );
      } else if (updateReceptionErrorMessage?.response?.message) {
        setErrorMessage(updateReceptionErrorMessage?.response?.message);
      }
    }
  }, [updateReceptionIsError]);
  // console.log("data from child", data?.data?.reception);

  // console.log("timeInVist is", moment(data?.data?.reception?.timeInVist,"h:mm a").format("ddd MMM D YYYY HH:mm:ss [GMT]ZZ"));
  // console.log("timeOutVist is", moment(data?.data?.reception?.timeOutVist,"h:mm a").format("ddd MMM D YYYY HH:mm:ss [GMT]ZZ"));
  // const ConvertFileLinks = (files) => {
  //   let links;
  //   myAxios.get(files,{
  //     responseType: "blob"
  //   })
  //   .then(data=>{
  
  //     console.log("file data",data.data);
  //     links = URL.createObjectURL(data.data)
  //     console.log('url',URL.createObjectURL(data.data));
  //   })
  //   .catch(err=>{
  //     console.log(err);
  //   })
  //   return links
  // }
  // console.log("watch",watch("fileVist"));
  return (
    <Fragment>
      <Modal
        size="lg"
        show={show}
        onHide={handleCloseModal}
        aria-labelledby=" example-modal-sizes-title-lg"
        className={`systemModal scrollbar-none overflow-y-auto bg-black/50`}
      >
        <ModalHeader
          title={
            data?.data?.reception?.typeVisit === 1
              ? "تعديل زيارة صادرة"
              : "تعديل زيارة واردة"
          }
        />

        <Modal.Body>
          <Form
            onSubmit={handleSubmit(onSubmit)}
            encType="multipart/form-data"
            className="flex flex-col gap-3 "
          >
            <FormModal title={"معلومات الزيارة"}>
              <div className="grid grid-cols-3 gap-4 mb-3">
                <FormControl>
                  <InputLabel label={"نوع الزيارة"} />
                  <Controller
                    control={control}
                    name="typeVisit"
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Select
                        fullWidth
                        value={options.find((option) =>
                          data?.data?.reception?.typeVisit === 1
                            ? option.value === 1
                            : option.value === 2
                        )}
                        OptionbackgroundColor="#414162"
                        disabled={true}
                        options={options}
                        // onChange={onChange}
                      />
                    )}
                  />
                </FormControl>
                <FormControl className="col-span-2">
                  <InputLabel
                    label={
                      data?.data?.reception?.typeVisit === 1
                        ? " اسم الموظف"
                        : "اسم الزائر"
                    }
                  />
                  
                  {data?.data?.reception?.typeVisit === 1 ? (
                    <Controller
                      control={control}
                      name="employee"
                      rules={{
                        required: "يجب اختيار الموظف",
                      }}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Select
                          fullWidth
                          onChange={onChange}
                          onBlur={onBlur}
                          value={value}
                          ref={ref}
                          placeholder="اكتب اسم الموظف"
                          OptionbackgroundColor="#414162"
                          options={employees}
                          mandatory
                        />
                      )}
                    />
                  ) : (
                    <CustomInput
                      control={control}
                      name="personVisit"
                      placeholder="اكتب اسم الموظف"
                      rules={{
                        required: "من فضلك اكتب اسم الموظف",
                        minLength: {
                          value: 3,
                          message: "لا تقل عن 3 حروف",
                        },
                        pattern: {
                          value: /^[A-Za-z\u0600-\u06FF ]{3,}$/i,
                          message:
                            "يجب ان تتكون من حروف عربى فقط او انجليزية فقط رقم",
                        },
                      }}
                    />
                  )}

                  <CustErrorMessage name={"personVisit"} errors={errors} />
                </FormControl>
              </div>
              {data?.data?.reception?.typeVisit === 2 ? (
                <div className="grid grid-cols-2 gap-4 mb-3">
                  <FormControl>
                    <InputLabel label={"الهوية"} />
                    <CustomInput
                      control={control}
                      name="IdentityNumber"
                      placeholder="اكتب هوية الموظف"
                      rules={{
                        required: "من فضلك اكتب هوية الموظف",

                        pattern: {
                          value: /^[0-9]{11}$/i,
                          message: "يجب ان تتكون من 11 رقم",
                        },
                      }}
                    />

                    <CustErrorMessage name={"IdentityNumber"} errors={errors} />
                  </FormControl>

                  <FormControl>
                    <InputLabel label={"فئة الزائر"} />
                    <CustomInput
                      control={control}
                      name="personType"
                      placeholder="اكتب فئة الزائر"
                      rules={{
                        required: "من فضلك اكتب فئة الزائر",
                      }}
                    />

                    <CustErrorMessage name={"personType"} errors={errors} />
                  </FormControl>
                </div>
              ) : null}

              <div className="grid grid-cols-3 mb-3 gap-4">
                <FormControl className="col-span-2">
                  <InputLabel label={"مكان الزيارة"} />
                  <CustomInput
                    control={control}
                    name="visitLocation"
                    placeholder="اكتب مكان الزياة"
                    rules={{
                      required: "من فضلك اكتب مكان الزياة",
                    }}
                  />

                  <CustErrorMessage name={"visitLocation"} errors={errors} />
                </FormControl>
                <FormControl>
                  <InputLabel label={"رقم الجوال"} />
                  <CustomInput
                    control={control}
                    name="phone"
                    placeholder="ادخل رقم الجوال"
                    rules={{
                      required: "من فضلك اكتب رقم الجوال",
                      pattern: {
                        value:
                          /^(\+?966|0)?\s?5\d{8}$|^(\+?20|0)?\s?(10|11|12|15)\d{8}$/i,
                        message: " رقم غير صحيح من فضلك ادخل رقم مصرى او سعودى",
                      },
                    }}
                  />

                  <CustErrorMessage name={"phone"} errors={errors} />
                </FormControl>
              </div>
              <div className="grid grid-cols-3 gap-4 mb-3">
                <FormControl>
                  <InputLabel label={"تاريخ الزيارة"} />
                  <CustomDatePicker
                    control={control}
                    name="dateVist"
                    rules={{
                      required: "من فضلك اكتب تاريخ الزيارة",
                    }}
                  />

                  <CustErrorMessage name={"dateVist"} errors={errors} />
                </FormControl>
                <FormControl>
                  <InputLabel label={"وقت الزياره من"} />
                  <CustomTimePicker
                    control={control}
                    name="timeInVist"
                    rules={{
                      required: "من فضلك اكتب وقت بدا الزياره",
                    }}
                  />

                  <CustErrorMessage name={"timeInVist"} errors={errors} />
                </FormControl>
                <FormControl>
                  <InputLabel label={"وقت الزياره الي"} />
                  <CustomTimePicker
                    control={control}
                    name="timeOutVist"
                    rules={{
                      required: "من فضلك اكتب وقت نهاية الزياره",
                    }}
                  />

                  <CustErrorMessage name={"timeOutVist"} errors={errors} />
                </FormControl>
              </div>
            </FormModal>
            <FormModal title={"تفاصيل الزيارة"}>
              <FormControl fullWidth>
                <InputLabel label={"سبب الزيارة"} />
                <CustomInput
                  control={control}
                  name="reasonVisit"
                  placeholder="اكتب سبب الزيارة .................................."
                  multiline
                  cols={5}
                  rows={5}
                  rules={{
                    required: "من فضلك اكتب سبب الزيارة",
                    minLength: {
                      value: 3,
                      message: "لا تقل عن 3 حروف",
                    },
                    pattern: {
                      value: /^[A-Za-z\u0600-\u06FF ]{3,}$/i,
                      message:
                        "يجب ان تتكون من حروف عربى فقط او انجليزية فقط رقم",
                    },
                  }}
                />

                <CustErrorMessage name={"reasonVisit"} errors={errors} />
              </FormControl>
              <FormControl fullWidth>
                <InputLabel label={"ملاحظات"} />
                <CustomInput
                  control={control}
                  name="notes"
                  placeholder="اكتب  ملاحظات .................................."
                  multiline
                  cols={5}
                  rows={5}
                  rules={{
                    required: "من فضلك اكتب ملاحظات",
                    maxLength: {
                      value: 6000,
                      message: "لا تقل عن 6000 حروف",
                    },
                    pattern: {
                      value: /^[A-Za-z\u0600-\u06FF ]{0,6000}$/i,
                      message:
                        "يجب ان تتكون من حروف عربى فقط او انجليزية فقط رقم",
                    },
                  }}
                />

                <CustErrorMessage name={"notes"} errors={errors} />
              </FormControl>
            </FormModal>

            <FormModal title={"المرفقات"}>
            <div className="flex items-start gap-2">
              <CustomeInputFile
                register={{
                  ...register("fileVist", {
                    validate: ValidateFileSize,
                  }),
                }}
                name="fileVist"
                multiple={true}
              />

              {!watch("fileVist")?.length &&
              data?.data?.reception?.fileVist?.length ? (
                <ShowFiles files={data?.data?.reception?.fileVist} />
              ) : null}
            </div>
            <CustErrorMessage name={"fileVist"} errors={errors} />
          </FormModal>



            <div className="flex my-2 justify-center gap-4">
              {/* <DeleteButton /> */}
              <SaveButton type="submit" />
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      <SuccessfullModal
        handleClose={() => {
          handleCloseSuccess();
          handleCloseModal();
        }}
        message={"تم التعديل بنجاح"}
        show={showSuccess}
      />
      <SuccessfullModal
        handleClose={handleCloseError}
        message={errorMessage}
        show={showError}
        status="error"
      />
    </Fragment>
  );
};




const Files = ({files})=> {
  return (<>
  
  </>)
}


export default UpdateReception;
