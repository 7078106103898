import { StatisticsCard } from "../../../../Components/StatisticsCard";
import {
  CompletedIcon,
  InProgressIcon,
  LatetIcon,
  ProjectsIcon,
} from "../../../../utiltis/Icons";

export const ProjectsStatistics = ({ data }) => {
  // console.log(data);
  return (
    <div className="row my-4 ">
      <div className="col-md-3">
        <StatisticsCard
          title={"المشاريع"}
          total={data?.planCount}
          icon={<ProjectsIcon />}
        />
      </div>
      <div className="col-md-3">
        <StatisticsCard
          title={"قيد التنفيذ"}
          total={data?.planStatistics?.IN_PROGRESS}
          icon={<InProgressIcon />}
          nested
        />
      </div>
      <div className="col-md-3">
        <StatisticsCard
          title={"مكتملة"}
          total={data?.planStatistics?.COMPLETED}
          icon={<CompletedIcon />}
          nested
        />
      </div>
      <div className="col-md-3">
        <StatisticsCard
          title={"متأخره"}
          total={data?.planLateCount?.late}
          icon={<LatetIcon />}
          nested
        />
      </div>
    </div>
  );
};
