import { AiFillDislike, AiFillLike } from "react-icons/ai";
import { UserImage } from "./UserImage";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import { useState } from "react";
import { twMerge } from "tailwind-merge";
export const ReactionsMenu = ({
  show,
  onClose,
  anchorEl,
  likes = [],
  dislikes = [],
}) => {
  // console.log("likes", likes);
  // console.log("dislikes", dislikes);
  return (
    <>
      {/* <div>
        <Button
          onClick={handleClick}
        >
          Dashboard
        </Button>
        <MenuWrapper anchorEl={anchorEl} open={show} handleClose={onClose}>
        <div className="flex items-center gap-3 p-2 border-b reaction-menu-border">
          <div className="reaction-menu-total text-mainText flex items-center gap-1 p-px px-1 rounded">
            <span>1</span>
            <p>الكل</p>
          </div>
          <div className="text-mainText flex items-center gap-1 p-px px-1 rounded">
            <span>1</span>
            <AiFillLike size={22} fill="#E1A945" className="" />
          </div>
          <div className="text-mainText flex items-center gap-1 p-px px-1 rounded">
            <span>1</span>
            <AiFillDislike size={22} fill="red" className="" />
          </div>
        </div>
          <MenuItem onClick={onClose}><div className="flex items-center justify-between gap-2 !font-sans">
            <div className="flex items-center justify-between gap-2">
              <UserImage
                name={"صباح صبوحه"}
                image={
                  "https://images.unsplash.com/photo-1494790108377-be9c29b29330?fm=jpg&w=3000&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlciUyMHByb2ZpbGV8ZW58MHx8MHx8fDA%3D"
                }
              />
              <p className="text-[#E1A945] text-sm font-semibold">صباح صبوحه</p>
            </div>
            <AiFillLike size={22} fill="#E1A945" className="" />
          </div></MenuItem>
          <MenuItem onClick={onClose}><div className="flex items-center justify-between gap-2 !font-sans">
            <div className="flex items-center justify-between gap-2">
              <UserImage
                name={"مروة محمود"}
                image={
                  "https://images.unsplash.com/photo-1494790108377-be9c29b29330?fm=jpg&w=3000&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlciUyMHByb2ZpbGV8ZW58MHx8MHx8fDA%3D"
                }
              />
              <p className="text-[#E1A945] text-sm font-semibold">مروة محمود</p>
            </div>
            <AiFillDislike size={22} fill="red" className="" />
          </div></MenuItem>
        </MenuWrapper>
      </div> */}

      <div
        className={twMerge(
          "absolute top-full right-0 min-w-[300px] reaction-menu rounded-2xl z-10 border !border-[#E1A945] dark:!border-[#424242]  ",
          show ? "openanimate" : "closeanimate hidden"
        )}
      >
        <div className="flex items-center gap-3 p-2 border-b reaction-menu-border">
          <div className="reaction-menu-total text-mainText flex items-center gap-1 p-px px-1 rounded">
            <span>{likes.length + dislikes.length}</span>
            <p>الكل</p>
          </div>
          <div className="text-mainText flex items-center gap-1 p-px px-1 rounded">
            <span>{likes.length}</span>
            <AiFillLike size={22} fill="#E1A945" className="" />
          </div>
          <div className="text-mainText flex items-center gap-1 p-px px-1 rounded">
            <span>{dislikes.length}</span>
            <AiFillDislike size={22} fill="red" className="" />
          </div>
        </div>
        <div className="flex flex-col gap-3 p-2 ">
          {likes.map((like) => (
            <User user={like} isLike={true} key={like._id} />
          ))}
          {dislikes.map((dislike) => (
            <User user={dislike} key={dislike._id} />
          ))}
        </div>
      </div>
    </>
  );
};

const User = ({ isLike = false , user = {}}) => {
  return (
    <div className="flex items-center justify-between gap-2">
      <div className="flex items-center justify-between gap-2">
        <UserImage
          name={`${user.firstName} ${user.lastName}`}
          image={user.image}
        />
        <p className="text-[#E1A945] text-sm font-semibold">{`${user.firstName} ${user.lastName}`}</p>
      </div>
      {isLike ? (
        <AiFillLike size={22} fill="#E1A945" className="" />
      ) : (
        <AiFillDislike size={22} fill="red" className="" />
      )}
    </div>
  );
};

const MenuWrapper = ({ children, anchorEl, open, handleClose }) => {
  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      MenuListProps={{
        className:
          "min-w-[300px] reaction-menu rounded-2xl border !border-[#E1A945] dark:!border-[#424242]",
      }}
      slotProps={{
        root: {
          // className: 'bg-green-400'
        },
        paper: {
          className: "!bg-transparent shadow-none",
        },
      }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      {children}
    </Menu>
  );
};
