import { useContext, useState } from "react";
import { addAccountType } from "../../../../../Context/AddAccountaing";
import AddExpensesReports from "../../../../../Components/System/Accountaing/AddExpensesReports/AddExpensesReports";
import { Image, Modal, Spinner } from "react-bootstrap";
import style from "../ExpensesDetails/ExpensesDetails.module.css";
import { SearchComponent } from "../../../../../Components/SearchComponent/SearchComponent";
import CustomTable from "../../../../../Components/Table/index.jsx";
import moment from "moment";
import BillIcon from "../../../../../assets/Bill_icon";
import { useGetAllExpensesItemsReport } from "../../../../../hooks/fetchers/Expenses-items-reports";
import { TableRow } from "../../../../../Components/Table/TableRow.jsx";
import { TableCell } from "../../../../../Components/Table/TableCell.jsx";
import ShowExpensesReportDetails from "../../../../../Components/System/Accountaing/showExpensesReportDetials/ShowExpensesReportDetials.js";
import { useParams } from "react-router-dom";
import config from "../../../../../Config/Config.jsx";
import Cookies from "js-cookie";
import LoadingPage from "../../../../../Components/feedback/LoadingPage.jsx";
import { Document, Page, pdfjs } from "react-pdf";
import { statusEnum } from "../../../../../helper/utils.jsx";
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.js",
  import.meta.url
).toString();
const ExpensesCountryDetails = () => {
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");

  const [pdfLoading, setPdfLoading] = useState(false);
  const [pdfUrl, setPdfUrl] = useState("");
  const [openBill, setOpenBill] = useState(false);

  const {
    accountaingType,
    setAccountaingType,
    openAddAccountant,
    setOpenAddAccountant,
    openDisplayDetails,
    setOpenDisplayDetails,
  } = useContext(addAccountType);
  const { country, status } = useParams();
  const columns = [
    { name: "اسم التقرير", selector: (row) => row.name },
    { name: "البند", selector: (row) => row?.clauseId?.name },
    {
      name: "تاريخ الاستحقاق",
      selector: (row) => moment(row.dueDate).format("YYYY-MM-DD"),
    },
    { name: "الحاله", selector: (row) => row.status },
    { name: "عرض", selector: (row) => row?.status },
    { name: "تقارير المصروفات" },
  ];

  const handleSearch = (val) => setSearch(val);
  const handleChange = (event, value) => setPage(value);

  const { data, isLoading, isError } = useGetAllExpensesItemsReport({
    search,
    country: country === "Egypt" ? "مصر" : "السعودية",
    status,
    page,
  });

  const onViewClick = (item) => {
    setOpenDisplayDetails(true);
    setSelectedItem(item);
    setSelectedItemId(item?._id);
    // Add logic to handle viewing item details
  };

  // console.log(data?.data, "data?.data?.expenses");
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const getFinancialPdf = async (id) => {
    setPdfLoading(true);
    try {
      const response = await fetch(
        `${config.apiGateway?.URL}/expenses/pdf/${id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/pdf",
            authes: `BSA__${Cookies.get("accessToken")}`,
          },
        }
      );
      if (response.ok) {
        const blob = await response.blob();
        const url = URL.createObjectURL(blob);
        setPdfUrl(url);
      } else {
        console.error("Failed to fetch PDF:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching PDF:", error);
    } finally {
      setPdfLoading(false);
    }
  };

  return (
    <div>
      {pdfLoading ? (
        <LoadingPage />
      ) : (
        pdfUrl && (
          <Modal
            show={true}
            onHide={() => setPdfUrl("")}
            className="h-screen bg-black/50"
            contentClassName="bg-transparent"
          >
            <Modal.Body className="h-full w-full min-w-min">
              <a
                href={pdfUrl}
                download={"file.pdf"}
                className="mb-3 mx-auto block text-center !text-[#efaa20] hover:!text-[#efaa20] border !border-[#efaa20] w-fit px-4 py-1 rounded-full bg-mainNavy"
              >
                تحميل المستند
              </a>
              <Document
                file={pdfUrl}
                onLoadSuccess={() => setPdfLoading(false)}
                className={"w-full min-w-min"}
              >
                <Page pageNumber={1} className={"w-full"} />
              </Document>
            </Modal.Body>
          </Modal>
        )
      )}
      {openAddAccountant ? (
        <AddExpensesReports />
      ) : (
        <>
          {openBill && (
            <Modal
              className="d-flex claimModal align-items-center justify-content-center"
              size="lg"
              onHide={() => setOpenBill(false)}
              show={openBill}
            >
              <Modal.Body className="d-flex align-items-center">
                <Image
                  src={`${process.env.PUBLIC_URL}/expenses.png`}
                  alt="Financial Request"
                  width={650}
                  height={700}
                />
              </Modal.Body>
            </Modal>
          )}
          {openDisplayDetails ? (
            <ShowExpensesReportDetails
              setOpenDisplayDetails={setOpenDisplayDetails}
              id={selectedItemId}
            />
          ) : (
            <>
              <div
                className={`w-100 ${style.ExpensesNumbersContainer} mb-4 p-4`}
              >
                <div className="d-flex gap-3">
                  <p className="text-mainText">اجمالي عدد المصروفات :</p>
                </div>
                <div className="Treasury-container-numbers d-flex justify-content-center text-mainText">
                  {data?.data?.totalAmount}
                </div>
              </div>
              <fieldset className={style.ExpensesDataTableContainer}>
                <legend className="text-center text-mainText">
                  تقارير المصروفات
                </legend>
                <div className="mt-3 h-400px overflow-auto scrollbar-none flex flex-col gap-3 py-4">
                  <div className="w-full md:w-3/4 lg:w-1/2 px-2 mx-auto">
                    <SearchComponent
                      handleChange={handleSearch}
                      value={search}
                      border="border !border-[#E4A11B]"
                    />
                  </div>
                  <CustomTable
                    columns={columns}
                    data={data?.data?.expenses}
                    paginationProps={{
                      count: data?.data?.pages,
                      page: page,
                      onChange: handleChange,
                    }}
                  >
                    {isLoading ? (
                      <div className="w-full h-full flex justify-center">
                        <Spinner className="relative top-1/3 text-[#EFAA20]" />
                      </div>
                    ) : isError ? (
                      <div className="w-full h-full flex justify-center">
                        <p className="relative top-1/3 text-red-500 text-xl">
                          يوجد خطأ ما
                        </p>
                      </div>
                    ) : (
                      data?.data?.expenses?.map(
                        (item, index) =>
                          !item?.isisDeleted && (
                            <TableRow
                              className={`my-2 border !border-[#efaa207f] ${
                                index % 2 === 0 ? "bg-mainNavy" : ""
                              }`}
                              key={item._id}
                            >
                              <TableCell>{item.name}</TableCell>
                              <TableCell>{item.clauseId?.name}</TableCell>
                              <TableCell>
                                {moment(item.dueDate).format("YYYY-MM-DD")}
                              </TableCell>
                              <TableCell>
                                {statusEnum[item.status]?.title}
                              </TableCell>
                              <TableCell>
                                <div onClick={() => onViewClick(item)}>
                                  <img
                                    src={`${process.env.PUBLIC_URL}/icons/view.png`}
                                    className="pointer"
                                    alt="view icon"
                                  />
                                </div>
                              </TableCell>
                              <TableCell>
                                <div
                                  className="pointer d-flex  w-100"
                                  onClick={() => getFinancialPdf(item._id)}
                                >
                                  <div className="p-1 rounded-md mx-3">
                                    <BillIcon fill="#efaa20" />
                                  </div>
                                </div>
                              </TableCell>
                            </TableRow>
                          )
                      )
                    )}
                  </CustomTable>
                </div>
              </fieldset>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default ExpensesCountryDetails;
