import { NavLink } from "react-router-dom";
import SettingIcon from "../logos/SettingIcon";
import ReceptionIcon from "../logos/ReceptionIcon";
import CustomerServicesIcon from "../logos/CustomerServicesIcon";
import AccountingIcon from "../logos/AccountingIcon";
import style from "../AsideBar.module.css";
import "../AsideBar.css";
import { RiInboxArchiveLine } from "react-icons/ri";

export const SettingsMenu = ({ openSettingsMenu, handleCloseMenu }) => {
  return (
    <div
      className={`absolute ${
        openSettingsMenu ? "top-0" : "top-full"
      } left-0 w-full h-full rounded-[19px] transition-all ease-in-out duration-500 overflow-hidden bg-[#ffffff3d] backdrop-blur-[15.899999618530273px]    `}
    >
      <div
        onClick={handleCloseMenu}
        className="bg-mainNavy p-2 text-center flex items-center justify-center flex-col"
      >
        <SettingIcon />
        <p className="text-mainText">الاعدادات</p>
      </div>
      <div className="row p-2 system-card-container ">
        <div className="col-md-4 ">
          <div className="system-item">
            <NavLink
              to="/System/Settings/Reception"
              className={({ isActive }) =>
                isActive ? style["active-link"] : ""
              }
            >
              <div className="system-card border !border-transparent  hover:!border-[#EFAA20]">
                <div className="card__content d-flex justify-content-center align-items-center  flex-column  ">
                  <ReceptionIcon />
                  <p className="text-mainText">الاستقبال</p>
                </div>
              </div>
            </NavLink>
          </div>
        </div>
        <div className="col-md-4 ">
          <div className="system-item">
            <NavLink
              to="/System/Settings/Orders/categories"
              className={({ isActive }) =>
                isActive ? style["active-link"] : ""
              }
            >
              <div className="system-card border !border-transparent  hover:!border-[#EFAA20]">
                <div className="card__content d-flex justify-content-center align-items-center  flex-column  ">
                  <RiInboxArchiveLine className="aside-icon text-mainText" />

                  <p className="text-mainText">الطلبات</p>
                </div>
              </div>
            </NavLink>
          </div>
        </div>
        <div className="col-md-4 ">
          <div className="system-item">
            <NavLink
              to={"/System/Settings/Accounating"}
              className={({ isActive }) =>
                isActive ? style["active-link"] : ""
              }
            >
              <div className="system-card border !border-transparent  hover:!border-[#EFAA20]">
                <div className="card__content d-flex justify-content-center align-items-center  flex-column  ">
                  <AccountingIcon />

                  <p className="text-mainText">الحسابات </p>
                </div>
              </div>
            </NavLink>
          </div>
        </div>
        <div className="col-md-4 ">
          <div className="system-item">
            <NavLink
              to={"/System/Settings/CitizenServices/vacations"}
              className={({ isActive }) =>
                isActive ? style["active-link"] : ""
              }
            >
              <div className="system-card border !border-transparent  hover:!border-[#EFAA20]">
                <div className="card__content d-flex justify-content-center align-items-center  flex-column  ">
                  <CustomerServicesIcon />
                  <p className="text-mainText">خدمات الموظفين</p>
                </div>
              </div>
            </NavLink>
          </div>
        </div>
        {/* <div className="col-md-4 ">
            <div className="system-item">
              <NavLink
                to={"/System/Settings/TimeLine"}
                className={({ isActive }) =>
                  isActive ? style["active-link"] : ""
                }
              >
                <div
                  className="system-card border !border-transparent  hover:!border-[#EFAA20]"
                  onClick={() => {
                    // console.log("Settings Clicked");
                  }}
                >
                  <div className="card__content d-flex justify-content-center align-items-center  flex-column  ">
                    <TimeIcon />
                    <p className="text-mainText">اليات الخطه الزمنيه </p>
                  </div>
                </div>
              </NavLink>
            </div>
          </div> */}
      </div>
    </div>
  );
};
