import React, { forwardRef, useState } from "react";
import InputEmoji from "react-input-emoji";
import { Controller } from "react-hook-form";
import Picker from "emoji-picker-react";
import { MdOutlineEmojiEmotions } from "react-icons/md";
import { GrEmoji } from "react-icons/gr";
import { NavDropdown } from "react-bootstrap";
const Input = forwardRef((props, ref) => <input {...props} ref={ref} />);

const useShowPicker = () => {
  const [showPicker, setShowPicker] = useState(false);
  const handleShowPicker = () => setShowPicker(true);
  const handleClosePicker = () => setShowPicker(false);
  const handleTogglePicker = () => setShowPicker(!showPicker);
  return {
    showPicker,
    handleShowPicker,
    handleClosePicker,
    handleTogglePicker,
  };
};

const ChatInput = forwardRef(
  ({ control, handleWritingMessage, onSubmit, setValue, watch }, ref) => {
    const { showPicker, handleClosePicker, handleTogglePicker } =
      useShowPicker();
    const onEmojiClick = (emojiObject, event) => {
      console.log("event", event);
      console.log("emojiObject", emojiObject.emoji);
      const msg = watch("message") || "";

      setValue("message", msg + emojiObject.emoji);

      handleClosePicker();
    };
    console.log("watch", watch("message"));
    return (
      <div className="flex items-center">
        {/* {showPicker && (
          <div className=" top-full left-0">
            <Picker onEmojiClick={onEmojiClick} style={{ width: "100%" }} />
          </div>
        )} */}

        <Controller
          control={control}
          name={`message`}
          rules={{
            required: true,
          }}
          render={({ field: { onChange, onBlur, value = "" } }) => (
            <Input
              value={value}
              onChange={(e) => {
                console.log(e.target.value);
                handleWritingMessage(e.target.value);
                return onChange(e.target.value);
              }}
              dir="rtl"
              className="!border-transparent text-mainText  bg-mainNavy scrollbar-none flex-1 p-2"
              placeholder="ارسل رسالة"
              ref={ref}
            />
          )}
        />

        <NavDropdown
          title={<GrEmoji color="#EFAA20" size={25} />}
          className="fs-5"
          id="collapsible-nav-dropdown"
        >
          <NavDropdown.Item className="p-0 hover:rounded-lg !bg-mainNavy hover:!bg-mainNavy hover:text-mainNavy">
          <Picker 
          searchDisabled={true}
          skinTonesDisabled={true}
          allowExpandReactions={false}
          onEmojiClick={onEmojiClick} className="!border-transparent !bg-mainNavy scrollbar-none" style={{ width: "100%" }} />
          </NavDropdown.Item>
        </NavDropdown>

        {/* <button
          type="button"
          onClick={handleTogglePicker}
          className=" top-2 right-1"
        >
          <MdOutlineEmojiEmotions size={25} />
        </button> */}
        {/* <Controller
          control={control}
          name={`message`}
          rules={{
            required: true,
          }}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <InputEmoji
              value={value}
              onChange={(val) => {
                console.log(val);
                handleWritingMessage(val);
                return onChange(val);
              }}
              cleanOnEnter
              onEnter={(val) => onSubmit({ message: val })}
              borderRadius="26"
              borderColor="00FFFFFF"
              inputClass="!border-transparent text-mainText text-start bg-mainNavy scrollbar-none"
              // inputClass="!border-transparent text-mainText text-start bg-mainNavy"
              shouldConvertEmojiToImage
              placeholder="ارسل رسالة"
              theme="dark"
              ref={inputRef}
            />
          )}
        /> */}
      </div>
    );
  }
);
// const ChatInput = forwardRef(
//     ({ control, handleWritingMessage, onSubmit, ref: inputRef }) => {
//       return (
//         <Controller
//           control={control}
//           name={`message`}
//           rules={{
//             required: true,
//           }}
//           render={({ field: { onChange, onBlur, value, ref } }) => (
//             <InputEmoji
//               value={value}
//               onChange={(val) => {
//                 console.log(val);
//                 handleWritingMessage(val);
//                 return onChange(val);
//               }}
//               cleanOnEnter
//               onEnter={(val) => onSubmit({ message: val })}
//               borderRadius="26"
//               borderColor="00FFFFFF"
//               inputClass="!border-transparent text-mainText text-start bg-mainNavy scrollbar-none"
//               // inputClass="!border-transparent text-mainText text-start bg-mainNavy"
//               shouldConvertEmojiToImage
//               placeholder="ارسل رسالة"
//               theme="dark"
//               ref={inputRef}
//             />
//           )}
//         />
//       );
//     }
//   );

export default ChatInput;
