import React, { useCallback, useContext, useState } from "react";
import styles from "./RevenusDetails.module.css";
import PieChart from "../../../../../Components/pieChart.jsx";
import Cookies from "js-cookie";

import { Modal } from "react-bootstrap";
import { useParams } from "react-router-dom";
import DisplayFinancialClaims from "../../../../../Components/System/Accountaing/DisplayFinancialClaims/DisplayFinancialClaims.jsx";
import { useEffect } from "react";
import Calendar from "react-calendar";
import { addAccountType } from "../../../../../Context/AddAccountaing.js";
import AddFinancialClaims from "../../../../../Components/System/Accountaing/AddFinancialClaims/AddFinancialClaims.js";
import AddInvoice from "../../../../../Components/System/Accountaing/AddInvoice/AddInvoice.js";
import { TableCell } from "../../../../../Components/Table/TableCell.jsx";
import { TableRow } from "../../../../../Components/Table/TableRow.jsx";
import CustomTable from "../../../../../Components/Table/index.jsx";
import {
  getAllAccountStats,
  getAllFinancial,
  getFinancialPDF,
  getFinancialStats,
  useGetAllFinancial,
} from "../../../../../helper/fetchers/finaical.jsx";
import moment from "moment";
import FileContainer from "../../../../../Components/FileContainer.jsx";
import {
  getAllInvoices,
  useGetAllInvoices,
} from "../../../../../helper/fetchers/invoice.jsx";
import DisplayInvoice from "../../../../../Components/System/Accountaing/DisplayInvoice/DisplayInvoice.jsx";
import BillIcon from "../../../../../assets/Bill_icon.jsx";
import { Document, Page, pdfjs } from "react-pdf";
import { PDFViewer } from "@react-pdf/renderer";
import config from "../../../../../Config/Config.jsx";
import LoadingPage from "../../../../../Components/feedback/LoadingPage.jsx";
import useSuccessfullModal from "../../../../../Components/Modals/hooks/useSuccessfullModal.jsx";
import { useDeleteFinancial } from "../../../../../hooks/fetchers/Financial.jsx";
import Progress from "../../../../../Components/Progress.jsx";
import { RiDeleteBin6Line } from "react-icons/ri";
import SuccessfullModal from "../../../../../Components/Modals/SuccessfullModal.jsx";
import CustomCheckboxTable from "../../../../../Components/CustomCheckboxTable.jsx";
import { useDeleteInvoice } from "../../../../../hooks/fetchers/Invoice.jsx";
import TableDeleteButton from "../../../../../Components/Buttons/TableDeleteButton.jsx";
import { CheckRole } from "../../../../../helper/utils.jsx";
import { roles } from "../../../../../helper/fetchers/Tasks.jsx";
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.js",
  import.meta.url
).toString();
const columns = [
  {
    name: "تحديد",
  },
  {
    name: "م",
  },
  {
    name: "اسم المشروع",
  },
  {
    name: "رقم الطلب",
  },
  {
    name: "نوع المشروع",
  },
  {
    name: "تاريخ الاستلام",
  },

  {
    name: "الكود",
  },
  {
    name: "الحالة",
  },
  {
    name: "عرض",
  },
  {
    name: "المطالبة",
  },
];
const columnsInvoices = [
  {
    name: "تحديد",
  },
  {
    name: "م",
  },
  {
    name: "اسم المشروع",
  },
  {
    name: "رقم الطلب",
  },
  {
    name: "نوع المشروع",
  },
  {
    name: "تاريخ الاستلام",
  },

  {
    name: "الكود",
  },

  {
    name: "عرض",
  },
  {
    name: "المطالبة",
  },
  {
    name: "الفاتوره",
  },
];
const RevenusDetails = () => {
  // handle Date
  const [DisplayRevenue, setDisplayRevenue] = useState(false);
  const [DisplayInvoices, setDisplayInvoices] = useState(false);
  const [openClaim, setOpenClaim] = useState(false);
  const { RevenueType } = useParams();
  const [chooseDate, setChooseDate] = useState(false);
  const [cleanValue, setCleanValue] = useState(new Date());
  const [newData, setNewData] = useState([]);
  const [invoicesData, setInvoicesData] = useState([]);
  const [selectedItem, setSelectedItem] = useState("");
  const [clickType, setClickType] = useState(null);

  const [page, setPage] = useState(1);
  const getFinancial = useCallback(async () => {
    const res = await getAllFinancial();
    if (res?.status === 200) {
      setNewData(res?.data?.financials);
    }
  }, []);

  useEffect(() => {
    getFinancial();
  }, [getFinancial]);
  const { data, isLoading, isError } = useGetAllFinancial({
    page,
  });
  const invoicesNewData = useGetAllInvoices({
    page,
  });
  const getInvoices = useCallback(async () => {
    const res = await getAllInvoices();
    if (res?.status === 200) {
      setInvoicesData(res?.data?.invoices);
    }
  }, []);

  useEffect(() => {
    getInvoices();
  }, [getInvoices]);

  const handleCleanValue = (e) => {
    setCleanValue(e);
    setChooseDate(false);
  };

  const {
    accountaingType,
    setAccountaingType,
    openAddAccountant,
    setOpenAddAccountant,
  } = useContext(addAccountType);

  useEffect(() => {
    setAccountaingType(RevenueType);
    setDisplayRevenue(false);
    setOpenAddAccountant(false);
    setDisplayInvoices(false);
    setPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [RevenueType]);
  const handleClose = () => {
    setOpenClaim(false);
  };

  const handleChange = (event, value) => {
    setPage(value);
    // setFilterTasksParams({ page: value });
  };
  const [stats, setStats] = useState();
  const getAllStats = useCallback(async () => {
    const res = await getFinancialStats();
    if (res?.status === 200) {
      setStats(res?.data);
    }
  }, []);
  useEffect(() => {
    getAllStats();
  }, [getAllStats]);
  const [invoicesStats, setInvoicesStats] = useState();
  const getAllInvoicesStats = useCallback(async () => {
    const res = await getAllAccountStats();
    if (res?.status === 200) {
      setInvoicesStats(res?.data);
    }
  }, []);
  useEffect(() => {
    getAllInvoicesStats();
  }, [getAllInvoicesStats]);

  const [pdfLoading, setPdfLoading] = useState(false);
  const [pdfUrl, setPdfUrl] = useState("");

  const getFinancialPdf = async (id) => {
    setPdfLoading(true);
    try {
      const response = await fetch(
        `${config.apiGateway?.URL}/finaical/pdf/${id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/pdf",
            authes: `BSA__${Cookies.get("accessToken")}`,
          },
        }
      );

      if (response.ok) {
        const blob = await response.blob();
        const url = URL.createObjectURL(blob);
        setPdfUrl(url);
      } else {
        console.error("Failed to fetch PDF:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching PDF:", error);
    } finally {
      setPdfLoading(false);
    }
  };

  // const [checked, setChecked] = useState([]);
  // const {
  //   handleShowSuccess,
  //   handleShowError,
  //   handleCloseSuccess,
  //   handleCloseError,
  //   showSuccess,
  //   showError,
  // } = useSuccessfullModal();
  // const {
  //   mutate: deleteMutation,
  //   isLoading: deleteIsLoading,
  //   isError: deleteIsError,
  // } = useDeleteFinancial(() => {
  //   handleShowSuccess();
  //   setChecked([]);
  // }, handleShowError);

  // const handleChecked = (val) => {
  //   if (checked.includes(val)) {
  //     setChecked(checked.filter((item) => item !== val));
  //   } else {
  //     setChecked([...checked, val]);
  //   }
  // };
  // // You have array of ids to delete

  // const handleDeleteAll = () => {};
  // const handleDeleteSelected = () => {
  //   deleteMutation({
  //     financials: checked,
  //   });
  // };

  return (
    <>
      {openAddAccountant && accountaingType === "FinancialClaims" ? (
        <AddFinancialClaims
          getFinancial={getFinancial}
          getAllStats={getAllStats}
        />
      ) : openAddAccountant && accountaingType === "Invoice" ? (
        <AddInvoice getInvoices={getInvoices} />
      ) : (
        <>
          {pdfLoading ? (
            <LoadingPage />
          ) : (
            pdfUrl && (
              <Modal
                show={true}
                onHide={() => setPdfUrl("")}
                className="h-screen"
              >
                <Modal.Body>
                  <Document
                    file={pdfUrl}
                    onLoadSuccess={() => setPdfLoading(false)}
                  >
                    <Page pageNumber={1} />
                  </Document>
                </Modal.Body>
              </Modal>
            )
          )}
          <FileContainer
            files={
              clickType === 0
                ? [selectedItem?.fileFinaical]
                : clickType === 1
                ? selectedItem?.invoiceFile
                : [selectedItem?.financialId?.fileFinaical]
            }
            show={openClaim}
            handleClose={handleClose}
          />
          <FileContainer />
          {chooseDate && (
            <Modal
              className=" InvoiceDate"
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              onHide={() => setChooseDate(false)}
              show={chooseDate}
            >
              <Modal.Body className="d-flex align-items-center">
                <Calendar
                  onChange={handleCleanValue}
                  className={"bg-mainItems"}
                  value={cleanValue}
                />
              </Modal.Body>
            </Modal>
          )}

          {DisplayRevenue ? (
            <DisplayFinancialClaims
              // getFinancial={getFinancial}
              RevenueType
              DisplayRevenue={DisplayRevenue}
              setDisplayRevenue={setDisplayRevenue}
              id={selectedItem?._id}
              getAllStats={getAllStats}
            />
          ) : DisplayInvoices ? (
            <DisplayInvoice
              viewInvoice={DisplayInvoices}
              setViewInvoices={setDisplayInvoices}
              id={selectedItem?._id}
              getAllInvoicesStats={getAllInvoicesStats}
              // getInvoices={getInvoices}
            />
          ) : (
            <div className="flex flex-col justify-between gap-5">
              {RevenueType === "FinancialClaims" ? (
                <div className={`${styles.RevenuesPieChartContainer}   `}>
                  <p className="text-mainText text-start">كل المطالبات</p>
                  {stats && (
                    <PieChart
                      toolbaroffestX=""
                      toolbaroffestY=""
                      colors={["#03795D", "#E40038"]}
                      width={400}
                      labels={[
                        `تم الدفع ${stats?.paid} دفعات`,
                        `لم يتم الدفع ${stats?.unpaid} دفعات`,
                      ]}
                      series={[stats?.paid, stats?.unpaid]}
                    />
                  )}
                </div>
              ) : (
                <div
                  className={`${styles.RevenuesPieChartContainer} invoicesContainer `}
                >
                  <p className="text-mainText  text-xl"> عدد الفواتير</p>

                  <div className="w-75 d-flex justify-content-between ">
                    <div className="d-flex gap-3">
                      {/* <svg
                        onClick={() => {
                          setChooseDate(true);
                        }}
                        className="pointer"
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="16"
                        viewBox="0 0 18 16"
                        fill="none"
                      >
                        <path
                          d="M17.4375 0.438965H0.4375L7.2375 8.21314V13.5877L10.6375 15.2313V8.21314L17.4375 0.438965Z"
                          fill="#D59921"
                        />
                      </svg> */}
                      <p className="text-mainText ">اجمالى عدد الفواتير :</p>
                    </div>
                    <div className="Treasury-container-numbers d-flex justify-content-center text-mainText">
                      <p>{invoicesStats?.invoiceCounts}</p>
                    </div>
                  </div>
                </div>
              )}
              {RevenueType === "FinancialClaims" ? (
                <FinancialTable
                  data={data}
                  getFinancialPdf={getFinancialPdf}
                  handleChange={handleChange}
                  isLoading={isLoading}
                  page={page}
                  setClickType={setClickType}
                  setDisplayRevenue={setDisplayRevenue}
                  setSelectedItem={setSelectedItem}
                />
              ) : (
                <InvoiceTable
                  handleChange={handleChange}
                  invoicesNewData={invoicesNewData}
                  page={page}
                  setClickType={setClickType}
                  setDisplayInvoices={setDisplayInvoices}
                  setOpenClaim={setOpenClaim}
                  setSelectedItem={setSelectedItem}
                />
              )}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default RevenusDetails;

const FinancialTable = ({
  data,
  page,
  handleChange,
  isLoading,
  setSelectedItem,
  setDisplayRevenue,
  setClickType,
  getFinancialPdf,
}) => {
  
  const [checked, setChecked] = useState([]);
  const {
    handleShowSuccess,
    handleShowError,
    handleCloseSuccess,
    handleCloseError,
    showSuccess,
    showError,
  } = useSuccessfullModal();
  const {
    mutate: deleteMutation,
    isLoading: deleteIsLoading,
    isError: deleteIsError,
  } = useDeleteFinancial(() => {
    handleShowSuccess();
    setChecked([]);
  }, handleShowError);

  const handleChecked = (val) => {
    if (checked.includes(val)) {
      setChecked(checked.filter((item) => item !== val));
    } else {
      setChecked([...checked, val]);
    }
  };
  // You have array of ids to delete

  const handleDeleteAll = () => {};
  const handleDeleteSelected = () => {
    deleteMutation({
      financials: checked,
    });
  };
  return (
    <fieldset className={`${styles.RevenuesColumnChartContainer}   `}>
      <legend className="text-mainText text-center">كل المطالبات</legend>

      {deleteIsLoading ? <LoadingPage /> : null}
      <SuccessfullModal
        handleClose={handleCloseSuccess}
        message={"تم الحذف بنجاح"}
        show={showSuccess}
      />
      <SuccessfullModal
        handleClose={handleCloseError}
        message={"حدث خطأ ما"}
        show={showError}
        status="error"
      />
      {CheckRole(roles["admin"]) ? (
        <TableDeleteButton
          checked={checked}
          dataLength={data?.financials?.length}
          onClickDeleteAll={handleDeleteAll}
          onClickDeleteSelected={handleDeleteSelected}
        />
      ) : null}
      <div className="mt-3 min-h-[400px] py-2 overflow-auto scrollbar-none">
        {deleteIsError ? (
          <p className="text-red-500 text-center text-lg">يوجد خطأ ما</p>
        ) : null}
        {deleteIsLoading ? <Progress /> : null}
        <CustomTable
          columns={CheckRole(roles["admin"]) ? columns : columns.slice(1)}
          data={data}
          paginationProps={{
            count: data?.pages,
            page: page,
            onChange: handleChange,
          }}
        >
          {isLoading
            ? null
            : data && data?.financials?.length > 0
            ? data?.financials?.map((item, index) => (
                <TableRow
                  className={`my-2 border !border-[#efaa207f] ${
                    index % 2 === 0 ? "bg-mainNavy" : ""
                  }`}
                  key={index}
                >
                  {CheckRole(roles["admin"]) ? (
                    <TableCell>
                      <CustomCheckboxTable
                        isChecked={checked?.some((x) => x === item?._id)}
                        handleClick={() => handleChecked(item?._id)}
                      />
                    </TableCell>
                  ) : null}
                  <TableCell>{(page - 1) * 10 + (index + 1)}</TableCell>
                  <TableCell>
                    {item?.projectId?.requestId?.projectName}
                  </TableCell>
                  <TableCell>
                    {item?.projectId?.requestId?.orderNumber}
                  </TableCell>
                  <TableCell>
                    {item?.projectId?.requestId?.projectType === 1
                      ? "تصميم"
                      : "اشرف علي التنفيذ"}
                  </TableCell>
                  <TableCell>
                    {moment(item?.dueDate).format("DD-MM-YYYY")}
                  </TableCell>
                  <TableCell>{item?.codeFinaical}</TableCell>
                  <TableCell>
                    {item?.status === "0" ? "لم يتم الدفع" : "تم الدفع"}
                  </TableCell>
                  <TableCell>
                    <img
                      src={process.env.PUBLIC_URL + "/icons/view.svg"}
                      alt=""
                      onClick={() => {
                        setSelectedItem(item);
                        setDisplayRevenue(true);
                      }}
                      className="pointer"
                    />
                  </TableCell>
                  <TableCell>
                    <div
                      className="pointer d-flex justfiy-content-center w-100"
                      onClick={() => {
                        // setSelectedItem(item);
                        // setOpenClaim(true);
                        setClickType(0);
                        getFinancialPdf(item?._id);
                      }}
                    >
                      <div className="p-1 rounded-md">
                        <BillIcon fill={"#efaa20"} />
                      </div>
                    </div>
                  </TableCell>
                </TableRow>
              ))
            : null}
        </CustomTable>
      </div>
    </fieldset>
  );
};
const InvoiceTable = ({
  invoicesNewData,
  page,
  handleChange,
  setSelectedItem,
  setDisplayInvoices,
  setOpenClaim,
  setClickType,
}) => {
  const [checked, setChecked] = useState([]);
  const {
    handleShowSuccess,
    handleShowError,
    handleCloseSuccess,
    handleCloseError,
    showSuccess,
    showError,
  } = useSuccessfullModal();
  const {
    mutate: deleteMutation,
    isLoading: deleteIsLoading,
    isError: deleteIsError,
  } = useDeleteInvoice(() => {
    handleShowSuccess();
    setChecked([]);
  }, handleShowError);
  const handleChecked = (val) => {
    if (checked.includes(val)) {
      setChecked(checked.filter((item) => item !== val));
    } else {
      setChecked([...checked, val]);
    }
  };
  // You have array of ids to delete

  const handleDeleteAll = () => {};
  const handleDeleteSelected = () => {
    deleteMutation({
      invoices: checked,
    });
  };
  return (
    <fieldset className={`${styles.RevenuesColumnChartContainer}   `}>
      <legend className="text-mainText text-center">كل الفواتير</legend>
      {deleteIsLoading ? <LoadingPage /> : null}
      <SuccessfullModal
        handleClose={handleCloseSuccess}
        message={"تم الحذف بنجاح"}
        show={showSuccess}
      />
      <SuccessfullModal
        handleClose={handleCloseError}
        message={"حدث خطأ ما"}
        show={showError}
        status="error"
      />
      {CheckRole(roles["admin"]) ? (
        <TableDeleteButton
          checked={checked}
          onClickDeleteAll={handleDeleteAll}
          onClickDeleteSelected={handleDeleteSelected}
          dataLength={invoicesNewData?.data?.invoices.length}
        />
      ) : null}
      <div className="my-3  overflow-auto scrollbar-none">
        <CustomTable
          columns={
            CheckRole(roles["admin"])
              ? columnsInvoices
              : columnsInvoices.slice(1)
          }
          data={invoicesNewData?.data?.invoices}
          paginationProps={{
            count: invoicesNewData?.data?.pages,
            page: page,
            onChange: handleChange,
          }}
        >
          {invoicesNewData?.data?.invoices &&
          invoicesNewData?.data?.invoices.length > 0
            ? invoicesNewData?.data?.invoices.map((item, index) => (
                <TableRow
                  className={`my-2 border !border-[#efaa207f] ${
                    index % 2 === 0 ? "bg-mainNavy" : ""
                  }`}
                  paginationProps={{
                    count: invoicesNewData?.data?.pages,
                    page: page,
                    onChange: handleChange,
                  }}
                  key={index}
                >
                  {CheckRole(roles["admin"]) ? (
                    <TableCell>
                      <CustomCheckboxTable
                        isChecked={checked?.some((x) => x === item?._id)}
                        handleClick={() => handleChecked(item?._id)}
                      />
                    </TableCell>
                  ) : null}
                  <TableCell>{(page - 1) * 10 + (index + 1)}</TableCell>
                  <TableCell>
                    {item?.projectId?.requestId?.projectName}
                  </TableCell>
                  <TableCell>
                    {item?.projectId?.requestId?.orderNumber}
                  </TableCell>
                  <TableCell>
                    {item?.projectId?.requestId?.projectType === 1
                      ? "تصميم"
                      : "اشرف علي التنفيذ"}
                  </TableCell>
                  <TableCell>
                    {moment(item?.dueDate).format("DD-MM-YYYY")}
                  </TableCell>
                  <TableCell>{item?.codeInvoice}</TableCell>
                  <TableCell>
                    <img
                      src={process.env.PUBLIC_URL + "/icons/view.svg"}
                      alt=""
                      onClick={() => {
                        setSelectedItem(item);
                        setDisplayInvoices(true);
                      }}
                      className="pointer"
                    />
                  </TableCell>
                  <TableCell>
                    <div
                      className="pointer d-flex justfiy-content-center w-100"
                      onClick={() => {
                        setSelectedItem(item);
                        setOpenClaim(true);
                        setClickType(1);
                      }}
                    >
                      <div className="p-1 rounded-md">
                        <BillIcon fill={"#efaa20"} />
                      </div>
                    </div>
                  </TableCell>
                  <TableCell>
                    <div
                      className="pointer d-flex justfiy-content-center w-100"
                      onClick={() => {
                        setSelectedItem(item);
                        setOpenClaim(true);
                        setClickType(2);
                      }}
                    >
                      <div className="p-1 rounded-md">
                        <BillIcon fill={"#efaa20"} />
                      </div>
                    </div>
                  </TableCell>
                </TableRow>
              ))
            : null}
        </CustomTable>
      </div>
    </fieldset>
  );
};
