export const CommentReplyBody = ({comment}) => {
  // console.log('replyed comment',comment);
    return (
      <div className="flex-1 comment-form p-[10px] rounded-md flex flex-col gap-2">
        <p className="text-base font-semibold text-[#E1A945]">{`${comment?.createdBy?.firstName} ${comment?.createdBy?.lastName}`} </p>
        <p className="text-base ">
          {comment?.commentText}
        </p>
      </div>
    );
  };