import { useMutation, useQueryClient } from "react-query";
import { deleteInvoices } from "../../helper/fetchers/invoice";

export const useDeleteInvoice = (onSuccess,onError) => {
    const queryClient = useQueryClient();
    return useMutation(deleteInvoices, {
      onSuccess: () => {
        queryClient.invalidateQueries("invoice");
        onSuccess()
      },
      onError: (error) => {
        onError()
      },
    });
  };