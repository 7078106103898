import { createBrowserRouter } from "react-router-dom";
import { lazy, Suspense } from "react";

import Loading from "./Components/Loading";
import SystemSignIn from "./Pages/System/Auth/SignIn/SystemSignIn";
import "react-time-picker/dist/TimePicker.css";
import "react-clock/dist/Clock.css";

import "react-international-phone/style.css";
import SystemIndex from "./Pages/System/index/SystemIndex";
import AllUsersChart from "./Pages/System/Users/AllUsersChart/AllUsersChart";
import { CountriesChart } from "./Pages/System/Users/AllUsersChart/CountriesChart";
import CountryChart from "./Pages/System/Users/AllUsersChart/CountryChart";
import { AllRequestsCharts } from "./Pages/System/Requests/AllRequestsChart/AllRequestsCharts";
import AllRequests from "./Pages/System/Requests/AllRequests/AllRequests";
import SystemUsers from "./Pages/System/Users/UserDetails/SystemUsers";
import DesignRequest from "./Pages/System/Requests/DesignRequest/DesignRequest";
import DesignCasesRequest from "./Pages/System/Requests/DesignRequest/DesignCasesRequest/DesignCasesRequest";
import ReviewRequest from "./Pages/System/Requests/ReviewRequest.jsx/ReviewRequest";
import ReviewCasesRequest from "./Pages/System/Requests/ReviewRequest.jsx/ReviewCasesRquest/ReviewCasesRequest";
import AllCLients from "./Pages/System/Clients/AllClients/AllCLients";
import AllClientsChart from "./Pages/System/Clients/AllClientsChart/AllClientsChart";
import InsideClients from "./Pages/System/Clients/InsideClients/InsideClients";
import OutSideClients from "./Pages/System/Clients/OutSideClients/OutSideClients";
import ClientDetails from "./Pages/System/Clients/ClientDetails/ClientDetails";
import AllMeetings from "./Pages/System/Meetings/AllMeetings/AllMeetings";

import AllProjects from "./Pages/System/Projects/AllProjects/AllProjects";
import AllProjectsChart from "./Pages/System/Projects/AllProjectsChart/AllProjectsChart";
import MainProjects from "./Pages/System/Projects/MainProjects/MainProjects";
import NestedMainProjects from "./Pages/System/Projects/MainProjects/NestedMainProjects";
import { MainSystem } from "./Pages/System/Main/MainSystem";
import AccountaingIndex from "./Pages/System/Accountaing/AccountaingIndex/AccountaingIndex";
import Treasury from "./Pages/System/Accountaing/Treasury/Treasury";
import Revenues from "./Pages/System/Accountaing/Revenues/Revenues";

import Expenses from "./Pages/System/Accountaing/Expenses/Expenses";
import ExpensesDetails from "./Pages/System/Accountaing/Expenses/ExpensesDetails/ExpensesDetails";
import RevenusDetails from "./Pages/System/Accountaing/Revenues/RevenusDetails/RevenusDetails";
import HrIndex from "./Pages/System/Hr/HrIndex/HrIndex";
import AllEmployees from "./Pages/System/Hr/AllEmployees/AllEmployees";
import HolidayMangment from "./Pages/System/Hr/HolidayMangment/HolidayMangment";
import EmployeesServices from "./Pages/System/Hr/EmployeesServices/EmployeesServices";
import MainEmployees from "./Pages/System/Hr/MainEmployees/MainEmployees";
import EmployeesManagment from "./Pages/System/Hr/EmployeesManagment/EmployeesManagment";
import NestedReportMangment from "./Pages/System/Projects/ReportManagement/NestedReportMangment/NestedReportMangment";
import Settings from "./Pages/System/Settings/Settings";
import Reception from "./Pages/System/Settings/Reception/Reception";
import Orders from "./Pages/System/Settings/Orders/Orders";
import Accounating from "./Pages/System/Settings/Accounating/Accounating";
import CitizenServices from "./Pages/System/Settings/CitizenServices/CitizenServices";
import TimeLine from "./Pages/System/Settings/TimeLine/TimeLine";
import { SettingContextProvider } from "./Context/SettingContext";
import EditProject from "./Pages/System/PlanModel/Projects/EditProject";
import ProjectContextProvier from "./Pages/System/PlanModel/context/ProjectContext";
import TableContextProvder from "./Pages/System/PlanModel/context/TableContext";
import ShowProject from "./Pages/System/PlanModel/Projects/ShowProject";
import Plans from "./Pages/System/Plans";
import PlansProjects from "./Pages/System/Plans/Projects";
import AddProject from "./Pages/System/Plans/Projects/AddProject";
import HrUsers from "./Components/System/Hr/UserDetails/HrUsers";
import ChatIndex from "./Pages/System/Chat/index/ChatIndex";
import AllChats from "./Pages/System/Chat/index/AllChats";
import UserChat from "./Pages/System/Chat/UserChat/UserChat";
import Profile from "./Pages/System/Profile/Profile";
import UserContext from "./Context/userProvider";
import UsersParamsProvider from "./Context/UsersParamsContext";

import ShowTask from "./Pages/System/PlanModel/Projects/ShowTask";
import TaskContextProvier from "./Pages/System/PlanModel/context/TaskContext";
import TasksModel from "./Pages/System/Tasks";
import MainPage from "./Pages/System/Tasks/pages/MainPage";
import ProjectsPage from "./Pages/System/Tasks/pages/ProjectsPage";
import TasksPage from "./Pages/System/Tasks/pages/TasksPage";
import AddPlan from "./Pages/System/Tasks/pages/AddPlan";
import ExternalTasksPage from "./Pages/System/Tasks/pages/ExternalTasksPage";
import ShowExternalTask from "./Pages/System/Tasks/pages/ShowExternalTask";
import AddTask from "./Pages/System/Tasks/pages/AddTask";
import OrdersPage from "./Pages/System/Settings/Orders/Pages/OrdersPage";
import ServicesPage from "./Pages/System/Settings/Orders/Pages/ServicesPage";
import CitizenServicesPage from "./Pages/System/Settings/CitizenServices/pages/CitizenServicesPage";
import VacationsPage from "./Pages/System/Settings/CitizenServices/pages/VacationsPage";
import MainContainer from "./Pages/System/Users/AllUsersChart/MainContainer";
import EditTask from "./Pages/System/PlanModel/Projects/EditTask";
import RootLayout from "./Pages/RootLayout";
import InsideClientsChart from "./Pages/System/Clients/InsideClients/InsideClientsChart";
import RequestsContext from "./Context/requests";
import ErrorPage from "./Pages/Errors/ErrorPage";
import ExpensesCountryDetails from "./Pages/System/Accountaing/Expenses/ExpensesCountryDetails/ExpensesCountryDetails";
import ExternalTaskContextProvier from "./Pages/System/PlanModel/context/ExternalTaskContext";
import ReminderPage from "./Pages/System/reminderPage/ReminderPage";
import ReminderContext from "./Context/ReminderPage";
import { ProjectProvider } from "./Pages/System/Projects/AllProjects/ProjectContext";
import TechnicalSupport from "./Pages/System/Technical-support/TechnicalSupport";
import TechnicalSupportTasks from "./Pages/System/Technical-support/TechnicalSupportTasks";
import TechnicalSupportHome from "./Pages/System/Technical-support/TechnicalSupportHome";
import { TechnicalSupportProvider } from "./Pages/System/Technical-support/components/TechnicalSupportProvider";
import TechnicalSupportOrders from "./Pages/System/Technical-support/TechnicalSupportOrders";
import TechnicalSupportTerms from "./Pages/System/Technical-support/TechnicalSupportTerms";
import TechnicalSupportHelpRequest from "./Pages/System/Technical-support/TechnicalSupportHelpRequest";
import { ReportsModule } from "./Pages/Modules";
import MainReportsPage from "./Pages/Modules/Reports/pages/MainPage";
import ReportRequestsPage from "./Pages/Modules/Reports/pages/RequestsPage";
import ReportProjectsPage from "./Pages/Modules/Reports/pages/ProjectsPage";
import ReportUsersPage from "./Pages/Modules/Reports/pages/UsersPage";
import ForgetPassword from "./Pages/System/Auth/ForgetPassword";
import OTPPage from "./Pages/System/Auth/OTP";
import NewPassword from "./Pages/System/Auth/NewPassword";
import MainUsersReportsPage from "./Pages/Modules/Reports/pages/UsersPage/pages/main";
import UserDetailsReport from "./Pages/Modules/Reports/pages/UsersPage/pages/userDetails";
import UserReportsDepartments from "./Pages/Modules/Reports/pages/UsersPage/pages/UserDepartments";
import UserReportsDepartment from "./Pages/Modules/Reports/pages/UsersPage/pages/UserDepartment";
import ReportManagersPage from "./Pages/Modules/Reports/pages/UsersPage/pages/Managers";
import ReportClientsPage from "./Pages/Modules/Reports/pages/ClientsPage";
import MainClientsReportsPage from "./Pages/Modules/Reports/pages/ClientsPage/pages/main";
import ClientDetailsReport from "./Pages/Modules/Reports/pages/ClientsPage/pages/ClientDetailsReport";
import TestingEmoji from "./Pages/TestingEmoji";
import RequestPage from "./Pages/System/Requests/RequestPage";
import ChatsContextProvider from "./Pages/System/PlanModel/context/ChatsContext";
import ProtectedAuth from "./Components/AuthLayout/ProtectedAuth";
import ShowProjectDetails from "./Components/System/Projects/ShowProjectDetails";

import AddProjectPage from "./Pages/System/Projects/AddProjectPage/AddProjectPage";

const SignUP = lazy(() => import("./Pages/DashBoard/SignUP/SignUP"));
const SignIn = lazy(() => import("./Pages/DashBoard/SignIn/SignIn"));

const ConfirmWithCode = lazy(() =>
  import("./Pages/DashBoard/ConfirmWithCode/ConfirmWithCode")
);
// const ForgetPassword = lazy(() =>
//   import("./Pages/DashBoard/ForgetPassword/ForgetPassword")
// );

const router = createBrowserRouter([
  {
    path: "",
    element: <RootLayout />,
    errorElement: <ErrorPage />,
    children: [
      // For Forms UI Designs
      {
        path: "/emoji-test",
        element: <TestingEmoji />,
      },
      // {
      //   path: "/table-test",
      //   element: <TableTest />,
      // },
      // {
      //   path: "/show-preview",
      //   element: <ShowPreview />,
      // },
      // For Forms UI Designs
      // {
      //   path: "/form",
      //   element: <FormDesign />,
      // },

      // for testing prouprs
      {
        path: "/",
        element: <ProtectedAuth><SystemSignIn /></ProtectedAuth> ,
      },
      {
        path: "/forget-password",
        element: <ForgetPassword />,
      },
      {
        path: "/otp",
        element: <OTPPage />,
      },
      {
        path: "/new-password",
        element: <NewPassword />,
      },
      // // Home
      // {
      //   path: "/home",
      //   element: <Home />,
      // },
      // DashBoard
      {
        path: "/Dashboard/SignUP",
        element: (
          <Suspense fallback={<Loading />}>
            <SignUP />
          </Suspense>
        ),
      },
      {
        path: "/Dashboard/ConfirmWithCode",
        element: (
          <Suspense fallback={<Loading />}>
            <ConfirmWithCode />
          </Suspense>
        ),
      },
      // {
      //   path: "/Dashboard/ForgetPassword",
      //   element: (
      //     <Suspense fallback={<Loading />}>
      //       <ForgetPassword />
      //     </Suspense>
      //   ),
      // },
      {
        path: "/Dashboard/SignIn",
        element: (
          <Suspense fallback={<Loading />}>
            <SignIn />
          </Suspense>
        ),
      },
      //system routes
      {
        path: "/System/SignIn",
        element: <SystemSignIn />,
      },
      {
        path: "",

        element: (
          <UserContext>
            <UsersParamsProvider>
              <SystemIndex />
            </UsersParamsProvider>
          </UserContext>
        ),
        children: [
          // system index (main)
          { path: "/System/index", element: <MainSystem /> },
          { path: "/System/index/:deprtmentType", element: <MainSystem /> },
          //system  users

          {
            path: "",
            element: <AllUsersChart />,
            children: [
              { path: "/System/Users/index", element: <CountriesChart /> },
              {
                path: "/System/AllUsers/Country/:Country/:role?/:departments?",
                element: <MainContainer />,
                children: [
                  {
                    index: true,
                    element: <CountryChart />,
                  },
                  {
                    path: "user/:id",
                    element: <SystemUsers />,
                  },
                ],
              },
            ],
          },
          // system Requests
          {
            path: "",
            element: (
              <RequestsContext>
                <AllRequests />
              </RequestsContext>
            ),
            children: [
              {
                path: "/System/Requests/index",
                element: (
                  <Suspense fallback={<Loading />}>
                    <AllRequestsCharts />
                  </Suspense>
                ),
              },
              {
                path: "System/Requests/Design",
                element: <DesignRequest />,
              },
              {
                path: "System/Requests/:id",
                element: <RequestPage />,
              },
              {
                path: "System/Requests/Design/:status",
                element: <DesignCasesRequest />,
              },
              {
                path: "System/Requests/Review",
                element: <ReviewRequest />,
              },
              {
                path: "System/Requests/Review/:status",
                element: <ReviewCasesRequest />,
              },
              {
                path: "System/Requests/outcoming/Design",
                element: <DesignRequest />,
              },
              {
                path: "System/Requests/outcoming/Design/:status",
                element: <DesignCasesRequest />,
              },
              {
                path: "System/Requests/outcoming/Review",
                element: <ReviewRequest />,
              },
              {
                path: "System/Requests/outcoming/Review/:status",
                element: <ReviewCasesRequest />,
              },
            ],
          },
          // System Clients
          {
            path: "/System/Clients",
            element: <AllCLients />,
            children: [
              { index: true, element: <AllClientsChart /> },
              {
                path: "Inside",
                element: <InsideClients />,
                children: [
                  { index: true, element: <InsideClientsChart /> },
                  { path: ":clientId", element: <ClientDetails /> },
                ],
              },

              { path: "Outside", element: <OutSideClients /> },
              {
                path: "Outside/Design",
                element: <OutSideClients />,
              },
              {
                path: "Outside/Review",
                element: <OutSideClients />,
              },
            ],
          },
          // System Reports
          {
            path: "/System/Reports",
            element: <ReportsModule />,
            children: [
              { index: true, element: <MainReportsPage /> },
              { path: "requests", element: <ReportRequestsPage /> },
              { path: "projects", element: <ReportProjectsPage /> },
              { path: "managers", element: <ReportManagersPage /> },
              {
                path: "users",
                element: <ReportUsersPage />,
                children: [
                  { index: true, element: <MainUsersReportsPage /> },
                  { path: ":id", element: <UserDetailsReport /> },
                  { path: "departments", element: <UserReportsDepartments /> },
                  {
                    path: "departments/:department",
                    element: <UserReportsDepartment />,
                  },
                ],
              },
              {
                path: "clients",
                element: <ReportClientsPage />,
                children: [
                  { index: true, element: <MainClientsReportsPage /> },
                  { path: ":id", element: <ClientDetailsReport /> },
                ],
              },
            ],
          },
          // System Meetings
          { path: "/System/Meetings/index", element: <AllMeetings /> },
          // System Projects
          {
            path: "",
            element: (
              <ProjectProvider>
                <AllProjects />
              </ProjectProvider>
            ),
            children: [
              { path: "/System/Projects/index", element: <AllProjectsChart /> },
              {
                path: "/System/Projects/Main/:ProjectTime",
                element: <MainProjects />,
              },
              {
                path: "/System/Projects/project/:id",
                element: <ShowProjectDetails />,
              },
              {
                path: "/System/Projects/Main/ReportManagement/:projectType",
                element: <NestedReportMangment />,
              },
              {
                path: "/System/Projects/Main/:ProjectTime/:ProjectType",
                element: <NestedMainProjects />,
              },
            ],
          },
          {
            path: "/System/Projects/new-project", 
            element: <AddProjectPage /> 
          },
          // System Accounating
          {
            path: "",
            element: <AccountaingIndex />,
            children: [
              { path: "/System/Accounating/index", element: <Treasury /> },
              { path: "/System/Accounating/Revenues", element: <Revenues /> },
              {
                path: "/System/Accounating/Revenues/:RevenueType",
                element: <RevenusDetails />,
              },
              { path: "/System/Accounating/Expenses", element: <Expenses /> },
              {
                path: "/System/Accounating/Expenses/:country/:status?",
                element: <ExpensesCountryDetails />,
              },
              {
                path: "/System/Accounating/Expenses/items",
                element: <ExpensesDetails />,
              },
            ],
          },
          // System Hr
          {
            path: "",
            element: <HrIndex />,
            children: [
              { path: "/System/Hr/index", element: <MainEmployees /> },
              {
                path: "/System/Hr/EmployeesManagment",
                element: <EmployeesManagment />,
              },
              {
                path: "/System/Hr/Employees",
                element: <AllEmployees />,
              },
              {
                path: "/System/Hr/Employees/:CountryName",
                element: <AllEmployees />,
              },
              {
                path: "/System/Hr/Users/:id",
                element: <HrUsers />,
              },
              {
                path: "/System/Hr/HolidayMangment",
                element: <HolidayMangment />,
              },
              {
                path: "/System/Hr/EmployeesServices",
                element: <EmployeesServices />,
              },
            ],
          },
          {
            path: "/System/reminder",
            element: (
              <ReminderContext>
                <ReminderPage />
              </ReminderContext>
            ),
          },
          {
            path: "/System/technical-support",
            element: (
              <TechnicalSupportProvider>
                <TechnicalSupport />
              </TechnicalSupportProvider>
            ),
            children: [
              {
                index: true,
                element: <TechnicalSupportHome />,
              },
              {
                path: "/System/technical-support/tasks/:status?",
                element: <TechnicalSupportTasks />,
              },
              {
                path: "/System/technical-support/help-request/:type?/:status?",
                element: <TechnicalSupportHelpRequest />,
              },
              {
                path: "/System/technical-support/orders/:type?/:status?",
                element: <TechnicalSupportOrders />,
              },
              {
                path: "/System/technical-support/terms/:type?",
                element: <TechnicalSupportTerms />,
              },
            ],
          },
          //System settings
          {
            path: "/System/Settings",
            element: (
              <SettingContextProvider>
                <Settings />
              </SettingContextProvider>
            ),
            children: [
              { path: "/System/Settings/Reception", element: <Reception /> },
              {
                path: "/System/Settings/Orders",
                element: <Orders />,
                children: [
                  { path: "categories", element: <OrdersPage /> },
                  { path: "services", element: <ServicesPage /> },
                ],
              },
              {
                path: "/System/Settings/Accounating",
                element: <Accounating />,
              },
              {
                path: "/System/Settings/CitizenServices",
                element: <CitizenServices />,
                children: [
                  { path: "vacations", element: <VacationsPage /> },
                  { path: "services", element: <CitizenServicesPage /> },
                ],
              },
              { path: "/System/Settings/TimeLine", element: <TimeLine /> },
            ],
          },
          //System Plans
          {
            path: "/System/tasks",
            element: (
              <ProjectContextProvier>
                <TaskContextProvier>
                  <ExternalTaskContextProvier>
                    <TableContextProvder>
                      <TasksModel />
                    </TableContextProvder>
                  </ExternalTaskContextProvier>
                </TaskContextProvier>
              </ProjectContextProvier>
            ),
            children: [
              { index: true, element: <MainPage /> },
              { path: "plans", element: <ProjectsPage /> },
              { path: "tasks", element: <TasksPage /> },
              { path: "external-tasks", element: <ExternalTasksPage /> },
            ],
          },
          {
            path: "/System/tasks/plans/add-project",
            element: <AddPlan />,
          },
          {
            path: "/System/tasks/plans/edit-project/:projectId",
            element: <EditProject />,
          },
          {
            path: "/System/tasks/tasks/edit-task/:taskId",
            element: <EditTask />,
          },
          {
            path: "/System/tasks/tasks/add-task",
            element: <AddTask />,
          },
          {
            path: "/System/tasks/plans/show-project/:projectId",
            element: <ShowProject />,
          },
          {
            path: "/System/tasks/tasks/show-task/:taskId",
            element: <ShowTask />,
          },
          {
            path: "/System/tasks/show-external-task/:taskId",
            element: <ShowExternalTask />,
          },
          // .................................
          {
            path: "/System/plans",
            element: (
              <ProjectContextProvier>
                <TableContextProvder>
                  <Plans />
                </TableContextProvder>
              </ProjectContextProvier>
            ),
          },
          //System Plans Projects
          {
            path: "/System/plans/:planType",
            element: (
              <ProjectContextProvier>
                <TaskContextProvier>
                  <TableContextProvder>
                    <PlansProjects />
                  </TableContextProvder>
                </TaskContextProvier>
              </ProjectContextProvier>
            ),
          },
          {
            path: "/System/plans/projects/add-project",
            element: <AddProject />,
          },
          {
            path: "/System/plans/tasks/add-task",
            element: <AddTask />,
          },
          {
            path: "/System/plans/show-project/:projectId",
            element: <ShowProject />,
          },
          {
            path: "/System/plans/show-task/:taskId",
            element: <ShowTask />,
          },

          // system Chat
          {
            path: "",
            element: (
              <ChatsContextProvider>
                <ChatIndex />
              </ChatsContextProvider>
            ),
            children: [
              { path: "/System/Chat/index", element: <AllChats /> },
              { path: "/System/Chat/:id", element: <UserChat /> },
            ],
          },
          //Profile
          {
            path: "/Profile",
            element: (
              <UsersParamsProvider>
                <Profile />
              </UsersParamsProvider>
            ),
          },
        ],
      },
    ],
  },
]);
export default router;
