import { useContext, useState } from "react";
import { AllCategories } from "../../../../Components/System/AllCategories/AllCategories";
import { Accordion } from "react-bootstrap";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { MdKeyboardArrowDown } from "react-icons/md";
import PageWrapperAllCLients, {
  AllClientContext,
} from "../components/PageWrapperAllCLients";

const Tabs = ({}) => {
  const [active, setActive] = useState();
  const { setFilterKey } = useContext(AllClientContext);
  const [insideCategories, setInsideCategories] = useState({
    design: false,
    review: false,
  });
  const navigate = useNavigate();

  return (
    <div className="pointer w-full mt-0">
      <div className="   d-flex  justify-content-center flex-column">
        <Accordion defaultActiveKey={null}>
          <Accordion.Item eventKey="0">
            <Accordion.Header
              onClick={() => {
                setActive(0);
              }}
              className={`${
                active === 0
                  ? "filterItem"
                  : "border-2 border-mainBorder rounded-md "
              }`}
            >
              <Link
                className="!w-full  !text-start text-mainText"
                to={"/System/Clients/Inside"}
              >
                الداخلي
              </Link>

              <MdKeyboardArrowDown size={20} />
            </Accordion.Header>

            <Accordion.Body>
              <div className="tabs d-flex justify-content-center align-items-center flex-column">
                <button
                  onClick={() => {
                    navigate("/System/Clients/Inside");
                    setFilterKey({ projectType: 1 });
                    setInsideCategories({
                      design: true,
                      review: false,
                    });
                  }}
                  className="!w-full "
                >
                  <div
                    className={`tab  ${
                      active === 0 &&
                      insideCategories.design &&
                      " border border-1 rounded-md hover:!border-[transparent !border-[#EFAA20]"
                    } text-end w-100 text-mainText`}
                  >
                    تصميم
                  </div>
                </button>
                <button
                  onClick={() => {
                    navigate("/System/Clients/Inside");
                    setFilterKey({ projectType: 2 });
                    setInsideCategories({
                      design: false,
                      review: true,
                    });
                  }}
                  className="!w-full text-start "
                >
                  <div
                    className={`tab  ${
                      active === 0 && insideCategories.review
                        ? " border border-1 rounded-md hover:!border-[transparent !border-[#EFAA20]"
                        : "!border-transparent"
                    } text-end w-100 text-mainText`}
                  >
                    الاشراف علي التنفيذ
                  </div>
                </button>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </div>
  );
};

const Title = () => {
  const { setFilterKey } = useContext(AllClientContext);
  return (
    <div className="mt-4 w-100">
      <button className="!w-full pointer " onClick={() => setFilterKey({})}>
        <p className=" text-mainText ">كل العملاء</p>
      </button>
    </div>
  );
};

const AllCLients = () => {
  return (
    <PageWrapperAllCLients>
      <div className="col-span-3 h-full hidden xl:flex">
        <AllCategories
          child={
            <div className="d-flex  flex-column   align-items-center ">
              <Title />
              <Tabs />
            </div>
          }
        />
      </div>
      <div className="col-span-12 xl:col-span-9 h-full ">
        <Outlet />
      </div>
    </PageWrapperAllCLients>
  );
};

export default AllCLients;
