import React from 'react'

const ChatBoxContainer = ({ children }) => {
    return (
      <div className="w-full mt-2 chatInputContainer bg-mainNavy rounded-[20px] border !border-[#414162]  scrollbar-none px-1">
        {children}
      </div>
    );
  };

export default ChatBoxContainer