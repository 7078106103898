import React from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";

import "./index.css";
import KSACites from "../../../KSACItes";
import Select from "../../../FormHandler/Select";
import { useState } from "react";
import { FormModal } from "../../../../Pages/System/PlanModel/components/FormModal";
import { InputLabel } from "../../../../Pages/System/PlanModel/components/InputLabel";
import { getDesignRequestsWithid } from "../../../../helper/fetchers/Requests";
import AddAttachment from "../../AddAttachment";
import ShowLocalFiles from "../../../ShowLocalFiles";
import ShowFiles from "../../../ShowFiles";
import SuccessfullModal from "../../../Modals/SuccessfullModal";
import { useForm, Controller } from "react-hook-form";
import { CustomDatePicker, CustomInput } from "../../../FormUi";
import { PhoneInput } from "react-international-phone";
import useSuccessfullModal from "../../../Modals/hooks/useSuccessfullModal";
import {
  useGetRequestsWithid,
  useUpdateRequestwithId,
} from "../../../../hooks/fetchers/Requests";
import LoadingPage from "../../../feedback/LoadingPage";

const clientTypeRoles = [
  {
    label: "فردي",
    value: 3,
  },
  {
    label: "شركه  او مؤسسة",
    value: 2,
  },
  {
    label: "حكومي او مستثمر",
    value: 1,
  },
];
const projectTypeRoles = [
  {
    label: "إشراف على التنفيذ",
    value: 2,
  },
  {
    label: "تصميم",
    value: 1,
  },
];
const IdentityTypeRoles = [
  {
    label: "سجل تجاري",
    value: 2,
  },
  {
    label: "هوية",
    value: 1,
  },
];
const mappedCity = KSACites?.map((item) => {
  return {
    value: item?.arLabel,
    label: item?.arLabel,
  };
});
const EditReviewRequest = ({ editRequest, setEditRequest, id }) => {
  const [request, setRequest] = useState({});
  const { handleSubmit, control, watch } = useForm({
    defaultValues: id
      ? async () => {
          const {
            data: { request },
          } = await getDesignRequestsWithid(id);
          setRequest(request);
          console.log("getDesignRequestsWithid: ", request);
          console.log(
            IdentityTypeRoles?.find(
              ({ value }) => value == request?.identityType
            )
          );
          //  agent,agencyNumber,instrumentNumber,servicesId,subservicesId,categoryId,subcategoryId,agencyAttachments
          return {
            ownerName: request?.ownerName,
            serviceNumber: request?.servicenumber,
            buildingLocation: request?.buildingLocation,
            transactionNumber: request?.transactionNumber,
            locationMap: request?.locationMap,
            projectName: request?.projectName,
            city: mappedCity?.find(({ value }) => value === request?.city),
            area: request?.area,
            pieceNumber: request?.pieceNumber,
            chartNumber: request?.chartNumber,
            identityNumber: request?.identityNumber,
            clientType: clientTypeRoles?.find(
              ({ value }) => value === request?.clientType
            ),
            identityType: IdentityTypeRoles?.find(
              ({ value }) => value == request?.identityType
            ),
            phone: request?.phone,
            commercialRegNo:
              +request?.identityType === 2 && request?.commercialRegNo
                ? request?.commercialRegNo
                : null,
            email: request?.email,
            taxNumber: request?.taxNumber,
            licenseNumber: request?.licenseNumber,
            licenseDeed: request?.licenseDeed,
            licenseDate: new Date(request?.licenseDate),
          };
        }
      : undefined,
  });

  const [instrumentImage, setInstrumentImage] = useState();
  const [licenseAttachments, setLicenseAttachments] = useState(null);
  const [financialAttachments, setfinancialAttachments] = useState(null);
  const [techAttachments, setTechAttachments] = useState(null);
  const [idPhoto, setIdPhoto] = useState(null);

  const { data: requestItem } = useGetRequestsWithid(id);

  const filesID = idPhoto ? [...idPhoto] : [];
  const filesInstrument = instrumentImage ? [...instrumentImage] : [];
  const filesAttachment = licenseAttachments ? [...licenseAttachments] : [];
  const filesfinancialAttachments = financialAttachments
    ? [...financialAttachments]
    : [];
  const filestechAttachments = techAttachments ? [...techAttachments] : [];

  const {
    handleShowSuccess,
    handleShowError,
    handleCloseSuccess,
    handleCloseError,
    showSuccess,
    showError,
  } = useSuccessfullModal();
  const { mutate: updateMutation, isLoading: updateIsLoading } =
    useUpdateRequestwithId(id, handleShowSuccess, handleShowError);

  const manipulateFormData = (data) => {
    data.city = data?.city?.value;
    data.clientType = data?.clientType?.value;
    data.identityType = data?.identityType?.value;

    data.licenseDate = Date(data.licenseDate);
    return data;
  };
  const onSubmit = async (data) => {
    const formData = new FormData();
    data = manipulateFormData(data);
    Object.keys(data).forEach((key) => {
      if (data[key]) {
        formData.append(key, data[key]);
      } else {
        formData.append(key, "");
      }
    });
    if (idPhoto && idPhoto?.length > 0) {
      filesID.forEach((file) => {
        formData.append(`idPhoto`, file);
      });
    }
    if (instrumentImage && instrumentImage?.length > 0) {
      filesInstrument.forEach((file) => {
        formData.append(`instrumentImage`, file);
      });
    }
    if (licenseAttachments && licenseAttachments?.length > 0) {
      filesAttachment.forEach((file) => {
        formData.append(`licenseAttachments`, file);
      });
    }
    if (+requestItem?.status !== 0) {
      if (financialAttachments && financialAttachments?.length > 0) {
        filesfinancialAttachments.forEach((file) => {
          formData.append(`financialFiles`, file);
        });
      }
      if (techAttachments && techAttachments?.length > 0) {
        filestechAttachments.forEach((file) => {
          formData.append(`techFiles`, file);
        });
      }
    }
    updateMutation(formData);
    console.log(data);
  };

  return (
    <div>
      {updateIsLoading ? <LoadingPage /> : null}
      {editRequest && (
        <>
          <SuccessfullModal
            message={"تم تعديل الطلب بنجاح"}
            show={showSuccess}
            handleClose={() => {
              handleCloseSuccess();
              setEditRequest(false);
            }}
          />
          <SuccessfullModal
            message={"حدث خطا ما"}
            show={showError}
            handleClose={handleCloseError}
            status="error"
          />

          <EditReviewRequestWrapper
            show={editRequest}
            onHide={() => setEditRequest(false)}
          >
            <div className="mx-4">
              <Form
                onSubmit={handleSubmit(onSubmit)}
                className="row flex-col gap-4 w-100 m-auto "
              >
                {/* ************************************ */}

                <FormModal title={"بيانات المشروع"}>
                  <div className="row w-100">
                    <div className=" col-md-7 mb-4">
                      <div className="flex flex-col">
                        <InputLabel label={"اسم المالك"} mandatory={true} />
                        <CustomInput
                          control={control}
                          name="ownerName"
                          placeholder="اكتب اسم المالك"
                        />
                      </div>
                    </div>
                    <div className=" col-md-7 mb-4">
                      <div className="flex flex-col">
                        <InputLabel label={"رقم الخدمة"} mandatory={true} />
                        <CustomInput
                          control={control}
                          name="serviceNumber"
                          placeholder="اكتب رقم الخدمة"
                        />
                      </div>
                    </div>
                    <div className=" col-md-7 mb-4">
                      <div className="flex flex-col">
                        <InputLabel
                          label={"رقم طلب المعاملة"}
                          mandatory={true}
                        />
                        <CustomInput
                          control={control}
                          name="transactionNumber"
                          placeholder="اكتب رقم طلب المعاملة"
                        />
                      </div>
                    </div>
                    <div className=" col-md-7 mb-4">
                      <div className="flex flex-col">
                        <InputLabel label={"اسم المشروع"} mandatory={true} />
                        <CustomInput
                          control={control}
                          name="projectName"
                          placeholder="اكتب اسم المشروع"
                        />
                      </div>
                    </div>
                    <div className=" col-md-7 mb-4">
                      <div className="flex flex-col">
                        <InputLabel label={"موقع المشروع"} mandatory={true} />
                        <CustomInput
                          control={control}
                          name="buildingLocation"
                          placeholder="اكتب موقع المشروع"
                        />
                      </div>
                    </div>
                    <div className=" col-md-7 mb-4">
                      <div className="flex flex-col">
                        <InputLabel
                          label={"الموقع الجغرافى"}
                          mandatory={true}
                        />
                        <CustomInput
                          control={control}
                          name="locationMap"
                          placeholder="اكتب الموقع الجغرافى"
                        />
                      </div>
                    </div>
                    <div className=" col-md-6 mb-4">
                      <div className="flex flex-col">
                        <InputLabel label={"المدينة"} mandatory={true} />
                        <Controller
                          control={control}
                          name="city"
                          rules={{ required: "من فضلك أختر المدينة" }}
                          render={({
                            field: { onChange, onBlur, ref, value },
                          }) => (
                            <Select
                              fullWidth
                              ref={ref}
                              value={value}
                              onChange={onChange}
                              onBlur={onBlur}
                              OptionbackgroundColor="#414162"
                              placeholder="اختر اسم المدينة"
                              options={mappedCity}
                            />
                          )}
                        />
                      </div>
                    </div>

                    <div className=" col-md-6 mb-4">
                      <div className="flex flex-col">
                        <InputLabel label={"الحي"} mandatory={true} />

                        <CustomInput
                          control={control}
                          name="area"
                          placeholder="أدخل الحي"
                        />
                      </div>
                    </div>

                    <div className=" col-md-6 mb-4">
                      <div className="flex flex-col">
                        <InputLabel label={"رقم القطعة"} mandatory={true} />

                        <CustomInput
                          control={control}
                          name="pieceNumber"
                          placeholder="أدخل رقم القطعة"
                        />
                      </div>
                    </div>
                    {watch("identityType")?.value === 2 ? (
                      <div className=" col-md-6 mb-4">
                        <div className="flex flex-col">
                          <InputLabel
                            label={"رقم السجل التجارى"}
                            mandatory={true}
                          />
                          <CustomInput
                            control={control}
                            name={"commercialRegNo"}
                          />
                        </div>
                      </div>
                    ) : null}
                    <div className=" col-md-6 mb-4">
                      <div className="flex flex-col">
                        <InputLabel label={"رقم المخطط"} mandatory={true} />

                        <CustomInput
                          control={control}
                          name="chartNumber"
                          placeholder="أدخل رقم المخطط"
                        />
                      </div>
                    </div>
                  </div>
                </FormModal>
                {/* ************************************ */}
                <FormModal title={"بيانات المشروع"}>
                  <div className="row  w-100">
                    <div className="col-md-6 mb-4">
                      <div className="flex flex-col">
                        <InputLabel label={"نوع العميل"} mandatory={true} />
                        <Controller
                          control={control}
                          name="clientType"
                          rules={{ required: "من فضلك أختر نوع العميل" }}
                          render={({
                            field: { onChange, onBlur, ref, value },
                          }) => (
                            <Select
                              fullWidth
                              ref={ref}
                              value={value}
                              onChange={onChange}
                              onBlur={onBlur}
                              OptionbackgroundColor="#414162"
                              placeholder="اختر نوع العميل"
                              options={clientTypeRoles}
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-md-6 mb-4">
                      <div className="flex flex-col">
                        <InputLabel label={"نوع الهوية"} mandatory={true} />
                        <Controller
                          control={control}
                          name="identityType"
                          rules={{ required: "من فضلك أختر نوع الهوية" }}
                          render={({
                            field: { onChange, onBlur, ref, value },
                          }) => (
                            <Select
                              fullWidth
                              ref={ref}
                              value={value}
                              onChange={onChange}
                              onBlur={onBlur}
                              OptionbackgroundColor="#414162"
                              placeholder="اختر اسم نوع الهوية"
                              options={IdentityTypeRoles}
                            />
                          )}
                        />
                      </div>
                    </div>

                    <div className="col-md-6 mb-4">
                      <Form.Group controlId="formBasicImage">
                        <Form.Label className="d-flex gap-2 align-items-center text-mainText">
                          صورة الهويه
                        </Form.Label>

                        <div className="flex items-center gap-3">
                          <AddAttachment
                            attachment={idPhoto}
                            setAttachment={setIdPhoto}
                            multi={false}
                          />
                          {idPhoto ? (
                            <ShowLocalFiles files={Array.from(idPhoto)} />
                          ) : (
                            <ShowFiles files={requestItem?.idPhoto} />
                          )}
                        </div>
                      </Form.Group>
                    </div>

                    <div className="col-md-6 mb-4">
                      <div className="flex flex-col">
                        <InputLabel
                          label={"البريد الالكتروني"}
                          mandatory={true}
                        />

                        <CustomInput
                          control={control}
                          name="email"
                          placeholder="أدخل البريد الالكتروني"
                        />
                      </div>
                    </div>
                    <div className="col-md-6 mb-4">
                      <div className="flex flex-col">
                        <InputLabel label={"رقم الهوية"} mandatory={true} />

                        <CustomInput
                          control={control}
                          name="identityNumber"
                          placeholder="أدخل رقم الهوية"
                        />
                      </div>
                    </div>

                    <div className="col-md-6 mb-4">
                      <div className="flex flex-col">
                        <InputLabel label={"رقم الجوال"} />
                        <Controller
                          control={control}
                          name={`phone`}
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <PhoneInput
                              defaultCountry="sa"
                              placeholder="ادخل رقم الجوال"
                              value={value}
                              className="bg-mainNavy"
                              onChange={onChange}
                              onBlur={onBlur}
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-md-6 mb-4">
                      <div className="flex flex-col">
                        <InputLabel
                          label={"رقم الشهادة الضربية"}
                          mandatory={true}
                        />

                        <CustomInput
                          control={control}
                          name="taxNumber"
                          placeholder="أدخل رقم الشهادة الضربية"
                        />
                      </div>
                    </div>
                  </div>
                </FormModal>
                <FormModal title={"بيانات الرخصة"}>
                  <div className="row  w-100">
                    <div className="col-md-6 mb-4">
                      <div className="flex flex-col">
                        <InputLabel label={"رقم الرخصة"} mandatory={true} />

                        <CustomInput
                          control={control}
                          name="licenseNumber"
                          placeholder="أدخل رقم الرخصة"
                        />
                      </div>
                    </div>

                    <div className="col-md-6  mb-4">
                      <div>
                        <div className="flex flex-col">
                          <InputLabel label={"تاريخ الرخصة"} mandatory={true} />

                          <CustomDatePicker
                            control={control}
                            name="licenseDate"
                            placeholder="أدخل تاريخ الرخصة"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6 mb-4">
                      <div className="flex flex-col">
                        <InputLabel label={"سند الرخصة"} mandatory={true} />

                        <CustomInput
                          control={control}
                          name="licenseDeed"
                          placeholder="أدخل سند الرخصة"
                        />
                      </div>
                    </div>
                    <div className="col-md-12 mb-4">
                      <div className="flex flex-col">
                        <InputLabel label={"الوصف المرفق"} mandatory={false} />

                        <CustomInput
                          control={control}
                          name="notes"
                          placeholder="أدخل الوصف المرفق"
                          multiline={true}
                          rows={5}
                        />
                      </div>
                    </div>

                    <div className="col-md-6 mb-4">
                      <Form.Group controlId="formBasicImage">
                        <Form.Label className="d-flex gap-2 align-items-center text-mainText">
                          صورة الصك
                        </Form.Label>

                        <div className="flex items-center gap-3">
                          <AddAttachment
                            attachment={instrumentImage}
                            setAttachment={setInstrumentImage}
                            multi={false}
                          />
                          {instrumentImage ? (
                            <ShowLocalFiles
                              files={Array.from(instrumentImage)}
                            />
                          ) : (
                            <ShowFiles files={requestItem?.instrumentImage} />
                          )}
                        </div>
                      </Form.Group>
                    </div>
                    <div className="col-md-6 mb-4">
                      <Form.Group controlId="formBasicImage">
                        <Form.Label className="d-flex gap-2 align-items-center text-mainText">
                          المستندات
                        </Form.Label>

                        <div className="flex items-center gap-3">
                          <AddAttachment
                            attachment={licenseAttachments}
                            setAttachment={setLicenseAttachments}
                            multi={false}
                          />
                          {licenseAttachments ? (
                            <ShowLocalFiles
                              files={Array.from(licenseAttachments)}
                            />
                          ) : (
                            <ShowFiles
                              files={requestItem?.licenseAttachments}
                            />
                          )}
                        </div>
                      </Form.Group>
                    </div>
                  </div>
                </FormModal>
                {+requestItem?.status !== 0 ? (
                  <>
                    <FormModal title={"ملفات العرض المالى"}>
                      <div className="flex items-center gap-3">
                        <AddAttachment
                          attachment={financialAttachments}
                          setAttachment={setfinancialAttachments}
                          multi={false}
                        />
                        {financialAttachments ? (
                          <ShowLocalFiles
                            files={Array.from(financialAttachments)}
                          />
                        ) : (
                          <ShowFiles files={requestItem?.financialFiles} />
                        )}
                      </div>
                    </FormModal>
                    <FormModal title={"ملفات العرض الفنى"}>
                      <div className="flex items-center gap-3">
                        <AddAttachment
                          attachment={techAttachments}
                          setAttachment={setTechAttachments}
                          multi={false}
                        />
                        {techAttachments ? (
                          <ShowLocalFiles files={Array.from(techAttachments)} />
                        ) : (
                          <ShowFiles files={requestItem?.techFiles} />
                        )}
                      </div>
                    </FormModal>
                  </>
                ) : null}
                <Button
                  type="submit"
                  className="sumbmitAddUpdateUser self-center "
                >
                  {updateIsLoading ? <Spinner /> : "حفظ"}
                </Button>
              </Form>
            </div>
          </EditReviewRequestWrapper>
        </>
      )}
    </div>
  );
};

const EditReviewRequestWrapper = ({ children, onHide, ...modalProps }) => {
  return (
    <Modal
      size="lg"
      aria-labelledby=" example-modal-sizes-title-lg"
      className="systemModal bg-black/50"
      contentClassName="overflow-y-auto scrollbar-thin scrollbar-track-mainNavy scrollbar-thumb-[#E4A11B]"
      onHide={onHide}
      {...modalProps}
    >
      <Modal.Header className="w-full !justify-between border-none">
        <div className=""></div>
        <ModalTitle title={"تعديل فى طلب الاشراف"} />
        <Button
          className="p-0 bg-transparent hover:bg-transparent"
          onClick={onHide}
        >
          <img src="/Rejected.svg" alt="" />
        </Button>
      </Modal.Header>

      {children}
    </Modal>
  );
};
const ModalTitle = ({ title }) => {
  return (
    <Modal.Title className="text-xl text-[#EFAA20] border !border-[#EFAA20] p-2 rounded-[5px] font-medium ">
      {title}
    </Modal.Title>
  );
};
export default EditReviewRequest;
