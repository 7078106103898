import ReminderPageClock from "./ReminderPageClock";
// Ensure you have this to apply default styles
import ReminderCalendar from "./ReminderCalendar";

const ReminderPageTop = ({ data }) => {
  return (
    <div className="border !border-[#efaa20] w-full py-8 px-4 rounded-md bg-mainItems flex justify-around">
      <ReminderCalendar data={data} />
      <ReminderPageClock />
    </div>
  );
};

export default ReminderPageTop;
