import { useEffect, useState } from 'react';
import { myAxiosJson } from '../../../../helper/https';

const useGetUserProfile = (userId) => {
    const [myData, setMyData] = useState([]);
    const [isLoadingProfileUser, setIsLoadingProfileUser] = useState(false);
  
    useEffect(() => {
      // setIsErrorProfileUser(false)
      setIsLoadingProfileUser(true);
      myAxiosJson
        .get(`user/profile`)
        .then(({ data }) => {
          setMyData(data.user);
        })
        .catch((err) => {
          // setIsErrorProfileUser(true)
          console.log(err);
        });
      setIsLoadingProfileUser(false);
    }, [userId]);
  
    return {
      myData,
      isLoadingProfileUser,
    };
  };

export default useGetUserProfile