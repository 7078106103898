import React, { useState, useEffect } from "react";
import AuthWrapper from "../components/AuthWrapper";
import AuthCard from "../components/AuthCard";
import { Form } from "react-bootstrap";
import SubmitButton from "../components/SubmitButton";
import Image from "../../../../Components/Image";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import {
  useResetPassword,
  useVerifyOTP,
} from "../../../../hooks/fetchers/auth";
import useSuccessfullModal from "../../../../Components/Modals/hooks/useSuccessfullModal";
import LoadingPage from "../../../../Components/feedback/LoadingPage";
import SuccessfullModal from "../../../../Components/Modals/SuccessfullModal";

const OTPPage = () => {
  const navigate = useNavigate();
  const [resend, setResend] = useState(false);
  const [counter, setCounter] = useState(60); // Initial counter value (60 seconds)
  const {
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: { otp: Array(4).fill("") },
  });

  const otp = watch("otp");

  const handleChange = (value, index) => {
    const otpCopy = [...otp];
    otpCopy[index] = value;
    setValue("otp", otpCopy);

    if (value && index < otp.length - 1) {
      document.getElementById(`otp-${index + 1}`)?.focus();
    }
  };
  const [err, setError] = useState("");

  const {
    showSuccess,
    showError,
    handleShowSuccess,
    handleShowError,
    handleCloseSuccess,
    handleCloseError,
  } = useSuccessfullModal();

  const { mutate, isLoading } = useVerifyOTP(handleShowSuccess, (error) => {
    console.log(error, "error");
    handleShowError();
    setError(error?.response?.data?.message);
  });

  const onSubmit = (data) => {
    console.log(data.otp.join(""));
    const newData = { verificationCode: data.otp.join("") };
    mutate(newData);
  };
  const [resetSuccess, setResetSuccess] = useState(false);
  const { mutate: resetMutate, isLoading: resetLoading } = useResetPassword(
    () => setResetSuccess(true),
    (error) => {
      handleShowError();
      setError(error?.response?.data?.message);
    }
  );

  useEffect(() => {
    let timer;
    if (counter > 0) {
      timer = setTimeout(() => setCounter(counter - 1), 1000);
    }
    return () => clearTimeout(timer);
  }, [counter]);

  const handleResend = () => {
    resetMutate({
      email: Cookies.get("forgotPasswordEmail"),
    });
    setCounter(60); // Reset counter after resending OTP
  };

  return isLoading || resetLoading ? (
    <LoadingPage />
  ) : (
    <AuthWrapper>
      <SuccessfullModal
        handleClose={handleCloseError}
        message={err}
        show={showError}
        status="error"
      />
      <SuccessfullModal
        handleClose={() => {
          handleCloseSuccess();
        }}
        message={"الرجاء تفقد حسابك لتغيير كلمه المرور..."}
        show={showSuccess}
      />
      <SuccessfullModal
        handleClose={() => {
          setResetSuccess(false);
        }}
        message={"تم اعاده ارسال رمز التحقق الي بريدك الالكتروني"}
        show={resetSuccess}
      />
      <AuthCard>
        <Form onSubmit={handleSubmit(onSubmit)} className="w-75 h-100">
          <div className="w-full">
            <Image
              src="https://bsa2011.com/images/systemLogin.gif"
              // style={{ width: "279px", height: "214px" }}
              alt="logo image"
              width={250}
              height={150}
              className={"pointer-events-none"}
            />
          </div>
          <div className="w-full">
            <Image
              src="/ConfirmMail.png"
              // style={{ width: "379px", height: "214px" }}
              alt="logo image"
              className={"pointer-events-none"}
              width={150}
              height={150}
            />
          </div>
          <div className="mb-3 space-y-2">
            <h2 className="font-medium text-4xl">{"ادخل الرمز التحقيقي"}</h2>
            <p className="font-normal text-base">
              {`تم ارسال “الرمز التحقيقي” `}{" "}
              <span className="text-[#6A0411] hover:!text-[#6A0411] text-sm font-medium">
                {" "}
                {Cookies.get("forgotPasswordEmail")}{" "}
              </span>
            </p>
          </div>

          <Form.Group dir="ltr" className="space-x-2">
            {otp.map((_, index) => (
              <Controller
                key={index}
                name={`otp.${index}`}
                control={control}
                rules={{ required: true, pattern: /^[0-9]$/ }}
                render={({ field: { name, onBlur, onChange, ref, value } }) => (
                  <input
                    ref={ref}
                    name={name}
                    value={value}
                    onBlur={onBlur}
                    id={`otp-${index}`}
                    type="text"
                    maxLength={1}
                    onChange={(e) => {
                      onChange(e);
                      handleChange(e.target.value, index);
                    }}
                    className={`w-10 h-10 text-center border dark:bg-primary-dark dark:text-white ${
                      errors.otp?.[index] ? "border-red-500" : "border-gray-300"
                    } rounded focus:outline-none focus:ring-1 `}
                  />
                )}
              />
            ))}
          </Form.Group>

          <div className="flex justify-center items-center mt-4">
            <SubmitButton>{"ارسال"}</SubmitButton>
          </div>

          <div className="flex justify-center items-center mt-4">
            <button
              className="w-full text-center py-1 text-[#6A0411] hover:!text-[#6A0411] text-sm font-normal pt-4"
              onClick={handleResend}
              disabled={counter > 0}
            >
              {counter > 0 ? `إعادة إرسال OTP (${counter})` : "إعادة إرسال OTP"}
            </button>
          </div>
        </Form>
      </AuthCard>
    </AuthWrapper>
  );
};

export default OTPPage;
