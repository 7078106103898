import React, { createContext, Fragment, useContext, useState } from "react";
import {
  useGetUserGoalsReportById,
  useGetUserKPIsReportById,
  useGetUserReport,
  useGetUserSalaryReport,
  useGetUserServicesReport,
  useGetUserTasksReportById,
  useGetUserVacationsReport,
} from "../../../../../../hooks/fetchers/Users";
import ProgressBar from "../../../components/ProgressBar";
import { useParams } from "react-router-dom";

import DownloadButton from "../../../../../../Components/Buttons/DownloadButton";
import { Avatar } from "@mui/material";
import { Tab, Tabs } from "@mui/material";
import { FormModal } from "../../../../../System/PlanModel/components/FormModal";
import moment from "moment";
import ShowFiles from "../../../../../../Components/ShowFiles";
import CustomTable from "../../../../../../Components/Table";
import { TableRow } from "../../../../../../Components/Table/TableRow";
import { TableCell } from "../../../../../../Components/Table/TableCell";
import {
  convertDateFormat,
  GenerateColumnsNames,
  projectTypeEnum,
} from "../../../../../../helper/utils";
import { Spinner } from "react-bootstrap";
import ExcellModall from "../../../../../../Components/Modals/ExcellModall";
import useExportExcel from "../../../../../../hooks/useExportExcel";
import ApexColumn from "../components/ApexColumn";
import Image from "../../../../../../Components/Image";
import VacationModal from "../components/VacationModal";
import useModal from "../../../../../../hooks/useModal";
import TaskCard from "../../../components/TaskCard";
import { a11yProps, genderEnum } from "../../../consts";
import TabPanel from "../../../components/TabPanel";
import { ImPrinter } from "react-icons/im";
import { TiExport } from "react-icons/ti";
import PrintUserDetailsFile from "../../../components/PrintUserDetailsFile";
import ProfileImage from "../../../../../../Components/ProfileImage";

const UserDetailsReport = () => {
  const [isLate, setIsLate] = useState(null);
  const [taskStatus, setTaskStatus] = useState(null);
  const { id } = useParams();
  const { data, isLoading, isError } = useGetUserReport(id);
  const {
    data: dataSalary,
    isLoading: isLoadingSalary,
    isError: isErrorSalary,
  } = useGetUserSalaryReport(id);
  const {
    data: dataServices,
    isLoading: isLoadingServices,
    isError: isErrorServices,
  } = useGetUserServicesReport(id);
  const {
    data: dataGoals,
    isLoading: isLoadingGoals,
    isError: isErrorGoals,
  } = useGetUserGoalsReportById(id);
  const {
    data: dataTasks,
    isLoading: isLoadingTasks,
    isError: isErrorTasks,
  } = useGetUserTasksReportById(id, { isLate: isLate, status: taskStatus });
  const {
    data: dataKPIs,
    isLoading: isLoadingKPIs,
    isError: isErrorKPIs,
  } = useGetUserKPIsReportById(id);
  const {
    data: dataVacations,
    isLoading: isLoadingVacations,
    isError: isErrorVacations,
  } = useGetUserVacationsReport(id);
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const { loading, allKeys, excelData, fileUrl, handleExport, resetExcelData } =
    useExportExcel();
  const {
    open: openPdf,
    handleOpenModal: handleOpenPdfModal,
    handleCloseModal: handleClosePdfModal,
  } = useModal();
  return (
    <div className="p-2">
      {!loading && excelData && (
        <ExcellModall
          onHide={resetExcelData}
          fileUrl={fileUrl}
          keys={allKeys}
          data={excelData}
        />
      )}

      {!isLoading && !isError && data ? (
        <Fragment>
          <PrintUserDetailsFile
            show={openPdf}
            onHide={handleClosePdfModal}
            data={[
              {
                ...data,
                tasks: dataTasks?.tasks,
                tasksCount: dataTasks?.count,
                tasksStats: dataTasks?.tasksStats,
                vacations: dataVacations,
                salary: dataSalary,
              },
            ]}
          />
          <div className="flex justify-between items-center p-3">
            <div className="">
              <p className="text-[#EFAA20] text-base font-medium">{`${data?.role} / ${data?.department}`}</p>
            </div>
            <div className="flex items-center gap-2">
              <DownloadButton
                onClick={() => handleExport(`system-reports/users/${id}/excel`)}
              >
                <div className="flex items-center gap-1">
                  <span>تصدير Excel</span>
                  <TiExport />
                </div>
              </DownloadButton>
              <DownloadButton
                onClick={() => {
                  setTaskStatus(null);
                  setIsLate(null);
                  handleOpenPdfModal();
                }}
              >
                <div className="flex items-center gap-1">
                  <span>طباعة</span>
                  <ImPrinter />
                </div>
              </DownloadButton>
            </div>
          </div>
          <div className="flex items-center gap-4 p-3">
            <ProfileImage
              image={data?.image}
              imageClassName="user-Personal"
              alt="user Image"
            />
            <div className="">
              <p className="text-[#EFAA20] font-medium">{`${data?.firstName} ${data?.lastName}`}</p>
              <p className="text-mainText">{data?.email}</p>
              <p className="text-mainText">
                <span dir="ltr">{data?.phone}</span>
              </p>
            </div>
          </div>
          <div className="flex justify-center " dir="">
            <Tabs
              value={value}
              onChange={handleChange}
              // variant="scrollable"
              variant="standard"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
              className="text-mainText"
            >
              <Tab
                className="!text-mainText"
                {...a11yProps(0)}
                label="عن الموظف"
              />
              <Tab
                className="!text-mainText"
                {...a11yProps(1)}
                label="معلومات مهنية"
              />
              <Tab
                className="!text-mainText"
                {...a11yProps(2)}
                label="معلومات الراتب"
              />
              <Tab
                className="!text-mainText"
                {...a11yProps(3)}
                label="الخدمات المخصصه"
              />
            </Tabs>
          </div>
          <div className="max-h-[650px] overflow-y-auto scrollbar-thin scrollbar-track-transparent scrollbar-thumb-[#EFAA20]">
            <TabPanel value={value} index={0}>
              <div className="space-y-4">
                <FormModal
                  title={"المعلومات العامة"}
                  titleClassName="!w-fit px-2"
                  className="space-y-4"
                >
                  <div className="flex">
                    <p className="w-1/2">
                      {"الاسم الأول : "} {data?.firstName}
                    </p>

                    <p className="w-1/2">
                      {"الاسم الأخير : "} {data?.lastName}
                    </p>
                  </div>
                  <p>
                    {"الاسم  المستخدم : "} {data?.userName}
                  </p>
                  <p>
                    {"البريد الأكترونى: "} {data?.email}
                  </p>
                  <p>
                    {"رقم الجوال : "} {data?.phone}
                  </p>
                  <p>
                    {"تاريخ الميلاد : "}
                    {moment(data?.dateOfBirth).format("YYYY-MM-DD")}
                  </p>
                  <p>
                    {"النوع : "} {genderEnum[data?.gender]}
                  </p>
                  <p>
                    {"الكود الوظيفى : 1235"} {data?.employeCode}
                  </p>
                  <p>
                    {"البلد: "} {data?.country}
                  </p>
                </FormModal>
                <FormModal className="space-y-4">
                  <div className="flex">
                    <p className="w-1/2">
                      {"الصلاحية : "} {data?.role}
                    </p>

                    <p className="w-1/2">
                      {"القسم: "} {data?.department}
                    </p>
                  </div>
                  <div className="flex">
                    <p className="w-1/2">
                      {"بدأ العمل  :"}
                      {moment(data?.startWork).format("YYYY-MM-DD")}
                    </p>

                    <p className="w-1/2">
                      {"المستوى: "} {data?.level}
                    </p>
                  </div>
                </FormModal>

                <FormModal>
                  <div className="mb-2">
                    <p>{"جميع اجازات الشهر الحالى"}</p>
                  </div>

                  <div className="w-full flex justify-center items-center">
                    {isLoadingVacations ? <Spinner /> : null}
                    {isErrorVacations ? (
                      <p className="text-red-400">يوجد خطا ما</p>
                    ) : null}
                  </div>
                  {!isLoadingVacations && !isErrorVacations ? (
                    <VacationsTable vacations={dataVacations} userId={id} />
                  ) : null}
                </FormModal>
              </div>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <TasksProvider>
                <div className="space-y-4">
                  <div className="w-full flex justify-center items-center">
                    {isLoadingKPIs ? <Spinner /> : null}
                    {isErrorKPIs ? (
                      <p className="text-red-400 text-lg">يوجد خطا ما</p>
                    ) : null}
                  </div>
                  {!isLoadingKPIs && !isErrorKPIs ? (
                    <>
                      <ApexColumn data={dataKPIs} />
                    </>
                  ) : null}

                  <FormModal>
                    <p className="text-sm font-medium">{"الشهر الحالى "}</p>
                    <div className="mt-2">
                      <p className="text-sm font-medium mb-2">{"الاهداف"}</p>
                      <ProgressBar progress={dataGoals?.goals} />
                      <p className="text-xs font-normal text-mainCustomGray">{`حقق ${dataGoals?.goals}% من الهدف`}</p>
                    </div>
                  </FormModal>

                  <div className="space-y-4">
                    <p className="text-[#EFAA20] text-xl font-medium">
                      {"مهام"}
                    </p>
                    <div className="flex justify-center items-center gap-4">
                      <TaskCard
                        title={"مهام مكتملة"}
                        value={dataTasks?.tasksStats?.completedTasks}
                        onClick={() => {
                          setTaskStatus(2);
                        }}
                      />
                      <TaskCard
                        title={"مهام قيد التنفيذ"}
                        value={dataTasks?.tasksStats?.inprogressTasks}
                        onClick={() => {
                          setTaskStatus(1);
                        }}
                      />
                      <TaskCard
                        title={"مهام متأخرة"}
                        value={dataTasks?.tasksStats?.latedTasks}
                        onClick={() => {
                          setIsLate(true);
                          setTaskStatus(null);
                        }}
                      />
                    </div>
                    <div className="">
                      <TasksTable tasks={dataTasks} />
                    </div>
                  </div>
                </div>
              </TasksProvider>
            </TabPanel>
            <TabPanel value={value} index={2}>
              <div className="space-y-4">
                <FormModal
                  title={"المعلومات العامة"}
                  titleClassName="!w-fit px-2"
                  className="space-y-4"
                >
                  <div className="flex">
                    <p className="w-1/2">
                      {"نسبة الزيادة: "} {dataSalary?.increaseSalary}
                    </p>

                    <p className="w-1/2">
                      {"بداية من شهر:  "}
                      {moment(data?.startWork).format("YYYY-MM-DD")}
                    </p>
                  </div>
                  <p>
                    {"المرتب الثابت :"} {dataSalary?.basicSalary}
                  </p>
                </FormModal>
                <FormModal className="space-y-4">
                  <p>{"المرفقات"}</p>
                  <ShowFiles files={dataSalary?.fileUser} />
                </FormModal>
              </div>
            </TabPanel>
            <TabPanel value={value} index={3}>
              <div className="space-y-4">
                {dataServices?.length ? (
                  dataServices?.map((service, index) => (
                    <FormModal className="space-y-4" key={index}>
                      <p>{"الخدمات المخصصه"}</p>
                      <ShowFiles files={service?.attachments} />
                    </FormModal>
                  ))
                ) : (
                  <div className="h-12 flex justify-center items-center">
                    <p className="text-[#EFAA20] text-xl">
                      لا يوجد خدمات مخصصة
                    </p>
                  </div>
                )}
              </div>
            </TabPanel>
          </div>
        </Fragment>
      ) : null}
    </div>
  );
};

export default UserDetailsReport;

const TasksContext = createContext(null);
const TasksProvider = ({ children }) => {
  const [taskStatus, setTaskStatus] = useState("");
  return <TasksContext.Provider value={{}}>{children}</TasksContext.Provider>;
};
const useTasksContext = () => {
  const ctx = useContext(TasksContext);
  if (!ctx) {
    throw Error("use inside provider");
  }
  return ctx;
};

const columns2 = GenerateColumnsNames(
  "م",
  "اسم المهمة",
  "نوع المشروع ",
  "تاريخ الاستلام",
  "تاريخ التسليم"
);

const TasksTable = ({ tasks }) => {
  const [page, setPage] = useState(1);
  const handleChange = (event, value) => {
    setPage(value);
    // setFilterTasksParams({ page: value });
  };
  return (
    <CustomTable
      columns={columns2}
      data={tasks?.tasks}
      paginationProps={{
        count: tasks?.pages,
        page: page,
        onChange: handleChange,
      }}
    >
      {tasks?.tasks && tasks?.tasks?.length > 0
        ? tasks?.tasks?.map(
            (
              {
                _id,
                projectName,
                projectType,
                startDate,
                endDate,

                status,
              },
              index
            ) => {
              return (
                <TableRow
                  className={`my-2 border !border-[#efaa207f] ${
                    index % 2 === 0 ? "bg-mainNavy" : ""
                  }`}
                  key={_id}
                >
                  <TableCell>{(page - 1) * 10 + (index + 1)}</TableCell>
                  <TableCell>{projectName || "لا يوجد مخطط"}</TableCell>
                  <TableCell>{projectTypeEnum[+projectType]}</TableCell>
                  <TableCell>
                    {convertDateFormat(startDate) || "--------"}
                  </TableCell>
                  <TableCell>
                    {convertDateFormat(endDate) || "--------"}
                  </TableCell>
                </TableRow>
              );
            }
          )
        : null}
    </CustomTable>
  );
};

const columnsVacations = GenerateColumnsNames(
  "م",
  "اسم الموظف",
  "الكود الوظيفى ",
  "نوع الاجازة",
  "تاريخ الاجازة",
  "عرض"
);
const VacationsTable = ({ vacations, userId }) => {
  const { open, handleOpenModal, handleCloseModal, handleToggleModal } =
    useModal();
  const [vacationId, setVacationId] = useState(null);
  const [page, setPage] = useState(1);
  const handleChange = (event, value) => {
    setPage(value);
    // setFilterTasksParams({ page: value });
  };
  return (
    <>
      {vacationId ? (
        <VacationModal
          ShowHoliday={open}
          handleClose={handleCloseModal}
          userId={userId}
          vacationId={vacationId}
        />
      ) : null}

      <CustomTable
        columns={columnsVacations}
        data={vacations}
        // paginationProps={{
        //   count: tasks?.pages,
        //   page: page,
        //   onChange: handleChange,
        // }}
      >
        {vacations && vacations?.length > 0
          ? vacations?.map(
              ({ _id, startDate, employeeId, vactionCategoryId }, index) => {
                return (
                  <TableRow
                    className={`my-2 border !border-[#efaa207f] ${
                      index % 2 === 0 ? "bg-mainNavy" : ""
                    }`}
                    key={_id}
                  >
                    {/* <TableCell>{(page - 1) * 10 + (index + 1)}</TableCell> */}
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{`${employeeId?.firstName} ${employeeId?.lastName}`}</TableCell>
                    <TableCell>{employeeId?.employeCode}</TableCell>
                    <TableCell>{vactionCategoryId?.name}</TableCell>
                    <TableCell>
                      {moment(startDate).format("YYYY-MM-DD") || "--------"}
                    </TableCell>
                    <TableCell>
                      <div
                        role="button"
                        onClick={() => {
                          setVacationId(_id);
                          handleOpenModal();
                        }}
                      >
                        <Image
                          src="/icons/view.svg"
                          alt=""
                          className="w-full"
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                );
              }
            )
          : null}
      </CustomTable>
    </>
  );
};
