import { createSlice } from "@reduxjs/toolkit";

const initialState={
    isOpened: false,
    inChatPage: false,
    selectedUser: null
}

const chatNotification = createSlice({
    name: 'chatNotification',
    initialState,
    reducers:{
        switchNotification(state , action){
            state.inChatPage = action.payload
        },
        setSelectedUser(state,action){
            state.selectedUser = action.payload
        }
    }
})
export const {switchNotification,setSelectedUser} = chatNotification.actions
export default chatNotification.reducer