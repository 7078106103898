import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import "./AddFinancialClaims.css";
import Input from "../../../FormHandler/Input";
import { UseInput } from "../../../../hooks";
import { Button, Form, Modal } from "react-bootstrap";
import SaveButton from "../../../SaveButton";
import {
  getAllBatchNameList,
  getAllRequestsList,
  getProject,
} from "../../../../helper/fetchers/Projects";
import Select from "../../../FormHandler/Select";
import { addAccountType } from "../../../../Context/AddAccountaing";
import { getDesignRequestsWithid } from "../../../../helper/fetchers/Requests";
import {
  addFinancial,
  getAllFinancialProject,
} from "../../../../helper/fetchers/finaical";
import DatePicker from "react-datepicker";
import AddAttachment from "../../AddAttachment";
import ShowLocalFiles from "../../../ShowLocalFiles";
import SuccessfullModal from "../../../Modals/SuccessfullModal";
import { toast } from "react-toastify";
import { PhoneInput } from "react-international-phone";
import LoadingPage from "../../../feedback/LoadingPage";

const AddFinancialClaims = ({ getFinancial, getAllStats }) => {
  // handle
  const { setOpenAddAccountant } = useContext(addAccountType);
  // handle Inputs
  const projectDescription = UseInput("", "", true);
  const projectQuantity = UseInput("", "", true);
  const projectValue = UseInput("", "", true);
  const vat = UseInput("", "", true);
  const amountVat = UseInput("", "", true);
  const taxNumber = UseInput("", "", true);
  const textAmount = UseInput("", "", true);
  const letterClinte = UseInput("", "", true);
  const email = UseInput("", "", true);
  // const phone = UseInput("", "", true);
  const [invoiceDate, setInvoiceDate] = useState("");
  const [dueDate, setDueDate] = useState("");
  const [attachment, setAttachment] = useState(null);
  // const [openAddAttachment, setOpenAddAttachment] = useState(false);
  const [projectsNotes, setProjectsNotes] = useState(false);
  const [batchList, setBatchList] = useState([]);

  // news stats
  const [requestList, setRequestList] = useState([]);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [selectedBatch, setSelectedBatch] = useState(null);
  const [phone, setPhone] = useState("");
  // #region All About Request
  const getAllRequests = useCallback(async () => {
    const data = await getAllFinancialProject();
    if (data.status === 200) {
      setRequestList(data?.data?.projects);
    }
  }, []);
  useEffect(() => {
    getAllRequests();
  }, [getAllRequests]);

  const MappedRequestList = requestList?.map((request) => ({
    value: request?.id,
    label: request?.requestId?.projectName,
  }));

  // #endregion

  // #region All About Batch Name
  const getAllBatchName = useCallback(async () => {
    const data = await getAllBatchNameList();
    if (data.status === 200) {
      setBatchList(data?.data?.batches);
    }
  }, []);
  useEffect(() => {
    getAllBatchName();
  }, [getAllBatchName]);

  const MappedBatchNameList = batchList?.map((request) => ({
    value: request?._id,
    label: request?.name,
  }));
  // #endregion
  const files = attachment ? [...attachment] : [];
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const handleAdd = async () => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("projectId", selectedRequest?.id);
    formData.append("batcheId", selectedBatch);
    formData.append("invoiceDate", invoiceDate);
    formData.append("taxNumber", taxNumber?.value);
    formData.append("letterClinte", letterClinte.value);
    formData.append("email", email?.value);
    formData.append("phone", phone);
    formData.append("description", projectDescription.value);
    formData.append("qty", projectQuantity.value);
    formData.append("amount", projectValue.value);
    formData.append("vat", vat?.value);
    formData.append("amountVat", amountVat.value);
    formData.append("dueDate", dueDate);
    formData.append("textAmount", textAmount?.value);
    if (attachment && attachment?.length > 0) {
      files.forEach((file) => {
        formData.append(`fileFinaical`, file);
      });
    }
    try {
      const res = await addFinancial(formData);
      if (res?.status === 201) {
        setSuccess(true);
        getFinancial();
        getAllStats();
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
    setIsLoading(false);
  };

  return isLoading ? (
    <LoadingPage />
  ) : (
    <>
      <p className="text-xl my-3 golden p-3">إضافة مطالبة جديدة</p>
      <div className="p-3 h-full max-h-[700px] overflow-y-auto scrollbar-thin scrollbar-track-transparent scrollbar-thumb-[#EFAA20]">
      <div className="flex items-center justify-between">
        <div className=" col-md-6 mb-4">
          {MappedRequestList && (
            <Select
              label={"بحث عن اسم المشروع"}
              options={MappedRequestList}
              mandatory
              className="w-[350px] text-mainText"
              onChange={(e) => {
                setSelectedRequest(requestList?.find((x) => x.id === e.value));
              }}
            />
          )}
        </div>
        <div className="col-md-6 mb-4">
          <Select
            options={MappedBatchNameList}
            mandatory
            className="w-[350px] text-mainText"
            onChange={(e) => {
              setSelectedBatch(e.value);
            }}
            label={"نوع الدفعة "}
          />
        </div>
      </div>
      <SuccessfullModal
        message={"تم اضافه مطالبه بنجاح"}
        handleClose={() => {
          setSuccess(false);
          setOpenAddAccountant(false);
        }}
        show={success}
      />
      {selectedRequest && selectedBatch && (
        <>
          <fieldset className="showProjectBorder  mx-auto pb-3 ">
            <legend className="text-center text-mainText">
              
              بيانات العميل
            </legend>
            <div className="d-flex w-90 m-auto justify-content-between">
              <p className="projectdetails text-mainText w-50">
                اسم المشروع :
                <span className="text-mainText">
                  
                  {selectedRequest?.requestId?.projectName}
                </span>
              </p>
              <p className="projectdetails text-mainText w-50">
                اسم العميل:
                <span className="text-mainText">
                  {selectedRequest?.requestId?.ownerName}
                </span>
              </p>
            </div>
            <div className="d-flex w-90 m-auto justify-content-between">
              <p className="projectdetails text-mainText w-50">
                نوع المشروع :
                <span className="text-mainText">
                  {selectedRequest?.requestId?.projectType === 1
                    ? "تصميم"
                    : "اشراف علي التنفيذ"}
                </span>
              </p>

              <p className="projectdetails text-mainText w-50 ">
                رقم الطلب :
                <span className="text-mainText">
                  
                  {selectedRequest?.requestId?.orderNumber}
                </span>
              </p>
            </div>
            <div className="d-flex w-90 m-auto justify-content-between">
              <p className="projectdetails text-mainText w-50">
                البريد الاكترونى :
                <span className="text-mainText">
                  {selectedRequest?.requestId?.email}
                </span>
              </p>

              <p className="projectdetails text-mainText w-50 ">
                رقم الجوال :
                <span className="text-mainText" dir="ltr">
                  {selectedRequest?.requestId?.phone}
                </span>
              </p>
            </div>
          </fieldset>
          <fieldset className="showProjectBorder  mx-auto pb-3 ">
            <legend className="text-center text-mainText"> تعريفات </legend>
            <div className="d-flex w-90 m-auto justify-content-between">
              <p className="projectdetails text-mainText w-100">
                التعريف الضريبى :
                <span className="text-mainText">
                  مكتب بدر عبد المحسن بن سليمان لاستشارات الهندسية
                </span>
              </p>
            </div>
            <div className="d-flex w-90 m-auto justify-content-between">
              <p className="projectdetails text-mainText w-50">
                الرقم الضريبي :
                <span className="text-mainText"> 300195565100003</span>
              </p>
            </div>
            <div className="d-flex w-90 m-auto justify-content-between">
              <p className="projectdetails text-mainText w-50">
                العنوان :<span className="text-mainText">الرياض حي النخيل</span>
              </p>
              <p className="projectdetails text-mainText w-50 ">
                <span className="text-mainText"></span>
              </p>
            </div>
          </fieldset>
          <fieldset className="showProjectBorder  mx-auto pb-3 ">
            <legend className="text-center text-mainText"> المرفقات </legend>
            <div className="d-flex w-90 m-auto justify-content-between">
              <div className="row">
                <div className="col-md-4 mb-4">
                  <Form.Group
                    className="licenseDate-container "
                    controlId="licenseDate"
                  >
                    <Form.Label className="d-flex gap-2 align-items-center after:content-['*'] after:text-red-500 relative after:absolute  after:right-[85px] text-mainText">
                      تاريخ الفاتورة
                    </Form.Label>
                    <DatePicker
                      mandatory
                      selected={invoiceDate}
                      placeholderText=" ادخل تاريخ الفاتورة "
                      onChange={(date) => setInvoiceDate(date)}
                      dateFormat="dd-MM-yyyy"
                      className="w-100 form-control"
                    />
                  </Form.Group>
                </div>
                <div className="col-md-8">
                  <div className="w-[200px] form-container mb-3">
                    <Input
                      {...taxNumber.bind}
                      placeholder="الرقم الضريبي"
                      className="w-[412px]"
                      label={"ادخل الرقم الضريبي"}
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="w-100 form-container mb-3">
                    <Input
                      {...letterClinte.bind}
                      placeholder="الساده"
                      className="w-[636px]"
                      label={"ادخل الساده"}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="w-100 form-container mb-3">
                    <Input
                      {...email.bind}
                      placeholder="البريد  الالكتروني"
                      className="w-100"
                      label={"ادخل البريد  الالكتروني"}
                    />
                  </div>
                </div>
                <div className="col-md-6 mb-4">
                  <label className="text-mainText">
                    رقم الجوال <span className="text-danger">*</span>
                  </label>
                  <PhoneInput
                    defaultCountry="sa"
                    placeholder="ادخل رقم الجوال"
                    value={phone}
                    onChange={(phone) => setPhone(phone)}
                  />
                  {/* <Input
            placeholder="ادخل  رقم الجوال"
            label={"رقم الجوال"}
            value={phone}
            onChange={(e) => setPhone(e?.target?.value)}
            mandatory
          /> */}
                </div>
              </div>
            </div>
          </fieldset>
          <fieldset className="showProjectBorder  mx-auto pb-3 ">
            <legend className="text-center text-mainText">
              
              معلومات الدفع
            </legend>
            <div className="d-flex w-90 m-auto justify-content-between">
              <p className="projectdetails text-mainText w-100">
                المبلغ الاجمالي :
                <span className="text-mainText">{`${selectedRequest?.amount} ريال`}</span>
              </p>
            </div>
            <div className="d-flex w-90 m-auto justify-content-between">
              <p className="projectdetails text-mainText w-50">
                عدد الدفعات:
                <span className="text-mainText">
                  {`${selectedRequest?.batchCount} دفعة`}
                </span>
              </p>

              <p className="projectdetails text-mainText w-50 ">
                المبلغ المتبقي :
                <span className="text-mainText">
                  {`${selectedRequest?.remainingAmount} ريال`}
                </span>
              </p>
            </div>
            <div className="d-flex w-90 m-auto justify-content-between">
              <p className="projectdetails text-mainText w-50">
                عدد الدفعات المتبقي :
                <span className="text-mainText">
                  {`${selectedRequest?.remainingBatch} دفعة`}
                </span>
              </p>
            </div>
          </fieldset>

          <fieldset className="showProjectBorder  mx-auto pb-3 ">
            <legend className="text-center text-mainText"> المرفقات </legend>
            <div className="d-flex w-90 m-auto justify-content-between">
              <div className="row">
                <div className="col-md-12">
                  <div className="w-100 form-container">
                    <Input
                      {...projectDescription.bind}
                      placeholder="اكتب الوصف"
                      className="w-[660px] "
                      label={"1-الوصف"}
                      customHeight="h-[80px]"
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="w-100 form-container mb-3">
                    <Input
                      {...projectQuantity.bind}
                      placeholder=" الكميه"
                      className="w-100"
                      label={"الكمية"}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="w-100 form-container mb-3">
                    <Input
                      {...projectValue.bind}
                      placeholder=" القيمة"
                      className="w-100"
                      label={"القيمة"}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="w-100 form-container mb-3">
                    <Input
                      {...vat.bind}
                      placeholder="  ق.م"
                      className="w-100"
                      label={"ادخل ال ق.م"}
                      discount={true}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="w-100 form-container mb-3">
                    <Input
                      {...amountVat.bind}
                      placeholder=" ض .ق.م"
                      className="w-100"
                      label={"ادخل ال ض.ق.م"}
                    />
                  </div>
                </div>
                <div className="col-md-4 mb-4">
                  <Form.Group
                    className="licenseDate-container "
                    controlId="licenseDate"
                  >
                    <Form.Label className="d-flex gap-2 align-items-center   after:right-[74px]">
                      تاريخ الدفع
                    </Form.Label>
                    <DatePicker
                      selected={dueDate}
                      placeholderText=" ادخل تاريخ الدفع"
                      onChange={(date) => setDueDate(date)}
                      dateFormat="dd-MM-yyyy"
                      className="w-[190px] form-control"
                    />
                  </Form.Group>
                </div>
                <div className="col-md-12">
                  <div className="w-100 form-container mb-3">
                    <Input
                      {...textAmount.bind}
                      placeholder=" اجمالي المبلغ كتابة"
                      className="w-[660px]"
                      label={" اجمالي المبلغ كتابة"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </fieldset>
          <fieldset className="showProjectBorder  mx-auto p-2 flex flex-row gap-2 ">
            <legend className="text-center text-mainText">
              
              الملف المرفق
            </legend>
            <AddAttachment
              attachment={attachment}
              setAttachment={setAttachment}
              multi={true}
            />
            {attachment && <ShowLocalFiles files={Array.from(attachment)} />}
          </fieldset>
          <div className="d-flex my-5 w-90  justify-content-end">
            <Button
              onClick={() => {
                handleAdd();
              }}
              className="sumbmitAddUpdateUser"
            >
              حفظ
            </Button>
          </div>
        </>
      )}
      </div>
    </>
  );
};

export default AddFinancialClaims;
