export const ProjectNames = [
    'مطاعم عشبة الليمون',
    'مطاعم عشبة الليمون',
    'مطاعم عشبة الليمون',
    'مطاعم عشبة الليمون',
    'مطاعم عشبة الليمون',
    'مطاعم عشبة الليمون',
    'مطاعم عشبة الليمون',
    'مطاعم عشبة الليمون',
    'مطاعم عشبة الليمون',
    'مطاعم عشبة الليمون',
    'مطاعم عشبة الليمون',

  ];

export const Supervisors = [
  {id:1, name: "م.ايهاب",position: "مدير مكتب",location: "السعودية", img:"/People/islam.jpg" },
  {id:2, name: "م.اشرف",position: "مدير مكتب",location: "مصر", img:"/People/islam.jpg" },
  {id:3, name: "م.عبد القادر",position: "مدير مكتب",location: "السعودية", img:"/People/islam.jpg" },
  {id:4, name: "م.اسلام إيهاب",position: "مدير قسم",location: "مصر", img:"/People/islam.jpg" },
]