import { useContext, useEffect, useState } from "react";
import { Form, Modal, Button } from "react-bootstrap";

import "./AddHoliday.css";
import FormControl from "@mui/material/FormControl";
import Cookies from "js-cookie";
import SaveButton from "../../../SaveButton";
import { AddHrType } from "../../../../Context/AddHr";
import { Controller, useForm } from "react-hook-form";
import Progress from "../../../Progress";
import Select from "../../../FormHandler/Select";
import SuccessfullModal from "../../../Modals/SuccessfullModal";
import { ErrorMessage } from "@hookform/error-message";
import { useGetAllVacationCategoriesHoliday } from "../../../../hooks/fetchers/VacationCategory";
import {
  useGetManagersUsers,
  useGetSelectedUsers,
} from "../../../../hooks/fetchers/Users";
import { VacationType } from "./consts";
import { FormModal } from "../../../../Pages/System/PlanModel/components/FormModal";
import { useAddVacation } from "../../../../hooks/fetchers/Vacations";
import useSuccessfullModal from "../../../Modals/hooks/useSuccessfullModal";
import { InputLabel } from "../../../../Pages/System/PlanModel/components/InputLabel";
import {
  CustomDatePicker,
  CustomInput,
  CustomeInputFile,
} from "../../../FormUi";
import moment from "moment";
import LoadingPage from "../../../feedback/LoadingPage";

const validateFileSize = (val) =>
  val.length > 5 ? "لا تزيد عن 5 ملفات" : true;
const roles = ["مدير", "مدير المكتب", "مواردبشرية"];
const AddHoliday = () => {
  const [user, setUser] = useState({});

  useEffect(() => {
    setUser({
      role: Cookies.get("role"),
      name: `${Cookies.get("firstName")} ${Cookies.get("lastName")}`,
      id: Cookies.get("userId"),
    });
  }, []);

  const { data: managers } = useGetManagersUsers();
  const { data: employees } = useGetSelectedUsers(roles.includes(user?.role));

  const {
    handleShowSuccess,
    handleShowError,
    handleCloseSuccess,
    handleCloseError,
    showSuccess,
    showError,
  } = useSuccessfullModal();
  const { data: vacations, isLoading: isLoadingVacations } =
    useGetAllVacationCategoriesHoliday();
  const { mutate, isLoading: isLoadingAddVacation } = useAddVacation(
    handleShowSuccess,
    handleShowError
  );
  // define context inputs
  const { openHr, handleCloseHr, setOpenHr } = useContext(AddHrType);
  // define react hook form
  const {
    register,
    handleSubmit,
    control,
    formState: { isSubmitting, errors },
  } = useForm();

  // handling submit event
  const manipulateData = (data) => {
    data.approvalManager = data.approvalManager.value;
    if (roles.includes(user?.role)) {
      data.employeeId = data.employeeId.value;
    }
    data.vactionCategoryId = data.vactionCategoryId.value;
    data.vactionType = data.vactionType.value;
    data.startDate = moment(data.startDate).format("YYYY-MM-DD");
    data.endDate = moment(data.endDate).format("YYYY-MM-DD");
    return data;
  };
  const onSubmit = async (data) => {
    data = manipulateData(data);
    const formData = new FormData();

    Object.keys(data).forEach((key) => {
      if (key !== "image") {
        if (key === "employeeId") {
          if (!roles.includes(user?.role)) {
            formData.append("employeeId", user?.id);
          } else {
            formData.append(key, data[key]);
          }
        } else {
          formData.append(key, data[key]);
        }
      }
    });

    if (data?.image && data?.image.length) {
      for (const img of data.image) {
        formData.append("image", img);
      }
    }
    // for (const iterator of formData.entries()) {
    //   console.log(iterator);
    // }
    mutate(formData);
  };
  const content = (
    <Modal.Body className="w-full p-2">
      <Form
        encType="multipart/form-data"
        className="flex flex-col gap-3 w-full"
        onSubmit={handleSubmit(onSubmit)}
      >
        <FormModal title={"مخصصة الى"}>
          <div className="grid grid-cols-2 gap-3">
            <FormControl fullWidth>
              <InputLabel label={"اسم الموظف"} />
              {roles.includes(user?.role) ? (
                <Controller
                  control={control}
                  name={`employeeId`}
                  rules={{
                    required: " من فضلك اختر اسم الموظف",
                  }}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Select
                      fullWidth
                      ref={ref}
                      onChange={onChange}
                      onBlur={onBlur}
                      OptionbackgroundColor="#414162"
                      placeholder="اختر اسم الموظف"
                      options={employees}
                    />
                  )}
                />
              ) : (
                <div className="">
                  <p>{user.name}</p>
                </div>
              )}

              <ErrorMessage
                errors={errors}
                name="employeeId"
                render={({ message }) => (
                  <p className="text-[#FF0000]">{message}</p>
                )}
              />
            </FormControl>

            <FormControl fullWidth>
              <InputLabel label={"غرض الاجازة"} />
              <Controller
                name={`vactionCategoryId`}
                control={control}
                rules={{
                  required: " من فضلك ادخل غرض الاجازة",
                }}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <Select
                    fullWidth
                    ref={ref}
                    onChange={onChange}
                    onBlur={onBlur}
                    OptionbackgroundColor="#414162"
                    placeholder="اخترر"
                    options={vacations}
                  />
                )}
              />
              <ErrorMessage
                errors={errors}
                name="vactionCategoryId"
                render={({ message }) => (
                  <p className="text-[#FF0000]">{message}</p>
                )}
              />
            </FormControl>
          </div>
        </FormModal>
        <FormModal title={"تفاصيل الاجازة"}>
          <div className="grid grid-cols-12 gap-3">
            <FormControl className="col-span-6" fullWidth>
              <InputLabel label={"نوع الاجازة"} />
              <Controller
                name={`vactionType`}
                control={control}
                rules={{
                  required: "من فضلك ادخل نوع الاجازة",
                }}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <Select
                    onChange={onChange}
                    onBlur={onBlur}
                    ref={ref}
                    OptionbackgroundColor={"#414162"}
                    placeholder="اختر نوع الاجازة"
                    options={VacationType}
                  />
                )}
              />
              <ErrorMessage
                errors={errors}
                name="vactionType"
                render={({ message }) => (
                  <p className="text-[#FF0000]">{message}</p>
                )}
              />
            </FormControl>

            <FormControl className="col-span-6" fullWidth>
              <InputLabel label={"مدة الاجازة"} />

              <CustomInput
                control={control}
                name="durationVaction"
                placeholder="اكتب مدة الاجازة"
                rules={{
                  required: " من فضلك اكتب مدة الاجازة",
                  minLength: {
                    value: 4,
                    message: "يجب أن يكون الحقل على الأقل 4 حروف",
                  },
                }}
              />
              <ErrorMessage
                errors={errors}
                name="durationVaction"
                render={({ message }) => (
                  <p className="text-[#FF0000]">{message}</p>
                )}
              />
            </FormControl>
            <FormControl className="col-span-6" fullWidth>
              <InputLabel label={"من تاريخ"} />
              <CustomDatePicker
                control={control}
                name="startDate"
                rules={{
                  required: " من فضلك ادخل تاريخ بداية الاجازة",
                }}
              />

              <ErrorMessage
                errors={errors}
                name="startDate"
                render={({ message }) => (
                  <p className="text-[#FF0000]">{message}</p>
                )}
              />
            </FormControl>
            <FormControl className="col-span-6" fullWidth>
              <InputLabel label={"الي تاريخ"} />
              <CustomDatePicker
                control={control}
                name="endDate"
                rules={{
                  required: " من فضلك ادخل تاريخ من  الاجازة",
                }}
              />

              <ErrorMessage
                errors={errors}
                name="endDate"
                render={({ message }) => (
                  <p className="text-[#FF0000]">{message}</p>
                )}
              />
            </FormControl>
          </div>
        </FormModal>
        <FormModal title={"موجة الى"}>
          <div className="grid grid-cols-2">
            <FormControl className="col-span-6" fullWidth>
              <InputLabel label={"تعتمد من "} />
              <Controller
                name={`approvalManager`}
                control={control}
                rules={{
                  required: " من فضلك ادخل البيانات",
                }}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <Select
                    onChange={onChange}
                    onBlur={onBlur}
                    ref={ref}
                    OptionbackgroundColor={"#414162"}
                    placeholder="اختر الشخص"
                    options={managers}
                  />
                )}
              />
              <ErrorMessage
                errors={errors}
                name="approvalManager"
                render={({ message }) => (
                  <p className="text-[#FF0000]">{message}</p>
                )}
              />
            </FormControl>
          </div>
        </FormModal>
        <FormModal title={"المرفقات"}>
          <CustomeInputFile
            register={{ ...register("image") }}
            rules={{
              validate: validateFileSize,
            }}
            name="image"
            multiple={true}
          />
        </FormModal>

        <div className="my-3">
          <SaveButton
            text={isSubmitting ? <Progress isSmall /> : "حفظ"}
            type="submit"
          />
        </div>
      </Form>
    </Modal.Body>
  );

  return (
    <Modal
      className="systemModal addHoliday bg-black/50"
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      onHide={handleCloseHr}
      show={openHr}
    >
      <Modal.Header className="w-full !justify-between border-none">
        <div className=""></div>
        <ModalTitle title={"طلب اجازة"} />
        <Button
          className="p-0 bg-transparent hover:bg-transparent"
          onClick={handleCloseHr}
        >
          <img src="/Rejected.svg" alt="" />
        </Button>
      </Modal.Header>
      {isLoadingAddVacation ? <LoadingPage /> : null}
      <SuccessfullModal
        show={showSuccess}
        handleClose={() => {
          handleCloseSuccess();
          handleCloseHr();
        }}
        message="تم اضافه اجازه جديدة بنجاح "
      />
      <SuccessfullModal
        show={showError}
        handleClose={() => {
          handleCloseError();
          handleCloseHr();
        }}
        message="حدث خطأ ما"
        status="error"
      />
      {content}
      {/* {(isLoadingVacations || isLoadingUsers) ? <Progress /> : content} */}
    </Modal>
  );
};

const ModalTitle = ({ title }) => {
  return (
    <Modal.Title className="text-xl text-[#EFAA20] border !border-[#EFAA20] p-2 rounded-[5px] font-medium ">
      {title}
    </Modal.Title>
  );
};

export default AddHoliday;
