const EmployeeCard = ({ title, desc, img }) => {
  return (
    <div className="bg-mainNavy p-2 rounded-[7px] flex gap-2">
      <div className=" rounded-full w-8 h-8">
        <img
          src={img ?? "/icons/avatar.png"}
          alt=""
          className="w-full object-contain h-8 rounded-full"
        />
      </div>
      <div className="flex flex-col gap-1">
        <p className="font-medium text-xs text-mainText">{title}</p>
        <p className="font-medium text-xs text-[#D59921]">{desc}</p>
      </div>
    </div>
  );
};
export default EmployeeCard;
