export const SaveButton = () => {
  return (
    <div className="flex justify-center items-center">
      <button
        type="submit"
        className="w-[140px] h-[30px] border !border-[#EFAA20] bg-[#EFAA20] hover:bg-[#EFAA20]  hover:text-white transition-colors rounded-[6px] text-white text-[15px] font-medium"
      >
        حفظ
      </button>
    </div>
  );
};
