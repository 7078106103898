import { InputLabel } from "@mui/material";
import React from "react";
import { Form } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { CustomeInputFile } from "../../../../Components/FormUi";
import { useForm } from "react-hook-form";
import { FormModal } from "../../PlanModel/components/FormModal";
import ShowFiles from "../../../../Components/ShowFiles";
import CustomTextArea from "../../../../Components/FormUi/CustomTextArea";

const AcceptIngoingForm = ({
  type,
  isTask,
  message,
  title,
  show,
  handleClose,
  handleSave,
  data,
}) => {
  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const validateFiles2 = (value) => {
    if (value.length > 2) {
      return "يمكنك تحميل ما يصل إلى 8 صور فقط";
    }
    return true;
  };

  const onSubmit = (formData) => {
    var newFormData = new FormData();
    if (type === 0 || type === 1) {
      newFormData.append("fixDescription", formData.description);
      if (formData.attachements) {
        for (let item of formData.attachements) {
          newFormData.append("fixAttachments", item);
        }
      }
      handleSave(newFormData, {
        onSuccess: () => {
          reset();
        },
      });
    } else if (type === 4) {
      // Type Accepts on Purchase
      newFormData.append("notes", formData.description);
      if (formData.attachements) {
        for (let item of formData.attachements) {
          newFormData.append("offerAttachements", item);
        }
      }
      handleSave(newFormData, {
        onSuccess: () => {
          reset();
        },
      });
    } else if (type === 5) {
      // Type Accepts on Purchase
      newFormData.append("updatePriceOfferNotes", formData.description);
      if (formData.attachements) {
        for (let item of formData.attachements) {
          newFormData.append("offerAttachements", item);
        }
      }
      handleSave(newFormData, {
        onSuccess: () => {
          reset();
        },
      });
    }
  };

  return (
    <Modal
      centered
      contentClassName="bg-mainItems !max-w-[700px] text-mainText"
      className="bg-black/50"
      show={show}
      size="lg"
      onHide={handleClose}
    >
      <Form
        onSubmit={handleSubmit(onSubmit)}
        encType="multipart/form-data"
        className="row my-4 date-input w-100 mx-auto p-3"
      >
        <p className="w-full py-3 rounded-[7px] outline-none text-mainText">
          {isTask ? "اضف تفاصيل الفحص" : `اضافة عرض سعر`}{" "}
        </p>
        <Modal.Body
          className={`w-full flex ${isTask ? "flex-col-reverse" : "flex-col"} `}
        >
          <FormModal title={"ارفاق الملف"} className="my-4 flex gap-3">
            <div className="overflow-y-hidden overflow-x-auto scrollbar-thin scrollbar-track-transparent scrollbar-thumb-[#EFAA20]  flex gap-3">
              <div className="flex gap-4  ">
                <CustomeInputFile
                  register={{
                    ...register("attachements", {
                      validate: validateFiles2,
                    }),
                  }}
                  name="attachements"
                  multiple={true}
                  removePadding={true}
                />
              </div>
              {data && (
                <div className="relative  flex gap-6 ">
                  <ShowFiles files={data} />
                </div>
              )}
            </div>
          </FormModal>
          <FormModal title={"ملاحظاتك"} className="my-4">
            <div className="col-md-12 mb-4">
              <Form.Group>
                <InputLabel label={"التفاصيل"} />

                <CustomTextArea
                  control={control}
                  name="description"
                  placeholder={"اكتب التفاصيل"}
                />
              </Form.Group>
            </div>
          </FormModal>
        </Modal.Body>
        <Modal.Footer className="border-none gap-2 w-full">
          <Button
            className="mx-0 py-1 px-14 font-semibold text-[13px] text-white bg-[#EFAA20] hover:bg-[#EFAA20]"
            type="submit"
          >
            حفظ
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default AcceptIngoingForm;
