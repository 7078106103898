import { useContext, useEffect, useMemo, useState } from "react";
import Image from "../../../../Components/Image";
import AddUpdateReciption from "../../../../Components/System/Settings/Reception/AddUpdateReception";
import ViewReception from "../../../../Components/System/Settings/Reception/ViewReception";
import SettingContext from "../../../../Context/SettingContext";
import { TableCell } from "../../../../Components/Table/TableCell";
import { TableRow } from "../../../../Components/Table/TableRow";
import CustomTable from "../../../../Components/Table";
import {
  useDeleteReception,
  useGetAllReceptions,
  useGetReception,
} from "../../../../hooks/fetchers/Receptions";
import { GenerateColumnsNames } from "../../../../helper/utils";
import moment from "moment";
import { SearchComponent } from "../../../../Components/SearchComponent/SearchComponent";
import UpdateReception from "../../../../Components/System/Settings/Reception/UpdateReception";
import { RiDeleteBin6Line } from "react-icons/ri";
import useSuccessfullModal from "../../../../Components/Modals/hooks/useSuccessfullModal";
import LoadingPage from "../../../../Components/feedback/LoadingPage";
import SuccessfullModal from "../../../../Components/Modals/SuccessfullModal";
import CustomCheckboxTable from "../../../../Components/CustomCheckboxTable";
import TableDeleteButton from "../../../../Components/Buttons/TableDeleteButton";
const columns = GenerateColumnsNames(
  "تحديد",
  "م",
  "اسم الشخص",
  "الجهة",
  "تاريخ الزيارة",
  "عرض",
  "تعديل"
);
const OrderBtn = ({ title, active, setActive, index }) => {
  return (
    <button
      onClick={() => setActive(index)}
      className={`p-2 add-user-button text-right !w-full border hover:!border-[#EFAA20] rounded-md ${
        active === index
          ? "text-[#EFAA20] text-base  !border-[#EFAA20]"
          : "text-[#ffffff80] !text-xs !border-transparent"
      } `}
    >
      {title}
    </button>
  );
};

const Reception = () => {
  const { setReceptionType } = useContext(SettingContext);
  const [searchParams, setSearchParams] = useState({ page: 1, typeVisit: 1 });
  const {
    data: receptionData,
    isLoading,
    isError,
  } = useGetAllReceptions(searchParams);
  const {
    handleShowSuccess,
    handleShowError,
    handleCloseSuccess,
    handleCloseError,
    showSuccess,
    showError,
  } = useSuccessfullModal();
  const {
    mutate: deleteMutation,
    isLoading: deleteIsLoading,
    isError: deleteIsError,
    error: deleteError,
  } = useDeleteReception(() => {
    handleShowSuccess();
    setChecked([]);
  }, handleShowError);
  const [id, setId] = useState(null);
  const {
    data: receptionById,
    isLoading: receptionByIdIsLoading,
    isSuccess: receptionByIdIsSuccess,
  } = useGetReception(id);

  const [active, setActive] = useState(0);
  const [status, setStatus] = useState("Exports");

  const [viewVisit, setViewVisit] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const handleOpenModal = () => {
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleSearch = (val) => {
    setSearchParams((prev) => ({ ...prev, search: val }));
  };
  const handleChangePage = (event, value) => {
    setSearchParams((prev) => ({ ...prev, page: value }));
    // setFilterTasksParams({ page: value });
  };

  const [checked, setChecked] = useState([]);
  const handleChecked = (val) => {
    if (checked.includes(val)) {
      setChecked(checked.filter((item) => item !== val));
    } else {
      setChecked([...checked, val]);
    }
  };
  const handleDeleteAll = () => {};
  const handleDeleteSelected = () => {
    console.log("checked",checked);
    deleteMutation({ "receptions": checked });
  };
  
  return (
    <section className="Reception h-full">
      {deleteIsLoading ? <LoadingPage/> : null}
      <SuccessfullModal
        handleClose={handleCloseSuccess}
        message={"تم الحذف بنجاح"}
        show={showSuccess}
      />
      <SuccessfullModal
        handleClose={handleCloseError}
        message={deleteError?.response?.data?.validationErrors || "حدث خطأ ما"}
        show={showError}
        status="error"
      />
      <ViewReception
        id={id}
        status={status}
        viewVisit={viewVisit}
        setViewVisit={setViewVisit}
        data={receptionById?.data?.reception}
      />
      {!receptionByIdIsLoading && receptionByIdIsSuccess && (
        <UpdateReception
          id={id}
          show={showModal}
          handleCloseModal={handleCloseModal}
          data={receptionById}
        />
      )}

      {/* <AddUpdateReciption
        id={id}
        show={showModal}
        handleCloseModal={handleCloseModal}
        data={receptionById?.data?.reception}
      /> */}
      <div className="grid grid-cols-12 gap-2 !h-full">
        <div className="bg-mainItems hidden xl:flex col-span-3  !h-full rounded-[19px]">
          <div className="p-3 w-full">
            <div className="my-3">
              <p className="text-mainText text-[15px] ">الاستقبال</p>
            </div>
            <div>
              <OrderBtn
                title={"البنود"}
                index={0}
                active={0}
                setActive={() => {}}
              />
            </div>
          </div>
        </div>

        <div className="bg-mainItems col-span-3  !h-full rounded-[19px]">
          <div className="pt-4 px-3">
            <p className="text-mainText text-[15px]">كل الزيارات</p>
          </div>

          <div
            onClick={() => {
              setActive(0);
              setStatus("Exports");
              setReceptionType("Exports");
              setSearchParams((prev) => ({ ...prev, typeVisit: 1 }));
            }}
            className={`p-2 my-3 w-full  border hover:!border-[#EFAA20] pointer   flex justify-between  ${
              active === 0 ? " !border-[#EFAA20]" : "!border-transparent"
            }  `}
          >
            <div className="flex gap-2 text-sm text-mainCustomGray ">
              <p className="text-base text-mainCustomGray ">الصادر</p>
            </div>
          </div>

          <div
            onClick={() => {
              setActive(1);
              setStatus("Imports");
              setReceptionType("Imports");
              setSearchParams((prev) => ({ ...prev, typeVisit: 2 }));
            }}
            className={`p-2 my-3 w-full  flex justify-between items-center border hover:!border-[#EFAA20] ${
              active === 1 ? " !border-[#EFAA20]" : "!border-transparent"
            }      pointer`}
          >
            <div className="flex items-center gap-2 text-sm text-mainCustomGray ">
              <p className="text-base text-mainCustomGray ">الوارد</p>
            </div>
          </div>
        </div>

        <div className="bg-mainItems col-span-9  xl:col-span-6 !h-full rounded-[19px]  ">
          <div className="p-3">
            <p className="text-xl text-mainText">
              {status === "Exports"
                ? "كل الزيارات الصادرة"
                : "كل الزيارات الواردة"}
            </p>
          </div>

          <div className="flex px-3 mt-3 justify-start items-center gap-3">
            <p className="text-mainText ">بحث</p>
            {/* <input
              type="text"
              className="bg-[#2B2B40] text-mainText rounded-[5px] p-2 w-full"
              placeholder={status === "Exports" ? "اسم الموظف" : "اسم الشخص"}
            /> */}
            <SearchComponent
              placeholder={status === "Exports" ? "اسم الموظف" : "اسم الشخص"}
              background="light"
              handleChange={handleSearch}
              border="border !border-[#E4A11B]"
              // value={}
            />
          </div>

          <fieldset className=" fieldBorder mt-3">
            <legend className="text-center text-mainText">كل الزيارات</legend>
            {!isLoading &&
            !isError &&
            receptionData?.data?.reception?.length > 0 ? (
              <TableDeleteButton
                  checked={checked}
                  dataLength={receptionData?.data?.reception?.length}
                  onClickDeleteAll={handleDeleteAll}
                  onClickDeleteSelected={handleDeleteSelected}
                />
            ) : null}
            <div className="mt-3 !h-[400px] overflow-y-auto scrollbar-thin scrollbar-track-mainItems scrollbar-thumb-[#E4A11B]">
              <CustomTable
                columns={columns}
                data={receptionData?.data?.reception}
                paginationProps={{
                  count: receptionData?.data?.pages,
                  page: searchParams.page,
                  onChange: handleChangePage,
                }}
              >
                {receptionData?.data?.reception &&
                receptionData?.data?.reception?.length > 0
                  ? receptionData?.data?.reception?.map(
                      (
                        {
                          _id,
                          personVisit,
                          visitLocation,
                          dateVist,
                          typeVisit,
                          employee,
                        },
                        index
                      ) => (
                        <TableRow
                          className={`my-2 border !border-[#efaa207f] ${
                            index % 2 === 0 ? "bg-mainNavy" : ""
                          }`}
                          key={_id}
                        >
                          <TableCell>
                                  <CustomCheckboxTable
                                    isChecked={checked?.some((x) => x === _id)}
                                    handleClick={() => handleChecked(_id)}
                                  />
                                </TableCell>
                          <TableCell>
                            {(searchParams.page - 1) * 10 + (index + 1)}
                          </TableCell>
                          <TableCell>
                            {typeVisit === 2
                              ? personVisit
                              : employee?.firstName}
                          </TableCell>
                          <TableCell>{visitLocation}</TableCell>
                          <TableCell>
                            {moment(dateVist).format("YYYY-MM-DD")}
                          </TableCell>
                          <TableCell>
                            <div
                              onClick={() => {
                                setViewVisit(true);
                                setId(_id);
                              }}
                            >
                              <Image
                                className="pointer"
                                src={"/icons/view.svg"}
                                alt="view image "
                                width={30}
                                height={30}
                              />
                            </div>
                          </TableCell>
                          <TableCell>
                            <div
                              onClick={() => {
                                handleOpenModal();
                                setId(_id);
                              }}
                            >
                              <Image
                                className="pointer"
                                alt={"editImg"}
                                src={"/icons/edit.svg"}
                                width={30}
                                height={30}
                              />
                            </div>
                          </TableCell>
                        </TableRow>
                      )
                    )
                  : null}
              </CustomTable>
            </div>
          </fieldset>
        </div>
      </div>
    </section>
  );
};

export default Reception;
