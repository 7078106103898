const VacationType = [
    {
      value: 0,
      label: " بدون راتب ",
    },
    {
      value: 1,
      label: "براتب",
    },
    {
      value: 2,
      label: "اخري",
    },
  ];

  const VacationStatus = [
    {
      value: 2,
      label: "مقبولة ",
    },
    {
      value: 1,
      label: "  مرفوضة",
    },

    {
      value: 0,
      label: "معلقة",
    },
  ];

export {VacationType,VacationStatus}