import React from "react";
import CustomTable from "../../../../../Components/Table";
import { TableRow } from "../../../../../Components/Table/TableRow";
import { TableCell } from "../../../../../Components/Table/TableCell";
import { clientTypeEnum, requestsEnum, statusEnum } from "../../../../../helper/utils";
const columns = [
  {
    name: "م",
  },
  {
    name: " اسم العميل",
  },
  {
    name: " رقم الجوال  ",
  },
  {
    name: "   نوع العميل ",
  },
  {
    name: " البريد اللاكترونى  ",
  },

  {
    name: "الحالة",
  },
];
export default function ClientsTable({ allClients }) {
  return (
    <CustomTable
      paginationProps={{
        className: "hidden",
      }}
      bordered={false}
      TableHeaderProps={{
        className: "bg-[#7676764D]",
      }}
      TableHeadProps={{
        className:
          "text-end text-[10px] border-none !font-semibold p-1 text-mainText textColor",
      }}
      columns={columns}
      data={allClients}
    >
      {allClients && allClients.length > 0
        ? allClients.map(
            ({ _id, ownerName, phone, clientType, email, status }, index) => (
              <TableRow
                className={`my-2  border-y !border-[#616161]`}
                key={index}
              >
                <TableCell
                  cellClassName={"min-w-[10px]"}
                  textColor="var(--main-text)"
                >
                  {index + 1}
                </TableCell>
                <TableCell>{ownerName || "لا توجد بيانات"}</TableCell>
                <TableCell>
                  {" "}
                  <span dir="ltr">{phone || "لا توجد بيانات"}</span>{" "}
                </TableCell>
                <TableCell>
                  {clientTypeEnum[clientType] || "لا توجد بيانات"}
                </TableCell>
                <TableCell>{email || "لا توجد بيانات"}</TableCell>
                <TableCell>
                  <div className="flex items-center gap-2">
                    <div
                      className="w-2 h-2 rounded-full"
                      style={{
                        backgroundColor: statusEnum[status]?.color,
                      }}
                    ></div>
                    <p>{requestsEnum[status] || "لا توجد بيانات"}</p>
                  </div>
                </TableCell>
              </TableRow>
            )
          )
        : null}
    </CustomTable>
  );
}
