import { Fragment, useState } from "react";
import "./index.css";
import { useParams, useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import "react-calendar/dist/Calendar.css";
import Calendar from "react-calendar";
import moment from "moment/moment";
import { IoPerson } from "react-icons/io5";
import Cookies from "js-cookie";
import * as XLSX from "xlsx";

import ShowClientDetails from "../../../../Components/System/Clients/ShowClient/ShowClientDetails";
import CustomTable from "../../../../Components/Table/index.jsx";
import { TableRow } from "../../../../Components/Table/TableRow.jsx";
import { TableCell } from "../../../../Components/Table/TableCell.jsx";
import DownloadButton from "../../../../Components/Buttons/DownloadButton.jsx";
import CustomModal from "../../../../Components/Modals/CustomModal.jsx";
import SuccessfullModal from "../../../../Components/Modals/SuccessfullModal.jsx";
import Pdf from "../../../../Components/Pdf.jsx";
import {
  useDeleteClient,
  useGetClient,
} from "../../../../hooks/fetchers/Clients.jsx";
import {
  clientTypeEnum,
  identityTypeEnum,
  projectTypeEnum,
} from "../../../../helper/utils.jsx";
import useCustomModal from "../../../../Components/Modals/hooks/useCustomModal.jsx";
import useSuccessfullModal from "../../../../Components/Modals/hooks/useSuccessfullModal.jsx";
import { MdArrowDropDown, MdBusinessCenter } from "react-icons/md";
import { FaPhone } from "react-icons/fa6";
import axios from "axios";
import config from "../../../../Config/Config.jsx";
import LoadingPage from "../../../../Components/feedback/LoadingPage.jsx";
import { Tooltip } from "@mui/material";

const statusEnum = {
  0: " في الانتظار",
  1: " قيد التنفيذ",
  2: "مرفوضة",
};
const columns = [
  {
    name: "م",
  },
  {
    name: "اسم المشروع",
  },
  {
    name: " رقم الطلب ",
  },
  {
    name: "   نوع المشروع",
  },
  {
    name: "تاريخ الاستلام  ",
  },

  {
    name: "    الحالة",
  },
  {
    name: "عرض",
  },
];
const ClientDetails = () => {
  const [viewInvoice, setViewInvoice] = useState(false);

  const [openCliam, setOpenClaim] = useState(false);
  const [showProject, setShowProject] = useState(false);

  const { clientId } = useParams();


  const [currentMonth, setCurrentMonth] = useState(false);
  const [employeeDetails, setEmployeeDetails] = useState("معلومات عامة");
  const [Montlyhwork, setMontlyhwork] = useState(new Date());

  const { data: client } = useGetClient(clientId);
  const [excelData, setExcelData] = useState();
  const [fileUrl, setFileUrl] = useState("");
  const [loading, setLoading] = useState(false);
  // console.log(client, "client");
  const [allKeys, setAllKeys] = useState([]);

  const handleExport = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${config.apiGateway.URL}/client/export/${client?._id}`,
        {
          responseType: "arraybuffer",
          headers: {
            "Content-Type": "application/excel",
            authes: `BSA__${Cookies.get("accessToken")}`,
          },
        }
      );

      if (response.status === 200) {
        const data = new Uint8Array(response.data);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet);
        const keysSet = new Set();

        jsonData.forEach((row) => {
          Object.keys(row).forEach((key) => keysSet.add(key));
        });

        setAllKeys(Array.from(keysSet));
        setExcelData(jsonData);

        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = URL.createObjectURL(blob);
        setFileUrl(url);
        setLoading(false);
      } else {
        setLoading(false);

        console.error("Failed to fetch Excel:", response.statusText);
      }
    } catch (error) {
      setLoading(false);

      console.error("Error fetching Excel:", error);
    }
  };
  return (
    <Fragment>
      {loading ? (
        <LoadingPage />
      ) : (
        excelData && (
          <Modal
            show={true}
            onHide={() => setExcelData("")}
            className="h-screen bg-black/50 customModel"
            contentClassName="bg-transparent"
          >
            <Modal.Body className="rounded-lg bg-mainItems w-full min-w-min flex items-center justify-center flex-col">
              <a
                href={fileUrl}
                download={`${client?.ownerName}.xlsx`}
                className="mb-3 mx-auto block text-center !text-[#efaa20] hover:!text-[#efaa20] border !border-[#efaa20] w-fit px-4 py-1 rounded-full bg-mainNavy"
              >
                تحميل المستند
              </a>
              <div className="bg-mainNavy shadow-lg p-4 rounded-lg">
                <table className="">
                  <thead>
                    <tr className="min-w-[100px]flex gap-2">
                      {allKeys.map((key) => (
                        <th
                          className="min-w-[8vw] text-sm text-mainText"
                          key={key}
                        >
                          {key}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {excelData.map((row, index) => (
                      <tr key={index}>
                        {allKeys.map((key) => (
                          <td
                            className=" text-xs text-mainCustomGray"
                            key={key}
                          >
                            {row[key] || ""}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </Modal.Body>
          </Modal>
        )
      )}
      <div className="col-span-8  p-3  bg-mainItems rounded-[19px]">
        <div className="flex justify-end gap-2 mb-3">
          <DownloadButton>تصدير CSV </DownloadButton>
          <DownloadButton onClick={handleExport}> تصدير Excel </DownloadButton>
        </div>
        <div className="flex justify-end gap-2 mb-3">
          <DeleteButton id={clientId} />
        </div>
        <div className="d-flex flex-column gap-2">
          <div className="d-flex gap-2">
            <IoPerson className="text-xl text-mainText" />
            <Tooltip title={client?.ownerName} placement="top">

            <p className=" text-mainText text-base font-semibold line-clamp-1">
              {client?.ownerName}
            </p>
            </Tooltip>
          </div>
          <div className="d-flex  gap-2">
            <MdBusinessCenter className="text-xl text-mainText" />
            <p className="text-mainCustomGray text-sm font-medium">
              {clientTypeEnum[client?.clientType]}
            </p>
          </div>
          <div className="d-flex  gap-2">
            <FaPhone className="text-xl text-mainText" />

            <p className="text-mainCustomGray text-sm font-medium">
             <span dir="ltr">
             {client?.phone}
              </span>  
            </p>
          </div>
        </div>

        <div className="main-text choose-inf position-relative d-flex gap-3 mx-2 my-3">
          <p
            className={`genral-inf pb-2 ${
              employeeDetails === "معلومات عامة" && "inf-type"
            }`}
            onClick={() => {
              setEmployeeDetails("معلومات عامة");
            }}
          >
            معلومات عامة
          </p>
          <p
            className={`genral-inf ${
              employeeDetails === "مشاريع" && "inf-type"
            }`}
            onClick={() => {
              setEmployeeDetails("مشاريع");
            }}
          >
            مشاريع
          </p>
        </div>
        {employeeDetails === "معلومات عامة" ? (
          <div className="mx-2 ">
            <div className="clinte-details-square p-3">
              <p className="text-mainText mb-3">
                الاسم العميل :
                <span className="text-mainCustomGray pr-4">
                  {client?.ownerName}
                </span>
              </p>
              <p className="text-mainText mb-3">
                نوع العميل :
                <span className="text-mainCustomGray pr-4">
                  {clientTypeEnum[client?.clientType]}
                </span>
              </p>
              <p className="text-mainText mb-3">
                البريد الأكترونى:
                <span className="text-mainCustomGray pr-4">
                  {client?.email}
                </span>
              </p>
              <p className="text-mainText mb-3">
                رقم الجوال:
                <span className="text-mainCustomGray pr-4" dir="ltr">
                  {client?.phone}
                </span>
              </p>
              <p className="text-mainText mb-3">
                نوع الهوية :
                <span className="text-mainCustomGray pr-4">
                  {identityTypeEnum[client?.identityType]}
                </span>
              </p>
              <p className="text-mainText mb-3">
                رقم الهوية :
                <span className="text-mainCustomGray pr-4">
                  {client?.identityNumber}
                </span>
              </p>
            </div>
          </div>
        ) : (
          <div>
            <div className="d-flex align-items-center relative">
              <svg
                onClick={() => {
                  setCurrentMonth(true);
                }}
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="15"
                viewBox="0 0 17 15"
                fill="none"
              >
                <path
                  d="M17 0H0L6.8 7.77417V13.1487L10.2 14.7923V7.77417L17 0Z"
                  fill="#D59921"
                />
              </svg>
              <p className="text-mainText mx-4  my-2">الشهر الحالي</p>
              <MdArrowDropDown
                className="text-2xl text-mainText cursor-pointer"
                onClick={() => {
                  setCurrentMonth(!currentMonth);
                }}
              />

              {currentMonth && (
                <>
                  <div
                    className="bg-transparent fixed w-screen h-screen top-0 right-0 z-40"
                    onClick={() => setCurrentMonth(false)}
                  />
                  <div className="bg-mainNavy p-4 z-50 rounded-md absolute top-10 shadow-2xl">
                    <Calendar
                      className="z-50"
                      onChange={(e) => {
                        setMontlyhwork(moment(e).format("MM/DD/YYYY"));
                        setCurrentMonth(false);
                      }}
                      value={Montlyhwork}
                    />
                  </div>
                </>
              )}
            </div>

            <div className="mt-3 !h-[400px] overflow-auto scrollbar-none">
              <CustomTable columns={columns} data={client?.projects}>
                {client?.projects && client?.projects.length > 0
                  ? client?.projects.map(({ _id , projectStatus }, index) => (
                      <TableRow
                        className={`my-2 border !border-[#efaa207f] ${
                          index % 2 === 0 ? "bg-mainNavy" : ""
                        }`}
                        key={_id}
                      >
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{client?.projectName}</TableCell>
                        <TableCell>{client?.orderNumber}</TableCell>
                        <TableCell>
                          {projectTypeEnum[client?.projectType]}
                        </TableCell>
                        <TableCell>
                          {moment(client?.createdAt).format("YYYY-MM-DD")}
                        </TableCell>
                        <TableCell>{statusEnum[projectStatus]}</TableCell>
                        <TableCell>
                          <img
                            src={process.env.PUBLIC_URL + "/icons/view.svg"}
                            onClick={() => {
                              setShowProject(true);
                            }}
                            className="display_project  rounded"
                            alt=" display project"
                          />
                        </TableCell>
                        {/* <TableCell>{Claim}</TableCell> */}
                        {/* <TableCell>{Invoice}</TableCell> */}
                      </TableRow>
                    ))
                  : null}
              </CustomTable>

              <Pdf
                PdfFile={`${process.env.PUBLIC_URL + "/example.pdf"}`}
                width={800}
                height={800}
                openPdf={openCliam}
                setOpenPdf={setOpenClaim}
              />
              <Pdf
                PdfFile={`${process.env.PUBLIC_URL + "/example.pdf"}`}
                width={800}
                height={800}
                openPdf={viewInvoice}
                setOpenPdf={setViewInvoice}
              />
            </div>

            {showProject && (
              <ShowClientDetails
                showProject={showProject}
                setShowProject={setShowProject}
                data={client}
              />
            )}
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default ClientDetails;

const DeleteButton = ({ id }) => {
  const navigate = useNavigate();
  const { handleShow, handleClose, show } = useCustomModal();
  const {
    handleShowSuccess,
    handleShowError,
    handleCloseSuccess,
    handleCloseError,
    showSuccess,
    showError,
  } = useSuccessfullModal();
  const { mutate } = useDeleteClient(handleShowSuccess, handleShowError);
  return (
    <Fragment>
      <button
        onClick={handleShow}
        className="mt-2  flex justify-center gap-1 text-xs font-medium bg-[#9E0C1E] text-white w-[5.5rem] py-1 rounded-[3px]"
      >
        حذف
        <img src="/icons/delete.svg" alt="" />
      </button>

      <CustomModal
        title="التاكيد"
        show={show}
        message={"هل انت متاكد من حذف المشروع"}
        handleSave={() => {
          mutate(id);
        }}
        handleClose={handleClose}
      />
      <SuccessfullModal
        show={showSuccess}
        message={"تم الحذف بنجاح"}
        handleClose={() => {
          handleCloseSuccess();
          navigate("/System/Clients/Inside");
        }}
      />
      <SuccessfullModal
        show={showError}
        message={"حدث خطأ ما"}
        handleClose={handleCloseError}
        status="error"
      />
    </Fragment>
  );
};
