import React, { useEffect, useState } from "react";
import "./HrUsers.css";
import Cookies from "js-cookie";

import Genralnformation from "../../Users/Genralnformation/Genralnformation";
import { AccountaingInformation } from "../../Users/AccountaingInformation/AccountaingInformation";

import AddUpdateUser from "../../Users/AddUpdateUser/AddUpdateUser";
import { useNavigate, useParams } from "react-router-dom";
import SearchUsers from "../../Users/SearchUsers/SearchUsers";
import ProfessinollInformation from "../../Users/ProfessinollInformation/ProfessinollInformation";
import Image from "../../../Image";
import CustomModal from "../../../Modals/CustomModal";
import SuccessfullModal from "../../../Modals/SuccessfullModal";
import UpdatePassword from "../../../Modals/UpdatePassword";
import DownloadButton from "../../../Buttons/DownloadButton";
import { deleteUserByID, getUserById } from "../../../../helper/fetchers/Users";
import { toast } from "react-toastify";
import Progress from "../../../Progress";
import { defaultImage } from "../../../../Config/Config";
import LoadingPage from "../../../feedback/LoadingPage";
import * as XLSX from "xlsx";
import { myAxios } from "../../../../helper/https";
import { Modal } from "react-bootstrap";
import ProfileImage from "../../../ProfileImage";
const HrUsers = () => {
  const navigate = useNavigate();
  const [employeeDetails, setEmployeeDetails] = useState("aboutEmpolyee");
  const [editUser, setEditUser] = useState(false);
  const [deleteUser, setDeleteUser] = useState(false);
  const [upgradeUser, setUpgradeUser] = useState(false);
  const [successfull, setSuccsesfull] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [message, setMessage] = useState("");
  const { id } = useParams();
  const colourStyles = {
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: "rgba(255, 255, 255, 0.50)",
      };
    },
    control: (styles) => ({
      ...styles,
      backgroundColor: "#2B2B40",
      border: "unset",
      color: "rgba(255, 255, 255, 0.50);",
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,

        color: "rgba(255, 255, 255, 0.50);",
        backgroundColor: "#2B2B40",
        border: "none ",
      };
    },
  };
  const handleDelete = async () => {
    const myCountry = Cookies.get("country");
    setIsLoading(true);
    try {
      const res = await deleteUserByID(id);
      // console.log(res);

      if (res.status === 204) {
        setDeleteUser(false);
        setMessage("تم الحذف بنجاح ");
        setSuccsesfull(true);
        
        navigate(`/System/Hr/Employees/${myCountry === "مصر" ? "egypt":"Saudia"}`);
      } else {
        // console.log("Data retrieval failed");
      }
    } catch (error) {
      // toast.error(error?.response?.data?.message);
      setIsError(true);
    }
    setIsLoading(false);
  };

  const [user, setUser] = useState(null);
  const getUserWithID = async () => {
    try {
      const { data } = await getUserById(id);

      if (data) {
        setUser(data?.user);
      } else {
        // console.log("Data retrieval failed");
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    getUserWithID();
  }, [id]);

  const [excelData, setExcelData] = useState();
  const [fileUrl, setFileUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [allKeys, setAllKeys] = useState([]);

  const handleExport = async (id) => {
    setLoading(true);
    try {
      const response = await myAxios.get(`human/excel/${id}`, {
        responseType: "arraybuffer",
        headers: {
          "Content-Type": "application/excel",
        },
      });

      if (response.status === 200) {
        const data = new Uint8Array(response.data);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet);
        const keysSet = new Set();
        jsonData.forEach((row) => {
          Object.keys(row).forEach((key) => keysSet.add(key));
        });

        setAllKeys(Array.from(keysSet));

        setExcelData(jsonData);

        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = URL.createObjectURL(blob);
        setFileUrl(url);
        setLoading(false);
      } else {
        setLoading(false);

        console.error("Failed to fetch Excel:", response.statusText);
      }
    } catch (error) {
      setLoading(false);

      console.error("Error fetching Excel:", error);
    }
  };

  return (
    <div className="Users-component   w-100    text-mainText ">
      {isLoading ? <LoadingPage /> : null}
      <CustomModal
        title="التاكيد"
        show={deleteUser}
        message={"هل انت متاكد من الحذف"}
        handleSave={handleDelete}
        handleClose={() => setDeleteUser(false)}
      />
      <UpdatePassword
        id={id}
        show={upgradeUser}
        handleClose={() => setUpgradeUser(false)}
        setSuccsesfull={setSuccsesfull}
        setIsError={setIsError}
        setMessage={setMessage}
      />
      <SuccessfullModal
        show={successfull}
        message={message}
        handleClose={() => {
          setSuccsesfull(false);
          setUpgradeUser(false);
        }}
      />
      <SuccessfullModal
        show={isError}
        message={"حدث خظأ ما"}
        handleClose={() => {
          setIsError(false);
          setUpgradeUser(false);
        }}
        status="error"
      />
      <div
        style={{
          border: "1px solid #EFAA20 !important",
        }}
      >
        {editUser ? (
          <AddUpdateUser setOpenModal={setEditUser} id={id} />
        ) : (
          <div className="grid grid-cols-12 gap-2 ">
            <div className="col-span-4">
              <SearchUsers />
            </div>

            <div className="col-span-8">
              <div className="show-employee !scrollbar-none py-4 px-2 w-100 !h-full">
                {user ? (
                  <>
                    <div className="show-employee-header  ">
                      <div className="d-flex justify-content-between">
                        <p className="golden"> {user.role} </p>
                        <div className="flex gap-3">
                          {/* <DownloadButton>تصدير CSV </DownloadButton> */}
                          <DownloadButton
                            onClick={() => handleExport(user._id)}
                          >
                            {" "}
                            تصدير Excel{" "}
                          </DownloadButton>
                        </div>

                        {loading ? (
                          <LoadingPage />
                        ) : (
                          excelData && (
                            <Modal
                              show={true}
                              onHide={() => setExcelData("")}
                              className="h-screen bg-black/50 "
                              contentClassName="bg-transparent"
                              centered
                            >
                              <Modal.Body className="h-full w-full min-w-min rounded-lg bg-mainItems">
                                <a
                                  href={fileUrl}
                                  download={"clients.xlsx"}
                                  className="mb-3 mx-auto block text-center !text-[#efaa20] hover:!text-[#efaa20] border !border-[#efaa20] w-fit px-4 py-1 rounded-full bg-mainNavy"
                                >
                                  تحميل المستند
                                </a>
                                <div className="bg-mainNavy shadow-lg p-4 rounded-lg">
                                  <table className="">
                                    <thead>
                                      <tr className="min-w-[100px]">
                                        {allKeys.map((key) => (
                                          <th
                                            className="min-w-[8vw] text-sm text-mainText"
                                            key={key}
                                          >
                                            {key}
                                          </th>
                                        ))}
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {excelData.map((row, index) => (
                                        <tr key={index}>
                                          {allKeys.map((key) => (
                                            <td
                                              className=" text-xs text-mainCustomGray py-1"
                                              key={key}
                                            >
                                              {row[key] || ""}
                                            </td>
                                          ))}
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                </div>
                              </Modal.Body>
                            </Modal>
                          )
                        )}
                      </div>
                      <div className="flex justify-between gap-3 ">
                        <div className="tab d-flex   ">
                          <ProfileImage
                            image={user.image ? user.image : defaultImage}
                            imageClassName="user-Personal"
                             alt="user Image"
                          />

                          <div className="d-flex flex-column me-3 ">
                            <h2 className=" name-header     my-0    ">
                              {user.firstName}
                            </h2>
                            <p className="main-text  my-0   ">{user.email}</p>
                            <p className=" name-header my-0    ">
                              {user.phone}
                            </p>
                          </div>
                        </div>

                        <div className="flex gap-1">
                          <div className="d-flex align-items-center ">
                            <Image
                              onClick={() => {
                                setUpgradeUser(true);
                              }}
                              src={`${
                                process.env.PUBLIC_URL + "/icons/update.png"
                              }`}
                              alt="user Image "
                              className="action-buttons  "
                            />
                          </div>
                          <div className="d-flex align-items-center ">
                            <Image
                              onClick={() => {
                                setEditUser(true);
                              }}
                              src={`${
                                process.env.PUBLIC_URL + "/icons/edit.svg"
                              }`}
                              alt="user Image "
                              className="action-buttons  "
                            />
                          </div>
                          <div className="d-flex align-items-center ">
                            <Image
                              onClick={() => {
                                setDeleteUser(true);
                              }}
                              src={`${
                                process.env.PUBLIC_URL + "/icons/delete.png"
                              }`}
                              alt="user Image "
                              className="action-buttons  "
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="main-text choose-inf relative flex px-3 my-3">
                      <p
                        className={`genral-inf flex-1 text-center py-2 border-b-2 ${
                          employeeDetails === "aboutEmpolyee"
                            ? "inf-type !border-[#D59921]"
                            : "!border-white/30"
                        }`}
                        onClick={() => {
                          setEmployeeDetails("aboutEmpolyee");
                        }}
                      >
                        عن الموظف
                      </p>
                      <p
                        className={`genral-inf flex-1 text-center py-2 border-b-2 ${
                          employeeDetails === "ProfessinollInformation"
                            ? "inf-type !border-[#D59921]"
                            : "!border-white/30"
                        }`}
                        onClick={() => {
                          setEmployeeDetails("ProfessinollInformation");
                        }}
                      >
                        معلومات مهنية
                      </p>
                      <p
                        className={`genral-inf flex-1 text-center py-2 border-b-2 ${
                          employeeDetails === "AccountaingInformation"
                            ? "inf-type !border-[#D59921]"
                            : "!border-white/30"
                        }`}
                        onClick={() => {
                          setEmployeeDetails("AccountaingInformation");
                        }}
                      >
                        معلومات الراتب
                      </p>
                    </div>
                    {employeeDetails === "aboutEmpolyee" ? (
                      <Genralnformation user={user} />
                    ) : employeeDetails === "ProfessinollInformation" ? (
                      <ProfessinollInformation user={user} />
                    ) : (
                      <AccountaingInformation user={user} />
                    )}
                  </>
                ) : (
                  <Progress />
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default HrUsers;
