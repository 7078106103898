import { useQuery } from "react-query";
import myAxiosInstance, { myAxiosJson } from "../https";
import { toast } from "react-toastify";

export const getAllProjectInvoices = (id) =>
  myAxiosInstance(`finaical/project/${id}`).catch(error=>{
    toast.error(error?.response?.data?.message)
});

export const addInvoice = (data) => myAxiosInstance.post("invoice/", data);

export const getAllInvoices = (params) =>
  myAxiosInstance("invoice", { params })
    .then(({ data }) => data)
    .catch(error=>{
      toast.error(error?.response?.data?.message)
  })
export const getInvoicesById = (id) => myAxiosInstance.get(`invoice/${id}`).catch(error=>{
  toast.error(error?.response?.data?.message)
});

export const updateInvoices = (id, data) =>
  myAxiosInstance.patch(`invoice/${id}`, data);

export const deleteInvoices = (data) => myAxiosJson.delete(`invoice/`,{data});

export const useGetAllInvoices = (params) => {
  return useQuery(["invoice", params], () => getAllInvoices(params));
};
