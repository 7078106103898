import { Grid } from "@mui/material";
import { NavButton } from "./components/NavButton";
import { Outlet } from "react-router-dom";
import { PageWrapperCitizenServices } from "./components/PageWrapperCitizenServices";

export default function CitizenServices() {
  return (
    <PageWrapperCitizenServices>
      <Grid item xs={3}>
        <div className="text-mainText bg-mainItems rounded-[19px] px-2 py-3 flex flex-col gap-3 h-full">
          <p className="w-full text-mainText text-right">كل الخدمات </p>
          <div className="flex flex-col gap-2">
            <NavButton to={"/System/Settings/CitizenServices/vacations"}>
              الاجازات
            </NavButton>
            <NavButton to={"/System/Settings/CitizenServices/services"}>
              الخدمات
            </NavButton>
          </div>
        </div>
      </Grid>
      <Grid item xs={9}>
        <Outlet />
      </Grid>
    </PageWrapperCitizenServices>
  );
}
