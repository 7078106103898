import { NavLink } from "react-router-dom";

export const NavButton = ({ children, to }) => {
  return (
    <NavLink
      to={to}
      className={({ isActive }) =>
        `add-user-button p-2 text-right !w-full border hover:!border-[#EFAA20] rounded-md ${
          isActive ? "!border-[#EFAA20]" : ""
        } `
      }
    >
      {children}
    </NavLink>
  );
};
