import React, { useContext, useEffect } from "react";
import "./index.css";
import { Outlet, useNavigate } from "react-router-dom";
import AsideBar from "../../../Components/System/AsideBar/AsideBar";
import Image from "../../../Components/Image";
import { UserProvider } from "../../../Context/userProvider";
import { useSelector } from "react-redux";

const SystemIndex = () => {
  const chatNotificationSelector = useSelector(
    (state) => state.chatNotificationPlace.inChatPage
  );
  const navigate = useNavigate();
  const { user } = useContext(UserProvider);

  useEffect(() => {
    if (!user) {
      navigate("/");
      console.log("no user exists");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);
  // console.log(`I'm in Chat Page : ${chatNotificationSelector}`);
  return (
    <section className="full-system system-bg min-h-screen  flex flex-col overflow-hidden">
      <header className="container">
        <nav className="flex justify-end">
          <div className="">
            <Image
              src={process.env.PUBLIC_URL + "/icons/systemlogo.png"}
              alt="BSA logo"
              className="system-logo text-mainText"
            />
          </div>
        </nav>
      </header>

      <section className="container overflow-hidden flex-1 flex justify-between gap-3 py-4 ">
        <div className="">
          <AsideBar />
        </div>
        <main className="flex flex-col flex-1 transition-all">
          <Outlet />
        </main>
      </section>
    </section>
  );
};

export default SystemIndex;
