import React, { useEffect, useState } from "react";
import Image from "../../../Image";
import useModal from "../hooks/useModal";
import AddAttatchmentsModal from "../AddAttatchmentsModal";
import { useAcceptRequestwithId } from "../../../../hooks/fetchers/Requests";
import useSuccessfullModal from "../../../Modals/hooks/useSuccessfullModal";
import LoadingPage from "../../../feedback/LoadingPage";
import SuccessfullModal from "../../../Modals/SuccessfullModal";
import { useNavigate } from "react-router-dom";

const ConfirmTask = ({ id, path = "", setShowProject }) => {
  const navigate = useNavigate();
  const [message, setMessage] = useState("حدث خطا ما");
  const { show: acceptRequest, handleOpenModal, handleCloseModal } = useModal();
  const {
    handleCloseError,
    handleCloseSuccess,
    handleShowError,
    handleShowSuccess,
    showError,
    showSuccess,
  } = useSuccessfullModal();
  const {
    mutate: acceptMutation,
    isLoading: acceptIsLoading,
    isError,
    error,
  } = useAcceptRequestwithId(id, handleShowSuccess, handleShowError);

  useEffect(() => {
    setMessage(error?.response?.data?.message);
  }, [isError, error]);
  return (
    <>
      {acceptIsLoading ? <LoadingPage /> : null}
      <Image
        className="pointer confirm"
        onClick={handleOpenModal}
        src={process.env.PUBLIC_URL + "/icons/confirm.png"}
      />
      <AddAttatchmentsModal
        show={acceptRequest}
        title={"اضافه مرفقات المشروع"}
        handleClose={handleCloseModal}
        handleSave={(data) => {
          console.log("data is:", data);
          // handleAcceptRequest();
          acceptMutation(data);
          // handleCloseModal();
          // setMessage("تم قبول الطلب بنجاح")
          // setOpenSuccessAccept(true);
        }}
        message={"هل انت متاكد من قبول الطلب"}
      />
      <SuccessfullModal
        show={showSuccess}
        message={"تم قبول الطلب بنجاح"}
        handleClose={() => {
          handleCloseSuccess();
          handleCloseModal();
          setShowProject(false);
          navigate(path);
        }}
      />
      <SuccessfullModal
        show={showError}
        message={message}
        handleClose={handleCloseError}
        status="error"
      />
    </>
  );
};

export default ConfirmTask;
