import { useState } from "react";
import * as XLSX from "xlsx";
import { myAxiosJson } from "../helper/https";
// import config
const useExportExcel = () => {
  const [excelData, setExcelData] = useState(null);
  const [fileUrl, setFileUrl] = useState("");
  const [loading, setLoading] = useState(false);

  const [allKeys, setAllKeys] = useState([]);

  const handleExport = async (path) => {
    setLoading(true);
    try {
      const response = await myAxiosJson.get(`${path}`, {
        responseType: "arraybuffer",
        headers: {
          "Content-Type": "application/excel",
        },
      });

      if (response.status === 200) {
        const data = new Uint8Array(response.data);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet);
        const keysSet = new Set();
        jsonData.forEach((row) => {
          Object.keys(row).forEach((key) => keysSet.add(key));
        });
        console.log("jsonData", jsonData);
        setAllKeys(Array.from(keysSet));

        setExcelData(jsonData);

        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = URL.createObjectURL(blob);
        setFileUrl(url);
        setLoading(false);
      } else {
        setLoading(false);

        console.error("Failed to fetch Excel:", response.statusText);
      }
    } catch (error) {
      setLoading(false);

      console.error("Error fetching Excel:", error);
    }
  };
  const resetExcelData = () => {
    setExcelData(null);
  }
  return {
    loading,
    allKeys,
    excelData,
    fileUrl,
    handleExport,
    resetExcelData
  };
};

export default useExportExcel;
