import React, { useContext, useState } from "react";
import TechnicalSupportTable from "./components/TechnicalSupportTable";
import { TechnicalSupportContext } from "./components/TechnicalSupportProvider";
import TechnicalSupportShow from "./components/TechnicalSupportShow";
import TasksStats from "./components/TasksStats";
import {
  useGetAllTasks,
  useGetStatsPurchase,
} from "../../../hooks/fetchers/TechnicalSupportPurcheseOrder";
import LoadingPage from "../../../Components/feedback/LoadingPage";
import { useParams } from "react-router-dom";
import DashboardPieChart from "../../../Components/charts/DashboardPieChart";

const TechnicalSupportTasks = () => {
  const [search, setSearch] = useState("");

  const [page, setPage] = useState(0);
  const { status } = useParams();

  const { data, isLoading, isError } = useGetAllTasks({
    status:
      status === "waiting"
        ? "0"
        : status === "inprogress"
        ? "1"
        : status === "done-check"
        ? "70"
        : status === "completed"
        ? "5"
        : null,
    page,
    search,
  });

  const { data: statsData, isLoading: isStatsLoading } = useGetStatsPurchase();

  const { showTasksOpen } = useContext(TechnicalSupportContext);

  const title = "كل المهام";

  const formatPercentage = (value) => {
    if (statsData?.TOTAL === 0) {
      return "%0";
    }
    return `%${Math.round((value / statsData?.TOTAL) * 100)}`;
  };

  return isStatsLoading ? (
    <LoadingPage />
  ) : showTasksOpen ? (
    <TechnicalSupportShow />
  ) : (
    <div className="h-full bg-mainItems rounded-[22px]">
      {!status && <TasksStats data={statsData} />}
      {status && (
        <div className="bg-mainItems border !border-[#efaa207f] rounded-[22px] px-3 mb-5 flex py-4  items-center justify-center gap-4">
          {status === "waiting" && (
            <div className="flex flex-col gap-2">
              <div className="flex items-center justify-center gap-4">
                <div className="flex gap-2">
                  <div className="w-6 h-6 rounded-md bg-[#FEB019]" />
                  <p className="font-normal text-sm text-mainText">
                    في الانتظار
                  </p>
                </div>
                <p className="font-normal text-sm text-mainText">
                  {formatPercentage(statsData?.PENDING)}
                </p>
              </div>
              <DashboardPieChart
                label={title}
                colors={["#FEB019", "#424279"]}
                width={230}
                labels={["في الانتظار", "الكل"]}
                series={[statsData?.PENDING, statsData.TOTAL]}
              />
            </div>
          )}
          {status === "inprogress" && (
            <div className="flex flex-col gap-2">
              <div className="flex items-center justify-center gap-4">
                <div className="flex gap-2">
                  <div className="w-6 h-6 rounded-md bg-[#424279]" />
                  <p className="font-normal text-sm text-mainText">
                    قيد التنفيذ
                  </p>
                </div>
                <p className="font-normal text-sm text-mainText">
                  {formatPercentage(statsData?.IN_PROGRESS)}
                </p>
              </div>
              <DashboardPieChart
                label={title}
                colors={["#008FFB", "#424279"]}
                width={230}
                labels={["قيد التنفيذ", "الكل"]}
                series={[statsData?.IN_PROGRESS, statsData.TOTAL]}
              />
            </div>
          )}
          {status === "done-check" && (
            <div className="flex flex-col gap-2">
              <div className="flex items-center justify-center gap-4">
                <div className="flex gap-2">
                  <div className="w-6 h-6 rounded-md bg-[#FF4560]" />
                  <p className="font-normal text-sm text-mainText">تم الفحص</p>
                </div>
                <p className="font-normal text-sm text-mainText">
                  {formatPercentage(statsData?.FIXED)}
                </p>
              </div>
              <DashboardPieChart
                label={title}
                colors={["#FF4560", "#424279"]}
                width={230}
                labels={["تم الفحص", "الكل"]}
                series={[statsData?.FIXED, statsData.TOTAL]}
              />
            </div>
          )}
          {status === "completed" && (
            <div className="flex flex-col  gap-2">
              <div className="flex items-center justify-center gap-4">
                <div className="flex gap-2">
                  <div className="w-6 h-6 rounded-md bg-[#00E396]" />
                  <p className="font-normal text-sm text-mainText">مكتمل</p>
                </div>
                <p className="font-normal text-sm text-mainText">
                  {formatPercentage(statsData?.COMPLETED)}
                </p>
              </div>
              <DashboardPieChart
                label={title}
                colors={["#00E396", "#424279"]}
                width={230}
                labels={["مكتمل", "الكل"]}
                series={[statsData?.COMPLETED, statsData.TOTAL]}
              />
            </div>
          )}
        </div>
      )}
      <TechnicalSupportTable
        data={data}
        isLoading={isLoading}
        isError={isError}
        search={search}
        setSearch={(e) => setSearch(e)}
        page={page}
        setPage={setPage}
      />
    </div>
  );
};

export default TechnicalSupportTasks;
