import { useQuery } from "react-query";
import { getDashboardDepartmentStatistics, getDashboardStatistics, plansPercentage, tasksPercentage } from "../../helper/fetchers/DashboardStatistics";

export const useGetDashboardStatistics = () => {
  return useQuery("dashboard-statistics", getDashboardStatistics);
};
export const useGetDashboardDepartmentStatistics = () => {
  return useQuery("dashboard-department-statistics", getDashboardDepartmentStatistics);
};




export const useGetDashboardPlansStatistics = () => {
  return useQuery("dashboard-plans-statistics", plansPercentage);
};
export const useGetDashboardTasksStatistics = () => {
  return useQuery("dashboard-tasks-statistics", tasksPercentage);
};
