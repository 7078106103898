import { createContext, useState } from "react";
import SystemControler from "../../../../Components/System/SystemControler/SystemControler";
import BackButton from "../../../../Components/BackButton";
import { useGetAllClients } from "../../../../hooks/fetchers/Clients";
import UserDropdown from "./UserDropdown";
import { IoIosArrowDown } from "react-icons/io";
export const AllClientContext = createContext();
export default function PageWrapperAllCLients({ children }) {
  const [filterKey, setFilterKey] = useState({});
  const [search, setSearch] = useState("");
  const { data, isLoading } = useGetAllClients({clientType : filterKey?.clientType , search});
  const [open, setOpen] = useState(false);
  return (
    <AllClientContext.Provider value={{ data, isLoading, setFilterKey ,search, setSearch}}>
      <SystemControler
        child={
          <div className="flex gap-2 relative">
            <BackButton />
            <button
              className="px-8 py-2 rounded-xl bg-mainNavy text-mainText flex gap-2 items-center justify-between xl:hidden"
              onClick={() => setOpen(!open)}
            >
              كل المستخدمين
              <IoIosArrowDown className="text-mainText text-xl" />
            </button>
            {open && (
              // <div className="">
              <UserDropdown setOpen={setOpen} />
              // </div>
            )}
          </div>
        }
      />

      <div className="grid grid-cols-12 gap-2 h-full">{children}</div>
    </AllClientContext.Provider>
  );
}
