import { toast } from "react-toastify";
import { myAxios, myAxiosJson } from "../https";
export const getAllClients = (params = {}) => {
  return myAxiosJson("client", { params }).then((data) => data.data).catch(error=>{
    toast.error(error?.response?.data?.message)
});
};
// export const getExportAllClients = myAxiosJson("client/export/");
export const getClient = (id) =>
  myAxiosJson(`/client/${id}`).then((data) => data.data.client).catch(error=>{
    toast.error(error?.response?.data?.message)
});
export const getClientsByProjectType = (type) =>
  myAxiosJson(`client/?projectType=${type}`).catch(error=>{
    toast.error(error?.response?.data?.message)
});

export const deleteClient = (id) => myAxiosJson.delete(`client/${id}`);

/*******************ٌReports****************/

export const getAllClientsReports = (params = {}) =>
  myAxiosJson
    .get("system-reports/clients/list", { params })
    .then((data) => data?.data)
    .catch((error) => {
      toast.error(error?.response?.data?.message);
    });

export const getAllClientsInfo = (params = {}) =>
  myAxiosJson
    .get("system-reports/clients/info", { params })
    .then((data) => {
      return data?.data;
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message);
    });
export const getAllClientsInfoSupervising = (params = {}) =>
  myAxiosJson
    .get("system-reports/clients/info/supervising", { params })
    .then((data) => {
      return data?.data;
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message);
    });
export const getAllClientsInfoDesign = (params = {}) =>
  myAxiosJson
    .get("system-reports/clients/info/design", { params })
    .then((data) => {
      return data?.data;
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message);
    });

export const getClientReportById = (id) =>
  myAxios(`system-reports/clients/${id}`).then(({ data }) => data).catch(error=>{
    toast.error(error?.response?.data?.message)
});

export const getClientPDFReportById = (id) =>
  myAxios(`system-reports/clients/info/${id}`).then(({ data }) => data).catch(error=>{
    toast.error(error?.response?.data?.message)
});

export const getClientsStatisticsReports = () =>
  myAxios(`system-reports/clients/stats`).then(({ data }) => {
    const transformedData = [
      // { label: "خارجى", value: data?.statistics?.external?.value },
      { label: "داخلى", value: data?.statistics?.internal?.value },
    ];
    return { ...data, transformedData };
  }).catch(error=>{
    toast.error(error?.response?.data?.message)
});
export const getClientsStatisticsDesignReports = () =>
  myAxios(`system-reports/clients/stats/design`).then(({ data }) => {
    const transformedData = [
      // { label: "خارجى", value: data?.statistics?.external?.value },
      { label: "داخلى", value: data?.statistics?.internal?.value },
    ];
    return { ...data, transformedData };
  }).catch(error=>{
    toast.error(error?.response?.data?.message)
});
export const getClientsStatisticsSupervisorReports = () =>
  myAxios(`system-reports/clients/stats/supervisor`).then(({ data }) => {
    const transformedData = [
      // { label: "خارجى", value: data?.statistics?.external?.value },
      { label: "داخلى", value: data?.statistics?.internal?.value },
    ];
    return { ...data, transformedData };
  }).catch(error=>{
    toast.error(error?.response?.data?.message)
});
export const getAllClientsProjectsReports = (data) =>
  myAxiosJson
    .post("system-reports/clients/projects", data)
    // .get("system-reports/clients/projects")
    .then((data) => data?.data)
    .catch((error) => {
      toast.error(error?.response?.data?.message);
    });
