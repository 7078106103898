import SystemControler from "../../../Components/System/SystemControler/SystemControler";
import BackButton from "../../../Components/BackButton";
import ReportsSidebar from "./components/ReportsSidebar";
import { Grid } from "@mui/material";
import { Outlet } from "react-router-dom";
import ReportProvider from "./ReportContext";
import withGuard from "../../../utiltis/withGuard";

const ReportsModule = () => {
  return (
    <ReportProvider>
      <SystemControler
        child={<BackButton />}
      />
      <Grid container spacing={1} className="h-full">
        <Grid item xs={3}>
          <ReportsSidebar />
        </Grid>
        <Grid item xs={9}>
          <div className="bg-mainItems h-full rounded-[19px]">
            <Outlet/>
          </div>
        </Grid>
      </Grid>
    </ReportProvider>
  );
};

export default withGuard(ReportsModule,["مدير"])
