import React from "react";
import ReactApexChart from "react-apexcharts";
import Progress from "../../../Progress";

const ReviewRequestChart = ({ stats }) => {
  const series = [
    parseInt(stats?.statistics?.SUPERVISING?.IN_PROGRESS),
    parseInt(stats?.statistics?.SUPERVISING?.PENDING),
    parseInt(stats?.statistics?.SUPERVISING?.REJECTED),
    parseInt(stats?.statistics?.SUPERVISING?.TO_PROJECT),
  ];
  const options = {
    chart: {
      width: 380,
      type: "donut",
    },
    labels: [`قيد التنفيذ `, `فى الانتظار  `, `مرفوضة`],
    labels: [
      `قيد التنفيذ ${parseInt(stats?.statistics?.SUPERVISING?.IN_PROGRESS)} `,
      `فى الانتظار ${parseInt(stats?.statistics?.SUPERVISING?.PENDING)} `,
      `مرفوضة ${parseInt(stats?.statistics?.SUPERVISING?.REJECTED)} `,
      `محولة ${parseInt(stats?.statistics?.SUPERVISING?.TO_PROJECT)} `,
    ],
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            total: {
              show: true,
              label: "  الطلبات",
              color: "var(--main-text)",
              formatter: (val) => {
                return val?.config?.series.reduce((acc, curr) => acc + curr, 0);
              },
            },
          },
        },
      },
    },
    colors: ["#4200FF", "#D59921", "#03795D", "#E40038"],
    stroke: {
      show: false, // Set this to false to remove the border of the donut segments
    },
    dataLabels: {
      enabled: false,
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            show: false,
          },
        },
      },
    ],
    legend: {
      position: "left",
      offsetY: 0,
      height: 230,
    },
  };

  return (
    <div>
      <div className="chart-wrap">
        <div id="chart">
          {stats ? (
            <ReactApexChart
              options={options}
              series={series}
              type="donut"
              width={400}
            />
          ) : (
            <Progress />
          )}
        </div>
      </div>
    </div>
  );
};

export default ReviewRequestChart;
