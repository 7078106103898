import React from "react";
import TechnicalSupportTermsTable from "./components/TechnicalSupportTermsTable";
import { useParams } from "react-router-dom";
import TechnicalSupportTermsOrder from "./TechnicalSupportTermsOrder";

const TechnicalSupportTerms = () => {
  const { type } = useParams();
  return type === "terms-task" ? (
    <div className="h-full bg-mainItems rounded-[22px]">
      <TechnicalSupportTermsTable />
    </div>
  ) : (
    <TechnicalSupportTermsOrder />
  );
};

export default TechnicalSupportTerms;
