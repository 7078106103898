import React from "react";
import { useSearchCountryUsersContext } from "./Context";
import { SearchComponent } from "../../../SearchComponent/SearchComponent";

const UserSearch = () => {
  const { filter, setFilter } = useSearchCountryUsersContext();
  return (
    <div className="d-flex justify-content-center">
      <SearchComponent
        className="w-full"
        border="border !border-[#E4A11B]"
        handleChange={setFilter}
        value={filter}
      />
    </div>
  );
};

export default UserSearch;
