import React from "react";
import { StatisticsCard } from "../../../../Components/StatisticsCard";
import { useNavigate } from "react-router-dom";
import { useGetDashboardStatistics } from "../../../../hooks/fetchers/DashboardStatistics";

export default function CategoriesStatistics() {
  const navigate = useNavigate();
  const { data } = useGetDashboardStatistics();
  return (
    <div className="row">
      <div
        className="col-md-6 mb-3 cursor-pointer"
        onClick={() => navigate("/System/Requests/index")}
      >
        <StatisticsCard
          nested={true}
          title={"الطلبات"}
          total={data?.request}
          icon={
            <img
              src={`${process.env.PUBLIC_URL + "/Main/orders.png"}`}
              alt=""
            />
          }
        />
      </div>
      <div
        className="col-md-6 mb-3 cursor-pointer"
        onClick={() => navigate("/System/Clients")}
      >
        <StatisticsCard
          nested={true}
          title={"العملاء"}
          total={data?.request}
          icon={
            <img
              src={`${process.env.PUBLIC_URL + "/Main/clients.png"}`}
              alt=""
            />
          }
        />
      </div>
      <div
        className="col-md-6 mb-3 cursor-pointer"
        onClick={() => navigate("/System/Projects/index")}
      >
        <StatisticsCard
          nested={true}
          title={"المشاريع"}
          total={data?.project}
          icon={
            <img
              src={`${process.env.PUBLIC_URL + "/Main/projects.png"}`}
              alt=""
            />
          }
        />
      </div>
      <div
        className="col-md-6 mb-3 cursor-pointer"
        onClick={() => navigate("/System/tasks/tasks")}
      >
        <StatisticsCard
          nested={true}
          title={"المهام"}
          total={data?.tasks}
          icon={
            <img
              src={`${process.env.PUBLIC_URL + "/Main/projects.png"}`}
              alt=""
            />
          }
        />
      </div>
    </div>
  );
}
