import DownloadButton from "../../../../../../Components/Buttons/DownloadButton";
import ManagersSidebar from "../components/ManagersSidebar";
import { Spinner } from "react-bootstrap";
import {
  useGetAllUsersReports,
  useGetManagersGoalsReport,
  useGetManagersKPIsReport,
  useGetManagersTasksReport,
} from "../../../../../../hooks/fetchers/Users";
import ApexColumn from "../components/ApexColumn";
import { FormModal } from "../../../../../System/PlanModel/components/FormModal";
import { createContext, useContext, useState } from "react";
import {
  convertDateFormat,
  GenerateColumnsNames,
  projectTypeEnum,
} from "../../../../../../helper/utils";
import CustomTable from "../../../../../../Components/Table";
import { TableRow } from "../../../../../../Components/Table/TableRow";
import { TableCell } from "../../../../../../Components/Table/TableCell";
import TaskCard from "../../../components/TaskCard";
import ProgressBar from "../../../components/ProgressBar";
import { TiExport } from "react-icons/ti";
import { ImPrinter } from "react-icons/im";
import useExportExcel from "../../../../../../hooks/useExportExcel";
import useModal from "../../../../../../hooks/useModal";
import ExcellModall from "../../../../../../Components/Modals/ExcellModall";
import PrintUsersDetailsFile from "../../../components/PrintUsersDetailsFile";
import PrintManagersDetailsFile from "../../../components/PrintManagersDetailsFile";
const ReportManagersPage = () => {
  const [isLate,setIsLate] = useState(null)
  const [taskStatus,setTaskStatus] = useState(null)
  const {
    data: dataKPIs,
    isLoading: isLoadingKPIs,
    isError: isErrorKPIs,
  } = useGetManagersKPIsReport();
  const {
    data: dataGoals,
    isLoading: isLoadingGoals,
    isError: isErrorGoals,
  } = useGetManagersGoalsReport();

  const {
    data: dataTasks,
    isLoading: isLoadingTasks,
    isError: isErrorTasks,
  } = useGetManagersTasksReport({isLate: isLate, status: taskStatus});
  const { loading, allKeys, excelData, fileUrl, handleExport, resetExcelData } =
    useExportExcel();
  const { data:users } = useGetAllUsersReports({ role: "مدير المكتب" });
  const {
    open: openPdf,
    handleOpenModal: handleOpenPdfModal,
    handleCloseModal: handleClosePdfModal,
  } = useModal();
  return (
    <div className="h-full rounded-[19px] grid grid-cols-12 bg-mainNavy text-mainText gap-2">
      <div className="col-span-4 bg-mainItems rounded-[19px]">
        <ManagersSidebar />
      </div>
      <div className="col-span-8 bg-mainItems rounded-[19px]">
        <div className="flex justify-between items-center p-3">
          <div className="">
            <p className="text-[#EFAA20] text-base font-medium">{`${"مديرين المكاتب"}`}</p>
          </div>
          {!loading && excelData && (
            <ExcellModall
              onHide={resetExcelData}
              fileUrl={fileUrl}
              keys={allKeys}
              data={excelData}
            />
          )}

          <PrintManagersDetailsFile
            show={openPdf}
            onHide={handleClosePdfModal}
            data={{
              users: users?.users,
              total: users?.count,
            }}
          />

          <div className="flex items-center gap-2">
            <DownloadButton
              onClick={() =>
                handleExport(`system-reports/users/excel?role=مدير المكتب`)
              }
            >
              <div className="flex items-center gap-1">
                <span>تصدير Excel</span>
                <TiExport />
              </div>
            </DownloadButton>
            <DownloadButton onClick={() => {
                setTaskStatus(null)
                setIsLate(null)
                handleOpenPdfModal()}}>
              <div className="flex items-center gap-1">
                <span>طباعة</span>
                <ImPrinter />
              </div>
            </DownloadButton>
          </div>
        </div>
        <TasksProvider>
          <div className="space-y-4">
            <div className="w-full flex justify-center items-center">
              {isLoadingKPIs ? <Spinner /> : null}
              {isErrorKPIs ? (
                <p className="text-red-400 text-lg">يوجد خطا ما</p>
              ) : null}
            </div>
            {!isLoadingKPIs && !isErrorKPIs ? (
              <>
                <ApexColumn data={dataKPIs} />
              </>
            ) : null}

            <FormModal>
              <p className="text-sm font-medium">{"الشهر الحالى "}</p>
              <div className="mt-2">
                <p className="text-sm font-medium mb-2">{"الاهداف"}</p>
                {/* <LinearProgress variant="determinate" value={dataGoals?.goals} /> */}
                <ProgressBar progress={dataGoals?.goals} />
                <p className="text-xs font-normal text-mainCustomGray">{`حقق ${dataGoals?.goals}% من الهدف`}</p>
              </div>
            </FormModal>

            <div className="space-y-4">
              <p className="text-[#EFAA20] text-xl font-medium">{"مهام"}</p>
              <div className="flex justify-center items-center gap-4">
                <TaskCard
                  title={"مهام مكتملة"}
                  value={dataTasks?.tasksStats?.completedTasks}
                  onClick={()=>{setTaskStatus(2)}}
                />
                <TaskCard
                  title={"مهام قيد التنفيذ"}
                  value={dataTasks?.tasksStats?.inprogressTasks}
                  onClick={()=>{setTaskStatus(1)}}
                />
                <TaskCard
                  title={"مهام متأخرة"}
                  value={dataTasks?.tasksStats?.latedTasks}
                  onClick={()=>{setIsLate(true);setTaskStatus(null)}}
                />
              </div>
              <div className="">
                <TasksTable tasks={dataTasks} />
              </div>
            </div>
          </div>
        </TasksProvider>
      </div>
    </div>
  );
};

export default ReportManagersPage;

const TasksContext = createContext(null);
const TasksProvider = ({ children }) => {
  const [taskStatus, setTaskStatus] = useState("");
  return <TasksContext.Provider value={{}}>{children}</TasksContext.Provider>;
};
const useTasksContext = () => {
  const ctx = useContext(TasksContext);
  if (!ctx) {
    throw Error("use inside provider");
  }
  return ctx;
};

const columns2 = GenerateColumnsNames(
  "م",
  "اسم المهمة",
  "نوع المشروع ",
  "تاريخ الاستلام",
  "تاريخ التسليم"
);

const TasksTable = ({ tasks }) => {
  const [page, setPage] = useState(1);
  const handleChange = (event, value) => {
    setPage(value);
    // setFilterTasksParams({ page: value });
  };
  return (
    <CustomTable
      columns={columns2}
      data={tasks?.tasks}
      paginationProps={{
        count: tasks?.pages,
        page: page,
        onChange: handleChange,
      }}
    >
      {tasks?.tasks && tasks?.tasks?.length > 0
        ? tasks?.tasks?.map(
            (
              {
                _id,
                projectName,
                projectType,
                startDate,
                endDate,

                status,
              },
              index
            ) => {
              return (
                <TableRow
                  className={`my-2 border !border-[#efaa207f] ${
                    index % 2 === 0 ? "bg-mainNavy" : ""
                  }`}
                  key={_id}
                >
                  <TableCell>{(page - 1) * 10 + (index + 1)}</TableCell>
                  <TableCell>{projectName || "لا يوجد مخطط"}</TableCell>
                  <TableCell>{projectTypeEnum[+projectType]}</TableCell>
                  <TableCell>
                    {convertDateFormat(startDate) || "--------"}
                  </TableCell>
                  <TableCell>
                    {convertDateFormat(endDate) || "--------"}
                  </TableCell>
                </TableRow>
              );
            }
          )
        : null}
    </CustomTable>
  );
};
