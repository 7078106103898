import { toast } from "react-toastify";
import myAxiosInstance from "../https";

export const getAllServiceCategories = (params) => myAxiosInstance("serviceHuman",{params}).then(data => data?.data?.services).catch(error=>{
  toast.error(error?.response?.data?.message)
});
export const getServiceCategory = (id) => myAxiosInstance(`serviceHuman/${id}`).then(data => data?.data?.service).catch(error=>{
  toast.error(error?.response?.data?.message)
});
export const addServiceCategory = (data) => myAxiosInstance.post("serviceHuman", data);
export const updateServiceCategory = (categoryId, data) =>
  myAxiosInstance.patch(`serviceHuman/${categoryId}`, data);
export const deleteServiceCategory = (categoryId) =>
  myAxiosInstance.delete(`serviceHuman/${categoryId}`);
  