import React from "react";
import CustomTable from "../../../../../Components/Table";
import { TableRow } from "../../../../../Components/Table/TableRow";
import { TableCell } from "../../../../../Components/Table/TableCell";
import moment from "moment";
const columns = [
  {
    name: "م",
  },
  {
    name: "اسم المستخدم",
  },
  {
    name: "رقم الجوال",
  },
  {
    name: "الصلاحية",
  },
  {
    name: "وقت التسجيل",
  },
  {
    name: "وقت تسجيل الخروج",
  },
  {
    name: "البريد اللاكترونى",
  },

  {
    name: "الحالة",
  },
];

const UserStatusEnum = {
  0: { title: "غير متصل", color: "#9e0c1e" },
  1: { title: "متصل", color: "#75cc68" },
};

export default function UsersTable({ allUsers }) {
  return (
    <CustomTable
      paginationProps={{
        className: "hidden",
      }}
      bordered={false}
      TableHeaderProps={{
        className: "bg-[#7676764D]",
      }}
      TableHeadProps={{
        className:
          "text-end text-[10px] border-none !font-semibold p-1 text-mainText textColor",
      }}
      columns={columns}
      data={allUsers}
    >
      {allUsers && allUsers.length > 0
        ? allUsers.map(
            (
              { _id, firstName, phone, role, email, status, createdAt ,logoutTime},
              index
            ) => (
              <TableRow
                className={`my-2  border-y !border-[#616161]`}
                key={index}
              >
                <TableCell cellClassName={"min-w-[10px]"}>
                  {index + 1}
                </TableCell>
                <TableCell>{firstName || "لا توجد بيانات"}</TableCell>
                <TableCell>
                  {" "}
                  <span dir="ltr">{phone || "لا توجد بيانات"}</span>{" "}
                </TableCell>
                <TableCell>{role || "لا توجد بيانات"}</TableCell>
                <TableCell>
                  <span dir="ltr">{moment(createdAt).format("hh:mm A")}</span>
                </TableCell>
                <TableCell>
                  <span dir="ltr">{logoutTime ? moment(logoutTime).format("hh:mm A") : "لا توجد بيانات"}</span>
                </TableCell>
                <TableCell>{email || "لا توجد بيانات"}</TableCell>
                <TableCell>
                  <div className="flex items-center gap-2">
                    <div
                      className="w-2 h-2 rounded-full"
                      style={{
                        backgroundColor: UserStatusEnum[status]?.color,
                      }}
                    ></div>
                    <p>{UserStatusEnum[status]?.title || "لا توجد بيانات"}</p>
                  </div>
                </TableCell>
              </TableRow>
            )
          )
        : null}
    </CustomTable>
  );
}
