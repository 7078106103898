import React, { useContext, useState } from "react";
import SystemControler from "../../../Components/System/SystemControler/SystemControler";
import BackButton from "../../../Components/BackButton";
import ReminderPageTop from "./components/ReminderPageTop";
import ReminderPageTable from "./components/ReminderPageTable";
import { ReminderPageProvider } from "../../../Context/ReminderPage";
import ShowReminder from "./components/ShowReminder";
import EditReminder from "./components/EditReminder";
import AddReminder from "./components/AddReminder";
import { useGetAllReminders } from "./hooks/reminderForm";

const ReminderPage = () => {
  const { setAddReminder } = useContext(ReminderPageProvider);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);

  const { data, isLoading, isError } = useGetAllReminders({
    page,
    search,
  });

  return (
    <div>
      {/*System  Top*/}
      <SystemControler
        child={
          <div className="flex items-center gap-2 relative ">
            <BackButton />
            <div
              className="add-user-button cursor-pointer customButton"
              onClick={() => setAddReminder(true)}
            >
              إضافة جديدة
            </div>
          </div>
        }
      />
      <AddReminder />
      <EditReminder />
      <ShowReminder />
      {/* Reminder Pop up  */}
      {/* <ReminderPopup /> */}
      <ReminderPageTop data={data} />
      <ReminderPageTable
        data={data}
        isLoading={isLoading}
        isError={isError}
        search={search}
        setSearch={setSearch}
        page={page}
        setPage={setPage}
      />
    </div>
  );
};

export default ReminderPage;
