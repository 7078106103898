import { createSlice } from "@reduxjs/toolkit";

export const SHOWMODE = "show"
export const EDITMODE = "edit"

const initialState = {
    mode: SHOWMODE
}
const holidayFormSlice = createSlice({
    name:"holiday/form",
    initialState,
    reducers:{
        switchMode: (state)=>{
            state.mode = state.mode === SHOWMODE ? EDITMODE : SHOWMODE
        },
        setMode: (state,action)=>{
            state.mode = action.payload
        },

    }
})

export const {switchMode,setMode} = holidayFormSlice.actions
export default holidayFormSlice.reducer