import { Avatar } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

const textFormatted = (text) => {
  if (text.length > 40) {
    return text.slice(0, 40) + "...";
  }
  return text;
};

function MessageNotificationCard({ data }) {
  return (
    <div className="flex flex-col !bg-mainItems w-[500px] h-[140px] rounded-[9px] overflow-hidden">
      <div className="text-mainText flex items-center gap-2">
        <img
          src="/icons/MessageIcon.svg"
          alt="message icon"
          className="self-end"
        />
        <p>رسالة جديدة</p>
      </div>
      <div className="flex flex-1 items-center">
        <div className="flex-1 flex items-center gap-3">
          <div>
            <Avatar
              src={data?.sender?.image}
              alt={`${data?.sender?.firstName} ${data?.sender?.lastName}`}
              sx={{ width: 56, height: 56 }}
            />
          </div>
          <div className="flex flex-col gap-2">
            <p className="text-xl text-[#EFAA20]">{`${data?.sender?.firstName} ${data?.sender?.lastName}`}</p>
            <p className="text-base text-mainText overflow-hidden">
              {textFormatted(data?.message)}
            </p>
          </div>
        </div>
        <div>
          <Link
            to={`/System/Chat/${data?.sender?.id}`}
            className="bg-[#EFAA20] py-1 !text-[#1E1E2D] hover:bg-mainItems hover:text-mainText transition-colors border !border-[#EFAA20] text-base px-12 rounded-md"
          >
            الرد
          </Link>
        </div>
      </div>
    </div>
  );
}

export default MessageNotificationCard;
