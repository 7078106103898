import React, { useContext, useEffect, useState } from "react";
import {
  useAddReminders,
  useGetReminders,
  useGetUserReminderSelect,
  useUpdateReminders,
} from "../hooks/reminderForm";
import LoadingPage from "../../../../Components/feedback/LoadingPage";
import { Form, Modal } from "react-bootstrap";
import Image from "../../../../Components/Image";
import useSuccessfullModal from "../../../../Components/Modals/hooks/useSuccessfullModal";
import { ReminderPageProvider } from "../../../../Context/ReminderPage";
import { InputLabel } from "../../PlanModel/components/InputLabel";
import {
  CustomDatePicker,
  CustomInput,
  CustomTimePicker,
} from "../../../../Components/FormUi";
import { Controller, useForm } from "react-hook-form";
import Select from "../../../../Components/FormHandler/Select";
import { ErrorMessage } from "@hookform/error-message";
import moment from "moment";
import dayjs from "dayjs";
import SuccessfullModal from "../../../../Components/Modals/SuccessfullModal";
import { CustomOption } from "../../../../Components/CustomOption/CustomOption";
import {
  getReminder,
  getUserReminderSelect,
} from "../../../../helper/fetchers/reminder";
import CustomTextArea from "../../../../Components/FormUi/CustomTextArea";

const EditReminder = () => {
  const {
    data: usersSelect,
    isLoading: isLoadingSelect,
    isError: isErrorSelect,
  } = useGetUserReminderSelect();

  const { EditReminder, setEditReminder, reminder } =
    useContext(ReminderPageProvider);
  const { data, isLoading: isLoadingData } = useGetReminders(reminder?._id);
  const [defaultValues, setDefaultValues] = useState({});
  const projectTypeData = [
    { value: 1, label: "تصميم" },
    { value: 2, label: "الاشراف علي التنفيذ" },
    { value: "other", label: "اخري" },
  ];

  const periorityData = [
    { value: 2, label: "عادية" },
    { value: 4, label: "متوسطة" },
    { value: 6, label: "مهمه" },
  ];
  const convertTime = (time) => {
    if (time) {
      const formattedTime = dayjs(time, "hh:mm A");
      return formattedTime.toDate(); // Convert dayjs object to JavaScript Date object
    }
  };
  useEffect(() => {
    if (data) {
      console.log("data", data);
      setDefaultValues({
        reminderName: data?.reminderName || "",
        assignTo: data?.assignTo?.map((item) => ({
          label: `${item.firstName} ${item.lastName}`,
          value: item._id,
          image: item.image,
          role: item.role,
        })),
        clientName: data?.clientName || "",
        details: data?.details || "",
        periority: periorityData?.find((x) => x?.value === +data?.periority),
        projectType: projectTypeData?.find(
          (x) => x?.value === +data?.projectType
        ),
        reminderDate: new Date(data?.reminderDate) || null,
        reminderTime: convertTime(data?.reminderTime) || null,
      });
    }
  }, [data]);

  const [err, setErr] = useState("");
  const {
    showSuccess,
    showError,
    handleShowSuccess,
    handleShowError,
    handleCloseSuccess,
    handleCloseError,
  } = useSuccessfullModal();

  const { mutate, isLoading, isError, error } = useUpdateReminders(
    handleShowSuccess,
    (err) => {
      handleShowError();
      setErr(
        err?.response?.data?.validationErrors || err?.response?.data?.message
      );
    },
    reminder?._id
  );

  const {
    register,
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    // defaultValues: async () => {
    //   const res = await getReminder(reminder?._id);
    //   const data = res;
    //   console.log("res", data.assignTo);
    //   return {
    //     assignTo: data?.assignTo?.map((item) => ({
    //       label: `${item.firstName} ${item.lastName}`,
    //       value: item._id,
    //     })),
    //   };
    // },
  });

  useEffect(() => {
    if (Object.keys(defaultValues).length > 0) {
      reset(defaultValues);
    }
  }, [defaultValues, reset]);

  const onSubmit = (data) => {
    const payload = {
      clientName: data.clientName,
      reminderName: data.reminderName,
      reminderDate: moment(data.reminderDate).format("YYYY-MM-DD"),
      reminderTime: moment(data.reminderTime).format("hh:mm A"),
      details: data.details,
      assignTo: data.assignTo?.map((user) => user.value),
      periority: data.periority?.value,
      projectType: data.projectType?.value,
    };
    mutate(payload);
  };

  return (
    <>
      {isLoading ? (
        <LoadingPage />
      ) : (
        <EditReminderWrapper
          show={EditReminder}
          onHide={() => setEditReminder(false)}
        >
          <Form
            onSubmit={handleSubmit(onSubmit)}
            className="row my-4 date-input w-100 mx-auto p-3"
          >
            <div className="col-md-12 mb-4">
              <div className="row">
                <div className="col-md-12 flex flex-col">
                  <InputLabel label={"اسم المهمه"} mandatory={true} />
                  <CustomInput
                    control={control}
                    name="reminderName"
                    placeholder="اكتب اسم المهمه"
                    rules={{
                      required: "يجب كتابة اسم اسم",
                      minLength: { value: 3, message: "لا يقل عن 3 حروف" },
                      maxLength: { value: 100, message: "لا يقل عن 100 حروف" },
                      pattern: {
                        value: /^[A-Za-z\u0600-\u06FF ]{3,}$/i,
                        message:
                          "يجب ان تتكون من حروف عربى فقط او انجليزية فقط رقم",
                      },
                    }}
                    id="new-project"
                  />
                </div>
              </div>
            </div>
            <div className="col-md-12 mb-4">
              <div className="row">
                <div className="col-md-6 flex flex-col">
                  <InputLabel label={"اسم العميل"} mandatory={true} />
                  <CustomInput
                    control={control}
                    name="clientName"
                    placeholder="اكتب اسم العميل"
                    rules={{
                      required: "يجب كتابة اسم اسم",
                      minLength: { value: 3, message: "لا يقل عن 3 حروف" },
                      maxLength: { value: 100, message: "لا يقل عن 100 حروف" },
                      pattern: {
                        value: /^[A-Za-z\u0600-\u06FF ]{3,}$/i,
                        message:
                          "يجب ان تتكون من حروف عربى فقط او انجليزية فقط رقم",
                      },
                    }}
                    id="new-project"
                  />
                </div>
                <div className="col-md-6">
                  <InputLabel label={"نوع المشروع"} mandatory={true} />
                  <Controller
                    control={control}
                    name="projectType"
                    rules={{ required: "من فضلك اختر نوع المشروع" }}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Select
                        fullWidth
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        OptionbackgroundColor="#414162"
                        placeholder="اختر نوع المشروع"
                        options={projectTypeData}
                      />
                    )}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="projectType"
                    render={({ message }) => (
                      <p className="text-[#FF0000]">{message}</p>
                    )}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6 mb-4">
              <InputLabel label={"الاولوية"} mandatory={true} />
              <Controller
                control={control}
                name="periority"
                rules={{ required: "من فضلك اختر الاولوية" }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Select
                    fullWidth
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    OptionbackgroundColor="#414162"
                    placeholder="اختر الاولوية"
                    options={periorityData}
                  />
                )}
              />
              <ErrorMessage
                errors={errors}
                name="periority"
                render={({ message }) => (
                  <p className="text-[#FF0000]">{message}</p>
                )}
              />
            </div>
            <div className="col-md-6 mb-4">
              {!isLoadingSelect && !isErrorSelect && (
                <>
                  <InputLabel label={"موجه الى"} mandatory={true} />
                  <Controller
                    control={control}
                    name={`assignTo`}
                    render={({ field: { onChange, onBlur, value = "" } }) => (
                      <Select
                        fullWidth
                        onChange={onChange}
                        onBlur={onBlur}
                        OptionbackgroundColor="#414162"
                        placeholder="اختر الشخص"
                        isMulti={true}
                        options={usersSelect?.allUsers?.map(
                          ({ _id, firstName, lastName, image, role }) => ({
                            label: `${firstName} ${lastName}`,
                            value: _id,
                            image,
                            role,
                          })
                        )}
                        components={{ Option: CustomOption }}
                      />
                    )}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="assignTo"
                    render={({ message }) => (
                      <p className="text-[#FF0000]">{message}</p>
                    )}
                  />
                </>
              )}
            </div>
            <div className="col-md-12 mb-4">
              <Form.Group>
                <InputLabel label={"التفاصيل"} />

                <CustomTextArea
                  control={control}
                  name="details"
                  placeholder={"اكتب التفاصيل "}
                />
              </Form.Group>
            </div>

            <div className="col-md-12 mb-4">
              <div className="row">
                <div className="!w-1/2">
                  <Form.Group
                    className="licenseDate-container flex flex-col"
                    controlId="reminderDate"
                  >
                    <InputLabel
                      size={16}
                      label={"تاريخ التذكير"}
                      className={"mb-1"}
                    />
                    <CustomDatePicker
                      control={control}
                      name={"reminderDate"}
                      rules={{ required: "يجب اختيار التذكير" }}
                    />
                    <ErrorMessage
                      name="reminderDate"
                      errors={errors}
                      render={({ message }) => (
                        <span className="text-red-500">{message}</span>
                      )}
                    />
                  </Form.Group>
                </div>

                <div dir="rtl" className="col-md-6 mb-2">
                  <div>
                    <Form.Group
                      className="licenseDate-container w-100 flex flex-col"
                      controlId="reminderTime"
                    >
                      <InputLabel label={"وقت التذكير"} />
                      <CustomTimePicker
                        control={control}
                        name="reminderTime"
                        rules={{ required: "من فضلك اكتب وقت بدا الاجتماع" }}
                      />
                    </Form.Group>
                  </div>
                </div>
              </div>
            </div>

            <div className="d-flex justify-content-center">
              <button
                type="submit"
                className="mt-4 sumbmitAddUpdateUser border-0 disabled"
              >
                حفظ
              </button>
            </div>
          </Form>
        </EditReminderWrapper>
      )}
      <SuccessfullModal
        handleClose={() => {
          handleCloseSuccess();
          setEditReminder(false);
          // navigate("/System/Accounating/index");
        }}
        message={"تمت التعديل بنجاح"}
        show={showSuccess}
      />
      <SuccessfullModal
        handleClose={handleCloseError}
        message={err}
        show={showError}
        status="error"
      />
    </>
  );
};

export default EditReminder;

const EditReminderWrapper = ({ children, show, onHide }) => {
  return (
    <Modal
      size="lg"
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
      className="systemModal mettingModal bg-black/50"
      contentClassName="scroll p-0"
    >
      <Modal.Header className="w-full">
        <div className="w-full flex justify-between">
          <p className="text-xl text-mainText">تعديل تذكير</p>
          <Image
            src="/Rejected.svg"
            alt="close modal button"
            className="pointer"
            onClick={onHide}
          />
        </div>
      </Modal.Header>
      {children}
    </Modal>
  );
};
