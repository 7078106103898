import React from "react";
import "./MeettingStyles.css";
import SystemControler from "../../../../Components/System/SystemControler/SystemControler";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
// import "moment/locale/ar";

import { AddMeeting } from "../../../../Components/System/Meetings/AddMeeting/AddMeeting";
import EditDeleteMeeting from "../../../../Components/System/Meetings/EditDeleteMeeting/EditDeleteMeeting";
import BackButton from "../../../../Components/BackButton";
import MeetingProvider, { useMeetingContext } from "./meetingContext";
require("globalize/lib/cultures/globalize.culture.ar-AE");

const AllMeetings = () => {
  return (
    <MeetingProvider>
      <AddSection />
      <EditSection />
      <Header />
      <MeetingsWraqpper>
        <EventsSection />
      </MeetingsWraqpper>
    </MeetingProvider>
  );
};

export default AllMeetings;

const MeetingsWraqpper = ({ children }) => {
  return (
    <div className="bg-mainItems h-full rounded-[17px]">
      <div className="bg-mainItems h-[701px] rounded-[17px] meeting scrollbar-none overflow-auto">
        {children}
      </div>
    </div>
  );
};

const Header = () => {
  const { AddModalActions } = useMeetingContext();

  return (
    <>
      <SystemControler
        child={
          <div className="">
            <BackButton />
            <button
              onClick={AddModalActions.handleOpenModal}
              className="add-user-button customButton"
            >
              اضافة جديدة
            </button>
          </div>
        }
      />
    </>
  );
};

const EditSection = () => {
  const {
    MeetingsApi,
    selectedEventId,
    showEditDeleteModal,
    setShowEditDeleteModal,
  } = useMeetingContext();
  return (
    <>
      {selectedEventId && (
        <EditDeleteMeeting
          id={selectedEventId}
          showEditDeleteModal={showEditDeleteModal}
          setShowEditDeleteModal={setShowEditDeleteModal}
          events={MeetingsApi?.data?.events}
        />
      )}
    </>
  );
};

const AddSection = () => {
  const { AddModalActions } = useMeetingContext();
  return (
    <AddMeeting
      view={AddModalActions.open}
      onClose={AddModalActions.handleCloseModal}
    />
  );
};

const EventsSection = () => {
  const { MeetingsApi, mappedEvents, handleEditMeeting } = useMeetingContext();
  const localizer = momentLocalizer(moment);
  return (
    <>
      {!MeetingsApi.isLoading && !MeetingsApi.isError ? (
        <Calendar
          onSelectEvent={handleEditMeeting}
          defaultDate={moment().toDate()}
          defaultView="month"
          culture={"ar-AE"}
          events={mappedEvents || []}
          localizer={localizer}
          style={{ height: "100%", margin: "0px", padding: "20px" }}
          rtl={false}
          messages={{
            work_week: "أسبوع العمل",
            day: "يوم",
            month: "شهر",
            previous: "<",
            next: ">",
            today: "اليوم",
            agenda: "جدول أعمال",
          }}
        />
      ) : null}
    </>
  );
};
