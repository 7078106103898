import React, { Fragment } from "react";
import { Avatar, Stack } from "@mui/material";
import {
  ConnectedBadge,
  NotConnectedBadge,
} from "../../../../../../Components/Users/StatusBadge";
import { defaultImage } from "../../../../../../Config/Config";
const UserListItemCard = ({ email, status, firstName, lastName, image }) => {
  return (
    <Fragment>
      <Stack direction="row" spacing={2}>
        {status === 1 ? (
          <ConnectedBadge
            overlap="circular"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            variant="dot"
          >
            <Avatar
              alt={`${firstName} ${lastName}`}
              src={image ? image : defaultImage}
            />
          </ConnectedBadge>
        ) : (
          <NotConnectedBadge
            overlap="circular"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            variant="dot"
          >
            <Avatar
              alt={`${firstName} ${lastName}`}
              src={image ? image : defaultImage}
            />
          </NotConnectedBadge>
        )}
      </Stack>
      <div className="d-flex flex-column me-3 ">
        <h2 className="text-mainText text-base font-medium">
          {`${firstName} ${lastName}`}
        </h2>
        <p className="text-mainCustomGray text-sm font-normal">{email}</p>
      </div>
    </Fragment>
  );
};

export default UserListItemCard;
