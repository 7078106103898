import { useQueryClient, useMutation, useQuery } from "react-query";
import {
  addUser,
  getAllUserPDFInfoReports,
  getAllUsers,
  getAllUsersHoliday,
  getAllUsersReports,
  getChatUsers,
  getDepartmentGoalsReport,
  getDepartmentKPIsReport,
  getDepartmentTasksReport,
  getManagersGoalsReport,
  getManagersKPIsReport,
  getManagersTasksReport,
  getManagersUsers,
  getSelectedUsers,
  getStatistics,
  getUserById,
  getUserGoalsReportById,
  getUserKPIsReportById,
  getUserReportById,
  getUserSalaryReportById,
  getUserServicesReportById,
  getUserStatisticsDepartmentsReports,
  getUserStatisticsDepartmentsReportsEgy,
  getUserStatisticsDepartmentsReportsSaudi,
  getUserStatisticsReports,
  getUserStatisticsReportsEgy,
  getUserStatisticsReportsSaudi,
  getUserTasksReportById,
  getUserVacationReportById,
  getUserVacationsReportById,
  updateMyProfile,
  updateProfilePic,
  updateUser,
} from "../../helper/fetchers/Users";
import { getUserProfile } from "../../helper/fetchers/Profie";
import { CheckRole } from "../../helper/utils";
import { roles } from "../../helper/fetchers/Tasks";

export const useGetAllUsers = (params) => {
  return useQuery(["users", params], () => getAllUsers(params));
};
export const useGetAllUsersReports = (params) => {
  return useQuery(["users", "reports", params], () =>
    getAllUsersReports(params)
  );
};
export const useGetAllUserPDFInfoReports = (department) => {
  return useQuery(["users", "reports","pdf", department], () =>
    getAllUserPDFInfoReports(department),
  {
    enabled: !!department
  }
  );
};
export const useGetChatUsers = (params) => {
  return useQuery(["users-chat", params], () => getChatUsers(params));
};
export const useGetUser = (userId) => {
  return useQuery(["users", userId], () => getUserById(userId), {
    enabled: !!userId,
  });
};
export const useGetUserReport = (userId) => {
  return useQuery(
    ["users", "reports", userId],
    () => getUserReportById(userId),
    {
      enabled: !!userId,
    }
  );
};
export const useGetUserSalaryReport = (userId) => {
  return useQuery(
    ["users", "reports", "salary", userId],
    () => getUserSalaryReportById(userId),
    {
      enabled: !!userId,
    }
  );
};
export const useGetUserVacationsReport = (userId) => {
  return useQuery(
    ["users", "reports", "vacations", userId],
    () => getUserVacationsReportById(userId),
    {
      enabled: !!userId,
    }
  );
};
export const useGetUserVacationReport = (userId, vacationId) => {
  return useQuery(
    ["users", "reports", "vacations", userId, vacationId],
    () => getUserVacationReportById(userId, vacationId),
    {
      enabled: !!userId && !!vacationId,
    }
  );
};

export const useGetUserServicesReport = (userId) => {
  return useQuery(
    ["users", "reports", "services", userId],
    () => getUserServicesReportById(userId),
    {
      enabled: !!userId,
    }
  );
};
export const useGetUserGoalsReportById = (userId) => {
  return useQuery(
    ["users", "reports", "goals", userId],
    () => getUserGoalsReportById(userId),
    {
      enabled: !!userId,
    }
  );
};
export const useGetManagersGoalsReport = () => {
  return useQuery(["manager", "reports", "goals"], getManagersGoalsReport);
};
export const useGetDepartmentGoalsReport = (department) => {
  return useQuery(
    ["users", "reports", "goals", "department", department],
    () => getDepartmentGoalsReport(department),
    {
      enabled: !!department,
    }
  );
};
export const useGetUserTasksReportById = (userId,params) => {
  return useQuery(
    ["users", "reports", "tasks", userId,params],
    () => getUserTasksReportById(userId,params),
    {
      enabled: !!userId,
    }
  );
};
export const useGetManagersTasksReport = (params) => {
  return useQuery(["manager", "reports", "tasks",params],()=> getManagersTasksReport(params));
};

export const useGetManagersKPIsReport = () => {
  return useQuery(["manager", "reports", "kpi"], getManagersKPIsReport);
};
export const useGetUserKPIsReportById = (userId) => {
  return useQuery(
    ["users", "reports", "kpi", userId],
    () => getUserKPIsReportById(userId),
    {
      enabled: !!userId,
    }
  );
};
export const useGetDepartmentTasksReport = (department,params) => {
  return useQuery(
    ["users", "reports", "tasks", "department", department , params],
    () => getDepartmentTasksReport(department,params),
    {
      enabled: !!department,
    }
  );
};
export const useGetDepartmentKPIsReport = (department) => {
  return useQuery(
    ["users", "reports", "kpi", "department", department],
    () => getDepartmentKPIsReport(department),
    {
      enabled: !!department,
    }
  );
};
export const useGetUserStatisticsReports = () => {
  return useQuery(["users", "reports", "stats"], getUserStatisticsReports);
};
export const useGetUserStatisticsReportsEgy = () => {
  return useQuery(
    ["users", "reports", "stats", "egy"],
    getUserStatisticsReportsEgy
  );
};
export const useGetUserStatisticsReportsSaudi = () => {
  return useQuery(
    ["users", "reports", "stats", "saudi"],
    getUserStatisticsReportsSaudi
  );
};
export const useGetUserStatisticsDepartmentsReports = (params) => {
  return useQuery(["users", "reports", "departments", "stats", params], () =>
    getUserStatisticsDepartmentsReports(params)
  );
};
export const useGetUserStatisticsDepartmentsReportsEgy = (params) => {
  return useQuery(["users", "reports", "stats", "departments", "egy"], () =>
    getUserStatisticsDepartmentsReportsEgy(params)
  );
};
export const useGetUserStatisticsDepartmentsReportsSaudi = (params) => {
  return useQuery(["users", "reports", "stats", "departments", "saudi"], () =>
    getUserStatisticsDepartmentsReportsSaudi(params)
  );
};
export const useGetUserProfile = () => {
  return useQuery(["users","profile"], getUserProfile);
};
export const useGetRolesStatistics = () => {
  return useQuery("users-roles", () => getStatistics());
};
export const useGetAllUsersHoliday = (params) => {
  return useQuery(["users-select", params], getAllUsersHoliday);
};
export const useGetManagersUsers = () => {
  return useQuery("users-managers", getManagersUsers ,
  //    {
  //   enabled: CheckRole(roles["admin"]) || CheckRole(roles["HR"])
  // }
);
};
export const useGetSelectedUsers = (enabled) => {
  return useQuery("users-selected", getSelectedUsers, {
    enabled: CheckRole(roles["admin"]) || CheckRole(roles["HR"]),
  });
};

export const useAddUser = (onSuccess, onError) => {
  const queryClient = useQueryClient();
  return useMutation(addUser, {
    onSuccess: (e) => {
      queryClient.invalidateQueries("users");
      onSuccess(e);
    },
    onError: (error) => {
      onError(error);
      // Handle error
    },
  });
};
export const useUpdateUser = (onSuccess, onError, userId) => {
  const queryClient = useQueryClient();
  return useMutation((data) => updateUser(userId, data), {
    onSuccess: () => {
      queryClient.invalidateQueries("users");
      onSuccess();
    },
    onError: (error) => {
      onError();
      // Handle error
    },
  });
};

export const useUpdateMyProfile = (onSuccess, onError) => {
  const queryClient = useQueryClient();
  return useMutation(updateMyProfile, {
    onSuccess: () => {
      queryClient.invalidateQueries("users");
      queryClient.invalidateQueries("profile");
      onSuccess();
    },
    onError: (error) => {
      onError();
      // Handle error
    },
  });
};
export const useUpdateUserPic = (onSuccess, onError) => {
  const queryClient = useQueryClient();
  return useMutation(updateProfilePic, {
    onSuccess: () => {
      queryClient.invalidateQueries("users");
      queryClient.invalidateQueries("profile");
      onSuccess();
    },
    onError: (error) => {
      onError();
      // Handle error
    },
  });
};
