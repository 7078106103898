import React, { useEffect } from "react";
import ReactApexChart from "react-apexcharts";

const InsideClientLineChart = ({ total }) => {
  useEffect(() => {
    const text = document.querySelectorAll(
      "text.apexcharts-text.apexcharts-datalabel-value"
    );
    text[0].setAttribute("fill", "#FFFFFF");
  }, []);

  const options = {
    tooltip: {
      enabled: true,
    },
    series: [
      {
        name: "التصميم",
        data: [45, 52, 38, 24, 33, 26, 21, 20, 6, 8, 15, 10],
      },
      {
        name: "الاشراف في التنفيذ",
        data: [50, 60, 40, 20, 36, 39, 40, 30, 10, 10, 20, 20],
      },
    ],
    chart: {
      height: 350,
      type: "line",
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: [5, 7, 5],
      curve: "smooth",
      dashArray: [0, 0],
    },
    title: {
      text: "Page Statistics",
      align: "right",
    },
    legend: {
      tooltipHoverFormatter: function (val, opts) {
        return (
          val +
          " - " +
          opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] +
          ""
        );
      },
    },
    colors: ["#EFAA20", "#00C4C9"],
    yaxis: [
      {
        title: {},
        opposite: true,
        labels: {
          show: true,
          align: "left",
          style: {
            colors: "var(--main-text)",
          },
        },
      },
    ],
    markers: {
      size: 0,
      hover: {
        sizeOffset: 6,
      },
    },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      labels: {
        style: {
          colors: "var(--main-text)",
        },
      },
    },
    tooltip: {
      y: [
        {
          title: {
            formatter: function (val) {
              return val + " (mins)";
            },
          },
        },
        {
          title: {
            formatter: function (val) {
              return val + " per session";
            },
          },
        },
        {
          title: {
            formatter: function (val) {
              return val;
            },
          },
        },
      ],
    },
    grid: {
      borderColor: "#f1f1f1",
    },
  };

  return (
    <div id="chart" className="w-100 custom-chart">
      <ReactApexChart
        options={options}
        series={options.series}
        type="line"
        className="w-100"
        height={340}
      />
    </div>
  );
};

export default InsideClientLineChart;
