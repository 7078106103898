import { Grid } from "@mui/material";
import React from "react";
import { Column } from "./Orders/components/Column";
import { NavButton } from "./Orders/components/NavButton";
import { ColumnTitle } from "./Orders/components/ColumnTitle";

const OrdersCategoryDropdown = ({ setOpen }) => {
  return (
    <div className="absolute top-[2.8rem] right-12 z-[1000000] bg-mainNavy rounded-xl">
      <div className="flex items-start flex-col  bg-mainItems w-[300px] h-[300px]  p-2 overflow-auto shadow-2xl ">
        <div
          className="w-screen h-screen fixed inset-0  z-[-1]"
          onClick={() => {
            setOpen(false);
          }}
        />
        <div className="flex flex-col w-full gap-2">
          <ColumnTitle title={"الطلبات"} />
          <NavButton to={"/System/Settings/Orders/categories"}>
            استخدام المشروع
          </NavButton>
          <NavButton to={"/System/Settings/Orders/services"}>
            خدمات المشروع
          </NavButton>
        </div>
      </div>
    </div>
  );
};

export default OrdersCategoryDropdown;
