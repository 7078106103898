import myAxiosInstance, { myAxios, myAxiosJson } from "../https";
import { toast } from "react-toastify";
import { CheckRole } from "../utils";

export const getAllUsers = (params = {}) => {
  let url = "user";
  const queryParams = Object.entries(params)
    .map((param) => param[1] && param[1] !== "" && `${param[0]}=${param[1]}`)
    .join("&");
  // console.log("queryParams: ",queryParams);
  if (queryParams) {
    url += `?${queryParams}`;
  }
  if (!CheckRole("موظف")) {
    return myAxiosJson
      .get(url)
      .then((data) => data?.data)
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  }
};
export const getChatUsers = (params = {}) => {
  let url = "user/chat";
  const queryParams = Object.entries(params)
    .map((param) => param[1] && param[1] !== "" && `${param[0]}=${param[1]}`)
    .join("&");
  // console.log("queryParams: ",queryParams);
  if (queryParams) {
    url += `?${queryParams}`;
  }
  return myAxiosJson
    .get(url)
    .then((data) => data?.data)
    .catch((error) => {
      toast.error(error?.response?.data?.message);
    });
};

export const getAllUsersHoliday = () =>
  myAxiosJson("user")
    .then((data) =>
      data?.data?.allUsers?.map(function (user) {
        return { value: user._id, label: user.firstName };
      })
    )
    .catch((error) => {
      toast.error(error?.response?.data?.message);
    });
export const getManagersUsers = () =>
  myAxiosJson("user/managers")
    .then((data) =>
      data?.data?.allUsers?.map(function (user) {
        return { value: user._id, label: `${user.firstName} ${user.lastName}` };
      })
    )
    .catch((error) => {
      toast.error(error?.response?.data?.message);
    });
export const getSelectedUsers = () =>
  myAxiosJson("user/allUsers")
    .then((data) =>
      data?.data?.allUsers?.map(function (user) {
        return { value: user._id, label: `${user.firstName} ${user.lastName}` };
      })
    )
    .catch((error) => {
      toast.error(error?.response?.data?.message);
    });

export const getAllUsersReports = (params = {}) =>
  myAxiosJson
    .get("system-reports/users/list", { params })
    .then((data) => data?.data)
    .catch((error) => {
      toast.error(error?.response?.data?.message);
    });
export const getAllUserPDFInfoReports = (department) =>
  myAxiosJson
    .get(`system-reports/users/pdf/department/${department}`)
    .then((data) => data?.data)
    .catch((error) => {
      toast.error(error?.response?.data?.message);
    });

export const addUser = (data) => myAxiosInstance.post("user", data);

export const getUserById = (id) => myAxios(`user/${id}`).catch(error=>{
  toast.error(error?.response?.data?.message)
});
export const getUserReportById = (id) =>
  myAxios(`system-reports/users/about/${id}`).then(({ data }) => data).catch(error=>{
    toast.error(error?.response?.data?.message)
});
export const getUserSalaryReportById = (id) =>
  myAxios(`system-reports/users/${id}/salary`).then(({ data }) => data).catch(error=>{
    toast.error(error?.response?.data?.message)
});
export const getUserVacationsReportById = (id) =>
  myAxios(`system-reports/users/${id}/vacations`).then(({ data }) => data).catch(error=>{
    toast.error(error?.response?.data?.message)
});

export const getUserVacationReportById = (userId, vacationId) =>
  myAxios(`system-reports/users/${userId}/vacations/${vacationId}`).then(
    ({ data }) => data
  ).catch(error=>{
    toast.error(error?.response?.data?.message)
});

export const getUserServicesReportById = (id) =>
  myAxios(`system-reports/users/${id}/services`).then(({ data }) => data).catch(error=>{
    toast.error(error?.response?.data?.message)
});
export const getUserGoalsReportById = (id) =>
  myAxios(`system-reports/users/${id}/goals`).then(({ data }) => data).catch(error=>{
    toast.error(error?.response?.data?.message)
});
export const getDepartmentGoalsReport = (department) =>
  myAxios(`system-reports/users/goals/${department}`).then(({ data }) => data).catch(error=>{
    toast.error(error?.response?.data?.message)
});
export const getUserTasksReportById = (id, params) =>
  myAxios(`system-reports/users/${id}/tasks`, { params }).then(
    ({ data }) => data
  ).catch(error=>{
    toast.error(error?.response?.data?.message)
});

export const getDepartmentTasksReport = (department, params) =>
  myAxios(`system-reports/users/tasks/${department}`, { params }).then(
    ({ data }) => data
  ).catch(error=>{
    toast.error(error?.response?.data?.message)
});

export const getManagersTasksReport = (params) =>
  myAxios(`system-reports/users/office-managers/plans`, { params }).then(
    ({ data }) => data
  ).catch(error=>{
    toast.error(error?.response?.data?.message)
});

export const getManagersGoalsReport = (id) =>
  myAxios(`system-reports/users/office-managers/goals/`).then(
    ({ data }) => data
  ).catch(error=>{
    toast.error(error?.response?.data?.message)
});

export const getManagersKPIsReport = () =>
  myAxios(`system-reports/users/office-managers/kbi/`).then(({ data }) => {
    const transformedData = Object.keys(data).map((key) => ({
      label: key,
      value: data[key],
    }));
    return transformedData;
  }).catch(error=>{
    toast.error(error?.response?.data?.message)
});

export const getUserKPIsReportById = (id) =>
  myAxios(`system-reports/users/${id}/kbi`).then(({ data }) => {
    const transformedData = Object.keys(data).map((key) => ({
      label: key,
      value: data[key],
    }));
    return transformedData;
  }).catch(error=>{
    toast.error(error?.response?.data?.message)
});

export const getDepartmentKPIsReport = (department) =>
  myAxios(`system-reports/users/department/kbi/${department}`).then(
    ({ data }) => {
      const transformedData = Object.keys(data).map((key) => ({
        label: key,
        value: data[key],
      }));
      return transformedData;
    }
  ).catch(error=>{
    toast.error(error?.response?.data?.message)
});

export const getUserStatisticsReports = () =>
  myAxios(`system-reports/users/stats`).then(({ data }) => {
    const transformedData = [
      // {label: "ADMINISTRATIVE" , value: data?.ADMINISTRATIVE?.value},
      // {label: "ARCHITECTURE" , value: data?.ARCHITECTURE?.value},
      // {label: "CIVIL" , value: data?.CIVIL?.value},
      // {label: "ELECTRICAL" , value: data?.ELECTRICAL?.value},
      { label: "الموظفين", value: data?.EMPLOYEES?.value },
      // {label: "IT" , value: data?.IT?.value},
      // {label: "MECHANICAL" , value: data?.MECHANICAL?.value},
      { label: "مدير مكتب", value: data["OFFICE MANAGERS"]?.value },
      // {label: "PROGRAMMING" , value: data?.PROGRAMMING?.value},
    ];

    return { ...data, transformedData };
  }).catch(error=>{
    toast.error(error?.response?.data?.message)
});
export const getUserStatisticsReportsEgy = () =>
  myAxios(`system-reports/users/stats/egy`).then(({ data }) => {
    const transformedData = [
      // {label: "ADMINISTRATIVE" , value: data?.ADMINISTRATIVE?.value},
      // {label: "ARCHITECTURE" , value: data?.ARCHITECTURE?.value},
      // {label: "CIVIL" , value: data?.CIVIL?.value},
      // {label: "ELECTRICAL" , value: data?.ELECTRICAL?.value},
      { label: "الموظفين", value: data?.EMPLOYEES?.value },
      // {label: "IT" , value: data?.IT?.value},
      // {label: "MECHANICAL" , value: data?.MECHANICAL?.value},
      { label: "مدير مكتب", value: data["OFFICE MANAGERS"]?.value },
      // {label: "PROGRAMMING" , value: data?.PROGRAMMING?.value},
    ];

    return { ...data, transformedData };
  }).catch(error=>{
    toast.error(error?.response?.data?.message)
});
export const getUserStatisticsReportsSaudi = () =>
  myAxios(`system-reports/users/stats/ksa`).then(({ data }) => {
    const transformedData = [
      // {label: "ADMINISTRATIVE" , value: data?.ADMINISTRATIVE?.value},
      // {label: "ARCHITECTURE" , value: data?.ARCHITECTURE?.value},
      // {label: "CIVIL" , value: data?.CIVIL?.value},
      // {label: "ELECTRICAL" , value: data?.ELECTRICAL?.value},
      { label: "الموظفين", value: data?.EMPLOYEES?.value },
      // {label: "IT" , value: data?.IT?.value},
      // {label: "MECHANICAL" , value: data?.MECHANICAL?.value},
      { label: "مدير مكتب", value: data["OFFICE MANAGERS"]?.value },
      // {label: "PROGRAMMING" , value: data?.PROGRAMMING?.value},
    ];

    return { ...data, transformedData };
  }).catch(error=>{
    toast.error(error?.response?.data?.message)
});

export const getUserStatisticsDepartmentsReports = (params) =>
  myAxios(`system-reports/users/department/all`, { params }).then(
    ({ data }) => {
      // console.log("hola", data);
      const transformedData = [
        { label: "إدارى", value: data?.ADMINISTRATIVE },
        { label: "معمارى", value: data?.ARCHITECTURE },
        { label: "مدنى", value: data?.CIVIL },
        { label: "كهربا", value: data?.ELECTRICAL },
        { label: "موارد بشرية", value: data?.HR },
        { label: "IT", value: data?.IT },
        { label: "ميكانيكا", value: data?.MECHANICAL },
        { label: "برمجة", value: data?.PROGRAMMING },
      ];

      return { ...data, transformedData };
    }
  ).catch(error=>{
    toast.error(error?.response?.data?.message)
});
export const getUserStatisticsDepartmentsReportsEgy = (params) =>
  myAxios(`system-reports/users/department/egy`, { params }).then(
    ({ data }) => {
      // console.log("hola", data);
      const transformedData = [
        { label: "إدارى", value: data?.ADMINISTRATIVE },
        { label: "معمارى", value: data?.ARCHITECTURE },
        { label: "مدنى", value: data?.CIVIL },
        { label: "كهربا", value: data?.ELECTRICAL },
        { label: "موارد بشرية", value: data?.HR },
        { label: "IT", value: data?.IT },
        { label: "ميكانيكا", value: data?.MECHANICAL },
        { label: "برمجة", value: data?.PROGRAMMING },
      ];

      return { ...data, transformedData };
    }
  ).catch(error=>{
    toast.error(error?.response?.data?.message)
});
export const getUserStatisticsDepartmentsReportsSaudi = (params) =>
  myAxios(`system-reports/users/department/ksa`, { params }).then(
    ({ data }) => {
      // console.log("hola", data);
      const transformedData = [
        { label: "إدارى", value: data?.ADMINISTRATIVE },
        { label: "معمارى", value: data?.ARCHITECTURE },
        { label: "مدنى", value: data?.CIVIL },
        { label: "كهربا", value: data?.ELECTRICAL },
        { label: "موارد بشرية", value: data?.HR },
        { label: "IT", value: data?.IT },
        { label: "ميكانيكا", value: data?.MECHANICAL },
        { label: "برمجة", value: data?.PROGRAMMING },
      ];

      return { ...data, transformedData };
    }
  ).catch(error=>{
    toast.error(error?.response?.data?.message)
});

export const getStatistics = () => myAxios(`/user/statistics/roles/country`).catch(error=>{
  toast.error(error?.response?.data?.message)
});
export const getDepartmentStatistics = () =>
  myAxios(`/user/statistics/department`).catch(error=>{
    toast.error(error?.response?.data?.message)
});

export const deleteUserByID = (id) => myAxios.delete(`/user/${id}`);

export const upgradePassword = (id, data) =>
  myAxiosJson.patch(`/user/updatePassword/${id}`, data);

export const updateProfilePic = (data) =>
  myAxiosInstance.patch(`/user/profile`, data);

export const getUserByCountry = (country, role, department) => {
  // console.log(country, role, department);

  if (role === "user" && department) {
    // Special case where role is "user" and department is an ID
    let url = `/user/?country=${country}`;
    return myAxios(url).catch(error=>{
      toast.error(error?.response?.data?.message)
  });
  }

  let url = `/user?country=${country}`;

  if (role && role !== "user") {
    url += `&role=${role}`;
  }

  if (department && department !== "user") {
    url += `&department=${department}`;
  }

  return myAxios(url).catch(error=>{
    toast.error(error?.response?.data?.message)
});
};

export const updateUser = (id, data) =>
  myAxiosInstance.patch(`user/${id}`, data);

export const updateMyProfile = (data) =>
  myAxiosInstance.patch(`user/update/me`, data);
export const getUserProfile = () =>
  myAxios("/user/profile").catch((error) =>
    toast.error(error?.response?.data?.message)
  );
