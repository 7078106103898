import { useState } from "react";
import { projectTypeEnum } from "../../../../../helper/utils";
import { SearchComponent } from "../../../../../Components/SearchComponent/SearchComponent";
import CustomTable from "../../../../../Components/Table";
import { TableRow } from "../../../../../Components/Table/TableRow";
import { TableCell } from "../../../../../Components/Table/TableCell";
import moment from "moment";
import DownloadButton from "../../../../../Components/Buttons/DownloadButton";
import { FilterIcon } from "../../../../../utiltis/Icons";
import { GrTransaction } from "react-icons/gr";
import { ImPrinter } from "react-icons/im";
import { TiExport } from "react-icons/ti";
import { Button, Modal, NavDropdown } from "react-bootstrap";
import useModal from "../../../../../hooks/useModal";
import { CustomDatePicker } from "../../../../../Components/FormUi";
import { InputLabel } from "../../../../System/PlanModel/components/InputLabel";
import { useForm } from "react-hook-form";
import { useReportContext } from "../../ReportContext";
import useExportExcel from "../../../../../hooks/useExportExcel";
import ExcellModall from "../../../../../Components/Modals/ExcellModall";
import CustomChart from "../../../../System/Main/components/charts/CustomChart";
import { BsThreeDotsVertical } from "react-icons/bs";
import { MdDeleteOutline } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { FaRegShareSquare } from "react-icons/fa";

import { useGetAllProjects, useGetAllProjectsReports, useGetProject } from "../../../../../hooks/fetchers/Projects";
import PrintProjectFile from "../../components/PrintProjectFile";
import { ProjectsTableColumns } from "../../consts";
import { CiImageOn } from "react-icons/ci";



const ReportProjectsPage = () => {
  const { projectType } = useReportContext();
  const { open, handleOpenModal, handleCloseModal } = useModal();
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");

  const [range, setRange] = useState({});
  // const { data: allClients } = useGetAllClients();
  const {
    data: dataRports,
    isLoading: isLoadingRports,
    isError: isErrorRports,
  } = useGetAllProjectsReports();

  const { data, isLoading, isError } = useGetAllProjects({
    page,
    range,
    projectType,
    search,
  });

  const { loading, allKeys, excelData, fileUrl, handleExport, resetExcelData } =
    useExportExcel();

  const handleChange = (event, value) => {
    setPage(value);
    // setFilterTasksParams({ page: value });
  };

  const handleSearch = (val) => {
    setSearch(val);
    console.log(`Search:`, val);
  };

  return (
    <div className="p-2 space-y-10">
      <div
        className={`bg-cardBg [box-shadow:_0px_4px_20px_0px_rgba(0,_0,_0,_0.25);] w-full py-0 px-1.5 rounded-[16.799px] item p-3 flex h-[250px] overflow-y-auto scrollbar-thin scrollbar-track-transparent scrollbar-thumb-[#EFAA20] `}
      >
        {!isLoadingRports && !isErrorRports ? (
          projectType === 1 ? (
            <CustomChart
              labelClass={"min-w-fit mt-2"}
              widthClass="w-full"
              data={[
                {
                  label: "فى انتظار الموافقة",
                  value: dataRports?.statistics?.DESIGN?.PENDING?.count,
                  percentage:
                    dataRports?.statistics?.DESIGN?.PENDING?.percentage,
                },
                {
                  label: "قيد التنفيذ",
                  value: dataRports?.statistics?.DESIGN?.IN_PROGRESS?.count,
                  percentage:
                    dataRports?.statistics?.DESIGN?.IN_PROGRESS?.percentage,
                },
                {
                  label: "مكتملة",
                  value: dataRports?.statistics?.DESIGN?.COMPLETED?.count,
                  percentage:
                    dataRports?.statistics?.DESIGN?.COMPLETED?.percentage,
                }
              ]}
              chartTitle="احصائيات مشاريع التصميم"
              title="عدد المشاريع"
              mainColTitle="الحالة"
            />
          ) : projectType === 2 ? (
            <CustomChart
              labelClass={"min-w-fit mt-2"}
              widthClass="w-full"
              data={[
                {
                  label: "فى انتظار الموافقة",
                  value: dataRports?.statistics?.SUPERVISING?.PENDING?.count,
                  percentage:
                    dataRports?.statistics?.SUPERVISING?.PENDING?.percentage,
                },
                {
                  label: "قيد التنفيذ",
                  value:
                    dataRports?.statistics?.SUPERVISING?.IN_PROGRESS?.count,
                  percentage:
                    dataRports?.statistics?.SUPERVISING?.IN_PROGRESS
                      ?.percentage,
                },
                {
                  label: "مكتملة",
                  value: dataRports?.statistics?.SUPERVISING?.COMPLETED?.count,
                  percentage:
                    dataRports?.statistics?.SUPERVISING?.COMPLETED?.percentage,
                }
              ]}
              chartTitle="احصائيات مشاريع الاشراف"
              title="عدد المشاريع "
              mainColTitle="الحالة"
            />
          ) : projectType === null ? (
            <CustomChart
              labelClass={"min-w-fit mt-2"}
              widthClass="w-full"
              data={[
                {
                  label: "فى انتظار الموافقة",
                  value: dataRports?.totalStatusCounts?.PENDING,
                  percentage: dataRports?.totalStatusPercentages?.PENDING,
                },
                {
                  label: "قيد التنفيذ",
                  value: dataRports?.totalStatusCounts?.IN_PROGRESS,
                  percentage: dataRports?.totalStatusPercentages?.IN_PROGRESS,
                },
                {
                  label: "مكتملة",
                  value: dataRports?.totalStatusCounts?.COMPLETED,
                  percentage: dataRports?.totalStatusPercentages?.COMPLETED,
                }
              ]}
              chartTitle="احصائيات المشاريع"
              title="عدد المشاريع"
              mainColTitle="الحالة"
            />
          ) : null
        ) : null}
      </div>
      <FilterModal
        show={open}
        onHide={handleCloseModal}
        onSave={(data) => {
          setRange(`${data.startDate},${data.endDate}`);
          console.log(`${data.startDate}, ${data.endDate}`);
        }}
      />

      {!loading && excelData && (
        <ExcellModall
          onHide={resetExcelData}
          fileUrl={fileUrl}
          keys={allKeys}
          data={excelData}
        />
      )}

      <fieldset className="TableContainer  py-3 px-2 mx-auto mt-3">
        <legend className="text-center text-mainText">كل المشاريع</legend>
        <div className="">
          <div className="grid grid-cols-12 gap-4 ">
            <div className="col-span-7">
              <SearchComponent
                handleChange={handleSearch}
                value={search}
                className={""}
                border="border !border-[#E4A11B]"
              />
            </div>
            <div className="col-span-5 flex justify-end items-end gap-4">
              <DownloadButton onClick={() => handleExport("projects/export/excel")}>
                <div className="flex items-center gap-1">
                  <span>تصدير Excel</span>
                  <TiExport />
                </div>
              </DownloadButton>

              <PrintAll data={data?.data?.projects} isLoading={isLoading} isError={isError} />

              <NavDropdown
                title={<FilterIcon />}
                className="fs-5"
                id="collapsible-nav-dropdown"
              >
                <NavDropdown.Item
                  onClick={handleOpenModal}
                  className="flex items-center gap-1 !bg-white !text-black overflow-hidden rounded-lg"
                >
                  <GrTransaction color="black" />
                  <span className="!text-black">من...الي</span>
                </NavDropdown.Item>
              </NavDropdown>
            </div>
          </div>
        </div>

        <div className="mt-3 py-1 !h-[450px] overflow-y-auto scrollbar-thin scrollbar-track-mainNavy scrollbar-thumb-[#E4A11B]">
          {isError ? (
            <p className="text-red-500 text-center text-lg">يوجد خطأ ما</p>
          ) : null}
          {!isLoading && !isError && data?.data?.projects ? (
            <ProjectsTable
              data={data?.data?.projects}
              handleChange={handleChange}
              page={page}
              handleExport={handleExport}
            />
          ) : null}
        </div>
      </fieldset>
    </div>
  );
};

const FilterModal = ({ show, onHide, onSave }) => {
  const { handleSubmit, control } = useForm();
  const manipulateData = (data) => {
    data.startDate = moment(data.startDate).format("").split("T")[0];
    data.endDate = moment(data.endDate).format("").split("T")[0];
    return data;
  };
  const onSubmit = (data) => {
    data = manipulateData(data);
    onSave(data);
    onHide();
  };
  return (
    <Modal
      show={show}
      onHide={onHide}
      className="systemModal bg-black/50 "
      contentClassName="border !border-[#EFAA20] rounded-3xl p-0"
      centered
    >
      <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header className="w-full !justify-between border-none">
          <Modal.Title className="text-mainText">{"تصنيف"}</Modal.Title>
          <Button
            className="p-0 bg-transparent hover:bg-transparent"
            onClick={onHide}
          >
            <img src="/Rejected.svg" alt="" />
          </Button>
        </Modal.Header>
        <Modal.Body className="w-full space-y-2">
          <div className="flex flex-col gap-2">
            <InputLabel label={"من تاريخ"} />
            <CustomDatePicker
              control={control}
              name="startDate"
              rules={{
                required: "من فضلك ادخل تاريخ البداية ",
              }}
            />
          </div>
          <div className="flex flex-col gap-2">
            <InputLabel label={"الي تاريخ"} />
            <CustomDatePicker
              control={control}
              name="endDate"
              rules={{
                required: "من فضلك ادخل تاريخ النهاية ",
              }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer className="w-full justify-center border-none">
          <Button type="submit" className="!bg-[#EFAA20]">
            {"حفظ"}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

const PrintAll = ({ data, isLoading, isError }) => {
  const {
    open: openPdf,
    handleOpenModal: handleOpenPdfModal,
    handleCloseModal: handleClosePdfModal,
  } = useModal();
  return (
    <>
      {/* {isLoading ? <LoadingPage /> : null} */}
      {!isLoading && !isError && data ? (
        <PrintProjectFile
          show={openPdf}
          onHide={handleClosePdfModal}
          data={data}
        />
      ) : null}
      <DownloadButton onClick={handleOpenPdfModal}>
        <div className="flex items-center gap-1">
          <span>طباعة</span>
          <ImPrinter />
        </div>
      </DownloadButton>
    </>
  );
};

const ProjectsTable = ({ data, page, handleChange, handleExport }) => {
  const navigate = useNavigate();
  const {
    open: openPdf,
    handleOpenModal: handleOpenPdfModal,
    handleCloseModal: handleClosePdfModal,
  } = useModal();
  const [projectId, setProjectId] = useState(null);
  const { data: projectData } = useGetProject(projectId);

  return (
    <>
      {projectData?.data?.project && (
        <PrintProjectFile
          show={openPdf}
          onHide={handleClosePdfModal}
          data={[projectData?.data?.project]}
        />
      )}
      <CustomTable
        columns={ProjectsTableColumns}
        data={data?.data?.projects}
        paginationProps={{
          count: data.pages,
          page: page,
          onChange: handleChange,
        }}
        responsive={false}
      >
        {data && data.length > 0
          ? data?.map(
              (
                item,
                index
              ) => (
                <TableRow
                  className={`my-2 border !border-[#efaa207f] ${
                    index % 2 === 0 ? "bg-mainNavy" : ""
                  }`}
                  key={item?._id}
                >
                  <TableCell>{(page - 1) * 10 + (index + 1)}</TableCell>
                  <TableCell>
                                {item?.requestId?.projectName || "لا توجد بيانات"}
                              </TableCell>
                              <TableCell>{item?.orderNumber || "لا توجد بيانات"}</TableCell>
                              <TableCell>
                                {item?.requestId?.clientType === 1
                                  ? "حكومي او مستثمر"
                                  : item?.requestId?.clientType === 2
                                  ? "شركة او مؤسسة"
                                  : "تجاري"}
                              </TableCell>
                              <TableCell>
                                {moment(item?.createdAt).format("YYYY-MM-DD")}
                              </TableCell>
                              <TableCell>
                                {projectTypeEnum[+item?.projectType]}
                              </TableCell>
                              <TableCell>
                                {item?.batchStatus === "1"
                                  ? "تم الدفع"
                                  : "لم يتم الدفع"}
                              </TableCell>
                  <TableCell>
                    <NavDropdown
                      title={<BsThreeDotsVertical className="text-[#efaa20]" />}
                      className="fs-5 w-fit"
                      id="collapsible-nav-dropdown"
                    >
                      <NavDropdown.Item
                        onClick={() => {
                          navigate(`/System/Projects/project/${item?._id}`);
                        }}
                        className="text-end d-flex gap-2  align-items-center hover:rounded-lg hover:text-mainNavy"
                      >
                        <CiImageOn className="text-black" />
                        <span className="text-black">عرض</span>
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        onClick={() => handleExport(`projects/export/excel/${item?._id}`)}
                        className="text-end  d-flex gap-2  align-items-center hover:rounded-lg"
                      >
                        <FaRegShareSquare className="text-black" />
                        <span className="text-black">تصدير</span>
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        onClick={() => {
                          setProjectId(item?._id);

                          handleOpenPdfModal();
                        }}
                        className="text-end  d-flex gap-2  align-items-center hover:rounded-lg"
                      >
                        <MdDeleteOutline className="text-black" />
                        <span className="text-black">طباعة</span>
                      </NavDropdown.Item>
                    </NavDropdown>
                  </TableCell>
                </TableRow>
              )
            )
          : null}
      </CustomTable>
    </>
  );
};

export default ReportProjectsPage;
