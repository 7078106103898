import { IoIosAdd } from "react-icons/io";

export const AddNewButton = ({ ...props }) => {
  return (
    <button
      className="flex flex-col justify-center items-center gap-1 w-full bg-mainNavy p-2 border !border-[#EFAA20] !border-dashed rounded-xl"
      {...props}
    >
      <IoIosAdd fontSize={35} color="#EFAA20" />
      <p className=" text-mainText">أضافة جديدة</p>
    </button>
  );
};
