import React from "react";
import useSuccessfullModal from "../../../Modals/hooks/useSuccessfullModal";
import { useDeleteMeeting } from "../../../../hooks/fetchers/Meetings";
import useModal from "../../../../hooks/useModal";
import CustomModal from "../../../Modals/CustomModal";
import DeleteButton from "../../../DeleteButton";
import SuccessfullModal from "../../../Modals/SuccessfullModal";

const DeleteEvent = ({ id, setShowEditDeleteModal }) => {
  const {
    handleShowSuccess,
    handleShowError,
    handleCloseSuccess,
    handleCloseError,
    showSuccess,
    showError,
  } = useSuccessfullModal();
  const { mutate } = useDeleteMeeting(id, handleShowSuccess, handleShowError);
  const { open, handleOpenModal, handleCloseModal } = useModal();
  return (
    <>
      <DeleteButton text={"حذف"} onClick={handleOpenModal} customPosition={true} />
      <CustomModal
        show={open}
        title={"التاكيد"}
        handleClose={handleCloseModal}
        handleSave={mutate}
        message={"هل انت متاكد من انك تريد رفض الاجتماع"}
      />
      <SuccessfullModal
        message={"تم حذف الاجتماع بنجاح"}
        show={showSuccess}
        handleClose={() => {
          handleCloseSuccess();
          setShowEditDeleteModal(false);
        }}
      />
      <SuccessfullModal
        message={"حدث خطأ ما"}
        show={showError}
        handleClose={handleCloseError}
        status="error"
      />
    </>
  );
};

export default DeleteEvent;
