import { useQuery, useMutation, useQueryClient } from "react-query";
import {
  getAllVacationCategories,
  addVacationCategory,
  updateVacationCategory,
  deleteVacationCategory,
  getAllVacationCategoriesHoliday,
  getVacationCategory,
} from "../../helper/fetchers/VacationCategory";

export const useGetAllVacationCategories = (params) => {
  return useQuery(["vacation-category",params],()=> getAllVacationCategories(params));  
};
export const useGetVacationCategory = (id) => {
  return useQuery(["vacation-category",id],()=> getVacationCategory(id),{
    enabled: !!id
  });  
};
export const useGetAllVacationCategoriesHoliday = () => {
  return useQuery("vacation-category-select", getAllVacationCategoriesHoliday);  
};

export const useAddVacationCategory = (onSuccess,onError) => {
  const queryClient = useQueryClient();
  return useMutation(addVacationCategory, {
    onSuccess: () => {
      queryClient.invalidateQueries("vacation-category");
      onSuccess();
    },
    onError: (error) => {
      onError()
    },
  });
};
export const useUpdateVacationCategory = (id,onSuccess,onError) => {
  const queryClient = useQueryClient();
  return useMutation((data) => updateVacationCategory(id, data), {
    onSuccess: () => {
      queryClient.invalidateQueries("vacation-category");
      onSuccess();
    },
    onError: (error) => {
      onError()
    },
  });
};
export const useDeleteVacationCategory = (onSuccess,onError) => {
  const queryClient = useQueryClient();
  return useMutation(deleteVacationCategory, {
    onSuccess: () => {
      queryClient.invalidateQueries("vacation-category");
      onSuccess()
    },
    onError: (error) => {
      onError()
    },
  });
};
