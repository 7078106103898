import React, { useEffect, useState } from "react";
import LoadingPage from "../../../feedback/LoadingPage";
import CustomModal from "../../../Modals/CustomModal";
import { useDeleteRequest } from "../../../../hooks/fetchers/Requests";
import useSuccessfullModal from "../../../Modals/hooks/useSuccessfullModal";
import useModal from "../hooks/useModal";
import SuccessfullModal from "../../../Modals/SuccessfullModal";
import { useNavigate } from "react-router-dom";
import Image from "../../../Image";

const DeleteRequest = ({ data, path = "", setShowProject }) => {
  const navigate = useNavigate();
  const [message, setMessage] = useState("حدث خطأ ما");
  const {
    handleCloseError,
    handleCloseSuccess,
    handleShowError,
    handleShowSuccess,
    showError,
    showSuccess,
  } = useSuccessfullModal();
  const { mutate, isLoading, isError, error } = useDeleteRequest(
    handleShowSuccess,
    handleShowError
  );
  const { show, handleOpenModal, handleCloseModal } = useModal();
  useEffect(() => {
    setMessage(error?.response?.data?.message);
  }, [isError, error]);
  return (
    <>
      {isLoading ? <LoadingPage /> : null}
      <Image
        className="pointer delete-icon"
        onClick={handleOpenModal}
        src={process.env.PUBLIC_URL + "/icons/deleteRequest.png"}
      />

      <CustomModal
        show={show}
        title={"التاكيد"}
        handleClose={handleCloseModal}
        handleSave={() => {
          mutate(data);
          handleCloseModal();
        }}
        message={"هل انت متاكد من انك تريد حذف الطلب"}
      />
      <SuccessfullModal
        show={showSuccess}
        message={"تم حذف الطلب بنجاح"}
        handleClose={() => {
          navigate(path);
          handleCloseSuccess();
          setShowProject(false);
        }}
      />
      <SuccessfullModal
        show={showError}
        message={message}
        handleClose={handleCloseError}
        status="error"
      />
    </>
  );
};

export default DeleteRequest;
