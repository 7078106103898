import React, { useState } from "react";
import "./index.css";
import SystemControler from "../../../../Components/System/SystemControler/SystemControler";
import { Outlet } from "react-router-dom";
import AllUserCategories from "../../../../Components/System/Users/AllUserCategories/AllUserCategories";
import BackButton from "../../../../Components/BackButton";
import { IoIosArrowDown } from "react-icons/io";
import AllUserDropdown from "./AllUserDropdown";

const AllUsersChart = () => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <SystemControler
        child={
          <div className="flex gap-2 relative">
            <BackButton />
            <button
              className="px-8 py-2 rounded-xl bg-mainNavy text-mainText flex gap-2 items-center justify-between xl:hidden"
              onClick={() => setOpen(!open)}
            >
              كل المستخدمين
              <IoIosArrowDown className="text-mainText text-xl" />
            </button>
            {open && (
              // <div className="">
              <AllUserDropdown setOpen={setOpen} />
              // </div>
            )}
          </div>
        }
      />
      <div className="grid grid-cols-12 gap-2 h-full">
        <div className="col-span-3 hidden xl:flex bg-mainItems">
          <AllUserCategories />
        </div>
        <div className="col-span-12 xl:col-span-9">
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default AllUsersChart;
