import React from "react";
import ReactApexChart from "react-apexcharts";

const DashboardPieChart = ({ labels, label, colors, series, width }) => {
  return (
    <ReactApexChart
      options={{
        chart: {
          width: "100%",
          height: "100%",
          type: "donut",
        },
        labels: labels,
        toolbar: {
          show: true,
          offsetX: -40,
          offsetY: -50,
          tools: {
            download: true,
            selection: false,
            zoom: false,
            zoomin: false,
            zoomout: false,
            pan: false,
            reset: false,
          },
          autoSelected: "download",
        },
        plotOptions: {
          pie: {
            donut: {
              size: "75%",
              labels: {
                show: true,
                total: {
                  show: true,
                  label: label,
                  color: "var(--main-text)",
                  formatter: function (w) {
                    return w.config.series.reduce((a, b) => a + b, 0);
                  },
                },
              },
            },
          },
        },
        colors: colors,
        stroke: {
          show: false,
        },
        dataLabels: {
          enabled: false,
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                show: false,
              },
            },
          },
        ],
        legend: {
          show: false,
        },
      }}
      series={series}
      type="donut"
      width={width ? width : 200}
    />
  );
};

export default DashboardPieChart;
