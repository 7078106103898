import React from "react";
import style from "../showExpensesDetials/ShowExpensesDetials.module.css";
import { Button, Form, Modal, NavDropdown } from "react-bootstrap";
import SaveButton from "../../../SaveButton";
import { useState } from "react";
import DeleteModal from "../../../../Pages/System/Settings/RemoveModal";
import DownloadButton from "../../../Buttons/DownloadButton";
import { convertDateFormat } from "../../../../helper/utils";
import { FaFileDownload } from "react-icons/fa";
import { PdfIcon } from "../../../../utiltis/Icons";
import useSuccessfullModal from "../../../Modals/hooks/useSuccessfullModal";
import SuccessfullModal from "../../../Modals/SuccessfullModal";
import {
  AcceptReportWithId,
  rejectReportWithId,
  useDeleteExpensesItemReport,
  useGetExpensesItemReport,
} from "../../../../hooks/fetchers/Expenses-items-reports";
import EditExpensesReport from "../EditExpensesReport/EditExpensesReport";
import CustomModal from "../../../Modals/CustomModal";
import RejectModel from "../../../Modals/RejectModel";
import Image from "../../../Image";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FiEdit } from "react-icons/fi";
import { MdDeleteOutline } from "react-icons/md";
import { toast } from "react-toastify";
import { useQueryClient } from "react-query";
import axios from "axios";
import Cookies from "js-cookie"
import * as XLSX from "xlsx";
import config from "../../../../Config/Config";
const ShowExpensesReportDetails = ({ setOpenDisplayDetails, id }) => {
  const {
    handleShowSuccess,
    handleShowError,
    handleCloseSuccess,
    handleCloseError,
    showSuccess,
    showError,
  } = useSuccessfullModal();

  const { data } = useGetExpensesItemReport(id);
  const { mutate: deleteMutation } = useDeleteExpensesItemReport(
    handleShowSuccess,
    handleShowError
  );
  const [deletePoper, setDeletPoper] = useState(false);
  const [editExpenses, setEditExpenses] = useState(false);
  const handleDeleteRequest = () => {
    // after deleting revenues succefully
    setDeletPoper(false);
  };
  // const [loading, setLoading] = useState(false);

  const [openSuccessReject, setOpenSuccessReject] = useState(false);
  const [refuseRequest, setRefuseRequest] = useState(false);
  const [acceptRequest, setAcceptRequest] = useState(false);
  const [message, setMessage] = useState("");
  const [confirmRefuseRequest, setConfirmRefuseRequest] = useState(false);

  // const [comment, setComment] = useState("");
  const handleLeaveComment = async (rejectedData) => {
    const formData = new FormData();
    formData.append("reasonRejected", rejectedData["reasonRejected"]);
    for (let item of rejectedData["rejectAttachments"]) {
      formData.append("rejectAttachments", item);
    }
    // setLoading(true);
    try {
      const data = await rejectReportWithId(id, formData);
      queryClient.invalidateQueries("expenses");
      setRefuseRequest(false);
      setMessage("تم رفض الطلب بنجاح");
      setOpenSuccessReject(true);
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
    // setLoading(false);
  };
  // const [acceptLoading, setAcceptLoading] = useState(false);
  const queryClient = useQueryClient();
  const [openSuccessAccept, setOpenSuccessAccept] = useState(false);

  const handleAcceptRequest = async () => {
    // setAcceptLoading(true);
    try {
      const { data } = await AcceptReportWithId(id);
      queryClient.invalidateQueries("expenses");
      setOpenSuccessAccept(true);

      setMessage("تم قبول الطلب بنجاح");
      setAcceptRequest(false);
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
    // setAcceptLoading(false);
  };
  const handleRefuse = () => {
    setRefuseRequest(false);
    setConfirmRefuseRequest(true);
  };





  const [excelData, setExcelData] = useState();
  const [fileUrl, setFileUrl] = useState("");
  const [loading, setLoading] = useState(false);

  const [allKeys, setAllKeys] = useState([]);


  const handleExport = async (id) => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${config.apiGateway.URL}/expenses/excel/${id}`,
        {
          responseType: "arraybuffer",
          headers: {
            "Content-Type": "application/excel",
            authes: `BSA__${Cookies.get("accessToken")}`,
          },
        }
      );

      if (response.status === 200) {
        const data = new Uint8Array(response.data);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet);
        const keysSet = new Set();
        jsonData.forEach((row) => {
          Object.keys(row).forEach((key) => keysSet.add(key));
        });
        console.log("jsonData",jsonData);
        setAllKeys(Array.from(keysSet));

        setExcelData(jsonData);

        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = URL.createObjectURL(blob);
        setFileUrl(url);
        setLoading(false);
      } else {
        setLoading(false);

        console.error("Failed to fetch Excel:", response.statusText);
      }
    } catch (error) {
      setLoading(false);

      console.error("Error fetching Excel:", error);
    }
  };








  return (
    <div className="ShowExpensesDetials">



{excelData && (
          <Modal
            show={true}
            onHide={() => setExcelData("")}
            className="h-screen bg-black/50 "
            contentClassName="bg-transparent"
            centered
          >
            <Modal.Body className="h-full w-full min-w-min rounded-lg bg-mainItems">
              <a
                href={fileUrl}
                download={"expenses.xlsx"}
                className="mb-3 mx-auto block text-center !text-[#efaa20] hover:!text-[#efaa20] border !border-[#efaa20] w-fit px-4 py-1 rounded-full bg-mainNavy"
              >
                تحميل المستند
              </a>
              <div className="bg-mainNavy shadow-lg p-4 rounded-lg">
                <table className="">
                  <thead>
                    <tr className="min-w-[100px]">
                      {allKeys.map((key) => (
                        <th
                          className="min-w-[8vw] text-sm text-mainText"
                          key={key}
                        >
                          {key}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {excelData.map((row, index) => (
                      <tr key={index}>
                        {allKeys.map((key) => (
                          <td
                            className=" text-xs text-mainCustomGray py-1"
                            key={key}
                          >
                            {row[key] || ""}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </Modal.Body>
          </Modal>
        )}






      <DeleteModal
        title={"التاكيد"}
        show={deletePoper}
        handleClose={handleDeleteRequest}
        onSave={() => {
          // handleDelete();
          deleteMutation(data._id);
        }}
        stillOpen={true}
      />
      <SuccessfullModal
        show={openSuccessAccept}
        message={message}
        handleClose={() => {
          setOpenSuccessAccept(false);
          setConfirmRefuseRequest(false);
          setOpenDisplayDetails(false);
        }}
      />
      <SuccessfullModal
        show={openSuccessReject}
        message={message}
        handleClose={() => {
          setOpenSuccessReject(false);
          setConfirmRefuseRequest(false);
          setOpenDisplayDetails(false);
        }}
      />
      <CustomModal
        show={acceptRequest}
        title={"التاكيد"}
        handleClose={() => {
          setAcceptRequest(false);
        }}
        handleSave={() => {
          handleAcceptRequest();
        }}
        message={"هل انت متاكد من قبول التقرير"}
      />
      <CustomModal
        show={refuseRequest}
        title={"التاكيد"}
        handleClose={() => {
          setRefuseRequest(false);
        }}
        handleSave={handleRefuse}
        message={"هل انت متاكد من انك تريد رفض الطلب"}
      />
      <RejectModel
        show={confirmRefuseRequest}
        message={"اترك سبب الرفض"}
        handleClose={() => {
          setConfirmRefuseRequest(false);
        }}
        // setComment={setComment}
        name="reasonRejected"
        fileName="rejectAttachments"
        // handleSave={(val)=>{
        //   console.log(val);
        // }}
        handleSave={handleLeaveComment}
      />

      <SuccessfullModal
        handleClose={() => {
          handleCloseSuccess();
          setOpenDisplayDetails(false);
          setDeletPoper(false);
        }}
        message={"تم الحذف بنجاح"}
        show={showSuccess}
      />
      <SuccessfullModal
        handleClose={handleCloseError}
        message={"حدث خطأ ما"}
        show={showError}
        status="error"
      />

      {editExpenses && (
        <EditExpensesReport
          editExpenses={editExpenses}
          setEditExpenses={setEditExpenses}
          selectedItem={data}
        />
      )}

      <div className={`${style.reportName} p-3`}>
        <div className="row">
          <div className="col-md-12 mb-2">
          <div className="flex justify-between items-center">
          <div >
            <p className="text-mainText">
              اسم التقرير :
              <span className="text-mainCustomGray"> {data?.name}</span>
            </p>
          </div>

          <div >
            <div className="flex gap-3 justify-center">
              {/* <DownloadButton>تصدير CSV </DownloadButton> */}
              <DownloadButton onClick={() => handleExport(data?._id)}>تصدير Excel </DownloadButton>
            </div>
          </div>
          </div>

          </div>

          <div className="col-md-12">
            <div className="flex justify-end items-center">

            {data?.status === 0 ? (
              <div className="d-flex gap-3 items-center ">
                <Image
                  className="pointer confirm"
                  onClick={() => {
                    setAcceptRequest(true);
                  }}
                  src={process.env.PUBLIC_URL + "/icons/confirm.png"}
                />
                <Image
                  className="pointer declince"
                  onClick={() => {
                    setRefuseRequest(true);
                  }}
                  src={process.env.PUBLIC_URL + "/icons/declince.png"}
                />
                <NavDropdown
                  title={<BsThreeDotsVertical className="text-mainText" />}
                  className="fs-5 "
                  id="collapsible-nav-dropdown"
                >
                  <NavDropdown.Item
                    onClick={() => {
                      setEditExpenses(true);
                    }}
                    className="text-end d-flex gap-2  align-items-center hover:rounded-lg hover:text-mainNavy"
                  >
                    <FiEdit className="text-black" />

                    <span className="text-black">تعديل</span>
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    onClick={() => {
                      setDeletPoper(true);
                    }}
                    className="text-end  d-flex gap-2  align-items-center hover:rounded-lg"
                  >
                    <MdDeleteOutline className="text-black" />
                    <span className="text-black">حذف</span>
                  </NavDropdown.Item>
                </NavDropdown>
              </div>
            ) : data?.status === 4 ? (
              <div className="d-flex gap-2  mt-3 justify-content-start   ">
                <img
                  className={`pointer ${style.actionIcon}`}
                  onClick={() => {
                    setDeletPoper(true);
                  }}
                  src={process.env.PUBLIC_URL + "/icons/delete.png"}
                  alt=""
                />
              </div>
            ) : data?.status === 5 ? (
              <div className="d-flex gap-3 items-center ">
                <Image
                  className="pointer confirm"
                  onClick={() => {
                    setAcceptRequest(true);
                  }}
                  src={process.env.PUBLIC_URL + "/icons/confirm.png"}
                />

                <NavDropdown
                  title={<BsThreeDotsVertical className="text-mainText" />}
                  className="fs-5 "
                  id="collapsible-nav-dropdown"
                >
                  <NavDropdown.Item
                    onClick={() => {
                      setEditExpenses(true);
                    }}
                    className="text-end d-flex gap-2  align-items-center hover:rounded-lg hover:text-mainNavy"
                  >
                    <FiEdit className="text-black" />

                    <span className="text-black">تعديل</span>
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    onClick={() => {
                      setDeletPoper(true);
                    }}
                    className="text-end  d-flex gap-2  align-items-center hover:rounded-lg"
                  >
                    <MdDeleteOutline className="text-black" />
                    <span className="text-black">حذف</span>
                  </NavDropdown.Item>
                </NavDropdown>
              </div>
            ) : null}
            </div>
            {/* <div className="d-flex gap-2  mt-3 justify-content-start   ">
              <img
                className={`pointer ${style.actionIcon}`}
                onClick={() => {
                  setDeletPoper(true);
                }}
                src={process.env.PUBLIC_URL + "/icons/delete.png"}
                alt=""
              />

              <img
                className={`pointer ${style.actionIcon}`}
                onClick={() => {
                  setEditExpenses(true);
                }}
                src={process.env.PUBLIC_URL + "/icons/edit.svg"}
                alt=""
              />
            </div> */}
          </div>
        </div>
      </div>
      <fieldset className={`${style.discription} w-90 mx-auto p-3`}>
        <legend className="text-center w-30 text-mainText">الوصف</legend>
        <div className="col-md-6 pb-2">
          <p className="text-mainText flex gap-1 items-center">
            البند : <span className="main-text">{data?.clauseId?.name}</span>
          </p>
        </div>
        <Form.Label className="text-mainText">الوصف : </Form.Label>
        <div className="h-36 bg-mainNavy  text-mainCustomGray p-2 rounded-[7px]">
          {" "}
          {data?.description}
        </div>
        <div className="row mt-2">
          <div className="col-md-6">
            <p className="text-mainText flex items-center gap-2">
              تاريخ الاستحقاق :
              <span className="main-text">
                {convertDateFormat(data?.dueDate)}
              </span>
            </p>
          </div>
          <div className="col-md-6">
            <p className="text-mainText flex gap-1 items-center">
              المبلغ : <span className="main-text">{data?.amount}</span>
            </p>
          </div>
        </div>
      </fieldset>
      <fieldset className={`border !border-[#EFAA2080]  w-90 mx-auto mt-3 p-3`}>
        <legend className="text-center w-30">الملف المرفق</legend>

        {/* <FIlePlaceholder
          title={ExpensesType === "ExpensesReports" ? "المصروفات" : "الصنف"}
        /> */}

        {data?.image?.length > 0 ? (
          <>
            {/* <div className="flex gap-3">
              <PreviewFiles data={data?.image} />
            </div> */}
            <div className="relative w-full flex gap-6 overflow-y-hidden overflow-x-auto scrollbar-thin scrollbar-track-transparent scrollbar-thumb-[#EFAA20] pb-14">
              <ShowFiles files={data?.image} />
            </div>
          </>
        ) : (
          <div className="flex justify-center gap-3">
            <p className="text-2xl text-[#D59921]"> لا يوجد ملفات</p>
          </div>
        )}
      </fieldset>

      <div
        onClick={() => {
          setOpenDisplayDetails(false);
        }}
        className=" mt-4 w-[30]"
      >
        <SaveButton />
      </div>
    </div>
  );
};
const ShowFiles = ({ files }) => {
  const { show, handleClose, handleShow } = useOpenModal();
  const [content, setContent] = useState(null);

  // console.log("files: ", files);
  return (
    <>
      <Example show={show} handleClose={handleClose} content={content} />
      {files?.map((file, index) => {
        const fileType = getType(file);
        switch (fileType) {
          case "jpg":
          case "jpeg":
          case "png":
          case "gif":
            return (
              <div
                key={index}
                onClick={() => {
                  setContent(
                    <>
                      <Button
                        className="bg-mainItems"
                        download={true}
                        href={file}
                      >
                        <FaFileDownload fontSize={25} color="#EFAA20" />
                      </Button>
                      <img
                        className="w-96  rounded-xl border"
                        src={file}
                        alt=""
                      />
                    </>
                  );
                  handleShow();
                }}
                className="shrink-0"
              >
                <img
                  className="w-24 h-24 rounded-xl border"
                  src={file}
                  alt=""
                />
              </div>
            );
          case "pdf":
            return (
              <div
                key={index}
                onClick={() => {
                  setContent(
                    <>
                      <Button
                        className="bg-mainItems"
                        download={true}
                        href={file}
                      >
                        <FaFileDownload fontSize={25} color="#EFAA20" />
                      </Button>
                      <embed
                        src={file}
                        type="application/pdf"
                        width="400"
                        height="600"
                        className="w-96 relative rounded-xl border"
                      />
                    </>
                  );
                  handleShow();
                }}
                className="shrink-0 flex justify-center items-center w-24 h-24 rounded-xl border"
              >
                <PdfIcon />
              </div>
            );
          case "zip":
            return (
              <div
                key={index}
                onClick={() => {
                  setContent(
                    <>
                      <Button
                        className="bg-mainItems"
                        download={true}
                        href={file}
                      >
                        <FaFileDownload fontSize={25} color="#EFAA20" />
                      </Button>
                      <img
                        className="w-96   rounded-xl border"
                        src="https://p7.hiclipart.com/preview/258/300/47/computer-icons-zip-truetype-icon-png-file-zip.jpg"
                        alt=""
                      />
                    </>
                  );
                  handleShow();
                }}
                className="shrink-0"
              >
                <img
                  className="w-24 h-24 rounded-xl border"
                  src="https://p7.hiclipart.com/preview/258/300/47/computer-icons-zip-truetype-icon-png-file-zip.jpg"
                  alt=""
                />
              </div>
            );
          case "xlsx":
            return (
              <div
                key={index}
                onClick={() => {
                  setContent(
                    <>
                      <Button
                        className="bg-mainItems"
                        download={true}
                        href={file}
                      >
                        <FaFileDownload fontSize={25} color="#EFAA20" />
                      </Button>
                      <img
                        className="w-96   rounded-xl border"
                        src="https://logodownload.org/wp-content/uploads/2020/04/excel-logo-0.png"
                        alt=""
                      />
                    </>
                  );
                  handleShow();
                }}
                className="shrink-0"
              >
                <img
                  className="w-24 h-24 rounded-xl border"
                  src="https://logodownload.org/wp-content/uploads/2020/04/excel-logo-0.png"
                  alt=""
                />
              </div>
            );
          case "rar":
            return (
              <div
                key={index}
                onClick={() => {
                  setContent(
                    <>
                      <Button
                        className="bg-mainItems"
                        download={true}
                        href={file}
                      >
                        <FaFileDownload fontSize={25} color="#EFAA20" />
                      </Button>
                      <img
                        className="w-96  rounded-xl border"
                        src="https://cdn-icons-png.freepik.com/512/9158/9158683.png"
                        alt=""
                      />
                    </>
                  );
                  handleShow();
                }}
                className="shrink-0"
              >
                <img
                  className="w-24 h-24 rounded-xl border"
                  src="https://cdn-icons-png.freepik.com/512/9158/9158683.png"
                  alt=""
                />
              </div>
            );
          default:
            return null;
        }
      })}
    </>
  );
};

function Example({ show, handleClose, content }) {
  return (
    <Modal
      size="lg"
      centered
      show={show}
      onHide={handleClose}
      contentClassName="p-0 bg-mainNavy w-fit mx-auto"
      className="bg-black/50"
    >
      <Modal.Body className="flex flex-col items-center">{content}</Modal.Body>
    </Modal>
  );
}

const getType = (fileName) => {
  const fileExtension = fileName.split(".").pop().toLowerCase();
  return fileExtension;
};

const useOpenModal = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return {
    show,
    handleClose,
    handleShow,
  };
};
export default ShowExpensesReportDetails;
