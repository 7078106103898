import { useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";

const ReminderCalendar = ({ data, onClickDay, setIsActiveDay }) => {
  const [calendarValue, setCalendarValue] = useState(new Date());

  // Function to check the status of a date
  const getTileClass = (date) => {
    if (data?.reminders) {
      const matchingReminder = data?.reminders?.find((reminder) => {
        const reminderDate = new Date(reminder.reminderDate);
        return (
          date.getFullYear() === reminderDate.getFullYear() &&
          date.getMonth() === reminderDate.getMonth() &&
          date.getDate() === reminderDate.getDate()
        );
      });

      if (matchingReminder) {
        if (matchingReminder.status === 2) {
          return "active-day"; // Completed
        } else if (matchingReminder.status === 1) {
          return "bg-[#A83A3A] text-white rounded-full"; // In Progress
        }
      }
    }
    return null; // Not active
  };

  return (
    <Calendar
      value={calendarValue}
      onChange={setCalendarValue}
      className="bg-mainNavy  border-none shadow-2xl rounded-2xl max-w-[280px] drop-shadow-2xl"
      tileClassName={({ date }) => {
        return getTileClass(date);
      }}
      onClickDay={(e) => {
        if (onClickDay) {
          const isActive = getTileClass(e) !== null;
          if (setIsActiveDay) {
            setIsActiveDay(isActive);
          }
          return onClickDay(e);
        } else {
          return;
        }
      }}
    />
  );
};

export default ReminderCalendar;
