import { useMutation, useQuery, useQueryClient } from "react-query";
import { deleteFinancial, getAllAccountMonthlyReport, getFinancialReportMonthly } from "../../helper/fetchers/finaical";
export const useGetFinancialReportMonthly = () => {
    return useQuery(["financial", "monthly"], getFinancialReportMonthly);
  };
export const useGetAllAccountMonthlyReport = () => {
    return useQuery(["Account", "monthly"], getAllAccountMonthlyReport);
  };
  export const useDeleteFinancial = (onSuccess,onError) => {
    const queryClient = useQueryClient();
    return useMutation(deleteFinancial, {
      onSuccess: () => {
        queryClient.invalidateQueries("financial");
        onSuccess()
      },
      onError: (error) => {
        onError()
      },
    });
  };