import React from "react";
import { Form } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import { IoCloudUploadOutline } from "react-icons/io5";
import { CustomInput } from "../../../../Components/FormUi";
import { FormControl } from "@mui/material";

const DonePaidForm = ({ show, handleClose, handleSave }) => {
  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = (formData) => {
    const newFormData = new FormData();
    newFormData.append("invoiceDescription", formData.name);
    if (formData.attachements) {
      for (let item of formData.attachements) {
        newFormData.append("productInvoiceAttach", item);
      }
    }
    handleSave(newFormData, {
      onSuccess: () => {
        reset();
      },
    });
  };

  return (
    <Modal
      centered
      contentClassName="bg-mainItems !max-w-[700px] text-mainText"
      className="bg-black/50"
      show={show}
      size="lg"
      onHide={handleClose}
    >
      <Form
        onSubmit={handleSubmit(onSubmit)}
        encType="multipart/form-data"
        className="row my-4 date-input w-100 mx-auto p-3"
      >
        <Modal.Body className="w-full flex items-center justify-center flex-col gap-2 ">
          <FormControl className="mb-3 w-[60%] flex flex-col gap-3">
            <label className="bg-mainNavy rounded-md flex flex-col justify-center items-center mb-1 gap-3 p-2 ">
              <input
                type="file"
                className="hidden"
                {...register("attachements", {
                  required: "يجب اضافة ملفات",
                })}
                multiple
              />
              <IoCloudUploadOutline fontSize={25} />
              <p>ارفق ملف المشروع</p>
              {errors.attachements && (
                <span className="text-red-500">
                  {errors.attachements.message}
                </span>
              )}
            </label>
            <CustomInput
              control={control}
              name="name"
              placeholder="ادخل الاسم"
              rules={{
                required: "يجب كتابة الاسم",
                minLength: {
                  value: 3,
                  message: "لا يقل عن 3 حروف",
                },
                maxLength: {
                  value: 100,
                  message: "لا يزيد عن 100 حرف",
                },
                pattern: {
                  value: /^[A-Za-z\u0600-\u06FF ]{3,}$/i,
                  message: "يجب ان تتكون من حروف عربية او انجليزية فقط",
                },
              }}
              id="new-project"
            />
            {errors.name && (
              <span className="text-red-500">{errors.name.message}</span>
            )}
          </FormControl>
          <Button
            type="submit"
            className="mx-0 py-1 px-8 font-semibold text-[13px] text-white w-[150px] bg-[#EFAA20] hover:bg-[#EFAA20]"
          >
            حفظ
          </Button>
        </Modal.Body>
      </Form>
    </Modal>
  );
};

export default DonePaidForm;
