import { Badge, IconButton, styled } from "@mui/material";
import React from "react";
import Cmt from "../../../assets/icons/comment.png";
import { useCommentCtx } from "./CommentCtx";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#F1416C",
    padding: "0 4px",
    color: '#FFFFFF'
  },
}));

const CommentButton = () => {
  const { comments } = useCommentCtx();
    const { handleOpenModal } = useCommentCtx();
  return (
    <StyledBadge badgeContent={comments.length}>
      <IconButton aria-label="comment" sx={{ p: 0 }} onClick={handleOpenModal}>
        <img src={Cmt} alt="comment" className="w-8" />
      </IconButton>
    </StyledBadge>
  );
};

export default CommentButton;
