import { IoClose } from "react-icons/io5";
import { CommentReplyBody } from "./CommentReplyBody";

export const ReplyTo = ({ onClose ,comment={}}) => {
    return (
      <div className="w-full flex justify-between items-start gap-3 py-1">
        <div className="relative top-5">
          <button className="bg-[#D9D9D9] rounded-full p-1" onClick={onClose}>
            <IoClose fill="#151521" />
          </button>
        </div>
          <CommentReplyBody comment={comment} />
        <div className=""></div>
      </div>
    );
  };