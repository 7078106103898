import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";

const LineChart = ({ series = [], colors = [] }) => {
  const options = {
    chart: {
      height: 350,
      type: "bar",
      sparkline: {
        enabled: false,
      },
      animations: {
        enabled: true,
        easing: "easeinout",
        speed: 800,
        animateGradually: {
          enabled: true,
          delay: 150,
        },
        dynamicAnimation: {
          enabled: true,
          speed: 350,
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        borderRadius: 2,
        dataLabels: {
          position: "top", // top, center, bottom
        },
      },
    },
    stroke: {
      curve: "smooth",
      dashArray: [0, 0],
    },
    title: {
      text: "Page Statistics",
      align: "right",
    },
    legend: {
      tooltipHoverFormatter: function (val, opts) {
        return (
          val +
          " - " +
          opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] +
          ""
        );
      },
    },
    colors: colors,
    yaxis: [
      {
        title: {},
        opposite: true, // Display the Y-axis on the left
        labels: {
          show: true, // Display labels on the left Y-axis
          align: "left", // Align labels to the right
          style: {
            colors: "var(--main-text)", // Set the color to white
          },
        },
      },
    ],
    markers: {
      size: 0,
      hover: {
        sizeOffset: 6,
      },
    },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      labels: {
        style: {
          colors: "var(--main-text)", // Set the color to white for both top and bottom labels
        },
      },
    },
    tooltip: {
      y: [
        {
          title: {
            formatter: function (val) {
              return ` ${val} `;
            },
          },
        },
        {
          title: {
            formatter: function (val) {
              return ` ${val} `;
            },
          },
        },
        {
          title: {
            formatter: function (val) {
              return ` ${val} `;
            },
          },
        },
      ],
    },
    grid: {
      borderColor: "var(--main-text)",
    },
  };

  return (
    <div id="chart" className="w-100 custom-chart">
      <ReactApexChart
        options={options}
        series={series}
        type="bar"
        className="w-100"
        height={340}
      />
    </div>
  );
};

export default LineChart;
