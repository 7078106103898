import { createSlice } from '@reduxjs/toolkit'

export const fullWidthSlice = createSlice({
  name: 'fullWidth',
  initialState: false,
  reducers: {
    toggleWidth: (state) => {
      return !state
    },
    resetWidth: (state) => {
      return false
    },
  },
})

export const { toggleWidth , resetWidth } = fullWidthSlice.actions

export default fullWidthSlice.reducer