import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { FaFileDownload } from "react-icons/fa";
import { PdfIcon } from "../utiltis/Icons";

const useOpenModal = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return {
    show,
    handleClose,
    handleShow,
  };
};
function Example({ show, handleClose, content }) {
  return (
    <Modal
      size="lg"
      centered
      show={show}
      onHide={handleClose}
      contentClassName="p-0 bg-mainNavy w-fit mx-auto"
      className="bg-black/50"
    >
      <Modal.Body className="flex flex-col items-center ">{content}</Modal.Body>
    </Modal>
  );
}
const getType = (fileName) => {
  const fileExtension = fileName?.split(".")?.pop()?.toLowerCase();
  return fileExtension;
};
const FileContainer = ({ files = [], show, handleClose }) => {
  // const { show, handleClose, handleShow } = useOpenModal();
console.log("files",files);

  return (
    <div>
      <Example show={show} handleClose={handleClose} />
      {files?.map((file, index) => {
        const fileType = getType(file);
        switch (fileType) {
          case "jpg":
          case "jpeg":
          case "png":
          case "gif":
            return (
              <Modal
                size="lg"
                centered
                show={show}
                onHide={handleClose}
                contentClassName="p-0 bg-mainNavy w-fit mx-auto"
                className="bg-black/50"
              >
                <Modal.Body>
                  <div className="w-full flex justify-center">
                  <Button className="bg-mainItems" download={true} href={file}>
                    <FaFileDownload fontSize={25} color="#EFAA20" />
                  </Button>

                  </div>
                  <img className="w-96  rounded-xl border" src={file} alt="" />
                </Modal.Body>
              </Modal>
            );
          case "pdf":
            return (
              <Modal
                size="lg"
                centered
                show={show}
                onHide={handleClose}
                contentClassName="p-0 bg-mainNavy w-fit mx-auto"
                className="bg-black/50"
              >
                <Modal.Body>
                <div className="w-full flex justify-center">
                  <Button className="bg-mainItems" download={true} href={file}>
                    <FaFileDownload fontSize={25} color="#EFAA20" />
                  </Button>
                  </div>
                  <embed
                    src={file}
                    type="application/pdf"
                    width="400"
                    height="600"
                    className="w-96 relative rounded-xl border"
                  />
                </Modal.Body>
              </Modal>
            );
          case "zip":
            return (
              <Modal.Body>
                <div className="w-full flex justify-center">

                <Button className="bg-mainItems" download={true} href={file}>
                  <FaFileDownload fontSize={25} color="#EFAA20" />
                </Button>
                </div>
                <img
                  className="w-96   rounded-xl border"
                  src="https://p7.hiclipart.com/preview/258/300/47/computer-icons-zip-truetype-icon-png-file-zip.jpg"
                  alt=""
                />
              </Modal.Body>
            );
          case "xlsx":
            return (
              <Modal.Body>
                <div className="w-full flex justify-center">

                <Button className="bg-mainItems" download={true} href={file}>
                  <FaFileDownload fontSize={25} color="#EFAA20" />
                </Button>
                </div>
                <img
                  className="w-96   rounded-xl border"
                  src="https://logodownload.org/wp-content/uploads/2020/04/excel-logo-0.png"
                  alt=""
                />
              </Modal.Body>
            );
          case "rar":
            return (
              <Modal.Body>
                <div className="w-full flex justify-center">

                <Button className="bg-mainItems" download={true} href={file}>
                  <FaFileDownload fontSize={25} color="#EFAA20" />
                </Button>
                </div>
                <img
                  className="w-96  rounded-xl border"
                  src="https://cdn-icons-png.freepik.com/512/9158/9158683.png"
                  alt=""
                />
              </Modal.Body>
            );
          default:
            return null;
        }
      })}
    </div>
  );
};

export default FileContainer;
