import { toast } from "react-toastify";
import myAxiosInstance, { myAxios, myAxiosJson } from "../https";

export const getAllServices = (params) =>
  myAxiosJson.get("services", { params }).then((data) => data?.data?.services).catch(error=>{
    toast.error(error?.response?.data?.message)
});
export const getAllServicesNew = () => myAxiosInstance("services").catch(error=>{
  toast.error(error?.response?.data?.message)
});



export const getServiceById = (id) => myAxiosInstance(`services/${id}`).then(data => data?.data?.service).catch(error=>{
  toast.error(error?.response?.data?.message)
});



export const addService = (data) => myAxiosJson.post("services", data);


export const updateService = (serviceId, data) =>
  myAxiosJson.patch(`services/${serviceId}`, data);


export const deleteService = (serviceId) =>
  myAxiosJson.delete(`services/${serviceId}`);
