import { twMerge } from "tailwind-merge";
import Skeleton from "@mui/material/Skeleton";
const List = ({ data, renderItem,withAvatar, isLoading = false, isError = false, className, ...props }) => {
  return (
    <div
      className={twMerge(
        "max-h-[700px] overflow-y-auto scrollbar-thin scrollbar-track-transparent scrollbar-thumb-[#EFAA20]",
        className
      )}
      {...props}
    >
        {isLoading ? <>
          <PlaceholderCard withAvatar={withAvatar} />
          <PlaceholderCard withAvatar={withAvatar} />
          <PlaceholderCard withAvatar={withAvatar} />
        </> : null }
        {isError ? <>
          <PlaceholderCard withAvatar={withAvatar} />
          <PlaceholderCard withAvatar={withAvatar} />
          <PlaceholderCard withAvatar={withAvatar} />
        </> : null }

{!isLoading && !isError ? data?.map((item, index) => renderItem(item,index)) : null}
      

    </div>
  );
};

const PlaceholderCard = ({ withAvatar = true }) => {
  return (
    <div className="flex gap-2 mb-2 p-2">
      {withAvatar ? (
        <Skeleton
          sx={{ bgcolor: "var(--text-gray)" }}
          variant="circular"
          width={40}
          height={40}
        />
      ) : null}

      <div className="">
        <Skeleton
          sx={{ bgcolor: "var(--text-gray)", fontSize: "1rem" }}
          variant="text"
        />
        <Skeleton
          sx={{ bgcolor: "var(--text-gray)" }}
          variant="rounded"
          width={210}
          height={60}
        />
      </div>
    </div>
  );
};

export default List;
