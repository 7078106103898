import { configureStore } from '@reduxjs/toolkit'
import fullWidthReducer from '../../Pages/System/Tasks/slices/fullWidthSlice'
import holidayFormReducer from '../../Components/System/Hr/ShowHolidayComponent/holidayFormSlice';
import chatReducer from './Chat/chatSlice';
import chatNotificationReducer from './Chat/chatNotification';
export const store = configureStore({
  reducer: {
    tasksMenuCollapsed : fullWidthReducer,
    holidayFormMode: holidayFormReducer,
    chat : chatReducer,
    chatNotificationPlace : chatNotificationReducer
    // socket: socketReducer,
  },
})