import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

const AllClientsPieChart = ({ total = 0 }) => {
  const [series, setSeries] = useState([70, 30]);

  useEffect(() => {
    // console.log("series 0 is " + series[0]);
  }, [series]);

  const options = {
    chart: {
      width: 500,
      type: "donut",
    },
    labels: [`داخلى `, ` خارجى  `],
    colors: ["#E40038", "#EFAA20"],
    stroke: {
      show: false, // Set this to false to remove the border of the donut segments
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      position: "left",
      offsetY: 0,
      height: 230,
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            total: {
              show: true,
              label: "كل العملاء",
              color: "var(--main-text)", // Set total label color to main-text
              formatter: (val) => {
                return total;
              },
            },
            colors: ["var(--main-text)", "var(--main-text)"], // Set label colors to main-text
          },
          value: {
            show: true,
            fontSize: "16px",
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: 400,
            color: "var(--main-text)", // Set value color to main-text
            offsetY: 16,
            formatter: function (val) {
              return 40;
            },
          },
        },
      },
    },
  };

  return (
    <div>
      <div className="chart-wrap">
        <div id="chart">
          <ReactApexChart
            options={options}
            series={series}
            type="donut"
            width={400}
          />
        </div>
      </div>
    </div>
  );
};

export default AllClientsPieChart;
