import React from "react";
import DashboardPieChart from "../../../../Components/charts/DashboardPieChart";

const TechnicalSupportChildTop = ({ data }) => {
  const title = "كل الطلبات";
  const colors = ["#FF4560", "#00E396", "#008FFB", "#FEB019"];
  const labels = ["في الانتظار", "تم الشراء", "قيد التنفيذ", "مرفوضه"];
  const values = [
    data?.PENDING,
    data?.COMPLETED,
    data?.IN_PROGRESS,
    data?.FIXED,
  ];

  const formatPercentage = (value) =>
    `${Math.round((value / data.TOTAL) * 100)}%` || `0%`;

  return (
    <div className="bg-mainItems border !border-[#efaa207f] rounded-[22px] px-3 mb-5 flex gap-2 ">
      <DashboardPieChart
        label={title}
        colors={colors}
        width={230}
        labels={labels}
        series={values}
      />
      <div className="w-full grid grid-cols-2 h-full gap-6 m-auto">
        <div className="flex items-center gap-4">
          <div className="flex gap-2">
            <div className="w-6 h-6 rounded-md bg-[#FF4560]" />
            <p className="font-normal text-sm text-mainText">في الانتظار</p>
          </div>
          <p className="font-normal text-sm text-mainText">
            {formatPercentage(data?.PENDING)}
          </p>
        </div>
        <div className="flex items-center gap-4">
          <div className="flex gap-2">
            <div className="w-6 h-6 rounded-md bg-[#00E396]" />
            <p className="font-normal text-sm text-mainText">تم الشراء</p>
          </div>
          <p className="font-normal text-sm text-mainText">
            {formatPercentage(data?.COMPLETED)}
          </p>
        </div>
        <div className="flex items-center gap-4">
          <div className="flex gap-2">
            <div className="w-6 h-6 rounded-md bg-[#008FFB]" />
            <p className="font-normal text-sm text-mainText">قيد التنفيذ</p>
          </div>
          <p className="font-normal text-sm text-mainText">
            {formatPercentage(data?.IN_PROGRESS)}
          </p>
        </div>
        <div className="flex items-center gap-4">
          <div className="flex gap-2">
            <div className="w-6 h-6 rounded-md bg-[#FEB019]" />
            <p className="font-normal text-sm text-mainText">مرفوضه</p>
          </div>
          <p className="font-normal text-sm text-mainText">
            {formatPercentage(data?.FIXED)}
          </p>
        </div>
      </div>
    </div>
  );
};

export default TechnicalSupportChildTop;
