// 406

import axios from "axios";

import config from "../Config/Config";
import Cookies from "js-cookie";

// axios.defaults.baseURL = config.apiGateway?.URL;

export const myAxiosInstance = axios.create({
  baseURL: config.apiGateway?.URL,
});

// Add a request interceptor
myAxiosInstance.interceptors.request.use(
  async (config) => {
    if (Cookies.get("accessToken")) {
      config.headers["authes"] = `BSA__${Cookies.get("accessToken")}`;
    }
    config.headers['Accept-Language'] = "ar";
    config.headers["Content-Type"] = "multipart/form-data";
    return config;
  },
  (error) => {
    console.log("The Error Is:",error);
    return Promise.reject(error);
  }
);
// Add a response interceptor
myAxiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    // if (error?.response?.status === 404) {
    //   toast.error(error.response.data?.message);
    //   // history.push("");
    // }
    console.log("The Error Is:",error?.response?.status);
if(error?.response?.status === 401){
  Cookies.remove("accessToken");
  Cookies.remove("role");
  Cookies.remove("socketId");
  Cookies.remove("goals");
  Cookies.remove("userId");
  Cookies.remove("country");
  // window.location.reload()
}
    return Promise.reject(error);
  }
);
export default myAxiosInstance;

export const myAxiosJson = axios.create({
  baseURL: config.apiGateway?.URL,
});
myAxiosJson.interceptors.request.use(
  async (config) => {
    if (Cookies.get("accessToken")) {
      config.headers["authes"] = `BSA__${Cookies.get("accessToken")}`;
    }
    
    config.headers['Accept-Language'] = "ar";
    config.headers["Content-Type"] = "application/json";
    return config;
  },
  
  async (error) => {
    try {
      if (error.code && error.code === 'ERR_NETWORK') {
        // Handle network errors here
        console.error("Network Error:", error.message);
        return Promise.reject(error);
      }

      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.error("Response Error:", error.response.data);
        console.error("Status Code:", error.response.status);
        console.error("Headers:", error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        console.error("Request Error:", error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error("Error:", error.message);
      }
      // Return a rejected promise with the error object
      return Promise.reject(error);
    } catch (error) {
      console.error("Error occurred while handling error:", error);
      return Promise.reject(error);
    }
  }
);
myAxiosJson.interceptors.response.use(
  (response) => response,
  (error) => {  
    // if (error?.response?.status === 404) {
    //   toast.error(error.response.data?.message);
    //   // history.push("");
    // }
    // console.log("The Error is: ", error.code === 'ERR_NETWORK');
    if(error?.response?.status === 401){
      Cookies.remove("accessToken");
      Cookies.remove("role");
      Cookies.remove("socketId");
      Cookies.remove("goals");
      Cookies.remove("userId");
      Cookies.remove("country");
      // window.location.reload()
    }
    return Promise.reject(error);
  }
);

export const myAxios = axios.create({
  baseURL: config.apiGateway?.URL,
});
myAxios.interceptors.request.use(
  async (config) => {
    if (Cookies.get("accessToken")) {
      config.headers["authes"] = `BSA__${Cookies.get("accessToken")}`;
    }
    config.headers['Accept-Language'] = "ar";
    config.headers["Content-Type"] = "application/json";
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
myAxios.interceptors.response.use(
  (response) => response,
  (error) => {
    // if (error?.response?.status === 404) {
    //   toast.error(error.response.data?.message);
    //   // history.push("");
    // }
    if(error?.response?.status === 401){
      Cookies.remove("accessToken");
      Cookies.remove("role");
      Cookies.remove("socketId");
      Cookies.remove("goals");
      Cookies.remove("userId");
      Cookies.remove("country");
      // window.location.reload()
    }
    return Promise.reject(error);
  }
);
