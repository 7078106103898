import React, { useState } from "react";
import { CiEdit } from "react-icons/ci";

const EditableInput = ({ renderShow, renderEdit }) => {
  const [isEditable, setIsEditable] = useState(true);
  const toggleEditable = () => setIsEditable(!isEditable);

  return (
    <div className="flex items-center gap-2">
      <div className="">{isEditable ? renderShow() : renderEdit()}</div>
      <button
        type="button"
        className="bg-green-500 text-mainText border border-green-500 hover:text-green-500 hover:bg-transparent p-1.5 rounded-sm"
        onClick={toggleEditable}
      >
        <CiEdit size={25} />
      </button>
    </div>
  );
};

export default EditableInput;
