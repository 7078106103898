import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import { FormModal } from "../../../Pages/System/PlanModel/components/FormModal";
import { CustomeInputFile } from "../../FormUi";
import { ErrorMessage } from "@hookform/error-message";
// import CloseBtn from "/assets/icons/Rejected.svg"

const validateFileLength = (value) => {
  if (value.length > 5) {
    return "اقصى عدد 5 ملفات";
  }
  return true;
};

function AddAttatchmentsModal({
  message,
  title,
  show,
  handleClose,
  handleSave,
}) {
  const { handleSubmit, control, register , formState: {errors}} = useForm();
  const onSubmit = (data) => {
    const formData = new FormData();
    Object.keys(data.financialFiles).forEach(key => {
      formData.append('financialFiles', data.financialFiles[key])
    })
    Object.keys(data.techFiles).forEach(key => {
      formData.append('techFiles', data.techFiles[key])
    })
    handleSave(formData)
  };
  return (
    <>
      <Modal
        centered
        contentClassName="bg-mainItems !max-w-[700px] text-mainText border !border-[#EFAA20] p-1"
        className="bg-black/50"
        show={show}
        size="lg"
        onHide={handleClose}
      >
        <Modal.Header className="py-2 rounded-t-md !justify-between border-none w-full">
          <Modal.Title className="text-white text-xl">{title}</Modal.Title>
        </Modal.Header>
        <form 
        onSubmit={handleSubmit(onSubmit)} 
        encType="multipart/form-data"
        className="w-full">
          <Modal.Body className="w-full space-y-4">
            <FormModal title={"ارفاق ملف عرض مالي"} titleClassName={"!text-base"}>
              <CustomeInputFile
                register={{
                  ...register("financialFiles", {
                    required: "برجاء ارفاق الملفات",
                    validate: validateFileLength,
                  }),
                }}
                name="financialFiles"
                multiple={true}
                accept={"image/*"}
              />
              <ErrorMessage
                name="financialFiles"
                errors={errors}
                render={({ message }) => (
                  <span className="text-red-500">{message}</span>
                )}
              />
            </FormModal>
            <FormModal title={"ارفاق ملف عرض الفني"} titleClassName={"!text-base"}>
              <CustomeInputFile
                register={{
                  ...register("techFiles", {
                    required: "برجاء ارفاق الملفات",
                    validate: validateFileLength,
                  }),
                }}
                name="techFiles"
                multiple={true}
                accept={"image/*"}
              />
              <ErrorMessage
                name="techFiles"
                errors={errors}
                render={({ message }) => (
                  <span className="text-red-500">{message}</span>
                )}
              />
            </FormModal>
          </Modal.Body>
          <Modal.Footer className="border-none gap-2 w-full justify-center">
            <Button
              className="mx-0 py-1 px-14 font-semibold text-[13px] text-white bg-[#EFAA20] hover:bg-[#EFAA20]"
            //   onClick={handleSave}
            type="submit"
            >
              حفظ
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
}

export default AddAttatchmentsModal;
