import { Link } from "react-router-dom";
import Stack from "@mui/material/Stack";

import Avatar from "@mui/material/Avatar";

import { ConnectedBadge, NotConnectedBadge } from "../StatusBadge";
import HoverLink from "../../List/HoverLink";
import { useContext } from "react";
import { ChatsContext } from "../../../Pages/System/PlanModel/context/ChatsContext";
export const UserCard = ({ path, user }) => {
  const { setChatItem } = useContext(ChatsContext);

  return (
    <HoverLink to={path}>
      <Stack direction="row" spacing={2}>
        {user?.status === 1 ? (
          <ConnectedBadge
            overlap="circular"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            variant="dot"
          >
            <Avatar
              alt={`${user?.firstName} ${user?.lastName}`}
              src={user?.image}
            />
          </ConnectedBadge>
        ) : (
          <NotConnectedBadge
            overlap="circular"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            variant="dot"
          >
            <Avatar
              alt={`${user?.firstName} ${user?.lastName}`}
              src={user?.image}
            />
          </NotConnectedBadge>
        )}
      </Stack>
      <div className="flex flex-col" onClick={() => setChatItem(user)}>
        <p className="text-mainText">{`${user?.firstName} ${user?.lastName}`}</p>
        <span className="text-[#EFAA20] text-[14px]">{`${user?.role} / ${user?.department}`}</span>
      </div>
    </HoverLink>
  );
};
