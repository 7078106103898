import { useEffect, useState } from "react";
import {
  AiFillDislike,
  AiFillLike,
  AiOutlineDislike,
  AiOutlineLike,
} from "react-icons/ai";
import { ReactionsMenu } from "./ReactionsMenu";
import useModal from "../hooks/useModal";
import { useLikeComment, useUnLikeComment } from "../hooks/services";
import { useParams } from "react-router-dom";

export const CommentReactions = ({
  likeUsers = [],
  dislikeUsers = [],
  commentId = "",
  comment = {},
}) => {
  const { taskId } = useParams();
  const { mutate: mutateLike } = useLikeComment(
    () => {},
    () => {},
    taskId,
    commentId
  );
  const { mutate: mutateUnLike } = useUnLikeComment(
    () => {},
    () => {},
    taskId,
    commentId
  );
  const [isLiked, setIsLiked] = useState(false);
  const [isDisLiked, setIsDisLiked] = useState(false);
  const toggleLike = () => {
    setIsDisLiked(false);
    setIsLiked(!isLiked);
    mutateLike();
  };
  const toggleDisLiked = () => {
    setIsLiked(false);
    setIsDisLiked(!isDisLiked);
    mutateUnLike();
  };

  // const { open, handleOpenModal, handleCloseModal } = useModal()

  const likesNumber = likeUsers.length;
  const dislikeNumber = dislikeUsers.length;

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    // console.log("mouse entered");
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (e) => {
    // console.log("mouse leave");
    setAnchorEl(null);
  };

  useEffect(() => {
    let likedMe = likeUsers.find((user) => user._id === comment.createdBy._id);
    let dislikedMe = dislikeUsers.find(
      (user) => user._id === comment.createdBy._id
    );
    // console.log("is likedMe ? ", Boolean(likedMe));
    // console.log("is dislikedMe ? ", Boolean(dislikedMe));
    setIsLiked(likedMe);
    setIsDisLiked(dislikedMe);
  }, []);

  return (
    <div
      className="relative"
      onMouseEnter={handleClick}
      onMouseLeave={handleClose}
    >
      <div className="flex overflow-hidden rounded-lg -mt-1 z-10 peer shadow-sm ">
        <button
          onClick={toggleDisLiked}
          className="p-1.5 bg-gray-50 hover:!bg-gray-200 transition-colors flex items-center gap-1 text-black"
        >
          {dislikeNumber ? dislikeNumber : " "}
          {isDisLiked ? (
            <AiFillDislike size={22} fill="red" className="" />
          ) : (
            <AiOutlineDislike size={22} color="black" className="" />
          )}
        </button>
        <button
          onClick={toggleLike}
          className="p-1.5 bg-gray-50 hover:!bg-gray-200 transition-colors flex items-center gap-1 text-black"
          // onMouseEnter={handleClick}
          // onMouseLeave={handleClose}
        >
          {likesNumber ? likesNumber : " "}
          {isLiked ? (
            <AiFillLike size={22} fill="#E1A945" className="" />
          ) : (
            <AiOutlineLike size={22} color="black" className="" />
          )}
        </button>
      </div>
      {likesNumber + dislikeNumber > 0 ? (
        <ReactionsMenu
          show={open}
          onClose={handleClose}
          anchorEl={anchorEl}
          onClick={handleClick}
          likes={likeUsers}
          dislikes={dislikeUsers}
        />
      ) : null}
    </div>
  );
};
