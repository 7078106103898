import React, { useState } from "react";
import "./index.css";
import Accordion from "react-bootstrap/Accordion";
import { Link } from "react-router-dom";
import { MdKeyboardArrowDown } from "react-icons/md";
import { AllCategories } from "../../AllCategories/AllCategories";
import { CheckRole } from "../../../../helper/utils";
import { roles } from "../../../../helper/fetchers/Tasks";
import Cookies from "js-cookie";

const departmentOption = [
  "مدنى",
  "معمارى",
  "كهرباء",
  "ميكانيكا",
  "IT",
  "موارد بشرية",
  "أدارى",
  "برمجة",
]

const AllUserCategories = ({ countryName = "السعودية" }) => {
  const [activeCountry, setActiveCountry] = useState(null);
  const [activeChild, setActiveChild] = useState({});

  const handleCountryToggle = (country) => {
    setActiveCountry((prev) => {
      // Close all children when parent is toggled
      if (prev === country) {
        setActiveChild({});
      } else {
        setActiveChild({});
      }
      return prev === country ? null : country;
    });
  };

  const handleChildToggle = (country, child) => {
    setActiveChild((prev) => ({
      ...prev,
      [country]: prev[country] === child ? null : child,
    }));
  };

  return (
    <AllCategories
      child={
        <div className="d-flex flex-column align-items-center">
          <Link className="pointer" to={"/System/Users/index"}>
            <p className="text-center text-mainText py-2">كل المستخدمين</p>
          </Link>
          <div className="pointer w-full mt-0">
            <div className="d-flex justify-content-center flex-column">
              <Accordion activeKey={activeCountry}>
                {CheckRole(roles["admin"]) ||
                (CheckRole(roles["manager"]) &&
                  Cookies.get("country") === "السعودية") ||
                CheckRole(roles["accountant"]) ||
                CheckRole(roles["HR"]) ? (
                  <Accordion.Item eventKey="saudia">
                    <Accordion.Header
                      className={`${
                        activeCountry === "saudia"
                          ? "border-2 rounded-md border-[#EFAA20] hover:border-transparent"
                          : "border-2 border-mainBorder rounded-md"
                      }`}
                      onClick={() => handleCountryToggle("saudia")}
                    >
                      <Link
                        to="/System/AllUsers/Country/Saudia"
                        className="flex w-full justify-between"
                      >
                        <div>السعودية</div>
                        <MdKeyboardArrowDown size={20} />
                      </Link>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="tabs d-flex justify-content-center align-items-center flex-column">
                        <Link
                          to={"/System/AllUsers/Country/Saudia/مدير المكتب"}
                        >
                          <div className="tab text-end w-100">مدير مكتب</div>
                        </Link>
                        <Accordion activeKey={activeChild.saudia}>
                          <Accordion.Item eventKey="مدير قسم">
                            <Accordion.Header
                              className="border-2 border-mainBorder rounded-md rounded-b-lg"
                              onClick={() =>
                                handleChildToggle("saudia", "مدير قسم")
                              }
                            >
                              <Link
                                className="flex w-full justify-between"
                                to={"/System/AllUsers/Country/Saudia/مدير قسم"}
                              >
                                <div className="">مدير قسم</div>
                                <MdKeyboardArrowDown size={20} />
                              </Link>
                            </Accordion.Header>
                            <Accordion.Body>
                              <div className="tabs d-flex flex-column">
                                {departmentOption?.map((type) => (
                                  <Link
                                    key={type}
                                    to={`/System/AllUsers/Country/Saudia/مدير قسم/${type}`}
                                  >
                                    <div className="tab">{type}</div>
                                  </Link>
                                ))}
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="موظف">
                            <Accordion.Header
                              className="border-2 border-mainBorder rounded-md rounded-b-lg"
                              onClick={() =>
                                handleChildToggle("saudia", "موظف")
                              }
                            >
                              <Link
                                className="flex w-full justify-between"
                                to={"/System/AllUsers/Country/Saudia/موظف"}
                              >
                                <div className="text-start">موظف</div>
                                <MdKeyboardArrowDown size={20} />
                              </Link>
                            </Accordion.Header>
                            <Accordion.Body>
                              <div className="tabs d-flex flex-column">
                                {departmentOption?.map((type) => (
                                  <Link
                                    key={type}
                                    to={`/System/AllUsers/Country/Saudia/موظف/${type}`}
                                  >
                                    <div className="tab">{type}</div>
                                  </Link>
                                ))}
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                ) : null}
                {CheckRole(roles["admin"]) ||
                (CheckRole(roles["manager"]) &&
                  Cookies.get("country") === "مصر") ||
                CheckRole(roles["accountant"]) ||
                CheckRole(roles["HR"]) ? (
                  <Accordion.Item eventKey="egypt">
                    <Accordion.Header
                      className={`${
                        activeCountry === "egypt"
                          ? "border-2 rounded-md border-[#EFAA20] hover:border-transparent"
                          : "border-2 border-mainBorder rounded-md rounded-b-lg"
                      }`}
                      onClick={() => handleCountryToggle("egypt")}
                    >
                      <Link
                        to="/System/AllUsers/Country/egypt"
                        className="w-full flex justify-between"
                      >
                        <div>مصر</div>
                        <MdKeyboardArrowDown size={20} />
                      </Link>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="tabs d-flex justify-content-center align-items-center flex-column">
                        <Link to={"/System/AllUsers/Country/egypt/مدير المكتب"}>
                          <div className="tab text-end w-100">مدير مكتب</div>
                        </Link>
                        <Accordion activeKey={activeChild.egypt}>
                          <Accordion.Item eventKey="مدير قسم">
                            <Accordion.Header
                              className="border-2 border-mainBorder rounded-md rounded-b-lg"
                              onClick={() =>
                                handleChildToggle("egypt", "مدير قسم")
                              }
                            >
                              <Link
                                className="w-full flex justify-between"
                                to={"/System/AllUsers/Country/egypt/مدير قسم"}
                              >
                                <div className="">مدير قسم</div>
                                <MdKeyboardArrowDown size={20} />
                              </Link>
                            </Accordion.Header>
                            <Accordion.Body>
                              <div className="tabs d-flex flex-column">
                                {departmentOption?.map((type) => (
                                  <Link
                                    key={type}
                                    to={`/System/AllUsers/Country/egypt/مدير قسم/${type}`}
                                  >
                                    <div className="tab">{type}</div>
                                  </Link>
                                ))}
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="موظف">
                            <Accordion.Header
                              className="border-2 border-mainBorder rounded-md rounded-b-lg"
                              onClick={() => handleChildToggle("egypt", "موظف")}
                            >
                              <Link
                                className="w-full flex justify-between"
                                to={"/System/AllUsers/Country/egypt/موظف"}
                              >
                                <div className="text-start">موظف</div>
                                <MdKeyboardArrowDown size={20} />
                              </Link>
                            </Accordion.Header>
                            <Accordion.Body>
                              <div className="tabs d-flex flex-column">
                                {departmentOption?.map((type) => (
                                  <Link
                                    key={type}
                                    to={`/System/AllUsers/Country/egypt/موظف/${type}`}
                                  >
                                    <div className="tab">{type}</div>
                                  </Link>
                                ))}
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                ) : null}
              </Accordion>
            </div>
          </div>
        </div>
      }
    />
  );
};

export default AllUserCategories;
