import React from "react";
import { Controller } from "react-hook-form";

const CustomTextArea = ({ control, name, placeholder, rules, disabled }) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field }) => (
        <textarea
          placeholder={placeholder}
          className="form-control my-3 w-100 m-auto textArea"
          cols={30}
          rows={7}
          disabled={disabled}
          value={field.value}
          onChange={field.onChange}
        />
      )}
    />
  );
};

export default CustomTextArea;
