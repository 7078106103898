import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

function PieChart(props) {
  return (
    <div>
      <div className="chart-wrap">
        <div id="chart">
          <ReactApexChart
            options={{
              chart: {
                width: 400,
                type: "donut",
              },
              labels: props.labels,
              toolbar: {
                show: true,
                offsetX: -40, // Adjust this value to move the toolbar horizontally
                offsetY: -50, // Adjust this value to move the toolbar vertically
                tools: {
                  download: true,
                  selection: false,
                  zoom: false,
                  zoomin: false,
                  zoomout: false,
                  pan: false,
                  reset: false,
                },
                autoSelected: "download",
              },
              plotOptions: {
                pie: {
                  donut: {
                    size: "80%",
                    labels: {
                      show: true,
                      total: {
                        show: true,
                        label: props.label,
                        color: "var(--main-text)",
                        formatter: (val) => {
                          return val?.config?.series.reduce(
                            (acc, curr) => acc + curr,
                            0
                          );
                        },
                      },
                    },
                  },
                },
              },
              colors: props.colors,
              stroke: {
                show: false, // Set this to false to remove the border of the donut segments
              },
              dataLabels: {
                enabled: false,
              },
              responsive: [
                {
                  breakpoint: 480,
                  options: {
                    chart: {
                      width: 200,
                    },
                    legend: {
                      show: false,
                    },
                  },
                },
              ],
              legend: {
                position: "left",
                offsetY: 0,
                height: 230,
              },
            }}
            series={props.series}
            type="donut"
            width={props.width ? props.width : 200}
          />
        </div>
      </div>
    </div>
  );
}

export default PieChart;
