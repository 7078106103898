import React from "react";
import { useSearchCountryUsersContext } from "./Context";
import List from "../../../List";
import { Avatar, Stack } from "@mui/material";
import { ConnectedBadge, NotConnectedBadge } from "../../../Users/StatusBadge";
import HoverLink from "../../../List/HoverLink";

const UserList = () => {
  const { statusActions, route, activeUsers, filteredUsers } =
    useSearchCountryUsersContext();
  return (
    <div className="max-h-[700px] overflow-y-auto scrollbar-none">
      {statusActions.status !== undefined ? (
        activeUsers.length > 0 ? (
          <List
            data={activeUsers}
            renderItem={(props) => (
              <UserCard
                path={`/${route}/user/${props?.id}`}
                user={props}
                key={props?.id}
              />
            )}
          />
        ) : (
          <NoUsers />
        )
      ) : filteredUsers.length > 0 ? (
        <List
          data={filteredUsers}
          renderItem={(props) => (
            <UserCard
              path={`/${route}/user/${props?.id}`}
              user={props}
              key={props?.id}
            />
          )}
        />
      ) : (
        <NoUsers />
      )}
    </div>
  );
};
const UserCard = ({ user, path }) => {
  return (
    <HoverLink to={path}>
      <Stack direction="row" spacing={2}>
        {user?.status === 1 ? (
          <ConnectedBadge
            overlap="circular"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            variant="dot"
          >
            <Avatar
              alt={`${user?.firstName} ${user?.lastName}`}
              src={user?.image}
            />
          </ConnectedBadge>
        ) : (
          <NotConnectedBadge
            overlap="circular"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            variant="dot"
          >
            <Avatar
              alt={`${user?.firstName} ${user?.lastName}`}
              src={user?.image}
            />
          </NotConnectedBadge>
        )}
      </Stack>
      <div className="flex flex-1 flex-col gap-1 items-start justify-center w-full overflow-hidden ">
        <h2 className="text-end name-header text-mainText w-full  ">
          {user?.firstName + " " + user?.lastName}
        </h2>
        <p className="text-mainCustomGray text-sm w-full  ">{user?.email}</p>
      </div>
    </HoverLink>
  );
};

const NoUsers = () => {
  return (
    <p className="text-base text-mainText text-center">لا يوجد مستخدمين</p>
  );
};
export default UserList;
