import { useEffect, useRef, useState } from "react";

import { toBlob } from "html-to-image";
import moment from "moment";
import PdfPreview from "../../../../Components/Preview/CustomPdfPreview";
import PdfDocument from "../../../Modules/Reports/components/PdfDocument";
import SectionElement from "../../../../Components/Preview/components/SectionElement";
import { statusEnum } from "../../../../helper/utils";

const PrintHolidayFile = ({ show, onHide, data = {} }) => {
  const refs = useRef([]);
  const [imageUrls, setImageUrls] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (show) {
      const captureImages = async () => {
        setLoading(true);
        const blobs = await Promise.all(
          refs.current.map((ref) => toBlob(ref, { pixelRatio: 2 }))
        );
        const urls = blobs.map((blob) => URL.createObjectURL(blob));
        setImageUrls(urls);
        setLoading(false);
      };

      captureImages();
    }
  }, [show]);
  useEffect(() => {
    // setTransformedData(SliceData(10, 15, data?.projectsInfo));
  }, [data]);
  console.log("PrintHolidayFile", data);
  return (
    <>
      <PdfPreview show={show} onHide={onHide}>
        {imageUrls.length > 0 && (
          <PdfPreview.DownloadButton
            document={<PdfDocument imageUrls={imageUrls} />}
            loading={loading}
          />
        )}

        <div ref={(el) => (refs.current[0] = el)} className="rounded-t w-full">
          <PdfPreview.Header
            titleAr={"طلب اجازة"}
            titleEn={"Vacation"}
            createdAt={`تاريخ الانشاء : ${moment(data?.createdAt).format(
              "YYYY-MM-DD"
            )}`}
          />
          <PdfPreview.Body>
            <div className="flex justify-between items-center">
              <PdfPreview.SectionTitle title={"تفاصيل الاجازة"} />
              <SectionElement
                title=" نوع الاجازة "
                disc={
                  +data?.vactionType === 0
                    ? "بدون راتب "
                    : +data?.vactionType === 1
                    ? "براتب "
                    : "اخري"
                }
                className="w-1/2 gap-0 px-2"
              />
            </div>

            <PdfPreview.SectionWrapper>
              <SectionElement
                title="غرض الاجازة"
                disc={data?.vactionCategoryId?.name}
              />

              <SectionElement
                title="مدة الاجازة"
                disc={data?.durationVaction}
              />
              <SectionElement
                title="من تاريخ"
                disc={moment(data?.startDate).format("DD-MM-YYYY")}
              />
              <SectionElement
                title="الى تاريخ"
                disc={moment(data?.endDate).format("DD-MM-YYYY")}
              />
              <SectionElement
                title="حالة الاجازة"
                disc={statusEnum[+data?.status]?.title}
              />
              {+data?.status === 5 ? (
                <SectionElement title="سبب الرفض" disc={data?.resonRejected} />
              ) : null}
            </PdfPreview.SectionWrapper>

            <div className="">
              <PdfPreview.SectionTitle title={"بيانات الموظف "} />
              <PdfPreview.SectionWrapper>
                <SectionElement
                  title="اسم الموظف"
                  disc={`${data?.employeeId?.firstName} ${data?.employeeId?.lastName}`}
                />
                <SectionElement
                  title="الكود الوظيفى"
                  disc={data?.employeeId?.employeCode}
                />
                <SectionElement
                  title="فرع العمل"
                  disc={data?.employeeId?.country}
                />
                <SectionElement
                  title="قسم"
                  disc={data?.employeeId?.department}
                />
              </PdfPreview.SectionWrapper>
            </div>
            <div className="">
              <PdfPreview.SectionTitle title={"موجهة الى "} />
              <PdfPreview.SectionWrapper>
                <SectionElement title="المسئول عن الموافقة" disc={data?.approvalManager?.firstName} />
                <SectionElement title="كود المسئول" disc={data?.approvalManager?.employeCode} />
                <SectionElement title="الصلاحية" disc={data?.approvalManager?.role} />
                <SectionElement title="قسم" disc={data?.approvalManager?.department} />
                <SectionElement title="فرع العمل" disc={data?.approvalManager?.country} />
              </PdfPreview.SectionWrapper>
            </div>
          </PdfPreview.Body>
        </div>
      </PdfPreview>
    </>
  );
};

export default PrintHolidayFile;
