import React from 'react'
import UsersSidebar from './components/UsersSidebar'
import { Outlet } from 'react-router-dom'

const ReportUsersPage = () => {
  return (
    <div className='h-full rounded-[19px] grid grid-cols-12 bg-mainNavy text-mainText gap-2'>
        <div className="col-span-4 bg-mainItems rounded-[19px]"><UsersSidebar/></div>
        <div className="col-span-8 bg-mainItems rounded-[19px]">
          <Outlet/>
        </div>
    </div>
  )
}

export default ReportUsersPage