import React, { useContext, useState } from "react";
import { Accordion } from "react-bootstrap";
import { AllClientContext } from "./PageWrapperAllCLients";
import { Link, useNavigate } from "react-router-dom";
import { MdKeyboardArrowDown } from "react-icons/md";

const UserDropdown = ({ setOpen }) => {
  const [active, setActive] = useState();
  const { setFilterKey } = useContext(AllClientContext);
  const [insideCategories, setInsideCategories] = useState({
    design: false,
    review: false,
  });
  const navigate = useNavigate();
  return (
    <div className="absolute top-[2.8rem] right-12 z-[1000000]">
      <div className="flex items-center flex-col bg-mainNavy w-[200px] h-[300px] rounded-xl p-2 ">
        <div
          className="w-screen h-screen fixed inset-0  z-[-1]"
          onClick={() => setOpen(false)}
        />
        <Accordion defaultActiveKey={null}>
          <Accordion.Item eventKey="0">
            <Accordion.Header
              onClick={() => {
                setActive(0);
              }}
              className={`${
                active === 0
                  ? "filterItem"
                  : "border-2 border-mainBorder rounded-md "
              }`}
            >
              <Link
                className="!w-full  !text-start text-mainText"
                to={"/System/Clients/Inside"}
              >
                الداخلي
              </Link>

              <MdKeyboardArrowDown size={20} />
            </Accordion.Header>

            <Accordion.Body>
              <div className="flex flex-col gap-2">
                <button
                  onClick={() => {
                    navigate("/System/Clients/Inside");
                    setFilterKey({ projectType: 1 });
                    setInsideCategories({
                      design: true,
                      review: false,
                    });
                    // setOpen(false);
                  }}
                  className="!w-full mt-2 "
                >
                  <div
                    className={`tab  ${
                      active === 0 &&
                      insideCategories.design &&
                      " border border-1 rounded-md hover:!border-[transparent !border-[#EFAA20]"
                    } text-end w-100 text-mainText p-2`}
                  >
                    تصميم
                  </div>
                </button>
                <button
                  onClick={() => {
                    navigate("/System/Clients/Inside");
                    setFilterKey({ projectType: 2 });
                    setInsideCategories({
                      design: false,
                      review: true,
                    });
                    // setOpen(false);
                  }}
                  className="!w-full text-start "
                >
                  <div
                    className={`tab  ${
                      active === 0 && insideCategories.review
                        ? " border border-1 rounded-md hover:!border-[transparent !border-[#EFAA20]"
                        : "!border-transparent"
                    } text-end w-100 text-mainText p-2`}
                  >
                    الاشراف علي التنفيذ
                  </div>
                </button>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </div>
  );
};

export default UserDropdown;
