import { createContext, useContext, useState } from "react";
import useModal from "../hooks/useModal";

export const SideBarProvider = createContext();
const SidebarContext = ({ children }) => {
  // const [collapsed, setCollapsed] = useState(false);
  const {
    open: collapsed,
    handleOpenModal: handleOpenCollapsed,
    handleCloseModal: handleCloseCollapsed,
    handleToggleModal: handleToggleCollapsed,
  } = useModal();
  return (
    <SideBarProvider.Provider
      value={{
        collapsed,
        handleOpenCollapsed,
        handleCloseCollapsed,
        handleToggleCollapsed,
      }}
    >
      {children}
    </SideBarProvider.Provider>
  );
};

export default SidebarContext;

export const useSideBarProvider = () => {
  const ctx = useContext(SideBarProvider);
  if (!ctx) {
    throw new Error(
      "useSideBarProvider must be used within a SidebarContextProvider"
    );
  }
  return ctx;
};
