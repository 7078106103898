import React from "react";
import { FaExclamationCircle } from "react-icons/fa";
import SystemControler from "./System/SystemControler/SystemControler";
import BackButton from "./BackButton";
function UnAuthorizedMessage() {
  return (
    <>
    <SystemControler
    child={<BackButton />}
    />
    <div className="bg-mainItems h-full rounded-[19px] text-mainText flex justify-center items-center">
      <div className="rounded-[19px] border !border-[#EFAA20] p-4 flex items-center gap-2">
        <p className="text-3xl text-red-500"> {"غير مسموح بدخول هذا القسم"} </p>
        <span className="text-2xl text-red-500 mt-1">
          <FaExclamationCircle />
        </span>
      </div>
    </div>
    </>
  );
}

export default UnAuthorizedMessage;
