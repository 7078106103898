import React, { useContext, useState } from "react";
import { AddNewButton } from "../../Settings/Orders/components/AddNewButton";
import { OptionsButton } from "../../Settings/OptionsButton";
import { FormModal } from "../../PlanModel/components/FormModal";
import TechnicalSupportAddSubCategory from "./TechnicalSupportAddSubCategory";
import { TechnicalSupportContext } from "./TechnicalSupportProvider";
import {
  useDeleteTechnicalSupportTermsOrderSubCategory,
  useGetAllTechnicalSupportTermsOrderSubCategory,
} from "../../../../hooks/fetchers/TechnicalSupportPurcheseOrder";
import RemoveModal from "../../Settings/RemoveModal";
import SuccessfullModal from "../../../../Components/Modals/SuccessfullModal";
import useSuccessfullModal from "../../../../Components/Modals/hooks/useSuccessfullModal";
import LoadingPage from "../../../../Components/feedback/LoadingPage";

const TechnicalSupportTermsOrderTable = () => {
  const [activeCategories, setActiveCategories] = useState(null);
  const {
    activeCategory,
    setAddTermsOpenSubCategory,
    setUpdatedItemSubCategory,
    setActiveSubCategory,
    updatedItem,
    updatedItemSubCategory,
  } = useContext(TechnicalSupportContext);
  const [showDelete, setShowDelete] = useState(false);

  const { data = [] } =
    useGetAllTechnicalSupportTermsOrderSubCategory(activeCategory);
  const handleShow = (item) => {
    setAddTermsOpenSubCategory(true);
    setActiveSubCategory(item?._id);
    setUpdatedItemSubCategory(item);
  };
  const {
    showSuccess,
    showError,
    handleShowSuccess,
    handleShowError,
    handleCloseSuccess,
    handleCloseError,
  } = useSuccessfullModal();
  const { mutate: deleteMutation, isLoading: isDeleteLoading } =
    useDeleteTechnicalSupportTermsOrderSubCategory(
      activeCategory,
      handleShowSuccess,
      handleShowError
    );
  const handleDelete = (item) => {
    setActiveSubCategory(item?._id);
    setUpdatedItemSubCategory(item);
    setShowDelete(true);
  };
  const [err, setError] = useState("");

  return isDeleteLoading ? (
    <LoadingPage />
  ) : (
    <>
      <TechnicalSupportAddSubCategory />
      <div className="bg-mainItems h-full rounded-xl w-[80%] p-2 ">
        <AddNewButton
          onClick={() => {
            setAddTermsOpenSubCategory(true);
            setUpdatedItemSubCategory(null);
          }}
        />
        <FormModal title={"بمعرفة"} className="h-full max-h-[700px] overflow-y-auto scrollbar-thin scrollbar-track-transparent scrollbar-thumb-[#EFAA20] mt-3">
          {data?.map((item) => (
            <div
              className={`flex w-full justify-between items-center px-2 text-[#ffffff80] border hover:!border-[#EFAA20] text-base ${
                activeCategories === item?._id
                  ? "!border-[#EFAA20]"
                  : "!border-transparent"
              }`}
              key={item?._id}
            >
              <button
                onClick={() => {
                  setActiveCategories(item?._id);
                }}
                className="w-full text-mainText text-right py-3"
              >
                {item?.name}
              </button>

              <OptionsButton
                id={item?._id}
                onUpdate={() => handleShow(item)}
                onDelete={() => handleDelete(item)}
              />
            </div>
          ))}
        </FormModal>
        <RemoveModal
          title={"التاكيد"}
          show={showDelete}
          handleClose={() => setShowDelete(false)}
          onSave={() => {
            deleteMutation(updatedItemSubCategory?._id, {
              onError: (err) => {
                setError(
                  err?.response?.data?.validationErrors ||
                    err?.response?.data?.message
                );
              },
            });
          }}
          stillOpen={true}
        />
        <SuccessfullModal
          handleClose={() => {
            setShowDelete(false);
            handleCloseSuccess();
          }}
          message={"تمت الحذف بنجاح"}
          show={showSuccess}
        />
        <SuccessfullModal
          handleClose={handleCloseError}
          message={err}
          show={showError}
          status="error"
        />
      </div>
    </>
  );
};

export default TechnicalSupportTermsOrderTable;
