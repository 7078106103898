import axios from "axios";
import myAxiosInstance from "../https";
import config from "../../Config/Config";
import Cookies from "js-cookie";
import { toast } from "react-toastify";

export const getAllExpensesItemsReport = (params) =>
  myAxiosInstance("expenses", { params }).catch(error=>{
    toast.error(error?.response?.data?.message)
});
export const getExpensesItemReport = (id) =>
  myAxiosInstance("expenses")
    .then(({ data }) => data?.expenses)
    .then((data) => data?.find((item) => item?._id === id)).catch(error=>{
      toast.error(error?.response?.data?.message)
  });

export const addExpensesItemReport = (data) =>
  myAxiosInstance.post("expenses/", data);

export const updateExpensesItemReport = (expensesID, data) =>
  myAxiosInstance.patch(`expenses/${expensesID}`, data);

export const deleteExpensesItemReport = (expensesID) =>
  myAxiosInstance.delete(`expenses/${expensesID}`);

export const addReport = (data) => {
  const headers = {
    "Content-Type": "multipart/form-data",
  };
  if (Cookies.get("accessToken")) {
    headers["authes"] = `BSA__${Cookies.get("accessToken")}`;
  }
  return axios.post(`${config.apiGateway?.URL}/expenses/`, data, { headers });
};
