import React, { useContext } from "react";
import { Modal } from "react-bootstrap";
import { ReminderPageProvider } from "../../../../Context/ReminderPage";
import Image from "../../../../Components/Image";
import ReminderGif from "../../../../assets/reminderGif.gif";
const ReminderPopup = ({show, onHide , data}) => {
  // const { showReminderPopup, setShowReminderPopup } =
  //   useContext(ReminderPageProvider);

  

console.log("reminder data",data);
  return (
    <ShowReminderWrapper
      onHide={onHide}
      show={show}
    >
      <div className="flex items-center justify-center gap-5 flex-col py-4 pb-4 ">
        <Image src={ReminderGif} alt="reminderGif" />
        <p className="text-3xl font-medium text-mainText">تذكير</p>
        <p className="text-xl font-medium text-mainText">
          {data?.message || ""}{" "}
        </p>
        <button
          onClick={onHide}
          type="submit"
          className="mt-4 sumbmitAddUpdateUser border-0 disabled"
        >
          حسنا
        </button>
      </div>
    </ShowReminderWrapper>
  );
};

export default ReminderPopup;
const ShowReminderWrapper = ({ children, show, onHide }) => {
  return (
    <Modal
      size="lg"
      show={show}
      onHide={onHide}
      aria-labelledby=" example-modal-sizes-title-lg"
      className="systemModal mettingModal bg-black/50 "
      contentClassName="scroll p-0 mt-[20%]"
    >
      {children}
    </Modal>
  );
};
