import { createContext, useContext, useState } from "react";

const SidebarContext = createContext();

const SidebarProvider = ({ children }) => {
  const [openProfileMenu, setOpenProfileMenu] = useState(false);
  const handleToggleProfileMenu = () => setOpenProfileMenu(!openProfileMenu);

  return (
    <SidebarContext.Provider
      value={{ openProfileMenu, handleToggleProfileMenu }}
    >
      {children}
    </SidebarContext.Provider>
  );
};

export const useSidebarContext = () => {
  const ctx = useContext(SidebarContext);
  if (!ctx) {
    throw Error("use inside provider");
  }
  return ctx;
};

export default SidebarProvider;
