import { useQuery } from "react-query";
import myAxiosInstance, { myAxios } from "../https";
import { toast } from "react-toastify";

export const getAllServicesHumans = () => myAxios("/serviceHuman/").catch(error=>{
  toast.error(error?.response?.data?.message)
});

export const getAllServicesHumansData = (params) =>
  myAxiosInstance.get("serviceHuman", { params }).then((data) => data?.data).catch(error=>{
    toast.error(error?.response?.data?.message)
});

export const useGetAllServiceHumanCategories = (params) => {
  return useQuery(["serviceHuman", params], () =>
    getAllServicesHumansData(params)
  );
};
