import React from "react";
import { CheckRole } from "../../../../helper/utils";
import AllTasksLineChart from "../../Plans/Projects/AllTasksLineChart/AllTasksLineChart";

import { useGetStatistics } from "../hooks/useGetStatistics";
import { TasksStatistics } from "../components/TasksStatistics";
import { ProjectsStatistics } from "../components/ProjectsStatistics";
import { roles } from "../../../../helper/fetchers/Tasks";

function MainPage() {
  const { data: statistics } = useGetStatistics();
  return (
    <div className="h-full bg-mainItems">
      <div className="bg-mainItems border !border-[#efaa207f] rounded-[22px] px-3 mb-5">
        {/* <AllPlansPieChart /> */}
        <div className="container ">
          {!CheckRole(roles["user"]) && !CheckRole(roles["administrator"]) && (
            <ProjectsStatistics data={statistics} />
          )}

          <TasksStatistics data={statistics} />
        </div>
      </div>
      <div className="bg-mainItems h-[400px] border !border-[#efaa207f] mb-5">
        <AllTasksLineChart />
      </div>
    </div>
  );
}

export default MainPage;
