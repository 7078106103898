import { toast } from "react-toastify";
import { myAxiosJson } from "../https";

export const getAllSubCategory = (categoryId) =>
  myAxiosJson(`category/${categoryId}/subcategory`).then(
    (data) => data?.data?.subCategory
  ).catch(error=>{
    toast.error(error?.response?.data?.message)
});
export const getSubCategoryById = (categoryId,subId) =>
  myAxiosJson(`category/${categoryId}/subcategory/${subId}`).then(
    (data) => data?.data?.subcategory
  ).catch(error=>{
    toast.error(error?.response?.data?.message)
});

export const addSubCategory = (categoryId, data) =>
  myAxiosJson.post(`category/${categoryId}/subcategory`, data);

export const updateSubCategory = (categoryId, subId, data) =>
  myAxiosJson.patch(`category/${categoryId}/subcategory/${subId}`, data);

export const deleteSubCategory = ([categoryId, subId]) =>
  myAxiosJson.delete(`category/${categoryId}/subcategory/${subId}`);
