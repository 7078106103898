import Cookies from "js-cookie";
import config from "../Config/Config";

export function convertDateFormat(dateString) {
  const date = new Date(dateString);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is 0-indexed, so add 1
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
}
export function convertTimeFormat(timeString) {
  const date = new Date(timeString);

  const hours = date.getHours();
  const minutes = String(date.getMinutes()).padStart(2, "0");

  const period = hours >= 12 ? "PM" : "AM";
  const formattedHours = hours % 12 || 12;

  return `${formattedHours}:${minutes} ${period}`;
}

export function FilesUrl(url) {
  return `${config.apiGateway?.URL}/${url}`;
}

export const getType = (fileName) => {
  const fileExtension = fileName?.split(".")?.pop()?.toLowerCase();
  return fileExtension;
};

export function GenerateColumnsNames(...columns) {
  return columns.map((column) => ({ name: column }));
}

export function displayErrorMessage(error) {
  // error?.response?.data?.validationErrors
  // error?.response?.data?.message
  const { validationErrors, message } = error?.response?.data;
  if (validationErrors) {
    return validationErrors;
  } else if (message) {
    return message;
  } else {
    return "حدث خطأ ما";
  }
}

export function CheckRole(role) {
  // console.log("role: ", role);
  // console.log("Cookies: ", Cookies.get("role"));
  return Cookies.get("role") && Cookies.get("role") === role;
}
export function CheckUserId(userID) {
  return Cookies.get("userId") && Cookies.get("userId") === userID;
}

export const orderTypeEnum = {
  1: "كل استخدمات المشروع",
  2: "كل خدمات المشروع",
  3: "نوع المشروع",
};

export const approvementEnum = {
  0: { title: "فى انتظار الموافقة الأولية" },
  1: { title: "فى انتظار الموافقة الثانية" },
  2: { title: "فى انتظار الموافقة النهائية" },
  3: { title: "مكتملة" },
};

export const statusEnum = {
  0: { title: "معلقة", color: "#2420EF" },
  1: { title: "قيد التنفيذ", color: "#EFAA20" },
  2: { title: "مكتملة", color: "#19b159" },
  3: { title: "موقوفة", color: "#19B159" },
  4: { title: "مقبول", color: "#19B159" },
  5: { title: "مرفوضة", color: "#FFFFFF" },
};

export const clientTypeEnum = {
  1: "حكومي أو مستثمر",
  2: "شركة أو مؤسسة",
  3: "فردي",
};
export const projectTypeEnum = {
  1: "تصميم",
  2: "اشراف علي التنفيذ",
};
export const identityTypeEnum = {
  1: "هوية",
  2: "تجاري",
};
export const paymenyEnum = {
  0: "لم يتم الدفع",
  1: "تم الدفع",
};
export const requestsEnum = {
  0: "فى انتظار الموافقة",
  1: "قيد التنفيذ",
  5: "مرفوضة",
  66: "تم تحويله الي مشروع",
};
export const reminderStatusEnum = {
  1: "قيد التنفيذ",
  2: "مكتمله",
};
export const vactionTypeEnum = {
  0: "بدون راتب",
  1: "براتب",
  2: "اخري",
}