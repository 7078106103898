import React from "react";
import { Outlet } from "react-router-dom";
import withGuard from "../../../utiltis/withGuard";
import { CheckRole } from "../../../helper/utils";
import { roles } from "../../../helper/fetchers/Tasks";

const ClientsModule = () => {
  return (
    <>
      <Outlet />
    </>
  );
}
export default withGuard(ClientsModule,[CheckRole(roles["administrator"]),CheckRole(roles["accountant"]) ,CheckRole(roles["admin"]),(CheckRole(roles["user"]))])