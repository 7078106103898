import React, { useEffect } from 'react'
import ReactApexChart from 'react-apexcharts';

const CountryPieChart = ({ data }) => {
    const labels = data?.map((item) => item?.label);
    const values = data?.map((item) => item?.value);
    useEffect(() => {
      const text = document.querySelectorAll(
        "text.apexcharts-text.apexcharts-datalabel-value"
      );
      text[0].setAttribute("fill", "var(--main-text)");
    }, []);
  
    const options = {
      annotations: {
        points: [
          {
            x: "50%",
            y: "50%",
            label: {
              text: "Custom Text",
              style: {
                fontSize: "16px",
                color: "#FF5733",
              },
            },
          },
        ],
      },
      plotOptions: {
        pie: {
          donut: {
            size: "80%",
            labels: {
              show: true,
              total: {
                show: true,
                label: " المستخدمين",
                color: "var(--main-text)",
                formatter: () => {
                  return data?.total;
                },
              },
            },
          },
        },
      },
      chart: {
        width: 380,
        type: "donut",
      },
      labels: [...labels],
      stroke: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      colors: ["#03795D", "#EFAA20", "#FD6074"],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              show: false,
            },
          },
        },
      ],
      legend: {
        position: "left",
        offsetY: 0,
        height: 200,
      },
    };
  
    const series = [...values];
    // console.log("labels:", labels);
    // console.log("series:", series);
    return (
      <div className="countryPiechart my-4">
        <div className="chart-wrap">
          <div id="chart">
            <ReactApexChart
              options={options}
              series={series}
              type="donut"
              width={"400px"}
            />
          </div>
        </div>
      </div>
    );
  };

export default CountryPieChart