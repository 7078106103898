import React from "react";

const Input = React.forwardRef(
  (
    {
      label,
      validator,
      submitted,
      mandatory,
      defaultValue = "",
      className = "",
      classNameWrap = "",
      formGroup = true,
      disabled = false,
      type = "text",
      required = false,
      width = "",
      height = "",
      append = "",
      prepend = "",
      background = "",
      name,
      value = "",
      borderColor = "#dee2e6",
      errorMassage,
      discount,
      customHeight,
      ...props
    },
    ref
  ) => {
    let hasWarning = submitted && validator && !validator.valid;

    return (
      <div
        className={` relative ${formGroup ? "form-group" : ""} ${
          hasWarning ? "has-warning" : ""
        } ${classNameWrap} ${append || prepend ? "input-group" : ""}`}
      >
        {label && (
          <label className="text-mainText">
            {label} {mandatory && <span className="text-danger">*</span>}
          </label>
        )}
        {prepend && (
          <div className="input-group-prepend">
            <span className="input-group-text">{prepend}</span>
          </div>
        )}
        <input
          type={type}
          value={value}
          defaultValue={defaultValue}
          style={{ height, width, backgroundColor: background, borderColor }}
          disabled={disabled}
          className={`form-control !text-mainText ${
            customHeight ? customHeight : "h-[37px]"
          }    ${className} ${
            submitted && validator && !validator.valid ? "is-invalid" : ""
          }`}
          ref={ref}
          {...props}
        />
        {discount && (
          <div className="absolute left-2 top-7">
            <span className="text-whiteYellow text-lg">%</span>
          </div>
        )}
        {append && (
          <div className="input-group-append">
            <span className="input-group-text">{append}</span>
          </div>
        )}
        {hasWarning && (
          <small className="invalid-feedback">{validator.message}</small>
        )}
        {hasWarning && (
          <small className="invalid-feedback">{validator.message}</small>
        )}
        {errorMassage && (
          <span className="text-xs text-red-700">{errorMassage}</span>
        )}
      </div>
    );
  }
);

export default Input;
