import React from "react";

const ExitIcon = () => {
  return (
    <svg
      width="26"
      height="24"
      viewBox="0 0 26 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.8182 24C5.291 24 0 18.6276 0 12C0 5.3724 5.291 3.38194e-06 11.8182 3.38194e-06C13.6531 -0.00139637 15.4631 0.43173 17.1044 1.26497C18.7456 2.09822 20.1729 3.30861 21.2727 4.8H18.07C16.7053 3.57811 15.0223 2.78203 13.223 2.50728C11.4238 2.23253 9.58462 2.49079 7.92632 3.25106C6.26802 4.01133 4.86099 5.24132 3.87408 6.79343C2.88717 8.34554 2.3623 10.1538 2.36245 12.0013C2.3626 13.8488 2.88777 15.657 3.87494 17.209C4.8621 18.7609 6.26933 19.9907 7.92776 20.7507C9.58618 21.5106 11.4254 21.7686 13.2246 21.4935C15.0238 21.2185 16.7067 20.4221 18.0712 19.2H21.2739C20.1739 20.6915 18.7465 21.902 17.105 22.7353C15.4636 23.5685 13.6533 24.0016 11.8182 24ZM20.0909 16.8V13.2H10.6364V10.8H20.0909V7.2L26 12L20.0909 16.8Z"
        fill="var(--main-text)"
      />
    </svg>
  );
};

export default ExitIcon;
