import React from "react";
import Personal from "./Personal";
import style from "../Chat.module.css";
const Message = ({ user, isSender, message, time }) => {
  return (
    <div
      className={
        isSender
          ? "my-3  sender flex justify-start gap-3"
          : "my-3 reciver  flex justify-end gap-3"
      }
    >
      {isSender ? (
        <>
          <div className="h-auto">
            <Personal user={user} />
          </div>
          <div
            className={`${style.userReciverMessage} py-2 px-[14.22px] min-w-[241.67px] min-h-[41.43px] bg-mainNavy `}
          >
            <p className="text-mainText text-xs break-words">{message}</p>
            <div className="flex justify-end">
              <span className="text-mainText text-xs py-1">{time}</span>
            </div>
          </div>
        </>
      ) : (
        <>
          <div
            className={`${style.userSenderMessage} py-2 px-[14.22px] min-w-[241.67px] min-h-[41.43px] bg-mainNavy `}
          >
            <p className="text-mainText text-xs break-words">{message}</p>
            <div className="flex justify-end">
              <span className="text-mainText text-xs py-1">{time}</span>
            </div>
          </div>

          <div className="h-auto">
            <Personal user={user} />
          </div>
        </>
      )}
    </div>
  );
};

export default Message;
