import React, { useContext, useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import Image from "../../../../Components/Image";
import LoadingPage from "../../../../Components/feedback/LoadingPage";
import { TechnicalSupportContext } from "./TechnicalSupportProvider";
import { CustomeInputFile } from "../../../../Components/FormUi";
import { Controller, useForm } from "react-hook-form";
import Select from "../../../../Components/FormHandler/Select";
import SuccessfullModal from "../../../../Components/Modals/SuccessfullModal";
import { InputLabel } from "../../PlanModel/components/InputLabel";
import { FormModal } from "../../PlanModel/components/FormModal";
import { FormControl } from "@mui/material";
import { CustErrorMessage } from "../../../../Components/Errors/CustErrorMessage";
import {
  useAddTechnicalSupportPurchase,
  useGetAllTechnicalSupportTermsOrder,
  useGetAllTechnicalSupportTermsOrderSubCategoryBuIDs,
  useUpdatePurchase,
} from "../../../../hooks/fetchers/TechnicalSupportPurcheseOrder";
import useSuccessfullModal from "../../../../Components/Modals/hooks/useSuccessfullModal";
import ShowFiles from "../../../../Components/ShowFiles";
import { useParams } from "react-router-dom";
import { CheckRole } from "../../../../helper/utils";

const TechnicalSupportOrderAdd = () => {
  const { setAddOrdersOpen, addOrdersOpen, updatedItem, setUpdatedItem } =
    useContext(TechnicalSupportContext);

  const validateFiles = (value) => {
    if (value.length > 5) {
      return "يمكنك تحميل ما يصل إلى 8 صور فقط";
    }
    return true;
  };
  const validateFiles2 = (value) => {
    if (value.length > 2) {
      return "يمكنك تحميل ما يصل إلى 8 صور فقط";
    }
    return true;
  };
  const {
    register,
    control,
    watch,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    description: updatedItem?.description,
    product: updatedItem?.product?.map((x) => ({
      value: x?._id,
      label: x?.name,
    })),
    offerType: updatedItem?.offerType?.map((x) => ({
      value: x?._id,
      label: x?.name,
    })),
  });
  const [category, setCategory] = useState(null);

  const { status } = useParams();

  const { data: categoryData = [] } = useGetAllTechnicalSupportTermsOrder();

  const { data: productData = [] } =
    useGetAllTechnicalSupportTermsOrderSubCategoryBuIDs(
      category?.map((x) => x?.value)
    );

  useEffect(() => {
    if (updatedItem) {
      reset({
        description: updatedItem?.description,
        product: updatedItem?.product?.map((x) => ({
          value: x?._id,
          label: x?.name,
        })),
        offerType: updatedItem?.offerType?.map((x) => ({
          value: x?._id,
          label: x?.name,
        })),
      });
    } else {
      setUpdatedItem(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedItem, reset]);

  const {
    showSuccess,
    showError,
    handleShowSuccess,
    handleShowError,
    handleCloseSuccess,
    handleCloseError,
  } = useSuccessfullModal();

  const { mutate, isLoading, isError, error } = useAddTechnicalSupportPurchase(
    handleShowSuccess,
    handleShowError
  );
  const { mutate: updateMutate, isLoading: updateLoading } = useUpdatePurchase(
    status,
    updatedItem?._id,
    handleShowSuccess,
    handleShowError
  );
  const [err, setError] = useState("");

  const onSubmit = (formData) => {
    // console.log(data);
    var newFormData = new FormData();

    newFormData.append("description", formData.description);
    formData.product?.forEach((item, index) => {
      newFormData.append(`product[${index}]`, item?.value);
    });

    formData.offerType?.forEach((item, index) => {
      newFormData.append(`offerType[${index}]`, item?.value);
    });

    if (formData.attachements) {
      for (let item of formData.attachements) {
        newFormData.append("attachements", item);
      }
    }

    if (formData.offerAttachements && !CheckRole("مدير")) {
      for (let item of formData.offerAttachements) {
        newFormData.append("offerAttachements", item);
      }
    }
    updatedItem
      ? updateMutate(newFormData, {
          onSuccess: () => {
            reset();
            setUpdatedItem(null);
          },
        })
      : mutate(newFormData, {
          onSuccess: () => {
            reset();
            setUpdatedItem(null);
          },
          onError: (err) => {
            setError(
              err?.response?.data?.validationErrors ||
                err?.response?.data?.message
            );
          },
        });
  };

  return (
    <>
      {isLoading || updateLoading ? (
        <LoadingPage />
      ) : (
        <AddTechnicalSupportWrapper
          show={addOrdersOpen}
          onHide={() => {
            setAddOrdersOpen(false);
            setUpdatedItem(null);
          }}
        >
          <Form
            onSubmit={handleSubmit(onSubmit)}
            encType="multipart/form-data"
            className="row my-4 date-input w-100 mx-auto p-3"
          >
            <FormModal title={"نوع الطلب"} className="my-4">
              <div className="grid grid-cols-1 gap-4 mb-3 w-1/2">
                <FormControl fullWidth>
                  <Controller
                    control={control}
                    name="offerType"
                    render={({ field }) => (
                      <Select
                        {...field}
                        async={false}
                        isMulti
                        options={categoryData?.map((x) => ({
                          value: x?._id,
                          label: x?.name,
                        }))}
                        onChange={(selected) => {
                          field.onChange(selected);
                          setCategory(selected);
                        }}
                      />
                    )}
                  />

                  <CustErrorMessage name={"offerType"} errors={errors} />
                </FormControl>
                <FormControl fullWidth>
                  <Controller
                    control={control}
                    name="product"
                    render={({ field }) => (
                      <Select
                        {...field}
                        async={false}
                        isMulti
                        options={productData?.map((x) => ({
                          value: x?._id,
                          label: x?.name,
                        }))}
                        onChange={(selected) => {
                          field.onChange(selected);
                        }}
                      />
                    )}
                  />
                  <CustErrorMessage name={"product"} errors={errors} />
                </FormControl>
              </div>
            </FormModal>
            <FormModal title={"وصف المهمه"}>
              <FormControl fullWidth className="mb-3">
                <InputLabel size={18} label={"وصف المهمه"} className={"mb-3"} />
                <textarea
                  className="w-full bg-mainNavy py-3 px-2 rounded-[7px] outline-none"
                  rows={5}
                  placeholder="ادخل وصف المهمه"
                  {...register("description", {
                    required: "يجب إدخال وصف المهمه",
                    minLength: {
                      value: 5,
                      message: "يجب أدخال 5 حروف على الأقل",
                    },
                    maxLength: {
                      value: 255,
                      message: "لا يجب أن تزيد عن 255 حرف",
                    },
                  })}
                ></textarea>
                {errors.description && (
                  <span className="text-red-500">
                    {errors.description.message}
                  </span>
                )}
              </FormControl>
              <div className="flex flex-col gap-2">
                <InputLabel size={18} label={"المرفقات"} className={"mb-3"} />
                <div className="flex gap-4 overflow-auto">
                  <CustomeInputFile
                    register={{
                      ...register("attachements", {
                        validate: validateFiles,
                      }),
                    }}
                    name="attachements"
                    multiple={true}
                    removePadding={true}
                  />
                  {updatedItem?.attachements?.length > 0 && (
                    <ShowFiles files={updatedItem?.attachements} />
                  )}
                </div>
              </div>
            </FormModal>
            {!CheckRole("مدير") && (
              <FormModal title={"عرض السعر"} className="mt-4">
                <div className="flex gap-4 overflow-auto">
                  <CustomeInputFile
                    register={{
                      ...register("offerAttachements", {
                        validate: validateFiles2,
                      }),
                    }}
                    name="offerAttachements"
                    multiple={true}
                    removePadding={true}
                  />
                  <CustErrorMessage
                    name={"offerAttachements"}
                    errors={errors}
                  />
                  {updatedItem?.offerAttachements?.length > 0 && (
                    <ShowFiles files={updatedItem?.offerAttachements} />
                  )}
                </div>
              </FormModal>
            )}
            <div className="d-flex justify-content-center">
              <button
                type="submit"
                className="mt-4 sumbmitAddUpdateUser border-0"
              >
                حفظ
              </button>
            </div>
          </Form>
        </AddTechnicalSupportWrapper>
      )}
      <SuccessfullModal
        handleClose={() => {
          handleCloseSuccess();
          setAddOrdersOpen(false);
        }}
        message={updatedItem ? "تمت التعديل بنجاح" : "تمت الاضافة بنجاح"}
        show={showSuccess}
      />
      <SuccessfullModal
        handleClose={handleCloseError}
        message={err}
        show={showError}
        status="error"
      />
    </>
  );
};

export default TechnicalSupportOrderAdd;

const AddTechnicalSupportWrapper = ({ children, show, onHide }) => {
  return (
    <Modal
      size="lg"
      show={show}
      onHide={onHide}
      aria-labelledby=" example-modal-sizes-title-lg"
      className="systemModal mettingModal bg-black/50"
      contentClassName="scroll p-0"
    >
      <Modal.Header className="w-full flex items-center justify-center">
        <div className="w-[57%] flex justify-between items-center  ">
          <p className="text-xl text-[#efaa20] text-center border !border-[#efaa20] p-2 rounded-md">
            طلب شراء
          </p>
          <Image
            src="/Rejected.svg"
            alt="close modal button"
            className="pointer"
            onClick={onHide}
          />
        </div>
      </Modal.Header>
      {children}
    </Modal>
  );
};
