import React from "react";
import { Sidebar } from "react-pro-sidebar";
import { useSelector } from "react-redux";
import { CustomNav } from "../../../PlanModel/components/CustomNav";
import { CheckRole } from "../../../../../helper/utils";
import { useTaskContext } from "../../../PlanModel/context/TaskContext";
import { useProjectContext } from "../../../PlanModel/context/ProjectContext";
import { Accordion } from "react-bootstrap";
import { roles } from "../../../../../helper/fetchers/Tasks";
function TasksSidebar() {
  const { setFilterPlansParams, setSelectedTab: setSelectedPlanTab } =
    useProjectContext();
  const { setFilterTasksParams, setSelectedTab } = useTaskContext();
  const tasksMenuCollapsed = useSelector(
    ({ tasksMenuCollapsed }) => tasksMenuCollapsed
  );
  // const { pathname } = useLocation();
  // console.log(pathname);
  return (
    <Sidebar
      backgroundColor="transparent"
      rtl={true}
      rootStyles={{
        height: "100%",
        border: "none !important",
      }}
      collapsed={tasksMenuCollapsed}
      collapsedWidth={0}
    >
      <div className="p-2 bg-mainItems rounded-2xl h-full">
        <Accordion defaultActiveKey={null}>
          {!(
            CheckRole(roles["user"]) ||
            CheckRole(roles["administrator"]) ||
            CheckRole(roles["auditor"])
          ) && (
            <CustomNav
              title={"المشاريع"}
              path={"/System/tasks/plans"}
              items={[
                {
                  title: "كل المشاريع",
                  onClick: () => {
                    setFilterPlansParams({});
                    setSelectedPlanTab("كل المشاريع");
                  },
                },
                {
                  title: "مشاريع قيد التنفيذ",
                  onClick: () => {
                    setFilterPlansParams({ status: "1" });
                    setSelectedPlanTab("مشاريع قيد التنفيذ");
                  },
                },
                {
                  title: "مشاريع معلقه",
                  onClick: () => {
                    setFilterPlansParams({ status: "0" });
                    setSelectedPlanTab("مشاريع معلقه");
                  },
                },
                {
                  title: "مشاريع منتهيه",
                  onClick: () => {
                    setFilterPlansParams({ status: "2" });
                    setSelectedPlanTab("مشاريع منتهيه");
                  },
                },
              ]}
            />
          )}

          <CustomNav
            title={"المهام"}
            path={"/System/tasks/tasks"}
            items={[
              {
                title: "كل المهام",
                onClick: () => {
                  setFilterTasksParams({});
                  setSelectedTab("كل المهام");
                },
              },
              {
                title: "مهام قيد التنفيذ",
                onClick: () => {
                  setFilterTasksParams({ status: 1 });
                  setSelectedTab("مهام قيد التنفيذ");
                },
              },
              {
                title: "مهام معلقه",
                onClick: () => {
                  setFilterTasksParams({ status: 0 });
                  setSelectedTab("مهام معلقه");
                },
              },
              {
                title: "فى انتظار الموافقة الأولية",
                onClick: () => {
                  setFilterTasksParams({ status: 2, approvement: 0 });
                  setSelectedTab("فى انتظار الموافقة الأولية");
                },
              },
              {
                title: "فى انتظار الموافقة الثانية",
                onClick: () => {
                  setFilterTasksParams({ status: 2, approvement: 1 });
                  setSelectedTab("فى انتظار الموافقة الثانية");
                },
              },
              {
                title: "فى انتظار الموافقة النهائية",
                onClick: () => {
                  setFilterTasksParams({ status: 2, approvement: 2 });
                  setSelectedTab("فى انتظار الموافقة النهائية");
                },
              },
              {
                title: "مكتملة",
                onClick: () => {
                  setFilterTasksParams({ status: 2, approvement: 3 });
                  setSelectedTab("مكتملة");
                },
              },
            ]}
          />
          {(CheckRole(roles["auditor"]) || CheckRole(roles["manager"])) && (
            <CustomNav
              title={"المهام الخارجيه"}
              path={"/System/tasks/external-tasks"}
              items={[]}
            />
          )}
        </Accordion>
      </div>
    </Sidebar>
  );
}

export default TasksSidebar;
