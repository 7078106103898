import React, { useState, useContext } from "react";
import { Link, Outlet, useParams } from "react-router-dom";
import { Accordion } from "react-bootstrap";
import { MdKeyboardArrowDown } from "react-icons/md";
import { IoIosArrowDown } from "react-icons/io";

import { AllCategories } from "../../../../Components/System/AllCategories/AllCategories";
import SystemControler from "../../../../Components/System/SystemControler/SystemControler";
import BackButton from "../../../../Components/BackButton";
import AccountantDropdown from "./AccountantDropdown";

import { addAccountType } from "../../../../Context/AddAccountaing";

import style from "./AccountaingIndex.module.css";
import "./accountaingStyle.css";

const AccountaingIndex = () => {
  const { setOpenAddAccountant, setOpenDisplayDetails } =
    useContext(addAccountType);

  const [active, setActive] = useState(null);
  const [open, setOpen] = useState(false);

  const [RevenuesCategory, setRevenuesCategories] = useState({
    FinancialClaims: false,
    Invoice: false,
  });

  const [ExpensesCategory, setExpensesCategories] = useState({
    ExpensesReports: false,
    Items: false,
    Egypt: false,
    SaudiArabia: false,
  });

  const handleOpenAddAccountant = () => setOpenAddAccountant(true);
  const handleItemsClick = () => {
    setExpensesCategories({
      ...ExpensesCategory,
      ExpensesReports: false,
      Items: true,
    });
    setOpenDisplayDetails(false);
  };
  const handleItemsReportClick = () => {
    setOpenAddAccountant(false);
    setOpenDisplayDetails(false);
  };
  const renderCategoryLink = (path, label, condition, conditionStyles) => (
    <Link to={path}>
      <div
        onClick={condition}
        className={`tab text-end w-full border pointer flex justify-between ${
          conditionStyles
            ? " !border-[#EFAA20] rounded-md hover:!border-transparent"
            : "!border-transparent"
        }`}
      >
        <span>{label}</span>
      </div>
    </Link>
  );

  return (
    <>
      <SystemControler
        child={
          <div className="flex items-center gap-2 relative">
            <BackButton />
            {
              <div
                className="add-user-button cursor-pointer customButton"
                onClick={handleOpenAddAccountant}
              >
                إضافة جديدة
              </div>
            }
            <button
              className="px-8 py-2 rounded-xl bg-mainNavy text-mainText flex gap-2 items-center justify-between xl:hidden"
              onClick={() => setOpen(!open)}
            >
              كل الخزينة
              <IoIosArrowDown className="text-mainText text-xl" />
            </button>

            {open && (
              <AccountantDropdown
                active={active}
                setActive={setActive}
                RevenuesCategory={RevenuesCategory}
                setRevenuesCategories={setRevenuesCategories}
                ExpensesCategory={ExpensesCategory}
                setExpensesCategories={setExpensesCategories}
                setOpen={setOpen}
              />
            )}
          </div>
        }
      />
      <div className="grid grid-cols-12 gap-2 h-full">
        <div className="hidden xl:flex col-span-3">
          <AllCategories
            child={
              <div>
                <Link
                  onClick={() => setActive(null)}
                  to="/System/Accounating/index"
                >
                  <h2 className="mx-2 text-mainText my-3">الخزينة</h2>
                </Link>
                <Accordion defaultActiveKey={null}>
                  <Accordion.Item eventKey="0">
                    <Link className="w-100" to={"/System/Accounating/Revenues"}>
                      <Accordion.Header
                        onClick={() => setActive(0)}
                        className={`w-full border-2 pointer flex justify-between ${
                          active === 0
                            ? "!border-[#EFAA20] rounded-md hover:!border-transparent"
                            : "border-2 border-mainBorder rounded-md rounded-b-lg"
                        }`}
                      >
                        <p>الايرادات</p>
                        <MdKeyboardArrowDown size={20} />
                      </Accordion.Header>
                    </Link>
                    <Accordion.Body>
                      <div className="tabs d-flex justify-content-center align-items-center flex-column">
                        {renderCategoryLink(
                          "System/Accounating/Revenues/FinancialClaims",
                          "المطالبة مالية",
                          () =>
                            setRevenuesCategories({
                              FinancialClaims: true,
                              Invoice: false,
                            })
                        )}
                        {renderCategoryLink(
                          "System/Accounating/Revenues/Invoice",
                          "الفواتير",
                          () =>
                            setRevenuesCategories({
                              FinancialClaims: false,
                              Invoice: true,
                            })
                        )}
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="1">
                    <Accordion.Header
                      onClick={() => setActive(1)}
                      className={`w-full border-2 pointer flex justify-between ${
                        active === 1
                          ? "!border-[#EFAA20] rounded-md hover:!border-transparent"
                          : "border-2 border-mainBorder rounded-md rounded-b-lg"
                      }`}
                    >
                      <span>المصروفات</span>
                      <MdKeyboardArrowDown size={20} />
                    </Accordion.Header>
                    <Accordion.Body>
                      <Accordion>
                        <Accordion.Item eventKey="3">
                          <Accordion.Header
                            onClick={() => {
                              setActive(3);
                              setOpenAddAccountant(false);
                              setOpenDisplayDetails(false);
                            }}
                            className={`w-full border-2 pointer flex justify-between ${
                              active === 3
                                ? "!border-[#EFAA20] rounded-md hover:!border-transparent"
                                : "border-2 border-mainBorder rounded-md rounded-b-lg"
                            }`}
                          >
                            <Link
                              className="w-full flex justify-between"
                              to="System/Accounating/Expenses/saudi"
                            >
                              <span>السعودية</span>
                              <MdKeyboardArrowDown size={20} />
                            </Link>
                          </Accordion.Header>
                          <Accordion.Body>
                            <div className="tabs d-flex justify-content-center align-items-center flex-column">
                              {renderCategoryLink(
                                "System/Accounating/Expenses/saudi/0",
                                "مصروفات معلقه",
                                () => handleItemsReportClick()
                              )}
                              {renderCategoryLink(
                                "System/Accounating/Expenses/saudi/4",
                                "مصروفات مقبوله",
                                () => handleItemsReportClick()
                              )}
                              {renderCategoryLink(
                                "System/Accounating/Expenses/saudi/5",
                                "مصروفات مرفوضه",
                                () => handleItemsReportClick()
                              )}
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                          <Accordion.Header
                            onClick={() => {
                              setActive(2);
                              setOpenAddAccountant(false);
                              setOpenDisplayDetails(false);
                            }}
                            className={`w-full border-2 pointer flex justify-between ${
                              active === 2
                                ? "!border-[#EFAA20] rounded-md hover:!border-transparent"
                                : "border-2 border-mainBorder rounded-md rounded-b-lg"
                            }`}
                          >
                            <Link
                              className="w-full flex justify-between"
                              to="System/Accounating/Expenses/Egypt"
                            >
                              <span>مصر</span>
                              <MdKeyboardArrowDown size={20} />
                            </Link>
                          </Accordion.Header>
                          <Accordion.Body>
                            <div className="tabs d-flex justify-content-center align-items-center flex-column">
                              {renderCategoryLink(
                                "System/Accounating/Expenses/Egypt/0",
                                "مصروفات معلقه",
                                () => handleItemsReportClick()
                              )}
                              {renderCategoryLink(
                                "System/Accounating/Expenses/Egypt/4",
                                "مصروفات مقبوله",
                                () => handleItemsReportClick()
                              )}
                              {renderCategoryLink(
                                "System/Accounating/Expenses/Egypt/5",
                                "مصروفات مرفوضه",
                                () => handleItemsReportClick()
                              )}
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                      {renderCategoryLink(
                        "System/Accounating/Expenses/Items",
                        "الاصناف",
                        () => handleItemsClick(),
                        active === 1 && ExpensesCategory.Items
                      )}
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            }
          />
        </div>
        <div className="xl:col-span-9 col-span-12">
          <div className={`${style.AccountaingIndexbg} yellow-scroll`}>
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
};

export default AccountaingIndex;
