import { useState } from "react";

const useModal = () => {
  const [open, setOpen] = useState(false);
  const handleOpenModal = () => {
    setOpen(true);
  };
  const handleCloseModal = () => {
    setOpen(false);
  };
  const handleToggleModal = () => {
    setOpen(!open);
  };
  return { open, handleOpenModal, handleCloseModal, handleToggleModal };
};

export default useModal;
