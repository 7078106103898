import React, { useContext } from "react";
import InsideClientPieChart from "../../../../Components/System/Clients/InsideClientChart/InsideClientPieChart";
import InsideClientLineChart from "../../../../Components/System/Clients/InsideClientChart/InsideClientLineChart";
import { AllClientContext } from "../components/PageWrapperAllCLients";
import { Spinner } from "react-bootstrap";

function InsideClientsChart() {
  const { data, isLoading } = useContext(AllClientContext);
  const content = (
    <div className="col-span-8 category-client-Component">
      <div className="insideChart p-4 h-100 flex items-center flex-col gap-4">
        <div className="AllRequestsPieChartContainer w-100  d-flex flex-column align-items-center justify-content-center">
          <p className="text-mainText" style={{ fontSize: "24px" }}>
            الداخلي
          </p>
          <InsideClientPieChart total={data?.Total} />
        </div>
        <div className="AllRequestsLineChartContainer w-100  d-flex align-items-center justify-content-center">
          <InsideClientLineChart total={data?.Total} />
        </div>
      </div>
    </div>
  );
  if (isLoading) {
    return (
      <div className="w-full h-full col-span-8 flex items-center justify-center">
        <Spinner className="text-[#EFAA20]" />
      </div>
    );
  }
  return content;
}

export default InsideClientsChart;
