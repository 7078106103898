import React, { useState } from "react";
import AuthWrapper from "../components/AuthWrapper";
import AuthCard from "../components/AuthCard";
import { Form } from "react-bootstrap";
import Input from "../../../../Components/FormHandler/Input";
import { Link, useNavigate } from "react-router-dom";

import Image from "../../../../Components/Image";
import SubmitButton from "../components/SubmitButton";
import { useForm } from "react-hook-form";
import useSuccessfullModal from "../../../../Components/Modals/hooks/useSuccessfullModal";
import { useResetPassword } from "../../../../hooks/fetchers/auth";
import LoadingPage from "../../../../Components/feedback/LoadingPage";
import { CustErrorMessage } from "../../../../Components/Errors/CustErrorMessage";
import Cookies from "js-cookie";
import SuccessfullModal from "../../../../Components/Modals/SuccessfullModal";

const ForgetPassword = () => {
  const {
    showSuccess,
    showError,
    handleShowSuccess,
    handleShowError,
    handleCloseSuccess,
    handleCloseError,
  } = useSuccessfullModal();
  const [err, setError] = useState("");
  const navigate = useNavigate();
  const { mutate, isLoading } = useResetPassword(handleShowSuccess, (error) => {
    handleShowError();
    setError(error?.response?.data?.message);
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    var formData = new FormData();
    formData.append("email", data?.email);
    Cookies.set("forgotPasswordEmail", data?.email);
    mutate(formData);
    // console.log(data);
    // navigate("/new-password");
  };

  return isLoading ? (
    <LoadingPage />
  ) : (
    <AuthWrapper>
      <SuccessfullModal
        handleClose={handleCloseError}
        message={err}
        show={showError}
        status="error"
      />
      <SuccessfullModal
        handleClose={() => {
          handleCloseSuccess();
          navigate("/otp");
        }}
        message={"تم ارسال رمز التحقق الي بريدك الالكتروني"}
        show={showSuccess}
      />
      <AuthCard>
        <Form className="w-75 h-100" onSubmit={handleSubmit(onSubmit)}>
          <div className="w-full">
            <Image
              src="https://bsa2011.com/images/systemLogin.gif"
              // style={{ width: "279px", height: "214px" }}
              alt="logo image"
              width={250}
              height={150}
              className={"pointer-events-none"}
            />
          </div>
          <div className="w-full">
            <Image
              src="/locker.png"
              // style={{ width: "379px", height: "214px" }}
              alt="logo image"
              className={"pointer-events-none"}
              width={150}
              height={150}
            />
          </div>
          <div className="mb-3 space-y-1">
            <h2 className="font-medium text-4xl">هل نسيت كلمة المرور ؟</h2>
            <p className="font-normal text-base">
              إدخل بريدك الإلكتروني واحصل على“الرمز التحقيقي” للتحقق.
            </p>
          </div>

          <Form.Group>
            <Form.Label className="text-black">البريد الالكتروني</Form.Label>
            <input
              {...register("email", {
                required: "يجب إدخال البريد الالكتروني",
                pattern: {
                  value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                  message: "يجب إدخال بريد الكتروني صحيح",
                },
              })}
              className="form-control  text-black placeholder:!text-black bg-transparent mb-4 h-[48.99px] border !border-[#94713E] hover:!border-[#94713E]  focus:!border-[#94713E]  focus:bg-transparent active:bg-transparent"
              placeholder="ادخل البريد الالكتروني "
              autoComplete="username"
            />
            <CustErrorMessage name={"email"} errors={errors} />
          </Form.Group>

          <div className="d-flex justify-content-center align-items-center mt-5">
            {/* <Link to="/otp"> */}
            <SubmitButton>
              {"احصل على الرمز التحقيقي"}
              {/* {isLoading ? <Progress isSmall /> : " الدخول الي الحساب"} */}
            </SubmitButton>
            {/* </Link> */}
          </div>
        </Form>
      </AuthCard>
    </AuthWrapper>
  );
};

export default ForgetPassword;
