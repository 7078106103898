import Table from "react-bootstrap/Table";
import { TableHeader } from "./TableHeader";
import { TableRow } from "./TableRow";
import { TableHead } from "./TableHead";
import { Pagination, PaginationItem } from "@mui/material";
import { useState } from "react";

export default function CustomTable({
  columns,
  data,
  children,
  TableHeaderProps,
  TableRowProps,
  TableHeadProps,
  paginationProps = {}, // Provide a default empty object
  paginationClassName,
  itemsPerPage = 10, // Default to 10 items per page
  ...props
}) {
  const [currentPage, setCurrentPage] = useState(1);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const { count = 0, page, onChange } = paginationProps;

  const paginatedChildren =
    children && children?.length > 0 && children?.slice(startIndex, endIndex);

  return (
    <>
      <Table bordered hover responsive {...props}>
        <TableHeader
          className="bg-mainNavy border !border-[#efaa207f]"
          {...TableHeaderProps}
        >
          <TableRow {...TableRowProps}>
            {columns?.map(({ name }, index) => (
              <TableHead
                key={index}
                className="text-end text-[11px] border-none !font-semibold p-2 !text-[#EFAA20] "
                {...TableHeadProps}
              >
                {name}
              </TableHead>
            ))}
          </TableRow>
        </TableHeader>
        <tbody>
          {paginatedChildren && paginatedChildren?.map((item) => item)}
        </tbody>
      </Table>
      {data && data?.length === 0 ? (
        <div className="text-center">
          <p className="w-full text-center text-xl border-none min-w-[50px] p-2 text-mainCustomGray">
            لا يوجد بيانات لعرضها
          </p>
        </div>
      ) : null}

      {count > 1 && (
        <div className="flex justify-center" dir="ltr">
          <Pagination
            variant="outlined"
            shape="rounded"
            color="primary"
            count={count}
            page={page}
            onChange={onChange}
            {...paginationProps}
            renderItem={(item) => (
              <PaginationItem
                className={paginationClassName}
                sx={{
                  bgcolor: "#EFAA20",
                  "&:hover" : {
                    bgcolor: '#efaa20c5',
                  },
                  '&.Mui-selected': {
                    bgcolor: "transparent",
                    color: '#EFAA20',
                    borderColor: '#EFAA20',
                    "&:hover" : {
                    bgcolor: 'transparent',
                    color: '#EFAA20'
                  },
                  }
                }}
                {...item}
              />
            )}
          />
        </div>
      )}
    </>
  );
}
