import React from "react";
import Cookies from "js-cookie";
import { Navigate } from "react-router-dom";

const ProtectedAuth = ({ children }) => {
  const user = Cookies.get("accessToken") ? Cookies.get("accessToken") : null;
  return user ? <Navigate to={"/System/index"} replace={true} /> : children;
};

export default ProtectedAuth;
