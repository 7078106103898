import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import SaveButton from "../../../Components/SaveButton";
import { PdfIcon } from "../../../utiltis/Icons";
import ShowFiles from "../../../Components/ShowFiles";
// import CloseBtn from "/assets/icons/Rejected.svg"
function ViewModel({ title, show, handleClose, data }) {
  const [content, setContent] = useState(null);
  const {
    showImage,
    handleClose: handleCloseImage,
    handleShow,
  } = useOpenModal();
  // console.log("data image:",data);
  return (
    <>
      <Example
        show={showImage}
        handleClose={handleCloseImage}
        content={content}
      />
      <Modal
        centered
        contentClassName=" border !border-[#EFAA20] !max-w-[700px] overflow-hidden !rounded-[20px] text-mainText"
        show={show}
        onHide={handleClose}
        size="lg"
      >
        <Modal.Header className="bg-mainNavy !rounded-[0px] !justify-between border-none w-full">
          <div className=""></div>
          <Modal.Title className="text-[#EFAA20] text-xl font-semibold border !border-[#EFAA20] py-2 px-7 rounded-md ">
            {title}
          </Modal.Title>
          <Button
            className="p-0 bg-transparent hover:bg-transparent"
            onClick={handleClose}
          >
            <img src="/Rejected.svg" alt="" />
          </Button>
        </Modal.Header>
        <Modal.Body className="justify-start bg-mainItems w-full">
          <div className="w-full text-start">
            <p className="text-end py-3 px-2 font-semibold rounded-[7px] outline-none">
              اسم البند :
              <span className="text-mainCustomGray">{data?.name}</span>
            </p>
            <p className="text-end py-3 px-2 font-semibold rounded-[7px] outline-none">
              الوصف
            </p>
            <div className="min-h-[200px] border !border-[#EFAA20] rounded-md">
              {/* <textarea
                className="bg-transparent w-full px-2 py-3"
                // placeholder="وصف البند "
                value={data?.description}
              ></textarea> */}
              <p className="text-right bg-transparent w-full px-2 py-3 text-mainText">
                {data?.descraption}
              </p>
            </div>
            <p className="text-end py-3 px-2 font-semibold rounded-[7px] outline-none">
              المرفقات
            </p>

            <div className="!justify-start p-3 border !border-[#EFAA20] rounded-md">
              <ShowFiles files={data?.image} />
              {/* {data?.image?.map((file, index) => {
                const fileType = getType(file);
                switch (fileType) {
                  case "jpg":
                  case "jpeg":
                  case "png":
                  case "gif":
                  case "jfif":
                    return (
                      <div
                        key={index}
                        onClick={() => {
                          setContent(
                            <>
                              <DownloadButton file={file} />

                              <img
                                className="w-full  rounded-xl border"
                                src={file}
                                alt=""
                              />
                            </>
                          );
                          handleShow();
                        }}
                      >
                        <img
                          className="w-20 h-20 rounded-xl border"
                          src={file}
                          alt=""
                        />
                      </div>
                    );
                  case "pdf":
                    return (
                      <div
                        key={index}
                        onClick={() => {
                          setContent(
                            <>
                              <DownloadButton file={file} />
                              <PdfIcon />
                            </>
                          );
                          handleShow();
                        }}
                        className="flex justify-center items-center w-24 h-24 rounded-xl border"
                      >
                        <PdfIcon />
                      </div>
                    );
                  case "zip":
                    return (
                      <div
                        key={index}
                        onClick={() => {
                          setContent(
                            <>
                              <DownloadButton file={file} />
                              <img
                                className="w-full rounded-xl border"
                                src="https://p7.hiclipart.com/preview/258/300/47/computer-icons-zip-truetype-icon-png-file-zip.jpg"
                                alt=""
                              />
                            </>
                          );
                          handleShow();
                        }}
                      >
                        <img
                          className="w-20 h-20 rounded-xl border"
                          src="https://p7.hiclipart.com/preview/258/300/47/computer-icons-zip-truetype-icon-png-file-zip.jpg"
                          alt=""
                        />
                      </div>
                    );
                  case "rar":
                    return (
                      <div
                        key={index}
                        onClick={() => {
                          setContent(
                            <>
                              <DownloadButton file={file} />
                              <img
                                className="w-full  rounded-xl border"
                                src="https://cdn-icons-png.freepik.com/512/9158/9158683.png"
                                alt=""
                              />
                            </>
                          );
                          handleShow();
                        }}
                      >
                        <img
                          className="w-20 h-20 rounded-xl border"
                          src="https://cdn-icons-png.freepik.com/512/9158/9158683.png"
                          alt=""
                        />
                      </div>
                    );
                  default:
                    return null;
                }
              })} */}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-none justify-center gap-2 w-full">
          {/* <SaveButton onClick={handleSave} /> */}
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ViewModel;

const getType = (fileName) => {
  const fileExtension = fileName.split(".").pop().toLowerCase();
  return fileExtension;
};

const useOpenModal = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return {
    showImage: show,
    handleClose,
    handleShow,
  };
};

function Example({ show, handleClose, content }) {
  return (
    <Modal
      centered
      show={show}
      onHide={handleClose}
      contentClassName="p-0 bg-mainNavy w-fit mx-auto"
      className="bg-black/50"
    >
      <Modal.Body className="flex flex-col items-center">{content}</Modal.Body>
    </Modal>
  );
}
const DownloadButton = ({ file }) => {
  return (
    <Button
      className="w-fit mx-auto text-mainText"
      download={true}
      variant="success"
      href={file}
    >
      {"تحميل"}
      {/* <FaFileDownload fontSize={25} color="#EFAA20" /> */}
    </Button>
  );
};
