import React from "react";
import { TimePicker, renderTimeViewClock } from "@mui/x-date-pickers";
import { useController } from "react-hook-form";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import enUS from "date-fns/locale/en-US";
export function CustomTimePicker({ control, name, rules }) {
  const {
    field,
    fieldState: { invalid, isTouched, isDirty },
    formState: { touchedFields, dirtyFields },
  } = useController({
    name,
    control,
    rules: rules,
  });

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enUS}>
      <TimePicker
        onChange={(e)=>{
          field.onChange(e)
          console.log(e);
        }}
        onBlur={field.onBlur}
        value={field.value}
        name={field.name}
        inputRef={field.ref}
        InputLabelProps={{}}
        inputFormat="DD-MM-YYYY"
        outputFormat="DD-MM-YYYY"
        viewRenderers={{
          hours: renderTimeViewClock,
          minutes: renderTimeViewClock,
          seconds: renderTimeViewClock,
        }}
        slotProps={{
          openPickerButton: {
            sx: { color: "var(--main-text)" },
          },
        }}
        InputProps={{
          sx: {
            bgcolor: "var(--main-navy)",
            color: "var(--main-text)",
          },
        }}
        sx={{
          "& fieldset": {
            display: "none",
          },
          "& input": {
            p: "0.7rem",
            color: "var(--main-text)",
          },

          bgcolor: "var(--main-navy)",
          color: "var(--main-text)",
          borderRadius: "7px",
        }}
      />
    </LocalizationProvider>
  );
}
