// const development_domain_server = "http://26.27.237.200:5000/api/v1";
const development_domain_server = "https://demo.bsa2011.com/api/v1";
const production_domain_server = "https://bsa2011.com/api/v1";
// export const staticImageSrc = "https://bsa2011.com";
export const defaultImage = "/People/person.png";

// const development_domain_server = "https://bsa2011.com/api/v1";
// const development_domain_server = "http://10.10.0.232:5000/api/v1";
export const staticImageSrc = "http://10.10.0.45:5000";
const development = {
  apiGateway: {
    URL: development_domain_server,
  },
};

const production = {
  apiGateway: {
    URL: production_domain_server,
  },
};

const config =
  process.env.NODE_ENV === "development" ? development : production;
// console.log("API URL", config.apiGateway.URL);
export default config;
