import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { useState } from "react";
import { useController } from "react-hook-form";

export function CustomInput({
  control,
  name,
  rules,
  discount,
  isPassword = false,
  ...props
}) {
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const {
    field,
    fieldState: { invalid, isTouched, isDirty },
    formState: { touchedFields, dirtyFields },
  } = useController({
    name,
    control,
    rules: rules,
  });

  let inputType;

  if (isPassword) {
    inputType = showPassword ? "text" : "password";
  } else {
    inputType = props.type;
  }
  return (
    <TextField
      onChange={field.onChange} // send value to hook form
      onBlur={field.onBlur} // notify when input is touched/blur
      value={field.value || ""} // input value
      name={field.name} // send down the input name
      inputRef={field.ref} // send input ref, so we can focus on input when error appear
      InputLabelProps={{}}
      InputProps={{
        sx: {
          bgcolor: "var(--main-navy)",
          color: "var(--main-text)",
          // p: "2rem"
        },
        endAdornment: isPassword ? (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
            >
              {showPassword ? (
                <Visibility className="text-mainCustomGray" />
              ) : (
                <VisibilityOff className="text-mainCustomGray" />
              )}
            </IconButton>
          </InputAdornment>
        ) : discount ? (
          <p className="text-whiteYellow ml-4">%</p>
        ) : null,
      }}
      sx={{
        "& fieldset": {
          display: "none",
        },
        "& input": {
          p: "0.70rem",
        },
        bgcolor: "var(--main-navy)",
        color: "white",
        borderRadius: "7px",
        // py: "0px"
      }}
      {...props}
      type={inputType}
    />
  );
}
