import { Modal } from "react-bootstrap";

export const ModalHeader = ({ title }) => {
    return (
      <Modal.Header className="border-none" closeButton>
        <Modal.Title className="mx-auto border !border-[#EFAA20] rounded-[5px] py-2 px-6">
          <p className="text-[#EFAA20] font-semibold text-xl">{title}</p>
        </Modal.Title>
      </Modal.Header>
    );
  };
