import React from "react";

export default function DownloadButton({ children, onClick }) {
  return (
    <button
      className="text-xs h-[25px] font-medium bg-buttonGray text-mainText py-1 px-3 rounded-[3px]"
      onClick={onClick}
    >
      {children}
    </button>
  );
}
