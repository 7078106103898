import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import Progress from "../../../../Progress";

const DesignCasesChart = ({ projectType, color, stats, design }) => {
  console.log("stats", stats);
  const [chartOptions, setChartOptions] = useState({
    chart: {
      width: 380,
      type: "donut",
    },
    labels: [projectType, "كل الطلبات"],
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            total: {
              show: true,
              label: "  الطلبات",
              color: "var(--main-text)",
              formatter: (val) => {
                return val?.config?.series.reduce((acc, curr) => acc + curr, 0);
              },
            },
          },
        },
      },
    },
    colors: [color, "#151A20"],
    stroke: {
      show: false, // Set this to false to remove the border of the donut segments
    },
    dataLabels: {
      enabled: false,
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            show: false,
          },
        },
      },
    ],
    legend: {
      position: "left",
      offsetY: 0,
      height: 230,
    },
  });

  useEffect(() => {
    setChartOptions((prevOptions) => ({
      ...prevOptions,
      colors: [color, "#151A20"],
    }));
  }, [color]);

  const getTotalCount = () => {
    if (design) {
      if (projectType === "طلبات قيد التنفيذ") {
        return parseInt(stats?.statistics?.DESIGN?.IN_PROGRESS);
      } else if (projectType === "طلبات فى انتظار الموافقة") {
        return parseInt(stats?.statistics?.DESIGN?.PENDING);
      } else if (projectType === "طلبات تم تحويلها الي مشاريع") {
        return parseInt(stats?.statistics?.DESIGN?.TO_PROJECT);
      } else {
        return parseInt(stats?.statistics?.DESIGN?.REJECTED);
      }
    } else {
      if (projectType === "طلبات قيد التنفيذ") {
        return parseInt(stats?.statistics?.SUPERVISING?.IN_PROGRESS);
      } else if (projectType === "طلبات فى انتظار الموافقة") {
        return parseInt(stats?.statistics?.SUPERVISING?.PENDING);
      } else if (projectType === "طلبات تم تحويلها الي مشاريع") {
        return parseInt(stats?.statistics?.SUPERVISING?.TO_PROJECT);
      }else {
        return parseInt(stats?.statistics?.SUPERVISING?.REJECTED);
      }
    }
  };
  const total = design
    ? stats?.statistics?.DESIGN?.TOTAL_COUNT
    : stats?.statistics?.SUPERVISING?.TOTAL_COUNT;
  return (
    <div>
      <div className="chart-wrap">
        <div id="chart">
          {stats ? (
            <ReactApexChart
              options={chartOptions}
              series={[getTotalCount()]}
              type="donut"
              width={400}
              height={250}
            />
          ) : (
            <Progress />
          )}
        </div>
      </div>
    </div>
  );
};

export default DesignCasesChart;
