import React from "react";

const AcceptButton = ({ onClick }) => {
  return (
    <button
      onClick={onClick}
      className="bg-[#EFAA20] text-white transition-colors hover:bg-[#EFAA20]  hover:!text-mainText border !border-[#EFAA20] py-1 px-5 rounded-[6px]"
    >
      موافق
    </button>
  );
};

export default AcceptButton;
