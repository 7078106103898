import React from "react";
import MonthlyApexChart from "../ProfessinollInformation/MonthlyApexChart.js";
import { ProgressBar } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useState } from "react";
import DataTableComponent from "../../../DataTableComponent.jsx";
import Pdf from "../../../Pdf.jsx";
import { staticImageSrc } from "../../../../Config/Config.jsx";
import PdfImage from "../../../PdfImage.jsx";
import { FormModal } from "../../../../Pages/System/PlanModel/components/FormModal.jsx";

const PersonalAttachments = ({ user }) => {
  const [Montlyhwork, setMontlyhwork] = useState(null);
  const [projectType, setProjectType] = useState("مشاريع منتهية");

  const [openPdf, setOpenPdf] = useState(false);
  const handlePdfDownload = (pdfSrc) => {
    const anchor = document.createElement("a");
    anchor.href = pdfSrc;
    anchor.target = "_blank";
    anchor.download = "project_offer.pdf";

    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  };
  // console.log(user, "user user use");
  return (
    <div className="ProfessinollInformation ">
      <FormModal title={"المرفقات"} className=" p-3 mt-2">
        <div className="d-flex flex-row overflow-x-auto w-full gap-2 search-users ">
          {user?.fileUser?.length ? (
            user?.fileUser?.map((item, i) => {
              if (item.endsWith(".pdf")) {
                return (
                  <div key={i}>
                    <div className="">
                      <div
                        onClick={() => handlePdfDownload(`${item}`)}
                        className="cursor-pointer"
                      >
                        <PdfImage
                          // openPdf={openPdf}
                          className="bg-mainNavy"
                          setOpenPdf={setOpenPdf}
                          width="93px"
                          height="61px"
                          // pdfSrc={`${item}`}
                        />
                      </div>
                    </div>
                  </div>
                );
              } else {
                return (
                  <img
                    src={`${item}`}
                    alt={item}
                    key={i}
                    className="w-32 h-24 object-contain"
                  />
                );
              }
            })
          ) : (
            <div className="w-full">
              <p className="text-xl text-mainText text-center">
                لا يوجد مرفقات
              </p>
            </div>
          )}
        </div>
      </FormModal>
    </div>
  );
};

export default PersonalAttachments;
