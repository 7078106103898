import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    messages : []
}

const chatSlice = createSlice({
    name: "chat",
    initialState,
    reducers: {
        clearChat(state){
            state.messages = []
        },
        appendMessage(state,action){
            state.messages = [...state.messages,action.payload]
        },
        assignMessage(state,action){
            state.messages = action.payload
        },
        
    }
})
export const {assignMessage,clearChat,appendMessage} = chatSlice.actions;
export default chatSlice.reducer