import axios from "axios";
import myAxiosInstance from "../https";
import config from "../../Config/Config";
import Cookies from "js-cookie";
import { toast } from "react-toastify";

export const getAllExpensesItems = (params) => myAxiosInstance("batches",{params}).catch(error=>{
  toast.error(error?.response?.data?.message)
});
export const getExpensesItem = (id) => myAxiosInstance("batches").then(({data}) => data?.batches).then((data)=> data?.find(item => item?._id === id) ).catch(error=>{
  toast.error(error?.response?.data?.message)
});

export const addExpensesItem = (data) => myAxiosInstance.post("batches/", data);

export const updateExpensesItem = (expensesID, data) =>
  myAxiosInstance.patch(`batches/${expensesID}`, data);

export const deleteExpensesItem = (expensesID) =>
  myAxiosInstance.delete(`batches/${expensesID}`);

export const addItem = (data) => {
  const headers = {
    "Content-Type": "multipart/form-data",
  };
  if (Cookies.get("accessToken")) {
    headers["authes"] = `BSA__${Cookies.get("accessToken")}`;
  }
  return axios.post(`${config.apiGateway?.URL}/batches/`, data, { headers });
};
