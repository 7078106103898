import { IconButton, Menu, MenuItem } from "@mui/material";
import React, { useState } from "react";
import { FaCheck } from "react-icons/fa6";
import { IoMdClose, IoMdMore } from "react-icons/io";
import { DeleteIcon, EditIcon } from "../../../../utiltis/Icons";
import { PiNotePencil } from "react-icons/pi";
import { MdOutlineModeEdit } from "react-icons/md";

const NewOptionsButton = ({
  id,
  onUpdate,
  onDelete,
  onAccept,
  onReject,
  isAcceptAllowed,
  isEdit,
  isAddNewOffer,
  onAddNewOffer,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    (isAcceptAllowed || isEdit || isAddNewOffer) && (
      <div>
        <IconButton
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          <IoMdMore className="text-mainText" />
        </IconButton>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
            sx: {
              bgcolor: "white",
            },
          }}
        >
          {isAddNewOffer ? (
            <MenuItem
              className="border min-w-[133px] text-right text-black"
              sx={{ gap: 1 }}
              onClick={() => {
                onAddNewOffer();
                handleClose();
              }}
            >
              <PiNotePencil />
              <span>اضافه عرض سعر جديد</span>
            </MenuItem>
          ) : null}
          {isAcceptAllowed ? (
            <>
              <MenuItem
                className="border min-w-[133px] text-right text-black"
                sx={{ gap: 1 }}
                onClick={() => {
                  onAccept();
                  handleClose();
                }}
              >
                <FaCheck /> <span>قبول</span>
              </MenuItem>
              <MenuItem
                className="border min-w-[133px] text-right text-black"
                sx={{ gap: 1 }}
                onClick={() => {
                  onReject();
                  handleClose();
                }}
              >
                <IoMdClose /> <span>رفض</span>
              </MenuItem>
            </>
          ) : null}
          {isEdit ? (
            <>
              <MenuItem
                className="border min-w-[133px] text-right text-black"
                sx={{ gap: 1 }}
                onClick={() => {
                  onUpdate();
                  handleClose();
                }}
              >
                <MdOutlineModeEdit className="text-black" /> <span>تعديل</span>
              </MenuItem>
              <MenuItem
                className="border min-w-[133px] text-right text-black"
                sx={{ gap: 1 }}
                onClick={() => {
                  onDelete();
                  handleClose();
                }}
              >
                <DeleteIcon /> <span>حذف</span>
              </MenuItem>
            </>
          ) : null}
        </Menu>
      </div>
    )
  );
};

export default NewOptionsButton;
