import { useQuery, useMutation, useQueryClient } from "react-query";
import {
  getAllTasks,
  addTask,
  updateTask,
  deleteTask,
  getTask,
  acceptTask,
  confirmTask,
  auditAcceptTask,
  auditResendTask,
} from "../../helper/fetchers/Tasks";

export const useGetAllTasks = (params) => {
  const query = useQuery(["task", params], () => getAllTasks(params));

  return query;
};
export const useGetTask = (taskId) => {
  const query = useQuery(["task", taskId], () => getTask(taskId), {
    enabled: !!taskId,
  });

  return query;
};

export const useAddTask = (onSuccess, onError) => {
  const queryClient = useQueryClient();
  return useMutation(addTask, {
    onSuccess: () => {
      queryClient.invalidateQueries("task");
      onSuccess();
    },
    onError: (error) => {
      onError();
      // Handle error
    },
  });
};

export const useAcceptTask = (onSuccess, onError) => {
  const queryClient = useQueryClient();
  return useMutation((taskId) => acceptTask(taskId), {
    onSuccess: () => {
      queryClient.invalidateQueries("task");
      onSuccess();
    },
    onError: (error) => {
      onError();
      // Handle error
    },
  });
};
export const useAuditAcceptTask = (onSuccess, onError) => {
  const queryClient = useQueryClient();
  return useMutation((taskId) => auditAcceptTask(taskId), {
    onSuccess: () => {
      queryClient.invalidateQueries("task");
      onSuccess();
    },
    onError: (error) => {
      onError();
      // Handle error
    },
  });
};
export const useAuditResendTask = (taskId, onSuccess, onError) => {
  const queryClient = useQueryClient();
  return useMutation((data) => auditResendTask(taskId, data), {
    onSuccess: () => {
      queryClient.invalidateQueries("task");
      onSuccess();
    },
    onError: (error) => {
      onError(error);
      // Handle error
    },
  });
};
export const useConfirmTask = (onSuccess, onError, taskId) => {
  const queryClient = useQueryClient();
  return useMutation((data) => confirmTask(taskId, data), {
    onSuccess: () => {
      queryClient.invalidateQueries("task");
      onSuccess();
    },
    onError: (error) => {
      onError();
      // Handle error
    },
  });
};
export const useDeleteTask = (onSuccess, onError) => {
  const queryClient = useQueryClient();
  return useMutation(deleteTask, {
    onSuccess: () => {
      queryClient.invalidateQueries("task");
      onSuccess();
    },
    onError: (error) => {
      onError();
    },
  });
};
export const useUpdateTask = (id, onSuccess, onError) => {
  const queryClient = useQueryClient();
  return useMutation((data) => updateTask(id, data), {
    onSuccess: () => {
      queryClient.invalidateQueries("task");
      onSuccess();
    },
    onError: (error) => {
      onError();
    },
  });
};
