import React from "react";

const OrderChip = ({ item }) => {
  return (
    <div className="bg-mainNavy px-2 py-1 rounded-lg text-mainText w-fit h-full">
      {item?.name}
    </div>
  );
};

export default OrderChip;
