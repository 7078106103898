import React, { useContext, useEffect, useState } from "react";
import SystemControler from "../../../../Components/System/SystemControler/SystemControler";
import { SearchComponent } from "../../../../Components/SearchComponent/SearchComponent";
import { NavDropdown } from "react-bootstrap";
import { Link, Outlet } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Badge from "@mui/material/Badge";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import BackButton from "../../../../Components/BackButton";
import { useGetChatUsers } from "../../../../hooks/fetchers/Users";
import Progress from "../../../../Components/Progress";
import { myAxiosJson } from "../../../../helper/https";
import { UserCard } from "../../../../Components/Users/UserCard/UserCard";
import { useDispatch, useSelector } from "react-redux";
import { switchNotification } from "../../../../setup/RTK/Chat/chatNotification";
import List from "../../../../Components/List";
import { ChatsContext } from "../../PlanModel/context/ChatsContext";

const SatusIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="15"
      viewBox="0 0 17 15"
      fill="none"
    >
      <path
        d="M17 0H0L6.8 7.77417V13.1487L10.2 14.7923V7.77417L17 0Z"
        fill="#D59921"
      />
    </svg>
  );
};
const ConnectedBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

const NotConnectedBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#FF0000",
    color: "#FF0000",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

const ChatIndex = () => {
  // const chatNotificationSelector = useSelector(state=> state.chatNotificationPlace.inChatPage)
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useState({});
  const { data, isLoading, isError } = useGetChatUsers(searchParams);
  const [country, setCountry] = useState(0);
  const handleSearch = (data) => {
    setSearchParams((prev) => ({ ...prev, search: data }));
    // console.log(data);
  };
  useEffect(() => {
    dispatch(switchNotification(true));
    return () => {
      dispatch(switchNotification(false));
    };
  }, []);

  // console.log("chatNotificationSelector:",chatNotificationSelector);
  return (
    <>
      <SystemControler child={<BackButton />} />
      <div className="grid grid-cols-12 gap-2 h-full">
        <div className="col-span-3 bg-mainItems p-2 rounded-[19px]">
          <p className="text-mainText mt-2 text-[15px]">كل المستخدمين</p>
          <div
            onClick={() => {
              setSearchParams((prev) => ({ ...prev, country: "السعودية" }));
              setCountry(0);
            }}
            className={`country pointer p-2 my-3 border border-transparent hover:!border-[#EFAA20] bg-mainNavy w-full h-[38px] rounded-md ${
              country === 0 ? " !border-[#EFAA20]" : "!border-transparent"
            }`}
          >
            <p className="text-mainText"> السعودية</p>
          </div>
          <div
            onClick={() => {
              setSearchParams((prev) => ({ ...prev, country: "مصر" }));
              setCountry(1);
            }}
            className={`country pointer p-2 my-3 border border-transparent hover:!border-[#EFAA20] bg-mainNavy w-full h-[38px] rounded-md ${
              country === 1
                ? " border !border-[#EFAA20]"
                : "!border-transparent"
            }`}
          >
            <p className="text-mainText"> مصر</p>
          </div>
        </div>
        <div className="col-span-3 pt-5 bg-mainItems flex flex-col gap-3 rounded-[19px] overflow-x-hidden">
          <SearchComponent
            className={"!w-3/4 mx-auto"}
            handleChange={handleSearch}
          />
          <div className="my-2 flex justify-between">
            <p
              onClick={() => {
                setSearchParams((prev) => ({ ...prev, country: null }));
              }}
              className="text-mainText pointer text-[14px] px-2"
            >
              كل المستخدمين
            </p>
            <NavDropdown
              title={<SatusIcon />}
              className="fs-5 status"
              id="collapsible-nav-dropdown"
            >
              <NavDropdown.Item
                onClick={() => {
                  setSearchParams((prev) => ({ ...prev, status: null }));
                }}
                className="text-end d-flex justify-content-between align-items-center"
              >
                <span className="text-black"> الكل</span>
              </NavDropdown.Item>
              <NavDropdown.Item
                onClick={() => {
                  setSearchParams((prev) => ({ ...prev, status: "1" }));
                }}
                className="text-end d-flex justify-content-between align-items-center"
              >
                <span className="text-black"> متصل</span>
                <div className="connected" />
              </NavDropdown.Item>
              <NavDropdown.Item
                onClick={() => {
                  setSearchParams((prev) => ({ ...prev, status: "0" }));
                }}
                className="text-end d-flex justify-content-between align-items-center"
              >
                <span className="text-black">غير متصل</span>
                <div className="not-connected " />
              </NavDropdown.Item>
            </NavDropdown>
          </div>
          {/* <Testing/> */}
          {isError ? (
            <p className="text-red-500 text-center ">يوجد خطا ما</p>
          ) : isLoading ? (
            <Progress />
          ) : (
            <DisplayUsers users={data?.users} />
          )}
        </div>
        <div className="col-span-6 bg-mainItems rounded-[19px]">
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default ChatIndex;

const DisplayUsers = ({ users }) => {
  // console.log("users is:", users);
  let content;
  if (users && users.length) {
    content = (
      <List
        data={users}
        renderItem={(props) => (
          <UserCard
            user={props}
            key={props._id}
            path={`/System/Chat/${props._id}`}
          />
        )}
      />
    );
  } else {
    content = (
      <p className="text-mainText text-center ">لا يوجد مستخدمين مطابقين</p>
    );
  }
  return (
    <div className="max-h-[700px] overflow-y-auto scrollbar-none">
      {content}
    </div>
  );
};
