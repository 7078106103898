import { useState } from "react";
import { CiEdit } from "react-icons/ci";
import { Button, Form, Image, Modal } from "react-bootstrap";
import Select from "../../../../Components/FormHandler/Select";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { Controller } from "react-hook-form";
import Progress from "../../../Progress";
import ChooseDepartmentComponent from "../ChooseDepartmentComponent/ChooseDepartmentComponent";
import SuccessfullModal from "../../../Modals/SuccessfullModal";
import { FaCheck } from "react-icons/fa";
import DeleteButton from "../../../DeleteButton";
import SaveButton from "../../../SaveButton";
import moment from "moment";
import CustomModal from "../../../Modals/CustomModal";
import EmployeeCard from "../Card";
import { CheckRole } from "../../../../helper/utils";
import { roles } from "../../../../helper/fetchers/Tasks";
import useModal from "../hooks/useModal";
import { useDeleteMeeting } from "../../../../hooks/fetchers/Meetings";
import useSuccessfullModal from "../../../Modals/hooks/useSuccessfullModal";
import LoadingPage from "../../../feedback/LoadingPage";
import { InputLabel } from "../../../../Pages/System/PlanModel/components/InputLabel";
import { ErrorMessage } from "@hookform/error-message";
import {
  CustomDatePicker,
  CustomInput,
  CustomTimePicker,
} from "../../../FormUi";
import CustomTextArea from "../../../FormUi/CustomTextArea";
import EditMeetingProvider, {
  useEditMeetingContext,
} from "./EditMeetingContext";
import DeleteEvent from "./DeleteEvent";
import EditableInput from "./EditableInput";
import EditDeleteMeetingWrapper from "./EditDeleteMeetingWrapper";
import EditMeetingForm from "./EditMeetingForm";

const EditDeleteMeeting = ({
  id,
  showEditDeleteModal,
  setShowEditDeleteModal,
  events,
}) => {
  return (
    <EditMeetingProvider events={events} id={id}>
      <EditWrappr setShowEditDeleteModal={setShowEditDeleteModal}>
        <EditMeetingForm
          id={id}
          showEditDeleteModal={showEditDeleteModal}
          setShowEditDeleteModal={setShowEditDeleteModal}
        />
      </EditWrappr>
    </EditMeetingProvider>
  );
};

const EditWrappr = ({ children, setShowEditDeleteModal }) => {
  const {
    updateIsLoading,
    message,
    show,
    handleCloseModal,
    selectedUsers,
    setSelectedUsers,
    showSuccess,
    handleCloseSuccess,
    showError,
    handleCloseError,
  } = useEditMeetingContext();
  return (
    <div>
      {updateIsLoading ? <LoadingPage /> : null}
      <ChooseDepartmentComponent
        show={show}
        onHide={handleCloseModal}
        selectedUsers={selectedUsers}
        setSelectedUsers={setSelectedUsers}
      />
      <SuccessfullModal
        message={"تم تعديل ميعاد بنجاح"}
        show={showSuccess}
        handleClose={() => {
          handleCloseSuccess();
          setShowEditDeleteModal(false);
        }}
      />
      <SuccessfullModal
        message={message}
        show={showError}
        handleClose={() => {
          handleCloseError();
          setShowEditDeleteModal(false);
        }}
        status="error"
      />
      {children}
    </div>
  );
};

export default EditDeleteMeeting;
