import { IoAdd } from "react-icons/io5";
import { forwardRef, useEffect, useRef, useState } from "react";
import { FaFileDownload } from "react-icons/fa";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { PdfIcon } from "../../utiltis/Icons";
const getType = (fileName) => {
  const fileExtension = fileName.split(".").pop().toLowerCase();
  return fileExtension;
};

const useOpenModal = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return {
    show,
    handleClose,
    handleShow,
  };
};

function Example({ show, handleClose, content }) {
  return (
    <Modal
      centered
      show={show}
      onHide={handleClose}
      contentClassName="p-0 bg-mainNavy w-fit mx-auto"
      className="bg-black/50"
    >
      <Modal.Body className="flex flex-col items-center">{content}</Modal.Body>
    </Modal>
  );
}

export const CustomeInputFile = forwardRef(
  ({ register, removePadding, multiple, filesOld = [], ...props }, ref) => {
    const { show, handleClose, handleShow } = useOpenModal();
    const [files, setFiles] = useState([]);
    const [content, setContent] = useState(null);
    const handleUploadedFile = (event) => {
      const uploadedFiles = Array.from(event.target.files);
      setFiles(uploadedFiles);
    };
    //  useEffect(()=>{
    // //   if(filesOld?.length){
    //   console.log("labelRef",labelRef?.current.children[register.name].value);

    // //   }
    //  },[])
    return (
      <div
        className={`relative flex gap-6 overflow-y-hidden overflow-x-auto scrollbar-thin scrollbar-track-transparent scrollbar-thumb-[#EFAA20]  ${
          removePadding ? "" : "pb-14"
        }`}
      >
        <Example show={show} handleClose={handleClose} content={content} />
        <label className="shrink-0 border !border-[#EFAA20] rounded-xl !border-dashed flex flex-col px-2 py-3 justify-center items-center gap-1 cursor-pointer">
          <input
            className="hidden"
            type="file"
            name={register.name}
            multiple={multiple}
            max={5}
            ref={register.ref}
            onBlur={register.onBlur}
            onChange={(e) => {
              register.onChange(e);
              handleUploadedFile(e);
            }}
            {...props}
          />
          <IoAdd color="#EFAA20" fontSize={30} />
          <p className="text-mainText text-sm">إضافة جديدة</p>
        </label>
        {files.map((file, index) => {
          const fileType = getType(file.name);
          switch (fileType) {
            case "jpg":
            case "jpeg":
            case "png":
            case "gif":
            case "jfif":
              return (
                <div
                  key={index}
                  onClick={() => {
                    setContent(
                      <>
                        <DownloadButton file={file} />

                        <img
                          className="w-full  rounded-xl border"
                          src={URL.createObjectURL(file)}
                          alt=""
                        />
                      </>
                    );
                    handleShow();
                  }}
                  className="shrink-0"
                >
                  <img
                    className="w-20 h-20 rounded-xl border"
                    src={URL.createObjectURL(file)}
                    alt=""
                  />
                </div>
              );
            case "pdf":
              return (
                <div
                  key={index}
                  onClick={() => {
                    setContent(
                      <>
                        <DownloadButton file={file} />
                        <embed
                          src={URL.createObjectURL(file)}
                          type="application/pdf"
                          width="400"
                          height="600"
                          className="w-full relative rounded-xl border"
                        />
                      </>
                    );
                    handleShow();
                  }}
                  className="flex shrink-0 justify-center items-center w-24 h-24 rounded-xl border"
                >
                  <PdfIcon />
                </div>
              );
            case "xlsx":
              return (
                <div
                  key={index}
                  onClick={() => {
                    setContent(
                      <>
                        <DownloadButton file={file} />
                        <img
                          className="w-full rounded-xl border"
                          src="https://logodownload.org/wp-content/uploads/2020/04/excel-logo-0.png"
                          alt=""
                        />
                      </>
                    );
                    handleShow();
                  }}
                  className="shrink-0"
                >
                  <img
                    className="w-20 h-20 rounded-xl border"
                    src="https://logodownload.org/wp-content/uploads/2020/04/excel-logo-0.png"
                    alt=""
                  />
                </div>
              );
            case "zip":
              return (
                <div
                  key={index}
                  onClick={() => {
                    setContent(
                      <>
                        <DownloadButton file={file} />
                        <img
                          className="w-full rounded-xl border"
                          src="https://p7.hiclipart.com/preview/258/300/47/computer-icons-zip-truetype-icon-png-file-zip.jpg"
                          alt=""
                        />
                      </>
                    );
                    handleShow();
                  }}
                  className="shrink-0"
                >
                  <img
                    className="w-20 h-20 rounded-xl border"
                    src="https://p7.hiclipart.com/preview/258/300/47/computer-icons-zip-truetype-icon-png-file-zip.jpg"
                    alt=""
                  />
                </div>
              );
            case "rar":
              return (
                <div
                  key={index}
                  onClick={() => {
                    setContent(
                      <>
                        <DownloadButton file={file} />
                        <img
                          className="w-96  rounded-xl border"
                          src="https://cdn-icons-png.freepik.com/512/9158/9158683.png"
                          alt=""
                        />
                      </>
                    );
                    handleShow();
                  }}
                  className="shrink-0"
                >
                  <img
                    className="w-20 h-20 rounded-xl border"
                    src="https://cdn-icons-png.freepik.com/512/9158/9158683.png"
                    alt=""
                  />
                </div>
              );
            default:
              return null;
          }
        })}
      </div>
    );
  }
);

const DownloadButton = ({ file }) => {
  return (
    <Button
      className="w-fit mx-auto text-mainText"
      download={true}
      variant="success"
      href={URL.createObjectURL(file)}
    >
      {"تحميل"}
      {/* <FaFileDownload fontSize={25} color="#EFAA20" /> */}
    </Button>
  );
};
