import { useCallback, useEffect, useState } from "react";
import "./index.css";

import ConfirmPoper from "../../../../Components/System/ConfirmPoper";
import ReviewRequestChart from "../../../../Components/System/Requests/ReviewRequestChart/ReviewRequestChart";
import EditReviewRequest from "../../../../Components/System/Requests/EditRequest/EditReviewRequest";
import ShowReviewRequest from "../../../../Components/System/ShowRequest/ShowReviewRequest";
import Image from "../../../../Components/Image.jsx";
import { TableCell } from "../../../../Components/Table/TableCell.jsx";
import { TableRow } from "../../../../Components/Table/TableRow.jsx";
import CustomTable from "../../../../Components/Table/index.jsx";
import { getAllRequestsStats } from "../../../../helper/fetchers/Requests.jsx";
import Progress from "../../../../Components/Progress.jsx";
import moment from "moment";
import { CheckRole, GenerateColumnsNames } from "../../../../helper/utils.jsx";
import {
  useDeleteRequest,
  useGetAllRequests,
} from "../../../../hooks/fetchers/Requests.jsx";
import { SearchComponent } from "../../../../Components/SearchComponent/SearchComponent.jsx";
import { RiDeleteBin6Line } from "react-icons/ri";
import CustomCheckboxTable from "../../../../Components/CustomCheckboxTable.jsx";
import LoadingPage from "../../../../Components/feedback/LoadingPage.jsx";
import SuccessfullModal from "../../../../Components/Modals/SuccessfullModal.jsx";
import useSuccessfullModal from "../../../../Components/Modals/hooks/useSuccessfullModal.jsx";
import TableDeleteButton from "../../../../Components/Buttons/TableDeleteButton.jsx";
import { roles } from "../../../../helper/fetchers/Tasks.jsx";
const ReviewRequest = ({}) => {
  const [page, setPage] = useState(1);
  const [showProject, setShowProject] = useState(false);
  const [editRequest, setEditRequest] = useState(false);
  const [ConfirmUpdate, setConfirmUpdate] = useState(false);
  const [ReviewProjectType, SetReviewProjectType] = useState("");
  const [search, setSearch] = useState(null);
  const { data, isLoading, isError } = useGetAllRequests({
    page,
    projectType: 2,
    search,
  });

  const DesignProjects = Array.from({ length: 10 }).map((_, index) => {
    return {
      id: index + 1,
      ProjectName: "BSA",
      ProjectNumber: "53543",
      createdAt: "12-10-2023",
      ProjectType: "اشراف",
      enStatus: "inProgress",
      status: "قيد الا نتظار",
      display: (
        <Image
          src={process.env.PUBLIC_URL + "/icons/view.svg"}
          onClick={() => {
            setShowProject(true);
            SetReviewProjectType(DesignProjects[index]?.enStatus);
          }}
          className="display_project  rounded"
          alt=" display project"
        />
      ),
      edit: (
        <Image
          src={process.env.PUBLIC_URL + "/icons/edit.svg"}
          onClick={() => {
            setEditRequest(true);
          }}
          className=" edit_project  rounded"
          alt="edit project"
        />
      ),
    };
  });

  const columns = GenerateColumnsNames(
    "تحديد",
    "م",
    "اسم المشروع",
    "رقم الطلب ",
    "تاريخ الاستلام",
    "نوع المشروع",
    "الحالة",
    "عرض",
    "تعديل"
  );
  const [id, setId] = useState(null);
  // const [reviewRequests, setReviewRequests] = useState();
  const handleChange = (event, value) => {
    setPage(value);
    // setFilterTasksParams({ page: value });
  };
  // const getReviewRequests = async () => {
  //   try {
  //     const { data } = await getRequestsWithProjectType(2,page);
  //     if (data) {
  //       setReviewRequests(data);
  //     } else {
  //       console.log("Data retrieval failed");
  //     }
  //   } catch (error) {
  //     toast.error(error?.response?.data?.message);
  //   }
  // };
  // useEffect(() => {
  //   getReviewRequests();
  // }, []);
  const [stats, setStats] = useState();
  const getRequests = useCallback(async () => {
    const response = await getAllRequestsStats();
    if (response?.status === 200) {
      setStats(response?.data);
    }
  }, []);
  useEffect(() => {
    getRequests();
  }, [getRequests]);
  const handleSearch = (val) => {
    setSearch(val);
    console.log(`Search:`, val);
  };
  // console.log("DesignRequests:",data);
  const [checked, setChecked] = useState([]);
  const {
    handleShowSuccess,
    handleShowError,
    handleCloseSuccess,
    handleCloseError,
    showSuccess,
    showError,
  } = useSuccessfullModal();
  const {
    mutate: deleteMutation,
    isLoading: deleteIsLoading,
    isError: deleteIsError,
  } = useDeleteRequest(() => {
    handleShowSuccess();
    setChecked([]);
  }, handleShowError);
  const handleChecked = (val) => {
    if (checked.includes(val)) {
      setChecked(checked.filter((item) => item !== val));
    } else {
      setChecked([...checked, val]);
    }
  };
  // You have array of ids to delete

  const handleDeleteAll = () => {};
  const handleDeleteSelected = () => {
    deleteMutation({ requests: checked });
  };

  return (
    <>
      {deleteIsLoading ? <LoadingPage /> : null}
      <SuccessfullModal
        handleClose={handleCloseSuccess}
        message={"تم الحذف بنجاح"}
        show={showSuccess}
      />
      <SuccessfullModal
        handleClose={handleCloseError}
        message={"حدث خطأ ما"}
        show={showError}
        status="error"
      />
      {showProject ? (
        <div className="AllRequests-scroll  h-full scrollbar-none">
          <ShowReviewRequest
            ReviewProjectType={ReviewProjectType}
            setShowProject={setShowProject}
            id={id}
            // getReviewRequests={getReviewRequests}
          />
        </div>
      ) : (
        <div className="AllRequests  h-full">
          <div>
            <div className="reviewChartContainer  d-flex justify-content-center align-items-center">
              <ReviewRequestChart stats={stats} />
            </div>

            <fieldset className="TableContainer py-3 px-2 mx-auto mt-3">
              <legend className="text-center text-mainText">
                طلبات ( اشراف )
              </legend>
              <div className="">
                <div className="w-3/4 mx-auto ">
                  <SearchComponent
                    handleChange={handleSearch}
                    value={search}
                    className={""}
                    border="border !border-[#E4A11B]"
                  />
                </div>
              </div>
              {CheckRole(roles["admin"]) && !isLoading && !isError && data?.request?.length > 0 ? (
                <TableDeleteButton
                  checked={checked}
                  dataLength={data?.request?.length}
                  onClickDeleteAll={handleDeleteAll}
                  onClickDeleteSelected={handleDeleteSelected}
                />
              ) : null}

              <div className="mt-3 !h-[400px] overflow-y-auto scrollbar-thin scrollbar-track-mainNavy scrollbar-thumb-[#E4A11B]">
                {isLoading ? <Progress /> : null}
                {isError ? (
                  <p className="text-red-500 text-center text-lg">
                    يوجد خطأ ما
                  </p>
                ) : null}

                {!isLoading && !isError && data.request ? (
                  <CustomTable
                  columns={CheckRole(roles["admin"]) ? columns : columns.slice(1)}
                    data={data?.request}
                    paginationProps={{
                      count: data.pages,
                      page: page,
                      onChange: handleChange,
                    }}
                  >
                    {data?.request && data?.request?.length > 0
                      ? data?.request?.map(
                          (
                            {
                              _id,
                              projectName,
                              orderNumber,
                              createdAt,
                              projectType,
                              status,
                              enStatus,
                              display,
                              edit,
                            },
                            index
                          ) => {
                            return (
                              <TableRow
                                className={`my-2 border !border-[#efaa207f] ${
                                  index % 2 === 0 ? "bg-mainNavy" : ""
                                }`}
                                key={_id}
                              >
                                {CheckRole(roles["admin"]) ? (
                                <TableCell>
                                  <CustomCheckboxTable
                                    isChecked={checked?.some((x) => x === _id)}
                                    handleClick={() => handleChecked(_id)}
                                  />
                                </TableCell>
                                ) : null}
                                <TableCell>
                                  {(page - 1) * 10 + (index + 1)}
                                </TableCell>
                                <TableCell>{projectName}</TableCell>
                                <TableCell>{orderNumber}</TableCell>
                                <TableCell>
                                  {moment(createdAt).format("YYYY-MM-DD")}
                                </TableCell>
                                <TableCell>اشراف علي التنفيذ</TableCell>
                                <TableCell>
                                  {status == 0
                                    ? "في الانتظار"
                                    : status == 1
                                    ? "قيد التنفيذ"
                                    : status == 66
                                    ? "تم تحويلها الي مشروع"
                                    : "مرفوضة"}
                                </TableCell>
                                <TableCell>
                                  <Image
                                    src={
                                      process.env.PUBLIC_URL + "/icons/view.svg"
                                    }
                                    onClick={() => {
                                      setShowProject(true);
                                      // se(
                                      //   DesignProjects[index]?.enStatus
                                      // );
                                      setId(_id);
                                    }}
                                    className="display_project  rounded"
                                    alt=" display project"
                                  />
                                </TableCell>
                                <TableCell>
                                  <Image
                                    src={
                                      process.env.PUBLIC_URL + "/icons/edit.svg"
                                    }
                                    onClick={() => {
                                      setEditRequest(true);
                                      setId(_id);
                                    }}
                                    className=" edit_project  rounded"
                                    alt=" edit project"
                                  />
                                </TableCell>
                              </TableRow>
                            );
                          }
                        )
                      : null}
                  </CustomTable>
                ) : null}
              </div>
            </fieldset>
          </div>
        </div>
      )}

      {editRequest && (
        <EditReviewRequest
          editRequest={editRequest}
          setEditRequest={setEditRequest}
          setConfirmPoper={setConfirmUpdate}
          handleClose={() => {
            setEditRequest(false);
          }}
          id={id}
          // getReviewRequests={getReviewRequests}
        />
      )}
      {ConfirmUpdate && (
        <ConfirmPoper
          confirmPoper={ConfirmUpdate}
          setConfirmPoper={setConfirmUpdate}
          setEditRequest={setEditRequest}
          text={"تم تعديل الطلب فى المشاريع بنجاح "}
        />
      )}
    </>
  );
};

export default ReviewRequest;
