import { toast } from "react-toastify";
import myAxiosInstance, { myAxiosJson } from "../https";

export const getProjectsReportMonthly = () => myAxiosJson("statics/project/report/monthly").then(({data})=> data).catch(error=>{
  toast.error(error?.response?.data?.message)
});
export const getAllRequestsList = () => myAxiosInstance("request/select").catch(error=>{
  toast.error(error?.response?.data?.message)
});

export const getAllBatchNameList = () => myAxiosInstance("batches/select").catch(error=>{
  toast.error(error?.response?.data?.message)
});

export const getAllProjects = (params) => myAxiosInstance("projects",{params}).catch(error=>{
  toast.error(error?.response?.data?.message)
});

export const getAllProjectsReports = () =>
  myAxiosInstance("projects/reports").then(({data})=>data).catch(error=>{
    toast.error(error?.response?.data?.message)
});

export const getAllProjectsStats = () =>
  myAxiosInstance("request/stats/status");

export const getAllProjectsStatus = () => myAxiosInstance("projects/status").catch(error=>{
  toast.error(error?.response?.data?.message)
});

export const getProject = (id) => myAxiosInstance.get(`projects/${id}`).catch(error=>{
  toast.error(error?.response?.data?.message)
});

export const addProject = (data) => myAxiosInstance.post("projects/", data);

export const updateAllProject = (projectId, data) =>
  myAxiosInstance.patch(`projects/${projectId}`, data);

export const deleteProject = (data) =>
  myAxiosJson.patch(`projects/remove/`,data);

export const continueProjectFunction = (projectID) =>
  myAxiosInstance.patch(`projects/continue/${projectID}`);

export const pendingProject = (projectID, data) => {
  return myAxiosJson.patch(`projects/pending/${projectID}`, data);
};
export const finishProject = (projectID) => myAxiosJson.patch(`projects/complete/${projectID}`)