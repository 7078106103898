import React, { useEffect, useState } from 'react'
import MenuIconButton from '../components/MenuIconButton'
import { useSelector, useDispatch } from "react-redux";
import { toggleWidth,resetWidth } from '../slices/fullWidthSlice';
import { Search } from '../components/SearchInput';
import TasksTable from '../../Plans/Projects/components/TasksTable';
import ExternalTasksTable from '../components/Tables/ExternalTasksTable';
import { SearchComponent } from '../../../../Components/SearchComponent/SearchComponent';
import {useExternalTaskContext} from "../../PlanModel/context/ExternalTaskContext"

function ExternalTasksPage() {
  useEffect(() => {
    dispatch(resetWidth());
    return () => dispatch(resetWidth());
  }, []);
  const dispatch = useDispatch();
  const { setFilterTasksParams } = useExternalTaskContext();
  const [search, setSearch] = useState("");
  const handleSearch = (value) => {
    setSearch(value);
    // console.log(e.target.value);
    setFilterTasksParams({ search: value });
  };
  return (
    <div className="flex-1">
      <div className="grid grid-cols-2 py-2">
        <div className="flex items-center gap-3 ">
          <MenuIconButton onClick={() => dispatch(toggleWidth())} />
          <p className="text-[#EFAA20] font-semibold text-xl">كل المهام الخارجيه</p>
        </div>
        <SearchComponent
            // className="m-auto w-[95%]"
            background={"#2B2B40"}
            border="border !border-[#E4A11B]"
            value={search}
            handleChange={handleSearch}
          />
      </div>
      <ExternalTasksTable />
    </div>
  )
}

export default ExternalTasksPage