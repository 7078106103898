import React from "react";
import { FaCheck } from "react-icons/fa6";

const CustomCheckboxTable = ({ isChecked, handleClick }) => {
  // const [check, setCheck] = useState(false);
  return (
    <div
      className={`w-7 h-7 bg-mainNavyDarker shadow-2xl rounded-lg flex items-center justify-center ${
        isChecked ? "border !border-[#efaa20]" : ""
      }`}
      onClick={handleClick}
    >
      {isChecked && <FaCheck className="text-mainText text-lg" />}
    </div>
  );
};

export default CustomCheckboxTable;
