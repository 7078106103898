import { Fragment, useContext, useState } from "react";
import { UpdateModal } from "./Modals/UpdateModal";
import { OptionsButton } from "../../OptionsButton";
import { useShowMenu } from "../hooks/useShowMenu";
import { useDeleteCategory } from "../../../../../hooks/fetchers/Categories";
import { CategoryContext } from "../context/CategoryContext";
import useSuccessfullModal from "../../../../../Components/Modals/hooks/useSuccessfullModal";
import SuccessfullModal from "../../../../../Components/Modals/SuccessfullModal";

export const EmptyData = ({ title }) => (
  <p className="text-mainText text-2xl text-center">{title}</p>
);

export const CategoriesList = ({ categories }) => {
  const { setCategoryId } = useContext(CategoryContext);
  const [optionId, setOptionId] = useState();

  const {
    handleShowSuccess,
    handleShowError,
    handleCloseSuccess,
    handleCloseError,
    showSuccess,
    showError,
  } = useSuccessfullModal();
  const { mutate: deleteCategoryMutation , error} = useDeleteCategory(
    handleShowSuccess,
    handleShowError
  );

  const [activeCategories, setActiveCategories] = useState([]);
  const { show, handleShow, handleClose } = useShowMenu();
  return (
    <div className="max-h-[700px] h-full overflow-y-auto scrollbar-thin scrollbar-track-mainNavy scrollbar-thumb-[#E4A11B]">
      {categories?.length > 0 ? (
        categories?.map(({ _id, name }, index) => (
          <div
            className={`flex w-full justify-between items-center px-2 text-[#ffffff80] border hover:!border-[#EFAA20] text-base ${
              activeCategories === _id
                ? "!border-[#EFAA20]"
                : "!border-transparent"
            }`}
            key={_id}
          >
            <button
              onClick={() => {
                setActiveCategories(_id);
                setCategoryId(_id);
              }}
              className="w-full text-mainText text-right py-3"
            >
              {name}
            </button>

            <OptionsButton
              id={_id}
              onUpdate={handleShow}
              onDelete={() => deleteCategoryMutation(_id)}
              getId={setOptionId}
            />
          </div>
        ))
      ) : (
        <EmptyData title={"لا يوجد بيانات لعرضها"} />
      )}
      {show ? (
        <UpdateModal id={optionId} show={show} handleClose={handleClose} />
      ) : null}
      <SuccessfullModal
        show={showSuccess}
        message={"تم الحذف بنجاح"}
        handleClose={handleCloseSuccess}
      />
      <SuccessfullModal
        status="error"
        show={showError}
        message={"حدث خطأ ما"}
        handleClose={handleCloseError}
      />
    </div>
  );
};
