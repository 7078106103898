import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  addMeeting,
  deleteMeeting,
  getAllMeeting,
  getMeeting,
  updateMeeting,
} from "../../helper/fetchers/meetings";

export const useGetAllMeetings = () => {
  return useQuery("event", getAllMeeting);
};
export const useGetMeeting = (id) => {
  return useQuery(["event",id], () => getMeeting(id) , {
    enabled: !!id
  });
};

export const useAddMeeting = (onSuccess, onError) => {
  const queryClient = useQueryClient();
  return useMutation(addMeeting, {
    onSuccess: () => {
      queryClient.invalidateQueries("event");
      onSuccess();
    },
    onError: (error) => {
      onError();
    },
  });
};
export const useDeleteMeeting = (id, onSuccess, onError) => {
  const queryClient = useQueryClient();
  return useMutation(() => deleteMeeting(id), {
    onSuccess: () => {
      queryClient.invalidateQueries("event");
      onSuccess();
    },
    onError: (error) => {
      onError();
    },
  });
};
export const useUpdateMeeting = (id, onSuccess, onError) => {
  const queryClient = useQueryClient();
  return useMutation((data) => updateMeeting(id, data), {
    onSuccess: () => {
      queryClient.invalidateQueries("event");
      onSuccess();
    },
    onError: (error) => {
      onError();
    },
  });
};
