import { Link } from "react-router-dom";

const HoverLink = ({ children, to , variant="square"}) => {
    return (
      <Link
        to={to}
        className="tab flex mb-1 p-2 border !border-transparent hover:!border-[#EFAA20]"
      >
        {children}
      </Link>
    );
  };
export default HoverLink