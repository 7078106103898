import { useCallback, useEffect, useState } from "react";
import { getUserByCountry } from "../../../../helper/fetchers/Users";
import { SearchComponent } from "../../../../Components/SearchComponent/SearchComponent";
import { NavDropdown } from "react-bootstrap";
import { Avatar } from "@mui/material";
import {
  ConnectedBadge,
  NotConnectedBadge,
} from "../../../../Components/Users/StatusBadge";
import { Link, useParams } from "react-router-dom";
import { FilterIcon } from "../../../../utiltis/Icons";
import HoverLink from "../../../../Components/List/HoverLink";

const AllUsersSearchDropDown = () => {
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState("");
  const [status, setStatus] = useState(undefined);
  const { Country, role, departments } = useParams();

  // console.log(Country, role, departments, "Country, role, departments");
  const countryName = Country === "Saudia" ? "السعودية" : "مصر";
  const getUsers = useCallback(async () => {
    if (countryName) {
      const res = await getUserByCountry(countryName, role, departments);
      res?.status === 200 ? setData(res?.data?.allUsers) : setData([]);
    }
  }, [countryName, role, departments]);

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  const filteredUsers = data?.filter((user) => {
    const fullName = user?.firstName + " " + user?.lastName;
    return (
      fullName?.toLowerCase()?.includes(filter?.toLowerCase()) ||
      user?.email.toLowerCase()?.includes(filter?.toLowerCase())
    );
  });

  const activeUsers = filteredUsers?.filter((user) => user?.status === status);

  const route = `System/AllUsers/Country/${Country}${role ? `/${role}` : ""}${
    departments ? `/${departments}` : ""
  }`;
  return (
    <div className="absolute left-[-19rem] overflow-auto bg-mainItems rounded-lg w-[300px] h-fit shadow-2xl">
      <div className="search-users py-4 overflow-x-hidden overflow-y-auto scrollbar-none h-full">
        <div className="d-flex   justify-content-center">
          <SearchComponent
            className="w-full"
            border="border !border-[#E4A11B]"
            handleChange={setFilter}
            value={filter}
          />
        </div>
        <div className="d-flex w-100 align-items-center justify-content-between m-2">
          <p className="text-center  text-mainText">كل المستخدمين</p>
          <NavDropdown
            title={<FilterIcon />}
            className="fs-5"
            id="collapsible-nav-dropdown"
          >
            <NavDropdown.Item
              onClick={() => setStatus(undefined)}
              className="text-end  d-flex justify-content-between  align-items-center hover:rounded-lg"
            >
              <span> الكل</span>
            </NavDropdown.Item>
            <NavDropdown.Item
              onClick={() => setStatus(1)}
              className="text-end  d-flex justify-content-between  align-items-center hover:rounded-lg"
            >
              <span> متصل</span>
              <div className="connected" />
            </NavDropdown.Item>
            <NavDropdown.Item
              onClick={() => setStatus(0)}
              className="text-end  d-flex justify-content-between align-items-center hover:rounded-lg"
            >
              <span>غير متصل</span>
              <div className="not-connected " />
            </NavDropdown.Item>
          </NavDropdown>
        </div>
        <div className="max-h-[700px] overflow-y-auto scrollbar-none">
          {status !== undefined ? (
            activeUsers.length > 0 ? (
              activeUsers?.map((user, index) => (
                <>
                  <UserCard
                    path={`/${route}/user/${user?.id}`}
                    user={user}
                    key={index}
                  />
                </>
              ))
            ) : (
              <p className="text-base text-mainText text-center">
                لا يوجد مستخدمين
              </p>
            )
          ) : filteredUsers.length > 0 ? (
            filteredUsers?.map((user, index) => (
              <>
                <UserCard
                  path={`/${route}/user/${user?.id}`}
                  user={user}
                  key={index}
                />
              </>
            ))
          ) : (
            <p className="text-base text-mainText text-center">
              لا يوجد مستخدمين
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default AllUsersSearchDropDown;

const UserCard = ({ user, path }) => {
  return (
    <HoverLink to={path}>
      <div>
        {user?.status === 1 ? (
          <ConnectedBadge
            overlap="circular"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            variant="dot"
          >
            <Avatar
              alt={`${user?.firstName} ${user?.lastName}`}
              src={user?.image}
            />
          </ConnectedBadge>
        ) : (
          <NotConnectedBadge
            overlap="circular"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            variant="dot"
          >
            <Avatar
              alt={`${user?.firstName} ${user?.lastName}`}
              src={user?.image}
            />
          </NotConnectedBadge>
        )}
      </div>
      <div className="flex flex-1 flex-col gap-1 items-start justify-center w-full overflow-hidden ">
        <h2 className="text-end name-header text-mainText w-full  ">
          {user?.firstName + " " + user?.lastName}
        </h2>
        <p className="text-mainCustomGray text-sm w-full  ">{user?.email}</p>
      </div>
    </HoverLink>
  );
};
