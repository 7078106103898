import React, { createContext, useState } from "react";
import App from "../App";

export const AddHrType = React.createContext();

export const AddHr = ({ children }) => {
  const [openHr, setOpenHr] = useState(false);
  const [HrType, setHrType] = useState("");
  const handleOpenHr = () => {
    setOpenHr(true)
  }
  const handleCloseHr = () => {
    setOpenHr(false)
  }
  return (
    <AddHrType.Provider value={{ openHr, setOpenHr, HrType, setHrType ,handleOpenHr,handleCloseHr}}>
      {children}
    </AddHrType.Provider>
  );
};
