/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect } from "react";
import styles from "./AddProject.module.css";

import { Button, Form, Modal } from "react-bootstrap";
import { useState } from "react";
import SaveButton from "../../../SaveButton";
import "../AddProject.css";
import Select from "../../../FormHandler/Select";
import AddAttachment from "../../AddAttachment";
import {
  addProject,
  getAllBatchNameList,
  getAllRequestsList,
} from "../../../../helper/fetchers/Projects";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import SuccessfullModal from "../../../Modals/SuccessfullModal";
import ShowFiles from "../../../ShowFiles";
import ShowLocalFiles from "../../../ShowLocalFiles";
import { useForm, Controller } from "react-hook-form";
import { FormModal } from "../../../../Pages/System/PlanModel/components/FormModal";
import { InputLabel } from "../../../../Pages/System/PlanModel/components/InputLabel";
import { CustomDatePicker, CustomInput } from "../../../FormUi";
import { ErrorMessage } from "@hookform/error-message";
import LoadingPage from "../../../feedback/LoadingPage";
import { useAddProject } from "../../../../hooks/fetchers/Projects";
import useSuccessfullModal from "../../../Modals/hooks/useSuccessfullModal";
import { Tooltip } from "@mui/material";
const paymentStatusOptions = [
  {
    label: "تم الدفع",
    value: 1,
  },
  {
    label: "لم يتم الدفع",
    value: 0,
  },
];
const AddProject = () => {
  const [requestList, setRequestList] = useState([]);
  const [batchList, setBatchList] = useState([]);

  const getAllRequests = useCallback(async () => {
    const data = await getAllRequestsList();
    if (data.status === 200) {
      setRequestList(data?.data?.request);
    }
  }, []);
  useEffect(() => {
    getAllRequests();
  }, []);
  const MappedRequestList = requestList?.map((request) => ({
    value: request?.id,
    label: request?.projectName,
  }));

  const getAllBatchName = useCallback(async () => {
    const data = await getAllBatchNameList();
    if (data.status === 200) {
      setBatchList(data?.data?.batches);
    }
  }, []);
  useEffect(() => {
    getAllBatchName();
  }, []);
  const MappedBatchNameList = batchList?.map((request) => ({
    value: request?._id,
    label: request?.name,
  }));
  // #endregion

  
  return (
    <>
      <ProjectForm
        requestList={requestList}
        MappedRequestList={MappedRequestList}
        MappedBatchNameList={MappedBatchNameList}
        paymentStatusOptions={paymentStatusOptions}
      />
    </>
  );
};

const ProjectForm = ({
  MappedRequestList,
  requestList,
  MappedBatchNameList,
  paymentStatusOptions,
}) => {
  const navigate = useNavigate();
  const {
    handleShowSuccess,
    handleShowError,
    handleCloseSuccess,
    handleCloseError,
    showSuccess,
    showError,
  } = useSuccessfullModal();
  const { mutate, isLoading, isError, error } = useAddProject(
    handleShowSuccess,
    handleShowError
  );
  const [message, setMessage] = useState("حدث خطأ ما");
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [attachment, setAttachment] = useState(null);
  const [paperProject, setPaperProject] = useState(null);
  const [openAddAttachment, setOpenAddAttachment] = useState(false);
  let [showNextPayment, setShowNextPayment] = useState(false);
  const files = attachment ? [...attachment] : [];
  const {
    control,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm();
  useEffect(() => {
    if (isError) {
      setMessage(error?.response?.data?.message);
    }
  }, [isError]);
  const handleStartPayment = () => {
    setShowNextPayment(true);
  };
  const manipulateFormData = (data) => {
    data.batcheId = data.batcheId?.value;
    data.batchStatus = data.batchStatus?.value === 0 ? 0 : 1;
    return data;
  };
  const onSubmit = async (data) => {
    data = manipulateFormData(data);
    const formData = new FormData();
    formData.append("requestId", selectedRequest?.id);
    formData.append("orderNumber", selectedRequest.orderNumber);

    // formData.append("deliveryDate", "2024-07-07");

    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });

    if (attachment && attachment?.length > 0) {
      files.forEach((file) => {
        formData.append(`ProjectOffers`, file);
      });
    }
    if (paperProject && paperProject?.length > 0) {
      files.forEach((file) => {
        formData.append(`paperProject`, file);
      });
    }

    for (const pair of formData.entries()) {
      console.log(pair[0], pair[1]);
    }
    mutate(formData);
    // setIsLoading(true);
    // try {
    //   const data = await addProject(formData);
    //   if (data?.status === 201) {
    //     setShowSuccess(true);
    //   }
    // } catch (error) {
    //   toast.error(error?.response?.data?.message);
    // }
    // setIsLoading(false);
  };

  const isDisabledConfirmBtn =
    watch("amount") && watch("depositAmount") && watch("batchCount");
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        {isLoading ? <LoadingPage /> : null}
        <div
          className={` addproject AddProjectComponent bg-mainItems rounded-[27px] border !border-[#EFAA20] p-5 flex flex-col gap-4 !h-[837px] overflow-y-auto `}
        >
          {openAddAttachment && (
            <AttachmentModal
              show={openAddAttachment}
              onHide={() => setOpenAddAttachment(false)}
              onChange={(e) => setAttachment(e.currentTarget.files[0])}
            />
          )}

          <h2 className="golden text-xl">إضافة جديدة</h2>

          <FormModal title={"بحث عن الطلب"}>
            <div className=" col-md-4 mb-4">
              {MappedRequestList && (
                <Select
                  label={"بحث"}
                  options={MappedRequestList}
                  mandatory
                  onChange={(e) => {
                    setSelectedRequest(
                      requestList?.find((x) => x.id === e.value)
                    );
                  }}
                />
              )}
            </div>
          </FormModal>
          {selectedRequest && selectedRequest?.id && (
            <div className="addingDetails mt-4 flex flex-col gap-4">
              <FormModal title={"معلومات عامة"}>
                <div className="row">
                  <div className="col-md-6 mb-3">
                    <p className="text-mainText">
                      اسم المالك :
                      <span className="main-text">
                        <Tooltip
                          placement="left"
                          title={selectedRequest.ownerName}
                        >
                          {selectedRequest.ownerName}
                        </Tooltip>
                      </span>
                    </p>
                  </div>
                  <div className="col-md-6 mb-3">
                    <p className="text-mainText">
                      نوع المشروع :
                      <span className="text-mainCustomGray">
                        {selectedRequest.projectType === 1
                          ? "تصميم"
                          : "اشراف علي التنفيذ"}
                      </span>
                    </p>
                  </div>
                  <div className="col-md-6 mb-3">
                    <p className="text-mainText">
                      نوع العميل :
                      <span className="text-mainCustomGray">
                        {selectedRequest.clientType === 1
                          ? "حكومي أو مستثمر"
                          : selectedRequest.clientType === 2
                          ? "شركة أو مؤسسة"
                          : "تجاري"}
                      </span>
                    </p>
                  </div>
                </div>
              </FormModal>
              <FormModal title={"معلومات إدارية"}>
                <div className="row">
                  <div className="col-md-12 mb-3">
                    <p className="text-mainText mb-1">العرض الفنى :</p>
                    <ShowFiles files={selectedRequest?.techFiles} />
                  </div>
                  <div className="col-md-12 mb-3">
                    <p className="text-mainText mb-1">العرض المالى :</p>
                    <ShowFiles files={selectedRequest?.financialFiles} />
                  </div>
                </div>
              </FormModal>

              <FormModal
                title={"المرفقات"}
                className={`${styles.fieldBorder} mt-4 p-2 flex items-center justify-start gap-4 overflow-x-auto custom-scrollbar`}
              >
                <AddAttachment
                  attachment={attachment}
                  setAttachment={setAttachment}
                  multi={true}
                />
                {attachment && (
                  <ShowLocalFiles files={Array.from(attachment)} />
                )}
              </FormModal>

              <FormModal
                title={"اوراق المشروع"}
                className={`${styles.fieldBorder} mt-4 p-2 flex items-center justify-start gap-4 overflow-x-auto custom-scrollbar`}
              >
                <AddAttachment
                  attachment={paperProject}
                  setAttachment={setPaperProject}
                  multi={true}
                />
                {paperProject && (
                  <ShowLocalFiles files={Array.from(paperProject)} />
                )}
              </FormModal>

              <FormModal title={"ارفاق الملاحظات"}>
                <div className="flex flex-col">
                  <InputLabel label={"ملاحظات المشروع"} />
                  <CustomInput
                    control={control}
                    name={"notesProject"}
                    multiline
                    rows={5}
                    placeholder="اكتب ملاحظات المشروع"
                  />
                </div>
              </FormModal>

              <FormModal title={"معلومات الدفع"}>
                <div className="row">
                  <div className="col-md-4 mb-4">
                    <div className="flex flex-col">
                      <InputLabel label={"المبلغ الاجمالى"} mandatory={true} />
                      <CustomInput
                        control={control}
                        name={"amount"}
                        rules={{
                          required: "يجب أدخال المبلغ الاجمالى",
                          pattern: {
                            value: /^[0-9]+$/,
                            message: "يجب ادخال ارقام فقط",
                          },
                        }}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="amount"
                        render={({ message }) => (
                          <p className="text-[#FF0000]">{message}</p>
                        )}
                      />
                    </div>
                  </div>
                  <div className="col-md-4 mb-4">
                    <div className="flex flex-col">
                      <InputLabel
                        label={"عدد الدفعات المتفق عليها"}
                        mandatory={true}
                      />
                      <CustomInput
                        control={control}
                        name={"batchCount"}
                        rules={{
                          required: "يجب أدخال عدد الدفعات المتفق عليها",
                          pattern: {
                            value: /^[0-9]+$/,
                            message: "يجب ادخال ارقام فقط",
                          },
                        }}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="batchCount"
                        render={({ message }) => (
                          <p className="text-[#FF0000]">{message}</p>
                        )}
                      />
                    </div>
                  </div>
                  <div className="col-md-4 mb-4">
                    <div className="flex flex-col">
                      <InputLabel label={"قيمة الخصم"} />
                      <CustomInput
                        control={control}
                        name={"discount"}
                        discount={true}
                        rules={{
                          pattern: {
                            value: /^[0-9]+$/,
                            message: "يجب ادخال ارقام فقط",
                          },
                        }}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="discount"
                        render={({ message }) => (
                          <p className="text-[#FF0000]">{message}</p>
                        )}
                      />
                    </div>
                  </div>
                  <div className="col-md-4 mb-4">
                    <div className="flex flex-col">
                      <InputLabel
                        label={"القيمه الافتتاحية"}
                        mandatory={true}
                      />
                      <CustomInput
                        control={control}
                        name={"depositAmount"}
                        rules={{
                          required: "يجب أدخال القيمه الافتتاحية",
                          pattern: {
                            value: /^[0-9]+$/,
                            message: "يجب ادخال ارقام فقط",
                          },
                        }}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="depositAmount"
                        render={({ message }) => (
                          <p className="text-[#FF0000]">{message}</p>
                        )}
                      />
                    </div>
                  </div>
                  <div
                    className={`col-md-4 ${
                      showNextPayment && "d-none"
                    }  d-flex align-items-center `}
                  >
                    <Button
                      onClick={handleStartPayment}
                      disabled={!isDisabledConfirmBtn}
                      type="button"
                      className={`bg-[#EFAA20] text-mainText confirmPayment w-50 mt-1  `}
                    >
                      تأكيد
                    </Button>
                  </div>
                  {showNextPayment && (
                    <>
                      <div className="col-md-4 mb-4">
                        <div className="flex flex-col">
                          <InputLabel label={"نوع الدفعة"} mandatory={true} />
                          <Controller
                            name="batcheId"
                            control={control}
                            rules={{
                              required: "يجب اختيار نوع الدفعة",
                            }}
                            render={({
                              field: { onBlur, onChange, value },
                            }) => (
                              <Select
                                value={value}
                                mandatory
                                onChange={onChange}
                                onBlur={onBlur}
                                options={MappedBatchNameList}
                                className={`${styles.searchOrder} w-100`}
                              />
                            )}
                          />
                          <ErrorMessage
                            errors={errors}
                            name="batcheId"
                            render={({ message }) => (
                              <p className="text-[#FF0000]">{message}</p>
                            )}
                          />
                        </div>
                      </div>

                      <div className="col-md-4 mb-4">
                        <div className="flex flex-col">
                          <InputLabel label={"تاريخ الدفع"} mandatory={true} />
                          <CustomDatePicker
                            control={control}
                            name={"createdDate"}
                            rules={{
                              required: "يجب أدخال تاريخ الدفع",
                            }}
                          />
                          <ErrorMessage
                            errors={errors}
                            name="createdDate"
                            render={({ message }) => (
                              <p className="text-[#FF0000]">{message}</p>
                            )}
                          />
                        </div>
                      </div>

                      <div className="col-md-4 mb-4">
                        <div className="flex flex-col">
                          <InputLabel
                            label={"تاريخ التسليم"}
                            mandatory={true}
                          />
                          <CustomDatePicker
                            control={control}
                            name={"deliveryDate"}
                            rules={{
                              required: "يجب أدخال تاريخ التسليم",
                            }}
                          />
                          <ErrorMessage
                            errors={errors}
                            name="deliveryDate"
                            render={({ message }) => (
                              <p className="text-[#FF0000]">{message}</p>
                            )}
                          />
                        </div>
                      </div>

                      <div className="col-md-4 mb-4">
                        <div className="flex flex-col">
                          <InputLabel label={" الحالة"} mandatory={true} />
                          <Controller
                            name="batchStatus"
                            control={control}
                            rules={{ required: "يجب أختيار الحالة" }}
                            render={({
                              field: { onBlur, onChange, value },
                            }) => (
                              <Select
                                value={value}
                                mandatory
                                onChange={onChange}
                                onBlur={onBlur}
                                options={paymentStatusOptions}
                                className={`${styles.searchOrder} w-100`}
                                placeholder="اختار الحالة"
                              />
                            )}
                          />
                          <ErrorMessage
                            errors={errors}
                            name="batchStatus"
                            render={({ message }) => (
                              <p className="text-[#FF0000]">{message}</p>
                            )}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-4">
                        <div className="flex flex-col">
                          <InputLabel label={"ملاحظات الدفع"} />
                          <CustomInput
                            control={control}
                            name={"notesAmount"}
                            multiline
                            rows={5}
                            placeholder="اكتب ملاحظات الدفع"
                          />
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </FormModal>
            </div>
          )}
          <Button
            type="submit"
            className="mx-auto py-1 px-14 font-semibold text-[15px] border !border-[#EFAA20] text-white hover:text-mainText !bg-[#EFAA20] hover:bg-[#EFAA20] disabled:bg-[#EFAA20]"
            disabled={!selectedRequest?.id}
          >
            حفظ
          </Button>
        </div>
      </form>
      <SuccessfullModal
        message={"تم اضافه المشروع بنجاح"}
        handleClose={() => {
          handleCloseSuccess();
          navigate("/System/Projects/Main/inProgress");
        }}
        show={showSuccess}
      />
      <SuccessfullModal
        show={showError}
        message={message}
        handleClose={handleCloseError}
        status="error"
      />
    </>
  );
};

const AttachmentModal = ({ show, onHide, onChange }) => {
  return (
    <Modal
      className="submitSystemPoper"
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      onHide={onHide}
      show={show}
    >
      <Modal.Body className="d-flex align-items-center">
        <img
          src={`${process.env.PUBLIC_URL}/chooseFile.png`}
          className="my-3"
          alt="choose file"
        />
        <Form.Group className={`${styles.inputbg}`} controlId="formBasicImage">
          <Form.Control
            type="file"
            placeholder="ادخل اسم الملف  "
            name="imageFile"
            className={`${styles.chooseFile} text-mainText`}
            onChange={onChange}
          />
        </Form.Group>
        <div className="my-3" onClick={onHide}>
          <SaveButton />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddProject;
