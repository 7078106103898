import React, { useState, Fragment, useEffect } from "react";
import Avatar from "@mui/material/Avatar";
import Modal from "react-bootstrap/Modal";
import commentLogo from "../../../assets/comment-logo.png";
import { useCommentCtx } from "./CommentCtx";
import CommentCard from "./CommentCard";
import { twMerge } from "tailwind-merge";
import useModal from "../hooks/useModal";
import { CommentForm } from "./CommentForm";
import { ReplyTo } from "./ReplyTo";
import "../style.css";
const CommentModal = ({ handleSave }) => {
  const { open, handleCloseModal, comments, task } = useCommentCtx();

  const [selectedId, setSelectedId] = useState(null);
  const [selectedComment, setSelectedComment] = useState({});
  const {
    open: openReply,
    handleCloseModal: handleCloseReply,
    handleToggleModal,
  } = useModal();
  // console.log("ctx comments", comments);
  useEffect(() => {
    const comment = comments.find((cmt) => cmt._id === selectedId);
    if (comment) {
      setSelectedComment(comment);
    }
  }, [selectedId]);
  // console.log("selectedComment",selectedComment);
  return (
    <Modal
      centered
      contentClassName="bg-mainItems !max-w-[700px] text-mainText !rounded-[20px] relative"
      className="bg-black/50 "
      show={open}
      size="lg"
      onHide={handleCloseModal}
    >
      <Modal.Header className="modal-header-from  py-2 rounded-t-[20px] !justify-between border-none w-full">
        <Modal.Title className="flex justify-between items-center w-full">
          <div className="flex items-center gap-2">
            <Avatar alt="logo" src={commentLogo} />
            <p className="text-mainText text-base">BSA</p>
          </div>
          <button type="button" onClick={handleCloseModal}>
            <img src="/Rejected.svg" alt="close" />
          </button>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="w-full modal-body-form h-[31rem] overflow-y-auto p-3 scrollbar-thin scrollbar-track-transparent scrollbar-thumb-[#EFAA20]">
        <div className="w-full h-full rounded-[7px] outline-none text-mainText space-y-5">
          {comments.length ? (
            comments.map((cmt) => {
              // console.log("cmt", cmt.replays);
              return (
                <Fragment key={cmt._id}>
                  <CommentCard
                    onOpenReply={(id) => {
                      // console.log("selected id is", id);
                      setSelectedId(id);
                      handleToggleModal();
                    }}
                    comment={cmt}
                    // isReply={cmt.replays.length}
                  />

                  {cmt.replays.length
                    ? cmt.replays.map((rep) => (
                        <CommentCard
                          onOpenReply={(id) => {
                            // console.log("selected id is", id);
                            setSelectedId(id);
                            handleToggleModal();
                          }}
                          comment={cmt}
                          // commentText={cmt.commentText}
                          rep={rep}
                          isReply={true}
                          key={rep._id}
                        />
                      ))
                    : null}
                </Fragment>
              );
            })
          ) : (
            <div className="w-full h-full flex justify-center items-center ">
              <p className="text-[#efaa20] text-xl lg:text-2xl  ">
                لا يوجد تعليقات مسبقة
              </p>
            </div>
          )}
          {/* <CommentCard onOpenReply={handleToggleModal} isReply /> */}
          {/* <CommentCard onOpenReply={handleToggleModal} /> */}
          {/* <CommentCard onOpenReply={handleToggleModal} /> */}
          {/* <CommentCard onOpenReply={handleToggleModal} /> */}
        </div>
        {/* {openReply ? (
          <div className="sticky bottom-0 inset-x-0 reply-to z-30 p-2">
            <ReplyTo comment={selectedComment} onClose={handleCloseReply} />
          </div>
        ) : null} */}
      </Modal.Body>
      {task?.status != 5 ? (
        <>
          <Modal.Footer
            className={twMerge(
              "border-none  transition-colors duration-300 p-2 gap-2 w-full relative items-start",
              openReply ? "reply-to" : "reply-to"
            )}
          >
            {openReply ? (
              <div className="w-full sticky top-0 inset-x-0 reply-to z-30 p-2">
                <ReplyTo comment={selectedComment} onClose={handleCloseReply} />
              </div>
            ) : null}
            <div className="comment-form rounded-2xl p-2 w-full shadow-md">
              <CommentForm
                commentId={openReply ? selectedId : null}
                onSave={handleCloseReply}
              />
            </div>
          </Modal.Footer>
        </>
      ) : null}
    </Modal>
  );
};

export default CommentModal;
