import { DevTool } from "@hookform/devtools";
import { useForm, Controller } from "react-hook-form";
import { FormControl } from "@mui/material";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import useSuccessfullModal from "../../../../../Components/Modals/hooks/useSuccessfullModal";
import SuccessfullModal from "../../../../../Components/Modals/SuccessfullModal";

import { Fragment, useEffect, useState } from "react";
import { FormModal } from "../../../PlanModel/components/FormModal";
import { InputLabel } from "../../../PlanModel/components/InputLabel";
import { useGetSupervisors } from "../../hooks/useGetSupervisors";
import { useGetProjects } from "../../hooks/useGetProjects";
import {
  CustomDatePicker,
  CustomInput,
  CustomeInputFile,
} from "../../../../../Components/FormUi";
import { SaveButton } from "./SaveButton";
import Select from "../../../../../Components/FormHandler/Select";
import { useAddPlan } from "../../../../../hooks/fetchers/Plans";
import LoadingPage from "../../../../../Components/feedback/LoadingPage";
import { ListErrorComponent } from "../../../../../Components/Errors/ListErrorComponent";
import { CustErrorMessage } from "../../../../../Components/Errors/CustErrorMessage";
import { CustomOption } from "../../../../../Components/CustomOption/CustomOption";
import CustomTextArea from "../../../../../Components/FormUi/CustomTextArea";

const validateFiles = (value) => {
  if (value.length > 8) {
    return "يمكنك تحميل ما يصل إلى 8 صور فقط";
  }
  return true;
};

const projectTypes = [
  {
    value: "1",
    label: "تصميم",
  },
  {
    value: "2",
    label: "اشراف علي التنفيذ",
  },
];

const manipulateData = (data) => {
  data.startDate = format(data.startDate, "MM/dd/yyyy");
  data.endDate = format(data.endDate, "MM/dd/yyyy");
  if (data.projectType) {
    data.projectType = data.projectType.value;
  }
  if (data.projectId) {
    data.projectId = data.projectId.value;
  }
  data.owner = data.owner.map((person) => person.value);
  return data;
};

const AddPlanForm = ({ isExist }) => {
  const [errorMessage, setErrorMessage] = useState("حدث خطأ ما");
  const navigate = useNavigate();
  const { data: projects } = useGetProjects();
  const { data: supervisors } = useGetSupervisors();
  // console.log(supervisors);
  const {
    showSuccess,
    showError,
    handleShowSuccess,
    handleShowError,
    handleCloseSuccess,
    handleCloseError,
  } = useSuccessfullModal();

  const { mutate, isLoading, isError, error } = useAddPlan(
    handleShowSuccess,
    handleShowError
  );
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    data = manipulateData(data);

    var formdata = new FormData();
    formdata.append("isExist", isExist);
    if (isExist === true) {
      formdata.append("projectId", data.projectId);
    } else if (isExist === false) {
      formdata.append("projectName", data.projectName);
      formdata.append("projectType", +data.projectType);
      formdata.append("buildingLocation", data.buildingLocation);
      formdata.append("clientType", data.clientType);
    }
    formdata.append("noteClient", data.noteClient);
    formdata.append("planDescription", data.planDescription);
    formdata.append("startDate", data.startDate);
    formdata.append("endDate", data.endDate);
    for (let i = 0; i < data.owner.length; i++) {
      formdata.append(`assignTo[${i}]`, data.owner[i]);
    }
    for (let item of data.fileProject) {
      formdata.append("fileProject", item);
    }
    for (let item of data.papersProject) {
      formdata.append("papersProject", item);
    }

    mutate(formdata);
  };

  useEffect(() => {
    if (isError) {
      if (error?.response?.data?.validationErrors) {
        setErrorMessage(
          <ListErrorComponent
            messages={error?.response?.data?.validationErrors?.split(",")}
          />
        );
      } else if (error?.response?.data?.message) {
        setErrorMessage(
          <p className="text-lg font-medium">
            {error?.response?.data?.message}
          </p>
        );
      }
    }
    return () => {
      setErrorMessage("حدث خطأ ما");
    };
  }, [isError]);

  console.log(projects, "projectsprojects");
  return (
    <Fragment>
      {isLoading ? <LoadingPage /> : null}
      <form
        className="flex flex-col gap-4  max-h-[700px]"
        onSubmit={handleSubmit(onSubmit)}
        encType="multipart/form-data"
      >
        {isExist === true ? (
          <FormModal title={"بحث عن المشروع"}>
            <div className="grid grid-cols-2">
              <FormControl fullWidth>
                <Controller
                  control={control}
                  name="projectId"
                  rules={{
                    required: "يجب اختيار مشروع",
                  }}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Select
                      fullWidth
                      onChange={onChange}
                      onBlur={onBlur}
                      label={"اسم المشروع"}
                      placeholder="ادخل اسم المشروع"
                      OptionbackgroundColor="#414162"
                      options={projects?.map(({ _id, projectName }) => ({
                        label: projectName,
                        value: _id,
                      }))}
                      mandatory
                    />
                  )}
                />

                <CustErrorMessage name={"projectId"} errors={errors} />
              </FormControl>
            </div>
          </FormModal>
        ) : isExist === false ? (
          <FormModal title={"اضافة مشروع جديد"}>
            <div className="grid grid-cols-2 gap-4 mb-3">
              <FormControl fullWidth>
                <InputLabel id="new-project" label={"اسم مشروع جديد"} />
                <CustomInput
                  control={control}
                  name="projectName"
                  placeholder="اسم مشروع جديد"
                  rules={{
                    required: "يجب كتابة اسم المشروع",
                    minLength: {
                      value: 3,
                      message: "لا يقل عن 3 حروف",
                    },
                    maxLength: {
                      value: 100,
                      message: "لا يقل عن 100 حروف",
                    },
                    pattern: {
                      value: /^[A-Za-z\u0600-\u06FF ]{3,}$/i,
                      message:
                        "يجب ان تتكون من حروف عربى فقط او انجليزية فقط رقم",
                    },
                  }}
                  id="new-project"
                />

                <CustErrorMessage name={"projectName"} errors={errors} />
              </FormControl>

              <FormControl fullWidth>
                <Controller
                  control={control}
                  name="projectType"
                  rules={{
                    required: "يجب اختيار نوع المشروع",
                  }}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Select
                      fullWidth
                      onChange={onChange}
                      onBlur={onBlur}
                      label={"نوع المشروع جديد"}
                      placeholder="اخل نوع المشروع"
                      OptionbackgroundColor="#414162"
                      options={projectTypes}
                      mandatory
                    />
                  )}
                />

                <CustErrorMessage name={"projectType"} errors={errors} />
              </FormControl>
            </div>
            <div className="grid grid-cols-2 gap-4">
              <FormControl fullWidth>
                <InputLabel id="new-project" label={"موقع المشروع"} />
                <CustomInput
                  name={"buildingLocation"}
                  control={control}
                  rules={{
                    required: "يجب اضافة موقع المشروع",
                    minLength: 3,
                  }}
                />
                <CustErrorMessage name={"buildingLocation"} errors={errors} />
              </FormControl>
              <FormControl fullWidth>
                <InputLabel id="new-project" label={"نوع العميل"} />
                <CustomInput
                  name={"clientType"}
                  control={control}
                  rules={{
                    required: "يجب اضافة نوع العميل",
                    minLength: {
                      value: 3,
                      message: "لا يقل عن 3 حروف",
                    },
                    maxLength: {
                      value: 500,
                      message: "لا يقل عن 500 حروف",
                    },
                  }}
                />
                <CustErrorMessage name={"clientType"} errors={errors} />
              </FormControl>
            </div>
          </FormModal>
        ) : null}

        <FormModal title={"تفاصيل المهمة"}>
          <div className="grid grid-cols-2 gap-4 mb-3">
            <FormControl fullWidth>
              <Controller
                control={control}
                name="owner"
                rules={{
                  required: "يجب اختيار المسئولين",
                }}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <Select
                    fullWidth
                    onChange={onChange}
                    onBlur={onBlur}
                    label={"اسم المسؤل"}
                    placeholder="ادخل اسم المسؤل"
                    OptionbackgroundColor="#414162"
                    isMulti={true}
                    options={supervisors?.map(
                      ({ _id, firstName, lastName, image, role }) => ({
                        label: `${firstName} ${lastName}`,
                        value: _id,
                        image,
                        role,
                      })
                    )}
                    components={{ Option: CustomOption }}
                    mandatory
                  />
                )}
              />

              <CustErrorMessage name={"owner"} errors={errors} />
            </FormControl>
          </div>
          <div className="grid grid-cols-2 gap-4 mb-3">
            <FormControl fullWidth>
              <InputLabel id="startDate" label={"تاريخ الاستلام"} />
              <CustomDatePicker
                control={control}
                name="startDate"
                rules={{ required: "يجب اضافة تاريخ الاستلام" }}
              />
              <CustErrorMessage name={"startDate"} errors={errors} />
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id="endDate" label={"تاريخ التسليم"} />
              <CustomDatePicker
                control={control}
                name="endDate"
                rules={{ required: "يجب اضافة تاريخ التسليم" }}
              />
              <CustErrorMessage name={"endDate"} errors={errors} />
            </FormControl>
          </div>
          <FormControl fullWidth>
            <InputLabel id="new-project-name" label={"وصف المشروع"} />

            <CustomTextArea
              control={control}
              name="planDescription"
              placeholder={"اكتب ملاحظات .................................."}
            />
          </FormControl>
        </FormModal>

        <FormModal title={"ملاحظات العميل"}>
          <div className="">
            <InputLabel id="new-project-name" label={"ملاحظات العميل"} />

            <CustomTextArea
              control={control}
              name="noteClient"
              placeholder={"اكتب ملاحظات .................................."}
            />
          </div>
        </FormModal>

        <FormModal title={"ملفات المشروع"}>
          <CustomeInputFile
            register={{
              ...register("fileProject", {
                validate: validateFiles,
              }),
            }}
            name="fileProject"
            multiple={true}
          />
          <CustErrorMessage name={"fileProject"} errors={errors} />
        </FormModal>
        <FormModal title={"مرفقات المشروع"}>
          <CustomeInputFile
            register={{
              ...register("papersProject", {
                validate: validateFiles,
              }),
            }}
            name="papersProject"
            multiple={true}
          />
          <CustErrorMessage name={"papersProject"} errors={errors} />
        </FormModal>

        <SaveButton />
        <DevTool control={control} />
      </form>

      <SuccessfullModal
        handleClose={() => {
          handleCloseSuccess();
          navigate("/System/tasks/plans");
        }}
        message={"تمت اضافة المشروع بنجاح"}
        show={showSuccess}
      />
      <SuccessfullModal
        handleClose={() => {
          handleCloseError();
          //   navigate("/System/tasks/plans");
        }}
        message={errorMessage}
        show={showError}
        status="error"
      />
    </Fragment>
  );
};

export default AddPlanForm;
