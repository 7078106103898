import { useState } from "react";

export const useShowMenu = () => {
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);
    return {
      show,
      handleShow,
      handleClose,
    };
  };