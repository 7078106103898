import React, { useContext, useState } from "react";
import { SearchComponent } from "../../../../Components/SearchComponent/SearchComponent";
import { FormModal } from "../../PlanModel/components/FormModal";
import { OptionsButton } from "../../Settings/OptionsButton";
import { TechnicalSupportContext } from "./TechnicalSupportProvider";
import {
  useDeleteTaskIssue,
  useGetAllTaskIssue,
} from "../../../../hooks/fetchers/TechnicalSupportPurcheseOrder";
import useSuccessfullModal from "../../../../Components/Modals/hooks/useSuccessfullModal";
import LoadingPage from "../../../../Components/feedback/LoadingPage";
import RemoveModal from "../../Settings/RemoveModal";
import SuccessfullModal from "../../../../Components/Modals/SuccessfullModal";

const TechnicalSupportTermsTable = () => {
  const [search, setSearch] = useState("");
  const handleSearch = (value) => {
    setSearch(value);
  };
  const {
    activeCategory,
    setActiveCategory,
    setOpenTerm,
    setUpdatedItem,
    updatedItem,
  } = useContext(TechnicalSupportContext);
  const { data } = useGetAllTaskIssue({
    search,
  });
  const handleShow = (item) => {
    setOpenTerm(true);
    setActiveCategory(item?._id);
    setUpdatedItem(item);
  };
  const {
    showSuccess,
    showError,
    handleShowSuccess,
    handleShowError,
    handleCloseSuccess,
    handleCloseError,
  } = useSuccessfullModal();

  const [showDelete, setShowDelete] = useState(false);
  const { mutate: deleteMutation, isLoading: isDeleteLoading } =
    useDeleteTaskIssue(handleShowSuccess, handleShowError);

  const handleDelete = (item) => {
    setActiveCategory(item?._id);
    setUpdatedItem(item);
    setShowDelete(true);
  };
  const [err, setError] = useState("");

  return isDeleteLoading ? (
    <LoadingPage />
  ) : (
    <div className="py-4 flex flex-col gap-4 h-full">
      <SearchComponent
        className="m-auto w-[95%] focus-visible:border-[#E4A11B]"
        background={"var(--main-navy)"}
        border="border !border-[#E4A11B] focus-visible:border-[#E4A11B]"
        value={search}
        handleChange={handleSearch}
      />
      <FormModal
        title={"كل البنود "}
        className={"h-full max-h-[700px] overflow-y-auto scrollbar-thin scrollbar-track-transparent scrollbar-thumb-[#EFAA20]"}
      >
        <>
          {data?.length ? (
            data?.map((item) => (
              <div
                className={`flex w-full justify-between items-center px-2 text-mainCustomGray  text-base `}
              >
                <button className="w-full text-mainText text-right py-3">
                  {item?.name}
                </button>
                <OptionsButton
                  id={item?.id}
                  onUpdate={() => handleShow(item)}
                  onDelete={() => handleDelete(item)}
                />
              </div>
            ))
          ) : (
            <p className="text-xl text-center text-mainText">
              لا يوجد بيانات لعرضها
            </p>
          )}
        </>
      </FormModal>
      <RemoveModal
        title={"التاكيد"}
        show={showDelete}
        handleClose={() => setShowDelete(false)}
        onSave={() => {
          deleteMutation(updatedItem?._id, {
            onError: (err) => {
              setError(
                err?.response?.data?.validationErrors ||
                  err?.response?.data?.message
              );
            },
          });
        }}
        stillOpen={true}
      />
      <SuccessfullModal
        handleClose={() => {
          setShowDelete(false);
          handleCloseSuccess();
        }}
        message={"تمت الحذف بنجاح"}
        show={showSuccess}
      />
      <SuccessfullModal
        handleClose={handleCloseError}
        message={err}
        show={showError}
        status="error"
      />
    </div>
  );
};

export default TechnicalSupportTermsTable;
