import Calendar from "react-calendar";
import { Container } from "react-bootstrap";
import { useEffect, useRef, useState } from "react";
import style from "./MainSystem.module.css";
import {
  useGetDashboardDepartmentStatistics,
  useGetDashboardPlansStatistics,
  useGetDashboardTasksStatistics,
} from "../../../hooks/fetchers/DashboardStatistics.jsx";
import { DashboradPageWrapper } from "./components/DashboradPageWrapper.jsx";
import CategoriesStatistics from "./components/CategoriesStatistics.jsx";
import CategoriesTable from "./components/CategoriesTable.jsx";
import { roles } from "../../../helper/fetchers/Tasks.jsx";
import { useGetStatistics } from "../Tasks/hooks/useGetStatistics.jsx";
import { CheckRole } from "../../../helper/utils.jsx";
import { TasksStatistics } from "../Tasks/components/TasksStatistics.jsx";
import CustomChart from "./components/charts/CustomChart";
import LoadingPage from "../../../Components/feedback/LoadingPage.jsx";
import ReminderCalendar from "../reminderPage/components/ReminderCalendar.jsx";
import { useGetAllReminders } from "../reminderPage/hooks/reminderForm.jsx";
import { useNavigate } from "react-router-dom";

export const MainSystem = () => {
  const [pageLoading, setPageLoading] = useState(false);
  const {
    data: dashboardStatistics,
    isLoading: dashboardStatisticsLoading,
    isError: dashboardStatisticsError,
  } = useGetDashboardDepartmentStatistics();
  // console.log(dashboardStatistics, "====");
  const {
    data: dashboardPlansStatistics,
    isLoading: dashboardPlansStatisticsLoading,
    isError: dashboardPlansStatisticsError,
  } = useGetDashboardPlansStatistics();
  const {
    data: dashboardTasksStatistics,
    isLoading: dashboardTasksStatisticsLoading,
    isError: dashboardTasksStatisticsError,
  } = useGetDashboardTasksStatistics();
  const { data: statistics } = useGetStatistics();
  useEffect(() => {
    setPageLoading(
      dashboardStatisticsLoading ||
        dashboardPlansStatisticsLoading ||
        dashboardTasksStatisticsLoading
    );
  }, [
    dashboardStatisticsLoading,
    dashboardPlansStatisticsLoading,
    dashboardTasksStatisticsLoading,
  ]);

  const { data: reminderData } = useGetAllReminders();

  const activeDayRef = useRef(null);
  const setIsActiveDay = (val) => {
    activeDayRef.current = val;
  };
  const navigate = useNavigate();
  return (
    <DashboradPageWrapper>
      <div className="flex justify-center h-full w-full">
        <div className={`${style.MainSystem} flex justify-center w-full`}>
          {pageLoading ? (
            <LoadingPage />
          ) : (
            <Container>
              {CheckRole(roles["manager"]) ? (
                <div className="mx-auto">
                  <div className="flex items-start gap-3">
                    <div
                      className={`item p-3 flex h-[250px] overflow-y-auto scrollbar-thin scrollbar-track-transparent scrollbar-thumb-[#EFAA20] ${style.cardsBackground}`}
                    >
                      {!dashboardTasksStatisticsError &&
                        (!dashboardTasksStatisticsLoading ? (
                          <CustomChart
                            data={dashboardTasksStatistics}
                            chartTitle="احصائيات الموظفين"
                            title="عدد المهام"
                            mainColTitle="الاسم"
                          />
                        ) : (
                          "Loading..."
                        ))}
                    </div>
                    <div
                      className={`item p-3 flex h-[250px] overflow-y-auto scrollbar-thin scrollbar-track-transparent scrollbar-thumb-[#EFAA20] ${style.cardsBackground}`}
                    >
                      {!dashboardPlansStatisticsError &&
                        (!dashboardPlansStatisticsLoading ? (
                          <CustomChart
                            data={dashboardPlansStatistics}
                            chartTitle="احصائيات المخططات"
                            title="عدد المخططات"
                            mainColTitle="الاسم"
                          />
                        ) : (
                          "Loading..."
                        ))}
                    </div>
                  </div>
                </div>
              ) : CheckRole(roles["user"]) ||
                CheckRole(roles["auditor"]) ||
                CheckRole(roles["administrator"]) ? (
                <div className="mx-auto">
                  <div className={`item p-3 ${style.cardsBackground}`}>
                    <TasksStatistics data={statistics} />
                  </div>
                </div>
              ) : (
                <div className="grid grid-cols-1 xl:grid-cols-2 gap-2 mx-auto">
                  <div className={`item p-3 ${style.cardsBackground}`}>
                    <CategoriesStatistics />
                  </div>
                  <div
                    className={`item p-3 flex h-[250px] overflow-y-auto scrollbar-none scrollbar-track-transparent scrollbar-thumb-[#EFAA20] ${style.cardsBackground}`}
                  >
                    {!dashboardStatisticsError &&
                      (!dashboardStatisticsLoading ? (
                        <CustomChart
                          data={dashboardStatistics?.Total?.departments}
                          chartTitle="احصائيات الموظفين"
                          title="عدد الموظفين"
                          mainColTitle="الاسم"
                        />
                      ) : (
                        "Loading..."
                      ))}
                  </div>
                </div>
              )}

              <div className="row mx-auto">
                <div className="col-lg-8 col-md-12 mb-2 mx-auto">
                  <CategoriesTable />
                </div>
                <div className="col-lg-4 col-md-12 mb-3 flex items-start justify-end">
                  <div>
                    {/* <Calendar
                    value={calendarValue}
                    onChange={setCalendarValue}
                    className="bg-mainNavy border-none shadow-2xl rounded-2xl max-w-[280px]"
                  /> */}
                    <ReminderCalendar
                      setIsActiveDay={setIsActiveDay}
                      data={reminderData}
                      onClickDay={(e) => {
                        // console.log("isActiveDay", activeDayRef);
                        if (activeDayRef && activeDayRef.current) {
                          navigate("/System/reminder");
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
            </Container>
          )}
        </div>
      </div>
    </DashboradPageWrapper>
  );
};
