/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from "react";
import Input from "../../../FormHandler/Input";
import Select from "../../../FormHandler/Select";
import { UseInput } from "../../../../hooks";
import { Form } from "react-bootstrap";
import { useStepContext } from "../../../../Context/StepContext";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
const AddProjectStepTwo = (props) => {
  const { userData, setUserData } = useStepContext();
  const identityNumber = UseInput(
    `${userData?.identityNumber ? userData.identityNumber : ""}`,
    "",
    true
  );

  const [idPhoto, setIdPhoto] = useState(
    userData?.idPhoto ? userData.idPhoto : null
  );

  const clientTypeRoles = [
    {
      label: "فردي",
      value: 3,
    },
    {
      label: "شركه  او مؤسسة",
      value: 2,
    },
    {
      label: "حكومي او مستثمر",
      value: 1,
    },
  ];

  const identityTypeRoles = [
    {
      label: "تجاري",
      value: 2,
    },
    {
      label: "هوية",
      value: 1,
    },
  ];

  const [clientType, setClientType] = useState(
    userData.clientType
      ? clientTypeRoles?.find((x) => x?.value === userData?.clientType)
      : ""
  );
  const [identityType, setIdentityType] = useState(
    userData.identityType
      ? identityTypeRoles?.find((x) => x?.value === userData?.identityType)
      : ""
  );
  const [email, setEmail] = useState(userData?.email ? userData.email : "");
  const [commercialNumber, setCommercialNumber] = useState(userData?.commercialNumber ? userData.commercialNumber : "");
  const [phone, setPhone] = useState(userData?.phone ? userData.phone : "");
  const [taxNumber, setTaxNumber] = useState(
    userData?.taxNumber ? userData.taxNumber : ""
  );

  // const identityType = UseSelect("", "", "Select");
  const [IsVaildState, setIsVaildState] = useState(false);
  const signalParent = (isValid) => {
    setIsVaildState(isValid);
    // props.signalIfValid(isValid);
  };

  useMemo(() => {
    if (
      clientType?.value &&
      identityType?.value &&
      email &&
      commercialNumber &&
      identityNumber.value &&
      idPhoto &&
      phone
    ) {
      const updatedUserData = {
        ...userData,
        clientType: clientType.value,
        identityType: identityType.value,
        identityNumber: identityNumber.value,
        email: email,
        commercialNumber: commercialNumber,
        idPhoto,
        phone: phone.length > 6 ? phone : "",
        taxNumber: taxNumber,
      };
      setUserData(updatedUserData);

      signalParent(true);
    } else {
      signalParent(false);
      const updatedUserData = {
        ...userData,
        clientType: clientType.value,
        identityType: identityType.value,
        identityNumber: identityNumber.value,
        email: email,
        commercialNumber: commercialNumber,
        idPhoto,
        taxNumber: taxNumber,
        phone: phone.length > 6 ? phone : "",
      };
      setUserData(updatedUserData);
    }
  }, [
    clientType?.value,
    identityType?.value,
    email,
    commercialNumber,
    idPhoto,
    phone,
    taxNumber,
    identityType.value,
    identityNumber?.value,
  ]);

  useEffect(() => {
    signalParent(IsVaildState);
  }, [IsVaildState]);
  console.log(phone, "=-=-=-=-=-=");
  return (
    <fieldset className="addProjectStep mx-auto">
      <legend className="text-center text-mainText">اضافة بيانات المالك</legend>
      <Form className="row w-100 m-auto ">
        <div className="col-md-6 mb-4">
          <Select
            label={" نوع العميل"}
            placeholder="اختر..."
            OptionbackgroundColor="#414162"
            value={clientType}
            onChange={(e) => {
              setClientType(e);
              setUserData((prevData) => ({
                ...prevData,
                clientType: e?.value,
              }));
            }}
            options={clientTypeRoles}
            mandatory
          />
        </div>

        <div className="col-md-6 mb-4">
          <Select
            label={" نوع الهوية  "}
            placeholder="اختر..."
            OptionbackgroundColor="#414162"
            value={identityType}
            onChange={(e) => setIdentityType(e)}
            options={identityTypeRoles}
            mandatory
          />
        </div>{+identityType.value === 2 ?  <div className="col-md-6 mb-4"> <Input
            placeholder="ادخل رقم السجل التجارى"
            label={"رقم السجل التجارى"}
            value={commercialNumber}
            onChange={(e) => setCommercialNumber(e?.target?.value)}
            mandatory
          /> </div>: null}
        <div className="col-md-6 mb-4">
          <Input
            placeholder="ادخل البريد الالكتروني"
            label={"البريد الالكتروني"}
            value={email}
            onChange={(e) => setEmail(e?.target?.value)}
            mandatory
          />
        </div>
        <div className="col-md-6 mb-4">
          <Input
            placeholder="ادخل  رقم الهوية"
            label={"رقم الهوية"}
            {...identityNumber.bind}
            mandatory
          />
        </div>

        <div className="col-md-6 mb-4">
          <label className="text-mainText">
            رقم الجوال <span className="text-danger">*</span>
          </label>
          <PhoneInput
            defaultCountry="sa"
            placeholder="ادخل رقم الجوال"
            value={phone}
            onChange={(phone) => setPhone(phone)}
          />
          {/* <Input
            placeholder="ادخل  رقم الجوال"
            label={"رقم الجوال"}
            value={phone}
            onChange={(e) => setPhone(e?.target?.value)}
            mandatory
          /> */}
        </div>
        <div className="col-md-6 mb-4">
          <Input
            placeholder="ادخل رقم الشهادة الضريبية"
            label={"رقم الشهادة الضربية"}
            value={taxNumber}
            onChange={(e) => setTaxNumber(e?.target?.value)}
          />
        </div>
        <div className="col-md-12 mb-4">
          <Form.Group controlId="formBasicImage">
            <Form.Label className="d-flex flex-column gap-2 ">
              <span> صورة الهويه</span>
            </Form.Label>

            <Form.Control
              type="file"
              multiple="multiple"
              width={100}
              height={100}
              className="choose-file-input"
              placeholder="صورة الهويه"
              name="imageFile"
              onChange={(e) => setIdPhoto(e.currentTarget.files[0])}
            />
          </Form.Group>
        </div>
      </Form>
    </fieldset>
  );
};

export default AddProjectStepTwo;
