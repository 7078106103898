import React from "react";
import CustomTable from "../../../../../Components/Table";
import { TableRow } from "../../../../../Components/Table/TableRow";
import { TableCell } from "../../../../../Components/Table/TableCell";
import {
  GenerateColumnsNames,
  clientTypeEnum,
  projectTypeEnum,
  statusEnum,
} from "../../../../../helper/utils";
import moment from "moment";
import { StatusDisplay } from "../../../Tasks/components/StatusDisplay";
const columns = GenerateColumnsNames(
  "م",
  "اسم المشروع",
  "نوع المشروع",
  "موقع المشروع",
  "نوع العميل",
  "تاريخ التسليم	",
  "الحالة"
);
export default function PlansTable({ allProjects }) {
  return (
    <CustomTable
      paginationProps={{
        className: "hidden",
      }}
      bordered={false}
      TableHeaderProps={{
        className: "bg-[#7676764D]",
      }}
      TableHeadProps={{
        className:
          "text-end text-[10px] border-none !font-semibold p-1 text-mainText textColor",
      }}
      columns={columns}
      data={allProjects}
    >
      {allProjects && allProjects.length > 0
        ? allProjects.map(
            (
              item,

              index
            ) => (
              <TableRow
                className={`my-2 border !border-[#efaa207f] ${
                  index % 2 === 0 ? "bg-mainNavy" : ""
                }`}
                key={item?._id}
              >
                <TableCell>{index + 1}</TableCell>
                <TableCell>{item?.projectName || "لا توجد بيانات"}</TableCell>
                <TableCell>
                  {projectTypeEnum[item?.projectType] || "لا توجد بيانات"}
                </TableCell>
                <TableCell>
                  {item?.buildingLocation || "لا توجد بيانات"}
                </TableCell>
                <TableCell>{clientTypeEnum[+item?.clientType]}</TableCell>

                <TableCell>
                  {moment(item?.startDate).format("YYYY-MM-DD") ||
                    "لا توجد بيانات"}
                </TableCell>

                <TableCell>
                  <StatusDisplay
                    title={statusEnum[+item?.status]?.title || "لا توجد بيانات"}
                    color={statusEnum[+item?.status]?.color}
                  />
                </TableCell>
              </TableRow>
            )
          )
        : null}
    </CustomTable>
  );
}
