import { Fragment, useEffect, useRef, useState } from "react";

import { toBlob } from "html-to-image";
import { Document, Page, Image, StyleSheet } from "@react-pdf/renderer";
import moment from "moment";
import SectionElement from "../../../../Components/Preview/components/SectionElement";
import PdfPreview from "../../../../Components/Preview/CustomPdfPreview";
import TableElement from "../../../../Components/Preview/components/Table";
import { projectTypeEnum, statusEnum } from "../../../../helper/utils";
import PdfDocument from "./PdfDocument";
import { columnsUserTasks } from "../consts";


const PrintUserDetailsFile = ({ show, onHide, data }) => {
  const refs = useRef([]);
  const [imageUrls, setImageUrls] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (show) {
      const captureImages = async () => {
        setLoading(true);
        const blobs = await Promise.all(
          refs.current.map((ref) => toBlob(ref, { pixelRatio: 2 }))
        );
        const urls = blobs.map((blob) => URL.createObjectURL(blob));
        setImageUrls(urls);
        setLoading(false);
      };

      captureImages();
    }
  }, [show]);
  // console.log("PrintUserDetailsFile", data);
  return (
    <>
      <PdfPreview show={show} onHide={onHide}>
        {imageUrls.length > 0 && (
          <PdfPreview.DownloadButton
            document={<PdfDocument imageUrls={imageUrls} />}
            loading={loading}
          />
        )}
        {data?.map((item, index) => (
          <Fragment key={index}>
            <div ref={(el) => (refs.current[index] = el)}
              className="rounded-t w-full"
            >
              <PdfPreview.Header
                createdAt={`تاريخ الانشاء : ${moment(item?.createdAt).format("YYYY-MM-DD")}`}
                titleAr={"مستخدم"}
                titleEn={"User"}
              />

              <PdfPreview.Body>
                <div className="flex justify-between items-center">
                  <PdfPreview.SectionTitle title={"بيانات المستخدم"} />
                  <div className="flex justify-between items-center">
                    <SectionElement
                      title="الكود الوظيفي"
                      disc={item?.employeCode}
                      className="w-1/2 gap-0 px-2"
                    />
                    <SectionElement
                      title="البلد"
                      disc={item?.country}
                      className="w-1/2 gap-0 px-2"
                    />
                  </div>
                </div>

                <PdfPreview.SectionWrapper>
                  <SectionElement
                    title="اسم المستخدم"
                    disc={`${item?.firstName} ${item?.lastName}`}
                  />
                  <SectionElement title="الصلاحيه" disc={item?.role} />
                  <SectionElement title="رقم الجوال" disc={item?.phone} />
                  <SectionElement title="القسم" disc={item?.department} />
                  <SectionElement
                    title="بدآ العمل في"
                    disc={moment(item?.startWork).format("YYYY-MM-DD")}
                  />
                  <SectionElement title="المرتب" disc={`${item?.salary?.basicSalary} ريال`} />
                </PdfPreview.SectionWrapper>

                {/* <div className="">
                  <PdfPreview.SectionTitle title={"معلومات المستخدم"} />
                  <TableElement
                    columns={columnsUserInfor}
                    data={item?.vacations}
                  >
                    {item?.vacations?.map(
                      (
                        { _id,approvalManager,employeeId,startDate,vactionCategoryId, projectName,projectType, vacationDuration, from, endDate, assign },
                        index
                      ) => (
                        <TableElement.TableRow className={`my-2`} key={index}>
                          <TableElement.TableCell>
                            {index + 1}
                          </TableElement.TableCell>
                          <TableElement.TableCell>
                            {projectName}
                          </TableElement.TableCell>
                          <TableElement.TableCell>
                            {projectType}
                          </TableElement.TableCell>
                          <TableElement.TableCell>
                            {from}
                          </TableElement.TableCell>
                          <TableElement.TableCell>{endDate}</TableElement.TableCell>
                          <TableElement.TableCell>
                            {assign}
                          </TableElement.TableCell>
                        </TableElement.TableRow>
                      )
                    )}
                  </TableElement>
                </div> */}

                <div className="">
                  <PdfPreview.SectionTitle title={"معلومات مهنيه"} />
                  <PdfPreview.SectionWrapper>
                    <div className="flex items-center gap-3">
                      {/* <SectionElement
                        title="عدد المشاريع"
                        disc={``}
                        className="flex-col items-start w-1/3"
                        titleClassName="w-fit"
                      /> */}
                      <SectionElement
                        title="عدد المهام"
                        disc={item?.tasksCount}
                        className="flex-col items-start w-1/3"
                        titleClassName="w-fit"
                      />
                      <SectionElement
                        title="الاهداف"
                        disc={item?.goals}
                        className="flex-col items-start w-1/3"
                        titleClassName="w-fit"
                      />
                    </div>
                    <div className="flex items-center gap-3">
                      <SectionElement
                        title="المهام قيد التنفيذ"
                        disc={item?.tasksStats?.inprogressTasks}
                        className="flex-col items-start w-1/3"
                        titleClassName="w-fit"
                      />
                      <SectionElement
                        title="المهام مكتمله"
                        disc={item?.tasksStats?.completedTasks}
                        className="flex-col items-start w-1/3"
                        titleClassName="w-fit"
                      />
                      <SectionElement
                        title="المهام متآخره"
                        disc={item?.tasksStats?.latedTasks}
                        className="flex-col items-start w-1/3"
                        titleClassName="w-fit"
                      />
                    </div>
                  </PdfPreview.SectionWrapper>
                </div>

                {/* <div className="">
                  <PdfPreview.SectionTitle title={"مشاريع"} />
                  --------------------------------------------
                  <PdfPreview.SectionWrapper>
                    <p className="text-base">
                      {item?.paperProject?.length
                        ? "يوجد مرفقات"
                        : "لا يوجد مرفقات"}
                    </p>
                    --------------------------------------------
                  </PdfPreview.SectionWrapper>
                </div> */}
                <div className="">
                  <PdfPreview.SectionTitle title={"المهام"} />
                  
                    <TableElement
                      columns={columnsUserTasks}
                      data={item?.tasks}
                    >
                      {item?.tasks?.map(
                        (
                          { projectName, projectType, startDate, endDate, status },
                          index
                        ) => (
                          <TableElement.TableRow className={`my-2`} key={index}>
                            <TableElement.TableCell>
                              {index + 1}
                            </TableElement.TableCell>
                            <TableElement.TableCell>
                              {projectName}
                            </TableElement.TableCell>
                            <TableElement.TableCell>
                              {projectTypeEnum[projectType]}
                            </TableElement.TableCell>
                            <TableElement.TableCell>
                              {moment(startDate).format("YYYY-MM-DD")}
                            </TableElement.TableCell>
                            <TableElement.TableCell>
                              {moment(endDate).format("YYYY-MM-DD")}
                            </TableElement.TableCell>
                            <TableElement.TableCell>
                              {statusEnum[status]?.title}
                            </TableElement.TableCell>
                          </TableElement.TableRow>
                        )
                      )}
                    </TableElement>
                  
                </div>
              </PdfPreview.Body>
            </div>
          </Fragment>
        ))}
      </PdfPreview>
    </>
  );
};

// const Item = ({ title = " ", disc = "", className = "" }) => {
//   return (
//     <>
//       <div className={twMerge("flex items-center gap-3", className)}>
//         <p className="w-20">{title}</p>
//         <div className="bg-[#F9EBD5] py-2 px-4 w-full min-h-[2rem]">
//           <p>{disc}</p>
//         </div>
//       </div>
//     </>
//   );
// };




export default PrintUserDetailsFile;
