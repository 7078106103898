import React, { useContext, useEffect } from "react";
import { CountriesChart } from "../../Users/AllUsersChart/CountriesChart";
import { AddHrType } from "../../../../Context/AddHr";
import AddUpdateUser from "../../../../Components/System/Hr/AddUpdateUser/AddUpdateUser";

const EmployeesManagment = () => {
  const { openHr, setOpenHr, HrType, setHrType } = useContext(AddHrType);

  useEffect(() => {
    setHrType("Employees");
  }, [openHr, HrType]);
  return (
    <>
      {openHr === true && HrType == "Employees" ? (
        <AddUpdateUser />
      ) : (
        <CountriesChart />
      )}
    </>
  );
};

export default EmployeesManagment;
