import React, { useCallback, useEffect, useState } from "react";
import "./index.css";
import AllRequestsPieChart from "../../../../Components/System/Requests/AllRequestsPieChart/AllRequestsPieChart";
import AllRequestLineChart from "../../../../Components/System/Requests/AllRequestlineChart/AllRequestLineChart";
import {
  getAllRequests,
  getAllRequestsStats,
} from "../../../../helper/fetchers/Requests";
import {
  useGetAllRequestsStats,
  useGetRequestsReportMonthly,
} from "../../../../hooks/fetchers/Requests";
import { Spinner } from "react-bootstrap";
export const AllRequestsCharts = () => {
  const { data, isLoading, isError } = useGetAllRequestsStats();
  const {
    data: monthlyData,
    isLoading: monthlyIsLoading,
    isError: monthlyIsError,
  } = useGetRequestsReportMonthly();

  return (
    <div className="p-4 flex gap-5 AllRequests items-center h-100 flex-col">
      <div className="AllRequestsPieChartContainer w-100  d-flex align-items-center justify-content-center">
        {isLoading && <Spinner />}
        {!isLoading && !isError ? (
          <AllRequestsPieChart data={data?.data} />
        ) : null}
      </div>
      <div className="AllRequestsLineChartContainer w-100  d-flex align-items-center justify-content-center">
        {monthlyIsLoading ? <Spinner/> : null}
        {monthlyIsError ? <p className="text-red-400">يوجد خطأ ما</p> : null}
        {!monthlyIsLoading && !monthlyIsError ? <AllRequestLineChart design={monthlyData?.design} supervising={monthlyData?.supervising}  /> : null}
        
      </div>
    </div>
  );
};
