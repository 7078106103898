import React, { useState } from "react";

import { DetectForm } from "../components/Forms/DetectForm";
import AddTaskForm from "../components/Forms/AddTaskForm";
import { PageWrapper } from "../components/Forms/AddPageWrapper";
export default function AddTask() {
  const [isExist, setIsExist] = useState(null);
  return (
    <PageWrapper title={"إضافة مهمة جديدة"}>
      {isExist === null && <DetectForm setIsExist={setIsExist} />}

      {isExist !== null && <AddTaskForm isExist={isExist} />}
    </PageWrapper>
  );
}
