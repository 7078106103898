import React from "react";
import styles from "./Revenues.module.css";
import PieChart from "../../../../Components/pieChart";
import ColumnChart from "../../../../Components/ColumnChart";
import { Image, Spinner } from "react-bootstrap";
import { ProgressBar } from "react-bootstrap";
import "./Revenues.css";
import { useGetFinancialReportMonthly } from "../../../../hooks/fetchers/Financial";
import ApexColumn from "../ApexColumn";

const Revenues = () => {
  const { data, isLoading, isError } = useGetFinancialReportMonthly();

  
  return (
    <div className="d-flex flex-column justify-content-between gap-5">
      <div className={`${styles.RevenuesPieChartContainer}   `}>
        <p className="text-mainText text-start">كل المطالبات</p>
        <PieChart
          label="الايردات"
          colors={["#03795D", "#E40038"]}
          width={450}
          labels={["تم الدفع 6 دفعات", "لم يتم الدفع 3 دفعات"]}
          series={[6, 3]}
        />
      </div>

      <fieldset className={`${styles.RevenuesColumnChartContainer}   `}>
        <legend className="text-mainText text-center">كل الايرادات</legend>
        <div
          className={`${styles.revenuesbg} revenuesColumnChartContainer pt-3 mx-auto`}
        >
          {isLoading ? <Spinner /> : null}
          {isError ? <p className="text-red-400">يوجد خطأ ما</p> : null}
          {!isLoading && !isError ? (
            <ApexColumn data={data} colors={["var(--main-text)"]} />
          ) : null}

          <div className="p-3 w-100  d-flex justify-content-between gap-4">
            <div className={`${styles.Revnueslider} `}>
              <Image
                src={process.env.PUBLIC_URL + "/icons/deprtmants.png"}
                alt="deprtment img"
              />
              <p className="text-[#A0AEC0]">شركة / مؤسسة</p>
              <span className="text-mainText"> 10</span>
              <ProgressBar variant="warning" className="mt-1" now={37} />
            </div>
            <div className={`${styles.Revnueslider} `}>
              <Image
                src={process.env.PUBLIC_URL + "/icons/deprtmants.png"}
                alt="deprtment img"
              />
              <p className="text-[#A0AEC0]    ">حكومى / مستسمر </p>
              <span className="text-mainText   "> 10</span>
              <ProgressBar variant="warning" className="mt-1" now={37} />
            </div>
            <div className={`${styles.Revnueslider} `}>
              <Image
                src={process.env.PUBLIC_URL + "/icons/deprtmants.png"}
                alt="deprtment img"
              />
              <p className="text-[#A0AEC0]"> فردي </p>
              <span className="text-mainText   "> 2</span>
              <ProgressBar variant="warning" className="mt-1" now={37} />
            </div>
          </div>
        </div>
      </fieldset>
    </div>
  );
};

export default Revenues;
