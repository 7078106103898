import style from "../MainSystem.module.css";
import { createContext, useState } from "react";
import { useQuery } from "react-query";
import { myAxiosJson } from "../../../../helper/https";
import CategoryFilterMenu from "./CategoryFilterMenu";
import ClientsTable from "./Tables/ClientsTable";
import UsersTable from "./Tables/UsersTable";
import ProjectsTable from "./Tables/ProjectsTable";
import { useGetAllPlans } from "../../../../hooks/fetchers/Plans";
import PlansTable from "./Tables/PlansTable";
import TasksTable from "./Tables/TasksTable";
import { useGetAllTasks } from "../../../../hooks/fetchers/Tasks";
import { CheckRole } from "../../../../helper/utils";
import { roles } from "../../../../helper/fetchers/Tasks";
export const DashboardCategoriesTableContext = createContext();

const filterKeys = {
  CLIENTS: 0,
  USERS: 1,
  PROJECTS: 2,
  PLANS: 3,
  TASKS: 4,
};

export default function CategoriesTable() {
  const { data: allClients } = useGetAllClients();

  const { data: allUsers } = useGetAllUsers(!CheckRole("موظف"));

  const { data: allPlans } = useGetAllPlans();

  const { data: allTasks } = useGetAllTasks();

  const initialState = CheckRole(roles["admin"])
    ? filterKeys["CLIENTS"]
    : CheckRole(roles["manager"])
    ? filterKeys["PLANS"]
    : CheckRole(roles["administrator"])
    ? filterKeys["CLIENTS"]
    : filterKeys["TASKS"];

  const [filterKey, setFilterKey] = useState(initialState);

  // console.log("allClients: ", allClients);
  // console.log("filterKey:",filterKey);
  // console.log("filterKey:",filterKey === filterKeys["PLANS"]);

  const transformRequests = (data, status) => {
    const filteredData = data?.filter((project) => project.status === status);
    return filteredData?.length ? filteredData : data;
  };

  return (
    <DashboardCategoriesTableContext.Provider
      value={{ filterKey, setFilterKey }}
    >
      <div
        className={`${style.chooseDeprtmentContainer} text-mainText  chooseDeprtment`}
      >
        {!CheckRole(roles["user"]) &&
        !CheckRole(roles["administrator"]) &&
        !CheckRole(roles["auditor"]) ? (
          <CategoryFilterMenu />
        ) : null}
        <div className="mt-3 !h-[243px] overflow-auto scrollbar-none">
          {filterKey === filterKeys["CLIENTS"] ? (
            <ClientsTable allClients={transformRequests(allClients, "0")} />
          ) : filterKey === filterKeys["USERS"] ? (
            <UsersTable allUsers={allUsers} />
          ) : filterKey === filterKeys["PROJECTS"] ? (
            <ProjectsTable allProjects={transformRequests(allClients, "1")} />
          ) : filterKey === filterKeys["PLANS"] ? (
            <PlansTable allProjects={allPlans?.plans} />
          ) : filterKey === filterKeys["TASKS"] ? (
            <TasksTable allProjects={allTasks?.tasks} />
          ) : null}
        </div>
      </div>
    </DashboardCategoriesTableContext.Provider>
  );
}

const useGetAllClients = () => {
  return useQuery("clients", () => {
    return myAxiosJson("request").then((data) => data.data.request);
  });
};

const useGetAllUsers = (isValid) => {
  return useQuery("users", () => {
    if (isValid) {
      return myAxiosJson("user")
        .then((data) => data.data.allUsers)
        .then((data) => data.filter((_, index) => index < 10));
    }
  });
};

const useGetAllProjects = () => {
  return useQuery("projects", () => {
    return myAxiosJson("projects")
      .then((data) => data.data.projects)
      .then((data) => data.filter((_, index) => index < 10));
  });
};
