import { useContext, useEffect, useState } from "react";
import SystemControler from "../../../Components/System/SystemControler/SystemControler";
import { Outlet, useLocation } from "react-router-dom";
import AddNewCitizenServices from "../../../Components/AddNewBtn/Settings/AddNewCitizenServices";
import { joiResolver } from "@hookform/resolvers/joi";
import AddUpdateReciption from "../../../Components/System/Settings/Reception/AddUpdateReception";
import SettingContext from "../../../Context/SettingContext";
import AddNewAccounating from "../../../Components/AddNewBtn/Settings/AddNewAccounating";
import AddModal from "./AddModal";
import { useAddCategory } from "../../../hooks/fetchers/Categories";
import SuccessfullModal from "../../../Components/Modals/SuccessfullModal";
import { useAddService } from "../../../hooks/fetchers/Services";
import useSuccessfullModal from "../../../Components/Modals/hooks/useSuccessfullModal";
import BackButton from "../../../Components/BackButton";
import LoadingPage from "../../../Components/feedback/LoadingPage";
import { IoIosArrowDown } from "react-icons/io";
import ReceptionDropdown from "./ReseptionDropdown";
import OrdersCategoryDropdown from "./OrdersCategoryDropdown";
import { categorySchema } from "../../../utiltis/Schemas/Categories/schema";
import { serviceSchema } from "../../../utiltis/Schemas/Services/schema";
import { displayErrorMessage } from "../../../helper/utils";

const Settings = () => {
  const { ReciptionType, orderType } = useContext(SettingContext);
  const {
    handleShowSuccess,
    handleShowError,
    handleCloseSuccess,
    handleCloseError,
    showSuccess,
    showError,
  } = useSuccessfullModal();

  const [errorModal, setErrorModal] = useState(false);

  // ***********************************

  const {
    mutate: mutateCategory,
    isLoading: isMutateCategoryLoading,
    isError: isMutateCategoryError,
    error: mutateCategoryError,
  } = useAddCategory(handleShowSuccess, handleShowError);
  const { mutate: mutateService, isLoading: isMutateServiceLoading } =
    useAddService(handleShowSuccess, handleShowError);

  // ***********************************
  const [show, setShow] = useState(false);

  // useEffect(() => {
  //   setErrorModal(isMutateCategoryError);
  //   // console.log(error?.message);
  // }, [isMutateCategoryError]);
  let { pathname } = useLocation();
  let pagePath = pathname.split("/System/Settings/")[1];
  const handleOpen = () => setShow(true);
  const handleClose = () => {
    setShow(false);
    // handleCloseDelete();
    // console.log("Delete");
  };
  const [open, setOpen] = useState(false);

  return (
    <>
      {isMutateServiceLoading ? <LoadingPage /> : null}
      <SystemControler
        child={
          <div className="flex items-center gap-2 relative">
            <BackButton />
            <button
              onClick={handleOpen}
              className="add-user-button customButton"
            >
              إضافة جديدة
            </button>
            {pagePath === "Reception" && (
              <button
                className="px-8 py-2 rounded-xl bg-mainNavy text-mainText flex gap-2 items-center justify-between xl:hidden"
                onClick={() => setOpen(!open)}
              >
                الاستقبال
                <IoIosArrowDown className="text-mainText text-xl" />
              </button>
            )}
            {(pagePath === "Orders/categories" ||
              pagePath === "Orders/services") && (
              <button
                className="px-8 py-2 rounded-xl bg-mainNavy text-mainText flex gap-2 items-center justify-between xl:hidden"
                onClick={() => setOpen(!open)}
              >
                الطلبات
                <IoIosArrowDown className="text-mainText text-xl" />
              </button>
            )}
            {pagePath === "Reception" && open && (
              <ReceptionDropdown setOpen={setOpen} />
            )}
            {(pagePath === "Orders/categories" ||
              pagePath === "Orders/services") &&
              open && (
                <OrdersCategoryDropdown setOpen={setOpen} pagePath={pagePath} />
              )}
          </div>
        }
      />

      {pagePath === "Reception" ? (
        <AddUpdateReciption
          id={null}
          handleCloseModal={handleClose}
          ReciptionType={ReciptionType}
          show={show}
        />
      ) : null}

      {pagePath === "Orders/categories" && (
        <AddModal
          title={"اضافة جديدة"}
          show={show}
          handleClose={handleClose}
          name={"name"}
          isLoading={isMutateCategoryLoading}
          handleSave={(data) => {
            mutateCategory(data);
            handleClose();
          }}
          formOptions={{
            resolver: joiResolver(categorySchema("ar")),
          }}
        />
      )}
      {pagePath === "Orders/services" && (
        <AddModal
          title={"اضافة جديدة"}
          show={show}
          handleClose={handleClose}
          name={"name"}
          isLoading={isMutateServiceLoading}
          handleSave={(data) => {
            mutateService(data);
            handleClose();
          }}
          formOptions={{
            resolver: joiResolver(serviceSchema("ar")),
          }}
        />
      )}
      {orderType === 1 || orderType === 2 ? (
        <>
          <SuccessfullModal
            show={showSuccess}
            message={"تمت الاضافة بنجاح"}
            handleClose={handleCloseSuccess}
          />
          <SuccessfullModal
            status="error"
            show={showError}
            message={() => displayErrorMessage(mutateCategoryError)}
            handleClose={handleCloseError}
          />
        </>
      ) : null}
      {pagePath === "CitizenServices/vacations" && (
        <AddNewCitizenServices
          handleClose={handleClose}
          title={"اضافة نوع اجازة جديد"}
          show={show}
          type={"vacations"}
        />
      )}
      {pagePath === "CitizenServices/services" && (
        <AddNewCitizenServices
          handleClose={handleClose}
          title={"اضافة خدمة جديدة"}
          show={show}
          type={"services"}
          // onSave={()=>{
          //   console.log("services: ",services);
          // }}
        />
      )}

      {/* *********************************** */}
      {pagePath === "Accounating" ? (
        <AddNewAccounating
          handleClose={handleClose}
          title={"اضافة بند جديد"}
          show={show}
        />
      ) : null}
      <Outlet />
    </>
  );
};

export default Settings;
