import { Link, useRouteError } from "react-router-dom";
import Image from "../../Components/Image";
const Error = () => {
  const error = useRouteError();
  console.log(error);
  if (error?.status == 404) {
    return (
      <ErrorWrapper>
        {/* <img src={img} alt='not found'></img> */}
        <h1 className="text-red-500 text-9xl">404</h1>
        <p className="text-[#EFAA20] text-2xl">الصفحة غير موجودة</p>

      </ErrorWrapper>
    );
  }
  return (
    <ErrorWrapper>
      <h1 className="text-red-500 md:text-4xl lg:text-5xl">حدث خطأ ما</h1>
    </ErrorWrapper>
  );
};

const ErrorWrapper = ({ children }) => {
  return (
    <div className="bg-white border h-screen flex flex-col justify-center items-center gap-3">
      <div className="">
      <Image
          src="/systemLogin.gif"
          className={"w-[320px]  object-contain"}
          alt={" BSA gif"}
        />
      </div>
      {children}
      <div className="bg-[#EFAA20] font-semibold text-[#151521] py-1 px-3 rounded">
      <Link to="/System/index">الرئيسية</Link>
      </div>
    </div>
  );
};

export default Error;
