import { sidebarClasses } from "react-pro-sidebar";

export const rootStyles = {
    [`.${sidebarClasses.container}`]: {
      scrollbarWidth: "none",
      flex: "1 1",
      display: "flex",
      flexDirection: "column",
      overflowX: "visible",
      overflowY: "visible",
      borderRadius: "19px",
    },
    color: "#FFF",
    backgroundColor: "var(--main-item-color)",
    height: "100% !important",
    border: "2px solid #EFAA20 !important",
    borderRadius: "19px",
    // overflow: "hidden",
  }
export const menuRootStyles = {
    [`ul`]: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      gap: "10px",
    },
  }