import React, { useContext, useState } from "react";
import { FaCheck } from "react-icons/fa6";
import Section from "../../../../Components/System/ShowRequest/components/Section";
import DescGroup from "../../../../Components/System/ShowRequest/components/DescGroup";
import { FormModal } from "../../PlanModel/components/FormModal";
import AssignToCard from "../../Tasks/components/AssignToCard";
import ShowFiles from "../../../../Components/ShowFiles";
import { TechnicalSupportContext } from "./TechnicalSupportProvider";
import {
  useAcceptItTask,
  useGetAllTasksById,
  useTaskComplete,
  useTaskFixed,
} from "../../../../hooks/fetchers/TechnicalSupportPurcheseOrder";
import moment from "moment";
import LoadingPage from "../../../../Components/feedback/LoadingPage";
import CustomModal from "../../../../Components/Modals/CustomModal";
import SuccessfullModal from "../../../../Components/Modals/SuccessfullModal";
import useSuccessfullModal from "../../../../Components/Modals/hooks/useSuccessfullModal";
import AcceptIngoingForm from "./AcceptIngoingForm";
import { CheckRole } from "../../../../helper/utils";
import Cookies from "js-cookie";
const TechnicalSupportShow = () => {
  const {
    showSuccess,
    showError,
    handleShowSuccess,
    handleShowError,
    handleCloseSuccess,
    handleCloseError,
  } = useSuccessfullModal();

  const { task } = useContext(TechnicalSupportContext);
  const { data, isLoading } = useGetAllTasksById(task?._id);
  const [showAccept, setShowAccept] = useState(false);
  const [err, setError] = useState("");

  const [showCompletePop, setCompletePop] = useState(false);
  const [isCompletePop, setIsCompletePop] = useState(0);
  const { mutate: acceptMutate, isLoading: acceptLoading } = useAcceptItTask(
    task?._id,
    handleShowSuccess,
    (error) => {
      handleShowError();
      setError(
        error?.response?.data?.validationErrors ||
          error?.response?.data?.message
      );
    }
  );
  const [fixedSuccess, setFixedSuccess] = useState(false);

  const { mutate: fixedMutate, isLoading: fixedLoading } = useTaskFixed(
    task?._id,
    () => setFixedSuccess(true),
    (error) => {
      handleShowError();
      setError(
        error?.response?.data?.validationErrors ||
          error?.response?.data?.message
      );
    }
  );

  const [completeSuccess, setCompleteSuccess] = useState(false);

  const { mutate: completeMutate, isLoading: completeLoading } =
    useTaskComplete(
      task?._id,
      () => setCompleteSuccess(true),
      (error) => {
        handleShowError();
        setError(
          error?.response?.data?.validationErrors ||
            error?.response?.data?.message
        );
      }
    );

  return isLoading || acceptLoading || fixedLoading || completeLoading ? (
    <LoadingPage />
  ) : (
    <div className="bg-mainItems h-[112vh] overflow-auto py-4">
      {/* Header */}
      <div className="flex justify-between p-4 border rounded-[19px] !border-[#EFAA20]">
        <div className="flex flex-col justify-center gap-4 w-full">
          <TitleText
            title={"نوع المهمه"}
            value={data?.dataType?.name || "لا توجد بيانات"}
          />
          <div className="flex items-center justify-between w-full">
            <TitleText
              title={"اسم الموظف"}
              value={
                (data?.employee
                  ? `${data?.employee?.firstName} ${data?.employee?.lastName}`
                  : `${data?.createdBy?.firstName} ${data?.createdBy?.lastName}`) ||
                "لا توجد بيانات"
              }
            />
            <TitleText
              title={"الحاله"}
              value={
                data.status === "0"
                  ? "في انتظار الموافقه"
                  : data.status === "2"
                  ? "مكتمله"
                  : data.status === "1"
                  ? "قيد التنفيذ"
                  : data.status === "70"
                  ? "تم الفحص"
                  : "لا توجد بيانات"
              }
            />
          </div>
          <div className="flex items-center justify-between w-full ">
            <TitleText
              title={"قسم"}
              value={
                (data?.employee
                  ? data?.employee?.role
                  : data?.createdBy?.role) || "لا توجد بيانات"
              }
            />
            {data?.status === "0" && Cookies.get("department") === "IT" && (
              <button
                className="bg-[#19B159] w-[70px] h-[25px] rounded flex items-center justify-center gap-2 text-white"
                onClick={() => setShowAccept(true)}
              >
                قبول
                <FaCheck className="text-white" />
              </button>
            )}
            {(data?.status === "1" || data?.status === "70") &&
              Cookies.get("department") === "IT" && (
                <div className="flex gap-4 ">
                  {data?.status !== "70" && (
                    <button
                      className="bg-[#19B159] w-[100px] h-[25px] rounded flex items-center justify-center gap-2 text-white"
                      onClick={() => {
                        setCompletePop(true);
                        setIsCompletePop(0);
                      }}
                    >
                      تم الفحص
                      <FaCheck className="text-white" />
                    </button>
                  )}
                  <button
                    className="bg-[#E1A945] w-[80px] h-[25px] rounded flex items-center justify-center gap-2 text-white"
                    onClick={() => {
                      setCompletePop(true);
                      setIsCompletePop(1);
                    }}
                  >
                    مكتمله
                    <FaCheck className="text-white" />
                  </button>
                </div>
              )}
          </div>
        </div>
      </div>
      {/* Details */}
      {!CheckRole("مدير") && data?.timeTo && (
        <Section title="تفاصيل المهمه">
          <div className="row px-2">
            <div className="col-md-6 mb-3">
              <DescGroup
                label="نوع المهمه"
                desc={data?.dataType || "لا توجد بيانات"}
              />
            </div>
            <div className="col-md-6 mb-3">
              <DescGroup
                label="رقم المهمه"
                desc={data?.number || "لا توجد بيانات"}
              />
            </div>

            <div className="col-md-6 mb-3">
              <DescGroup
                label="من تاريخ "
                desc={
                  moment(data?.dateFrom).format("YYYY-MM-DD") ||
                  "لا توجد بيانات"
                }
              />
            </div>
            <div className="col-md-6 mb-3">
              <DescGroup
                label="من وقت"
                desc={data?.timeFrom || "لا توجد بيانات"}
              />
            </div>
            <div className="col-md-6 mb-3">
              <DescGroup
                label="الي تاريخ"
                desc={
                  moment(data?.dateTo).format("YYYY-MM-DD") || "لا توجد بيانات"
                }
              />
            </div>
            <div className="col-md-6 mb-3">
              <DescGroup
                label="الي وقت"
                d
                desc={data?.timeTo || "لا توجد بيانات"}
              />
            </div>
          </div>
        </Section>
      )}
      <fieldset className="showProjectBorder p-2 ">
        <legend className="text-center text-mainText"> الوصف</legend>

        <p className=" text-mainText">الوصف :</p>

        <textarea
          placeholder="اكتب ملاحظات"
          className="form-control my-3 w-100 m-auto"
          cols={30}
          rows={5}
          disabled
          value={data?.description}
        />
      </fieldset>
      {data?.knwoingly && (
        <FormModal title={"بمعرفة"}>
          <div className="grid grid-cols-2 gap-5">
            <AssignToCard
              title={`${data?.knwoingly?.firstName} ${data?.knwoingly?.lastName}`}
              desc={`${data?.knwoingly?.role}`}
              image={data?.knwoingly?.image}
              userId={data?.knwoingly?._id}
            />
          </div>
        </FormModal>
      )}
      <FormModal title={"المرفقات"} className="mt-8">
        {data?.attachments?.length > 0 ? (
          <>
            <div className="relative w-full flex gap-6 overflow-y-hidden overflow-x-auto scrollbar-thin scrollbar-track-transparent scrollbar-thumb-[#EFAA20] pb-14">
              <ShowFiles files={data?.attachments} />
            </div>
          </>
        ) : (
          <div className="flex justify-center gap-3">
            <p className="text-2xl text-[#D59921]"> لا يوجد مرفقات</p>
          </div>
        )}
      </FormModal>
      <CustomModal
        title={"التأكيد"}
        message={"هل انت متأكد من تاكيد هذا الطلب"}
        show={showAccept}
        handleClose={() => setShowAccept(false)}
        handleSave={() => {
          acceptMutate();
        }}
      />
      <AcceptIngoingForm
        show={showCompletePop}
        handleClose={() => setCompletePop(false)}
        isTask={true}
        type={isCompletePop}
        handleSave={(newData) => {
          isCompletePop === 0 ? fixedMutate(newData) : completeMutate(newData);
        }}
      />

      <SuccessfullModal
        handleClose={() => {
          setShowAccept(false);
          handleCloseSuccess();
          setShowAccept(false);
          setCompletePop(false);
        }}
        message={"تم القبول بنجاح"}
        show={showSuccess}
      />
      <SuccessfullModal
        handleClose={() => {
          setShowAccept(false);
          setFixedSuccess(false);
          handleCloseSuccess();
          setShowAccept(false);
          setCompletePop(false);
        }}
        message={"تم الفحص بنجاح"}
        show={fixedSuccess}
      />
      <SuccessfullModal
        handleClose={() => {
          setCompleteSuccess(false);
          handleCloseSuccess();
          setShowAccept(false);
          setCompletePop(false);
        }}
        message={"تمت الاكتمال  بنجاح"}
        show={completeSuccess}
      />
      <SuccessfullModal
        handleClose={handleCloseError}
        message={err}
        show={showError}
        status="error"
      />
    </div>
  );
};

export default TechnicalSupportShow;
const TitleText = ({ title, value, isStatus }) => {
  return (
    <p className="font-normal  text-mainText text-lg">
      {title} :
      <span
        className={
          isStatus ? "me-1 text-[#D59921]" : "me-1 text-mainCustomGray"
        }
      >
        {value}
      </span>
    </p>
  );
};
