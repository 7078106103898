import { createContext, Fragment, useContext, useState } from "react";
import { FormModal } from "../../../../../System/PlanModel/components/FormModal";
import {
  useGetAllUserPDFInfoReports,
  useGetAllUsersReports,
  useGetDepartmentGoalsReport,
  useGetDepartmentKPIsReport,
  useGetDepartmentTasksReport,
} from "../../../../../../hooks/fetchers/Users";
import { useParams } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import {
  convertDateFormat,
  GenerateColumnsNames,
  projectTypeEnum,
} from "../../../../../../helper/utils";
import { TableCell } from "../../../../../../Components/Table/TableCell";
import { TableRow } from "../../../../../../Components/Table/TableRow";
import CustomTable from "../../../../../../Components/Table";
import CustomTableMenu from "../../../../../../Components/Table/CustomTableMenu";
import DownloadButton from "../../../../../../Components/Buttons/DownloadButton";
import ApexColumn from "../components/ApexColumn";
import TaskCard from "../../../components/TaskCard";
import ProgressBar from "../../../components/ProgressBar";
import ExcellModall from "../../../../../../Components/Modals/ExcellModall";
import useExportExcel from "../../../../../../hooks/useExportExcel";
import useModal from "../../../../../../hooks/useModal";
import { TiExport } from "react-icons/ti";
import { ImPrinter } from "react-icons/im";
import PrintDepartmentDetailsFile from "../../../components/PrintDepartmentDetailsFile";
const UserReportsDepartment = () => {
  const [isLate,setIsLate] = useState(null)
  const [taskStatus,setTaskStatus] = useState(null)
  const { department } = useParams();
  const { data, isLoading, isError } = useGetDepartmentGoalsReport(department);

  const {
    data: dataTasks,
    isLoading: isLoadingTasks,
    isError: isErrorTasks,
  } = useGetDepartmentTasksReport(department,{isLate: isLate, status: taskStatus});


  const { data: users } = useGetAllUserPDFInfoReports(department);







  const {
    data: dataKPIs,
    isLoading: isLoadingKPIs,
    isError: isErrorKPIs,
  } = useGetDepartmentKPIsReport(department);
  const { loading, allKeys, excelData, fileUrl, handleExport, resetExcelData } =
    useExportExcel();
  const {
    open: openPdf,
    handleOpenModal: handleOpenPdfModal,
    handleCloseModal: handleClosePdfModal,
  } = useModal();
  return (
    <div>
      {!loading && excelData && (
        <ExcellModall
          onHide={resetExcelData}
          fileUrl={fileUrl}
          keys={allKeys}
          data={excelData}
        />
      )}



<PrintDepartmentDetailsFile
        show={openPdf}
        onHide={handleClosePdfModal}
        data={{
          ...users,
          header:{
            titleEn: "Department Details",
            titleAr: department,
          }
        }}
      />






      <div className="flex justify-between items-center p-3">
        <div className="">
          <p className="text-[#EFAA20] text-base font-medium">{`${"قسم"} ${department}`}</p>
        </div>
        <div className="flex items-center gap-2">
          <DownloadButton
            onClick={() =>
              handleExport(
                `system-reports/users/excel?department=${department}`
              )
            }
          >
            <div className="flex items-center gap-1">
              <span>تصدير Excel</span>
              <TiExport />
            </div>
          </DownloadButton>
          <DownloadButton onClick={() => {
                setTaskStatus(null)
                setIsLate(null)
                handleOpenPdfModal()}}>
            <div className="flex items-center gap-1">
              <span>طباعة</span>
              <ImPrinter />
            </div>
          </DownloadButton>
        </div>
      </div>

      <TasksProvider>
        <div className="space-y-4 max-h-[750px] overflow-y-auto overflow-x-hidden scrollbar-thin scrollbar-track-transparent scrollbar-thumb-[#EFAA20]">
          <div className="w-full flex justify-center items-center">
            {isLoadingKPIs ? <Spinner /> : null}
            {isErrorKPIs ? (
              <p className="text-red-400 text-lg">يوجد خطا ما</p>
            ) : null}
          </div>

          {!isLoadingKPIs && !isErrorKPIs ? (
            <>
              {/* <AllUsersColumnChart data={dataKPIs} /> */}
              <ApexColumn data={dataKPIs} />
            </>
          ) : null}

          <FormModal>
            <p className="text-sm font-medium">{"الشهر الحالى "}</p>
            <div className="mt-2">
              <div className="w-full flex justify-center items-center">
                {isLoading ? <Spinner /> : null}
                {isError ? (
                  <p className="text-red-400 text-lg">يوجد خطا ما</p>
                ) : null}
              </div>
              <p className="text-sm font-medium mb-2">{"الاهداف"}</p>
              {!isLoading && !isError ? (
                <Fragment>
                  <ProgressBar progress={data?.goals} />
                  <p className="text-xs font-normal text-mainCustomGray">{`حقق ${data?.goals}% من الهدف`}</p>
                </Fragment>
              ) : null}
            </div>
          </FormModal>

          <div className="space-y-4">
            <p className="text-[#EFAA20] text-xl font-medium">{"مهام"}</p>
            <div className="flex justify-center items-center gap-4">
              <TaskCard
                title={"مهام مكتملة"}
                value={dataTasks?.tasksStats?.completedTasks}
                onClick={()=>{setTaskStatus(2)}}
              />
              <TaskCard
                title={"مهام قيد التنفيذ"}
                value={dataTasks?.tasksStats?.inprogressTasks}
                onClick={()=>{setTaskStatus(1)}}
              />
              <TaskCard
                title={"مهام متأخرة"}
                value={dataTasks?.tasksStats?.latedTasks}
                onClick={()=>{setIsLate(true);setTaskStatus(null)}}
              />
            </div>
            <div className="">
              <TasksTable tasks={dataTasks} />
            </div>
          </div>
        </div>
      </TasksProvider>
    </div>
  );
};

const TasksContext = createContext(null);
const TasksProvider = ({ children }) => {
  const [taskStatus, setTaskStatus] = useState("");
  console.log("taskStatus", taskStatus);
  return (
    <TasksContext.Provider value={{ taskStatus, setTaskStatus }}>
      {children}
    </TasksContext.Provider>
  );
};
const useTasksContext = () => {
  const ctx = useContext(TasksContext);
  if (!ctx) {
    throw Error("use inside provider");
  }
  return ctx;
};

const columns2 = GenerateColumnsNames(
  "م",
  "اسم المهمة",
  "نوع المشروع ",
  "تاريخ الاستلام",
  "المسؤل"
);
const TasksTable = ({ tasks }) => {
  const [page, setPage] = useState(1);
  const handleChange = (event, value) => {
    setPage(value);
    // setFilterTasksParams({ page: value });
  };
  return (
    <CustomTable
      columns={columns2}
      data={tasks?.tasks}
      paginationProps={{
        count: tasks?.pages,
        page: page,
        onChange: handleChange,
      }}
      responsive={false}
    >
      {tasks?.tasks && tasks?.tasks?.length > 0
        ? tasks?.tasks?.map(
            (
              {
                _id,
                projectName,
                projectType,
                startDate,
                assignTo,

                status,
              },
              index
            ) => {
              return (
                <TableRow
                  className={`my-2 border !border-[#efaa207f] ${
                    index % 2 === 0 ? "bg-mainNavy" : ""
                  }`}
                  key={_id}
                >
                  <TableCell>{(page - 1) * 10 + (index + 1)}</TableCell>
                  <TableCell>{projectName || "لا يوجد مخطط"}</TableCell>
                  <TableCell>{projectTypeEnum[+projectType]}</TableCell>
                  <TableCell>
                    {convertDateFormat(startDate) || "--------"}
                  </TableCell>
                  <TableCell>
                    <CustomTableMenu
                      items={assignTo?.map((employee, index) => ({
                        value: `${employee?.firstName} ${employee?.lastName}`,
                      }))}
                      // items={[
                      //   {title:"تاريخ:" ,value: moment(confirmedAt).format("YYYY-MM-DD")},
                      //   {title:"وقت:" ,value: moment(confirmedAt).format("h:m a")}
                      // ]}
                      maxItems={1}
                    />
                    {/* {assignTo
                        ? assignTo?.map((employee, index) => (
                            <p className="" key={index}>
                              {employee?.firstName}
                            </p>
                          ))
                        : "--------"} */}
                  </TableCell>
                </TableRow>
              );
            }
          )
        : null}
    </CustomTable>
  );
};

export default UserReportsDepartment;
