import React from "react";

const TasksIcon = () => {
  return (
    <svg
      width="25"
      height="27"
      viewBox="0 0 25 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.5"
        y="1.52539"
        width="20.8734"
        height="24.9747"
        rx="2.5"
        stroke="var(--main-text)"
      />
      <rect
        x="4.10156"
        y="14.3545"
        width="14.0127"
        height="1.02532"
        rx="0.512658"
        fill="var(--main-text)"
      />
      <rect
        x="4.10156"
        y="8.88574"
        width="9.22785"
        height="1.02532"
        rx="0.512658"
        fill="var(--main-text)"
      />
      <rect
        x="4.10156"
        y="19.8232"
        width="14.0127"
        height="1.02532"
        rx="0.512658"
        fill="var(--main-text)"
      />
      <circle cx="19.9929" cy="4.27215" r="4.27215" fill="#EFAA20" />
      <path
        d="M19.664 5.81033C19.5815 5.81033 19.499 5.77688 19.4357 5.70913L18.2076 4.39445C18.0818 4.25981 18.0818 4.04112 18.2076 3.90648C18.3334 3.77184 18.5377 3.77184 18.6634 3.90648L19.664 4.97761L21.6644 2.83536C21.7902 2.70071 21.9944 2.70071 22.1202 2.83536C22.246 2.97 22.246 3.18868 22.1202 3.32332L19.8915 5.70913C19.8291 5.77602 19.7465 5.81033 19.664 5.81033Z"
        fill="var(--main-text)"
      />
    </svg>
  );
};

export default TasksIcon;
