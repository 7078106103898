import { useMutation, useQueryClient } from "react-query";
import { myAxiosJson } from "../../helper/https";

export const resetPassword = (data) =>
  myAxiosJson.post("user/forgetpassword", data);

export const verifyOtp = (data) => myAxiosJson.post("user/verification", data);

const defaultOnSuccess = () => {};

const defaultOnError = (error) => {
  console.log(error);
  return error;
};
export const useResetPassword = (
  onSuccess = defaultOnSuccess,
  onError = defaultOnError
) => {
  return useMutation((data) => resetPassword(data), {
    onSuccess: () => {
      onSuccess();
    },
    onError,
  });
};
export const useVerifyOTP = (
  onSuccess = defaultOnSuccess,
  onError = defaultOnError
) => {
  return useMutation((data) => verifyOtp(data), {
    onSuccess: () => {
      onSuccess();
    },
    onError,
  });
};
