import React from 'react'
import Message from './Message';
import AllChats from '../index/AllChats';
import moment from 'moment';
import Progress from '../../../../Components/Progress';

const MessageList = ({ isLoadingMessage, allMessages, myId }) => {
    return (
      <>
        {isLoadingMessage ? <Progress /> : null}
        {allMessages?.length ? (
          allMessages?.map(({ from, to, message, createdAt }, index) => (
            <Message
              time={moment(createdAt).format("hh:mm a")}
              user={from}
              isSender={from._id === myId}
              message={message}
              key={index}
            />
          ))
        ) : (
          <AllChats />
        )}
      </>
    );
  };

export default MessageList