import { Fragment, useEffect, useRef, useState } from "react";

import { toBlob } from "html-to-image";
import { Document, Page, Image, StyleSheet } from "@react-pdf/renderer";
import moment from "moment";
import SectionElement from "../../../../Components/Preview/components/SectionElement";
import PdfPreview from "../../../../Components/Preview/CustomPdfPreview";
import TableElement from "../../../../Components/Preview/components/Table";
import { projectTypeEnum, statusEnum } from "../../../../helper/utils";
import PdfDocument from "./PdfDocument";

const columnsUserInfor = [
  {
    name: " م",
  },
  {
    name: "اسم المستخدم",
  },
  {
    name: "الكود الوظيفي",
  },
  {
    name: "البلد",
  }
];

const PrintUsersDetailsFile = ({ show, onHide, data }) => {
  const refs = useRef([]);
  const [imageUrls, setImageUrls] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (show) {
      const captureImages = async () => {
        setLoading(true);
        const blobs = await Promise.all(
          refs.current.map((ref) => toBlob(ref, { pixelRatio: 2 }))
        );
        const urls = blobs.map((blob) => URL.createObjectURL(blob));
        setImageUrls(urls);
        setLoading(false);
      };

      captureImages();
    }
  }, [show]);
  // console.log("PrintUsersDetailsFile", data);
  return (
    <>
      <PdfPreview show={show} onHide={onHide}>
        {imageUrls.length > 0 && (
          <PdfPreview.DownloadButton
            document={<PdfDocument imageUrls={imageUrls} />}
            loading={loading}
          />
        )}
        
          <Fragment>
            <div
              // ref={(el) => (refs.current[index] = el)}
              ref={(el) => (refs.current[0] = el)}
              className="rounded-t w-full"
            >
              <PdfPreview.Header
                // createdAt={`تاريخ الانشاء : ${moment(item?.createdAt).format("YYYY-MM-DD")}`}
                titleAr={"المستخدمين"}
                titleEn={"Users"}
              />

              <PdfPreview.Body>
                <div className="flex justify-between items-center">
                  <PdfPreview.SectionTitle title={"بيانات المستخدمين"} />
                  <SectionElement
                      title="عدد المستخدمين"
                      disc={data?.total}
                      className="w-1/2 gap-0 px-2"
                    />
                </div>
                <PdfPreview.SectionWrapper>
                  <SectionElement title="عدد مستخدمين السعوديه" disc={data?.ksa?.total} />
                  <SectionElement title="عدد مستخدمين مصر" disc={data?.egy?.total} />
        <div className="col-span-2">

                  <TableElement
                    columns={columnsUserInfor}
                    data={data?.users}
                  >
                    {data?.users?.map(
                      (
                        { firstName,lastName, employeCode, country },
                        index
                      ) => (
                        <TableElement.TableRow className={`my-2`} key={index}>
                          <TableElement.TableCell>
                            {index + 1}
                          </TableElement.TableCell>
                          <TableElement.TableCell>
                            {`${firstName} ${lastName}`}
                          </TableElement.TableCell>
                          <TableElement.TableCell>
                            {employeCode}
                          </TableElement.TableCell>
                          <TableElement.TableCell>
                            {country}
                          </TableElement.TableCell>
                        </TableElement.TableRow>
                      )
                    )}
                  </TableElement>
        </div>
                </PdfPreview.SectionWrapper>

              </PdfPreview.Body>
            </div>
          </Fragment>
        
      </PdfPreview>
    </>
  );
};

// const Item = ({ title = " ", disc = "", className = "" }) => {
//   return (
//     <>
//       <div className={twMerge("flex items-center gap-3", className)}>
//         <p className="w-20">{title}</p>
//         <div className="bg-[#F9EBD5] py-2 px-4 w-full min-h-[2rem]">
//           <p>{disc}</p>
//         </div>
//       </div>
//     </>
//   );
// };



export default PrintUsersDetailsFile;
