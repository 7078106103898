import { FaExclamation } from "react-icons/fa6";

export const ListErrorComponent = ({ messages }) => {
    return (
      <ul className="w-full grid lg:grid-cols-2 gap-2px-4">
        {messages?.map((message, index) => (
          <li
            key={index}
            className="flex items-center gap-2 text-base font-medium text-red-500 text-end"
          >
            <FaExclamation className="border !border-yellow-400 text-lg text-yellow-400 rounded-full p-px" />
            {message}
          </li>
        ))}
      </ul>
    );
  };