import { useState } from "react";

function useOpenNotifications() {
  const [open, setOpen] = useState(true);

  const handleClose = (event) => {
    setOpen(false);
  };
  return {
    open,
    handleClose,
  };
}

export default useOpenNotifications;
