import React, { useContext, useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import Image from "../../../../Components/Image";
import LoadingPage from "../../../../Components/feedback/LoadingPage";
import { TechnicalSupportContext } from "./TechnicalSupportProvider";
import { CustomInput } from "../../../../Components/FormUi";
import { useForm } from "react-hook-form";
import SuccessfullModal from "../../../../Components/Modals/SuccessfullModal";
import { InputLabel } from "../../PlanModel/components/InputLabel";
import { FormControl } from "@mui/material";
import {
  useAddTechnicalSupportTermsOrder,
  useUpdateTechnicalSupportTermsOrder,
} from "../../../../hooks/fetchers/TechnicalSupportPurcheseOrder";
import useSuccessfullModal from "../../../../Components/Modals/hooks/useSuccessfullModal";

const TechnicalSupportAddTerms = () => {
  const { addTermsOpen, setAddTermsOpen, updatedItem, setUpdatedItem } =
    useContext(TechnicalSupportContext);
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: updatedItem?.name || "",
    },
  });

  useEffect(() => {
    if (updatedItem) {
      reset({ name: updatedItem.name });
    }
  }, [updatedItem, reset]);

  const {
    showSuccess,
    showError,
    handleShowSuccess,
    handleShowError,
    handleCloseSuccess,
    handleCloseError,
  } = useSuccessfullModal();
  const [err, setError] = useState("");

  const [updateSuccess, setUpdateSuccess] = useState(false);

  const { mutate, isLoading } = useAddTechnicalSupportTermsOrder(
    handleShowSuccess,
    handleShowError
  );
  const { mutate: updateMutate, isLoading: isUpdateLoading } =
    useUpdateTechnicalSupportTermsOrder(
      updatedItem?._id,
      handleShowSuccess,
      handleShowError
    );

  const onSubmit = (data) => {
    const payload = {
      name: data.name,
    };

    updatedItem
      ? updateMutate(payload, {
          onSuccess: () => {
            reset();
            setUpdatedItem(null);
            setUpdateSuccess(true);
          },
          onError: (err) => {
            setError(
              err?.response?.data?.validationErrors ||
                err?.response?.data?.message
            );
          },
        })
      : mutate(payload, {
          onSuccess: () => {
            reset();
          },
          onError: (err) => {
            setError(
              err?.response?.data?.validationErrors ||
                err?.response?.data?.message
            );
          },
        });
  };

  return isLoading || isUpdateLoading ? (
    <LoadingPage />
  ) : (
    <>
      <AddTechnicalSupportWrapper
        show={addTermsOpen}
        onHide={() => {
          setUpdatedItem(null);
          setAddTermsOpen(false);
        }}
      >
        <Form
          onSubmit={handleSubmit(onSubmit)}
          encType="multipart/form-data"
          className="row my-4 date-input w-100 mx-auto p-3"
        >
          <FormControl fullWidth className="mb-3">
            <div className="w-full flex items-center justify-between pb-4">
              <InputLabel size={18} label={"نوع الطلب"} className={"mb-3"} />
              <Image
                src="/Rejected.svg"
                alt="close modal button"
                className="pointer"
                onClick={() => {
                  setUpdatedItem(null);
                  setAddTermsOpen(false);
                }}
              />
            </div>
            <CustomInput
              control={control}
              name="name"
              placeholder="ادخل الاسم"
              rules={{
                required: "يجب كتابة ادخل الاسم",
                minLength: {
                  value: 3,
                  message: "لا يقل عن 3 حروف",
                },
                maxLength: {
                  value: 100,
                  message: "لا يقل عن 100 حروف",
                },
                pattern: {
                  value: /^[A-Za-z\u0600-\u06FF ]{3,}$/i,
                  message: "يجب ان تتكون من حروف عربى فقط او انجليزية فقط رقم",
                },
              }}
              id="new-project"
            />
          </FormControl>
          <div className="d-flex justify-content-center">
            <button
              type="submit"
              className="mt-4 sumbmitAddUpdateUser border-0"
            >
              حفظ
            </button>
          </div>
        </Form>
      </AddTechnicalSupportWrapper>
      <SuccessfullModal
        handleClose={() => {
          handleCloseSuccess();
          setAddTermsOpen(false);
        }}
        message={updateSuccess ? "تم التعديل بنجاح" : "تمت الاضافة بنجاح"}
        show={showSuccess}
      />
      <SuccessfullModal
        handleClose={handleCloseError}
        message={err}
        show={showError}
        status="error"
      />
    </>
  );
};

export default TechnicalSupportAddTerms;

const AddTechnicalSupportWrapper = ({ children, show, onHide }) => {
  return (
    <Modal
      size="lg"
      show={show}
      onHide={onHide}
      aria-labelledby=" example-modal-sizes-title-lg"
      className="systemModal mettingModal bg-black/50 !flex items-center justify-center"
      contentClassName="scroll p-0 !min-w-[700px]"
    >
      {children}
    </Modal>
  );
};
