import { toast } from "react-toastify";
import myAxiosInstance from "../https";

export const getAllVacationCategories = (params) =>
  myAxiosInstance
    .get("vacationCategory", { params })
    .then((data) => data?.data).catch(error=>{
      toast.error(error?.response?.data?.message)
  });
export const getVacationCategory = (vacationCategoryId) =>
  myAxiosInstance
    .get(`vacationCategory/${vacationCategoryId}`)
    .then((data) => data?.data?.vacationCategory).catch(error=>{
      toast.error(error?.response?.data?.message)
  });
export const getAllVacationCategoriesHoliday = () =>
  myAxiosInstance("vacationCategory").then((data) =>
    data?.data?.vacationCategory?.map(function (vaction) {
      return { value: vaction._id, label: vaction.name };
    })
  ).catch(error=>{
    toast.error(error?.response?.data?.message)
});

export const addVacationCategory = (data) =>
  myAxiosInstance.post("vacationCategory", data);

export const updateVacationCategory = (categoryId, data) => {
  // console.log(`vacationCategory/${categoryId}`, data);
  return myAxiosInstance.patch(`vacationCategory/${categoryId}`, data);
};

export const deleteVacationCategory = (categoryId) =>
  myAxiosInstance.patch(`vacationCategory/delete/${categoryId}`);
