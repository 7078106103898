import React from "react";
import { useEditMeetingContext } from "./EditMeetingContext";
import EditDeleteMeetingWrapper from "./EditDeleteMeetingWrapper";
import { InputLabel } from "../../../../Pages/System/PlanModel/components/InputLabel";
import { Controller } from "react-hook-form";
import Select from "../../../FormHandler/Select";
import { ErrorMessage } from "@hookform/error-message";
import { Button, Form } from "react-bootstrap";
import CustomTextArea from "../../../FormUi/CustomTextArea";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import DeleteEvent from "./DeleteEvent";
import SaveButton from "../../../SaveButton";
import { CheckRole } from "../../../../helper/utils";
import Progress from "../../../Progress";
import {
  CustomDatePicker,
  CustomInput,
  CustomTimePicker,
} from "../../../FormUi";
import EditableInput from "./EditableInput";
import { roles } from "../../../../helper/fetchers/Tasks";
import moment from "moment";
import { FaCheck } from "react-icons/fa6";
import EmployeeCard from "../Card";
const meetingCountries = [
  {
    value: 1, // 1
    label: "السعودية",
  },
  {
    value: 2, // 2
    label: "مصر",
  },
];

const meetingType = [
  {
    value: 1, // 1
    label: "شامل",
  },
  {
    value: 2, // 2
    label: "مع شخص",
  },
];
const EditMeetingForm = ({
  id,
  showEditDeleteModal,
  setShowEditDeleteModal,
}) => {
  const {
    createdByMe,
    handleSubmit,
    onSubmit,
    control,
    errors,
    handleOpenModal,
    meetingPlace,
    watch,
    selectedEvent,
    selectedUsers,
    newUsers,
    setMeetingPlace,
    updateIsLoading,
  } = useEditMeetingContext();
  return (
    <>
      {showEditDeleteModal && id && (
        <EditDeleteMeetingWrapper
          show={showEditDeleteModal}
          onHide={() => setShowEditDeleteModal(false)}
        >
          <Form
            // onSubmit={handleEditDeleteMeeting}
            onSubmit={handleSubmit(onSubmit)}
            className="row my-4 date-input  w-100 mx-auto p-3  "
          >
            <div className="col-md-12 mb-4">
              <div className="row">
                <div className="col-md-6">
                  <CustomFormInput
                    errors={errors}
                    label="بلد الاجتماع"
                    name="country"
                    renderInput={() => (
                      <Controller
                        name="country"
                        control={control}
                        rules={{
                          required: "من فضلك اختر بلد الاجتماع",
                        }}
                        render={({
                          field: { name, onBlur, onChange, value = "" },
                        }) => (
                          <Select
                            name={name}
                            value={value}
                            onChange={onChange}
                            onBlur={onBlur}
                            placeholder="اختر بلد الاجتماع"
                            options={meetingCountries}
                          />
                        )}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6 mb-4">
              <CustomFormInput
                errors={errors}
                label="نوع الاجتماع"
                name="meetingType"
                renderInput={() => (
                  <Controller
                    name="meetingType"
                    control={control}
                    rules={{
                      required: "من فضلك اختر نوع الاجتماع",
                    }}
                    render={({
                      field: { name, onBlur, onChange, value = "" },
                    }) => (
                      <Select
                        name={name}
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        placeholder="اختر نوع الاجتماع"
                        options={meetingType}
                      />
                    )}
                  />
                )}
              />
            </div>

            {watch("meetingType")?.value === 2 && (
              <div className="col-md-6 d-flex align-items-end  justify-content-center mb-4">
                <Button
                  className="w-100 ChooseDeprtmant  bg-mainNavy"
                  onClick={handleOpenModal}
                >
                  اختار الشخص
                </Button>
              </div>
            )}
            {watch("meetingType")?.value === 2 && selectedUsers?.length > 0 && (
              <div className="mb-4">
                <p className="font-semibold text-base mb-3 text-mainText text-center">
                  المستخدمين
                </p>

                <div className="grid grid-cols-2 gap-3">
                  {newUsers?.map((emp, index) => (
                    <EmployeeCard
                      title={`${emp?.firstName} ${emp?.lastName}`}
                      desc={`${emp?.role} ${emp?.country}`}
                      img={emp?.image}
                      key={index}
                    />
                  ))}
                </div>
              </div>
            )}
            <div className="col-md-12 mb-4">
              <Form.Group>
                <InputLabel label={"الاجتماع عن"} />
                <CustomTextArea
                  control={control}
                  name="descraption"
                  placeholder={
                    "اكتب ملاحظات .................................."
                  }
                />
              </Form.Group>
            </div>
            <div className="col-md-6   mb-4">
              <FormControl>
                <FormLabel className="text-mainText ">مكان الاجتماع</FormLabel>
                <Controller
                  name="typeMeeting"
                  control={control}
                  render={({ field: { onChange } }) => (
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      name="radio-buttons-group"
                      className="custom-radio"
                    >
                      <FormControlLabel
                        onChange={(e) => {
                          setMeetingPlace(e.target.value);
                          onChange(1);
                        }}
                        className="text-mainText mt-2 flex gap-3 "
                        value="online"
                        control={
                          <label
                            onClick={() => {
                              setMeetingPlace("online");
                              onChange(1);
                            }}
                            htmlFor="online"
                            className={`w-5 h-5 rounded-[5px] bg-mainItems border ${
                              meetingPlace === "online"
                                ? "!border-[#EFAA20]"
                                : ""
                            }`}
                          >
                            {watch("typeMeeting") === 1 ? <FaCheck /> : null}
                            <Radio sx={{ display: "none" }} />
                          </label>
                        }
                        label="online"
                      />
                      <FormControlLabel
                        className="text-mainText flex gap-3 my-2"
                        onChange={(e) => {
                          setMeetingPlace(e.target.value);
                          onChange(0);
                        }}
                        value="offline"
                        control={
                          <label
                            onClick={() => {
                              setMeetingPlace("offline");
                              onChange(0);
                            }}
                            htmlFor="offline"
                            className={`w-5 h-5 rounded-[5px] bg-mainItems border ${
                              meetingPlace === "offline"
                                ? "!border-[#EFAA20]"
                                : ""
                            }`}
                          >
                            {watch("typeMeeting") === 0 ? <FaCheck /> : null}
                            <Radio id="offline" sx={{ display: "none" }} />
                          </label>
                        }
                        label="offline"
                      />
                    </RadioGroup>
                  )}
                />
              </FormControl>
            </div>
            {meetingPlace === "online" && (
              <div className="col-md-12 meetingLink flex flex-col  mb-4">
                <InputLabel label={"لينك الاجتماع"} mandatory={true} />
                <CustomInput
                  control={control}
                  name={"meetingLink"}
                  rules={{
                    required: "يجب إدخال لينك الاجتماع",
                  }}
                />
                <ErrorMessage
                  errors={errors}
                  name="meetingLink"
                  render={({ message }) => (
                    <p className="text-[#FF0000]">{message}</p>
                  )}
                />
              </div>
            )}
            <div className="col-md-12  mb-4">
              <Form.Group
                className="licenseDate-container flex flex-col"
                controlId="licenseDate"
              >
                <EditableInput
                  renderShow={() => (
                    <div className="border py-1 px-4 rounded ">
                      <p className="text-mainText ">
                        {moment(selectedEvent?.startDate).format("YYYY-MM-DD")}
                      </p>
                    </div>
                  )}
                  renderEdit={() => (
                    <>
                      <InputLabel
                        size={16}
                        label={"تاريخ الاجتماع"}
                        className={"mb-1"}
                      />
                      <CustomDatePicker
                        control={control}
                        name={"startDate"}
                        rules={{
                          required: "يجب اختيار التاريخ",
                        }}
                      />
                    </>
                  )}
                />

                <ErrorMessage
                  name="startDate"
                  errors={errors}
                  render={({ message }) => (
                    <span className="text-red-500">{message}</span>
                  )}
                />
              </Form.Group>
            </div>
            <div dir="rtl" className="col-md-6 mb-2 ">
              <div className="flex justify-end">
                <Form.Group
                  className="licenseDate-container w-100 flex flex-col"
                  controlId="licenseDate"
                >
                  <InputLabel label={"توقيت بدا الاجتماع"} />
                  <EditableInput
                    renderShow={() => (
                      <div className="border py-1 px-4 rounded ">
                        <p className="text-mainText ">{selectedEvent?.start}</p>
                      </div>
                    )}
                    renderEdit={() => (
                      <>
                        <CustomTimePicker
                          control={control}
                          name="start"
                          rules={{
                            required: "من فضلك اكتب وقت بدا الاجتماع",
                          }}
                        />
                        <ErrorMessage
                          name="start"
                          errors={errors}
                          render={({ message }) => (
                            <span className="text-red-500">{message}</span>
                          )}
                        />
                      </>
                    )}
                  />
                </Form.Group>
              </div>
            </div>
            <div dir="rtl" className="col-md-6 mb-2">
              <div>
                <Form.Group
                  className="licenseDate-container w-100 flex flex-col"
                  controlId="licenseDate"
                >
                  <InputLabel label={"توقيت نهاية الاجتماع"} />

                  <EditableInput
                    renderShow={() => (
                      <div className="border py-1 px-4 rounded ">
                        <p className="text-mainText ">{selectedEvent?.end}</p>
                      </div>
                    )}
                    renderEdit={() => (
                      <>
                        <CustomTimePicker
                          control={control}
                          name="end"
                          rules={{
                            required: "من فضلك اكتب وقت نهاية الاجتماع",
                          }}
                        />
                        <ErrorMessage
                          name="end"
                          errors={errors}
                          render={({ message }) => (
                            <span className="text-red-500">{message}</span>
                          )}
                        />
                      </>
                    )}
                  />
                </Form.Group>
              </div>
            </div>
            {CheckRole(roles["admin"]) || createdByMe ? (
              <>
                <div className="flex gap-3 justify-center items-center">
                  <SaveButton
                    type="submit"
                    text={updateIsLoading ? <Progress isSmall /> : " تعديل"}
                    // onClick={(e) => onSubmit(e)}
                    className="mt-4 bg-green-500 text-mainText border-0 disabled "
                  >
                    {updateIsLoading ? <Progress isSmall /> : " حفظ"}
                  </SaveButton>
                  <DeleteEvent
                    id={id}
                    setShowEditDeleteModal={setShowEditDeleteModal}
                  />
                </div>
              </>
            ) : null}
          </Form>
        </EditDeleteMeetingWrapper>
      )}
    </>
  );
};

const CustomFormInput = ({ renderInput, label, errors, name }) => {
  return (
    <>
      <InputLabel label={label} className={"mb-3"} mandatory={true} />
      {renderInput()}
      <ErrorMessage
        name={name}
        errors={errors}
        render={({ message }) => (
          <span className="text-red-500">{message}</span>
        )}
      />
    </>
  );
};

export default EditMeetingForm;
