import React from "react";
import { NavButton } from "./components/NavButton";
import { Grid } from "@mui/material";
import { ColumnTitle } from "./components/ColumnTitle";
import { Column } from "./components/Column";
import { Outlet } from "react-router-dom";
import { OrdersWrapperPage } from "./components/OrdersWrapperPage";

export default function Orders() {
  return (
    <OrdersWrapperPage>
      <Grid item xs={3} className="hidden xl:grid">
        <Column sx={{ px: 1 }}>
          <ColumnTitle title={"الطلبات"} />
          <NavButton to={"/System/Settings/Orders/categories"}>
            استخدام المشروع
          </NavButton>
          <NavButton to={"/System/Settings/Orders/services"}>
            خدمات المشروع
          </NavButton>
        </Column>
      </Grid>
      <Outlet />
    </OrdersWrapperPage>
  );
}
