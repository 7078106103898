import React from "react";
import { Outlet } from "react-router-dom";
import withGuard from "../../../utiltis/withGuard";
import { CheckRole } from "../../../helper/utils";
import { roles } from "../../../helper/fetchers/Tasks";

const MeetingsModule = () => {
  return (
    <>
      <Outlet />
    </>
  );
}
export default withGuard(MeetingsModule,[CheckRole(roles["user"]),CheckRole(roles["auditor"]),CheckRole(roles["manager"]),CheckRole(roles["administrator"]),CheckRole(roles["HR"]),CheckRole(roles["accountant"]),CheckRole(roles["admin"])])