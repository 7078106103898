import { StatisticsCard } from "../../../../Components/StatisticsCard";
import { CompletedIcon, InProgressIcon, LatetIcon, TasksIcon } from "../../../../utiltis/Icons";

export const TasksStatistics = ({ data }) => {
    return (
      <div className="row my-4">
        <div className="col-md-3">
          <StatisticsCard title={"المهام"} total={data?.tasksCount} icon={<TasksIcon />} />
        </div>
        <div className="col-md-3">
          <StatisticsCard
            title={"قيد التنفيذ"}
            total={data?.taskStatistics?.IN_PROGRESS}
            icon={<InProgressIcon />}
            nested
          />
        </div>
        <div className="col-md-3">
          <StatisticsCard
            title={"مكتملة"}
            total={data?.taskStatistics?.COMPLETED}
            icon={<CompletedIcon />}
            nested
          />
        </div>
        <div className="col-md-3">
          <StatisticsCard
            title={"متأخره"}
            total={data?.taskLateCount?.late}
            icon={<LatetIcon />}
            nested
          />
        </div>
      </div>
    );
  };
  