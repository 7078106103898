import { useState } from "react";

const useTypingNow = () => {
  const [typingNow, setTypingNow] = useState(false);
  const startTyping = () => setTypingNow(true);
  const stopTyping = () => setTypingNow(false);
  return {
    typingNow,
    startTyping,
    stopTyping,
  };
};

export default useTypingNow;
