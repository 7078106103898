import { useEffect, useMemo, useState } from "react";
import DatePicker from "react-datepicker";
import { UseInput } from "../../../../hooks";
import { Form } from "react-bootstrap";
import Input from "../../../FormHandler/Input";
import { useStepContext } from "../../../../Context/StepContext";
import Progress from "../../../Progress";
import moment from "moment";
const AddProjectStepThree = (props) => {
  // Design Data
  // context variabules

  const {
    userData,
    setUserData,
    Submitted,
    selectProjectType,
    setSelectedProjectType,
    submitRequest,
  } = useStepContext();
  const [userDataVaild, setUserDataVaild] = useState(false);
  //check project type

  // Design Data
  const agent = UseInput(`${userData.agent ? userData.agent : ""}`, "", true);
  const agencyNumber = UseInput(
    `${userData.agencyNumber ? userData.agencyNumber : ""}`,
    "",
    true
  );
  const [agencyAttachments, setAgencyAttachments] = useState(
    userData.agencyAttachments ? userData.agencyAttachments : ""
  );
  const [instrumentImage, setInstrumentImage] = useState(
    userData.instrumentImage ? userData.instrumentImage : ""
  );

  const instrumentNumber = UseInput(
    `${userData.instrumentNumber ? userData.instrumentNumber : ""}`,
    "",
    true
  );

  const [notes, setNotes] = useState(userData.notes ? userData.notes : "");

  // console.log(notes);

  // check desing vaildation
  const [IsVaildState, setIsVaildState] = useState(false);

  const signalParent = (isValid) => {
    setIsVaildState(isValid);
    // props.signalIfValid(isValid);
  };
  const [licenseAttachments, setlicenseAttachments] = useState();

  useMemo(() => {
    if (
      (agent?.value ||
        agencyNumber?.value ||
        agencyAttachments ||
        instrumentNumber.value) &&
      selectProjectType?.value === 1
    ) {
      const updatedUserData = {
        ...userData,
        agent: agent.value,
        agencyNumber: agencyNumber?.value,
        agencyAttachments,
        instrumentNumber: instrumentNumber?.value,
        instrumentImage: instrumentImage,
        notes: notes,
      };
      setUserData(updatedUserData);
      setUserDataVaild(true);
      signalParent(true);
    }
  }, [
    agent?.value,
    agencyNumber?.value,
    agencyAttachments,
    instrumentNumber.value,
    notes,
  ]);

  // Review Data
  const licenseNumber = UseInput(
    `${userData.licenseNumber ? userData.licenseNumber : ""}`,
    "number",
    true
  );
  const licenseDeed = UseInput(
    `${userData.licenseDeed ? userData.licenseDeed : ""}`,
    "number",
    true
  );
  const [licenseDate, setlicenseDate] = useState(
    userData.licenseDate ? new Date(userData?.licenseDate) : null
  );

  // check Review validation
  useMemo(() => {
    if (
      (licenseNumber.value ||
        licenseDeed.value ||
        licenseDate ||
        licenseAttachments) &&
      selectProjectType?.value === 2
    ) {
      signalParent(true);
      const updatedUserData = {
        ...userData,
        licenseNumber: licenseNumber?.value,
        licenseDeed: licenseDeed?.value,
        licenseDate: moment(licenseDate).format("YYYY-MM-DD"),
        licenseAttachments,
        instrumentImage: instrumentImage,
        notes,
      };
      setUserData(updatedUserData);
      setUserDataVaild(true);
    }
  }, [
    licenseNumber.value,
    licenseDeed.value,
    licenseDate,
    licenseAttachments,
    notes,
  ]);

  useEffect(() => {
    signalParent(IsVaildState);
  }, []);

  useEffect(() => {
    signalParent(IsVaildState);
  }, [IsVaildState]);

  return (
    <fieldset className="addProjectStep step-three mx-auto">
      {selectProjectType?.value === 1 ? (
        <legend className="text-center text-mainText">
          
          اضافة بيانات الوكالة
        </legend>
      ) : (
        <legend className="text-center text-mainText">
          اضافة بيانات الرخصة
        </legend>
      )}

      {selectProjectType?.value === 1 ? (
        <Form className=" row w-100 m-auto ">
          <div className="col-md-6 mb-4">
            <Input
              className="h-[37px]"
              label={" الوكيل "}
              {...agent.bind}
              mandatory
            />
          </div>
          <div className="col-md-6 mb-4">
            <Input
              className="h-[37px]"
              label={" رقم الوكيل "}
              {...agencyNumber.bind}
              mandatory
            />
          </div>

          <div className="col-md-6 mb-4">
            <Form.Group controlId="formBasicImage">
              <Form.Label className="d-flex gap-2 align-items-center text-mainText">
                ارفاق الوكالة
              </Form.Label>
              <Form.Control
                type="file"
                placeholder="      ارفاق الوكالة "
                name="imageFile"
                onChange={(e) => setAgencyAttachments(e.currentTarget.files[0])}
              />
            </Form.Group>
          </div>

          <div className="col-md-6 mb-4">
            <Input
              className="h-[37px]"
              label={"رقم الصك"}
              {...instrumentNumber.bind}
              mandatory
            />
          </div>
          <div className="col-md-6 mb-4">
            <Form.Group controlId="formBasicImage">
              <Form.Label className="d-flex flex-column gap-2 ">
                <span> صورة الصك</span>
              </Form.Label>
              <Form.Control
                type="file"
                multiple="multiple"
                // width={100}
                // height={100}
                // className="choose-file-input w-full"
                placeholder="صورة الصك"
                name="imageFile"
                onChange={(e) => setInstrumentImage(e.currentTarget.files[0])}
              />
            </Form.Group>
          </div>
          <div className="col-md-12 mb-4">
            <Form.Label className="d-flex gap-2 align-items-center">
              ملاحظاتك
            </Form.Label>
            <Form.Control
              as="textarea"
              multiple="multiple"
              placeholder="ادخل ملاحظاتك ..."
              style={{ height: "150px" }}
              value={notes}
              onChange={(e) => {
                setNotes(e.target.value);
                setUserData((prevData) => ({
                  ...prevData,
                  notes: e?.target.value,
                }));
              }}
            />
          </div>
          <div className="col-md-12 d-flex justify-content-end  align-items-end mb-4">
            <button
              // disabled={!userDataVaild}
              onClick={submitRequest}
              className="  mt-4 sumbmitAddUpdateUser border-0 disabled "
            >
              
              {" حفظ"}
              {/* {Submitted ? <Progress isSmall /> : " حفظ"} */}
            </button>
          </div>
        </Form>
      ) : (
        <Form className="row w-100 m-auto ">
          <div className="col-md-6 mb-4">
            <Input
              className="h-[37px]"
              placeholder="ادخل رقم الرخصة"
              label={" رقم الرخصة "}
              {...licenseNumber.bind}
              mandatory
            />
          </div>
          <div className="col-md-6 mb-4">
            <Input
              className="h-[37px]"
              placeholder="ادخل سند الرخصة"
              label={" سند الرخصة  "}
              {...licenseDeed.bind}
              mandatory
            />
          </div>
          <div className="col-md-6 mb-4">
            <Form.Group controlId="formBasicImage">
              <Form.Label className="d-flex flex-column gap-2 ">
                <span> صورة الصك</span>
              </Form.Label>
              <Form.Control
                type="file"
                multiple="multiple"
                // width={100}
                // height={100}
                // className="choose-file-input w-full"
                placeholder="صورة الصك"
                name="imageFile"
                onChange={(e) => setInstrumentImage(e.currentTarget.files[0])}
              />
            </Form.Group>
          </div>
          <div className="col-md-6  mb-4">
            <div>
              <Form.Group
                className="licenseDate-container w-100"
                controlId="licenseDate"
              >
                <Form.Label className="d-flex gap-2 align-items-center">
                  تاريخ الرخصة
                </Form.Label>

                <DatePicker
                  selected={licenseDate}
                  placeholderText=" ادخل تاريخ الرخصة "
                  onChange={(date) => setlicenseDate(date)}
                  dateFormat="dd-MM-yyyy"
                  className="w-100 form-control"
                />
              </Form.Group>
            </div>
          </div>

          <div className="col-md-6 mb-4">
            <Form.Group controlId="formBasicImage">
              <Form.Label className="d-flex gap-2 align-items-center">
                تحميل المستندات
              </Form.Label>
              <Form.Control
                type="file"
                multiple="multiple"
                placeholder=" تحمبل المستندات"
                name="imageFile"
                height="100px"
                onChange={(e) =>
                  setlicenseAttachments(e.currentTarget.files[0])
                }
              />
            </Form.Group>
          </div>

          <div className="col-md-12 mb-4">
            <Form.Label className="d-flex gap-2 align-items-center">
              ملاحظاتك
            </Form.Label>
            <Form.Control
              as="textarea"
              multiple="multiple"
              placeholder="ادخل ملاحظاتك ..."
              style={{ height: "150px" }}
              value={notes}
              onChange={(e) => {
                setNotes(e.target.value);
                setUserData((prevData) => ({
                  ...prevData,
                  notes: e?.target.value,
                }));
              }}
            />
          </div>
          <div className="col-md-12 d-flex justify-content-end  mb-4">
            <button
              // disabled={!userDataVaild}
              onClick={submitRequest}
              className="  mt-4 sumbmitAddUpdateUser border-0 disabled "
            >
              
              {" حفظ"}
              {/* {Submitted ? <Progress isSmall /> : " حفظ"} */}
            </button>
          </div>
        </Form>
      )}
    </fieldset>
  );
};

export default AddProjectStepThree;
