import { toast } from "react-toastify";
import myAxiosInstance, { myAxios, myAxiosJson } from "../https";

export const getAllCategories = (params) =>
  myAxiosJson.get("category",{params}).then((data) => data?.data?.category).catch(error=>{
    toast.error(error?.response?.data?.message)
});

export const getCategory = (id) =>
  myAxiosJson.get(`category/${id}`).then((data) => data?.data?.category).catch(error=>{
    toast.error(error?.response?.data?.message)
});

export const getAllCategoriesService = () => myAxiosInstance.get("category").catch(error=>{
  toast.error(error?.response?.data?.message)
});

export const addCategory = (data) => myAxiosJson.post("category", data);
export const updateCategory = (categoryId, data) =>
  myAxiosJson.patch(`category/${categoryId}`, data);
export const deleteCategory = (categoryId) =>
  myAxiosJson.delete(`category/${categoryId}`);
