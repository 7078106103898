import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useSuccessfullModal from "../../../Modals/hooks/useSuccessfullModal";
import useModal from "../hooks/useModal";
import Image from "../../../Image";
import { useRejecetRequestwithId } from "../../../../hooks/fetchers/Requests";
import RejectModel from "../../../Modals/RejectModel";
import SuccessfullModal from "../../../Modals/SuccessfullModal";
import CustomModal from "../../../Modals/CustomModal";
import LoadingPage from "../../../feedback/LoadingPage";

const RejectTask = ({ id, path = "", setShowProject }) => {
  const navigate = useNavigate();
  const [message, setMessage] = useState("حدث خطأ ما");
  const {
    handleCloseError,
    handleCloseSuccess,
    handleShowError,
    handleShowSuccess,
    showError,
    showSuccess,
  } = useSuccessfullModal();
  const { show, handleOpenModal, handleCloseModal } = useModal();
  const {
    show: showReject,
    handleOpenModal: handleOpenModalReject,
    handleCloseModal: handleCloseModalReject,
  } = useModal();
  const { mutate, isLoading, isError, error } = useRejecetRequestwithId(
    id,
    handleShowSuccess,
    handleShowError
  );
  const handleLeaveComment = (data) => {
    const formData = new FormData();
    formData.append("reasonRejected", data.reasonRejected);
    Object.keys(data?.rejectAttachments)?.forEach((key) => {
      formData.append("rejectAttachments", data.rejectAttachments[key]);
    });
    mutate(formData);
  };
  useEffect(() => {
    setMessage(error?.response?.data?.message);
  }, [isError, error]);
  return (
    <>
      {isLoading ? <LoadingPage /> : null}
      <Image
        className="pointer declince"
        onClick={handleOpenModal}
        src={process.env.PUBLIC_URL + "/icons/declince.png"}
      />
      <CustomModal
        show={show}
        title={"التاكيد"}
        handleClose={handleCloseModal}
        handleSave={() => {
          handleCloseModal();
          handleOpenModalReject();
        }}
        message={"هل انت متاكد من انك تريد رفض الطلب"}
      />
      <RejectModel
        show={showReject}
        message={"اترك سبب الرفض"}
        handleClose={handleCloseModalReject}
        handleSave={handleLeaveComment}
      />
      <SuccessfullModal
        show={showSuccess}
        message={"تم رفض الطلب بنجاح"}
        handleClose={() => {
          handleCloseSuccess();
          navigate(path);
          setShowProject(false);
        }}
      />
      <SuccessfullModal
        show={showError}
        message={message}
        handleClose={handleCloseError}
        status="error"
      />
    </>
  );
};

export default RejectTask;
