import React, { useState } from "react";
import AuthWrapper from "../components/AuthWrapper";
import AuthCard from "../components/AuthCard";
import { Form } from "react-bootstrap";
import SubmitButton from "../components/SubmitButton";
import Image from "../../../../Components/Image";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { IoEyeOffOutline, IoEyeOutline } from "react-icons/io5";

/**
 * 
 * @returns ${
                        errors.otp?.[index]
                          ? "border-red-500"
                          : "border-gray-300"
                      }
 */

const NewPassword = () => {
  const [openEye, setOpenEye] = useState(false);
  const [openCEye, setOpenCEye] = useState(false);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { isLoading },
  } = useForm({
    mode: "all",
    defaultValues: {
      confirm: "",
      password: "",
    },
  });
  const togglePassword = () => {
    setOpenEye(!openEye);
  };
  const toggleCPassword = () => {
    setOpenCEye(!openCEye);
  };
  const onSubmit = (data) => {};
  return (
    <AuthWrapper>
      <AuthCard>
        <Form onSubmit={handleSubmit(onSubmit)} className="w-75 h-100">
          <div className="w-full">
            <Image
              src="https://bsa2011.com/images/systemLogin.gif"
              // style={{ width: "279px", height: "214px" }}
              alt="logo image"
              width={250}
              height={150}
              className={"pointer-events-none"}
            />
          </div>
          <div className="w-full">
            <Image
              src="/lock.png"
              // style={{ width: "379px", height: "214px" }}
              alt="logo image"
              className={"pointer-events-none"}
              width={150}
              height={150}
            />
          </div>
          <div className="mb-3 space-y-2">
            <h2 className="font-medium text-4xl">{"ادخل كلمة مرور جديدة"}</h2>
          </div>
          <Form.Group>
            <Form.Label className="text-black "> كلمة المرور</Form.Label>
            <div className="form-control p-0 flex items-center !bg-transparent mb-4 h-[48.99px] border !border-[#94713E] hover:!border-[#94713E]  focus:!border-[#94713E] focus:bg-transparent active:bg-transparent">
              <input
                {...register("password", {
                  required: "يجب إدخال كلمة السر",
                })}
                className="form-control text-black placeholder:!text-black bg-transparent h-full !shadow-none focus:shadow-none  !border-none !outline-none"
                type={openEye ? "text" : "password"}
                placeholder="ادخل كلمه مرور المستخدم"
                autoComplete="current-password"
              />
              <button className="p-2" type="button" onClick={togglePassword}>
                {openEye ? (
                  <IoEyeOutline color="black" />
                ) : (
                  <IoEyeOffOutline color="black" />
                )}
              </button>
            </div>
          </Form.Group>
          <Form.Group>
            <Form.Label className="text-black "> تأكيد كلمة المرور</Form.Label>
            <div className="form-control p-0 flex items-center !bg-transparent mb-4 h-[48.99px] border !border-[#94713E] hover:!border-[#94713E]  focus:!border-[#94713E] focus:bg-transparent active:bg-transparent">
              <input
                {...register("confirm", {
                  required: "يجب تأكيد كلمة المرور",
                })}
                className="form-control text-black placeholder:!text-black bg-transparent h-full !shadow-none focus:shadow-none  !border-none !outline-none"
                type={openCEye ? "text" : "password"}
                placeholder="تأكيد كلمه المرور"
                autoComplete="current-password"
              />
              <button className="p-2" type="button" onClick={toggleCPassword}>
                {openCEye ? (
                  <IoEyeOutline color="black" />
                ) : (
                  <IoEyeOffOutline color="black" />
                )}
              </button>
            </div>
          </Form.Group>

          <div className="flex justify-center items-center ">
            {/* <Link to="/otp"> */}
            <SubmitButton>
              {"ارسال"}
              {/* {isLoading ? <Progress isSmall /> : " الدخول الي الحساب"} */}
            </SubmitButton>
            {/* </Link> */}
          </div>
        </Form>
      </AuthCard>
    </AuthWrapper>
  );
};

export default NewPassword;
