import React from "react";
import { Accordion } from "react-bootstrap";
import { CheckRole } from "../../../helper/utils";
import { roles } from "../../../helper/fetchers/Tasks";
import { CustomNav } from "../PlanModel/components/CustomNav";
import { useProjectContext } from "../PlanModel/context/ProjectContext";
import { useTaskContext } from "../PlanModel/context/TaskContext";

const TasksDropdown = ({ setOpen }) => {
  const { setFilterPlansParams } = useProjectContext();
  const { setFilterTasksParams } = useTaskContext();

  return (
    <div className="absolute top-[4.5rem]  z-[1000000] bg-mainNavy rounded-xl">
      <div className="flex items-start flex-col  bg-mainItems w-[300px] h-[300px]  p-2 overflow-auto shadow-2xl ">
        <div
          className="w-screen h-screen fixed inset-0  z-[-1]"
          onClick={() => {
            setOpen(false);
          }}
        />
        <div className="p-2 bg-mainItems rounded-2xl h-full w-full">
          <Accordion defaultActiveKey={null}>
            {!(
              CheckRole(roles["user"]) ||
              CheckRole(roles["administrator"]) ||
              CheckRole(roles["auditor"])
            ) && (
              <CustomNav
                title={"المشاريع"}
                path={"/System/tasks/plans"}
                items={[
                  {
                    title: "كل المشاريع",
                    onClick: () => {
                      setFilterPlansParams({});
                    },
                  },
                  {
                    title: "مشاريع قيد التنفيذ",
                    onClick: () => {
                      setFilterPlansParams({ status: "1" });
                    },
                  },
                  {
                    title: "مشاريع معلقه",
                    onClick: () => {
                      setFilterPlansParams({ status: "0" });
                    },
                  },
                  {
                    title: "مشاريع منتهيه",
                    onClick: () => {
                      setFilterPlansParams({ status: "2" });
                    },
                  },
                ]}
              />
            )}

            <CustomNav
              title={"المهام"}
              path={"/System/tasks/tasks"}
              items={[
                {
                  title: "كل المهام",
                  onClick: () => {
                    setFilterTasksParams({});
                  },
                },
                {
                  title: "مهام قيد التنفيذ",
                  onClick: () => {
                    setFilterTasksParams({ status: 1 });
                  },
                },
                {
                  title: "مهام معلقه",
                  onClick: () => {
                    setFilterTasksParams({ status: 0 });
                  },
                },
                {
                  title: "فى انتظار الموافقة الأولية",
                  onClick: () => {
                    setFilterTasksParams({ status: 2, approvement: 0 });
                  },
                },
                {
                  title: "فى انتظار الموافقة الثانية",
                  onClick: () => {
                    setFilterTasksParams({ status: 2, approvement: 1 });
                  },
                },
                {
                  title: "فى انتظار الموافقة النهائية",
                  onClick: () => {
                    setFilterTasksParams({ status: 2, approvement: 2 });
                  },
                },
                {
                  title: "مكتملة",
                  onClick: () => {
                    setFilterTasksParams({ status: 2, approvement: 3 });
                  },
                },
              ]}
            />
            {(CheckRole(roles["auditor"]) || CheckRole(roles["manager"])) && (
              <CustomNav
                title={"المهام الخارجيه"}
                path={"/System/tasks/external-tasks"}
                items={[]}
              />
            )}
          </Accordion>
        </div>
      </div>
    </div>
  );
};

export default TasksDropdown;
