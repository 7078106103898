import SearchButton from "../SearchButton";
import { useGetAllClauses } from "../../../../hooks/fetchers/Clause";
import { ClausesBtn } from "./components/ClausesBtn";
import { OptionsButton } from "./components/OptionsButton";
import { AccounatingPageWrapper } from "./components/AccounatingPageWrapper";
import { Column } from "./components/Column";
import { FormModal } from "../../PlanModel/components/FormModal";
import Progress from "../../../../Components/Progress";
import { Fragment, useState } from "react";
import { SearchComponent } from "../../../../Components/SearchComponent/SearchComponent";

export const termsData = [
  { id: 1, name: "اساسية" },
  { id: 2, name: "ايجارات" },
  { id: 3, name: "تجديد سجلات" },
  { id: 4, name: "تأمينات" },
  { id: 5, name: "اخري" },
];

export const ClauseItem = ({ name, data, id }) => {
  return (
    <div
      className={`flex w-full justify-between items-center px-2 text-mainCustomGray border hover:!border-[#EFAA20] text-base ${"!border-transparent"}`}
    >
      <button className="w-full text-mainText text-right py-3">{name}</button>
      <OptionsButton data={data} id={id} />
    </div>
  );
};
export default function Accounating() {
  const [search, setSearch] = useState("");
  const {
    data: clauses,
    isLoading,
    isError,
    error,
  } = useGetAllClauses({ search });
  const handleSearch = (value) => {
    setSearch(value);
  };
  return (
    <AccounatingPageWrapper>
      <Column xs={3}>
        <div className="p-2 ">
          <p className="w-full text-mainText text-right my-2">الحسابات </p>
          <div className="flex flex-col gap-2">
            <ClausesBtn title={"البنود"} />
          </div>
        </div>
      </Column>
      <Column xs={9}>
        <div className="p-3">
          <SearchComponent
            className="m-auto w-[95%] focus-visible:border-[#E4A11B]"
            background={"var(--main-navy)"}
            border="border !border-[#E4A11B] focus-visible:border-[#E4A11B]"
            value={search}
            handleChange={handleSearch}
          />
        </div>
        <FormModal
          title={"كل البنود "}
          className={"flex-1 overflow-y-auto scrollbar-none"}
        >
          {/* {(isError) ? <p  >{`${error?.message}`}</p>:null} */}
          {isLoading && !isError ? <Progress /> : null}
          {!isLoading && !isError ? (
            <Fragment>
              {clauses?.length ? (
                clauses?.map(({ _id, name, descraption, image }, index) => (
                  <ClauseItem
                    name={name}
                    data={clauses[index]}
                    id={_id}
                    key={_id}
                  />
                ))
              ) : (
                <p className="text-xl text-center text-mainText">
                  لا يوجد بيانات لعرضها
                </p>
              )}
            </Fragment>
          ) : null}
        </FormModal>
      </Column>
    </AccounatingPageWrapper>
  );
}
