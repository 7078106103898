import React, { createContext, Fragment, useContext, useState } from "react";
import { BiUser } from "react-icons/bi";
import { SlPhone } from "react-icons/sl";
import { TiClipboard } from "react-icons/ti";
import { useParams } from "react-router-dom";
import DownloadButton from "../../../../../../Components/Buttons/DownloadButton";
import { Tab, Tabs } from "@mui/material";
import { FormModal } from "../../../../../System/PlanModel/components/FormModal";
import moment from "moment";
import CustomTable from "../../../../../../Components/Table";
import { TableRow } from "../../../../../../Components/Table/TableRow";
import { TableCell } from "../../../../../../Components/Table/TableCell";
import {
  clientTypeEnum,
  GenerateColumnsNames,
  projectTypeEnum,
  statusEnum,
} from "../../../../../../helper/utils";
import { Spinner } from "react-bootstrap";
import ExcellModall from "../../../../../../Components/Modals/ExcellModall";
import useExportExcel from "../../../../../../hooks/useExportExcel";

import Image from "../../../../../../Components/Image";
import useModal from "../../../../../../hooks/useModal";
import { a11yProps } from "../../../consts";
import TabPanel from "../../../components/TabPanel";
import { ImPrinter } from "react-icons/im";
import { TiExport } from "react-icons/ti";
import {
  useGetClientPDFReport,
  useGetClientReport,
  useGetClientsProjectsReports,
} from "../../../../../../hooks/fetchers/Clients";
import PrintClientDetailsFile from "../../../components/PrintClientDetailsFile";

const ClientDetailsFile = ({ openPdf, handleClosePdfModal, data }) => {
  const ctx = useDetailsContext();
  console.log("ctx", ctx);
  return (
    <PrintClientDetailsFile
      show={openPdf}
      onHide={handleClosePdfModal}
      data={data}
    />
  );
};

const DetailsContext = createContext(null);

const DetailsProvider = ({ children }) => {
  return (
    <DetailsContext.Provider value={{}}>{children}</DetailsContext.Provider>
  );
};

const useDetailsContext = () => {
  const ctx = useContext(DetailsContext);
  if (!ctx) {
    throw Error("use inside context");
  }
  return ctx;
};

const ClientDetailsReport = () => {
  const { id } = useParams();
  const { data, isLoading, isError } = useGetClientReport(id);
  const {
    data: dataPDF,
    isLoading: isLoadingPDF,
    isError: isErrorPDF,
  } = useGetClientPDFReport(id);

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const { loading, allKeys, excelData, fileUrl, handleExport, resetExcelData } =
    useExportExcel();
  const {
    open: openPdf,
    handleOpenModal: handleOpenPdfModal,
    handleCloseModal: handleClosePdfModal,
  } = useModal();

  return (
    <div className="p-2">
      {!loading && excelData && (
        <ExcellModall
          onHide={resetExcelData}
          fileUrl={fileUrl}
          keys={allKeys}
          data={excelData}
        />
      )}

      {!isLoading && !isError && data ? (
        <Fragment>
          {!isLoadingPDF && !isErrorPDF ? (
            <DetailsProvider>
              <ClientDetailsFile
                openPdf={openPdf}
                handleClosePdfModal={handleClosePdfModal}
                data={dataPDF}
              />
            </DetailsProvider>
          ) : null}

          <div className="flex justify-end items-center p-3">
            <div className="flex items-center gap-2">
              <DownloadButton
                onClick={() =>
                  handleExport(`system-reports/clients/${id}/export/excel`)
                }
                // system-reports/clients/668bb1d93a27d52cbb92fdb4/export/excel
              >
                <div className="flex items-center gap-1">
                  <span>تصدير Excel</span>
                  <TiExport />
                </div>
              </DownloadButton>
              <DownloadButton onClick={handleOpenPdfModal}>
                <div className="flex items-center gap-1">
                  <span>طباعة</span>
                  <ImPrinter />
                </div>
              </DownloadButton>
            </div>
          </div>
          <div className="flex items-center gap-4 p-3">
            <div className="space-y-2">
              <p className="text-[#EFAA20] font-medium flex items-center gap-2">
                <BiUser size={20} /> {`${data?.ownerName}`}
              </p>
              <p className="text-mainText flex items-center gap-2">
                <TiClipboard size={20} />
                {clientTypeEnum[data?.clientType]}
              </p>
              <p className="text-mainText flex items-center gap-2">
                <SlPhone size={20} />
                <span dir="ltr">{data?.phone}</span>
              </p>
            </div>
          </div>
          <div className="flex justify-start " dir="">
            <Tabs
              value={value}
              onChange={handleChange}
              // variant="scrollable"
              variant="standard"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
              className="text-mainText"
            >
              <Tab
                className="!text-mainText"
                {...a11yProps(0)}
                label="معلومات العامة"
              />
              <Tab
                className="!text-mainText"
                {...a11yProps(1)}
                label="المشاريع"
              />
            </Tabs>
          </div>
          <div className="max-h-[650px] overflow-y-auto scrollbar-thin scrollbar-track-transparent scrollbar-thumb-[#EFAA20]">
            <TabPanel value={value} index={0}>
              <div className="space-y-4">
                <FormModal className="space-y-4">
                  <p className="">
                    {"اسم العميل : "} {data?.ownerName}
                  </p>

                  <p className="">
                    {"نوع العميل : "} {clientTypeEnum[data?.clientType]}
                  </p>

                  <p>
                    {"البريد الأكترونى: "} {data?.email}
                  </p>
                  <p>
                    {"رقم الجوال : "} <span dir="ltr">{data?.phone}</span>
                  </p>
                  <p>
                    {"نوع الهوية : "}
                    {data?.identityType}
                  </p>
                  <p>
                    {"رقم الهوية : "} {data?.identityNumber}
                  </p>
                </FormModal>
              </div>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <TasksProvider>
                <div className="space-y-4">
                  <p className="text-sm font-medium">{"الشهر الحالى "}</p>

                  <ClientProjects email={{ email: data?.email }} />
                </div>
              </TasksProvider>
            </TabPanel>
          </div>
        </Fragment>
      ) : null}
    </div>
  );
};

export default ClientDetailsReport;

const TasksContext = createContext(null);
const TasksProvider = ({ children }) => {
  const [taskStatus, setTaskStatus] = useState("");
  return <TasksContext.Provider value={{}}>{children}</TasksContext.Provider>;
};
const useTasksContext = () => {
  const ctx = useContext(TasksContext);
  if (!ctx) {
    throw Error("use inside provider");
  }
  return ctx;
};

const columns2 = GenerateColumnsNames(
  "م",
  "اسم المشروع",
  "رقم الطلب ",
  "نوع المشروع",
  "تاريخ الاستلام",
  "الحالة",
  "عرض"
);

const ClientProjects = ({ email }) => {
  const {
    data: dataTasks,
    isLoading: isLoadingTasks,
    isError: isErrorTasks,
  } = useGetClientsProjectsReports(email);
  return (
    <>
      <div className="w-full flex justify-center">
        <div className="w-full flex justify-center items-center">
          {isLoadingTasks ? <Spinner /> : null}
        </div>
        {isErrorTasks ? (
          <p className="text-red-400">يوجد خطا فى البيانات</p>
        ) : null}
      </div>
      <ProjectsTable projects={dataTasks} />
    </>
  );
};

const ProjectsTable = ({ projects }) => {
  const [page, setPage] = useState(1);
  const handleChange = (event, value) => {
    setPage(value);
    // setFilterTasksParams({ page: value });
  };
  return (
    <CustomTable
      columns={columns2}
      data={projects?.projects}
      paginationProps={{
        count: projects?.pages,
        page: page,
        onChange: handleChange,
      }}
    >
      {projects?.projects && projects?.projects?.length > 0
        ? projects?.projects?.map(
            (
              {
                _id,
                projectName,
                orderNumber,
                deliveryDate,
                projectType,
                projectStatus,
              },
              index
            ) => {
              return (
                <TableRow
                  className={`my-2 border !border-[#efaa207f] ${
                    index % 2 === 0 ? "bg-mainNavy" : ""
                  }`}
                  key={_id}
                >
                  <TableCell>{(page - 1) * 10 + (index + 1)}</TableCell>
                  <TableCell>{projectName || "لا يوجد مخطط"}</TableCell>
                  <TableCell>{orderNumber}</TableCell>

                  <TableCell>
                    {projectTypeEnum[projectType] || "--------"}
                  </TableCell>
                  <TableCell>
                    {moment(deliveryDate).format("YYYY-MM-DD") || "--------"}
                  </TableCell>
                  <TableCell>
                    {statusEnum[projectStatus]?.title || "--------"}
                  </TableCell>
                  <TableCell>
                    <button>
                      <Image src="/icons/view.svg" alt="" className="w-full" />
                    </button>
                  </TableCell>
                </TableRow>
              );
            }
          )
        : null}
    </CustomTable>
  );
};
