import React from "react";
import Image from "./Image";
import { Modal } from "react-bootstrap";
import useModal from "../hooks/useModal";
import { twMerge } from "tailwind-merge";

const ProfileImage = ({ image ,imageClassName , alt=""}) => {
  const { open, handleOpenModal, handleCloseModal } = useModal();
  return (
    <>
      <Image
        src={image}
        alt={alt}
        className={twMerge("w-[92px] h-[92px] rounded-full aspect-square border !border-gray-500 bg-gray-400 cursor-pointer",imageClassName)}
        onClick={handleOpenModal}
      />
      <Modal
        size="lg"
        centered
        show={open}
        onHide={handleCloseModal}
        contentClassName="p-0  w-fit mx-auto bg-transparent rounded-full"
        className="bg-black/70"
      >
        <Modal.Body className="flex flex-col items-center p-0 rounded-full">
          <img src={image} alt="" className="w-full rounded-full aspect-square border !border-gray-500 bg-gray-400" />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ProfileImage;
