import React, { createContext, useContext, useState } from "react";

export const showProjectContext = createContext();

export const ShowProjectProvider = ({ children }) => {
  const [showProjectFlag, setShowProjectFlag] = useState(false);

  return (
    <showProjectContext.Provider
      value={{ showProjectFlag, setShowProjectFlag }}
    >
      {children}
    </showProjectContext.Provider>
  );
};

export const useShowProjectContext = () => {
  const ctx = useContext(showProjectContext);
  if (!ctx) {
    throw Error("use Inside Context");
  }
  return ctx;
};
