import React, { useState } from "react";
import { Form, Modal } from "react-bootstrap";
import SaveButton from "../SaveButton";
import { toast } from "react-toastify";
import { IoCloudUploadOutline } from "react-icons/io5";

const AddAttachment = ({
  attachment,
  setAttachment,
  multi,
  open,
  modalOnly,
  setOpen,
  click,
}) => {
  const [openAddAttachemnt, setOpenAddAttachemnt] = useState(false);
  return (
    <div className="w-fit ">
      {(openAddAttachemnt || open) && (
        <Modal
          className="submitSystemPoper"
          size="sm"
          aria-labelledby="contained-modal-title-vcenter"
          onHide={() => (open ? setOpen(false) : setOpenAddAttachemnt(false))}
          show={openAddAttachemnt || open}
        >
          <Modal.Body className="d-flex align-items-center ">
            {/* <img
              src={`${process.env.PUBLIC_URL}/chooseFile.png`}
              className="my-3"
              alt="choose file"
            /> */}
            <div className="flex items-center justify-center flex-col gap-3">
              <div className="flex flex-col w-[150px] h-[90px] bg-mainNavy rounded-md items-center justify-center">
                <IoCloudUploadOutline className="text-3xl " />
                <p className="text-mainText text-lg">ارفق الملف </p>
              </div>
              <div className="flex ">
                <Form.Group className={``} controlId="formBasicImage">
                  <Form.Control
                    type="file"
                    name="imageFile"
                    multiple={true}
                    htmlFor="formFile"
                    className={`chooseFile text-mainText`}
                    onChange={(e) => {
                      const files = e.target.files;
                      if (multi) {
                        if (files.length > 5) {
                          toast.error(
                            "You can only select a maximum of 5 files."
                          );
                          e.target.value = "";
                        } else {
                          setAttachment(files);
                        }
                      } else {
                        setAttachment(files);
                      }
                    }}
                  />
                </Form.Group>

                <div
                  className="flex justify-center "
                  onClick={() => {
                    setOpenAddAttachemnt(false);
                    click && click();
                  }}
                >
                  <SaveButton />
                </div>
              </div>
            </div>
            {/* </div> */}
          </Modal.Body>
        </Modal>
      )}

      {!modalOnly && (
        <div
          className={`addFileShape pointer my-1 bg-mainNavy p-0  d-flex flex-column align-items-center justfiy-content-center`}
        >
          <div
            className={` pointer bg-mainNavy   d-flex flex-column align-items-center justfiy-content-center`}
          >
            <div
              onClick={() => {
                setOpenAddAttachemnt(true);
              }}
            >
              <svg
                className="m-auto"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  d="M1 8H8M8 8H15M8 8V15M8 8V1"
                  stroke="#EFAA20"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <p className="text-sm mx-auto text-mainText">اضافة جديدة</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddAttachment;
