import style from "./StatisticsCarde.module.css";
export const StatisticsCard = ({ title, total, nested, icon }) => {
  return (
    <div
      className={`py-2 px-1 ${
        nested ? style.subCategoryCard : style.categoryCard
      }`}
    >
      <div className="d-flex justify-content-between    laptop:flex-row  tablet:flex-col mobile:flex-col  w-90 h-[75px]  align-items-center mx-2">
        <div>
          <p className="text-white text-bold laptop:text-xl  tablet:text-center tablet:text-sm mobile:text-sm">
            {title}
          </p>
        </div>
        <div className="flex flex-col items-center justify-center">
          {icon}
          <p className="text-white laptop:text-xl tablet:text-center text-xl tablet:text-sm mobile:text-sm">
            {total}
          </p>
        </div>
      </div>
    </div>
  );
};
