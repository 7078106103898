import React from "react";
import { Accordion } from "react-bootstrap";
import { CustomNav } from "../../../System/PlanModel/components/CustomNav";
import { useReportContext } from "../ReportContext";
import { Link, useLocation, useNavigate } from "react-router-dom";

const departmentOption = [
  {
    title: "مدنى",
  },
  {
    title: "معمارى",
  },
  {
    title: "كهرباء",
  },
  {
    title: "ميكانيكا",
  },
  {
    title: "IT",
  },
  {
    title: "موارد بشرية",
  },
  {
    title: "برمجة",
  },
  {
    title: "أدارى",
  },
];

const ReportsSidebar = () => {
  const { setProjectType ,setIsExternal} = useReportContext();
  
  const navigate = useNavigate();
  const { pathname } = useLocation();
  // console.log("pathName", pathname);
  return (
    <div className="p-2 bg-mainItems rounded-2xl h-full">
      <Link to={"/System/Reports"} className="text-mainText font-medium py-1">
        كل التقارير
      </Link>
      <Accordion defaultActiveKey={null}>
        <CustomNav
          title={"الطلبات"}
          path={"requests"}
          items={
            pathname === "/System/Reports/requests"
              ? [
                  {
                    title: "الكل",
                    onClick: () => {
                      setProjectType(null);
                    },
                  },
                  {
                    title: "تصميم",
                    onClick: () => {
                      setProjectType(1);
                    },
                  },
                  {
                    title: "إشراف على التنفيذ",
                    onClick: () => {
                      setProjectType(2);
                    },
                  },
                ]
              : null
          }
        />
        <CustomNav
          title={"المشاريع"}
          path={"projects"}
          items={
            pathname === "/System/Reports/projects"
              ? [
                  {
                    title: "الكل",
                    onClick: () => {
                      setProjectType(null);
                    },
                  },
                  {
                    title: "تصميم",
                    onClick: () => {
                      setProjectType(1);
                    },
                  },
                  {
                    title: "إشراف على التنفيذ",
                    onClick: () => {
                      setProjectType(2);
                    },
                  },
                ]
              : null
          }
        />

        <CustomNav
          title={"المستخدمين"}
          //   path={"/System/tasks/plans"}
          path={"users"}
          items={[
            {
              title: "مدير المكتب",
              onClick: () => {
                navigate(`/System/Reports/managers`);
              },
            },
            {
              title: "كل الأقسام",
              onClick: () => {
                navigate("users/departments");
              },
              subItems: departmentOption?.map((dep) => ({
                title: dep?.title,
                onClick: () => {
                  navigate(`users/departments/${dep?.title}`);
                },
              })),
            },
          ]}
         
        />
        <CustomNav
          title={"العملاء"}
          //   path={"/System/tasks/plans"}
          path={"clients"}
          onClick={()=> {setIsExternal(null)}}
          items={[
            {
              title: "الداخلى",
              onClick: () => {
                setIsExternal(false)
                setProjectType(null)
                // navigate("users/departments");
              },
              subItems: [
                {
                  title: "تصميم",
                  onClick: () => {
                    setProjectType(1)
                  },
                },
                {
                  title: "إشراف على التنفيذ",
                  onClick: () => {
                    setProjectType(2)
                  },
                },
              ],
            },
            // {
            //   title: "الخارجى",
            //   onClick: () => {
            //     setIsExternal(true)
            //     // navigate("users/departments");
            //   },
            //   subItems: [
            //     {
            //       title: "تصميم",
            //       onClick: () => {
            //         setProjectType(1)
            //       },
            //     },
            //     {
            //       title: "إشراف على التنفيذ",
            //       onClick: () => {
            //         setProjectType(2)
            //       },
            //     },
            //   ],
            // },
          ]}
        />
        {/* <CustomNav
          title={"كل الأقسام"}
          path={"users/departments"}
          items={departmentOption?.map(dep=> ({
            title: dep?.title,
            onClick: () => {
              navigate(`users/departments/${dep?.title}`);
            },
          }))}
        /> */}

        {/* <CustomNav
          title={"الحسابات"}
          //   path={"/System/tasks/plans"}
          path={""}
          items={[]}
        /> */}
      </Accordion>
    </div>
  );
};

export default ReportsSidebar;
