import React from "react";
import { DatePicker } from "@mui/x-date-pickers";
import { useController } from "react-hook-form";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import enUS from "date-fns/locale/en-US";
import { CiCalendar } from "react-icons/ci";
export function CustomDatePicker({ control, name, rules, ...props }) {
  const {
    field,
    fieldState: { invalid, isTouched, isDirty },
    formState: { touchedFields, dirtyFields },
  } = useController({
    name,
    control,
    rules: rules,
  });

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enUS}>
      <DatePicker
        onChange={field.onChange}
        onBlur={field.onBlur}
        value={field.value}
        name={field.name}
        inputRef={field.ref}
        InputLabelProps={{}}
        inputFormat="DD-MM-YYYY"
        outputFormat="DD-MM-YYYY"
        slotProps={{
          openPickerButton: {
            sx: { color: "var(--main-text)" },
          },
        }}
        slots={{ openPickerIcon: CiCalendar }}
        // renderInput={(props) => <TextField {...props} helperText="invalid mask" color='white' /> }
        OpenPickerIcon={"x"}
        InputProps={{
          sx: {
            bgcolor: "var(--main-navy)",
            color: "var(--main-text)",
          },
        }}
        sx={{
          "& fieldset": {
            display: "none",
          },
          "& input": {
            p: "0.70rem",
            color: "var(--main-text)",
          },
          "& svg": {
            // color: "Rgba(255,255,255,0.5)",
          },
          bgcolor: "var(--main-navy)",
          color: "var(--main-text)",
          borderRadius: "7px",
        }}
        {...props}
      />
    </LocalizationProvider>
  );
}
