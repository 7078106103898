import React, { useCallback, useEffect, useState } from "react";
import AllUsersPieChart from "../../../../Components/System/Users/AllUsersChart/AllUsersPieChart";

import { useParams } from "react-router-dom";
import {
  getDepartmentStatistics,
  getStatistics,
} from "../../../../helper/fetchers/Users";
import { useGetDashboardDepartmentStatistics } from "../../../../hooks/fetchers/DashboardStatistics";
import CustomChart from "../../Main/components/charts/CustomChart";
import Progress from "../../../../Components/Progress";

export const CountriesChart = () => {
  const { Country } = useParams();
  // const [countryBase, setCountryBase] = useState("Saudia");
  const [data, setData] = useState();
  // const [departmentData, setDepartmentData] = useState([]);

  // useEffect(() => {
  //   if (Country === "Saudia") {
  //     setCountryBase("Saudia");
  //   } else if (Country === "egypt") {
  //     setCountryBase("egypt");
  //   }
  // }, [Country]);

  const getStats = useCallback(async () => {
    const res = await getStatistics();
    if (res?.status === 200) {
      setData(res?.data);
    }
    // const departmentRes = await getDepartmentStatistics();
    // if (departmentRes?.status === 200) {
    //   // console.log(departmentRes?.data);
    //   setDepartmentData(departmentRes?.data);
    // }
  }, []);
  useEffect(() => {
    getStats();
  }, [getStats]);

  const {
    data: dashboardStatistics,
    isLoading: dashboardStatisticsLoading,
    isError: dashboardStatisticsError,
  } = useGetDashboardDepartmentStatistics();

  // background: rgba(47, 46, 70, 0.5);
  // box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

  return (
    <div className="all-users-Chart p-3">
      <div className=" AllUsersPieChart d-flex  w-75  justify-content-end">
        <AllUsersPieChart data={data} />
      </div>
      <fieldset className="All-users-columnChart-container  w-5/6 mx-auto">
        <legend className="text-mainText text-center">كل المستخدمين</legend>
        <div className="All-users-columnChart  d-flex h-full align-items-center flex-column max-h-[370px] overflow-y-auto scrollbar-thin scrollbar-track-transparent scrollbar-thumb-[#EFAA20]">
          {/* <AllUsersColumnChart data={departmentData} /> */}
          <div className="rounded-[16.799px] bg-mainNavy shadow  p-1.5 h-full w-full">
            {dashboardStatisticsError ? (
              <div className="h-56 grid place-content-center">
                <p className="text-red-500 text-xl"> حدث خطأ ما </p>
              </div>
            ) : null}
            {dashboardStatisticsLoading ? (
              <div className="h-56 grid place-content-center">
                <Progress />
              </div>
            ) : null}
            {!dashboardStatisticsError && !dashboardStatisticsLoading ? (
              <CustomChart
                data={
                  Country === "Saudia"
                    ? dashboardStatistics?.Saudi?.departments
                    : dashboardStatistics?.Egypt?.departments
                }
                chartTitle={"احصائيات الموظفين"}
                title={"عدد الموظفين"}
                rowProps={{
                  className: "h-12 text-white",
                }}
                showChart={false}
                mainColTitle = "الاسم"
              />
            ) : null}
          </div>

          {/* <div className="w-90 mx-auto mt-3">
        <DeprtamentSlider data={departmentData} />
      </div> */}
        </div>
      </fieldset>
    </div>
  );
};
