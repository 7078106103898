import { useQuery, useMutation, useQueryClient } from "react-query";
import { addService, deleteService, getAllServices, getServiceById, updateService } from "../../helper/fetchers/Services";
export const useGetAllServices = (params) => {
  const query = useQuery(["services",{params}],()=> getAllServices(params));
  return query;
};
export const useGetServicesById = (id) => {
  const query = useQuery(["service",id],()=> getServiceById(id));
  return query;
};
export const useAddService = (onSuccess,onError) => {
  const queryClient = useQueryClient();
  return useMutation(addService, {
    onSuccess: () => {
      queryClient.invalidateQueries("services");
      onSuccess()
    },
    onError: (error) => {
      onError()
    },
  });
};
export const useUpdateService = (onSuccess,onError, id) => {
  const queryClient = useQueryClient();
  return useMutation((data) => updateService(id, data), {
    onSuccess: () => {
      queryClient.invalidateQueries("services");
      onSuccess()
    },
    onError: (error) => {
      onError()
    },
  });
};
export const useDeleteService = (onSuccess,onError) => {
  const queryClient = useQueryClient();
  return useMutation(deleteService, {
    onSuccess: () => {
      queryClient.invalidateQueries("services");
      onSuccess()
    },
    onError: (error) => {
      onError()
    },
  });
};