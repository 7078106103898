import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  addComment,
  getCommentById,
  getMentions,
  likeComment,
  replyComment,
  unlikeComment,
} from "../services";

export const useGetCommentById = (taskId, commentId) => {
  return useQuery(["comments", taskId, commentId], () =>
    getCommentById(taskId, commentId)
  );
};
export const useGetMentions = (taskId) => {
  return useQuery(["comments","mentions", taskId], () => getMentions(taskId), {
    enabled: !!taskId,
  });
};

export const useAddComment = (onSuccess, onError, taskId) => {
  const queryClient = useQueryClient();
  return useMutation((data) => addComment(taskId, data), {
    onSuccess: () => {
      queryClient.invalidateQueries("comments");
      queryClient.invalidateQueries("task");
      onSuccess();
    },
    onError: (error) => {
      onError();
    },
  });
};
export const useReplyComment = (onSuccess, onError, commentId) => {
  const queryClient = useQueryClient();
  return useMutation((data) => replyComment(commentId, data), {
    onSuccess: () => {
      queryClient.invalidateQueries("comments");
      queryClient.invalidateQueries("task");
      onSuccess();
    },
    onError: (error) => {
      onError();
    },
  });
};
export const useLikeComment = (onSuccess, onError, taskId, commentId) => {
  const queryClient = useQueryClient();
  return useMutation(() => likeComment(taskId, commentId), {
    onSuccess: () => {
      queryClient.invalidateQueries("comments");
      queryClient.invalidateQueries("task");
      onSuccess();
    },
    onError: (error) => {
      onError();
    },
  });
};
export const useUnLikeComment = (onSuccess, onError, taskId, commentId) => {
  const queryClient = useQueryClient();
  return useMutation(() => unlikeComment(taskId, commentId), {
    onSuccess: () => {
      queryClient.invalidateQueries("comments");
      queryClient.invalidateQueries("task");
      onSuccess();
    },
    onError: (error) => {
      onError();
    },
  });
};
