import React from "react";
import CommentButton from "./components/CommentButton";
import CommentModal from "./components/CommentModal";
import { CommentProvider } from "./components/CommentCtx";
import { CheckRole } from "../../helper/utils";
import { roles } from "../../helper/fetchers/Tasks";
import Cookies from "js-cookie";
const Comments = ({ comments, task }) => {
  const assignToIds = task?.assignTo?.map((emp) => emp?._id);
  const isAssignToMe = assignToIds?.includes(Cookies.get("userId"));
  const isCreatedByMe = task?.createdBy?._id === Cookies.get("userId");
  // console.log("task is", task);
  const isAcceptedStatus = (num) => {
    return num == 1 || num == 2 || num == 3 || num == 4 || num == 5
      ? true
      : false;
  };
  const isShowComment = () => {
    if (isAcceptedStatus(task?.status)) {
      if (CheckRole(roles["admin"]) || CheckRole(roles["manager"])) {
        return true;
      } else if (isAssignToMe || isCreatedByMe) {
        return true;
      }
    }
    return false;
  };
  return (
    <CommentProvider comments={comments} task={task}>
      {isShowComment() ? (
        <>
          <CommentButton />
          <CommentModal />
        </>
      ) : null}
    </CommentProvider>
  );
};

export default Comments;
