import React, { useContext } from "react";
import "./index.css";

import AllClientsPieChart from "../../../../Components/System/Clients/AllClientsPieChart/AllClientsPieChart";
import AllClientstLineChart from "../../../../Components/System/Clients/AllClientstLineChart/AllClientstLineChart";
import { AllClientContext } from "../components/PageWrapperAllCLients";
import { Spinner } from "react-bootstrap";
const AllClientsChart = () => {
  const { data, isLoading } = useContext(AllClientContext);
  const content = (
    <div className=" p-4 d-flex gap-5 AllClientsChart  align-items-center flex-column">
      <div className="AllRequestsPieChartContainer w-100  d-flex align-items-center justify-content-center">
        <AllClientsPieChart total={data?.Total} />
      </div>
      <div className="AllRequestsLineChartContainer w-100  d-flex align-items-center justify-content-center">
        <AllClientstLineChart />
      </div>
    </div>
  );
  if (isLoading) {
    return (
      <div className="w-full h-full col-span-8 flex items-center justify-center">
        <Spinner className="text-[#EFAA20]" />
      </div>
    );
  }
  return content;
};
export default AllClientsChart;
