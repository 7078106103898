import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { upgradePassword } from "../../helper/fetchers/Users";
import Progress from "../Progress";
import UpdatePasswordForm from "../Forms/UpdatePasswordForm/UpdatePasswordForm";

function UpdatePassword({
  message,
  show,
  setSuccsesfull,
  setIsError,
  setMessage,
  handleSave,
  handleClose,
  id,
}) {
  return (
    <>
      <Modal
        centered
        contentClassName="bg-mainItems border py-3 !max-w-[800px] !border-[#EFAA20] !rounded-[20.27px] text-mainText"
        show={show}
        size="lg"
        onHide={handleClose}
      >
        <Modal.Header className=" py-2 flex !justify-start !rounded-[0px] border-none">
          <p className="text-[#EFAA20] text-[23px]  font-bold">
          تحديث كلمة السر
          </p>
        </Modal.Header>
        <Modal.Body className="w-full text-mainText">
          <UpdatePasswordForm
            id={id}
            onSuccess={() => {
              handleClose();
              setSuccsesfull(true);
              setMessage("تم تحديث كلمة السر بنجاح");
            }}
            oError={() => {
              setIsError(true);
            }}
          />
        </Modal.Body>
      </Modal>
    </>
  );
}

export default UpdatePassword;
