import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  deleteClient,
  getAllClients,
  getAllClientsInfo,
  getAllClientsInfoDesign,
  getAllClientsInfoSupervising,
  getAllClientsProjectsReports,
  getAllClientsReports,
  getClient,
  getClientPDFReportById,
  getClientReportById,
  getClientsStatisticsDesignReports,
  getClientsStatisticsReports,
  getClientsStatisticsSupervisorReports,
} from "../../helper/fetchers/Clients";

export const useGetAllClients = (params) => {
  return useQuery(["client", params], () => getAllClients(params));
};

// export const useGetExportAllClients = (onSuccess,onError) => {
//   const queryClient = useQueryClient();
//   return useMutation(getExportAllClients, {
//     onSuccess: () => {
//       queryClient.invalidateQueries("client");
//       onSuccess()
//     },
//     onError: (error) => {
//       onError()
//     },
//   });

// };
export const useGetClient = (id) => {
  return useQuery(["client", id], () => getClient(id), { enabled: !!id });
};

export const useDeleteClient = (onSuccess, onError) => {
  const queryClient = useQueryClient();
  return useMutation(deleteClient, {
    onSuccess: () => {
      queryClient.invalidateQueries("client");
      onSuccess();
    },
    onError: (error) => {
      onError();
    },
  });
};

/*******************ٌReports****************/

export const useGetAllClientsReports = (params) => {
  return useQuery(["client", "reports", {params}], () =>
    getAllClientsReports(params)
  );
};
export const useGetAllClientsInfo = (params) => {
  return useQuery(["client", "info", {params}], () =>
    getAllClientsInfo(params)
  );
};
export const useGetAllClientsInfoSupervising = (params) => {
  return useQuery(["client", "info","supervising", {params}], () =>
    getAllClientsInfoSupervising(params)
  );
};
export const useGetAllClientsInfoDesign = (params) => {
  return useQuery(["client", "info","design", {params}], () =>
    getAllClientsInfoDesign(params)
  );
};
export const useGetClientReport = (clientId) => {
  return useQuery(
    ["client", "reports", clientId],
    () => getClientReportById(clientId),
    {
      enabled: !!clientId,
    }
  );
};
export const useGetClientPDFReport = (clientId) => {
  return useQuery(
    ["client", "reports","pdf", clientId],
    () => getClientPDFReportById(clientId),
    {
      enabled: !!clientId,
    }
  );
};
export const useGetClientsProjectsReports = (data) => {
  return useQuery(["client", "reports", "projects"], () => getAllClientsProjectsReports(data) , {
    enabled: !!data
  });
};
export const useGetClientsStatisticsReports = () => {
  return useQuery(["client", "reports", "stats"], getClientsStatisticsReports);
};
export const useGetClientsStatisticsDesignReports = () => {
  return useQuery(["client", "reports", "stats" , "design"], getClientsStatisticsDesignReports);
};
export const useGetClientsStatisticsSupervisorReports = () => {
  return useQuery(["client", "reports", "stats" , "supervisor"], getClientsStatisticsSupervisorReports);
};
