import React, { useCallback, useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import "./index.css";

const AllUsersPieChart = ({ data }) => {
  const [total, setTotal] = useState();
  const setTotalData = useCallback(() => {
    if (data) {
      setTotal(data?.Total?.total);
    }
  }, [data]);
  useEffect(() => {
    setTotalData();
  }, [setTotalData]);

  const series = [parseInt(data?.Saudi?.total), parseInt(data?.Egypt?.total)];

  const options = {
    chart: {
      type: "donut",
    },
    labels: [`السعودية  `, `مصر   `],
    colors: ["#007F2E", "#dc3545"],
    stroke: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 300,
            height: 300,
          },
          legend: {
            show: false,
          },
        },
      },
    ],
    legend: {
      position: "left",
      offsetY: 50,
      height: 200,
      horizontalAlign: 'center', 
    },
    plotOptions: {
      pie: {
        donut: {
          size: '70%',
          labels: {
            show: true,
            total: {
              show: true,
              label: "كل المستخدمين",
              color: "var(--main-text)",
              formatter: (val) => {
                return val?.config?.series.reduce((acc, curr) => acc + curr, 0);
              },
            },
          },
          value: {
            show: true,
            fontSize: "16px",
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: 400,
            color: "var(--main-text)",
            offsetY: 16,
            formatter: function (val) {
              return 40;
            },
          },
        },
      },
    },
  };

  return (
    <div className="chart-wrap h-100">
      <div id="chart" className="h-100  overflow-x-hidden">
        {data && (
          <ReactApexChart
            options={options}
            series={series}
            type="donut"
            width={"450px"}
          />
        )}
      </div>
    </div>
  );
};

export default AllUsersPieChart;
