import React, { useCallback, useEffect, useMemo, useState } from "react";
import AllUserCategories from "../../../../Components/System/Users/AllUserCategories/AllUserCategories";

import AllUsersPieChart from "../../../../Components/System/Users/AllUsersChart/AllUsersPieChart";

import CountryPieChart from "../../../../Components/System/Users/CountryChart/CountryPieChart";
import { Link, useParams } from "react-router-dom";
import CountryColumnChart from "../../../../Components/System/Users/CountryChart/CountryColumnChart";
import CountryDeprtamentSlider from "../../../../Components/System/Users/CountryChart/CountryDeprtamentSlider";
import "./index.css";
import SearchCountryUsers from "../../../../Components/System/Users/SearchUsers/SearchCountryUsers";
import {
  getDepartmentStatistics,
  getStatistics,
} from "../../../../helper/fetchers/Users";
import { useGetDashboardDepartmentStatistics } from "../../../../hooks/fetchers/DashboardStatistics";
import Progress from "../../../../Components/Progress";
import CustomChart from "../../Main/components/charts/CustomChart";

const CountryChart = () => {
  const { Country } = useParams();
  const [countryBase, setCountryBase] = useState("Saudia");
  const [data, setData] = useState();
  const [departmentData, setDepartmentData] = useState();

  useEffect(() => {
    if (Country === "Saudia") {
      setCountryBase("Saudia");
    } else if (Country === "egypt") {
      setCountryBase("egypt");
    }
  }, [Country]);

  const getStats = useCallback(async () => {
    const res = await getStatistics();
    if (res?.status === 200) {
      setData(res?.data);
    }
    const departmentRes = await getDepartmentStatistics();
    if (departmentRes?.status === 200) {
      setDepartmentData(departmentRes?.data);
    }
  }, []);

  useEffect(() => {
    getStats();
  }, [getStats]);

  const {
    data: dashboardStatistics,
    isLoading: dashboardStatisticsLoading,
    isError: dashboardStatisticsError,
  } = useGetDashboardDepartmentStatistics();

  return (
    <div className="h-full grid grid-cols-12 gap-2">
      <div className="col-span-12">
        <div className="country-Chart px-2 py-5">
          <div className="d-flex justify-content-center flex-column align-items-center">
            <div className="d-flex  mx-auto gap-4 justify-center">
              <p className=" text-center text-xl text-mainText mb-4">
                {countryBase === "Saudia" ? "السعودية" : "مصر"}
              </p>
            </div>
            {!dashboardStatisticsError && !dashboardStatisticsLoading ? (
              <CountryPieChart
                data={
                  countryBase === "Saudia"
                    ? dashboardStatistics?.Saudi
                    : dashboardStatistics?.Egypt
                }
              />
            ) : null}
          </div>
          <fieldset className="All-users-columnChart-container p-3 m-auto">
            <legend className="text-mainText text-center">
              كل المستخدمين في {countryBase === "Saudia" ? "السعودية" : "مصر"}
            </legend>

            <div className="rounded-[16.799px] bg-mainNavy shadow p-1.5 h-full w-full">
              {dashboardStatisticsError ? (
                <div className="h-56 grid place-content-center">
                  <p className="text-red-500 text-xl"> حدث خطأ ما </p>
                </div>
              ) : null}
              {dashboardStatisticsLoading ? (
                <div className="h-56 grid place-content-center">
                  <Progress />
                </div>
              ) : null}
              {!dashboardStatisticsError && !dashboardStatisticsLoading ? (
                <CustomChart
                  data={
                    Country === "Saudia"
                      ? dashboardStatistics?.Saudi?.departments
                      : dashboardStatistics?.Egypt?.departments
                  }
                  chartTitle={"احصائيات الموظفين"}
                  title={"عدد الموظفين"}
                  rowProps={{
                    className: "h-12 text-white",
                  }}
                  showChart={false}
                  mainColTitle = "الاسم"
                />
              ) : null}
            </div>

            {/* <div className="county-users-columnChart  d-flex   align-items-center flex-column">
              <CountryColumnChart data={departmentData} country={countryBase} />
              <CountryDeprtamentSlider
                data={departmentData}
                country={countryBase}
              />
            </div> */}
          </fieldset>
        </div>
      </div>
    </div>
  );
};

export default CountryChart;
