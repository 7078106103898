import { useParams } from "react-router-dom";
import style from "./ShowExpensesDetials.module.css";
import { Button, Form, Modal } from "react-bootstrap";
import SaveButton from "../../../SaveButton";
import { useState } from "react";
import EditExpenses from "../EditExpenses/EditExpenses";
import DeleteModal from "../../../../Pages/System/Settings/RemoveModal";
import DownloadButton from "../../../Buttons/DownloadButton";
import { convertDateFormat } from "../../../../helper/utils";
import { useDeleteBatche } from "../../../../hooks/fetchers/Batches";
import { FaFileDownload } from "react-icons/fa";
import { PdfIcon } from "../../../../utiltis/Icons";
import { useDeleteExpensesItem, useGetExpensesItem } from "../../../../hooks/fetchers/Expenses-items";
import useSuccessfullModal from "../../../Modals/hooks/useSuccessfullModal";
import SuccessfullModal from "../../../Modals/SuccessfullModal";

const ShowExpensesDetials = ({ setOpenDisplayDetails , id}) => {
  const {
    handleShowSuccess,
    handleShowError,
    handleCloseSuccess,
    handleCloseError,
    showSuccess,
    showError,
  } = useSuccessfullModal();

  const {data} = useGetExpensesItem(id)
  // console.log("data",data);
  const {mutate:deleteMutation , isLoading} = useDeleteExpensesItem(handleShowSuccess,handleShowError)
  const { ExpensesType } = useParams();
  const [deletePoper, setDeletPoper] = useState(false);
  const [confirmDeletePoper, setConfirmDeletPoper] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editExpenses, setEditExpenses] = useState(false);
  const { mutate: mutateDelete } = useDeleteBatche();
  const handleDeleteRequest = () => {
    // after deleting revenues succefully
    setDeletPoper(false);
    setConfirmDeletPoper(true);
  };

  // const handleDelete = async () => {
  //   setLoading(true);
  //   const res = await deleteBatche(data._id);

  //   try {
  //     if (res?.status === 204) {
  //       toast.success("تم حذف الصنف بنجاح");
  //       setOpenDisplayDetails(false);
  //       setConfirmDeletPoper(false);
  //       setDeletPoper(false);
  //       getData();
  //       setLoading(false);
  //     }
  //   } catch (error) {
  //     toast.error(error?.response?.data?.message);
  //     setLoading(false);
  //   }
  // };
  return (
    <div className="ShowExpensesDetials  ">
      {/* {confirmDeletePoper && (
        <Modal
          className="submitSystemPoper"
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          onHide={() => setConfirmDeletPoper(false)}
          show={confirmDeletePoper}
        >
          <Modal.Body>
            <div className="d-flex justify-content-center w-100">
              
              <Image
                src={process.env.PUBLIC_URL + "/correct.gif"}
                width={120}
                height={120}
                className=""
                color="#E1B67C"
              />
            </div>

            <div className="d-flex w-75 flex-column mx-auto mt-3 justify-content-center align-items-center ">
              {
                <p className="text-mainText my-3" style={{ fontSize: "30px" }}>
                  
                  تم الحذف بنجاح
                </p>
              }
              <Button
                onClick={() => {
                  setConfirmDeletPoper(false);
                }}
                className="sumbmitAddUpdateUser"
              >
                حفظ
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      )}

      {deletePoper && (
        <Modal
          className="submitSystemPoper"
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          onHide={() => setDeletPoper(false)}
          show={deletePoper}
        >
          <Modal.Body className="d-flex align-items-center">
            <div className="d-flex w-75 flex-column mx-auto mt-3 justify-content-center align-items-center ">
              {
                <p className="text-mainText" style={{ fontSize: "30px" }}>
                  
                  هل انت متاكد من الحذف
                </p>
              }
              <div className="d-flex justify-content-center mt-3 gap-3">
                <Button
                  onClick={() => {
                    setDeletPoper(false);
                    handleDeleteRequest();
                  }}
                  className="Delete-button"
                >
                  نعم
                </Button>

                <Button
                  onClick={() => {
                    setDeletPoper(false);
                  }}
                  className="No-Delete"
                >
                  لا
                </Button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )} */}
      <DeleteModal
        title={"التاكيد"}
        show={deletePoper}
        handleClose={handleDeleteRequest}
        onSave={() => {
          // handleDelete();
          deleteMutation(data._id)
        }}
        stillOpen={true}
      />
      <SuccessfullModal
        handleClose={() => {
          handleCloseSuccess();
          setOpenDisplayDetails(false);
          setConfirmDeletPoper(false);
          setDeletPoper(false);
        }}
        message={"تم الحذف بنجاح"}
        show={showSuccess}
      />
      <SuccessfullModal
        handleClose={handleCloseError}
        message={"حدث خطأ ما"}
        show={showError}
        status="error"
      />


      {editExpenses && (
        <EditExpenses
          editExpenses={editExpenses}
          setEditExpenses={setEditExpenses}
          selectedItem={data}
        />
      )}

      <div className={`${style.reportName} p-3`}>
        <div className="row">
          <div className="col-md-8 mb-2">
            {ExpensesType === "ExpensesReports" ? (
              <p className="text-mainText">
                اسم التقرير : <span className="text-mainCustomGray">BSA</span>
              </p>
            ) : (
              <p className="text-mainText">
                اسم الصنف :{" "}
                <span className="text-mainCustomGray">{data?.name}</span>
              </p>
            )}
          </div>

          <div className="col-md-4 mb-2">
            <div className="d-flex gap-3 justify-content-start">
              <DownloadButton>تصدير CSV </DownloadButton>
              <DownloadButton>تصدير Excel </DownloadButton>
            </div>
          </div>
          <div className="col-md-9"></div>
          <div className="col-md-3">
            <div className="d-flex gap-2  mt-3 justify-content-start   ">
              <img
                className={`pointer ${style.actionIcon}`}
                onClick={() => {
                  setDeletPoper(true);
                }}
                src={process.env.PUBLIC_URL + "/icons/delete.png"}
                alt=""
              />

              <img
                className={`pointer ${style.actionIcon}`}
                onClick={() => {
                  setEditExpenses(true);
                }}
                src={process.env.PUBLIC_URL + "/icons/edit.svg"}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <fieldset className={`${style.discription} w-90 mx-auto p-3`}>
        <legend className="text-center w-30 text-mainText">الوصف</legend>
        <Form.Label className="text-mainText">الوصف : </Form.Label>
        <div className="h-36 bg-mainNavy  text-mainCustomGray p-2 rounded-[7px]">
          {" "}
          {data?.description}
        </div>
        <div className="row mt-2">
          <div className="col-md-6">
            <p className="text-mainText flex items-center gap-2">
              {ExpensesType === "ExpensesReports"
                ? " تاريخ الاستحقاق :"
                : " تاريخ الانشاء :"}

              <span className="main-text">
                {convertDateFormat(data?.dateCreated)}
              </span>
            </p>
          </div>
          <div className="col-md-6">
            <p className="text-mainText">
              {ExpensesType === "ExpensesReports"
                ? "  المبلغ :"
                : "  كود الصنف : "}

              <span className="main-text">{data?.codeBatche}</span>
            </p>
          </div>
        </div>
      </fieldset>
      <fieldset className={`border !border-[#EFAA2080]  w-90 mx-auto mt-3 p-3`}>
        <legend className="text-center w-30">الملف المرفق</legend>

        {/* <FIlePlaceholder
          title={ExpensesType === "ExpensesReports" ? "المصروفات" : "الصنف"}
        /> */}

        {data?.image?.length > 0 ? (
          <>
            {/* <div className="flex gap-3">
              <PreviewFiles data={data?.image} />
            </div> */}
            <div className="relative w-full flex gap-6 overflow-y-hidden overflow-x-auto scrollbar-thin scrollbar-track-transparent scrollbar-thumb-[#EFAA20] pb-14">
              <ShowFiles files={data?.image} />
            </div>
          </>
        ) : (
          <div className="flex justify-center gap-3">
            <p className="text-2xl text-[#D59921]"> لا يوجد ملفات</p>
          </div>
        )}
      </fieldset>

      <div
        onClick={() => {
          setOpenDisplayDetails(false);
        }}
        className=" mt-4 w-[30]"
      >
        <SaveButton />
      </div>
    </div>
  );
};
const ShowFiles = ({ files }) => {
  const { show, handleClose, handleShow } = useOpenModal();
  const [content, setContent] = useState(null);

  // console.log("files: ", files);
  return (
    <>
      <Example show={show} handleClose={handleClose} content={content} />
      {files?.map((file, index) => {
        const fileType = getType(file);
        switch (fileType) {
          case "jpg":
          case "jpeg":
          case "png":
          case "gif":
            return (
              <div
                key={index}
                onClick={() => {
                  setContent(
                    <>
                      <Button
                        className="bg-mainItems"
                        download={true}
                        href={file}
                      >
                        <FaFileDownload fontSize={25} color="#EFAA20" />
                      </Button>
                      <img
                        className="w-96  rounded-xl border"
                        src={file}
                        alt=""
                      />
                    </>
                  );
                  handleShow();
                }}
                className="shrink-0"
              >
                <img
                  className="w-24 h-24 rounded-xl border"
                  src={file}
                  alt=""
                />
              </div>
            );
          case "pdf":
            return (
              <div
                key={index}
                onClick={() => {
                  setContent(
                    <>
                      <Button
                        className="bg-mainItems"
                        download={true}
                        href={file}
                      >
                        <FaFileDownload fontSize={25} color="#EFAA20" />
                      </Button>
                      <embed
                        src={file}
                        type="application/pdf"
                        width="400"
                        height="600"
                        className="w-96 relative rounded-xl border"
                      />
                    </>
                  );
                  handleShow();
                }}
                className="shrink-0 flex justify-center items-center w-24 h-24 rounded-xl border"
              >
                <PdfIcon />
              </div>
            );
          case "zip":
            return (
              <div
                key={index}
                onClick={() => {
                  setContent(
                    <>
                      <Button
                        className="bg-mainItems"
                        download={true}
                        href={file}
                      >
                        <FaFileDownload fontSize={25} color="#EFAA20" />
                      </Button>
                      <img
                        className="w-96   rounded-xl border"
                        src="https://p7.hiclipart.com/preview/258/300/47/computer-icons-zip-truetype-icon-png-file-zip.jpg"
                        alt=""
                      />
                    </>
                  );
                  handleShow();
                }}
                className="shrink-0"
              >
                <img
                  className="w-24 h-24 rounded-xl border"
                  src="https://p7.hiclipart.com/preview/258/300/47/computer-icons-zip-truetype-icon-png-file-zip.jpg"
                  alt=""
                />
              </div>
            );
          case "xlsx":
            return (
              <div
                key={index}
                onClick={() => {
                  setContent(
                    <>
                      <Button
                        className="bg-mainItems"
                        download={true}
                        href={file}
                      >
                        <FaFileDownload fontSize={25} color="#EFAA20" />
                      </Button>
                      <img
                        className="w-96   rounded-xl border"
                        src="https://logodownload.org/wp-content/uploads/2020/04/excel-logo-0.png"
                        alt=""
                      />
                    </>
                  );
                  handleShow();
                }}
                className="shrink-0"
              >
                <img
                  className="w-24 h-24 rounded-xl border"
                  src="https://logodownload.org/wp-content/uploads/2020/04/excel-logo-0.png"
                  alt=""
                />
              </div>
            );
          case "rar":
            return (
              <div
                key={index}
                onClick={() => {
                  setContent(
                    <>
                      <Button
                        className="bg-mainItems"
                        download={true}
                        href={file}
                      >
                        <FaFileDownload fontSize={25} color="#EFAA20" />
                      </Button>
                      <img
                        className="w-96  rounded-xl border"
                        src="https://cdn-icons-png.freepik.com/512/9158/9158683.png"
                        alt=""
                      />
                    </>
                  );
                  handleShow();
                }}
                className="shrink-0"
              >
                <img
                  className="w-24 h-24 rounded-xl border"
                  src="https://cdn-icons-png.freepik.com/512/9158/9158683.png"
                  alt=""
                />
              </div>
            );
          default:
            return null;
        }
      })}
    </>
  );
};

function Example({ show, handleClose, content }) {
  return (
    <Modal
      size="lg"
      centered
      show={show}
      onHide={handleClose}
      contentClassName="p-0 bg-mainNavy w-fit mx-auto"
      className="bg-black/50"
    >
      <Modal.Body className="flex flex-col items-center">{content}</Modal.Body>
    </Modal>
  );
}

const getType = (fileName) => {
  const fileExtension = fileName.split(".").pop().toLowerCase();
  return fileExtension;
};

const useOpenModal = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return {
    show,
    handleClose,
    handleShow,
  };
};
export default ShowExpensesDetials;
