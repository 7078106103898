const genderOptions = [
    {
      value: 1,
      label: "ذكر",
    },
    {
      value: 2,
      label: "انثي",
    },
  ];
  const countryOption = [
    {
      value: "السعودية",
      label: "السعودية",
    },
    {
      value: "مصر",
      label: "مصر",
    },
  ];
  const departmentOption = [
    {
      value: "مدني",
      label: "مدني",
    },
    {
      value: " معماري",
      label: "معماري",
    },
    {
      value: "كهربا",
      label: "كهربا",
    },
    {
      value: "ميكانيكا",
      label: "ميكانيكا",
    },
    {
      value: "برمجة",
      label: "برمجة",
    },
    {
      value: "أدارى",
      label: "أدارى",
    },
  ];
  const roleOption = [
    {
      value: "موظف",
      label: "موظف",
    },
    {
      value: "مدير",
      label: "مدير",
    },
    {
      value: "مدقق",
      label: "مدقق",
    },
    {
      value: "مدير المكتب",
      label: "مدير المكتب",
    },
    {
      value: "مدير قسم",
      label: "مدير قسم",
    },
    {
      value: "محاسب",
      label: "محاسب",
    },
    {
      value: "مواردبشرية",
      label: "مواردبشرية",
    },
    {
      value: "أدارى",
      label: "أدارى",
    },
  ];

export {
    genderOptions,
    countryOption,
    departmentOption,
    roleOption
}