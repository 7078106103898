import React from "react";
import { Accordion } from "react-bootstrap";
import { Link } from "react-router-dom";
import { MdKeyboardArrowDown } from "react-icons/md";
import { NavItem } from "./NavItem";

export const CustomNav = ({ title, path, items, onClick }) => {
  const renderItems = (items) => {
    return items?.map(({ title, path, onClick, subItems }, index) => {
      if (subItems && subItems.length > 0) {
        return (
          <Accordion key={index}>
            <CustomNav title={title} path={path} items={subItems} onClick={onClick} />
          </Accordion>
        );
      } else {
        return <NavItem title={title} onClick={onClick} key={index} />;
      }
    });
  };

  return (
    <Accordion.Item eventKey={title + path} className="custom-accordion-item">
      {path ? (
        <div role="button" onClick={onClick}>
        <Link to={path}>
          <Accordion.Header className="border-2 border-mainBorder rounded-md rounded-b-lg">
            {title}
            <MdKeyboardArrowDown size={20} />
          </Accordion.Header>
        </Link>
        </div>
      ) : (
        <div role="button" onClick={onClick}>
          <Accordion.Header className="border-2 border-mainBorder rounded-md rounded-b-lg">
            {title}
            <MdKeyboardArrowDown size={20} />
          </Accordion.Header>
        </div>
      )}

      <Accordion.Body className="my-2">
        <div className="tabs d-flex justify-content-center align-items-center flex-column">
          {renderItems(items)}
        </div>
      </Accordion.Body>
    </Accordion.Item>
  );
};
