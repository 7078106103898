import { createContext, useContext } from "react";
import useModal from "../hooks/useModal";

const CommentCtx = createContext(null);

export const CommentProvider = ({ children, comments = [] , task = {}}) => {
  const modalActions = useModal();
  
  return (
    <CommentCtx.Provider value={{ ...modalActions ,comments,task}}>
      {children}
    </CommentCtx.Provider>
  );
};

export const useCommentCtx = () => useContext(CommentCtx);
