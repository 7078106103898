import { useQuery, useMutation, useQueryClient } from "react-query";
import {
  getAllExpensesItems,
  addExpensesItem,
  updateExpensesItem,
  deleteExpensesItem,
  getExpensesItem,
} from "../../helper/fetchers/Expenses-Items";

export const useGetAllExpensesItems = (search) => {
  const query = useQuery(["batches", "expense", search], () =>
    getAllExpensesItems(search)
  );
  return query;
};
export const useGetExpensesItem = (id) => {
  const query = useQuery(
    ["batches", "expense", id],
    () => getExpensesItem(id),
    {
      enabled: !!id,
    }
  );
  return query;
};

export const useAddExpensesItem = (onSuccess, onError) => {
  const queryClient = useQueryClient();
  return useMutation(addExpensesItem, {
    onSuccess: () => {
      queryClient.invalidateQueries("batches");
      onSuccess();
    },
    onError: (error) => {
      onError();
    },
  });
};
export const useUpdateExpensesItem = (onSuccess, onError, id) => {
  const queryClient = useQueryClient();
  return useMutation((data) => updateExpensesItem(id, data), {
    onSuccess: () => {
      queryClient.invalidateQueries("batches");
      onSuccess();
    },
    onError: (error) => {
      onError();
    },
  });
};
export const useDeleteExpensesItem = (onSuccess, onError) => {
  const queryClient = useQueryClient();
  return useMutation(deleteExpensesItem, {
    onSuccess: () => {
      onSuccess();
      queryClient.invalidateQueries("batches");
    },
    onError: (error) => {
      onError();
      // Handle error
    },
  });
};
