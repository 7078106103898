import React from "react";

const ChatIcon = () => {
  return (
    <svg
      width="41"
      height="41"
      viewBox="0 0 41 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M36.8993 6.96973H17.2193C16.7683 6.96973 16.3993 7.33873 16.3993 7.78973V13.5297H4.0993C3.6483 13.5297 3.2793 13.8987 3.2793 14.3497V27.4697C3.2793 27.9207 3.6483 28.2897 4.0993 28.2897H7.3793V33.2097C7.3793 34.4397 8.5683 33.9887 8.7733 33.7837L14.2673 28.2897H23.7793C24.2303 28.2897 24.5993 27.9207 24.5993 27.4697V21.7297H26.7723L32.2253 27.2237C32.7583 27.7567 33.6193 27.3467 33.6193 26.6497V21.7297H36.8993C37.3503 21.7297 37.7193 21.3607 37.7193 20.9097V7.78973C37.7193 7.33873 37.3503 6.96973 36.8993 6.96973ZM22.9593 26.6497H13.9393C13.7343 26.6497 13.5293 26.7317 13.3653 26.8957L9.0193 31.2417V27.4697C9.0193 27.0187 8.6503 26.6497 8.1993 26.6497H4.9193V15.1697H16.3993V20.9097C16.3993 21.3607 16.7683 21.7297 17.2193 21.7297H22.9593V26.6497ZM36.0793 20.0897H32.7993C32.3483 20.0897 31.9793 20.4587 31.9793 20.9097V24.6817L27.6743 20.3357C27.5103 20.1717 27.3053 20.0897 27.1003 20.0897H18.0393V8.60973H36.0793V20.0897Z"
        fill="var(--main-text)"
      />
    </svg>
  );
};

export default ChatIcon;
