import { useEffect, useState } from "react";
import { MenuPlansIcon } from "../../../../utiltis/Icons";
import { toggleWidth, resetWidth } from "../slices/fullWidthSlice";
import { useDispatch } from "react-redux";
import ProjectsTable from "../../Plans/Projects/components/ProjectsTable";
import { useProjectContext } from "../../PlanModel/context/ProjectContext";
import { SearchComponent } from "../../../../Components/SearchComponent/SearchComponent";
function ProjectsPage() {
  const { setFilterPlansParams ,selectedTab} = useProjectContext();
  const [search, setSearch] = useState("");
  const handleSearch = (value) => {
    setSearch(value);
    // console.log(e.target.value);
    setFilterPlansParams({ search: value });
  };
  useEffect(() => {
    dispatch(resetWidth());
    return () => dispatch(resetWidth());
  }, []);
  const dispatch = useDispatch();

  return (
    <div className="bg-mainItems">
      <div className="flex-1">
        <div className="grid grid-cols-3 py-2">
          <div className="flex items-center gap-3 ">
            <MenuIconButton onClick={() => dispatch(toggleWidth())} />
            <p className="text-[#EFAA20] font-semibold text-xl">{selectedTab}</p>
          </div>
          <div className="col-span-2">
            <SearchComponent
              // className="m-auto w-[95%]"
              background={"#2B2B40"}
              border="border !border-[#E4A11B]"
              value={search}
              handleChange={handleSearch}
            />
          </div>
        </div>
        <ProjectsTable />
      </div>
    </div>
  );
}

export default ProjectsPage;
const MenuIconButton = ({ onClick }) => {
  return (
    <button onClick={onClick} aria-label="toggle" className="p-2">
      <MenuPlansIcon />
    </button>
  );
};

