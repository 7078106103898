import React from "react";
import SearchCountryUsers from "../../../../Components/System/Users/SearchUsers/SearchCountryUsers";
import { Outlet } from "react-router-dom";

const MainContainer = () => {
  return (
    <div className="h-full grid grid-cols-3 gap-2">
      <div className="hidden xl:flex ">
        <SearchCountryUsers />
      </div>
      <div className="xl:col-span-2 col-span-3">
        <Outlet />
      </div>
    </div>
  );
};

export default MainContainer;
