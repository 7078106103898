import { Fragment, useEffect, useRef, useState } from "react";
import {
  projectTypeEnum
} from "../../../../helper/utils";
import { toBlob } from "html-to-image";
import { Document, Page, Image, StyleSheet } from "@react-pdf/renderer";
import moment from "moment";
import SectionElement from "../../../../Components/Preview/components/SectionElement";
import PdfPreview from "../../../../Components/Preview/CustomPdfPreview";
import TableElement from "../../../../Components/Preview/components/Table";
import PdfDocument from "./PdfDocument";
const columnsProjectsInfor = [
  {
    name: "القيمة",
  },
  {
    name: "الدفعات",
  },
  {
    name: "نوع الدفعة",
  },
  {
    name: "تاريخ الاستحقاق",
  },

  {
    name: "الحالة",
  },
];
const PrintProjectFile = ({ show, onHide, data }) => {
  const refs = useRef([]);
  const [imageUrls, setImageUrls] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (show) {
      const captureImages = async () => {
        setLoading(true);
        const blobs = await Promise.all(
          refs.current.map((ref) => toBlob(ref, { pixelRatio: 2 }))
        );
        const urls = blobs.map((blob) => URL.createObjectURL(blob));
        setImageUrls(urls);
        setLoading(false);
      };

      captureImages();
    }
  }, [show]);
  return (
    <>
      <PdfPreview show={show} onHide={onHide}>
        {imageUrls.length > 0 && (
          <PdfPreview.DownloadButton
            document={<PdfDocument imageUrls={imageUrls} />}
            loading={loading}
          />
        )}
        {data?.map((item, index) => (
          <Fragment key={index}>
            <div
              ref={(el) => (refs.current[index] = el)}
              className="rounded-t w-full"
            >
              <PdfPreview.Header
                createdAt={`تاريخ الانشاء : ${moment(item?.createdAt).format("YYYY-MM-DD")}`}
                titleAr={"المشاريع"}
                titleEn={"Projects"}
              />

              <PdfPreview.Body>
                <div className="flex justify-between items-center">
                  <PdfPreview.SectionTitle title={"معلومات الطلب"} />
                  <SectionElement
                    title="الحالة"
                    disc={
                      item?.projectStatus === 1
                        ? "قيد التنفيذ"
                        : item?.projectStatus === 0
                        ? "مشروع معلق"
                        : "منتهية"
                    }
                    className="w-1/2 gap-0 px-2"
                  />
                </div>

                <PdfPreview.SectionWrapper>
                  <SectionElement
                    title="نوع المشروع"
                    disc={projectTypeEnum[item?.projectType]}
                  />
                  <SectionElement title="رقم الطلب" disc={item?.orderNumber} />
                  <SectionElement title="اسم المالك" disc={item?.ownerName} />
                  <SectionElement
                    title="موقع المشروع"
                    disc={item?.buildingLocation}
                  />
                </PdfPreview.SectionWrapper>

                <div className="">
                  <PdfPreview.SectionTitle title={"معلومات المشروع"} />
                  <PdfPreview.SectionWrapper>
                    <SectionElement
                      title="اسم المشروع"
                      disc={item?.projectName}
                      className="col-span-2 gap-[7px]"
                    />
                    <SectionElement
                      title="وصف المشروع"
                      disc={item?.notesProject || "لا يوجد وصف"}
                      className="col-span-2 gap-[7px]"
                    />
                  </PdfPreview.SectionWrapper>
                  <div className="py-4 px-2">
                    <TableElement
                      columns={columnsProjectsInfor}
                      data={item?.invoices}
                    >
                      {item?.invoices?.map(
                        (
                          { _id, financialId, invoiceFile, projectId },
                          index
                        ) => (
                          <TableElement.TableRow className={`my-2`} key={index}>
                            <TableElement.TableCell>
                              {projectId?.totalAmount}
                            </TableElement.TableCell>
                            <TableElement.TableCell>
                              {projectId?.totalAmount}
                            </TableElement.TableCell>
                            <TableElement.TableCell>
                              {projectId?.totalAmount}
                            </TableElement.TableCell>
                            <TableElement.TableCell>
                              {projectId?.totalAmount}
                            </TableElement.TableCell>
                            <TableElement.TableCell>
                              {projectId?.totalAmount}
                            </TableElement.TableCell>
                            <TableElement.TableCell>
                              {projectId?.totalAmount}
                            </TableElement.TableCell>
                          </TableElement.TableRow>
                        )
                      )}
                    </TableElement>
                  </div>
                </div>

                <div className="">
                  <PdfPreview.SectionTitle title={"معلومات الدفع"} />
                  <PdfPreview.SectionWrapper>
                    <SectionElement
                      title="المبلغ الاجمالى"
                      disc={`${item?.totalAmount} ريال`}
                    />
                    <SectionElement
                      title="المبلغ المتبقى"
                      disc={`${item?.remainingAmount} ريال`}
                    />
                    <SectionElement
                      title="قيمة الخصم"
                      disc={`${item?.discount} %`}
                    />
                    <SectionElement
                      title="عدد الدفعات"
                      disc={`${item?.batchCount} دفعة`}
                    />
                    <SectionElement
                      title="عدد الدفعات المتبقى"
                      disc={`${item?.remainingBatch} دفعة`}
                    />
                  </PdfPreview.SectionWrapper>
                </div>

                <div className="">
                  <PdfPreview.SectionTitle title={"اوراق المشروع"} />
                  <PdfPreview.SectionWrapper>
                    <p className="text-base">
                      {item?.paperProject?.length
                        ? "يوجد مرفقات"
                        : "لا يوجد مرفقات"}
                    </p>
                  </PdfPreview.SectionWrapper>
                </div>
                <div className="">
                  <PdfPreview.SectionTitle title={"المرفقات"} />
                  <PdfPreview.SectionWrapper>
                    <p className="text-base">
                      {item?.ProjectOffers?.length
                        ? "يوجد مرفقات"
                        : "لا يوجد مرفقات"}
                    </p>
                  </PdfPreview.SectionWrapper>
                </div>
              </PdfPreview.Body>
            </div>
          </Fragment>
        ))}
      </PdfPreview>
    </>
  );
};

// const Item = ({ title = " ", disc = "", className = "" }) => {
//   return (
//     <>
//       <div className={twMerge("flex items-center gap-3", className)}>
//         <p className="w-20">{title}</p>
//         <div className="bg-[#F9EBD5] py-2 px-4 w-full min-h-[2rem]">
//           <p>{disc}</p>
//         </div>
//       </div>
//     </>
//   );
// };




export default PrintProjectFile;
