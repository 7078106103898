import { useDeleteSubService } from "../../../../../hooks/fetchers/SubService";
import { useShowMenu } from "../hooks/useShowMenu";
import { UpdateSubServiceModal } from "./Modals/UpdateSubServiceModal";
import { OptionsButton } from "../../OptionsButton";
import useSuccessfullModal from "../../../../../Components/Modals/hooks/useSuccessfullModal";
import SuccessfullModal from "../../../../../Components/Modals/SuccessfullModal";
import LoadingPage from "../../../../../Components/feedback/LoadingPage";
import { useState } from "react";
export const SubServicesList = ({ service, serviceId }) => {
  const { show, handleShow, handleClose } = useShowMenu();
  const [selected,setSelected] = useState()
  const {
    handleShowSuccess,
    handleShowError,
    handleCloseSuccess,
    handleCloseError,
    showSuccess,
    showError,
  } = useSuccessfullModal();
  const { mutate: deleteMutation, isLoading: loadingMutation } =
    useDeleteSubService(handleShowSuccess, handleShowError);

  return (
    <div className="max-h-[700px] h-full overflow-y-auto scrollbar-thin scrollbar-track-mainNavy scrollbar-thumb-[#E4A11B]">
      {loadingMutation ? <LoadingPage /> : null}
      {service?.length > 0 ? (
        service?.map(({ _id, name, serviceId }) => (
          <div
            className={`flex w-full justify-between items-center px-2 text-[#ffffff80] border hover:!border-[#EFAA20] text-base ${" !border-transparent"}`}
            key={_id}
          >
            <button
              //  onClick={() =>
              //   setActive(index)
              // }
              className="w-full"
            >
              <p className="w-full text-mainText text-right my-3">{name}</p>
            </button>

            <OptionsButton
              id={_id}
              onUpdate={handleShow}
              onDelete={() => deleteMutation([serviceId, _id])}
              getId={setSelected}
            />
          </div>
        ))
      ) : (
        <p className="text-mainText text-2xl text-center">
          {"لا يوجد بيانات لعرضها"}
        </p>
      )}
      {show ? (
        <UpdateSubServiceModal
          serviceId={serviceId}
          subId={selected}
          show={show}
          handleClose={handleClose}
        />
      ) : null}
      <SuccessfullModal
        show={showSuccess}
        handleClose={() => {
          handleCloseSuccess();
          handleClose();
        }}
        message="تم الحذف بنجاح "
      />
      <SuccessfullModal
        show={showError}
        handleClose={handleCloseError}
        message="حدث خطأ ما"
        status="error"
      />
    </div>
  );
};
