import { useState } from "react";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { FaFileDownload } from "react-icons/fa";
import { PdfIcon } from "../utiltis/Icons";

const ShowFiles = ({ files }) => {
  const useOpenModal = () => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return {
      show,
      handleClose,
      handleShow,
    };
  };
  function Example({ show, handleClose, content }) {
    return (
      <Modal
        size="lg"
        centered
        show={show}
        onHide={handleClose}
        contentClassName="p-0 bg-mainNavy w-fit mx-auto"
        className="bg-black/50"
      >
        <Modal.Body className="flex flex-col items-center ">
          {content}
        </Modal.Body>
      </Modal>
    );
  }
  const getType = (fileName) => {
    const fileExtension = fileName.split(".").pop().toLowerCase();
    return fileExtension;
  };

  const { show, handleClose, handleShow } = useOpenModal();
  const [content, setContent] = useState(null);
  return (
    <>
      <Example show={show} handleClose={handleClose} content={content} />
      {files && files.length ? files?.map((file, index) => {
        const fileType = getType(file);
        switch (fileType) {
          case "jpg":
          case "jpeg":
          case "png":
          case "gif":
            return (
              <div
                key={index}
                onClick={() => {
                  setContent(
                    <dev className="flex items-center justify-center flex-col gap-2">
                      <Button
                        className="bg-mainItems"
                        download={true}
                        href={file}
                      >
                        <FaFileDownload fontSize={25} color="#EFAA20" />
                      </Button>
                      <img
                        className="w-96  rounded-xl border"
                        src={file}
                        alt=""
                      />
                    </dev>
                  );
                  handleShow();
                }}
                className="shrink-0"
              >
                <img
                  className="w-24 h-24 rounded-xl border"
                  src={file}
                  alt=""
                />
              </div>
            );
          case "pdf":
            return (
              <div
                key={index}
                onClick={() => {
                  setContent(
                    <dev className="flex items-center justify-center flex-col gap-2">
                      <Button
                        className="bg-mainItems"
                        download={true}
                        href={file}
                      >
                        <FaFileDownload fontSize={25} color="#EFAA20" />
                      </Button>
                      <embed
                        src={file}
                        type="application/pdf"
                        width="400"
                        height="600"
                        className="w-96 relative rounded-xl border"
                      />
                    </dev>
                  );
                  handleShow();
                }}
                className="flex shrink-0 justify-center items-center w-24 h-24 rounded-xl border"
              >
                <PdfIcon />
              </div>
            );
          case "zip":
            return (
              <div
                key={index}
                onClick={() => {
                  setContent(
                    <dev className="flex items-center justify-center flex-col gap-2">
                      <Button
                        className="bg-mainItems"
                        download={true}
                        href={file}
                      >
                        <FaFileDownload fontSize={25} color="#EFAA20" />
                      </Button>
                      <img
                        className="w-96   rounded-xl border"
                        src="https://p7.hiclipart.com/preview/258/300/47/computer-icons-zip-truetype-icon-png-file-zip.jpg"
                        alt=""
                      />
                    </dev>
                  );
                  handleShow();
                }}
                className="shrink-0"
              >
                <img
                  className="w-24 h-24 rounded-xl border"
                  src="https://p7.hiclipart.com/preview/258/300/47/computer-icons-zip-truetype-icon-png-file-zip.jpg"
                  alt=""
                />
              </div>
            );
          case "xlsx":
            return (
              <div
                key={index}
                onClick={() => {
                  setContent(
                    <dev className="flex items-center justify-center flex-col gap-2">
                      <Button
                        className="bg-mainItems"
                        download={true}
                        href={file}
                      >
                        <FaFileDownload fontSize={25} color="#EFAA20" />
                      </Button>
                      <img
                        className="w-96   rounded-xl border"
                        src="https://logodownload.org/wp-content/uploads/2020/04/excel-logo-0.png"
                        alt=""
                      />
                    </dev>
                  );
                  handleShow();
                }}
                className="shrink-0"
              >
                <img
                  className="w-24 h-24 rounded-xl border"
                  src="https://logodownload.org/wp-content/uploads/2020/04/excel-logo-0.png"
                  alt=""
                />
              </div>
            );
          case "rar":
            return (
              <div
                key={index}
                onClick={() => {
                  setContent(
                    <dev className="flex items-center justify-center flex-col gap-2">
                      <Button
                        className="bg-mainItems"
                        download={true}
                        href={file}
                      >
                        <FaFileDownload fontSize={25} color="#EFAA20" />
                      </Button>
                      <img
                        className="w-96  rounded-xl border"
                        src="https://cdn-icons-png.freepik.com/512/9158/9158683.png"
                        alt=""
                      />
                    </dev>
                  );
                  handleShow();
                }}
                className="shrink-0"
              >
                <img
                  className="w-24 h-24 rounded-xl border"
                  src="https://cdn-icons-png.freepik.com/512/9158/9158683.png"
                  alt=""
                />
              </div>
            );
          default:
            return null;
        }
      }) : <div className="h-12 flex justify-center items-center">
        <p className="text-[#EFAA20] text-xl">لا يوجد مرفقات</p>
        </div>}
    </>
  );
};

export default ShowFiles;
