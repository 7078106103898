import { useQuery, useMutation, useQueryClient } from "react-query";
import {
  getAllClauses,
  addClause,
  updateClause,
  deleteClause,
} from "../../helper/fetchers/Clause";

export const useGetAllClauses = (params) => {
  const query = useQuery(["clause" , {params}], () => getAllClauses(params));

  return query;
};

export const useAddClause = (onSuccess,onError) => {
  const queryClient = useQueryClient();
  return useMutation(addClause, {
    onSuccess: () => {
      queryClient.invalidateQueries("clause");
      onSuccess()
    },
    onError: (error) => {
      onError()
    },
  });
};
export const useUpdateClause = (onSuccess,onError,id) => {
  const queryClient = useQueryClient();
  return useMutation((data) => updateClause(id, data), {
    onSuccess: () => {
      queryClient.invalidateQueries("clause");
      onSuccess()
    },
    onError: (error) => {
      onError()
    },
  });
};
export const useDeleteClause = (onSuccess,onError) => {
  const queryClient = useQueryClient();
  return useMutation(deleteClause, {
    onSuccess: () => {
      queryClient.invalidateQueries("clause");
      onSuccess()
    },
    onError: (error) => {
      onError()
    },
  });
};
