import CountryPieChart from "../../../../Components/System/Users/CountryChart/CountryPieChart";
import { Link, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import SearchUsers from "../../../../Components/System/Users/SearchUsers/SearchUsers";
import { useGetDashboardDepartmentStatistics } from "../../../../hooks/fetchers/DashboardStatistics";
import Progress from "../../../../Components/Progress";
import CustomChart from "../../Main/components/charts/CustomChart";
import { IoIosArrowBack } from "react-icons/io";
import Cookies from "js-cookie";
import { roles } from "../../../../helper/fetchers/Tasks";
import { CheckRole } from "../../../../helper/utils";

const CountryChartHR = () => {
  const { CountryName } = useParams();
  const myCountry = Cookies.get("country");
  const [countryBase, setCountryBase] = useState(
    myCountry === "مصر" ? "egypt":"Saudia"  
  );
  // Cookies.get("country") === "السعودية")
  // Cookies.get("country") === "مصر")
  console.log(myCountry);

  useEffect(() => {
    if (CountryName === "Saudia") {
      setCountryBase("Saudia");
    } else if (CountryName === "egypt") {
      setCountryBase("egypt");
    }
  }, [CountryName]);

  const {
    data: dashboardStatistics,
    isLoading: dashboardStatisticsLoading,
    isError: dashboardStatisticsError,
  } = useGetDashboardDepartmentStatistics();
  return (
    <div className="grid grid-cols-12 gap-2 h-full">
      <div className="col-span-4">
        <SearchUsers />
      </div>
      <div className="col-span-8">
        <div className="country-Chart px-2 py-5">
          <div className="d-flex justify-content-center flex-column align-items-center">
            <div className="d-flex  mx-auto gap-4   justify-center">
              <p className=" text-center text-xl   text-mainText">
                {CheckRole(roles["admin"]) ? (
                  <>{countryBase === "Saudia" ? "السعودية" : "مصر"}</>
                ) : (
                  <>{myCountry}</>
                )}
              </p>
              {CheckRole(roles["admin"]) ? (
                <Link
                  to={
                    countryBase === "Saudia"
                      ? "/System/Hr/Employees/egypt"
                      : "/System/Hr/Employees/Saudia"
                  }
                >
                  <IoIosArrowBack color="#D59921" size={25} />
                </Link>
              ) : null}
            </div>
            {!dashboardStatisticsError && !dashboardStatisticsLoading ? (
              <CountryPieChart
                data={
                  countryBase === "Saudia"
                    ? dashboardStatistics?.Saudi
                    : dashboardStatistics?.Egypt
                }
              />
            ) : null}
          </div>
          <fieldset className="All-users-columnChart-container  py-3 m-auto ">
            <legend className="text-mainText text-center">
              كل المستخدمين في {countryBase === "Saudia" ? "السعودية" : "مصر"}
            </legend>
            <div className="rounded-[16.799px] bg-mainNavy shadow p-1.5 h-full w-full">
              {dashboardStatisticsError ? (
                <div className="h-56 grid place-content-center">
                  <p className="text-red-500 text-xl"> حدث خطأ ما </p>
                </div>
              ) : null}
              {dashboardStatisticsLoading ? (
                <div className="h-56 grid place-content-center">
                  <Progress />
                </div>
              ) : null}
              {!dashboardStatisticsError && !dashboardStatisticsLoading ? (
                <CustomChart
                  data={
                    countryBase === "Saudia"
                      ? dashboardStatistics?.Saudi?.departments
                      : dashboardStatistics?.Egypt?.departments
                  }
                  chartTitle={"احصائيات الموظفين"}
                  title={"عدد الموظفين"}
                  rowProps={{
                    className: "h-12 text-white",
                  }}
                  showChart={false}
                  mainColTitle="الاسم"
                />
              ) : null}
            </div>
            {/* <div className="county-users-columnChart  d-flex   align-items-center flex-column">
              <CountryColumnChart data={departmentData} country={countryBase} />
              <CountryDeprtamentSlider
                data={departmentData}
                country={countryBase}
              />
            </div> */}
          </fieldset>
        </div>
      </div>
    </div>
  );
};

export default CountryChartHR;
