import { IoMdArrowDropright } from "react-icons/io";
import { IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function BackButton() {
  const navigate = useNavigate();
  const handleBackNavigation = () => {
    navigate(-1);
  };
  return (
    <IconButton onClick={handleBackNavigation}>
      <IoMdArrowDropright color="var(--main-text)" fontSize={25} />
    </IconButton>
  );
}
