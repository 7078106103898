import React, { useCallback, useEffect } from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import Input from "../../../FormHandler/Input";
import { UseInput, UseSelect } from "../../../../hooks";
import Select from "../../../FormHandler/Select";
import "./EditRevenues.css";
import { useState } from "react";
import { ConfirmPopup } from "primereact/confirmpopup";
import ConfirmPoper from "../../ConfirmPoper";
import { useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import moment from "moment";
import AddAttachment from "../../AddAttachment";
import ShowLocalFiles from "../../../ShowLocalFiles";
import ShowFiles from "../../../ShowFiles";
import { toast } from "react-toastify";
import { updateFinancial } from "../../../../helper/fetchers/finaical";
import SuccessfullModal from "../../../Modals/SuccessfullModal";
import { updateInvoices } from "../../../../helper/fetchers/invoice";
import { PhoneInput } from "react-international-phone";

const EditInvoice = ({ editInvoice, setEditInvoice, item, getItem }) => {
  const [confirmPoper, setConfirmPoper] = useState(false);
  const { RevenueType } = useParams();
  // console.log(item, "item");
  //   Data
  const projectDescription = UseInput("", "", true);
  const projectQuantity = UseInput("", "", true);
  const projectValue = UseInput("", "", true);
  const vat = UseInput("", "", true);
  const amountVat = UseInput("", "", true);
  const taxNumber = UseInput("", "", true);
  const textAmount = UseInput("", "", true);
  const invoiceNumber = UseInput("", "", true);
  const email = UseInput("", "", true);
  // const phone = UseInput("", "", true);
  const [phone, setPhone] = useState("");
  const [attachment, setAttachment] = useState(null);

  // define edit inputs
  const [invoiceDate, setInvoiceDate] = useState("");

  const setValues = useCallback(() => {
    setInvoiceDate(new Date(item?.invoiceDate));
    projectDescription?.changeValue(item?.description);
    projectQuantity?.changeValue(item?.qty);
    projectValue?.changeValue(item?.amount);
    vat?.changeValue(item?.vat);
    amountVat?.changeValue(item?.amountVat);
    taxNumber?.changeValue(item?.taxNumber);
    textAmount?.changeValue(item?.textAmount);
    invoiceNumber?.changeValue(item?.invoiceNumber);
    email?.changeValue(item?.email);
    setPhone(item?.phone);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);

  useEffect(() => {
    setValues();
  }, [setValues]);
  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const files = attachment ? [...attachment] : [];

  const handleUpdate = async () => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("qty", projectQuantity.value);
    formData.append("description", projectDescription.value);
    formData.append("vat", vat?.value);
    formData.append("amountVat", amountVat.value);
    formData.append("phone", phone);
    formData.append("textAmount", textAmount?.value);
    formData.append("email", email?.value);
    formData.append("invoiceDate", invoiceDate);
    formData.append("amount", projectValue.value);
    formData.append("invoiceNumber", invoiceNumber?.value);
    formData.append("finaicalId", item?.financialId?._id);

    if (attachment && attachment?.length > 0) {
      files.forEach((file) => {
        formData.append(`invoiceFile`, file);
      });
    }
    try {
      const res = await updateInvoices(item?._id, formData);
      if (res?.status === 200) {
        setSuccess(true);
        getItem();
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
    setIsLoading(false);
  };
  return (
    <div>
      <SuccessfullModal
        message={"تم تعديل الفاتوره بنجاح"}
        handleClose={() => {
          setSuccess(false);
          setEditInvoice(false);
        }}
        show={success}
      />
      {editInvoice && (
        <Modal
          size="lg"
          show={editInvoice}
          onHide={() => setEditInvoice(false)}
          aria-labelledby=" example-modal-sizes-title-lg"
          className="systemModal    "
        >
          <div
            className="p-1 mx-auto my-3 edit-header
         w-50"
          >
            <p className="golden   text-center">تعديل فى الفاتورة </p>
          </div>

          <div className="mx-4">
            <Form className="row w-100 m-auto  ">
              <fieldset className=" mx-auto border-golden w-90 p-3 ">
                <legend className="text-center text-mainText"> تعريفات </legend>

                <div className="row w-100">
                  <div className=" col-md-6 mb-4">
                    <p className="w-100 text-mainText ">
                      اسم المشروع :
                      <span className="main-text p-2 ">
                        {item?.projectId?.requestId?.projectName}
                      </span>
                    </p>
                  </div>
                  <div className=" col-md-6 mb-4">
                    <p className="w-100  text-mainText">
                      الكود :
                      <span className="main-text p-2">
                        {item?.projectId?.requestId?.orderNumber}
                      </span>
                    </p>
                  </div>
                  <div className=" col-md-12 mb-4">
                    <Input
                      label={" التعريف الضريبي:"}
                      mandatory
                      disabled
                      value={"مكتب بدر عبد المحسن بن سليمان لاستشارات الهندسية"}
                    />
                  </div>

                  <div className=" col-md-6 mb-4">
                    <Input
                      label={" الرقم الضريبي:"}
                      mandatory
                      disabled
                      value={"300195565100003"}
                    />
                  </div>

                  <div className=" col-md-6 mb-4">
                    <Input
                      label={"  العنوان    "}
                      mandatory
                      disabled
                      value={"الرياض حي النخيل"}
                    />
                  </div>

                  <div className=" col-md-6 mb-4">
                    <Input label={"الكود"} mandatory disabled value={"0003"} />
                  </div>
                </div>
              </fieldset>
              <fieldset className=" my-3 mx-auto border-golden w-90 p-3 ">
                <legend className="text-center text-mainText">
                  
                  تعريفات العميل
                </legend>

                <div className="row  w-100">
                  <div className="col-md-6 mb-4">
                    <Input
                      label={"رقم الفاتوره"}
                      placeholder="ادخل رقم الفاتوره"
                      mandatory
                      {...invoiceNumber.bind}
                    />
                  </div>
                  <div className="col-md-6 mb-4">
                    <Form.Group className="d-flex flex-column text-mainText">
                      <Form.Label className="text-mainText">
                        تاريخ الفاتورة
                      </Form.Label>
                      <DatePicker
                        selected={invoiceDate}
                        placeholderText=" اختر تاريخ الفاتورة   "
                        onChange={(date) => setInvoiceDate(date)}
                        dateFormat="dd-MM-yyyy"
                        className="w-100 form-control"
                      />
                    </Form.Group>
                  </div>
                  <div className="col-md-6 mb-4">
                    <Input
                      label={"البريد الالكتروني"}
                      placeholder="البريد الالكتروني"
                      mandatory
                      {...email.bind}
                    />
                  </div>

                  {/* <div className="col-md-6 mb-4">
                    <Input
                      label={"  رقم الجوال"}
                      placeholder="رقم الجوال"
                      {...phone.bind}
                    />
                  </div> */}
                  <div className="col-md-6 mb-4">
                    <label className="text-mainText">
                      رقم الجوال <span className="text-danger">*</span>
                    </label>
                    <PhoneInput
                      defaultCountry="sa"
                      placeholder="ادخل رقم الجوال"
                      value={phone}
                      onChange={(phone) => setPhone(phone)}
                    />
                    {/* <Input
            placeholder="ادخل  رقم الجوال"
            label={"رقم الجوال"}
            value={phone}
            onChange={(e) => setPhone(e?.target?.value)}
            mandatory
          /> */}
                  </div>
                </div>
              </fieldset>
              <fieldset className="border-golden w-90 mx-auto pb-3 ">
                <legend className="text-center text-mainText">
                  
                  المرفقات
                </legend>
                <div className="d-flex w-90 m-auto justify-content-between">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="w-100 form-container">
                        <Input
                          {...projectDescription.bind}
                          placeholder="اكتب الوصف"
                          className="w-100"
                          label={"1-الوصف"}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="w-100 form-container mb-3">
                        <Input
                          {...projectQuantity.bind}
                          placeholder=" الكميه"
                          className="w-100"
                          label={"الكمية"}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="w-100 form-container mb-3">
                        <Input
                          {...projectValue.bind}
                          placeholder=" القيمة"
                          className="w-100"
                          label={"القيمة"}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="w-100 form-container mb-3">
                        <Input
                          {...vat.bind}
                          placeholder="  ق.م"
                          className="w-100"
                          label={"ادخل ال ق.م"}
                          discount={true}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="w-100 form-container mb-3">
                        <Input
                          {...amountVat.bind}
                          placeholder=" ض .ق.م"
                          className="w-100"
                          label={" ال ض.ق.م"}
                        />
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="w-[590px] form-container mb-3">
                        <Input
                          {...textAmount.bind}
                          placeholder=" اجمالي المبلغ كتابة"
                          className="w-100"
                          label={" اجمالي المبلغ كتابة"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </fieldset>
              <fieldset className="border-golden my-3 w-90 mx-auto p-2 flex flex-row gap-2">
                <legend className="text-center text-mainText">
                  
                  الملف المرفق
                </legend>
                <AddAttachment
                  attachment={attachment}
                  setAttachment={setAttachment}
                  multi={true}
                />
                {attachment ? (
                  <ShowLocalFiles files={Array.from(attachment)} />
                ) : item?.invoiceFile?.length > 0 ? (
                  <ShowFiles files={item?.invoiceFile} />
                ) : null}
              </fieldset>

              <div className="d-flex justify-content-end w-90">
                <Button
                  onClick={() => {
                    handleUpdate();
                  }}
                  className="sumbmitAddUpdateUser"
                >
                  {isLoading ? <Spinner size="sm" /> : "حفظ"}
                </Button>
              </div>
            </Form>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default EditInvoice;
