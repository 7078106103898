import { useCallback, useEffect } from "react";
import { Button, NavDropdown } from "react-bootstrap";
import { useState } from "react";
import Input from "../../../FormHandler/Input.jsx";
import "./Invoice.css";
import Image from "../../../Image.jsx";
import DownloadButton from "../../../Buttons/DownloadButton.jsx";
import {
  deleteInvoices,
  getInvoicesById,
} from "../../../../helper/fetchers/invoice.jsx";
import moment from "moment/moment.js";
import ShowFiles from "../../../ShowFiles.jsx";
import EditInvoice from "../editInvoices/EditInvoice.js";
import DeleteModal from "../../../../Pages/System/Settings/RemoveModal.jsx";
import SuccessfullModal from "../../../Modals/SuccessfullModal.jsx";
import { useQueryClient } from "react-query";
import LoadingPage from "../../../feedback/LoadingPage.jsx";
import useModal from "../../../../hooks/useModal.jsx";
import PrintInvoiceFile from "./PrintInvoiceFile.jsx";
import { ImPrinter } from "react-icons/im";
import { Tooltip } from "@mui/material";

const DisplayInvoice = ({
  setViewInvoices,
  viewInvoice,
  id,
  getInvoices,
  getAllInvoicesStats,
}) => {
  const [openDelete, setOpenDelete] = useState(false);
  const [ConfirmDelete, setConfirmDelete] = useState(false);
  // Edit
  const [editInvoice, setEditInvoice] = useState(false);
  const [success, setSuccess] = useState(false);
  const [item, setItem] = useState();

  const getItem = useCallback(async () => {
    if (!id) return;
    try {
      const res = await getInvoicesById(id);
      if (res?.status === 200) {
        setItem(res.data);
      } else {
        console.error(`Error fetching invoice: ${res?.status}`);
      }
    } catch (err) {
      console.error("Network error:", err);
    } finally {
    }
  }, [id]);

  useEffect(() => {
    getItem();
  }, [getItem]);
  // functions to handle actions on the view

  const handleDelete = () => {
    // after ensuring  the Delete is done
    setOpenDelete(false);
    setConfirmDelete(true);
  };
  const queryClient = useQueryClient();
  const [successLoading, setSuccessLoading] = useState(false);

  const handleClickDelete = async () => {
    setSuccessLoading(true);
    const res = await deleteInvoices({
      invoices: [item?._id],
    });
    
    if (res?.status === 204) {
      setSuccess(true);
      queryClient.invalidateQueries("invoice");
      setSuccessLoading(false);

      // getInvoices();
      getAllInvoicesStats();
    }
  };
  const {
    open: openPdf,
    handleOpenModal: handleOpenPdfModal,
    handleCloseModal: handleClosePdfModal,
  } = useModal();
  return (
    <div className="DisplayFinancialClaims   ">
      {item ? (
        <PrintInvoiceFile
          show={openPdf}
          onHide={handleClosePdfModal}
          data={item}
        />
      ) : null}

      {editInvoice && (
        <EditInvoice
          editInvoice={editInvoice}
          setEditInvoice={setEditInvoice}
          item={item}
          getItem={getItem}
        />
      )}
      {successLoading && <LoadingPage />}
      <DeleteModal
        title={"التاكيد"}
        show={openDelete}
        handleClose={handleDelete}
        onSave={handleClickDelete}
      />
      <SuccessfullModal
        message={"تم حذف الفاتوره بنجاح"}
        handleClose={() => {
          setSuccess(false);
          setEditInvoice(false);
          setViewInvoices(false);
        }}
        show={success}
      />
      <div className="border-golden">
        <div className="row px-4 py-3">
          <div className="col-md-8 mb-2">
            <p className="text-mainText">
              اسم المشروع :
              <span> {item?.projectId?.requestId?.projectName}</span>
            </p>
          </div>
          <div className="col-md-4   mb-2">
            <div className=" d-flex gap-3 justify-content-start ">
              <DownloadButton onClick={handleOpenPdfModal}>
                <div className="flex items-center gap-1">
                  <span>طباعة</span>
                  <ImPrinter />
                </div>
              </DownloadButton>
              <DownloadButton> تصدير Excel </DownloadButton>
            </div>
          </div>
          <div className="col-md-9 mb-3">
            <p className="text-mainText">
              نوع المشروع :
              <span>
                {item?.projectId?.requestId?.projectType === 1
                  ? "تصميم"
                  : "اشراف علي التنفيذ"}
              </span>
            </p>
          </div>
          <div className="col-md-3   mb-3">
            <p className="text-mainText">
              <p className="text-mainText">
                الحالة :
                <span>
                  {item?.projectId?.requestId?.status === "1"
                    ? "قيد التنفيذ"
                    : item?.projectId?.requestId?.status === "2"
                    ? "مكتمل"
                    : "معلق"}
                </span>
              </p>
            </p>
          </div>
          <div className="col-md-9 mb-2">
            <p className="text-mainText">
              رقم الطلب :<span> {item?.projectId?.requestId?.orderNumber}</span>
            </p>
          </div>
          <div className="col-md-3 mb-2">
            <div className="d-flex align-items-center  gap-3">
              <Image
                className="pointer editIcon"
                onClick={() => {
                  setOpenDelete(true);
                }}
                src={process.env.PUBLIC_URL + "/icons/delete.png"}
              />

              <Image
                className="pointer editIcon"
                onClick={() => {
                  setEditInvoice(true);
                }}
                src={process.env.PUBLIC_URL + "/icons/edit.svg"}
              />

              <NavDropdown
                title={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="4"
                    height="16"
                    viewBox="0 0 4 16"
                    fill="none"
                  >
                    <path
                      d="M2 16C0.89543 16 0 15.1046 0 14C0 12.8954 0.89543 12 2 12C3.10457 12 4 12.8954 4 14C4 15.1046 3.10457 16 2 16ZM2 10C0.89543 10 0 9.10457 0 8C0 6.89543 0.89543 6 2 6C3.10457 6 4 6.89543 4 8C4 8.53043 3.78929 9.03914 3.41421 9.41421C3.03914 9.78929 2.53043 10 2 10ZM2 4C0.89543 4 0 3.10457 0 2C0 0.89543 0.89543 0 2 0C3.10457 0 4 0.89543 4 2C4 2.53043 3.78929 3.03914 3.41421 3.41421C3.03914 3.78929 2.53043 4 2 4Z"
                      fill="white"
                    />
                  </svg>
                }
                className="fs-5 "
              >
                <NavDropdown.Item
                  className="text-end  d-flex justify-content-between  align-items-center"
                  href="#action/3.2"
                >
                  <span> المشروع</span>
                </NavDropdown.Item>
                <NavDropdown.Item
                  className="text-end  d-flex justify-content-between align-items-center"
                  href="#action/3.3"
                >
                  <span> العميل</span>
                </NavDropdown.Item>
                <NavDropdown.Item
                  className="text-end  d-flex justify-content-between align-items-center"
                  href="#action/3.3"
                >
                  <span> الحسابات</span>
                </NavDropdown.Item>
              </NavDropdown>
            </div>
          </div>
        </div>
      </div>
      <div className="max-h-[700px] overflow-y-auto scrollbar-thin scrollbar-track-transparent scrollbar-thumb-[#EFAA20]">
        <fieldset className="showProjectBorder w-90 mx-auto pb-3 ">
          <legend className="text-center text-mainText"> بيانات العميل</legend>
          <div className="d-flex w-90 m-auto justify-content-between">
            <p className="projectdetails text-mainText w-50">
              اسم المشروع :
              <span className="main-text">
                {item?.projectId?.requestId?.projectName}
              </span>
            </p>
            <p className="projectdetails text-mainText w-50 line-clamp-1">
              اسم العميل:
              <Tooltip
                placement="left"
                title={item?.projectId?.requestId?.ownerName}
              >
                <span className="main-text ">
                  {item?.projectId?.requestId?.ownerName || "لا توجد بيانات"}
                </span>
              </Tooltip>
            </p>
          </div>
          <div className="d-flex w-90 m-auto justify-content-between">
            <p className="projectdetails text-mainText w-50">
              نوع المشروع :
              <span className="main-text">
                {item?.projectId?.requestId?.projectType === 1
                  ? "تصميم"
                  : "اشراف علي التنفيذ"}
              </span>
            </p>

            <p className="projectdetails text-mainText w-50 ">
              رقم الطلب :
              <span className="main-text">
                : {item?.projectId?.requestId?.orderNumber}
              </span>
            </p>
          </div>
          <div className="d-flex w-90 m-auto justify-content-between">
            <p className="projectdetails text-mainText w-50">
              البريد الاكترونى :<span className="main-text">{item?.email}</span>
            </p>

            <p className="projectdetails text-mainText w-50 ">
              رقم الجوال:
              <span dir="ltr" className="main-text">
                {item?.phone}
              </span>
            </p>
          </div>
        </fieldset>
        <fieldset className="showProjectBorder w-90 mx-auto pb-3 ">
          <legend className="text-center text-mainText"> تعريفات </legend>
          <div className="d-flex w-90 m-auto justify-content-between">
            <p className="projectdetails text-mainText w-100">
              التعريف الضريبى:
              <span className="main-text">
                مكتب بدر عبد المحسن بن سليمان لاستشارات الهندسية
              </span>
            </p>
          </div>
          <div className="d-flex w-90 m-auto justify-content-between">
            <p className="projectdetails text-mainText w-50">
              الرقم الضريبي :<span className="main-text"> 300195565100003</span>
            </p>

            <p className="projectdetails text-mainText w-50 ">
              الكود :<span className="main-text"> : 0003</span>
            </p>
          </div>
          <div className="d-flex w-90 m-auto justify-content-between">
            <p className="projectdetails text-mainText w-50">
              العنوان :<span className="main-text">الرياض حي النخيل</span>
            </p>

            <p className="projectdetails text-mainText w-50 ">
              <span className="main-text"></span>
            </p>
          </div>
        </fieldset>
        <fieldset className="showProjectBorder w-90 mx-auto pb-3 ">
          <legend className="text-center text-mainText">
            {" "}
            تعريفات العميل{" "}
          </legend>
          <div className="d-flex w-90 m-auto justify-content-between">
            <p className="projectdetails text-mainText w-50 ">
              رقم الفاتوره :
              <span className="main-text"> : {item?.invoiceNumber}</span>
            </p>
            <p className="projectdetails text-mainText w-50">
              تاريخ الفاتورة :
              <span className="main-text">
                {moment(item?.invoiceDate).format("DD-MM-YYYY")}
              </span>
            </p>
          </div>

          <div className="d-flex w-90 m-auto justify-content-between">
            <p className="projectdetails text-mainText w-50">
              البريد الالكتروني :
              <span className="main-text"> {item?.email}</span>
            </p>
            <p className="projectdetails text-mainText w-50">
              رقم الجوال :{" "}
              <span className="main-text" dir="ltr">
                {" "}
                {item?.phone}
              </span>
            </p>
          </div>
          <div className="d-flex w-90 m-auto justify-content-between"></div>
        </fieldset>
        <fieldset className="showProjectBorder w-90 mx-auto pb-3 ">
          <legend className="text-center text-mainText"> معلومات الدفع </legend>
          <div className="d-flex w-90 m-auto justify-content-between">
            <p className="projectdetails text-mainText w-100">
              المبلغ الاجمالي :
              <span className="main-text">{item?.projectId?.totalAmount}</span>
            </p>
          </div>
          <div className="d-flex w-90 m-auto justify-content-between">
            <p className="projectdetails text-mainText w-50">
              عدد الدفعات:
              <span className="main-text">{item?.projectId?.batchCount}</span>
            </p>

            <p className="projectdetails text-mainText w-50 ">
              المبلغ المتبقي :
              <span className="main-text">
                {item?.projectId?.remainingAmount}
              </span>
            </p>
          </div>
          <div className="d-flex w-90 m-auto justify-content-between">
            <p className="projectdetails text-mainText w-50">
              عدد الدفعات المتبقي :
              <span className="main-text">
                {item?.projectId?.remainingBatch}
              </span>
            </p>
          </div>
        </fieldset>
        <fieldset className="showProjectBorder w-90 mx-auto pb-3 ">
          <legend className="text-center text-mainText"> المرفقات </legend>
          <div className="d-flex w-90 m-auto justify-content-between">
            <div className="row">
              <div className="col-md-12">
                <div className="w-100 form-container">
                  <Input
                    disabled={true}
                    placeholder="اكتب الوصف"
                    className="w-[650px]"
                    label={"1-الوصف"}
                    value={item?.description}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="w-100 form-container mb-3">
                  <Input
                    disabled={true}
                    placeholder=" الكميه"
                    className="w-100"
                    label={"الكمية"}
                    value={item?.qty}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="w-100 form-container mb-3">
                  <Input
                    disabled={true}
                    placeholder=" القيمة"
                    className="w-100"
                    label={"القيمة"}
                    value={item?.amount}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="w-100 form-container mb-3">
                  <Input
                    placeholder="ض.ق.م"
                    className="w-100"
                    label={"ادخل ال ض.ق.م"}
                    value={item?.vat}
                    disabled
                    discount={true}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="w-100 form-container mb-3">
                  <Input
                    disabled={true}
                    placeholder="ض.ق.م"
                    className="w-100"
                    value={item?.amountVat}
                    label={"ال ض.ق.م"}
                  />
                </div>
              </div>

              <div className="col-md-4">
                <div className="w-100 form-container mb-3">
                  <Input
                    disabled={true}
                    placeholder=" اجمالي المبلغ"
                    className="w-100"
                    label={" اجمالي المبلغ "}
                    value={item?.finalAmount}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="w-100 form-container mb-3">
                  <Input
                    disabled={true}
                    placeholder=" اجمالي المبلغ كتابة"
                    className="w-100"
                    value={item?.textAmount}
                    label={" اجمالي المبلغ كتابة"}
                  />
                </div>
              </div>
            </div>
          </div>
        </fieldset>
        <fieldset className="showProjectBorder w-90 mx-auto p-2 ">
          <legend className="text-center text-mainText"> الملف المرفق </legend>
          {item?.invoiceFile?.length > 0 && (
            <ShowFiles files={item?.invoiceFile} />
          )}
        </fieldset>
        <div className="d-flex my-5 w-90  justify-content-end">
          <Button
            onClick={() => {
              setViewInvoices(false);
            }}
            className="sumbmitAddUpdateUser"
          >
            حفظ
          </Button>
        </div>
      </div>
    </div>
  );
};

export default DisplayInvoice;
