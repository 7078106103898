import { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import "./chatInput.css";
import MessageSound from "../../../../assets/sounds/chatMessage.mp3";

import { myAxiosJson } from "../../../../helper/https";
import Cookies from "js-cookie";
import { useForm } from "react-hook-form";

import {
  sockietDissconnect,
  sockietOnMessage,
  sockietEmitMessage,
} from "../../../../utiltis/SockietHelper";
import { useDispatch } from "react-redux";
import { setSelectedUser } from "../../../../setup/RTK/Chat/chatNotification";
import { useReadMessage } from "../../../../hooks/fetchers/Notifications";
import UserDetails from "./UserDetails";
import ChatMessages from "./ChatMessages";
import SendButton from "./SendButton";
import ChatBoxContainer from "./ChatBoxContainer";
import ChatInput from "./ChatInput";
import useTypingNow from "../hooks/useTypingNow";
import useGetUserProfile from "../hooks/useGetUserProfile";
import useMutateChat from "../hooks/useMutateChat";
import { ChatsContext } from "../../PlanModel/context/ChatsContext";

const UserChat = () => {
  const dispatch = useDispatch();
  const { mutate: mutateReadMessage } = useReadMessage();
  const { typingNow, startTyping, stopTyping } = useTypingNow();
  const { control, handleSubmit, resetField, setValue, watch } = useForm();
  const [allMessages, setAllMessages] = useState([]);
  const { id: userId } = useParams();
  const myId = Cookies.get("userId");
  const [isLoadingMessage, setIsLoadingMessage] = useState(false);

  const { chatItem: userData } = useContext(ChatsContext);

  const { myData } = useGetUserProfile(userId);
  const { mutate } = useMutateChat(
    () => {
      console.log("Sended Successfully");
      resetField("message");
    },
    () => {}
  );
  const containerRef = useRef(null);
  useEffect(() => {
    sockietOnMessage("recieveMessage", (recievedMessage) => {
      setAllMessages((prev) => [...prev, recievedMessage]);
      const sound = new Audio(MessageSound);
      sound.play();
    });
    return () => {
      sockietDissconnect();
      setAllMessages([]);
    };
  }, []);
  useEffect(() => {
    sockietOnMessage("typing", (val) => {
      startTyping();
    });
    return () => {
      sockietDissconnect();
    };
  }, []);
  useEffect(() => {
    sockietOnMessage("stopUserTyping", (val) => {
      stopTyping();
    });
    return () => {
      sockietDissconnect();
    };
  }, []);
  useEffect(() => {
    dispatch(setSelectedUser(userId));
    return () => {
      dispatch(setSelectedUser(null));
    };
  }, [userId]);
  const onSubmit = (messageData) => {
    if (messageData.message) {
      mutate({ userId: userId, message: messageData.message });
      setAllMessages((prev) => [
        ...prev,
        {
          from: { ...myData },
          message: messageData.message,
          createdAt: Date(),
        },
      ]);
    }
  };

  const handleWritingMessage = (val) => {
    console.log("value", val);
    sockietEmitMessage("userTyping", val);
    setTimeout(() => {
      sockietEmitMessage("stopTyping", val);
    }, 1500);
  };

  useEffect(() => {
    setAllMessages([]);
    setIsLoadingMessage(true);
    if (userId) {
      myAxiosJson
        .get(`chat/ovo/${userId}`)
        .then(({ data }) => {
          if (
            myId !==
              data?.chat?.messages[data?.chat?.messages.length - 1]?.from
                ?._id &&
            !data?.chat?.messages[data?.chat?.messages.length - 1]?.read
          ) {
            const sendId = data?.chat?._id;
            if (sendId) {
              mutateReadMessage(sendId);
            }
          }
          setAllMessages(data?.chat?.messages || []);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    setIsLoadingMessage(false);
  }, [userId]);

  const divRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    divRef.current.scrollTop = divRef.current.scrollHeight;
  }, [allMessages]);
  return (
    <div className="w-full h-full relative p-2" ref={containerRef}>
      {userData && <UserDetails userData={userData} />}
      <ChatMessages
        ref={divRef}
        allMessages={allMessages}
        isLoadingMessage={isLoadingMessage}
        myId={myId}
        typingNow={typingNow}
      />

      <ChatBoxContainer>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-row-reverse items-center gap-2"
        >
          <div className="w-11/12">
            <ChatInput
              ref={inputRef}
              control={control}
              handleWritingMessage={handleWritingMessage}
              onSubmit={onSubmit}
              setValue={setValue}
              watch={watch}
            />
          </div>

          <SendButton />
        </form>
      </ChatBoxContainer>
    </div>
  );
};

export default UserChat;
