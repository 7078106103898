import { toast } from "react-toastify";
import myAxiosInstance, { myAxiosJson } from "../https";

export const getAllPlans = (params = {}) =>
  myAxiosInstance("plan",{ params }).then((data) => data?.data).catch(error=>{
    toast.error(error?.response?.data?.message)
});
export const getPlan = (planId) =>
  myAxiosInstance(`plan/${planId}`).then((data) => data?.data?.plan).catch(error=>{
    toast.error(error?.response?.data?.message)
});
export const addPlan = (data) => myAxiosInstance.post("plan", data);
export const updatePlan = (planId, data) =>
  myAxiosInstance.patch(`plan/${planId}`, data);
export const deletePlan = (data) => myAxiosJson.delete(`plan/`,{data});

export const stopPlan = (planId,data) => myAxiosJson.patch(`plan/stoped/${planId}`,data);

export const completePlan = (planId,data) => myAxiosJson.patch(`plan/pause/${planId}`);

export const finishPlan = (planId,data) => myAxiosJson.patch(`plan/finish/${planId}`);

export const confirmPlan = (planId,data) => myAxiosJson.patch(`plan/confirm/${planId}`);

export const refusePlan = (planId,data) => myAxiosJson.patch(`plan/reject/${planId}`,data);

