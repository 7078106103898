import { useState } from "react";
import { SearchComponent } from "../../../../../../Components/SearchComponent/SearchComponent";
import { Button, Modal, NavDropdown } from "react-bootstrap";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FaRegShareSquare } from "react-icons/fa";
import { MdDeleteOutline } from "react-icons/md";
import { FilterIcon } from "../../../../../../utiltis/Icons";
import { GrTransaction } from "react-icons/gr";
import useModal from "../../../../../../hooks/useModal";
import { useGetAllUsersReports } from "../../../../../../hooks/fetchers/Users";
import moment from "moment";
import { InputLabel } from "../../../../../System/PlanModel/components/InputLabel";
import { CustomDatePicker } from "../../../../../../Components/FormUi";
import { useForm } from "react-hook-form";
import List from "../../../../../../Components/List";
import HoverLink from "../../../../../../Components/List/HoverLink";
import UserListItemCard from "./UserListItemCard";

const FilterModal = ({ show, onHide, onSave }) => {
  const { handleSubmit, control } = useForm();
  const manipulateData = (data) => {
    data.startDate = moment(data.startDate).format("").split("T")[0];
    data.endDate = moment(data.endDate).format("").split("T")[0];
    return data;
  };
  const onSubmit = (data) => {
    data = manipulateData(data);
    onSave(data);
    onHide();
  };
  return (
    <Modal
      show={show}
      onHide={onHide}
      className="systemModal bg-black/50 "
      contentClassName="border !border-[#EFAA20] rounded-3xl p-0"
      centered
    >
      <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header className="w-full !justify-between border-none">
          <Modal.Title className="text-mainText">{"تصنيف"}</Modal.Title>
          <Button
            className="p-0 bg-transparent hover:bg-transparent"
            onClick={onHide}
          >
            <img src="/Rejected.svg" alt="" />
          </Button>
        </Modal.Header>
        <Modal.Body className="w-full space-y-2">
          <div className="flex flex-col gap-2">
            <InputLabel label={"من تاريخ"} />
            <CustomDatePicker
              control={control}
              name="startDate"
              rules={{
                required: "من فضلك ادخل تاريخ البداية ",
              }}
            />
          </div>
          <div className="flex flex-col gap-2">
            <InputLabel label={"الي تاريخ"} />
            <CustomDatePicker
              control={control}
              name="endDate"
              rules={{
                required: "من فضلك ادخل تاريخ النهاية ",
              }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer className="w-full justify-center border-none">
          <Button type="submit" className="!bg-[#EFAA20]">
            {"حفظ"}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

const UsersSidebar = () => {
  const { open, handleOpenModal, handleCloseModal } = useModal();
  const [range, setRange] = useState({});
  const [search, setSearch] = useState("");

  const handleASearch = (val) => {
    setSearch(val);
  };
  const { data } = useGetAllUsersReports({ range, search });
  return (
    <div className="h-full rounded-[19px] p-2 space-y-4">
      <SearchComponent
        handleChange={handleASearch}
        background="lighter"
        border="border !border-[#E4A11B]"
      />

      <FilterModal
        show={open}
        onHide={handleCloseModal}
        onSave={(data) => {
          setRange(`${data.startDate},${data.endDate}`);
          console.log(`${data.startDate}, ${data.endDate}`);
        }}
      />

      <div className="flex justify-between items-center border-b border-gray-400 py-2">
        {/* <UsersOptions /> */}

        <div className="w-full">{"كل المستخدمين"}</div>
        <div className="">
          <NavDropdown
            title={<FilterIcon />}
            className="fs-5"
            id="collapsible-nav-dropdown"
          >
            <NavDropdown.Item
              onClick={handleOpenModal}
              className="flex items-center gap-1 !bg-white !text-black overflow-hidden rounded-lg"
            >
              <GrTransaction color="black" />
              <span className="!text-black">من...الي</span>
            </NavDropdown.Item>
          </NavDropdown>
        </div>
      </div>
      <div className="max-h-[700px] overflow-y-auto scrollbar-thin scrollbar-track-transparent scrollbar-thumb-[#EFAA20]">
        <List
          data={data?.users}
          renderItem={(props) => (
            <HoverLink to={`${props?._id}`} key={props?._id}>
              <UserListItemCard {...props} />
            </HoverLink>
          )}
        />
      </div>
    </div>
  );
};



export default UsersSidebar;

const UsersOptions = () => {
  return (
    <div className="">
      <NavDropdown
        title={<BsThreeDotsVertical className="text-mainText" />}
        className="fs-5 w-fit"
        id="collapsible-nav-dropdown"
      >
        <NavDropdown.Item
          // onClick={() => handleExport(`request/excel/${_id}`)}
          className="text-end  d-flex gap-2  align-items-center hover:rounded-lg"
        >
          <FaRegShareSquare className="text-black" />
          <span className="text-black">تصدير</span>
        </NavDropdown.Item>
        <NavDropdown.Item
          // onClick={() => {
          //   setRequestId(_id);

          //   handleOpenPdfModal();
          // }}
          className="text-end  d-flex gap-2  align-items-center hover:rounded-lg"
        >
          <MdDeleteOutline className="text-black" />
          <span className="text-black">طباعة</span>
        </NavDropdown.Item>
      </NavDropdown>
    </div>
  );
};
