import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import FormControl from "@mui/material/FormControl";
// import FormLabel from "@mui/material/FormLabel";
// import FormHelperText from "@mui/material/FormHelperText";
import { InputLabel } from "../../../Pages/System/PlanModel/components/InputLabel";
import { useForm } from "react-hook-form";
import { useAddClause } from "../../../hooks/fetchers/Clause";
import useSuccessfullModal from "../../Modals/hooks/useSuccessfullModal";
import SuccessfullModal from "../../Modals/SuccessfullModal";
import { Spinner } from "react-bootstrap";
const fileTypes = [
  "image/apng",
  "image/bmp",
  "image/gif",
  "image/jpeg",
  "image/pjpeg",
  "image/png",
  "image/svg+xml",
  "image/tiff",
  "image/webp",
  "image/x-icon",
];
export default function AddNewAccounating({ title, show, handleClose }) {
  const {
    handleShowSuccess,
    handleShowError,
    handleCloseSuccess,
    handleCloseError,
    showSuccess,
    showError,
  } = useSuccessfullModal();
  const { mutate, isLoading } = useAddClause(
    handleShowSuccess,
    handleShowError
  );
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("descraption", data.descraption);

    for (const image of data.image) {
      formData.append("image", image);
    }
    mutate(formData);
    handleClose();
  };
  const validateFiles = (value) => {
    if (value.length > 4) {
      return "يمكنك تحميل ما يصل إلى 4 صور فقط";
    }
    return true;
  };
  return (
    <Modal
      centered
      contentClassName="bg-mainItems border !max-w-[700px] !border-[#EFAA20] !rounded-[20px] text-mainText"
      show={show}
      onHide={handleClose}
      size="lg"
    >
      <form
        onSubmit={handleSubmit(onSubmit)}
        encType="multipart/form-data"
        className="w-full"
      >
        <Modal.Header className="!justify-between border-none">
          <div className=""></div>
          <Modal.Title className="text-xl text-[#EFAA20] border !border-[#EFAA20] p-2 rounded-[5px] font-medium ">
            {title}
          </Modal.Title>
          <Button
            className="p-0 bg-transparent hover:bg-transparent"
            onClick={handleClose}
          >
            <img src="/Rejected.svg" alt="" />
          </Button>
        </Modal.Header>
        <Modal.Body>
          <FormControl fullWidth className="mb-3">
            <InputLabel size={18} label={"اسم البند"} className={"mb-3"} />
            <input
              className="w-full bg-mainNavy py-3 px-2 rounded-[7px] outline-none"
              type="text"
              placeholder="ادخل الاسم"
              {...register("name", {
                required: "يجب إدخال الإسم",
                minLength: {
                  value: 5,
                  message: "يجب أدخال 5 حروف على الأقل",
                },
                maxLength: {
                  value: 200,
                  message: "لا يجب أن تزيد عن 200 حرف",
                },
              })}
            />
            {errors.name && (
              <span className="text-red-500">{errors.name.message}</span>
            )}
          </FormControl>
          <FormControl fullWidth className="mb-3">
            <InputLabel size={18} label={"الوصف"} className={"mb-3"} />
            <textarea
              className="w-full bg-mainNavy py-3 px-2 rounded-[7px] outline-none"
              rows={5}
              placeholder="ادخل وصف البند"
              {...register("descraption", {
                required: "يجب إدخال الإسم",
                minLength: {
                  value: 5,
                  message: "يجب أدخال 5 حروف على الأقل",
                },
                maxLength: {
                  value: 255,
                  message: "لا يجب أن تزيد عن 255 حرف",
                },
              })}
            ></textarea>
            {errors.descraption && (
              <span className="text-red-500">{errors.descraption.message}</span>
            )}
          </FormControl>
          <FormControl fullWidth className="mb-3">
            <InputLabel size={18} label={"ارفاق صورة"} className={"mb-3"} />
            <label className="bg-mainNavy flex justify-center items-center rounded-[7px] py-5">
              <input
                type="file"
                multiple
                className="hidden"
                accept="image/*"
                {...register("image", {
                  required: "يجب إختيار الصورة",
                  validate: validateFiles

                })}
              />
              <img src="/upload-icon.svg" alt="upload" />
            </label>
            {errors.image && (
              <span className="text-red-500">{errors.image.message}</span>
            )}
          </FormControl>
        </Modal.Body>
        <Modal.Footer className="border-none">
          <Button
            type="submit"
            className="mx-auto py-1 px-14 font-semibold text-[15px] border !border-[#EFAA20] text-white hover:text-mainText bg-[#EFAA20] hover:bg-[#EFAA20] "
          >
            {isLoading ? <Spinner size="sm" animation="border" /> : "حفظ"}
          </Button>
        </Modal.Footer>
      </form>
      <SuccessfullModal
        handleClose={() => {
          handleCloseSuccess();
          handleClose();
        }}
        message={"تمت الاضافة بنجاح"}
        show={showSuccess}
      />
      <SuccessfullModal
        handleClose={handleCloseError}
        message={"حدث خطأ ما"}
        show={showError}
        status="error"
      />
    </Modal>
  );
}

/**
formdata.append("image", fileInput.files[0], "/C:/Users/ht/Downloads/Rectangle 9010.png");
  function updateImageDisplay(e) {
    const curFiles = e.target.files;
    if (curFiles.length === 0) {
      return;
    } else {
      setDateToday(dateNow);
      const selectedImages = Array.from(curFiles);
      const imagesArr = selectedImages.map((file) => {
        if (validFileType(file)) {
          return URL.createObjectURL(file);
        }
      });
      setImgSrc((prevImg) => prevImg.concat(imagesArr));
      setImg(selectedImages);
    }
  }
  const fileTypes = [
    "image/apng",
    "image/bmp",
    "image/gif",
    "image/jpeg",
    "image/pjpeg",
    "image/png",
    "image/svg+xml",
    "image/tiff",
    "image/webp",
    "image/x-icon",
  ];
  function validFileType(file) {
    return fileTypes.includes(file.type);
  }
 */
