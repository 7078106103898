import { useState } from "react";

const useModal = () => {
  const [show, setSow] = useState(false);
  const handleOpenModal = () => setSow(true);
  const handleCloseModal = () => setSow(false);
  const handleToggleModal = () => setSow(!show);
  return { show, handleOpenModal, handleCloseModal, handleToggleModal };
};

export default useModal;
