import { createContext, useContext, useState } from "react";
import { useGetProject } from "../hooks/fetchers/Projects";

const ProjectContext = createContext(null);

const ProjectProvider = ({ children, id }) => {
  const ProjectApi = useGetProject(id);  
  const [holdProject, setHoldProject] = useState(false);
  const [continueProject, setContinueProject] = useState(false);
  const [confirmholdProject, setConfirmHoldProject] = useState(false);
  const [FinishHoldProject, setFinishHoldProject] = useState(false);
  const [viewMore, setViewMore] = useState(false);
  const [openCliam, setOpenClaim] = useState(false);
  const [confirmContinueProject, setConfirmContinueProject] = useState(false);
  const [ClaimFile, setClaimFile] = useState();
  const [showImg, setShowImg] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const [imgSrc, setImgSrc] = useState(
    `${process.env.PUBLIC_URL}/icons/show.png`
  );
  return (
    <ProjectContext.Provider
      value={{
        ProjectApi,
        holdProject,
        setHoldProject,
        continueProject,
        setContinueProject,
        confirmholdProject,
        setConfirmHoldProject,
        FinishHoldProject,
        setFinishHoldProject,
        viewMore,
        setViewMore,
        openCliam,
        setOpenClaim,
        confirmContinueProject,
        setConfirmContinueProject,
        ClaimFile,
        setClaimFile,
        showImg,
        setShowImg,
        imgSrc,
        setImgSrc,
        openDeleteModal,
        setOpenDeleteModal,
      }}
    >
      {children}
    </ProjectContext.Provider>
  );
};

export const useProjectContext = () => {
  const ctx = useContext(ProjectContext);
  if (!ctx) {
    throw Error("Use Inside Context");
  }
  return ctx;
};

export default ProjectProvider;
