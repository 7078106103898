import Image from "../../../Components/Image";
import { FaRegClock } from "react-icons/fa";
import { MdOutlineDateRange } from "react-icons/md";
import moment from "moment";
import Dropdown from "react-bootstrap/Dropdown";
import { Avatar } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useReadNotification } from "../../../hooks/fetchers/Notifications";
import { twMerge } from "tailwind-merge";

const notificationEnum = {
  plans: { value: "50", path: `` },
  tasks: { value: "55", path: `` },
};

function NotificationCard({ data = {}, isNotify = true }) {
  const navigate = useNavigate();
  const { mutate } = useReadNotification();
  let url = "";
  if (data?.module === "50") {
    url = `/System/tasks/plans/show-project/${data?.moduleId}`;
  } else if (data?.module === "55") {
    url = `/System/tasks/tasks/show-task/${data?.moduleId}`;
  } else if (data?.module === "111") {
    url = `/System/tasks/tasks/show-task/${data?.moduleId}`;
  } else if (data?.module === "56") {
    url = `/System/tasks/show-external-task/${data?.moduleId}`;
  } else if (data?.module === "88") {
    url = `/System/Meetings/index`;
  }
  return (
    <Dropdown.Item
      href="#"
      className={twMerge(
        "flex gap-1 bg-mainItems hover:bg-mainItems focus:bg-mainItems active:bg-mainItems p-0",
        isNotify ? "" : "shadow"
      )}
    >
      <div
        onClick={() => {
          navigate(url);
          mutate(data?._id);
        }}
        className="flex-1 min-h-[120px]  pointer p-2 bg-mainItems rounded-[9px] text-start"
      >
        <div
          style={{ fontFamily: "cairo" }}
          className="flex justify-between items-stretch "
        >
          <div className="flex items-center !h-full self-center relative">
            <Avatar
              src={"/icons/Notifactionicon.png"}
              alt="Notifactionicon image"
              className={"!w-20 !h-20 rounded-full object-cover"}
            />

            {!isNotify && !data?.read && (
              <div className="w-2 h-2 rounded-full mt-2 bg-red-700 absolute top-2 left-2" />
            )}
          </div>

          <div className="flex flex-1 flex-col justify-between gap-3">
            <div className="flex flex-col gap-2">
              <p className="text-[16px] line-clamp-1 text-[#EFAA20]">
                {data && data.title}
              </p>
              <p className="text-[16px] line-clamp-1 text-mainText">
                {data && data.message}
              </p>
            </div>

            <div className="flex mt-3 justify-end items-center gap-4">
              <div className="flex items-center gap-2">
                <p className="text-mainText">
                  {moment(data?.createdAt).format("hh:mm a")}
                </p>
                <FaRegClock color="#B7B7B7" />
              </div>
              <div className="flex items-center gap-2">
                <p className="text-mainText">
                  {moment(data?.createdAt).format("YYYY-MM-DD")}
                </p>

                <MdOutlineDateRange color="#B7B7B7" />
              </div>
            </div>
          </div>
        </div>

        {/* <Link to={url}> */}

        {/* </Link> */}
      </div>
    </Dropdown.Item>
  );
}

export default NotificationCard;
