import { Fragment, useEffect, useState } from "react";
import { Form, Modal, Button } from "react-bootstrap";
import FormControl from "@mui/material/FormControl";
import "./ShowHolidayComponent.css";
import { Checkbox } from "@mui/material";
import Progress from "../../../Progress";
import PreviewImage from "../../../Modals/PreviewImage";
import {
  useConfirmVacation,
  useDeleteVacation,
  useGetVacation,
  useRejectVaction,
  useUpdateVacation,
} from "../../../../hooks/fetchers/Vacations";
import { FormModal } from "../../../../Pages/System/PlanModel/components/FormModal";
import CustomModal from "../../../Modals/CustomModal";
import useCustomModal from "../../../Modals/hooks/useCustomModal";
import useSuccessfullModal from "../../../Modals/hooks/useSuccessfullModal";
import SuccessfullModal from "../../../Modals/SuccessfullModal";
import CommentModel from "../../../Modals/CommentModel";
import useCommentModel from "../../../Modals/hooks/useCommentModel";
import moment from "moment";
import {
  CheckRole,
  displayErrorMessage,
  statusEnum,
  vactionTypeEnum,
} from "../../../../helper/utils";
import { useSelector, useDispatch } from "react-redux";
import { switchMode, EDITMODE } from "./holidayFormSlice";
import { useForm, Controller } from "react-hook-form";
import { InputLabel } from "../../../../Pages/System/PlanModel/components/InputLabel";
import Select from "../../../FormHandler/Select";
import {
  useGetManagersUsers,
  useGetSelectedUsers,
} from "../../../../hooks/fetchers/Users";
import { useGetAllVacationCategoriesHoliday } from "../../../../hooks/fetchers/VacationCategory";
import { VacationType } from "../AddHoliday/consts";
import {
  CustomDatePicker,
  CustomeInputFile,
  CustomInput,
} from "../../../FormUi";
import ShowFiles from "../../../ShowFiles";
import { roles } from "../../../../helper/fetchers/Tasks";

const ShowHolidayComponent = ({ ShowHoliday, handleClose, id }) => {
  const dispatch = useDispatch();
  const { data: managers } = useGetManagersUsers();
  const { data: employees } = useGetSelectedUsers(true);
  const { data: vacations } = useGetAllVacationCategoriesHoliday();
  const {
    handleShowSuccess,
    handleShowError,
    handleCloseSuccess,
    handleCloseError,
    showSuccess,
    showError,
  } = useSuccessfullModal();
  const { mutate , error } = useUpdateVacation(handleShowSuccess, handleShowError, id);

  const [showPerview, setShowPerview] = useState(false);
  const [imgSrc, setImgSrc] = useState("");
  const { data, isLoading, isError } = useGetVacation(id);
  const { register, handleSubmit, control, watch, reset } = useForm();

  // console.log("error",displayErrorMessage(error));

  const transformData = (data) => {
    return {
      employeeId: {
        label: data?.data?.vacation?.employeeId?.firstName,
        value: data?.data?.vacation?.employeeId?._id,
      },
      approvalManager: {
        label: data?.data?.vacation?.approvalManager?.firstName,
        value: data?.data?.vacation?.approvalManager?._id,
      },
      durationVaction: data?.data?.vacation?.durationVaction,
      vactionCategoryId: {label: data?.data?.vacation?.vactionCategoryId?.name, value: data?.data?.vacation?.vactionCategoryId?._id},
      startDate: new Date(data?.data?.vacation?.startDate),
      endDate: new Date(data?.data?.vacation?.endDate),
      vactionType: {
        label: vactionTypeEnum[+data?.data?.vacation?.vactionType],
        value: data?.data?.vacation?.vactionType,
      },
    };
  };

  // Reset form with transformed data
  useEffect(() => {
    if (data) {
      const transformedData = transformData(data);
      reset(transformedData);
    }
  }, [data, reset]);

  console.log("watch", watch());
  console.log("managers", managers);

  const modeSelector = useSelector(
    ({ holidayFormMode }) => holidayFormMode.mode
  );
  const manipulateData = (data) => {
    data.approvalManager = data?.approvalManager?.value;
    data.employeeId = data?.employeeId?.value;
    data.vactionCategoryId = data?.vactionCategoryId?.value;
    data.vactionType = data?.vactionType?.value;
    data.startDate = moment(data?.startDate).format("YYYY-MM-DD");
    data.endDate = moment(data?.endDate).format("YYYY-MM-DD");
    return data;
  };

  const onSubmit = (data) => {
    data = manipulateData(data);
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      if (key !== "image") {
        formData.append(key, data[key]);
      }
    });
    console.log("data", data);

    // formData.append("durationVaction", data.durationVaction);

    mutate(formData);
    dispatch(switchMode());
  };

  return (
    <Modal
      className="systemModal bg-black/50"
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={handleClose}
      show={ShowHoliday}
    >
      <ModalHeader
        title={modeSelector === EDITMODE ? "تعديل طلب الاجازة" : "طلب اجازة"}
        handleClose={handleClose}
      />
      {data?.data?.vacation ? (
        <Modal.Body className="w-full">
          <PreviewImage
            showImg={showPerview}
            imgSrc={imgSrc}
            onClose={() => setShowPerview(false)}
          />
          <Form
            encType="multipart/form-data"
            className="flex flex-col gap-3 p-2"
            onSubmit={handleSubmit(onSubmit)}
          >
            <FormModal title={"مخصصة الى"}>
              <div className="row">
                {modeSelector === EDITMODE ? (
                  <div className="col-md-6">
                    {CheckRole(roles["admin"]) || CheckRole(roles["HR"]) ? (
                      <FormControl fullWidth>
                        <InputLabel label={"اسم الموظف"} />

                        <Controller
                          control={control}
                          name={`employeeId`}
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <Select
                              fullWidth
                              ref={ref}
                              onChange={onChange}
                              onBlur={onBlur}
                              OptionbackgroundColor="#414162"
                              placeholder="اختر اسم الموظف"
                              options={employees}
                            />
                          )}
                        />
                      </FormControl>
                    ) : (
                      <p className="text-mainText">
                        {"اسم الموظف :"}
                        <span className="main-text p-2">
                          {`${data?.data?.vacation?.employeeId?.firstName} ${data?.data?.vacation?.employeeId?.lastName}`}
                        </span>
                      </p>
                    )}
                  </div>
                ) : (
                  <Fragment>
                    <div className="col-md-6">
                      <p className="text-mainText">
                        {"اسم الموظف :"}
                        <span className="main-text p-2">
                          {`${data?.data?.vacation?.employeeId?.firstName} ${data?.data?.vacation?.employeeId?.lastName}`}
                        </span>
                      </p>
                    </div>
                    <div className="col-md-6">
                      <p className="text-mainText">
                        {"الكود الوظيفى :"}

                        <span className="main-text p-2">
                          {data?.data?.vacation?.employeeId?.employeCode}
                        </span>
                      </p>
                    </div>
                  </Fragment>
                )}
              </div>
            </FormModal>

            <FormModal title={"تفاصيل الاجازة"}>
              <div className="row">
                <div className="col-md-6 mb-4">
                  {modeSelector === EDITMODE ? (
                    <FormControl fullWidth>
                      <InputLabel label={"غرض الاجازة"} />
                      <Controller
                        name={`vactionCategoryId`}
                        control={control}
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <Select
                            ref={ref}
                            OptionbackgroundColor={"#414162"}
                            className="w-100"
                            value={value}
                            onChange={onChange}
                            onBlur={onBlur}
                            placeholder="اخترر"
                            options={vacations}
                          />
                        )}
                      />
                    </FormControl>
                  ) : (
                    <p className="text-mainText">
                      {"نوع الاجازة :"}
                      <span className="main-text p-2">
                        {data?.data?.vacation?.vactionCategoryId?.name}
                      </span>
                    </p>
                  )}
                </div>
                <div className="col-md-6  mb-4">
                  {modeSelector === EDITMODE ? (
                    <FormControl className="col-span-6" fullWidth>
                      <InputLabel label={"نوع الاجازة"} />
                      <Controller
                        name={`vactionType`}
                        control={control}
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <Select
                            onChange={onChange}
                            onBlur={onBlur}
                            ref={ref}
                            OptionbackgroundColor={"#414162"}
                            className="w-100"
                            placeholder="اختر نوع الاجازة"
                            options={VacationType}
                          />
                        )}
                      />
                    </FormControl>
                  ) : (
                    <p className="text-mainText">
                      <Checkbox checked className="text-mainText" />

                      <span className="main-text p-2">
                        {vactionTypeEnum[+data?.data?.vacation?.vactionType]}
                      </span>
                    </p>
                  )}
                </div>

                <div className="col-md-4 mb-3">
                  {modeSelector === EDITMODE ? (
                    <FormControl fullWidth>
                      <InputLabel label={"مدة الاجازة"} />

                      <CustomInput
                        control={control}
                        name="durationVaction"
                        placeholder="اكتب مدة الاجازة"
                      />
                    </FormControl>
                  ) : (
                    <p className="text-mainText">
                      {"مدة الاجازة :"}
                      <span className="main-text p-2">
                        {data?.data?.vacation?.durationVaction}
                      </span>
                    </p>
                  )}
                </div>
                <div className="col-md-4 mb-3">
                  {modeSelector === EDITMODE ? (
                    <FormControl className="col-span-6" fullWidth>
                      <InputLabel label={"من تاريخ"} />
                      <CustomDatePicker control={control} name="startDate" />
                    </FormControl>
                  ) : (
                    <p className="text-mainText">
                      {"من تاريخ : "}
                      <span className="main-text p-2">
                        {moment(data?.data?.vacation?.startDate).format(
                          "DD-MM-YYYY"
                        )}
                      </span>
                    </p>
                  )}
                </div>
                <div className="col-md-4 mb-3">
                  {modeSelector === EDITMODE ? (
                    <FormControl className="col-span-6" fullWidth>
                      <InputLabel label={"الي تاريخ"} />
                      <CustomDatePicker control={control} name="endDate" />
                    </FormControl>
                  ) : (
                    <p className="text-mainText">
                      {"الى تاريخ :"}
                      <span className="main-text p-2">
                        {moment(data?.data?.vacation?.endDate).format(
                          "DD-MM-YYYY"
                        )}
                      </span>
                    </p>
                  )}
                </div>
                <div className="col-md-12 mb-4">
                  <p className="text-mainText">
                    {"حالة الاجازة :"}
                    <span className="main-text p-2">
                      {statusEnum[data?.data?.vacation?.status]?.title}
                    </span>
                  </p>
                </div>
                {data?.data?.vacation?.resonRejected && (
                  <div className="col-md-12 mb-4">
                    <p className="text-mainText">
                      {"سبب الرفض :"}
                      <span className="main-text p-2">
                        {data?.data?.vacation?.resonRejected}
                      </span>
                    </p>
                  </div>
                )}
              </div>
            </FormModal>

            <FormModal title="موجة الى">
              <div className="row">
                {modeSelector === EDITMODE ? (
                  <div className="col-md-6 mb-4">
                    <FormControl fullWidth>
                      <InputLabel label={"تعتمد من "} />
                      <Controller
                        name={`approvalManager`}
                        control={control}
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <Select
                            value={value}
                            onChange={onChange}
                            onBlur={onBlur}
                            ref={ref}
                            OptionbackgroundColor={"#414162"}
                            className="w-100"
                            placeholder="اختر الشخص"
                            options={managers}
                          />
                        )}
                      />
                    </FormControl>
                  </div>
                ) : (
                  <Fragment>
                    <div className="col-md-6 mb-4">
                      <p className="text-mainText">
                        تعتمد من :
                        <span className="main-text p-2">
                          {`${data?.data?.vacation?.approvalManager?.firstName} ${data?.data?.vacation?.approvalManager?.lastName}`}
                        </span>
                      </p>
                    </div>

                    <div className="col-md-6  mb-4">
                      <p className="text-mainText">
                        الكود الوظيفى :
                        <span className="main-text p-2">
                          {data?.data?.vacation?.approvalManager?.employeCode}
                        </span>
                      </p>
                    </div>
                  </Fragment>
                )}
              </div>
            </FormModal>

            <FormModal title={"المرفقات"}>
              {modeSelector === EDITMODE ? (
                <CustomeInputFile
                  register={{ ...register("image") }}
                  name="image"
                  multiple={true}
                />
              ) : (
                <div className="flex justify-center items-start gap-2">
                  <ShowFiles files={data?.data?.vacation?.image} />
                  {/* <div className="col-md-12 mb-4">
                    <div className="flex gap-3  ">
                      {data?.data?.vacation?.image?.length > 0 ? (
                        data?.data?.vacation?.image?.map((img, index) => (
                          <Image
                            key={index}
                            className="w-[93px] pointer h-[61px] rounded-[7px]"
                            src={img}
                            alt="vacation image"
                            onClick={() => {
                              setImgSrc(img);
                              setShowPerview(true);
                            }}
                          />
                        ))
                      ) : (
                        <ImgIcon />
                      )}
                    </div>
                  </div> */}
                </div>
              )}
            </FormModal>

            <div
              className="flex justify-center items-center gap-3"
              // onClick={handleClose}
            >
              {modeSelector === EDITMODE ? (
                <SubmitEdit />
              ) : (
                <div className="flex items-center gap-2">
                  {+data?.data?.vacation?.status === 0 ? (
                    <>
                      {CheckRole(roles["admin"]) ||
                      CheckRole(roles["manager"]) ? (
                        <>
                          <RejectVacation id={id} closeModal={handleClose} />
                          <AcceptVacation id={id} closeModal={handleClose} />
                        </>
                      ) : null}
                      <UpdateVacation id={id} closeModal={handleClose} />

                      {!CheckRole(roles["user"]) ? (
                        <>
                          <DeleteVacation id={id} closeModal={handleClose} />
                        </>
                      ) : null}
                    </>
                  ) : (
                    <>
                      <UpdateVacation id={id} closeModal={handleClose} />
                      {!CheckRole(roles["user"]) ? (
                        <DeleteVacation id={id} closeModal={handleClose} />
                      ) : null}
                    </>
                  )}

                  {/* {data?.data?.vacation?.status != 5 && (
                    <RejectVacation id={id} closeModal={handleClose} />
                  )} */}
                  {/* 
                  <div className="flex justify-center">
                  <UpdateVacation id={id} closeModal={handleClose} />
                  </div> */}
                  {/* <DeleteVacation id={id} closeModal={handleClose} /> */}
                  {/* {data?.data?.vacation?.status != 4 && (
                    <AcceptVacation id={id} closeModal={handleClose} />
                  )} */}
                </div>
              )}
            </div>

            <SuccessfullModal
              show={showSuccess}
              handleClose={handleCloseSuccess}
              message="تم تعديل الاجازه بنجاح "
            />
            <SuccessfullModal
              show={showError}
              handleClose={handleCloseError}
              message={()=> displayErrorMessage(error)}
              status="error"
            />
          </Form>
        </Modal.Body>
      ) : (
        <Progress />
      )}
    </Modal>
  );
};

export default ShowHolidayComponent;

const DeleteVacation = ({ id, closeModal }) => {
  const { handleShow, handleClose, show } = useCustomModal();
  const {
    handleShowSuccess,
    handleShowError,
    handleCloseSuccess,
    handleCloseError,
    showSuccess,
    showError,
  } = useSuccessfullModal();
  const { mutate } = useDeleteVacation(handleShowSuccess, handleShowError);
  return (
    <Fragment>
      <button
        type="button"
        onClick={handleShow}
        className="bg-[#BA0A0A] text-white text-sm border !border-[#ba0a0a] hover:bg-[#ba0a0ade] transition-colors rounded-md py-1 px-10"
      >
        {"حذف"}
        {/* <DeleteIcon/> */}
      </button>
      <CustomModal
        title="التاكيد"
        show={show}
        message={"هل انت متاكد من حذف طلب الاجازة"}
        handleSave={() => {
          mutate(id);
        }}
        handleClose={handleClose}
      />
      <SuccessfullModal
        handleClose={() => {
          handleCloseSuccess();
          closeModal();
        }}
        message={"تم حذف طلب الاجازة بنجاح"}
        show={showSuccess}
      />
      <SuccessfullModal
        handleClose={handleCloseError}
        message={"حدث خطأ ما"}
        show={showError}
        status="error"
      />
    </Fragment>
  );
};

const SubmitEdit = ({}) => {
  const dispatch = useDispatch();
  return (
    <Fragment>
      <button
        type="submit"
        className="bg-[#19B159] text-white text-sm border !border-[#19B159] hover:bg-[#19b158de] transition-colors rounded-md py-1 px-10"
      >
        {"حفظ التعديلات"}
      </button>
      <button
        type="button"
        onClick={() => dispatch(switchMode())}
        className="bg-[#BA0A0A] text-white text-sm border !border-[#ba0a0a] hover:bg-[#ba0a0ade] transition-colors rounded-md py-1 px-10"
      >
        {"تجاهل التعديلات"}
      </button>
    </Fragment>
  );
};

const UpdateVacation = ({ id, closeModal }) => {
  const dispatch = useDispatch();
  return (
    <button
      type="button"
      onClick={() => dispatch(switchMode())}
      // className="bg-[#19B159] hover:bg-[#19B159de] text-white rounded-md py-1.5 px-2"
      className="bg-[#19B159] text-white text-sm border !border-[#19B159] hover:bg-[#19B159de] transition-colors rounded-md py-1 px-10"
    >
      تعديل
      {/* <EditIcon /> */}
    </button>
  );
};

const AcceptVacation = ({ id, closeModal }) => {
  const { handleShow, handleClose, show } = useCustomModal();
  const {
    handleShowSuccess,
    handleShowError,
    handleCloseSuccess,
    handleCloseError,
    showSuccess,
    showError,
  } = useSuccessfullModal();
  const { mutate } = useConfirmVacation(handleShowSuccess, handleShowError);
  return (
    <Fragment>
      <button
        type="button"
        onClick={handleShow}
        className="bg-[#19B159] text-white text-sm border !border-[#19B159] hover:bg-[#19B159de] transition-colors rounded-md py-1 px-10"
      >
        {"قبول"}
      </button>
      <CustomModal
        title="التاكيد"
        show={show}
        message={"هل انت متاكد من قبول طلب الاجازة"}
        handleSave={() => {
          mutate(id);
          handleClose();
        }}
        handleClose={handleClose}
      />
      <SuccessfullModal
        handleClose={() => {
          handleCloseSuccess();
          closeModal();
        }}
        message={"تم قبول طلب الاجازة بنجاح"}
        show={showSuccess}
      />
      <SuccessfullModal
        handleClose={handleCloseError}
        message={"حدث خطأ ما"}
        show={showError}
        status="error"
      />
    </Fragment>
  );
};
const RejectVacation = ({ id, closeModal }) => {
  const { handleShow, handleClose, show } = useCustomModal();
  const { showComment, handleCloseComment, handleShowComment } =
    useCommentModel();
  const {
    handleShowSuccess,
    handleShowError,
    handleCloseSuccess,
    handleCloseError,
    showSuccess,
    showError,
  } = useSuccessfullModal();
  const { mutate } = useRejectVaction(handleShowSuccess, handleShowError, id);
  return (
    <Fragment>
      <button
        type="button"
        onClick={handleShow}
        className="bg-[#BA0A0A] text-sm border !border-[#BA0A0A] hover:bg-[#ba0a0ade] text-white  transition-colors rounded-md py-1 px-10"
      >
        {"رفض"}
      </button>
      <CustomModal
        title="التاكيد"
        show={show}
        message={"هل انت متاكد من رفض طلب الاجازة"}
        handleSave={() => {
          handleShowComment();
          handleClose();
        }}
        handleClose={handleClose}
      />
      <CommentModel
        show={showComment}
        message={"اترك سبب التعليق ...."}
        name={"resonRejected"}
        handleClose={handleCloseComment}
        setComment={() => {}}
        handleSave={(data) => {
          // console.log(data);
          mutate(data);
          handleCloseComment();
        }}
      />
      <SuccessfullModal
        handleClose={() => {
          handleCloseSuccess();
          closeModal();
        }}
        message={"تم رفض طلب الاجازة بنجاح"}
        show={showSuccess}
      />
      <SuccessfullModal
        handleClose={handleCloseError}
        message={"حدث خطأ ما"}
        show={showError}
        status="error"
      />
    </Fragment>
  );
};

const ModalHeader = ({ title, handleClose }) => {
  return (
    <Modal.Header className="w-full !justify-between border-none">
      <div className=""></div>
      <Modal.Title className="text-xl text-[#EFAA20] border !border-[#EFAA20] py-1.5 px-6 rounded-[5px] font-medium ">
        {title}
      </Modal.Title>
      <Button
        className="p-0 bg-transparent hover:bg-transparent"
        onClick={handleClose}
      >
        <img src="/Rejected.svg" alt="" />
      </Button>
    </Modal.Header>
  );
};
