import { Fragment, useContext, useEffect, useState } from "react";
import style from "./EmployeesServices.module.css";
import { SearchComponent } from "../../../../Components/SearchComponent/SearchComponent.jsx";
import "./EmployeesServices.css";
import { AddHrType } from "../../../../Context/AddHr.js";
import AddServices from "../../../../Components/System/Hr/AddServices/AddServices";
import Pdf from "../../../../Components/Pdf.jsx";
import ShowServicesComponent from "../../../../Components/System/Hr/ShowServicesComponent/ShowServicesComponent.jsx";
import { TableCell } from "../../../../Components/Table/TableCell.jsx";
import { TableRow } from "../../../../Components/Table/TableRow.jsx";
import CustomTable from "../../../../Components/Table/index.jsx";
import { IoCloseOutline } from "react-icons/io5";
import Progress from "../../../../Components/Progress.jsx";
import moment from "moment";
import Image from "../../../../Components/Image.jsx";
import Cookies from "js-cookie";
import { useGetAllServiceHumanCategories } from "../../../../helper/fetchers/servicesHuman.jsx";
import { ImgIcon } from "./const.js";
import {
  useConfirmHumanResoursce,
  useDeleteHumanResoursce,
  useGetAllHumanResoursces,
  useGetHumanResoursce,
  useRejectHumanResoursce,
} from "../../../../hooks/fetchers/HumanResoursce.jsx";
import { CheckRole, GenerateColumnsNames, getType } from "../../../../helper/utils.jsx";
import { Carousel, Modal, NavDropdown } from "react-bootstrap";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import DisplayStatus from "../../../../Components/DisplayStatus.jsx";
import { BsThreeDotsVertical } from "react-icons/bs";
import { MdDeleteOutline } from "react-icons/md";
import { IoCheckmark } from "react-icons/io5";
import useSuccessfullModal from "../../../../Components/Modals/hooks/useSuccessfullModal.jsx";
import useModal from "../../../../hooks/useModal.jsx";
import LoadingPage from "../../../../Components/feedback/LoadingPage.jsx";
import CustomModal from "../../../../Components/Modals/CustomModal.jsx";
import SuccessfullModal from "../../../../Components/Modals/SuccessfullModal.jsx";
import CommentModel from "../../../../Components/Modals/CommentModel.jsx";
import { FaPrint } from "react-icons/fa";
import { Document, Page } from "react-pdf";
import config from "../../../../Config/Config.jsx";
import List from "../../../../Components/List/index.jsx";
import { roles } from "../../../../helper/fetchers/Tasks.jsx";
import PrintServiceFile from "./printServiceFile.jsx";


const columns = GenerateColumnsNames(
  "م",
  "اسم الموظف",
  "الكود الوظيفي",
  "نوع الخدمة",
  "تاريخ الخدمة",
  "عرض",
  "الحاله",
  ""
);
const EmployeesServices = () => {
  const [searchParamsRecourse, setSearchParamsRecourse] = useState({});
  const { data: allHumanResoursces } =
    useGetAllHumanResoursces(searchParamsRecourse);

  const [showServices, setShowServices] = useState(false);

  const { openHr, HrType, setHrType } = useContext(AddHrType);
  // handle open services pdf files
  const [openPdf, setOpenPdf] = useState(false);

  useEffect(() => {
    setHrType("EmployeesServices");
  }, [openHr, HrType]);

  const [id, setId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showCarsoul, setShowCarsoul] = useState(false);
  const [CarsoulImgs, setCarsoulImgs] = useState(false);
  // const [servicesHumanID, setServicesHumanId] = useState(null);
  const [search, setSearch] = useState(null);
  const [searchHumen, setSearchHumen] = useState(null);
  const [searchParams, setSearchParams] = useState({});

  // const { data } = useGetHumanResoursce(id);
  // console.log("HumanResoursce:",data);

  // const gethumanServicesId = async () => {
  //   try {
  //     setLoading(true);
  //     const { data } = await getAllServicesHumans();
  //     // console.log(data);
  //     if (data?.services) {
  //       setServicesHumanId(data.services);
  //     } else {
  //       console.log("Data retrieval failed");
  //     }
  //   } catch (error) {
  //     setError(error?.response?.data?.message);
  //     toast.error(error?.response?.data?.message);
  //   }
  //   setLoading(false);
  // };
  // useEffect(() => {
  //   gethumanServicesId();
  // }, []);
  const { data: servicesHumanID } =
    useGetAllServiceHumanCategories(searchParams);

  const handleSearch = (value) => {
    if (value) {
      setSearchParamsRecourse({ search: value });
    } else {
      setSearchParamsRecourse({});
    }
  };
  const handleSearchBar = (value) => {
    if (value) {
      setSearchParams({ search: value });
    } else {
      setSearchParams({});
    }
  };

  return (
    <>
      <Pdf
        PdfFile={process.env.PUBLIC_URL + "/example.pdf"}
        width={800}
        height={800}
        openPdf={openPdf}
        setOpenPdf={setOpenPdf}
      />
      <Example
        show={showCarsoul}
        handleClose={() => setShowCarsoul(false)}
        files={CarsoulImgs}
      />
      <ShowServicesComponent
        id={id}
        showServices={showServices}
        setShowServices={setShowServices}
      />

      {openHr && HrType == "EmployeesServices" ? (
        <AddServices />
      ) : (
        <div className="flex gap-2 h-full">
          {!CheckRole(roles["user"]) ?<div className="w-1/3">
            <div className={`${style.servicesTypesContainer} `}>
              <SearchComponent
                handleChange={handleSearchBar}
                value={searchHumen}
              />
              <p className="text-mainText mt-2">كل الخدمات</p>
              {!error && loading ? (
                <Progress />
              ) : error && !loading ? (
                <p className="text-red-500">{error}</p>
              ) : !error && !loading ? (
                <Fragment>
                  <div className="max-h-[700px] overflow-y-auto scrollbar-thin scrollbar-track-transparent scrollbar-thumb-[#EFAA20]">
                    {servicesHumanID &&
                    servicesHumanID?.services?.length > 0 ? (
                      <List
                        data={servicesHumanID?.services}
                        isLoading={loading}
                        renderItem={({ name, humanService }, index) => (
                          <VacationCard
                            name={name}
                            onClick={() => {
                              if (humanService?.length > 0) {
                                setShowCarsoul(true);
                                setCarsoulImgs(humanService);
                              }
                            }}
                            key={index}
                          />
                        )}
                      />
                    ) : (
                      <p className="text-center text-lg text-mainText py-1">
                        {"لا يوجد بيانات لعرضها"}
                      </p>
                    )}
                  </div>
                </Fragment>
              ) : null}
            </div>
          </div>: null}
          <div className="w-2/3 flex-1">
            <div
              className={`${style.allServcies} w-100 allServciesContainer p-4`}
            >
              <p className="text-mainText text-xl">كل الخدمات المخصصة </p>

              <div className="my-3">
                <div className="w-3/4 mx-auto ">
                  <SearchComponent
                    handleChange={handleSearch}
                    value={search}
                    placeholder="اسم الموظف"
                    border="border !border-[#E4A11B]"
                  />
                </div>
              </div>

              <fieldset className={`${style.allServciesTable} `}>
                <legend className="text-center text-mainText">
                  كل النماذج
                </legend>

                {/* <DataTableComponent data={data} columns={columns} /> */}
                <div className="mt-3 !max-h-[400px] h-full overflow-y-auto scrollbar-thin scrollbar-track-mainItems scrollbar-thumb-[#E4A11B]">
                  {allHumanResoursces?.human ? (
                    <CustomTable
                      columns={columns}
                      data={allHumanResoursces?.human}
                      responsive={false}
                    >
                      {allHumanResoursces?.human &&
                      allHumanResoursces?.human?.length > 0
                        ? allHumanResoursces?.human.map(
                            (
                              item,
                              index
                            ) => (
                              <TableRow
                                className={`my-2 border !border-[#efaa207f] ${
                                  index % 2 === 0 ? "bg-mainNavy" : ""
                                }`}
                                key={index}
                              >
                                <TableCell>{index + 1}</TableCell>
                                <TableCell>{item?.employId?.firstName}</TableCell>
                                <TableCell>{item?.employId?.employeCode}</TableCell>
                                <TableCell>{item?.humanServicesId?.name}</TableCell>
                                <TableCell>
                                  {moment(item?.serviceDate).format("YYYY-MM-DD")}
                                </TableCell>
                                <TableCell>
                                  <Image
                                    src={
                                      process.env.PUBLIC_URL + "/icons/view.svg"
                                    }
                                    onClick={() => {
                                      setShowServices(true);
                                      setId(item?._id);
                                    }}
                                    className="pointer"
                                    alt=""
                                  />
                                </TableCell>
                                <TableCell>
                                  <DisplayStatus status={item?.status} />
                                </TableCell>
                                <TableCell>
                                  <NavDropdown
                                    title={
                                      <BsThreeDotsVertical className="text-[#efaa20]" />
                                    }
                                    className="fs-5 w-fit"
                                    id="collapsible-nav-dropdown"
                                  >
                                    {+item?.status === 0 ? (
                                      <>
                                        <PrintVacation id={item?._id} />
                                      {CheckRole(roles["admin"]) || CheckRole(roles["manager"]) ? <>
                                        <AcceptService id={item?._id} />
                                        <RejectService id={item?._id} />
                                        </> : null }
                                      </>
                                    ) : (
                                      <>
                                        <PrintVacation item={item} />
                                        {!CheckRole(roles["user"]) ? <>
                                        <DeleteService id={item?._id} />
                                          </> : null }
                                      </>
                                    )}
                                  </NavDropdown>
                                </TableCell>
                              </TableRow>
                            )
                          )
                        : null}
                    </CustomTable>
                  ) : (
                    <Progress />
                  )}
                </div>
              </fieldset>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EmployeesServices;

function Example({ show, handleClose, files }) {
  // console.log("files:", files);
  // const handleDownload = () => {
  //   myAxiosJson
  //   .get('https://bsa2011.com:5000/upload/VacationCategory/SsTTaSFB2LHuFKBXXqY2r-download (1).jpeg',{
  //     headers: {
  //       responseType: 'blob',

  //     }
  //   })
  //   .then(res => {
  //     console.log(res);
  //   })
  //   .catch(error => {
  //     console.log(error);
  //   })
  // }
  return (
    <Modal
      // size="sm"
      centered
      show={show}
      onHide={handleClose}
      contentClassName="p-0 bg-[#151521] w-fit mx-auto"
      className="bg-black/50"
    >
      <Modal.Body className="flex flex-col items-center">
        {!files || files.length === 0 ? (
          <p className="text-white">لا يوجد ملفات</p>
        ) : (
          <div className="">
            <Carousel
              className="w-full"
              nextIcon={
                <IoIosArrowForward
                  className="bg-black/25 rounded text-[#EFAA20]"
                  size={25}
                />
              }
              prevIcon={
                <IoIosArrowBack
                  className="bg-black/25 rounded text-[#EFAA20]"
                  size={25}
                />
              }
            >
              {files.map((file, index) => {
                const fileType = getType(file);
                console.log("fileType", fileType);
                console.log("file", file);

                switch (fileType) {
                  case "jpg":
                  case "jpeg":
                  case "png":
                  case "gif":
                    return (
                      <Carousel.Item key={index}>
                        <div className="flex justify-center mb-2">
                          <a
                            href={file}
                            download={true}
                            className="bg-[#EFAA20] py-1 px-3 rounded"
                          >
                            download
                          </a>
                        </div>
                        <div className="relative">
                          <div className="carousel-overlay" />
                          <img
                            className="d-block w-100"
                            src={file}
                            alt={`slide-${index}`}
                          />
                        </div>
                      </Carousel.Item>
                    );
                  case "pdf":
                    return (
                      <Carousel.Item key={index}>
                        <div className="flex justify-center mb-2">
                          <a
                            href={file}
                            download={true}
                            className="bg-[#EFAA20] py-1 px-3 rounded"
                          >
                            download
                          </a>
                        </div>
                        <div className="relative">
                          <div className="carousel-overlay" />
                          <embed
                            src={file}
                            type="application/pdf"
                            width="400"
                            height="600"
                            className="d-block w-100"
                            alt={`slide-${index}`}
                          />
                        </div>
                      </Carousel.Item>
                    );
                  case "zip":
                    return (
                      <Carousel.Item key={index}>
                        <div className="flex justify-center mb-2">
                          <a
                            href={file}
                            download={true}
                            className="bg-[#EFAA20] py-1 px-3 rounded"
                          >
                            download
                          </a>
                        </div>
                        <div className="relative">
                          <div className="carousel-overlay" />
                          <img
                            className="d-block w-100"
                            src={
                              "https://p7.hiclipart.com/preview/258/300/47/computer-icons-zip-truetype-icon-png-file-zip.jpg"
                            }
                            alt={`slide-${index}`}
                          />
                        </div>
                      </Carousel.Item>
                    );
                  case "xlsx":
                    return (
                      <Carousel.Item key={index}>
                        <div className="flex justify-center mb-2">
                          <a
                            href={file}
                            download={true}
                            className="bg-[#EFAA20] py-1 px-3 rounded"
                          >
                            download
                          </a>
                        </div>
                        <div className="relative">
                          <div className="carousel-overlay" />
                          <img
                            className="d-block w-100"
                            src={
                              "https://logodownload.org/wp-content/uploads/2020/04/excel-logo-0.png"
                            }
                            alt={`slide-${index}`}
                          />
                        </div>
                      </Carousel.Item>
                    );
                  case "rar":
                    return (
                      <Carousel.Item key={index}>
                        <div className="flex justify-center mb-2">
                          <a
                            href={file}
                            download={true}
                            className="bg-[#EFAA20] py-1 px-3 rounded"
                          >
                            download
                          </a>
                        </div>
                        <div className="relative">
                          <div className="carousel-overlay" />
                          <img
                            className="d-block w-100"
                            src={
                              "https://cdn-icons-png.freepik.com/512/9158/9158683.png"
                            }
                            alt={`slide-${index}`}
                          />
                        </div>
                      </Carousel.Item>
                    );
                  default:
                    return null;
                }
              })}
            </Carousel>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
}

const AcceptService = ({ id }) => {
  const [message, setMessage] = useState("حدث خطا ما");
  const {
    handleShowSuccess,
    handleShowError,
    handleCloseSuccess,
    handleCloseError,
    showSuccess,
    showError,
  } = useSuccessfullModal();
  const { mutate, isLoading, isError, error } = useConfirmHumanResoursce(
    handleShowSuccess,
    handleShowError
  );
  const { open, handleOpenModal, handleCloseModal } = useModal();
  useEffect(() => {
    if (isError) {
      setMessage(error?.response?.data?.message);
    }
  }, [isError]);

  return (
    <>
      {isLoading ? <LoadingPage /> : null}
      <NavDropdown.Item
        onClick={handleOpenModal}
        className="text-end d-flex gap-1  align-items-center hover:rounded-lg hover:text-mainNavy"
      >
        <IoCheckmark size={18} className="text-black" />

        <span className="text-black">قبول</span>
      </NavDropdown.Item>

      <CustomModal
        title="التاكيد"
        show={open}
        message={"هل انت متاكد من قبول طلب الخدمة"}
        handleSave={() => {
          mutate(id);
          handleCloseModal();
        }}
        handleClose={handleCloseModal}
      />

      <SuccessfullModal
        show={showSuccess}
        message={"تم قبول الخدمة"}
        handleClose={() => {
          handleCloseSuccess();
        }}
      />
      <SuccessfullModal
        show={showError}
        message={message}
        handleClose={handleCloseError}
        status="error"
      />
    </>
  );
};
const DeleteService = ({ id }) => {
  const [message, setMessage] = useState("حدث خطا ما");
  const {
    handleShowSuccess,
    handleShowError,
    handleCloseSuccess,
    handleCloseError,
    showSuccess,
    showError,
  } = useSuccessfullModal();
  const { mutate, isLoading, isError, error } = useDeleteHumanResoursce(
    handleShowSuccess,
    handleShowError
  );
  const { open, handleOpenModal, handleCloseModal } = useModal();
  useEffect(() => {
    if (isError) {
      setMessage(error?.response?.data?.message);
    }
  }, [isError]);

  return (
    <>
      {isLoading ? <LoadingPage /> : null}
      <NavDropdown.Item
        onClick={handleOpenModal}
        className="text-end  d-flex gap-1  align-items-center hover:rounded-lg"
      >
        <MdDeleteOutline className="text-black" />
        <span className="text-black">حذف</span>
      </NavDropdown.Item>

      <CustomModal
        title="التاكيد"
        show={open}
        message={"هل انت متاكد من حذف الخدمة"}
        handleSave={() => {
          mutate(id);
          handleCloseModal();
        }}
        handleClose={handleCloseModal}
      />

      <SuccessfullModal
        show={showSuccess}
        message={"تم حذف الخدمة"}
        handleClose={() => {
          handleCloseSuccess();
        }}
      />
      <SuccessfullModal
        show={showError}
        message={message}
        handleClose={handleCloseError}
        status="error"
      />
    </>
  );
};
const RejectService = ({ id }) => {
  const [message, setMessage] = useState("حدث خطا ما");
  const {
    handleShowSuccess,
    handleShowError,
    handleCloseSuccess,
    handleCloseError,
    showSuccess,
    showError,
  } = useSuccessfullModal();
  const { mutate, isLoading, isError, error } = useRejectHumanResoursce(
    handleShowSuccess,
    handleShowError,
    id
  );
  const { open, handleOpenModal, handleCloseModal } = useModal();
  const {
    open: openComment,
    handleOpenModal: handleOpenModalComment,
    handleCloseModal: handleCloseModalComment,
  } = useModal();
  useEffect(() => {
    if (isError) {
      setMessage(error?.response?.data?.message);
    }
  }, [isError]);

  return (
    <>
      {isLoading ? <LoadingPage /> : null}

      <NavDropdown.Item
        onClick={handleOpenModal}
        className="text-end d-flex gap-1  align-items-center hover:rounded-lg hover:text-mainNavy"
      >
        <IoCloseOutline size={18} className="text-black" />

        <span className="text-black">رفض</span>
      </NavDropdown.Item>

      <CustomModal
        title="التاكيد"
        show={open}
        message={"هل انت متاكد من رفض الخدمة"}
        handleSave={() => {
          handleOpenModalComment();
          handleCloseModal();
        }}
        handleClose={handleCloseModal}
      />
      <CommentModel
        show={openComment}
        message={"اترك سبب التعليق ...."}
        name={"resoneRejected"}
        handleClose={handleCloseModalComment}
        setComment={() => {}}
        handleSave={(data) => {
          // console.log(data);
          mutate(data);
          handleCloseModalComment();
        }}
      />

      <SuccessfullModal
        show={showSuccess}
        message={"تم رفض الخدمة"}
        handleClose={() => {
          handleCloseSuccess();
        }}
      />
      <SuccessfullModal
        show={showError}
        message={message}
        handleClose={handleCloseError}
        status="error"
      />
    </>
  );
};

const PrintVacation = ({ id }) => {
  // const [pdfLoading, setPdfLoading] = useState(false);
  // const [pdfUrl, setPdfUrl] = useState("");
  const {data , isLoading , isError} = useGetHumanResoursce(id)
  // const getFinancialPdf = async (id) => {
  //   setPdfLoading(true);
  //   try {
  //     const response = await fetch(
  //       `${config.apiGateway?.URL}/human/pdf/${id}`,
  //       {
  //         method: "GET",
  //         headers: {
  //           "Content-Type": "application/pdf",
  //           authes: `BSA__${Cookies.get("accessToken")}`,
  //         },
  //       }
  //     );
  //     if (response.ok) {
  //       const blob = await response.blob();
  //       const url = URL.createObjectURL(blob);
  //       setPdfUrl(url);
  //     } else {
  //       console.error("Failed to fetch PDF:", response.statusText);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching PDF:", error);
  //   } finally {
  //     setPdfLoading(false);
  //   }
  // };
  const {
    open: openPdf,
    handleOpenModal: handleOpenPdfModal,
    handleCloseModal: handleClosePdfModal,
  } = useModal();
  return (
    <>
      <NavDropdown.Item
        onClick={handleOpenPdfModal}
        className="text-end  d-flex gap-1  align-items-center hover:rounded-lg"
      >
        <FaPrint className="text-black" />
        <span className="text-black">طباعة</span>
      </NavDropdown.Item>
      {!isLoading && !isError && data ? (
        <PrintServiceFile
          show={openPdf}
          onHide={handleClosePdfModal}
          data={data?.data?.human}
        />
      ) : null}
      {/* {(!isLoading && !isError) ?
    <a href={data?.data} download={"example.pdf"}>ffff</a>
    : null} */}

      {/* {pdfLoading ? (
        <LoadingPage />
      ) : (
        pdfUrl && (
          <Modal
            show={true}
            onHide={() => setPdfUrl("")}
            className="h-screen bg-black/50"
            contentClassName="bg-transparent"
          >
            <Modal.Body className="h-full w-full min-w-min">
              <a
                href={pdfUrl}
                download={"file.pdf"}
                className="mb-3 mx-auto block text-center !text-[#efaa20] hover:!text-[#efaa20] border !border-[#efaa20] w-fit px-4 py-1 rounded-full bg-mainNavy"
              >
                تحميل المستند
              </a>
              <Document
                file={pdfUrl}
                onLoadSuccess={() => setPdfLoading(false)}
                className={"w-full min-w-min"}
              >
                <Page pageNumber={1} className={"w-full"} />
              </Document>
            </Modal.Body>
          </Modal>
        )
      )} */}

      {/* <CustomModal
        title="التاكيد"
        show={show}
        message={"هل انت متاكد من طباعة الاجازة"}
        handleSave={() => {
          // mutate(id);
        }}
        handleClose={handleClose}
      />
      <SuccessfullModal
        handleClose={handleCloseSuccess}
        message={"تم طباعة الاجازة بنجاح"}
        show={showSuccess}
      />
      <SuccessfullModal
        handleClose={handleCloseError}
        message={"حدث خطأ ما"}
        show={showError}
        status="error"
      /> */}
    </>
  );
};

const VacationCard = ({ onClick, name }) => {
  return (
    <div className="flex items-center p-2 gap-3 my-3 w-full border !border-transparent hover:!border-[#EFAA20] pointer">
      <div className={`bg-mainNavy borderYellow p-2 rounded-md `}>
        <ImgIcon onClick={onClick} />
      </div>
      <p className="text-mainText text-sm"> {name}</p>
    </div>
  );
};
