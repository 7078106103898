import React from "react";
import { Modal } from "react-bootstrap";

const ExcellModall = ({ onHide, fileUrl, keys, data }) => {
  console.log("exc",data);
  return (
    <Modal
      show={true}
      onHide={onHide}
      className="h-screen bg-black/50 "
      contentClassName="bg-transparent"
      centered
    >
      <Modal.Body className="h-full w-full min-w-min rounded-lg bg-mainItems">
        {data && data.length ? <>
          <a
          href={fileUrl}
          download={"expenses.xlsx"}
          className="mb-3 mx-auto block text-center !text-[#efaa20] hover:!text-[#efaa20] border !border-[#efaa20] w-fit px-4 py-1 rounded-full bg-mainNavy"
        >
          تحميل المستند
        </a>
        <div className="bg-mainNavy shadow-lg p-4 rounded-lg">
          <table className="">
            <thead>
              <tr className="min-w-[100px]">
                {keys.map((key) => (
                  <th className="min-w-[8vw] text-sm text-[#efaa20]" key={key}>
                    {key}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data.map((row, index) => (
                <tr key={index}>
                  {keys.map((key) => (
                    <td className=" text-xs text-mainText py-1" key={key}>
                      {row[key] || ""}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        
        </> : <div>
          <p className="text-xl text-center !text-[#EFAA20]">
            لا يوجد بيانات
          </p>
          </div>}
        
      </Modal.Body>
    </Modal>
  );
};

export default ExcellModall;
