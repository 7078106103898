import React, { useState } from "react";
import { TableCell } from "../../../../../Components/Table/TableCell";
import { TableRow } from "../../../../../Components/Table/TableRow";
import CustomTable from "../../../../../Components/Table";
import {
  CheckRole,
  clientTypeEnum,
  convertDateFormat,
  GenerateColumnsNames,
  projectTypeEnum,
  statusEnum,
} from "../../../../../helper/utils";
import { Link } from "react-router-dom";
import SuccessfullModal from "../../../../../Components/Modals/SuccessfullModal";
import CustomModal from "../../../../../Components/Modals/CustomModal";
import { IoMdMore } from "react-icons/io";
import { IconButton, Menu, MenuItem } from "@mui/material";
import { useProjectContext } from "../../../PlanModel/context/ProjectContext";
import { StatusDisplay } from "../../../Tasks/components/StatusDisplay";
import Image from "../../../../../Components/Image";
import moment from "moment";
import CustomTableMenu from "../../../../../Components/Table/CustomTableMenu";
import { useDeletePlan } from "../../../../../hooks/fetchers/Plans";
import useSuccessfullModal from "../../../../../Components/Modals/hooks/useSuccessfullModal";
import LoadingPage from "../../../../../Components/feedback/LoadingPage";
import CustomCheckboxTable from "../../../../../Components/CustomCheckboxTable";
import { RiDeleteBin6Line } from "react-icons/ri";
import Progress from "../../../../../Components/Progress";
import TableDeleteButton from "../../../../../Components/Buttons/TableDeleteButton";
import { roles } from "../../../../../helper/fetchers/Tasks";

const columns = GenerateColumnsNames(
  "تحديد",
  "م",
  "اسم المشروع",
  "نوع المشروع ",
  "موقع المشروع",
  "نوع العميل",
  "تاريخ التسليم",

  "الحالة",
  "المسؤل",
  "عرض"
);
const ShowButton = ({ id }) => {
  return (
    <Link
      to={`/System/tasks/plans/show-project/${id}`}
      id={id}
      aria-label="show"
    >
      <Image src="/icons/view.svg" alt="" className="w-full" />
    </Link>
  );
};
function ProjectsTable() {
  // const { data } = useGetAllPlans();
  const { projects, setFilterPlansParams, isLoading, isError } =
    useProjectContext();
  const [page, setPage] = React.useState(1);
  const handleChange = (event, value) => {
    setPage(value);
    setFilterPlansParams({ page: value });
  };

  const [checked, setChecked] = useState([]);
  const {
    handleShowSuccess,
    handleShowError,
    handleCloseSuccess,
    handleCloseError,
    showSuccess,
    showError,
  } = useSuccessfullModal();
  const {
    mutate: deleteMutation,
    isLoading: deleteIsLoading,
    isError: deleteIsError,
  } = useDeletePlan(() => {
    handleShowSuccess();
    setChecked([]);
  }, handleShowError);

  const handleChecked = (val) => {
    if (checked.includes(val)) {
      setChecked(checked.filter((item) => item !== val));
    } else {
      setChecked([...checked, val]);
    }
  };
  // You have array of ids to delete

  const handleDeleteAll = () => {};
  const handleDeleteSelected = () => {
    deleteMutation({
      plans: checked,
    });
  };
  return (
    <>
      {deleteIsLoading ? <LoadingPage /> : null}
      <SuccessfullModal
        handleClose={handleCloseSuccess}
        message={"تم الحذف بنجاح"}
        show={showSuccess}
      />
      <SuccessfullModal
        handleClose={handleCloseError}
        message={"حدث خطأ ما"}
        show={showError}
        status="error"
      />
      {CheckRole(roles["admin"]) ? (
        <TableDeleteButton
          checked={checked}
          dataLength={projects?.plans?.length}
          onClickDeleteAll={handleDeleteAll}
          onClickDeleteSelected={handleDeleteSelected}
        />
      ) : null}
      <div className="mt-3 !h-[400px] overflow-y-auto scrollbar-thin scrollbar-track-mainNavy scrollbar-thumb-[#E4A11B]">
        {isError ? (
          <p className="text-red-500 text-center text-lg">يوجد خطأ ما</p>
        ) : null}
        {isLoading ? <Progress /> : null}
        <CustomTable
          columns={CheckRole(roles["admin"]) ? columns : columns.slice(1)}
          data={projects?.plans}
          paginationProps={{
            count: projects?.pages,
            page: page,
            onChange: handleChange,
          }}
        >
          {projects?.plans && projects?.plans?.length > 0
            ? projects?.plans.map((item, index) => {
                const managers = item?.assignTo?.map((manager) => ({
                  value: `${manager?.firstName} ${manager?.lastName}`,
                }));
                // console.log(managers);
                return (
                  <TableRow
                    className={`my-2 border !border-[#efaa207f] ${
                      index % 2 === 0 ? "bg-mainNavy" : ""
                    }`}
                    key={item?._id}
                  >
                    {CheckRole(roles["admin"]) ? (
                      <TableCell>
                        <CustomCheckboxTable
                          isChecked={checked?.some((x) => x === item?._id)}
                          handleClick={() => handleChecked(item?._id)}
                        />
                      </TableCell>
                    ) : null}
                    <TableCell>{(page - 1) * 10 + (index + 1)}</TableCell>
                    <TableCell>{item?.projectName || "--------"}</TableCell>
                    <TableCell>
                      {projectTypeEnum[item?.projectType] || "--------"}
                    </TableCell>
                    <TableCell>
                      {item?.buildingLocation || "--------"}
                    </TableCell>
                    <TableCell>
                      {item?.isExist
                        ? clientTypeEnum[+item?.clientType]
                        : item?.clientType}
                    </TableCell>

                    <TableCell>
                      {moment(item?.startDate).format("YYYY-MM-DD") ||
                        "--------"}
                    </TableCell>

                    <TableCell>
                      <StatusDisplay
                        title={statusEnum[+item?.status]?.title || "--------"}
                        color={statusEnum[+item?.status]?.color}
                      />
                    </TableCell>
                    {/* <TableCell>{projectId?.ownerName || "--------"}</TableCell> */}
                    <TableCell>
                      <CustomTableMenu
                        items={managers}
                        maxItems={2}
                        renderItem={item?.assignTo.map((item, index) => (
                          <div
                            key={index}
                            className="flex flex-col gap-2 text-[10px] font-semibold min-w-[200px] [filter:_drop-shadow(2px_2px_2px_rgba(0,0,0,0.2));]"
                          >
                            <div className="">
                              <p>{item.firstName}</p>
                            </div>
                            <div className="flex gap-1 items-center text-[#D59921]">
                              <p>{item.role}</p> /<p>{item.country}</p>
                            </div>
                          </div>
                        ))}
                      />
                      {/* <div className="flex flex-col justify-center items-start">
                        {assignTo?.map((manager) => (
                          <p>{manager?.firstName + " " + manager?.lastName}</p>
                        ))}
                      </div> */}
                    </TableCell>
                    <TableCell>
                      <div className="flex justify-between items-start gap-1 ">
                        <ShowButton id={item?._id} />
                        {/* <OptionsButton id={_id} /> */}
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })
            : null}
        </CustomTable>
      </div>
    </>
  );
}

export default ProjectsTable;
