import React, { useState } from 'react'
import Slider from "react-slick";
import { ProgressBar } from "react-bootstrap";

const AllDeprtmentSlider = ({ chartData }) => {
    const [mergedArray, setMergedArray] = useState([]);
    const settings = {
      slidesToShow: 5,
      slidesToScroll: 5,
  
      cssEase: "linear",
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 5,
          },
        },
        {
          breakpoint: 1000,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 760,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
  
    return (
      <Slider
        className="systemDeprtamentSlider allDepetmentChart d-flex mx-auto"
        {...settings}
      >
        {chartData?.map((data, index) => (
          <div
            className=" d-flex flex-column mb-2 deprtament-slider-Container mx-auto"
            key={index}
          >
            <h2 style={{ color: "var(--main-text)", fontSize: "12px" }}>
              {data?.label}
            </h2>
            <p className="main-text">{data?.value}</p>
            <ProgressBar
              className="w-50  sliderProgressBar "
              dir="rtl"
              now={data?.value}
            />
          </div>
        ))}
      </Slider>
    );
  };

export default AllDeprtmentSlider