import Button from "react-bootstrap/Button";
import { FormControl, TextField } from "@mui/material";
import Modal from "react-bootstrap/Modal";
import { InputLabel } from "../../Pages/System/PlanModel/components/InputLabel";
import { useForm } from "react-hook-form";
import { Form } from "react-bootstrap";

function RejectModel({
  message,
  show,
  handleClose,
  handleSave,
  isRejectComment,
  isOnlyComment,
}) {
  const { register, handleSubmit } = useForm();
  const onSubmit = (data) => {
    handleSave(data);
  };
  return (
    <>
      {show && (
        <div
          className="fixed top-0 left-0 w-full h-full bg-black opacity-30 z-[892] "
          onClick={() => handleClose(false)}
        />
      )}
      <Modal
        centered
        contentClassName="bg-mainItems border p-2 !max-w-[700px] !border-[#EFAA20] !rounded-[20.27px] text-mainText"
        show={show}
        onHide={handleClose}
        size="lg"
      >
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={isOnlyComment ? "w-full" : ""}
        >
          <Modal.Body>
            <FormControl fullWidth>
              <InputLabel
                id={isRejectComment ? "rejectComment" : "reasonRejected"}
                label={"اترك سبب الرفض"}
                size={20}
              />
              <TextField
                size="small"
                id={isRejectComment ? "rejectComment" : "reasonRejected"}
                multiline
                rows={4}
                {...register(
                  isRejectComment ? "rejectComment" : "reasonRejected"
                )}
                placeholder={message}
                // onChange={(e) => setComment && setComment(e.target?.value)}
                variant="outlined"
                sx={{
                  my: 1,
                  color: "var(--main-text)",
                  border: "1px solid #EFAA2080",
                  "& fieldset": {
                    border: "none",
                  },
                }}
                inputProps={{
                  sx: {
                    color: "var(--main-text)",
                    py: "10px",
                  },
                }}
                className="text-mainText bg-mainNavy rounded-[7px]"
              />
            </FormControl>
            {!isOnlyComment && (
              <FormControl fullWidth>
                <Form.Group className={``} controlId="formBasicImage">
                  <div className="flex flex-col gap-2 w-full">
                    <InputLabel
                      id="rejectAttachments"
                      label={"اترك سبب الرفض"}
                      size={20}
                    />
                    <Form.Control
                      type="file"
                      name="imageFile"
                      multiple={true}
                      htmlFor="formFile"
                      className={`chooseFile text-mainText`}
                      {...register("rejectAttachments")}
                    />
                  </div>
                </Form.Group>
                {/* <input  id="rejectAttachments" {...register("rejectAttachments")} /> */}

                {/* <TextField
                size="small"
                type="file"
                multiple
                {...register(fileName)}
                // onChange={(e) => setComment && setComment(e.target?.value)}
                variant="outlined"
                sx={{
                  my: 1,
                  border: "1px solid #EFAA2080",
                  "& fieldset": {
                    border: "none",
                  },
                }}
                inputProps={{
                  sx: {
                    color: "white",
                    py: "10px",
                  },
                }}
                className="text-mainText bg-[#2B2B40] rounded-[7px]"
              /> */}
              </FormControl>
            )}
          </Modal.Body>
          <Modal.Footer className="border-none">
            <Button
              className="mx-auto py-1 px-14 font-semibold text-[13px] text-white bg-[#EFAA20] hover:bg-[#EFAA20]"
              type="submit"
            >
              حفظ
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
}

export default RejectModel;
