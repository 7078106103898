import { useEffect, useRef, useState } from "react";

import { toBlob } from "html-to-image";
import SectionElement from "../../../../Components/Preview/components/SectionElement";
import PdfPreview from "../../../../Components/Preview/CustomPdfPreview";
import TableElement from "../../../../Components/Preview/components/Table";
import {
  clientTypeEnum
} from "../../../../helper/utils";
import PdfDocument from "./PdfDocument";
import { columnsClientsInfo } from "../consts";




const SliceData = (start = 0, sliceLength = 10, data = []) => {
  let result = [];
  for (let i = start; i < data.length; i += sliceLength) {
      result.push(data.slice(i, i + sliceLength));
  }
  return result;
};


const PrintClientsDetailsFile = ({ show, onHide, data }) => {
  const [transformedData,setTransformedData] = useState([])
  const refs = useRef([]);
  const [imageUrls, setImageUrls] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (show) {
      const captureImages = async () => {
        setLoading(true);
        const blobs = await Promise.all(
          refs.current.map((ref) => toBlob(ref, { pixelRatio: 5 }))
        );
        const urls = blobs.map((blob) => URL.createObjectURL(blob));
        setImageUrls(urls);
        setLoading(false);
      };

      captureImages();
    }
  }, [show]);



  useEffect(()=>{
    setTransformedData(SliceData(10,15,data?.clients))
  },[data])
  return (
    <>
      <PdfPreview show={show} onHide={onHide}>
        {imageUrls.length > 0 && (
          <PdfPreview.DownloadButton
            document={<PdfDocument imageUrls={imageUrls} />}
            loading={loading}
          />
        )}

        <div ref={(el) => (refs.current[0] = el)} className="rounded-t w-full">
          <PdfPreview.Header
            // createdAt={`تاريخ الانشاء : ${moment(item?.createdAt).format("YYYY-MM-DD")}`}

            titleAr={"العملاء"}
            titleEn={"Clients"}
          />

          <PdfPreview.Body>
            <div className="flex justify-between items-center">
              <PdfPreview.SectionTitle title={"معلومات العملاء"} />
              <SectionElement
                title="عدد كل العملاء"
                disc={data?.clientsData?.total[0]?.total}
                className="w-1/2 gap-0 px-2"
              />
            </div>

            <PdfPreview.SectionWrapper>
              <SectionElement title="عدد عملاء التصميم" disc={data?.clientsData?.design[0]?.total} />
              <SectionElement title="عدد عملاء الفردى" disc={data?.clientsData?.total[0]?.single} />
              <SectionElement title="عدد عملاء الاشراف" disc={data?.clientsData?.supervising[0]?.total} />
              <SectionElement title="عدد عملاء شركه - مؤسسه" disc={data?.clientsData?.total[0]?.company} />
              <SectionElement title="عدد عملاء حكومي - مستسمر" disc={data?.clientsData?.total[0]?.goverment} />
            </PdfPreview.SectionWrapper>

            <div className="">
              <PdfPreview.SectionTitle title={"بيانات العملاء "} />
              <PdfPreview.SectionWrapper>
                <div className="col-span-2">
                  <TableElement
                    columns={columnsClientsInfo}
                    data={data?.clients}
                  >
                    {data?.clients
                      ?.slice(0, 10)
                      ?.map((props, index) => (
                        <ClientTableRow {...props} index={index} key={index} />
                      ))}
                  </TableElement>
                </div>
              </PdfPreview.SectionWrapper>
            </div>
          </PdfPreview.Body>
        </div>

      {transformedData?.map((group,groupIndex)=>(
        <div
        ref={(el) => (refs.current[groupIndex+1] = el)}
        className="rounded-t w-full"
      >
        <PdfPreview.Header
          // createdAt={`تاريخ الانشاء : ${moment(item?.createdAt).format("YYYY-MM-DD")}`}

          titleAr={"العملاء"}
          titleEn={"Clients"}
        />

        <PdfPreview.Body>
          <div className="">
            <PdfPreview.SectionTitle title={"بيانات العملاء "} />
            <PdfPreview.SectionWrapper>
              <div className="col-span-2">
                <TableElement
                  columns={columnsClientsInfo}
                  data={group}
                >
                  {group
                    ?.map((props, index) => (
                      <ClientTableRow
                        {...props}
                        index={((groupIndex * 15) + index) + 10}
                        key={((groupIndex * 15) + index) + 10}
                      />
                    ))}
                </TableElement>
              </div>
            </PdfPreview.SectionWrapper>
          </div>
        </PdfPreview.Body>
      </div>
      ))}                


      </PdfPreview>
    </>
  );
};













const ClientTableRow = ({ index, ownerName, clientType, phone }) => {
  return (
    <TableElement.TableRow className={`my-2`}>
      <TableElement.TableCell>{index + 1}</TableElement.TableCell>
      <TableElement.TableCell>{`${ownerName}`}</TableElement.TableCell>
      <TableElement.TableCell>
        {clientTypeEnum[clientType]}
      </TableElement.TableCell>
      <TableElement.TableCell>
        <span dir="rtl">{phone}</span>
      </TableElement.TableCell>
    </TableElement.TableRow>
  );
};

export default PrintClientsDetailsFile;
