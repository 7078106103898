import React, { useEffect, useState } from "react";
import Clock from "react-clock";

const ReminderPageClock = () => {
  const [value, setValue] = useState(new Date());
  useEffect(() => {
    const interval = setInterval(() => setValue(new Date()), 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className="h-full relative">
      <Clock
        value={value}
        renderNumbers={true}
        renderMinuteMarks={false}
        className="clockContainer"
        hourHandWidth={2}
        hourMarksWidth={2}
        minuteMarksWidth={2}
        minuteHandWidth={2}
      />
      <div className="bg-mainText w-3 h-3 absolute top-[48%] left-[48%]  rounded-full" />
    </div>
  );
};

export default ReminderPageClock;
