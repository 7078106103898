import React, { useContext, useState } from "react";
import SystemControler from "../../../../Components/System/SystemControler/SystemControler";
import { showProjectContext } from "../../../../Context/showProject";
import BackButton from "../../../../Components/BackButton";
import { IoIosArrowDown } from "react-icons/io";
import AllProjectDropdown from "./AllProjectDropdown";
import { useNavigate } from "react-router-dom";

const CustomHeader = ({ handleOpen, handleClose, view, active, setActive }) => {
  const { showProjectFlag, setShowProjectFlag } =
    useContext(showProjectContext);
  const [open, setOpen] = useState(false);
const navigate = useNavigate()
  return (
    <SystemControler
      child={
        <div className="flex items-center gap-4 relative ">
          {view ? (
            <p onClick={handleClose} className="pointer text-mainText">
              <BackButton />
              <span className="main-text mr-4">إضافة جديدة</span>
              <span className="text-mainText"> / المشاريع </span>
            </p>
          ) : showProjectFlag ? (
            <p
              onClick={() => setShowProjectFlag(false)}
              className="pointer text-mainText"
            >
              <svg
                className="mx-3"
                xmlns="http://www.w3.org/2000/svg"
                width="9"
                height="15"
                viewBox="0 0 9 15"
                fill="none"
              >
                <path
                  d="M8.52681 7.88703C8.7714 7.68698 8.7714 7.31302 8.52681 7.11297L0.816557 0.806657C0.490077 0.539626 2.88033e-07 0.771911 3.0647e-07 1.19369L8.57785e-07 13.8063C8.76222e-07 14.2281 0.490078 14.4604 0.816558 14.1933L8.52681 7.88703Z"
                  fill="white"
                />
              </svg>
            </p>
          ) : (
            <div className=" ">
              <BackButton />
              <button
                // onClick={handleOpen}
                onClick={()=>{
                  navigate(`/System/Projects/new-project`)
                }}
                className="add-user-button customButton mr-4"
              >
                إضافة جديدة
              </button>
            </div>
          )}
          <button
            className="px-8 py-2 rounded-xl bg-mainNavy text-mainText flex gap-2 items-center justify-between xl:hidden "
            onClick={() => setOpen(!open)}
          >
            كل المشاريع
            <IoIosArrowDown className="text-mainText text-xl" />
          </button>
          {open && (
            <AllProjectDropdown
              setOpen={setOpen}
              active={active}
              setActive={setActive}
            />
          )}
        </div>
      }
    />
  );
};

export default CustomHeader;
