import React, { useContext, useState, useEffect } from "react";
import TechnicalSupportChildTop from "./components/TechnicalSupportChildTop";
import { TechnicalSupportContext } from "./components/TechnicalSupportProvider";
import TechnicalSupportOrderTable from "./components/TechnicalSupportOrderTable";
import {
  useGetAllPurchase,
  useGetStatsPurchase,
} from "../../../hooks/fetchers/TechnicalSupportPurcheseOrder";
import { useParams } from "react-router-dom";
import TechnicalSupportShowOrder from "./components/TechnicalSupportShowOrder";
import LoadingPage from "../../../Components/feedback/LoadingPage";
import DashboardPieChart from "../../../Components/charts/DashboardPieChart";

const TechnicalSupportOrders = () => {
  const [search, setSearch] = useState("");

  const [page, setPage] = useState(1);
  const { type, status } = useParams();

  const {
    data: purchaseData,
    isLoading: isPurchaseLoading,
    isError: isPurchaseError,
  } = useGetAllPurchase(type || null, {
    status:
      status === "waiting"
        ? "0"
        : status === "inprogress"
        ? "1"
        : status === "done-paid"
        ? "2"
        : status === "rejected"
        ? "5"
        : null,
    page,
    search,
  });

  const { data: stats, isLoading: statsLoading } = useGetStatsPurchase({});
  const { showOrdersOpen } = useContext(TechnicalSupportContext);
  const title = "كل الطلبات";


  const formatPercentage = (value) =>
    `${Math.round((value / stats.TOTAL) * 100)}%` || `0%`;
  return statsLoading ? (
    <LoadingPage />
  ) : showOrdersOpen ? (
    <TechnicalSupportShowOrder />
  ) : (
    <div className="h-full bg-mainItems rounded-[22px]">
      {!status && <TechnicalSupportChildTop data={stats} />}
      {status && (
        <div className="bg-mainItems border !border-[#efaa207f] rounded-[22px] px-3 mb-5 flex py-4  items-center justify-center gap-4">
          {status === "waiting" && (
            <div className="flex flex-col gap-2">
              <div className="flex items-center justify-center gap-4">
                <div className="flex gap-2">
                  <div className="w-6 h-6 rounded-md bg-[#FEB019]" />
                  <p className="font-normal text-sm text-mainText">
                    في الانتظار
                  </p>
                </div>
                <p className="font-normal text-sm text-mainText">
                  {formatPercentage(stats?.PENDING)}
                </p>
              </div>
              <DashboardPieChart
                label={title}
                colors={["#FEB019", "#424279"]}
                width={230}
                labels={["في الانتظار", "الكل"]}
                series={[stats?.PENDING, stats.TOTAL]}
              />
            </div>
          )}
          {status === "inprogress" && (
            <div className="flex flex-col gap-2">
              <div className="flex items-center justify-center gap-4">
                <div className="flex gap-2">
                  <div className="w-6 h-6 rounded-md bg-[#424279]" />
                  <p className="font-normal text-sm text-mainText">
                    قيد التنفيذ
                  </p>
                </div>
                <p className="font-normal text-sm text-mainText">
                  {formatPercentage(stats?.IN_PROGRESS)}
                </p>
              </div>
              <DashboardPieChart
                label={title}
                colors={["#008FFB", "#424279"]}
                width={230}
                labels={["قيد التنفيذ", "الكل"]}
                series={[stats?.IN_PROGRESS, stats.TOTAL]}
              />
            </div>
          )}
          {status === "done-check" && (
            <div className="flex flex-col gap-2">
              <div className="flex items-center justify-center gap-4">
                <div className="flex gap-2">
                  <div className="w-6 h-6 rounded-md bg-[#FF4560]" />
                  <p className="font-normal text-sm text-mainText">تم الفحص</p>
                </div>
                <p className="font-normal text-sm text-mainText">
                  {formatPercentage(stats?.FIXED)}
                </p>
              </div>
              <DashboardPieChart
                label={title}
                colors={["#FF4560", "#424279"]}
                width={230}
                labels={["تم الفحص", "الكل"]}
                series={[stats?.FIXED, stats.TOTAL]}
              />
            </div>
          )}
          {status === "completed" && (
            <div className="flex flex-col  gap-2">
              <div className="flex items-center justify-center gap-4">
                <div className="flex gap-2">
                  <div className="w-6 h-6 rounded-md bg-[#00E396]" />
                  <p className="font-normal text-sm text-mainText">مكتمل</p>
                </div>
                <p className="font-normal text-sm text-mainText">
                  {formatPercentage(stats?.COMPLETED)}
                </p>
              </div>
              <DashboardPieChart
                label={title}
                colors={["#00E396", "#424279"]}
                width={230}
                labels={["مكتمل", "الكل"]}
                series={[stats?.COMPLETED, stats.TOTAL]}
              />
            </div>
          )}
        </div>
      )}
      <TechnicalSupportOrderTable
        data={purchaseData}
        isLoading={isPurchaseLoading}
        isError={isPurchaseError}
        search={search}
        setSearch={setSearch}
        page={page}
        setPage={setPage}
        status={
          status === "waiting"
            ? "0"
            : status === "inprogress"
            ? "1"
            : status === "done-paid"
            ? "2"
            : status === "rejected"
            ? "5"
            : null
        }
      />
    </div>
  );
};

export default TechnicalSupportOrders;
