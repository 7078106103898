import { createContext, useState } from "react";

export const CategoryContext = createContext()
export const CategoryProvider = ({children}) => {
    const [categoryId, setCategoryId] = useState(null);
    const [optionId, setOptionId] = useState(null);
    return (<CategoryContext.Provider value={{categoryId, setCategoryId,optionId, setOptionId}}>
        {children}
    </CategoryContext.Provider>
  );
};
