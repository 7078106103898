import React from 'react'

const ProgressBar = ({ progress }) => {
    return (
      <div className="w-full h-4 bg-gray-200 rounded-full mb-1">
        <div
          className="h-full rounded-full bg-[#EFAA20]"
          style={{ width: `${progress}%` }}
        ></div>
      </div>
    );
  };

export default ProgressBar