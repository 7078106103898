import { toast } from "react-toastify";
import { myAxiosJson } from "../https";

export const getSubServices = (serviceId) =>
  myAxiosJson(`services/${serviceId}/subservices/`).then(data => data?.data?.subservices).catch(error=>{
    toast.error(error?.response?.data?.message)
});
export const getSubServiceById = (serviceId,subId) =>
  myAxiosJson(`services/${serviceId}/subservices/${subId}`).then(data => data?.data?.subServices).catch(error=>{
    toast.error(error?.response?.data?.message)
});

export const addSubService = (serviceId, data) =>
  myAxiosJson.post(`services/${serviceId}/subservices`, data);

export const updateSubService = (serviceID,subId, data) =>
  myAxiosJson.patch(`services/${serviceID}/subservices/${subId}`, data);



export const deleteSubService = ([serviceID, subId]) => myAxiosJson.delete(`services/${serviceID}/subservices/${subId}`);
