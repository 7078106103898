import { createContext, useContext } from "react";
import Error from "../../../Errors/ErrorPage";
import { useGetRequestsWithid } from "../../../../hooks/fetchers/Requests";
import useEditRequest from "./hooks/useEditRequest";

const RequestContext = createContext(null);



const RequestProvider = ({ children, id }) => {
  const { data: request } = useGetRequestsWithid(id);
  const editActions = useEditRequest();
  return (
    <RequestContext.Provider value={{ request, id , editActions}}>
      {children}
    </RequestContext.Provider>
  );
};
export const useRequestContext = () => {
  const ctx = useContext(RequestContext);
  if (!ctx) {
    throw Error("you must use inside context");
  }
  return ctx;
};

export default RequestProvider;
