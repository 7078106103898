import { Fragment, useContext, useEffect, useState } from "react";

import { UserProvider } from "../../../../Context/userProvider";
import { getUserProfile } from "../../../../helper/fetchers/Profie";
import SystemControler from "../../../../Components/System/SystemControler/SystemControler";
import Progress from "../../../../Components/Progress";

export const DashboradPageWrapper = ({ children }) => {
  const { user: userDetails } = useContext(UserProvider);
  const [user, setUser] = useState();
  useEffect(() => {
    const getUser = async () => {
      try {
        const { data } = await getUserProfile();
        if (data?.success) {
          setUser(data.user);
        } else {
        }
      } catch (error) {
        console.log(error);
      }
    };
    getUser();
  }, []);
  return (
    <Fragment>
      <SystemControler
        child={
          userDetails ? (
            <div>
              <h2 className="text-mainText text-end text-2xl fw-bold">
                مرحباً بك {user?.firstName} 👋
              </h2>
              <p className="text-[#EFAA20] text-xl">
                مرحباً بعودتك الي النظام الخاص بك
              </p>
            </div>
          ) : (
            <Progress />
          )
        }
      />

      {children}
    </Fragment>
  );
};
