import React, { useContext, useState } from "react";
import { Modal, NavDropdown } from "react-bootstrap";
import * as XLSX from "xlsx";

import { FilterIcon } from "../../../../utiltis/Icons";
import { AllClientContext } from "./PageWrapperAllCLients";
// import { useGetExportAllClients } from "../../../../hooks/fetchers/Clients";
import useSuccessfullModal from "../../../../Components/Modals/hooks/useSuccessfullModal";
import SuccessfullModal from "../../../../Components/Modals/SuccessfullModal";
import Cookies from "js-cookie";
import axios from "axios";
import config from "../../../../Config/Config";
import LoadingPage from "../../../../Components/feedback/LoadingPage";
import { BsThreeDotsVertical } from "react-icons/bs";
export default function FilterClients() {
  const { setFilterKey } = useContext(AllClientContext);
  const [fileUrl, setFileUrl] = useState(null);
  return (
    <div className="d-flex justify-content-between mt-4 w-100">
      <OptionsButton setFileUrl={setFileUrl} />

      <button
        onClick={() => {
          setFilterKey({});
        }}
        className="pointer"
      >
        <p className=" text-mainText px-2">كل العملاء</p>
      </button>
      {/* {fileUrl ? <a href={fileUrl} download>
        download
      </a> : null} */}
      <NavDropdown title={<FilterIcon />} className="fs-5 ">
        <NavDropdown.Item
          className="text-end flex justify-between items-center"
          onClick={() => {
            setFilterKey({ clientType: 3 });
          }}
        >
          <span className="text-black"> فردي</span>
        </NavDropdown.Item>
        <NavDropdown.Item
          className="text-end d-flex justify-content-between align-items-center"
          onClick={() => {
            setFilterKey({ clientType: 2 });
          }}
        >
          <span className="text-black"> شركة أو مؤسسة</span>
        </NavDropdown.Item>

        <NavDropdown.Item
          className="text-end d-flex justify-content-between align-items-center"
          onClick={() => {
            setFilterKey({ clientType: 1 });
          }}
        >
          <span className="text-black"> حكومي أو مستثمر</span>
        </NavDropdown.Item>
      </NavDropdown>
    </div>
  );
}

const OptionsButton = () => {
  const {
    handleShowSuccess,
    handleShowError,
    handleCloseSuccess,
    handleCloseError,
    showSuccess,
    showError,
  } = useSuccessfullModal();
  // const { mutate } = useGetExportAllClients(handleShowSuccess, handleShowError)
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  // const getFinancialPdf = async (id) => {
  //   setPdfLoading(true);
  //   try {
  //     const response = await fetch(
  //       `${config.apiGateway?.URL}/expenses/pdf/${id}`,
  //       {
  //         method: "GET",
  //         headers: {
  //           "Content-Type": "application/pdf",
  //           authes: `BSA__${Cookies.get("accessToken")}`,
  //         },
  //       }
  //     );
  //     if (response.ok) {
  //       const blob = await response.blob();
  //       const url = URL.createObjectURL(blob);
  //       setPdfUrl(url);
  //     } else {
  //       console.error("Failed to fetch PDF:", response.statusText);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching PDF:", error);
  //   } finally {
  //     setPdfLoading(false);
  //   }
  // };
  const [excelData, setExcelData] = useState();
  const [fileUrl, setFileUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [allKeys, setAllKeys] = useState([]);

  const handleExport = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${config.apiGateway.URL}/client/export`,
        {
          responseType: "arraybuffer",
          headers: {
            "Content-Type": "application/excel",
            authes: `BSA__${Cookies.get("accessToken")}`,
          },
        }
      );

      if (response.status === 200) {
        const data = new Uint8Array(response.data);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet);
        const keysSet = new Set();
        jsonData.forEach((row) => {
          Object.keys(row).forEach((key) => keysSet.add(key));
        });

        setAllKeys(Array.from(keysSet));

        setExcelData(jsonData);

        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = URL.createObjectURL(blob);
        setFileUrl(url);
        setLoading(false);
      } else {
        setLoading(false);

        console.error("Failed to fetch Excel:", response.statusText);
      }
    } catch (error) {
      setLoading(false);

      console.error("Error fetching Excel:", error);
    }
  };

  return (
    <>
      <NavDropdown
        title={<BsThreeDotsVertical className="text-mainText" />}
        className="fs-5"
        id="collapsible-nav-dropdown"
      >
        <NavDropdown.Item
          onClick={() => handleExport()}
          className="text-end  d-flex justify-content-between  align-items-center hover:rounded-lg"
        >
          <span className="text-black hover:text-black">تصدير Excel</span>
        </NavDropdown.Item>
      </NavDropdown>
      {loading ? (
        <LoadingPage />
      ) : (
        excelData && (
          <Modal
            show={true}
            onHide={() => setExcelData("")}
            className="h-screen bg-black/50 "
            contentClassName="bg-transparent"
          >
            <Modal.Body className="h-full w-full min-w-min rounded-lg bg-mainItems">
              <a
                href={fileUrl}
                download={"clients.xlsx"}
                className="mb-3 mx-auto block text-center !text-[#efaa20] hover:!text-[#efaa20] border !border-[#efaa20] w-fit px-4 py-1 rounded-full bg-mainNavy"
              >
                تحميل المستند
              </a>
              <div className="bg-mainNavy shadow-lg p-4 rounded-lg">
                <table className="">
                  <thead>
                    <tr className="min-w-[100px]">
                      {allKeys.map((key) => (
                        <th
                          className="min-w-[8vw] text-sm text-mainText"
                          key={key}
                        >
                          {key}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {excelData.map((row, index) => (
                      <tr key={index}>
                        {allKeys.map((key) => (
                          <td
                            className=" text-xs text-mainCustomGray py-1"
                            key={key}
                          >
                            {row[key] || ""}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </Modal.Body>
          </Modal>
        )
      )}
      <SuccessfullModal
        show={showSuccess}
        message={"تم تصدير ملف العملاء بنجاح"}
        handleClose={() => {
          handleCloseSuccess();
        }}
      />
      <SuccessfullModal
        show={showError}
        message={"حدث خطأ ما"}
        handleClose={handleCloseError}
        status="error"
      />
    </>
  );
};
