import React, { useState } from "react";
import InputEmoji from "react-input-emoji";
import Picker from "emoji-picker-react";
import { MdOutlineEmojiEmotions } from "react-icons/md";
const TestingEmoji = () => {
  return (
    <div>
      <div className="container">
        {/* <TestOne /> */}
        <TestTwo />
      </div>
    </div>
  );
};

const TestOne = () => {
  const [value, setValue] = useState("");
  const handleEnter = (val) => {
    console.log({ message: val });
  };
  return (
    <div className="">
      <InputEmoji
        value={value}
        onChange={setValue}
        cleanOnEnter
        onEnter={handleEnter}
        borderRadius="26"
        borderColor="00FFFFFF"
        inputClass="!border-transparent text-mainText text-start bg-mainNavy scrollbar-none"
        placeholder="ارسل رسالة"
        theme="dark"
        // ref={inputRef}
      />
    </div>
  );
};
const TestTwo = () => {
  const [inputString, setInputString] = useState("");
  const [showPicker, setShowPicker] = useState(false);
  const onEmojiClick = (emojiObject, event) => {
    console.log("event", event);
    console.log("emojiObject", emojiObject);
    setInputString((prev) => prev + emojiObject.emoji);
    setShowPicker(false);
  };
  return (
    <div className=" flex items-center w-full relative">
      <input
        type="text"
        value={inputString}
        onChange={(e) => setInputString(e.target.value)}
        className="w-full outline-none py-2 px-7 rounded border mb-2"
      />
      <button
        onClick={() => setShowPicker(!showPicker)}
        className="absolute top-2 right-1"
      >
        <MdOutlineEmojiEmotions size={25} />
      </button>
      {showPicker && (
        <Picker onEmojiClick={onEmojiClick} style={{ width: "100%" }} />
      )}
    </div>
  );
};

export default TestingEmoji;
