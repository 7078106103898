import { Grid, Box } from "@mui/material";
export const Column = ({ children, ...props }) => {
  return (
    <Grid item {...props}>
      <Box className="bg-mainItems rounded-[19px] h-full flex flex-col gap-2">
        {children}
      </Box>
    </Grid>
  );
};
