export const getErrorMessages = (
    lang,
    fieldEn = "field",
    fieldAr = "الحقل",
    min = 4,
    max = 5
  ) => {
    //Handle messages from system languages
    const messages = {
      en: {
        "any.required": `${fieldEn} field is required`,
        "string.empty": `${fieldEn} can not be empty`,
        "string.pattern.base": `${fieldEn} Invalid format`,
        "string.min": `${fieldEn} Must be at least ${min} characters`,
        "string.max": `${fieldEn} Must be at least ${max} characters`,
        "date.base": "Invalid date format",
        "number.base": `${fieldEn} Must be a number`,
        "number.valid": "Invalid value",
        "object.base": `${fieldEn} Must be an object`,
        "array.base": `${fieldEn} Must be an array`,
        "array.max": `${fieldEn} Must contain at most ${max} items`,
      },
      ar: {
        "any.required": `${fieldAr} مطلوب`,
        "string.empty": `لا يمكن أن يكون ${fieldAr} فارغًا`,
        "string.pattern.base": `تنسيق ${fieldAr} غير صالح`,
        "string.min": `يجب أن يكون ${fieldAr} على الأقل ${min} حروف`,
        "string.max": `يجب أن يكون على الأكثر ${max} حروف`,
        "date.base": `تنسيق التاريخ غير صالح`,
        "number.base": `${fieldAr} يجب أن يكون رقما`,
        "number.valid": `قيمة ال ${fieldAr} غير صالحة`,
        "object.base": "يجب أن يكون كائنًا",
        "array.base": "يجب أن يكون مصفوفة",
        "array.max": `${fieldAr} يجب ان تحتوي علي  ${max} عناصر حد اقصي`,
      },
    };
    return messages[lang] || messages.ar;
  };

// validationFiled
// import joi from "joi";
//   ///^([A-Za-z]+|[\u0600-\u06FF]+)$/
//   export const validationsFelid = {
//     email: joi.string().email(),
//     password: joi
//       .string()
//       .pattern(new RegExp(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/))
//       .required(), //Eslamehab2022
//     id: joi.string().min(24).max(24),
//     userName: joi.string().min(4).max(30).alphanum().required(),
//     cPassword: joi.string().valid(joi.ref("password")).required(),
//     phone: joi
//       .string()
//       .pattern(
//         // new RegExp(/^(?:\+?966|01)?[0-9]\d{8}$/)
//        new RegExp(/^(?:(?:\+?966|01)|(?:(?:\+?20|0)(?:1|2|10))?)?[0-9]\d{8}$/)
//       ),
    // file: joi.object({
    //   fieldname: joi.string().alphanum().required(),
    //   originalname: joi.string().required(),
    //   encoding: joi.string().alphanum().required(),
    //   mimetype: joi.string().required(),
    //   dest: joi.string().required(),
    //   destination: joi.string().required(),
    //   filename: joi.string().required(),
    //   path: joi.string().required(),
    //   size: joi.number().positive().required(),
    // }),
//   };