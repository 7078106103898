import { toast } from "react-toastify";
import myAxiosInstance from "../https";

export const getAllClauses = (params) => myAxiosInstance("clause",{params}).then(data => data?.data?.clauses).catch(error=>{
  toast.error(error?.response?.data?.message)
});

export const addClause = (data) => myAxiosInstance.post("clause", data);
export const updateClause = (clauseId, data) =>
  myAxiosInstance.patch(`clause/${clauseId}`, data);
export const deleteClause = (clauseId) =>
  myAxiosInstance.delete(`clause/${clauseId}`);
