import { useQuery, useMutation, useQueryClient } from "react-query";
import {
  getAllPlans,
  getPlan,
  addPlan,
  updatePlan,
  deletePlan,
  stopPlan,
  refusePlan,
  completePlan,
  confirmPlan,
  finishPlan,
} from "../../helper/fetchers/Plans";

export const useGetAllPlans = (params) => {
  const query = useQuery(["plan",params], () => getAllPlans(params));

  return query;
};
export const useGetPlan = (id) => {
  const query = useQuery(["plan", id], () => getPlan(id));
  return query;
};

export const useAddPlan = (onSuccess,onError) => {
  const queryClient = useQueryClient();
  return useMutation((data) => addPlan(data), {
    onSuccess: () => {
      queryClient.invalidateQueries("plan");
      onSuccess();
    },
    onError: (error) => {
      onError()
      // Handle error
    },
  });
};
export const useUpdatePlan = (onSuccess,onError, id) => {
  const queryClient = useQueryClient();
  return useMutation((data) => updatePlan(id, data), {
    onSuccess: () => {
      queryClient.invalidateQueries("plan");
      onSuccess();
    },
    onError: (error) => {
      onError()
    },
  });
};
export const useDeletePlan = () => {
  const queryClient = useQueryClient();
  return useMutation(deletePlan, {
    onSuccess: () => {
      queryClient.invalidateQueries("plan");
    },
    onError: (error) => {
      // Handle error
    },
  });
};
export const useStopPlan = (id) => {
  const queryClient = useQueryClient();
  return useMutation((data) => stopPlan(id,data), {
    onSuccess: () => {
      queryClient.invalidateQueries("plan");
    },
    onError: (error) => {
      // Handle error
    },
  });
};
export const useCompletePlan = (onSuccess,onError) => {
  const queryClient = useQueryClient();
  return useMutation(completePlan, {
    onSuccess: () => {
      queryClient.invalidateQueries("plan");
      onSuccess()
    },
    onError: (error) => {
      onError()
    },
  });
};
export const useFinishPlan = (onSuccess,onError) => {
  const queryClient = useQueryClient();
  return useMutation(finishPlan, {
    onSuccess: () => {
      queryClient.invalidateQueries("plan");
      onSuccess()
    },
    onError: (error) => {
      onError()
    },
  });
};
export const useConfirmPlan = (onSuccess,onError) => {
  const queryClient = useQueryClient();
  return useMutation(confirmPlan, {
    onSuccess: () => {
      queryClient.invalidateQueries("plan");
      onSuccess()
    },
    onError: (error) => {
      onError()
    },
  });
};
export const useRefusePlan = (id,onSuccess,onError) => {
  const queryClient = useQueryClient();
  return useMutation((data) => refusePlan(id,data), {
    onSuccess: () => {
      queryClient.invalidateQueries("plan");
      onSuccess()
    },
    onError: (error) => {
      // Handle error
      onError()
    },
  });
};


