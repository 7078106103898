import React from "react";
import AddNewProject from "../../../../Components/System/Projects/AddProject/AddProject";
import SystemControler from "../../../../Components/System/SystemControler/SystemControler";
import BackButton from "../../../../Components/BackButton";

const AddProjectPage = () => {
  return (
    <div>
      <SystemControler
        child={
          <p className="pointer text-mainText">
            <BackButton />
            <span className="main-text mr-4">إضافة جديدة</span>
            <span className="text-mainText"> / المشاريع </span>
          </p>
        }
      />
      <AddNewProject />
    </div>
  );
};

export default AddProjectPage;
