import React from "react";
import { NavDropdown } from "react-bootstrap";
import { FilterIcon } from "../../../../utiltis/Icons";
import { useSearchCountryUsersContext } from "./Context";

const UserFilter = () => {
  const { statusActions } = useSearchCountryUsersContext();
  return (
    <div className="d-flex w-100 align-items-center justify-content-between m-2">
      <p className="text-center  text-mainText">كل المستخدمين</p>
      <NavDropdown
        title={<FilterIcon />}
        className="fs-5"
        id="collapsible-nav-dropdown"
      >
        <NavDropdown.Item
          onClick={statusActions.resetStatus}
          className="text-end  d-flex justify-content-between align-items-center hover:rounded-lg"
        >
          <span className="text-black"> الكل</span>
        </NavDropdown.Item>
        <NavDropdown.Item
          onClick={statusActions.onlineStatus}
          className="text-end  d-flex justify-content-between align-items-center hover:rounded-lg"
        >
          <span className="text-black"> متصل</span>
          <div className="connected" />
        </NavDropdown.Item>
        <NavDropdown.Item
          onClick={statusActions.offlineStatus}
          className="text-end  d-flex justify-content-between align-items-center hover:rounded-lg"
        >
          <span className="text-black">غير متصل</span>
          <div className="not-connected " />
        </NavDropdown.Item>
      </NavDropdown>
    </div>
  );
};

export default UserFilter;
