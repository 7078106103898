import React from "react";
import ReactSelect from "react-select";
import AsyncSelect from "react-select/async";



// eslint-disable-next-line react/display-name
const Select = React.forwardRef((props, ref) => {
  const {
    fullWidth,
    label,
    validator,
    mandatory,
    placeholder,
    height,
    submitted,
    isMulti = false,
    formGroup = true,
    small = false,
    async = false,
    autoHeight = false,
    className = "",
    OptionbackgroundColor = "var(--main-navy)",
    cacheOptions = true,
    labelClasses,
    onChange,
    onBlur,
    value,
    disabled,
  } = props;

  let hasWarning = submitted && validator && !validator.valid;

  const selectStyles = (small, autoHeight) => {
    const height = "auto";

    let options = {
      control: (styles) => ({
        ...styles,
        borderColor: "transparent",
        borderRadius: "0.25rem",
        height,
        minHeight: height,
        cursor: "pointer",
        backgroundColor: "var(--main-navy)",
        boxShadow: "none",
        ":hover": {
          borderColor: "transparent",
        },
      }),
      menu: (styles) => ({
        ...styles,
        backgroundColor: "var(--main-item-color)",
        zIndex: 1000,
      }),
      option: (styles, { isFocused }) => ({
        ...styles,
        cursor: "pointer",
        color: isFocused ? "white" : "var(--main-text)",
        backgroundColor: isFocused ? "#efaa20" : "",
        zIndex: 1000,
      }),
      placeholder: (styles) => ({
        ...styles,
        color: "var(--main-text)",
        // opacity: 0.5,
      }),
      singleValue: (styles) => ({
        ...styles,
        color: "var(--main-text)",
      }),
      multiValueLabel: (styles) => ({
        ...styles,
        color: "white",
        backgroundColor: "#efaa20",
        borderRadius: "0px 4px 4px 0px",
      }),
      multiValue: (styles) => ({
        ...styles,
        color: "white",
        backgroundColor: "#efaa20",
        // borderRadius: "0px 4px 4px 0px",
      }),
      multiValueRemove: (styles) => ({
        ...styles,
        backgroundColor: "#efaa20",
        borderRadius: "4px 0px 0px 4px",
        color: "white",
        ":hover": {
          backgroundColor: "#c9302c",
          color: "white",
        },
      }),
      input: (base) => ({
        ...base,
        margin: 0,
        padding: 0,
        color: "var(--main-text)",
      }),
      noOptionsMessage: (base) => ({
        ...base,
        color: "var(--main-text)",
      }),
    };

    if (small) {
      options = {
        ...options,
        ...{
          dropdownIndicator: (base) => ({
            ...base,
            padding: 4,
          }),
          clearIndicator: (base) => ({
            ...base,
            padding: 4,
          }),
          valueContainer: (base) => ({
            ...base,
            padding: "0px 6px",
            color: "#FFFFFF",
          }),
          input: (base) => ({
            ...base,
            margin: 0,
            padding: 0,
            color: "#FFFFFF",
          }),
        },
      };
    }

    return options;
  };

  return (
    <div
      className={`${formGroup ? "form-group" : ""} ${
        hasWarning ? "has-warning" : ""
      }
      ${fullWidth ? "w-100" : ""}
      `}
    >
      {label && (
        <label className={`text-mainText text-xs ${labelClasses}`}>
          {label} {mandatory && <span className="text-danger">*</span>}
        </label>
      )}
      {!async && (
        <ReactSelect
          ref={ref}
          styles={selectStyles(small, autoHeight)}
          isMulti={isMulti}
          placeholder="اختر"
          width
          height
          value={value}
          defaultValue={value}
          closeMenuOnSelect={!isMulti}
          onChange={onChange}
          onBlur={onBlur}
          isDisabled={disabled}
          clearIndicator={() => null}
          className={`react-select text-mainTextReverse ${className} ${
            submitted && validator && !validator.valid ? "is-invalid" : ""
          }`}
          noOptionsMessage={() => "لا توجد بيانات"}
          {...props}
        />
      )}
      {async && (
        <AsyncSelect
          ref={ref}
          placeholder={placeholder ?? "اختر"}
          styles={selectStyles(small)}
          isMulti={isMulti}
          width
          height
          className={`react-select text-mainTextReverse ${className} ${
            submitted && validator && !validator.valid ? "is-invalid" : ""
          }`}
          {...props}
          cacheOptions={cacheOptions}
          defaultOptions={[]}
          isDisabled={disabled}
        />
      )}

      {hasWarning && (
        <small className="invalid-feedback">{validator.message}</small>
      )}
    </div>
  );
});

export default Select;
