import React, { forwardRef } from 'react'
import MessageList from './MessageList';
import Typing from '../../../../Components/Chat/Typing/Typing';

const ChatMessages = forwardRef(
    ({ allMessages, isLoadingMessage, myId, typingNow }, ref) => {
      return (
        <div
          ref={ref}
          className="chat-container mb-2 p-3 overflow-y-auto overflow-x-hidden h-[537px] border !border-[#D5992180] scrollbar-thin scrollbar-track-mainNavy scrollbar-thumb-[#E4A11B] mt-4"
        >
          <MessageList
            allMessages={allMessages}
            isLoadingMessage={isLoadingMessage}
            myId={myId}
          />
  
          {typingNow ? (
            <div className="flex justify-end">
              <Typing />
            </div>
          ) : null}
        </div>
      );
    }
  );

export default ChatMessages