import { Offline, Online } from "react-detect-offline";
import "react-datepicker/dist/react-datepicker.css";
import "react-international-phone/style.css";
import "bootstrap/dist/css/bootstrap.css";

import router from "./Routes";
import "./App.css";
import { RouterProvider } from "react-router-dom";
// support react-pdf
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
import { pdfjs } from "react-pdf";
import { Provider } from "react-redux";
import { store } from "./setup/RTK/store";
import { useEffect } from "react";
import { Alert } from "react-bootstrap";
// import { sockietEmitMessage } from "./utiltis/SockietHelper";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

function App() {
  useEffect(() => {
    if (process.env.NODE_ENV !== "development") {
      console.log = () => {};
    }
  }, []);
  // console.log(Cookies.get("role"))
  return (
    <div className="App">
      <div>
        <Offline>
          <Alert variant={"danger"} className="fixed top-0 left-0 right-0 z-50">
            لا يوجد اتصال بالانترنت فى الوقت الحالى
          </Alert>
        </Offline>
      </div>
      <Provider store={store}>
        <RouterProvider router={router} />
      </Provider>
    </div>
  );
}

export default App;
