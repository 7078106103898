import React from "react";
import { Container } from "react-bootstrap";
import Slider from "react-slick";
import ProgressBar from "react-bootstrap/ProgressBar";
import "./index.css";
const CountryDeprtamentSlider = ({ data, country }) => {
  const settings = {
    slidesToShow: 5,
    slidesToScroll: 5,

    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 760,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <Slider className="systemDeprtamentSlider d-flex  w-199 " {...settings}>
      <div className=" d-flex flex-column mb-2 deprtament-slider-Container  m-auto   ">
        <img
          src={process.env.PUBLIC_URL + "/icons/deprtmants.png"}
          alt="deprtmant img"
          className="deprtmant-icon"
        />
        <h2 style={{ color: "var(--main-text)", fontSize: "12px" }}>
          ميكانيكا
        </h2>
        <p className="main-text">
          {country === "egypt"
            ? data?.Egypt?.mechanical?.count
            : data?.Saudi?.mechanical?.count}
        </p>
        <ProgressBar
          className="w-50   "
          dir="rtl"
          variant="warning"
          now={
            country === "egypt"
              ? data?.Egypt?.mechanical?.count
              : data?.Saudi?.mechanical?.count
          }
        />
      </div>
      <div className=" d-flex flex-column mb-2 deprtament-slider-Container m-auto   ">
        <img
          src={process.env.PUBLIC_URL + "/icons/deprtmants.png"}
          alt="deprtmant img"
          className="deprtmant-icon"
        />
        <h2 style={{ color: "var(--main-text)", fontSize: "12px" }}>البرمجة</h2>
        <p className="main-text">
          
          {country === "egypt"
            ? data?.Egypt?.programming?.count
            : data?.Saudi?.programming?.count}
        </p>
        <ProgressBar
          className="w-50   "
          dir="rtl"
          variant="warning"
          now={
            country === "egypt"
              ? data?.Egypt?.programming?.count
              : data?.Saudi?.programming?.count
          }
        />
      </div>
      <div className=" d-flex flex-column mb-2 deprtament-slider-Container m-auto   ">
        <img
          src={process.env.PUBLIC_URL + "/icons/deprtmants.png"}
          alt="deprtmant img"
          className="deprtmant-icon"
        />
        <h2 style={{ color: "var(--main-text)", fontSize: "12px" }}>مدير</h2>
        <p className="main-text">
          {country === "egypt"
            ? data?.Egypt?.administrator?.count
            : data?.Saudi?.administrator?.count}
        </p>
        <ProgressBar
          className="w-50   "
          dir="rtl"
          variant="warning"
          now={
            country === "egypt"
              ? data?.Egypt?.administrator?.count
              : data?.Saudi?.administrator?.count
          }
        />
      </div>
      <div className=" d-flex flex-column mb-2 deprtament-slider-Container m-auto   ">
        <img
          src={process.env.PUBLIC_URL + "/icons/deprtmants.png"}
          alt="deprtmant img"
          className="deprtmant-icon"
        />

        <h2 style={{ color: "var(--main-text)", fontSize: "12px" }}>كهربائي</h2>
        <p className="main-text">
          {country === "egypt"
            ? data?.Egypt?.electrical?.count
            : data?.Saudi?.electrical?.count}
        </p>
        <ProgressBar
          className="w-50   "
          dir="rtl"
          variant="warning"
          now={
            country === "egypt"
              ? data?.Egypt?.electrical?.count
              : data?.Saudi?.electrical?.count
          }
        />
      </div>
      <div className=" d-flex flex-column mb-2 deprtament-slider-Container m-auto   ">
        <img
          src={process.env.PUBLIC_URL + "/icons/deprtmants.png"}
          alt="deprtmant img"
          className="deprtmant-icon"
        />
        <h2 style={{ color: "var(--main-text)", fontSize: "12px" }}>مدني</h2>
        <p className="main-text">
          {country === "egypt"
            ? data?.Egypt?.civil?.count
            : data?.Saudi?.civil?.count}
        </p>
        <ProgressBar
          className="w-50   "
          dir="rtl"
          variant="warning"
          now={
            country === "egypt"
              ? data?.Egypt?.civil?.count
              : data?.Saudi?.civil?.count
          }
        />
      </div>
      <div className=" d-flex flex-column mb-2 deprtament-slider-Container m-auto   ">
        <img
          src={process.env.PUBLIC_URL + "/icons/deprtmants.png"}
          alt="deprtmant img"
          className="deprtmant-icon"
        />
        <h2 style={{ color: "var(--main-text)", fontSize: "12px" }}>
          مهندس معماري»
        </h2>
        <p className="main-text">
          {country === "egypt"
            ? data?.Egypt?.architect?.count
            : data?.Saudi?.architect?.count}
        </p>
        <ProgressBar
          className="w-50   "
          dir="rtl"
          variant="warning"
          now={
            country === "egypt"
              ? data?.Egypt?.architect?.count
              : data?.Saudi?.architect?.count
          }
        />
      </div>
    </Slider>
  );
};

export default CountryDeprtamentSlider;
