import { useState } from "react";
import { FormModal } from "../components/FormModal";
import { FaFileDownload } from "react-icons/fa";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import CustomModal from "../../../../Components/Modals/CustomModal";
import { useNavigate, useParams } from "react-router-dom";
import SystemControler from "../../../../Components/System/SystemControler/SystemControler";
import SuccessfullModal from "../../../../Components/Modals/SuccessfullModal";
import CommentModel from "../../../../Components/Modals/CommentModel";
import Cookies from "js-cookie";

import {
  CheckRole,
  approvementEnum,
  clientTypeEnum,
  projectTypeEnum,
  statusEnum,
} from "../../../../helper/utils";
import { myAxiosJson } from "../../../../helper/https";
import { useMutation, useQueryClient } from "react-query";
import {
  DeleteIcon,
  EditIcon,
  FaCheck,
  PdfIcon,
  RefuseIcon,
} from "../../../../utiltis/Icons";
import useSuccessfullModal from "../../../../Components/Modals/hooks/useSuccessfullModal";
import useCustomModal from "../../../../Components/Modals/hooks/useCustomModal";
import useCommentModel from "../../../../Components/Modals/hooks/useCommentModel";
import {
  useAcceptTask,
  useAuditAcceptTask,
  useConfirmTask,
  useDeleteTask,
  useGetTask,
} from "../../../../hooks/fetchers/Tasks";
import AssignToCard from "../../Tasks/components/AssignToCard";
import moment from "moment";
import UploadFileModal from "../../../../Components/Modals/UploadFileModal";
import LoadingPage from "../../../../Components/feedback/LoadingPage";
import { roles } from "../../../../helper/fetchers/Tasks";
import Comments from "../../../../Components/Comments";
import BackButton from "../../../../Components/BackButton";

export default function ShowTask() {
  const { taskId } = useParams();
  const { data } = useGetTask(taskId);
  const assignToIds = data?.assignTo?.map((emp) => emp?._id);
  const isMine = assignToIds?.includes(Cookies.get("userId"));
  const createdByMe = Cookies.get("userId") == data?.createdBy?._id;
  // console.log("isMine: ", isMine);

  return (
    <PageWrapper>
      <div className=" p-4 border rounded-[19px] !border-[#EFAA20]">
        <div className="flex justify-between">
          <div className="flex flex-col justify-center min-w-fit gap-4">
            <TitleText
              title={"انشآت بواسطة"}
              value={data?.createdBy?.firstName || "--------"}
            />
            <TitleText
              title={"نوع المشروع"}
              // value={data?.isExisting === true ?
              //   projectTypeEnum[data?.planId?.projectId?.projectType] :
              //    projectTypeEnum[+data?.projectType]}
              value={
                data?.isExisting === false
                  ? projectTypeEnum[+data?.projectType]
                  : data?.planId?.isExist === true
                  ? projectTypeEnum[data?.planId?.projectId?.projectType]
                  : projectTypeEnum[data?.planId?.projectType]
              }
            />

            <TitleText
              title={"نوع العميل"}
              // value={
              //   data?.isExisting === true
              //     ? clientTypeEnum[data?.planId?.projectId?.clientType]
              //     : data?.isExisting === false
              //     ? data?.clientType
              //     : "--------"
              // }
              // value={
              //   data?.isExisting === false
              //     ? data?.clientType
              //     : data?.planId?.isExist === true
              //     ? clientTypeEnum[data?.planId?.projectId?.clientType]
              //     : data?.planId?.clientType
              // }
              value={clientTypeEnum[data?.clientType]}
            />
            {data?.timeOut ? (
              <>
                <div className="flex items-center gap-2">
                  <p className="min-w-fit font-normal  text-mainText text-lg">
                    {"الوقت المستقطع"}:{" "}
                  </p>
                  <div className="inline-flex flex-col w-full shadow p-1.5 rounded-lg">
                    <div className="flex gap-3">
                      <p className="w-1/3 text-center text-[#D59921]">
                        {"يوم"}
                      </p>
                      <p className="w-1/3 text-center text-[#D59921]">
                        {"ساعة"}
                      </p>
                      <p className="w-1/3 text-center text-[#D59921]">
                        {"دقيقة"}
                      </p>
                    </div>
                    <div className="flex gap-3">
                      <p className="w-1/3 text-center">{data?.timeOut?.days}</p>
                      <p className="w-1/3 text-center">
                        {data?.timeOut?.hours}
                      </p>
                      <p className="w-1/3 text-center">
                        {data?.timeOut?.minutes}
                      </p>
                    </div>
                  </div>
                </div>
                {/* <TitleText
              title={"الوقت المستقطع"}
              value={
              <div className="inline-flex flex-col w-full">
                <div className="flex">
                  <p className="w-1/3 text-center text-[#D59921]">{"يوم"}</p>
                  <p className="w-1/3 text-center text-[#D59921]">{"ساعة"}</p>
                  <p className="w-1/3 text-center text-[#D59921]">{"دقيقة"}</p>
                </div>
                <div className="flex">
                  <p className="w-1/3 text-center">{data?.timeOut?.days}</p>
                  <p className="w-1/3 text-center">{data?.timeOut?.hours}</p>
                  <p className="w-1/3 text-center">{data?.timeOut?.minutes}</p>
                </div>
              </div>
              }
            /> */}
              </>
            ) : null}
            {data?.isRejected && (
              <TitleText
                title={"سبب الرفض"}
                value={data?.reasonReject}
                isStatus={true}
              />
            )}
            {data?.resendReason && (
              <TitleText
                title={"سبب إعادة الإرسال"}
                value={data?.resendReason}
                isStatus={true}
              />
            )}
          </div>
          <div className="flex flex-col justify-center gap-3">
          <TitleText
                title={"وقت التسليم الفعلى"}
                value={data?.isLate ? <span className="text-red-600">متأخرة</span> : <span className="text-[#D59921]">غير متأخرة</span>}
                isStatus={true}
              />
            <TitleText
              title={"الحالة"}
              value={
                data?.status == 2
                  ? approvementEnum[+data?.approvement]?.title
                  : statusEnum[+data?.status]?.title
              }
              isStatus={true}
            />

            <div className="flex items-center gap-2">
              {/* {CheckRole("مدير")} */}
              {/* {CheckRole("موظف")} */}
              {/* {CheckRole("مدير المكتب")} */}
              {/* {CheckRole("مدقق")} */}
              {/* {CheckRole("مدير قسم")} */}

              <Comments comments={data?.comments} task={data} />

              {(CheckRole(roles["admin"]) || createdByMe) && (
                <EditButton id={taskId} />
              )}

              {(CheckRole(roles["admin"]) || createdByMe) && (
                <DeleteProject id={taskId} />
              )}

              {CheckRole(roles["admin"]) && data?.status !== 5 && (
                <RefuseTask id={taskId} />
              )}

              {isMine && data?.status === 0 && <AcceptTask id={taskId} />}

              {isMine && data?.status === 1 && <ConfirmTask id={taskId} />}

              {CheckRole(roles["admin"]) &&
                data?.status === 2 &&
                data?.approvement < 3 && <AcceptTaskFromAdmin id={taskId} />}

              {/* {CheckRole("مدير") && (
              <Fragment>
                <RefuseTask id={taskId} />
                <DeleteProject id={taskId} />
                <EditButton id={taskId} />
              </Fragment>
            )} */}
              {/* {CheckRole("مدير المكتب") && (
              <Fragment>
                <EditButton id={taskId} />
              </Fragment>
            )} */}

              {/* {(CheckRole("موظف") || CheckRole("مدقق")) && (
              <>
                {data?.status === 0 && <AcceptTask id={taskId} />}
                {data?.status === 1 && <ConfirmTask id={taskId} />}
              </>
            )} */}

              {/* <OptionsButton/> */}
            </div>
          </div>
        </div>

        {/* <div className="w-full max-w-[15rem] mx-auto">
          {data?.timeOut ? (
            <fieldset className="w-full p-3 border !border-[#d59921] rounded text-mainText shadow">
              <legend className="text-mainText text-xs !w-full mx-auto text-center">
                {"الوقت المستقطع"}
              </legend>
              <div className="divide-y">
                <div className="flex items-center gap-3 pb-1.5">
                  <p className="text-[#d59921] font-medium w-1/2">{"يوم"}:</p> <p className="text-mainText w-1/2 text-center opacity-80">{data?.timeOut?.days}</p>{" "}
                </div>
                <div className="flex items-center gap-3 py-1">
                  <p className="text-[#d59921] font-medium w-1/2">{"ساعة"}:</p> <p className="text-mainText w-1/2 text-center opacity-80">{data?.timeOut?.hours}</p>{" "}
                </div>
                <div className="flex items-center gap-3">
                  <p className="text-[#d59921] font-medium w-1/2">{"دقيقة"}:</p> <p className="text-mainText w-1/2 text-center opacity-80">{data?.timeOut?.minutes}</p>{" "}
                </div>
              </div>
            </fieldset>
          ) : null}
        </div> */}
      </div>

      <div className="flex flex-col gap-4 p-4 h-[700px] overflow-y-auto scrollbar-thin scrollbar-track-transparent scrollbar-thumb-[#D59921]">
        <FormModal title={"بيانات المشروع"}>
          <div className="grid grid-cols-2 gap-4">
            <TitleText
              title={"اسم المهمة"}
              value={data?.projectName || "لا يوجد مخطط"}
            />
            {data?.isExisting === false ? (
              <TitleText
                title={"موقع المشروع"}
                value={data?.buildingLocation || "لا يوجد بيانات"}
              />
            ) : null}
          </div>
        </FormModal>
        <FormModal title={" المسؤلين"}>
          <p className="font-semibold text-base mb-3 text-mainText">
            المشرف العام :
          </p>
          <div className="grid grid-cols-2 gap-5">
            <AssignToCard
              title={`${data?.createdBy?.firstName} ${data?.createdBy?.lastName}`}
              desc={`${data?.createdBy?.role} - ${data?.createdBy?.country}`}
              image={data?.createdBy?.image}
              userId={data?.createdBy?._id}
            />
          </div>
        </FormModal>
        <FormModal title={" الموظفين"}>
          <p className="font-semibold text-base mb-3">الموظفين :</p>

          <div className="grid grid-cols-2 gap-5">
            {data?.assignTo?.map((item) => (
              <AssignToCard
                title={`${item?.firstName} ${item?.lastName}`}
                desc={`${item?.role} - ${item?.country}`}
                image={item?.image}
                userId={item?._id}
                key={item?._id}
              />
            ))}
          </div>
        </FormModal>
        <FormModal title={"تفاصيل المهمه"}>
          <div className="grid grid-cols-2 gap-4 mb-4">
            <TitleText
              title={"تاريخ الاستلام"}
              value={moment(data?.startDate).format("YYYY-MM-DD")}
            />
            <TitleText
              title={"تاريخ التسليم"}
              value={moment(data?.endDate).format("YYYY-MM-DD")}
            />
          </div>
          <div className="grid grid-cols-2 gap-4 mb-4">
            <TitleText title={"وقت الاستلام"} value={data?.startTime} />
            <TitleText title={"وقت التسليم"} value={data?.endTime} />
          </div>
          <div className="">
            <p className="text-base font-normal mb-2">وصف المهمه</p>
            <p
              className="border !border-[#D59921] h-44 p-2 text-mainCustomGray"
              dangerouslySetInnerHTML={{ __html: data?.description }}
            ></p>
          </div>
        </FormModal>
        {/* <FormModal title={"ملاحظات العميل"}>
          <div className="">
            <p className="text-base font-normal mb-2">ملاحظات العميل</p>
            <p
              className="border !border-[#D59921] h-44 p-2 text-mainText"
              dangerouslySetInnerHTML={{ __html: data?.noteClient }}
            ></p>
          </div>
        </FormModal> */}

        <FormModal title={"ملفات المشروع"}>
          {data?.fileTask?.length > 0 ? (
            <div className="relative w-full flex gap-6 overflow-y-hidden overflow-x-auto scrollbar-thin scrollbar-track-transparent scrollbar-thumb-[#EFAA20] pb-14">
              <ShowFiles files={data?.fileTask} />
            </div>
          ) : (
            <div className="flex justify-center gap-3">
              <p className="text-2xl text-[#D59921]"> لا يوجد ملفات</p>
            </div>
          )}
        </FormModal>
        <FormModal title={"المرفقات"}>
          {data?.attachmentTask?.length > 0 ? (
            <div className="relative w-full flex gap-6 overflow-y-hidden overflow-x-auto scrollbar-thin scrollbar-track-transparent scrollbar-thumb-[#EFAA20] pb-14">
              <ShowFiles files={data?.attachmentTask} />
            </div>
          ) : (
            <div className="flex justify-center gap-3">
              <p className="text-2xl text-[#D59921]"> لا يوجد مرفقات</p>
            </div>
          )}
        </FormModal>
        {data?.status !== 0 && data?.status !== 1 && (
          <FormModal title={"ملفات الموظف"}>
            {data?.employeeTaskFiles?.length > 0 ? (
              <div className="relative w-full flex gap-6 overflow-y-hidden overflow-x-auto scrollbar-thin scrollbar-track-transparent scrollbar-thumb-[#EFAA20] pb-14">
                <ShowFiles files={data?.employeeTaskFiles} />
              </div>
            ) : (
              <div className="flex justify-center gap-3">
                <p className="text-2xl text-[#D59921]"> لا يوجد ملفات للموظف</p>
              </div>
            )}
          </FormModal>
        )}
      </div>
    </PageWrapper>
  );
}

const PageWrapper = ({ children }) => {
  return (
    <>
      <SystemControler child={<BackButton />} />
      <div className="border rounded-[19px] bg-mainItems text-mainText !border-[#EFAA20] h-full">
        {children}
      </div>
    </>
  );
};

const EditButton = ({ id }) => {
  const navigate = useNavigate();
  return (
    <button
      onClick={() => navigate(`/System/tasks/tasks/edit-task/${id}`)}
      className="bg-[#19B159] rounded-[3px] flex justify-center items-center w-6 h-6"
    >
      <EditIcon />
    </button>
  );
};

const AcceptTask = ({ id }) => {
  const {
    handleShow: handleShowAccept,
    handleClose: handleCloseAccept,
    show: showAccept,
  } = useCustomModal();
  const {
    handleShowSuccess,
    handleShowError,
    handleCloseSuccess,
    handleCloseError,
    showSuccess,
    showError,
  } = useSuccessfullModal();
  const { mutate, isLoading } = useAcceptTask(
    handleShowSuccess,
    handleShowError
  );
  return (
    <>
      {isLoading ? <LoadingPage /> : null}
      <button
        onClick={handleShowAccept}
        className="bg-[#EFAA20] rounded-[4px] flex justify-center items-center gap-2 py-1 px-3 text-sm"
      >
        القبول
        <FaCheck />
      </button>
      <CustomModal
        title={"التأكيد"}
        message={"هل انت متأكد من القبول"}
        show={showAccept}
        handleClose={handleCloseAccept}
        handleSave={() => {
          mutate(id);
          handleCloseAccept();
        }}
      />
      <SuccessfullModal
        message={"تم قبول المشروع بنجاح"}
        handleClose={handleCloseSuccess}
        show={showSuccess}
      />
      <SuccessfullModal
        message={"حدث خطأ ما"}
        handleClose={handleCloseError}
        show={showError}
        status="error"
      />
    </>
  );
};

const ConfirmTask = ({ id }) => {
  const [files, setFiles] = useState([]);
  const navigate = useNavigate();
  const { handleShow, handleClose, show } = useCustomModal();
  const {
    handleShow: handleShowUploadFile,
    handleClose: handleCloseUploadFile,
    show: showUploadFile,
  } = useCustomModal();
  const {
    handleShowSuccess,
    handleShowError,
    handleCloseSuccess,
    handleCloseError,
    showSuccess,
    showError,
  } = useSuccessfullModal();
  const { mutate, isLoading } = useConfirmTask(
    handleShowSuccess,
    handleShowError,
    id
  );
  return (
    <>
      {isLoading ? <LoadingPage /> : null}
      <button
        onClick={handleShowUploadFile}
        // onClick={handleShow}
        className="bg-[#19B159] rounded-[3px] flex justify-center items-center px-2 h-6 text-white"
      >
        <FaCheck />
        انتهت
      </button>
      <UploadFileModal
        show={showUploadFile}
        handleClose={handleCloseUploadFile}
        name={"employeeTaskFiles"}
        handleSave={(data) => {
          setFiles(data);
          handleCloseUploadFile();
          handleShow();
        }}
      />
      <CustomModal
        title={"التأكيد"}
        message={"هل انت متأكد"}
        show={show}
        handleClose={handleClose}
        handleSave={() => {
          let formData = new FormData();

          for (let file of files) {
            formData.append("employeeTaskFiles", file);
            // console.log(file);
          }
          mutate(formData);
          // console.log(files);
          handleClose();
          navigate("/System/tasks/tasks");
        }}
      />
      <SuccessfullModal
        message={"تم ارسال المهمة بنجاح"}
        handleClose={handleCloseSuccess}
        show={showSuccess}
      />
      <SuccessfullModal
        message={"حدث خطأ ما"}
        handleClose={handleCloseError}
        show={showError}
        status="error"
      />
    </>
  );
};

const DeleteProject = ({ id }) => {
  const navigate = useNavigate();
  const { handleShow, handleClose, show } = useCustomModal();
  const {
    handleShowSuccess,
    handleShowError,
    handleCloseSuccess,
    handleCloseError,
    showSuccess,
    showError,
  } = useSuccessfullModal();
  const { mutate, isLoading } = useDeleteTask(
    handleShowSuccess,
    handleShowError
  );

  return (
    <>
      {isLoading ? <LoadingPage /> : null}
      <button
        onClick={handleShow}
        className="bg-[#E40038] rounded-[3px] flex  justify-center items-center w-6 h-6"
      >
        <DeleteIcon />
      </button>
      <CustomModal
        title={"التأكيد"}
        message={"هل انت متأكد من الحذف"}
        show={show}
        handleClose={handleClose}
        handleSave={() => {
          handleClose();
          mutate({
            tasks: [id],
          });
        }}
      />

      <SuccessfullModal
        message={"تم حذف المشروع بنجاح"}
        handleClose={() => {
          handleCloseSuccess();
          navigate("/System/tasks/tasks");
        }}
        show={showSuccess}
      />
      <SuccessfullModal
        message={"حدث خطأ ما"}
        handleClose={handleCloseError}
        show={showError}
        status="error"
      />
    </>
  );
};

const useRefuseTask = (id, onSuccess, onError) => {
  const queryClient = useQueryClient();
  return useMutation((data) => myAxiosJson.patch(`task/reject/${id}`, data), {
    onSuccess: () => {
      queryClient.invalidateQueries("task");
      onSuccess();
    },
    onError: () => {
      onError();
    },
  });
};

const RefuseTask = ({ id }) => {
  const navigate = useNavigate();
  const { handleShow, handleClose, show } = useCustomModal();
  const {
    handleShowSuccess,
    handleCloseSuccess,
    showSuccess,
    handleShowError,
    handleCloseError,
    showError,
  } = useSuccessfullModal();

  const { handleShowComment, handleCloseComment, showComment } =
    useCommentModel();
  const { mutate, isLoading } = useRefuseTask(
    id,
    handleShowSuccess,
    handleShowError
  );
  const [refuseComment, setRefuseComment] = useState("");
  return (
    <>
      {isLoading ? <LoadingPage /> : null}
      <button
        onClick={handleShow}
        className="bg-[#EFAA20] rounded-[3px] text-[11px] flex gap-1 justify-center items-center h-6 p-2"
      >
        رفض
        <RefuseIcon />
      </button>

      <CustomModal
        title={"التأكيد"}
        message={"هل انت متأكد من رفض هذا المشروع"}
        show={show}
        handleClose={handleClose}
        handleSave={() => {
          handleClose();
          handleShowComment();
        }}
      />
      <CommentModel
        message={"اترك تعليقاً ...."}
        show={showComment}
        handleClose={handleCloseComment}
        setComment={setRefuseComment}
        name={"reasonReject"}
        handleSave={(data) => {
          // console.log("reasonReject:", data);
          mutate(data);

          handleCloseComment();
        }}
      />
      <SuccessfullModal
        message={"تم رفض المشروع بنجاح"}
        handleClose={() => {
          handleCloseSuccess();
          navigate("/System/tasks/tasks");
        }}
        show={showSuccess}
      />
      <SuccessfullModal
        message={"حدث خطأ ما"}
        handleClose={handleCloseError}
        show={showError}
        status="error"
      />
    </>
  );
};

const TitleText = ({ title, value, isStatus }) => {
  return (
    <p className="font-normal  text-mainText text-lg">
      {title} :
      <span
        className={
          isStatus ? "me-1 text-[#D59921]" : "me-1 text-mainCustomGray"
        }
      >
        {value}
      </span>
    </p>
  );
};

const ShowFiles = ({ files }) => {
  const { show, handleClose, handleShow } = useOpenModal();
  const [content, setContent] = useState(null);

  // console.log("files: ", files);
  return (
    <>
      <Example show={show} handleClose={handleClose} content={content} />
      {files?.map((file, index) => {
        const fileType = getType(file);
        switch (fileType) {
          case "jpg":
          case "jpeg":
          case "png":
          case "gif":
            return (
              <div
                key={index}
                onClick={() => {
                  setContent(
                    <>
                      <Button
                        className="bg-mainItems"
                        download={true}
                        href={file}
                      >
                        <FaFileDownload fontSize={25} color="#EFAA20" />
                      </Button>
                      <img
                        className="w-96  rounded-xl border"
                        src={file}
                        alt=""
                      />
                    </>
                  );
                  handleShow();
                }}
                className="shrink-0"
              >
                <img
                  className="w-24 h-24 rounded-xl border"
                  src={file}
                  alt=""
                />
              </div>
            );
          case "pdf":
            return (
              <div
                key={index}
                onClick={() => {
                  setContent(
                    <>
                      <Button
                        className="bg-mainItems"
                        download={true}
                        href={file}
                      >
                        <FaFileDownload fontSize={25} color="#EFAA20" />
                      </Button>
                      <embed
                        src={file}
                        type="application/pdf"
                        width="400"
                        height="600"
                        className="w-96 relative rounded-xl border"
                      />
                    </>
                  );
                  handleShow();
                }}
                className="flex shrink-0 justify-center items-center w-24 h-24 rounded-xl border"
              >
                <PdfIcon />
              </div>
            );
          case "zip":
            return (
              <div
                key={index}
                onClick={() => {
                  setContent(
                    <>
                      <Button
                        className="bg-mainItems"
                        download={true}
                        href={file}
                      >
                        <FaFileDownload fontSize={25} color="#EFAA20" />
                      </Button>
                      <img
                        className="w-96   rounded-xl border"
                        src="https://p7.hiclipart.com/preview/258/300/47/computer-icons-zip-truetype-icon-png-file-zip.jpg"
                        alt=""
                      />
                    </>
                  );
                  handleShow();
                }}
                className="shrink-0"
              >
                <img
                  className="w-24 h-24 rounded-xl border"
                  src="https://p7.hiclipart.com/preview/258/300/47/computer-icons-zip-truetype-icon-png-file-zip.jpg"
                  alt=""
                />
              </div>
            );
          case "xlsx":
            return (
              <div
                key={index}
                onClick={() => {
                  setContent(
                    <>
                      <Button
                        className="bg-mainItems"
                        download={true}
                        href={file}
                      >
                        <FaFileDownload fontSize={25} color="#EFAA20" />
                      </Button>
                      <img
                        className="w-96   rounded-xl border"
                        src="https://logodownload.org/wp-content/uploads/2020/04/excel-logo-0.png"
                        alt=""
                      />
                    </>
                  );
                  handleShow();
                }}
                className="shrink-0"
              >
                <img
                  className="w-24 h-24 rounded-xl border"
                  src="https://logodownload.org/wp-content/uploads/2020/04/excel-logo-0.png"
                  alt=""
                />
              </div>
            );
          case "rar":
            return (
              <div
                key={index}
                onClick={() => {
                  setContent(
                    <>
                      <Button
                        className="bg-mainItems"
                        download={true}
                        href={file}
                      >
                        <FaFileDownload fontSize={25} color="#EFAA20" />
                      </Button>
                      <img
                        className="w-96  rounded-xl border"
                        src="https://cdn-icons-png.freepik.com/512/9158/9158683.png"
                        alt=""
                      />
                    </>
                  );
                  handleShow();
                }}
                className="shrink-0"
              >
                <img
                  className="w-24 h-24 rounded-xl border"
                  src="https://cdn-icons-png.freepik.com/512/9158/9158683.png"
                  alt=""
                />
              </div>
            );
          default:
            return null;
        }
      })}
    </>
  );
};

function Example({ show, handleClose, content }) {
  return (
    <Modal
      size="lg"
      centered
      show={show}
      onHide={handleClose}
      contentClassName="p-0 bg-mainNavy w-fit mx-auto"
      className="bg-black/50"
    >
      <Modal.Body className="flex flex-col items-center">{content}</Modal.Body>
    </Modal>
  );
}

const getType = (fileName) => {
  const fileExtension = fileName.split(".").pop().toLowerCase();
  return fileExtension;
};

const useOpenModal = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return {
    show,
    handleClose,
    handleShow,
  };
};

const AcceptTaskFromAdmin = ({ id }) => {
  const navigate = useNavigate();
  const {
    handleShow: handleShowConfirm,
    handleClose: handleCloseConfirm,
    show: showConfirm,
  } = useCustomModal();
  const {
    handleShowSuccess,
    handleCloseSuccess: handleCloseSuccessConfirm,
    showSuccess: showSuccessConfirm,
    showError: showErrorConfirm,
    handleShowError,
    handleCloseError: handleCloseErrorConfirm,
  } = useSuccessfullModal();
  const { mutate, isLoading } = useAuditAcceptTask(
    handleShowSuccess,
    handleShowError
  );
  return (
    <>
      {/* {isLoading ? <LoadingPage/> : null} */}
      <button
        onClick={handleShowConfirm}
        className="bg-[#19B159] rounded-[3px] flex justify-center items-center px-4 h-7"
      >
        الموافقة
        <FaCheck />
      </button>
      <CustomModal
        title={"التأكيد"}
        message={"هل انت متأكد"}
        show={showConfirm}
        handleClose={handleCloseConfirm}
        handleSave={() => {
          mutate(id);
          handleCloseConfirm();
        }}
      />
      <SuccessfullModal
        message={"تمت الموافقة بنجاح"}
        handleClose={() => {
          handleCloseSuccessConfirm();
          navigate("/System/tasks/external-tasks");
        }}
        show={showSuccessConfirm}
      />
      <SuccessfullModal
        message={"حدث خطأ ما"}
        handleClose={handleCloseErrorConfirm}
        show={showErrorConfirm}
        status="error"
      />
    </>
  );
};
