import { RiEdit2Line } from "react-icons/ri";
import "./index.css";
import { useState } from "react";
import EditDesignRequest from "../Requests/EditRequest/EditDesignRequest";
import DownloadButton from "../../Buttons/DownloadButton";
import PreviewImage from "../../Modals/PreviewImage";
import Progress from "../../Progress";
import { Link } from "react-router-dom";
import ShowFiles from "../../ShowFiles";
import moment from "moment";
import { useGetRequestsWithid } from "../../../hooks/fetchers/Requests";
import AcceptButton from "./components/AcceptButton";
import NoFiles from "./components/NoFiles";
import Section from "./components/Section";
import DescGroup from "./components/DescGroup";
import ConfirmTask from "./components/ConfirmTask";
import FinalApprove from "./components/FinalApprove";
import DeleteRequest from "./components/DeleteRequest";
import RejectTask from "./components/RejectTask";
import { Tooltip } from "@mui/material";
import { useDesignRequestContext } from "../../../Pages/System/Requests/DesignRequest/DesignRequestContext";

const projectTypeData = {
  1: "تصميم",
  2: "الاشراف علي التنفيذ",
};

const ShowDesignRequest = ({ setShowProject, id }) => {
  const [showImg, setShowImg] = useState(false);
  const [imgSrc, setImgSrc] = useState(
    `${process.env.PUBLIC_URL}/icons/show.png`
  );
  const [editRequest, setEditRequest] = useState(false);
  const { data: request } = useGetRequestsWithid(id);

  return (
    <div className="show-Design">
      <PreviewImage
        onClose={() => setShowImg(false)}
        showImg={showImg}
        imgSrc={imgSrc}
      />
      {editRequest && (
        <EditDesignRequest
          id={id}
          editRequest={editRequest}
          setEditRequest={setEditRequest}
          handleClose={() => {
            setEditRequest(false);
          }}
        />
      )}

      <div className="border-golden mb-4 bg-mainItems">
        {request ? (
          <>
            <div className="row p-4 justify-between ">
              <div className="col-6 flex flex-col items-start gap-4">
                <DescGroup
                  label="اسم المشروع"
                  desc={request?.projectName || "لا توجد بيانات"}
                />
                <DescGroup
                  label="نوع المشروع"
                  desc={
                    projectTypeData[request?.projectType] || "لا توجد بيانات"
                  }
                />
                <DescGroup
                  label="رقم الطلب"
                  desc={request?.orderNumber || "لا توجد بيانات"}
                />
              </div>
              <div className="col-6 flex flex-col items-end gap-4">
                <div className="flex gap-2 justify-start">
                  <DownloadButton>تصدير CSV </DownloadButton>
                  <DownloadButton> تصدير Excel </DownloadButton>
                </div>
                <div className="">
                  <DescGroup
                    label="الحالة"
                    desc={
                      request?.status === 0
                        ? "فى انتظار الموافقة"
                        : request?.status === 1
                        ? "قيد التنفيذ"
                        : request?.status === 5
                        ? "مرفوضة"
                        : request?.status === 66
                        ? "تم تحويلها الى مشروع"
                        : "لا توجد بيانات"
                    }
                  />
                </div>
                <div className="flex items-center gap-2">
                  <div className="">
                    <button
                      onClick={() => setEditRequest(true)}
                      className="flex items-center gap-1 bg-[#19B159] rounded-[3px] text-white text-xs p-1"
                    >
                      تعديل
                      <RiEdit2Line />
                    </button>
                  </div>
                  {request?.status == 1 ? (
                    <div className="">
                      <FinalApprove
                        id={id}
                        path="/System/Requests/Design/66"
                        setShowProject={setShowProject}
                      />
                    </div>
                  ) : null}
                  <div className="">
                    {request?.status == 0 ? (
                      <div className="d-flex gap-3">
                        <ConfirmTask
                          id={id}
                          path="/System/Requests/Design/1"
                          setShowProject={setShowProject}
                        />
                        <RejectTask
                          id={id}
                          path="/System/Requests/Design/5"
                          setShowProject={setShowProject}
                        />
                      </div>
                    ) : request?.status == 1 ? (
                      <div className="d-flex gap-3">
                        <DeleteRequest
                          data={{ requests: [id] }}
                          path="/System/Requests/Design"
                          setShowProject={setShowProject}
                        />
                      </div>
                    ) : request?.status == 2 ? (
                      <div className="d-flex gap-3">
                        <ConfirmTask
                          id={id}
                          path="/System/Requests/Design/1"
                          setShowProject={setShowProject}
                        />
                        <DeleteRequest
                          data={{ requests: [id] }}
                          setShowProject={setShowProject}
                          path="/System/Requests/Design"
                        />
                      </div>
                    ) : request?.status == 66 ? (
                      <div className="d-flex gap-3">
                        <DeleteRequest
                          data={{ requests: [id] }}
                          path="/System/Requests/Design"
                          setShowProject={setShowProject}
                        />
                      </div>
                    ) : (
                      <div className="d-flex gap-3">
                        <ConfirmTask
                          id={id}
                          path="/System/Requests/Design/1"
                          setShowProject={setShowProject}
                        />
                        <DeleteRequest
                          data={{ requests: [id] }}
                          path="/System/Requests/Design"
                          setShowProject={setShowProject}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="row p-4 justify-between">
              {request?.createdBy ? (
                <div className="col-md-6">
                  <div className="flex flex-col gap-1 p-2 rounded-3 border !border-[#E4A11B]">
                    <div className=" flex gap-2">
                      <p className="min-w-[102px] text-mainText flex gap-2">
                        أنشأت بواسطة :
                      </p>
                      <p className="text-[#E4A11B]">
                        {`${request?.createdBy?.firstName} ${request?.createdBy?.lastName}`}
                      </p>
                    </div>
                    <div className=" flex gap-2">
                      <p className="min-w-[102px] text-mainText ">
                        أنشأت بتاريخ :
                      </p>
                      <p className="text-[#E4A11B]">
                        {`${moment(request?.createdAt).format("YYYY-MM-DD")}`}
                      </p>
                    </div>
                    <div className=" flex gap-2">
                      <p className="min-w-[102px] text-mainText "> الساعة :</p>
                      <p className="text-[#E4A11B]">
                        {`${moment(request?.createdAt).format("HH:mm a")}`}
                      </p>
                    </div>
                  </div>
                </div>
              ) : null}
              {request?.updatedBy ? (
                <div className="col-md-6">
                  <div className="flex flex-col gap-1 p-2 rounded-3 border !border-[#E4A11B]">
                    <div className=" flex gap-2">
                      <p className="min-w-[128px] text-mainText flex gap-2">
                        تم التعديل بواسطة :
                      </p>
                      <p className="text-[#E4A11B]">
                        {`${request?.updatedBy?.firstName} ${request?.updatedBy?.lastName}`}
                      </p>
                    </div>
                    <div className=" flex gap-2">
                      <p className="min-w-[128px] text-mainText ">
                        تم التعديل بتاريخ :
                      </p>
                      <p className="text-[#E4A11B]">
                        {`${moment(request?.updatedAt).format("YYYY-MM-DD")}`}
                      </p>
                    </div>
                    <div className=" flex gap-2">
                      <p className="min-w-[128px] text-mainText "> الساعة :</p>
                      <p className="text-[#E4A11B]">
                        {`${moment(request?.updatedAt).format("HH:mm a")}`}
                      </p>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </>
        ) : (
          <Progress />
        )}
      </div>

      {request ? (
        <div className="h-[600px] bg-mainItems overflow-y-auto scrollbar-thin scrollbar-track-mainNavy scrollbar-thumb-[#E4A11B] px-4">
          <Section title="بيانات المشروع">
            <div className="row px-2">
              <div className="col-md-6 mb-3">
                <DescGroup
                  label="اسم المالك"
                  desc={
                    (
                      <Tooltip placement="left" title={request?.ownerName}>
                        <span className="line-clamp-1">
                          {request?.ownerName}
                        </span>
                      </Tooltip>
                    ) || "لا توجد بيانات"
                  }
                />
              </div>
              <div className="col-md-6 mb-3">
                <DescGroup
                  label="موقع المشروع"
                  desc={request?.buildingLocation || "لا توجد بيانات"}
                />
              </div>
              <div className="col-md-6 mb-3">
                <p className="text-mainText flex gap-2">
                  الموقع الجغرافى :
                  <span className="text-mainText">
                    {request?.locationMap ? (
                      <Link
                        target="_blank"
                        rel="noreferrer"
                        to={request?.locationMap}
                      >
                        أذهب الى الموقع الجغرافى
                      </Link>
                    ) : (
                      "لا توجد بيانات"
                    )}
                  </span>
                </p>
              </div>
              {request?.commercialRegNo ? (
                <div className="col-md-6 mb-3">
                  <DescGroup
                    label="رقم السجل التجارى"
                    desc={request?.commercialRegNo || "لا توجد بيانات"}
                  />
                </div>
              ) : null}
              <div className="col-md-6 mb-3">
                <DescGroup
                  label="المدينة"
                  desc={request?.city || "لا توجد بيانات"}
                />
              </div>
              <div className="col-md-6 mb-3">
                <DescGroup
                  label="الحي"
                  desc={request?.area || "لا توجد بيانات"}
                />
              </div>
              <div className="col-md-6 mb-3">
                <DescGroup
                  label="رقم القطعة"
                  desc={request?.pieceNumber || "لا توجد بيانات"}
                />
              </div>
              <div className="col-md-6 mb-3">
                <DescGroup
                  label="رقم المخطط"
                  desc={request?.chartNumber || "لا توجد بيانات"}
                />
              </div>
              <div className="col-md-6 mb-3">
                <DescGroup
                  label="نوع المشروع"
                  desc={projectTypeData[request?.projectType]}
                />
              </div>
            </div>
          </Section>

          <Section title="بيانات المالك">
            <div className="row px-2">
              <div className="col-md-6 mt-3">
                <DescGroup
                  label="نوع العميل"
                  desc={
                    request?.clientType == 1
                      ? "حكومي أو مستثمر"
                      : request?.clientType == 2
                      ? "شركة او مؤسسة"
                      : "فردي"
                  }
                />
              </div>
              <div className="col-md-6 mt-3">
                <DescGroup
                  label="رقم الشهادة الضربية"
                  desc={request?.taxNumber || "لا توجد بيانات"}
                />
              </div>
              <div className="col-md-6 mt-3">
                <DescGroup
                  label="نوع الهوية"
                  desc={request?.identityType == 1 ? "هوية" : "تجاري"}
                />
              </div>
              <div className="col-md-6 mt-3">
                <DescGroup
                  label="البريد الالكتروني"
                  desc={request?.email || "لا توجد بيانات"}
                />
              </div>

              <div className="col-md-6 mt-3 mb-3 flex gap-4  items-center">
                <p className="text-mainText flex w-[140px]"> صورة الهوية :</p>
                <div className="w-100 flex w-full gap-2 ">
                  {request.idPhoto?.length > 0 ? (
                    <>
                      <div className="flex gap-3">
                        <ShowFiles files={request.idPhoto} />
                      </div>
                    </>
                  ) : (
                    <NoFiles />
                  )}
                </div>
              </div>

              <div className="col-md-6 mt-3">
                <DescGroup
                  label="رقم الجوال"
                  desc={
                    <span dir="ltr">{request?.phone || "لا توجد بيانات"}</span>
                  }
                />
              </div>
              <div className="col-md-6 mt-3">
                <DescGroup
                  label="رقم طلب الخدمة"
                  desc={request?.servicenumber || "لا توجد بيانات"}
                />
              </div>
              <div className="col-md-6 mt-3">
                <DescGroup
                  label="رقم المعاملة"
                  desc={request?.transactionNumber || "لا توجد بيانات"}
                />
              </div>
            </div>
          </Section>

          <Section title="بيانات الوكيل">
            <div className="row px-2">
              <div className="col-md-6 mt-3">
                <DescGroup
                  label="الوكيل"
                  desc={request?.agent || "لا توجد بيانات"}
                />
              </div>
              <div className="col-md-6 mt-3">
                <DescGroup
                  label="رقم الوكالة"
                  desc={request?.agencyNumber || "لا توجد بيانات"}
                />
              </div>
              <div className="col-md-6 mt-3 flex gap-3  items-center">
                <p className="text-mainText flex gap-2 w-[185px]">
                  مرفقات الوكالة :
                </p>
                <div className="w-100 flex w-full gap-2 ">
                  {request.agencyAttachments?.length > 0 ? (
                    <div className="flex gap-3">
                      <ShowFiles files={request.agencyAttachments} />
                    </div>
                  ) : (
                    <NoFiles />
                  )}
                </div>
              </div>
              <div className="col-md-6 mt-3 mb-3 flex gap-4  items-center">
                <p className="text-mainText flex w-[140px]"> صورة الصك :</p>
                <div className="w-100 flex w-full gap-2 ">
                  {request.instrumentImage?.length > 0 ? (
                    <>
                      <div className="flex gap-3">
                        <ShowFiles files={request.instrumentImage} />
                      </div>
                    </>
                  ) : (
                    <NoFiles />
                  )}
                </div>
              </div>
              <div className="col-md-6 mt-3">
                <DescGroup
                  label="رقم الصك"
                  desc={request?.instrumentNumber || "لا توجد بيانات"}
                />
              </div>

              <div className="col-md-12 mt-3">
                <DescGroup
                  label="ملاحظات"
                  desc={request?.notes || "لا توجد بيانات"}
                />
              </div>
            </div>
          </Section>

          {request?.status == 5 && (
            <Section title="مرفوضة">
              <div className="row px-2">
                <div className="col-md-12 mt-3 mb-3 flex gap-4  items-center">
                  <DescGroup
                    label="سبب الرفض"
                    desc={request?.resonRejected || "لا توجد بيانات"}
                  />
                </div>

                <div className="col-md-12 mt-3 mb-3 flex gap-4  items-center">
                  <div className="flex flex-col gap-2 w-full">
                    <p className="text-mainText flex w-[140px]"> المرفقات :</p>
                    <div className="w-100 flex justify-center w-full gap-2 ">
                      {request.rejectAttachments?.length > 0 ? (
                        <>
                          <div className="flex gap-3">
                            <ShowFiles files={request.rejectAttachments} />
                          </div>
                        </>
                      ) : (
                        <NoFiles />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Section>
          )}
          <Section title="ملفات العرض مالي">
            <div className="w-100 flex w-full gap-2 ">
              {request?.financialFiles?.length > 0 ? (
                <>
                  <div className="flex gap-3">
                    <ShowFiles files={request?.financialFiles} />
                  </div>
                </>
              ) : (
                <NoFiles />
              )}
            </div>
          </Section>
          <Section title="ملفات العرض الفني">
            <div className="w-100 flex w-full gap-2 ">
              {request?.techFiles?.length > 0 ? (
                <>
                  <div className="flex gap-3">
                    <ShowFiles files={request?.techFiles} />
                  </div>
                </>
              ) : (
                <NoFiles />
              )}
            </div>
          </Section>
          <div className="my-3 mx-2 d-flex justify-content-end">
            <AcceptButton onClick={() => setShowProject(false)} />
          </div>
        </div>
      ) : (
        <Progress />
      )}
    </div>
  );
};

export default ShowDesignRequest;
