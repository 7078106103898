import { useState } from 'react';

const useEditRequest = () => {
    const [editRequest, setEditRequest] = useState(false);
    const handleStartEdit = () => setEditRequest(true);
    const handleStopEdit = () => setEditRequest(false);
    const handleToggleEdit = () => setEditRequest(!editRequest);
  
    return {
      editRequest,
      handleStartEdit,
      handleStopEdit,
      handleToggleEdit,
      setEditRequest
    };
  };

export default useEditRequest