import React from 'react'
import { IoSendSharp } from 'react-icons/io5';

const SendButton = () => {
    return (
      <button className="w-1/12">
        <IoSendSharp size={25} color="#414162" />
      </button>
    );
  };

export default SendButton