import { useQuery, useMutation, useQueryClient } from "react-query";
import {
  acceptItTask,
  addItTasks,
  addTaskIssue,
  addTasks,
  addTechnicalPurchase,
  addTermsOrder,
  addTermsOrderSubCategory,
  auditAcceptPurchase,
  auditRefusePurchase,
  auditUpdatePricePurchase,
  deleteItPurchase,
  deleteItTasks,
  deletePurchase,
  deleteTaskIssue,
  deleteTermsOrder,
  deleteTermsOrderSubCategory,
  getAllAdmin,
  getAllEmployee,
  getAllItTasks,
  getAllItTasksById,
  getAllPurchase,
  getAllPurchaseById,
  getAllPurchaseStats,
  getAllStats,
  getAllTaskIssue,
  getAllTasksManager,
  getAllTasksStats,
  getAllTermsOrder,
  getAllTermsOrderSubCategory,
  getSubCategoryByIds,
  updatePurchase,
  updatePurchaseComplete,
  updateTaskIssue,
  updateTaskToComplete,
  updateTaskToFixed,
  updateTermsOrder,
  updateTermsOrderSubCategory,
} from "../../helper/fetchers/TechnicalSupport";

// Default success and error handlers
const defaultOnSuccess = () => {};
const defaultOnError = (error) => {
  console.error(error);
  return error;
};

// Technical Support Terms Order

export const useGetAllTechnicalSupportTermsOrder = (params) => {
  return useQuery(["it-category", params], () => getAllTermsOrder(params));
};

export const useAddTechnicalSupportTermsOrder = (
  onSuccess = defaultOnSuccess,
  onError = defaultOnError
) => {
  const queryClient = useQueryClient();
  return useMutation(addTermsOrder, {
    onSuccess: () => {
      queryClient.invalidateQueries("it-category");
      onSuccess();
    },
    onError,
  });
};

export const useUpdateTechnicalSupportTermsOrder = (
  id,
  onSuccess = defaultOnSuccess,
  onError = defaultOnError
) => {
  const queryClient = useQueryClient();
  return useMutation((data) => updateTermsOrder(id, data), {
    onSuccess: () => {
      queryClient.invalidateQueries("it-category");
      onSuccess();
    },
    onError,
  });
};

export const useDeleteTechnicalSupportTermsOrder = (
  onSuccess = defaultOnSuccess,
  onError = defaultOnError
) => {
  const queryClient = useQueryClient();
  return useMutation(deleteTermsOrder, {
    onSuccess: () => {
      queryClient.invalidateQueries("it-category");
      onSuccess();
    },
    onError,
  });
};

export const useGetAllTechnicalSupportTermsOrderSubCategory = (id, params) => {
  return useQuery(
    id ? [`it-category/${id}/it-subcategory`, params] : null,
    () => getAllTermsOrderSubCategory(params, id),
    {
      enabled: !!id,
    }
  );
};

export const useGetAllTechnicalSupportTermsOrderSubCategoryBuIDs = (
  idsArray
) => {
  return useQuery(
    ["it-subcategory/select", idsArray],
    () => getSubCategoryByIds(idsArray),
    {
      enabled: !!idsArray,
    }
  );
};

export const useAddTechnicalSupportTermsOrderSubCategory = (
  id,
  onSuccess = defaultOnSuccess,
  onError = defaultOnError
) => {
  const queryClient = useQueryClient();
  return useMutation((data) => addTermsOrderSubCategory(id, data), {
    onSuccess: () => {
      queryClient.invalidateQueries(`it-category/${id}/it-subcategory`);
      onSuccess();
    },
    onError,
  });
};

export const useUpdateTechnicalSupportTermsOrderSubCategory = (
  parentId,
  id,
  onSuccess = defaultOnSuccess,
  onError = defaultOnError
) => {
  const queryClient = useQueryClient();
  return useMutation((data) => updateTermsOrderSubCategory(id, data), {
    onSuccess: () => {
      queryClient.invalidateQueries(`it-category/${parentId}/it-subcategory`);
      onSuccess();
    },
    onError,
  });
};

export const useDeleteTechnicalSupportTermsOrderSubCategory = (
  id,
  onSuccess = defaultOnSuccess,
  onError = defaultOnError
) => {
  const queryClient = useQueryClient();
  return useMutation(deleteTermsOrderSubCategory, {
    onSuccess: () => {
      queryClient.invalidateQueries(`it-category/${id}/it-subcategory`);
      onSuccess();
    },
    onError,
  });
};

// Purchase Technical Support

export const useAddTechnicalSupportPurchase = (
  onSuccess = defaultOnSuccess,
  onError = defaultOnError
) => {
  const queryClient = useQueryClient();
  return useMutation(addTechnicalPurchase, {
    onSuccess: () => {
      queryClient.invalidateQueries("purchase");
      onSuccess();
    },
    onError,
  });
};

export const useGetAllPurchase = (status, params) => {
  return useQuery([`purchase/${status}`, params], () =>
    getAllPurchase(status, params)
  );
};

export const useGetStatsPurchase = (params) => {
  return useQuery(["statics/it-task", params], () => getAllTasksStats(params));
};

export const useUpdatePurchase = (
  status,
  id,
  onSuccess = defaultOnSuccess,
  onError = defaultOnError
) => {
  const queryClient = useQueryClient();
  return useMutation((data) => updatePurchase(id, data), {
    onSuccess: () => {
      queryClient.invalidateQueries(`purchase/${status}`);
      onSuccess();
    },
    onError,
  });
};

export const useUpdatePurchaseComplete = (
  id,
  onSuccess = defaultOnSuccess,
  onError = defaultOnError
) => {
  const queryClient = useQueryClient();
  return useMutation((data) => updatePurchaseComplete(id, data), {
    onSuccess: () => {
      queryClient.invalidateQueries(`purchase/${id}`);
      onSuccess();
    },
    onError,
  });
};

export const useDeleteOrderPurchase = (
  status,
  onSuccess = defaultOnSuccess,
  onError = defaultOnError
) => {
  const queryClient = useQueryClient();
  return useMutation(deletePurchase, {
    onSuccess: () => {
      queryClient.invalidateQueries(`purchase/${status}`);
      onSuccess();
    },
    onError,
  });
};

export const useAcceptPurchase = (
  id,
  onSuccess = defaultOnSuccess,
  onError = defaultOnError
) => {
  const queryClient = useQueryClient();
  return useMutation((data) => auditAcceptPurchase(id, data), {
    onSuccess: () => {
      queryClient.invalidateQueries(`purchase/${id}`);
      onSuccess();
    },
    onError,
  });
};

export const useUpdatePricePurchase = (
  id,
  onSuccess = defaultOnSuccess,
  onError = defaultOnError
) => {
  const queryClient = useQueryClient();
  return useMutation((data) => auditUpdatePricePurchase(id, data), {
    onSuccess: () => {
      queryClient.invalidateQueries(`purchase/${id}`);
      onSuccess();
    },
    onError,
  });
};

export const useGetPurchasesById = (id) => {
  return useQuery([`purchase/${id}`], () => getAllPurchaseById(id));
};

export const useGetAllStats = () => {
  return useQuery(["statics/it/home"], getAllStats);
};

export const useRefusePurchase = (
  status,
  onSuccess = defaultOnSuccess,
  onError = defaultOnError
) => {
  const queryClient = useQueryClient();
  return useMutation(({ taskId, data }) => auditRefusePurchase(taskId, data), {
    onSuccess: () => {
      queryClient.invalidateQueries(`purchase/${status}`);
      onSuccess();
    },
    onError,
  });
};

// Technical Support Task Issue

export const useGetAllTaskIssue = (params) => {
  return useQuery(["it-category", params], () => getAllTaskIssue(params));
};

export const useAddTaskIssue = (
  onSuccess = defaultOnSuccess,
  onError = defaultOnError
) => {
  const queryClient = useQueryClient();
  return useMutation(addTaskIssue, {
    onSuccess: () => {
      queryClient.invalidateQueries("it-category");
      onSuccess();
    },
    onError,
  });
};

export const useUpdateTaskIssue = (
  id,
  onSuccess = defaultOnSuccess,
  onError = defaultOnError
) => {
  const queryClient = useQueryClient();
  return useMutation((data) => updateTaskIssue(id, data), {
    onSuccess: () => {
      queryClient.invalidateQueries("it-category");
      onSuccess();
    },
    onError,
  });
};

export const useDeleteTaskIssue = (
  onSuccess = defaultOnSuccess,
  onError = defaultOnError
) => {
  const queryClient = useQueryClient();
  return useMutation(deleteTaskIssue, {
    onSuccess: () => {
      queryClient.invalidateQueries("it-category");
      onSuccess();
    },
    onError,
  });
};

// Tasks

export const useGetStatsTask = () => {
  return useQuery(["statics/it-task"], getAllPurchaseStats);
};

export const useGetAllTasks = (params) => {
  return useQuery(["it-task", params], () => getAllItTasks(params));
};

export const useGetAllTasksManager = (params) => {
  return useQuery(["it-task", params], () => getAllTasksManager(params));
};

export const useGetAllTasksById = (id) => {
  return useQuery([`it-task/${id}`], () => getAllItTasksById(id));
};

export const useAllEmployeeSelect = () => {
  return useQuery(["user/it-users/select"], getAllEmployee);
};

export const useAddItTask = (
  onSuccess = defaultOnSuccess,
  onError = defaultOnError
) => {
  const queryClient = useQueryClient();
  return useMutation(addItTasks, {
    onSuccess: () => {
      queryClient.invalidateQueries("it-task");
      onSuccess();
    },
    onError,
  });
};

export const useAllAdminSelect = () => {
  return useQuery(["user/admin-mention/select"], getAllAdmin);
};

export const useAcceptItTask = (
  id,
  onSuccess = defaultOnSuccess,
  onError = defaultOnError
) => {
  const queryClient = useQueryClient();
  return useMutation(() => acceptItTask(id), {
    onSuccess: () => {
      queryClient.invalidateQueries(`it-task/${id}`);
      onSuccess();
    },
    onError,
  });
};

export const useTaskFixed = (
  id,
  onSuccess = defaultOnSuccess,
  onError = defaultOnError
) => {
  const queryClient = useQueryClient();
  return useMutation((data) => updateTaskToFixed(id, data), {
    onSuccess: () => {
      queryClient.invalidateQueries(`it-task/${id}`);
      onSuccess();
    },
    onError,
  });
};

export const useTaskComplete = (
  id,
  onSuccess = defaultOnSuccess,
  onError = defaultOnError
) => {
  const queryClient = useQueryClient();
  return useMutation((data) => updateTaskToComplete(id, data), {
    onSuccess: () => {
      queryClient.invalidateQueries(`it-task/${id}`);
      onSuccess();
    },
    onError,
  });
};

export const useDeleteItTasks = (onSuccess, onError) => {
  const queryClient = useQueryClient();
  return useMutation(deleteItTasks, {
    onSuccess: () => {
      onSuccess();
      queryClient.invalidateQueries("it-task");
    },
    onError: (error) => {
      onError();
      // Handle error
    },
  });
};
export const useDeletePurchaseSelected = (status, onSuccess, onError) => {
  const queryClient = useQueryClient();
  return useMutation(deleteItPurchase, {
    onSuccess: () => {
      onSuccess();
      queryClient.invalidateQueries(`purchase/${status}`);
    },
    onError: (error) => {
      onError();
      // Handle error
    },
  });
};
