import React, { useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import "./index.css";

const InsideClientPieChart = ({ total }) => {
  useEffect(() => {
    const text = document.querySelectorAll(
      "text.apexcharts-text.apexcharts-datalabel-value"
    );
    text[0].setAttribute("fill", "#FFFFFF");
  }, []);

  const options = {
    chart: {
      width: 380,
      type: "donut",
    },
    labels: [`داخلى`],

    colors: ["#151A20", "#EFAA20"],
    stroke: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            total: {
              show: true,
              label: " الداخلي",
              color: "var(--main-text)",
              formatter: (val) => {
                return total;
              },
            },
          },
          value: {
            show: true,
            fontSize: "16px",
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: 400,
            color: "var(--main-text)",
            offsetY: 16,
            formatter: function (val) {
              return 0;
            },
          },
        },
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            show: false,
          },
        },
      },
    ],
    legend: {
      position: "left",
      offsetY: 0,
      height: 230,
    },
  };

  return (
    <div>
      <div className="chart-wrap ">
        <div id="chart">
          <ReactApexChart
            options={options}
            series={[0, total]}
            type="donut"
            width={"90%"}
            height={250}
          />
        </div>
      </div>
    </div>
  );
};

export default InsideClientPieChart;
