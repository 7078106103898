import { useContext, useState } from "react";
import { useShowMenu } from "../hooks/useShowMenu";
import { useDeleteService } from "../../../../../hooks/fetchers/Services";
import { UpdateServiceModal } from "./Modals/UpdateServiceModal";
import { OptionsButton } from "../../OptionsButton";
import { ServiceContext } from "../context/ServiceContext";
import useSuccessfullModal from "../../../../../Components/Modals/hooks/useSuccessfullModal";
import SuccessfullModal from "../../../../../Components/Modals/SuccessfullModal";
import LoadingPage from "../../../../../Components/feedback/LoadingPage";

export const ServicesList = ({ services }) => {
  const { show, handleShow, handleClose } = useShowMenu();
  const { serviceId, setServiceId } = useContext(ServiceContext);
  const [optionId, setOptionId] = useState();
  const {
    handleShowSuccess,
    handleShowError,
    handleCloseSuccess,
    handleCloseError,
    showSuccess,
    showError,
  } = useSuccessfullModal();
  const { mutate: deleteServiceMutation, isLoading: loadingDeleteMutation } =
    useDeleteService(handleShowSuccess, handleShowError);
  const [activeServices, setActiveServices] = useState([]);
  return (
    <div className="max-h-[700px] h-full overflow-y-auto scrollbar-thin scrollbar-track-mainNavy scrollbar-thumb-[#E4A11B]">
      {loadingDeleteMutation ? <LoadingPage /> : null}
      {services?.length > 0 ? (
        services?.map(({ _id, name }, index) => (
          <div className={`flex w-full justify-between items-center px-2 text-[#ffffff80] border hover:!border-[#EFAA20] text-base ${
              activeServices === _id
                ? "!border-[#EFAA20]"
                : "!border-transparent"
            }`}
            key={_id}
          >
            <button
              onClick={() => {
                setActiveServices(_id);
                setServiceId(_id);
              }}
              className="w-full"
            >
              <p className="w-full text-mainText text-right my-3">{name}</p>
            </button>

            <OptionsButton
              id={_id}
              onUpdate={handleShow}
              onDelete={() => deleteServiceMutation(_id)}
              getId={setOptionId}
            />
          </div>
        ))
      ) : (
        <p className="text-mainText text-2xl text-center">
          {"لا يوجد بيانات لعرضها"}
        </p>
      )}
      {show ? (
        <UpdateServiceModal
          id={optionId}
          show={show}
          handleClose={handleClose}
        />
      ) : null}
      <SuccessfullModal
        show={showSuccess}
        handleClose={() => {
          handleCloseSuccess();
          handleClose();
        }}
        message="تم الحذف بنجاح "
      />
      <SuccessfullModal
        show={showError}
        handleClose={handleCloseError}
        message="حدث خطأ ما"
        status="error"
      />
    </div>
  );
};
