import { Fragment, useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import useSuccessfullModal from "../../../../../Components/Modals/hooks/useSuccessfullModal";
import { myAxiosJson } from "../../../../../helper/https";
import { useNavigate } from "react-router-dom";
import { FormModal } from "../../../PlanModel/components/FormModal";
import { Checkbox, FormControl } from "@mui/material";
import { InputLabel } from "../../../PlanModel/components/InputLabel";
import { format } from "date-fns";
import { useQuery } from "react-query";
import SuccessfullModal from "../../../../../Components/Modals/SuccessfullModal";
import { useGetEmployees } from "../../hooks/useGetEmployees";

import {
  CustomDatePicker,
  CustomInput,
  CustomTimePicker,
  CustomeInputFile,
} from "../../../../../Components/FormUi";
import Avatar from "@mui/material/Avatar";
import { SaveButton } from "./SaveButton";
import { useAddTask } from "../../../../../hooks/fetchers/Tasks";
import Select from "../../../../../Components/FormHandler/Select";
import LoadingPage from "../../../../../Components/feedback/LoadingPage";
import { CustErrorMessage } from "../../../../../Components/Errors/CustErrorMessage";
import { ListErrorComponent } from "../../../../../Components/Errors/ListErrorComponent";
import { styled } from "@mui/material/styles";
import { CustomOption } from "../../../../../Components/CustomOption/CustomOption";
import CustomTextArea from "../../../../../Components/FormUi/CustomTextArea";
const projectTypes = [
  {
    value: "1",
    label: "تصميم",
  },
  {
    value: "2",
    label: "اشراف علي التنفيذ",
  },
];
const clientTypes = [
  {
    value: "1",
    label: "حكومي أو مستثمر",
  },
  {
    value: "2",
    label: "شركة أو مؤسسة",
  },
  {
    value: "3",
    label: "فردي",
  },
];

const ValidateFileSize = (value) => {
  if (value.length > 4) {
    return "لا يجب ان تزيد عدد الملفات عن 4";
  }
  return true;
};

const useGetPlans = () => {
  return useQuery("plan/name", () => {
    return myAxiosJson("plan/name").then((data) => {
      const plans = data.data.plans.map((plan) => ({
        _id: plan._id,
        projectName: plan.projectName,
      }));
      return plans;
    });
  });
};

function AddTaskForm({ isExist }) {
  const [errorMessage, setErrorMessage] = useState("حدث خطأ ما");
  const navigate = useNavigate();
  const { data: projects } = useGetPlans();
  const {
    showSuccess,
    showError,
    handleShowSuccess,
    handleShowError,
    handleCloseSuccess,
    handleCloseError,
  } = useSuccessfullModal();

  const { data: supervisors } = useGetEmployees();

  const {
    register,
    control,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { mutate, isLoading, isError, error } = useAddTask(
    handleShowSuccess,
    handleShowError
  );
  const manipulateData = (data) => {
    data.startDate = format(data.startDate, "MM/dd/yyyy");
    data.endDate = format(data.endDate, "MM/dd/yyyy");
    data.startTime = format(data.startTime, "HH:mm aa");
    data.endTime = format(data.endTime, "HH:mm aa");
    if (data.projectType) {
      data.projectType = data.projectType.value;
    }
    if (data.clientType) {
      data.clientType = data.clientType.value;
    }
    if (data.projectId) {
      data.projectId = data.projectId.value;
    }
    data.owner = data.owner.map((person) => person.value);
    return data;
  };
  const onSubmit = (data) => {
    data = manipulateData(data);

    // console.log(data);
    var formdata = new FormData();

    formdata.append("isExisting", isExist);
    if (isExist === true) {
      formdata.append("planId", data.projectId);
    } else if (isExist === false) {
      formdata.append("projectName", data.taskName);
      formdata.append(
        "buildingLocation",
        data.buildingLocation || "لا يوجد بيانات"
      );
      formdata.append("projectType", data.projectType);
      formdata.append("clientType", `${data.clientType}`);
    }
    formdata.append("description", data.description);
    formdata.append("startDate", data.startDate);
    formdata.append("endDate", data.endDate);
    formdata.append("startTime", data.startTime);
    formdata.append("endTime", data.endTime);

    for (let i = 0; i < data.owner.length; i++) {
      formdata.append(`assignTo[${i}]`, data.owner[i]);
    }

    if (data.fileTask) {
      for (let item of data.fileTask) {
        formdata.append("fileTask", item);
      }
    }
    if (data.attachmentTask) {
      for (let item of data.attachmentTask) {
        formdata.append("attachmentTask", item);
      }
    }
    // console.log("submitted data", data);
    mutate(formdata);
  };
  useEffect(() => {
    if (isError) {
      if (error?.response?.data?.validationErrors) {
        setErrorMessage(
          <ListErrorComponent
            messages={error?.response?.data?.validationErrors?.split(",")}
          />
        );
      } else if (error?.response?.data?.message) {
        setErrorMessage(
          <p className="text-lg font-medium">
            {error?.response?.data?.message}
          </p>
        );
      }
    }
    return () => {
      setErrorMessage("حدث خطأ ما");
    };
  }, [isError]);
  return (
    <Fragment>
      {isLoading ? <LoadingPage /> : null}
      <form
        className="flex flex-col gap-4 max-h-[900px] overflow-y-auto"
        onSubmit={handleSubmit(onSubmit)}
        encType="multipart/form-data"
      >
        {isExist === true ? (
          <FormModal title={"بحث عن المشروع"}>
            <div className="grid grid-cols-2">
              <FormControl fullWidth>
                <Controller
                  control={control}
                  name="projectId"
                  rules={{
                    required: "يجب اختيار المشروع",
                  }}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Select
                      fullWidth
                      onChange={onChange}
                      onBlur={onBlur}
                      label={"اسم المشروع"}
                      placeholder="ادخل اسم المشروع"
                      OptionbackgroundColor="#414162"
                      options={projects?.map(({ _id, projectName }) => ({
                        label: projectName,
                        value: _id,
                      }))}
                      mandatory
                    />
                  )}
                />
                <CustErrorMessage name={"projectId"} errors={errors} />
              </FormControl>
            </div>
          </FormModal>
        ) : isExist === false ? (
          <FormModal title={"اضافة مهمة جديدة"}>
            <div className="grid grid-cols-2 gap-4 mb-3">
              <FormControl fullWidth>
                <InputLabel id="new-project" label={"اسم مهمه جديد"} />
                <CustomInput
                  control={control}
                  name="taskName"
                  rules={{
                    required: "يجب كتابة اسم المهمة",
                    minLength: {
                      value: 3,
                      message: "لا يقل عن 3 حروف",
                    },
                    maxLength: {
                      value: 100,
                      message: "لا يقل عن 100 حروف",
                    },
                    pattern: {
                      value: /^[A-Za-z\u0600-\u06FF ]{3,}$/i,
                      message:
                        "يجب ان تتكون من حروف عربى فقط او انجليزية فقط رقم",
                    },
                  }}
                  placeholder="اسم مهمه جديد"
                />
                <CustErrorMessage name={"taskName"} errors={errors} />
              </FormControl>
              <FormControl fullWidth>
                {/* <InputLabel id="new-project" label={"نوع العميل"} /> */}
                {/* <CustomInput
                  name={"clientType"}
                  control={control}
                  rules={{
                    required: "يجب اضافة نوع العميل",
                    pattern: {
                      value: /^[A-Za-z\u0600-\u06FF ]{3,}$/i,
                      message:
                        "يجب ان تتكون من حروف عربى فقط او انجليزية فقط رقم",
                    },
                    minLength: {
                      value: 3,
                      message: "لا يقل عن 3 حروف",
                    },
                    maxLength: {
                      value: 100,
                      message: "لا يقل عن 100 حروف",
                    },
                  }}
                /> */}

                <Controller
                  control={control}
                  name="clientType"
                  rules={{
                    required: "يجب اختيار نوع العميل",
                  }}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Select
                      fullWidth
                      onChange={onChange}
                      onBlur={onBlur}
                      label={"نوع العميل جديد"}
                      placeholder="ادخل نوع العميل"
                      OptionbackgroundColor="#414162"
                      options={clientTypes}
                      mandatory
                    />
                  )}
                />

                <CustErrorMessage name={"clientType"} errors={errors} />
              </FormControl>
            </div>
            <div className="grid grid-cols-2 gap-4">
              <FormControl fullWidth>
                <Controller
                  control={control}
                  name="projectType"
                  rules={{
                    required: "يجب اختيار نوع المشروع",
                  }}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Select
                      fullWidth
                      onChange={onChange}
                      onBlur={onBlur}
                      label={"نوع المشروع جديد"}
                      placeholder="اخل نوع المشروع"
                      OptionbackgroundColor="#414162"
                      options={projectTypes}
                      mandatory
                    />
                  )}
                />
                <CustErrorMessage name={"projectType"} errors={errors} />
              </FormControl>
              <FormControl fullWidth>
                <InputLabel id="new-project" label={"موقع المشروع"} />
                <CustomInput
                  control={control}
                  name="buildingLocation"
                  rules={{
                    minLength: {
                      value: 3,
                      message: "لا يقل عن 3 حروف",
                    },
                  }}
                  placeholder="موقع مشروع جديد"
                />
                <CustErrorMessage name={"buildingLocation"} errors={errors} />
              </FormControl>
            </div>
          </FormModal>
        ) : null}

        <FormModal title={"تفاصيل المهمة"}>
          <div className="grid grid-cols-2 gap-4 mb-3">
            <FormControl fullWidth>
              <Controller
                control={control}
                name="owner"
                multiple={true}
                rules={{
                  required: "يجب اختيار المسئولين",
                }}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <Select
                    fullWidth
                    onChange={onChange}
                    onBlur={onBlur}
                    label={"اسم الموظف"}
                    placeholder="ادخل اسم الموظف"
                    OptionbackgroundColor="#414162"
                    isMulti={true}
                    options={supervisors?.map(
                      ({ _id, firstName, lastName, image, role }) => ({
                        label: `${firstName} ${lastName}`,
                        value: _id,
                        image,
                        role,
                      })
                    )}
                    components={{ Option: CustomOption }}
                    mandatory
                  />
                )}
              />
              <CustErrorMessage name={"owner"} errors={errors} />
            </FormControl>
          </div>
          <div className="grid grid-cols-2 gap-4 mb-3">
            <FormControl fullWidth>
              <InputLabel id="startDate" label={"تاريخ الاستلام"} />
              <CustomDatePicker
                control={control}
                name="startDate"
                rules={{ required: "يجب اختيار تاريخ الاستلام" }}
              />
              <CustErrorMessage name={"startDate"} errors={errors} />
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id="endDate" label={"تاريخ التسليم"} />
              <CustomDatePicker
                control={control}
                name="endDate"
                rules={{ required: "يجب اختيار تاريخ التسليم" }}
              />
              <CustErrorMessage name={"endDate"} errors={errors} />
            </FormControl>
          </div>
          <div className="grid grid-cols-2 gap-4 mb-3">
            <FormControl fullWidth>
              <InputLabel id="startTime" label={"وقت الاستلام"} />
              <CustomTimePicker
                control={control}
                name="startTime"
                rules={{ required: "يجب اختيار وقت الاستلام" }}
              />
              <CustErrorMessage name={"startTime"} errors={errors} />
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id="endTime" label={"وقت التسليم"} />
              <CustomTimePicker
                control={control}
                name="endTime"
                rules={{ required: "يجب اختيار وقت التسليم" }}
              />
              <CustErrorMessage name={"endTime"} errors={errors} />
            </FormControl>
          </div>
          <FormControl fullWidth>
            <InputLabel label={"وصف المهمة"} />

            <CustomTextArea
              control={control}
              name="description"
              placeholder={"اكتب ملاحظات .................................."}
            />
          </FormControl>
        </FormModal>
        <FormModal title={"ملفات المهمة"}>
          <CustomeInputFile
            register={{
              ...register("fileTask", {
                validate: ValidateFileSize,
              }),
            }}
            name="fileTask"
            multiple={true}
          />
          <CustErrorMessage name={"fileTask"} errors={errors} />
        </FormModal>
        <FormModal title={"مرفقات"}>
          <CustomeInputFile
            register={{
              ...register("attachmentTask", {
                validate: ValidateFileSize,
              }),
            }}
            name="attachmentTask"
            multiple={true}
          />
          <CustErrorMessage name={"attachmentTask"} errors={errors} />
        </FormModal>
        <SaveButton />
      </form>

      <SuccessfullModal
        handleClose={() => {
          handleCloseSuccess();
          navigate("/System/tasks/tasks");
        }}
        message={"تمت اضافة المهمة بنجاح"}
        show={showSuccess}
      />
      <SuccessfullModal
        handleClose={() => {
          handleCloseError();
          //   navigate("/System/tasks/tasks");
        }}
        message={errorMessage}
        show={showError}
        status="error"
      />
    </Fragment>
  );
}

export default AddTaskForm;
