import React from "react";
import TechnicalSupportStats from "./components/TechnicalSupportStats";
import { useGetAllStats } from "../../../hooks/fetchers/TechnicalSupportPurcheseOrder";
import LoadingPage from "../../../Components/feedback/LoadingPage";
import AllTasksChart from "./components/AllTasksChart";
import Cookies from "js-cookie";
import { CheckRole } from "../../../helper/utils";
const TechnicalSupportHome = () => {
  const { data, isLoading } = useGetAllStats();
  return isLoading ? (
    <LoadingPage />
  ) : (
    <div className="h-full bg-mainItems rounded-[22px]">
      <div className="bg-mainItems border !border-[#efaa207f] rounded-[22px] px-3 mb-5">
        {/* <AllPlansPieChart /> */}
        <div className="container ">
          <TechnicalSupportStats
            parentTitle="المهام"
            parentTotal={data?.tasks?.all || 0}
            inProgressTitle="قيد الانتظار"
            inProgressTotal={data?.tasks?.pending || 0}
            completeTitle="قيد التنفيذ"
            completeTotal={data?.tasks?.in_progress || 0}
            lateTitle="مكتملة"
            lateTotal={data?.tasks?.completed || 0}
          />
          {(Cookies.get("department") === "IT" || CheckRole("مدير")) && (
            <TechnicalSupportStats
              parentTitle="طلبات الشراء"
              parentTotal={data?.purchases?.all || 0}
              inProgressTitle="قيد الانتظار"
              inProgressTotal={data?.purchases?.pending || 0}
              completeTitle="قيد التنفيذ"
              completeTotal={data?.purchases?.in_progress || 0}
              lateTitle="تم الشراء"
              lateTotal={data?.purchases?.completed || 0}
            />
          )}
        </div>
      </div>
      <div className="bg-mainItems h-[400px] border !border-[#efaa207f] mb-5">
        <AllTasksChart />
      </div>
    </div>
  );
};

export default TechnicalSupportHome;
