import "./index.css";
import { useContext, useState } from "react";
import ShowHolidayComponent from "../../Hr/ShowHolidayComponent/ShowHolidayComponent";
import { AddHrType } from "../../../../Context/AddHr";
import PersonalAttachments from "../PersonalAttachments/PersonalAttachments";
import { convertDateFormat, projectTypeEnum } from "../../../../helper/utils";
const Genralnformation = ({ user }) => {
  const { openHr, HrType } = useContext(AddHrType);
  const [ShowHoliday, setShowHoliday] = useState(false);

  return (
    <div className="genralnformation overflow-y-auto  px-2">
      <ShowHolidayComponent
        setShowHoliday={setShowHoliday}
        ShowHoliday={ShowHoliday}
      />
      <div className="flex flex-col gap-3">
        <div className="w-full flex flex-col items-start justify-between gap-3 py-1 px-2 jutify-content-between inf-square golden-square ">
          <div className="w-full flex items-center justify-between gap-2">
            <p className="w-full text-mainText">
              
              الاسم الاول :
              <span className="text-mainCustomGray"> {user?.firstName} </span>
            </p>
            <p className="w-full text-end text-mainText">
              
              الاسم الاخير:
              <span className="text-mainCustomGray"> {user?.lastName} </span>
            </p>
          </div>

          <div className="w-full flex items-center justify-between gap-2">
            <p className="w-full text-mainText">
              
              اسم المستخدم:
              <span className="text-mainCustomGray"> {user?.userName}</span>
            </p>
            <p className="w-full text-end text-mainText">
              
              النوع:
              <span className="text-mainCustomGray">
                
                {user?.gender === 1 ? "ذكر" : "انثي"}
              </span>
            </p>
          </div>

          <div className="">
            <p className="text-mainText">
              
              البريد الالكتروني:
              <span className="text-mainCustomGray"> {user?.email}</span>
            </p>
          </div>

          <div className="w-full flex items-center justify-between gap-2">
            <p className="w-full text-mainText">
              
              رقم الجوال :
              <span className="text-mainCustomGray" dir="ltr"> {user?.phone}</span>
            </p>
            <p className="w-full text-end text-mainText">
              
              البلد :
              <span className="text-mainCustomGray"> {user?.country}</span>
            </p>
          </div>

          <p className="text-mainText">
            
            تاريخ الميلاد :
            <span className="text-mainCustomGray">
              {convertDateFormat(user?.dateOfBirth)}
            </span>
          </p>
          <div className="">
            <p className="text-mainText">
              
              الكود الوظيفي :
              <span className="text-mainCustomGray">{user?.employeCode}</span>
            </p>
          </div>
        </div>
        <div className="w-full flex flex-col items-start justify-between gap-3 py-1 px-2 jutify-content-between inf-square golden-square ">
          <div className="">
            <p className="text-mainText">
              
              الصلاحية:
              <span className="text-mainCustomGray"> {user?.role} </span>
            </p>
          </div>
          <div className="">
            <p className="text-mainText">
              
              القسم:
              <span className="text-mainCustomGray"> {user?.department} </span>
            </p>
          </div>
          <div className="">
            <p className="text-mainText">
              بدأ العمل :
              <span className="text-mainCustomGray">
                
                {convertDateFormat(user?.startWork)}
              </span>
            </p>
          </div>
          <div className="">
            <p className="text-mainText">
              المستوي :
              <span className="text-mainCustomGray">{user?.level}</span>
            </p>
          </div>
          <div className="">
            <p className="text-mainText">
              الفئة :
              <span className="text-mainCustomGray">{projectTypeEnum[user?.specialization]}</span>
            </p>
          </div>
        </div>

        <PersonalAttachments user={user} />
      </div>
    </div>
  );
};

export default Genralnformation;
