import { Fragment, useState } from "react";
import { SubCategoryBtn } from "../components/SubCategoryBtn";
import { useGetAllVacationCategories } from "../../../../../hooks/fetchers/VacationCategory";
import { SearchComponent } from "../../../../../Components/SearchComponent/SearchComponent";
import Progress from "../../../../../Components/Progress";

export default function VacationsPage() {
  const [active, setActive] = useState(1);
  const [search, setSearch] = useState("");
  const [searchParams, setSearchParams] = useState({});
  const { data, isLoading, isError } =
    useGetAllVacationCategories({search});
  const handleSearch = (value) => {
    setSearch(value)
    // if (value) {
    //   setSearchParams({ search: value });
    // } else {
    //   setSearchParams({});
    // }
  };
  return (
    <div className="bg-mainItems flex flex-col rounded-[19px] h-full">
      <div className="p-3">
        <SearchComponent
          handleChange={handleSearch}
          border="border !border-[#E4A11B]"
          value={search}
        />
      </div>
      <div className="p-3 mt-3 flex-1">
        <div
          className={`relative h-full py-4  px-2 border !border-[#d5992133]`}
        >
          <p className="absolute p-2 left-1/2 top-0 -translate-x-1/2 -mt-1 -translate-y-1/2 bg-mainItems text-mainText text-[15px] font-semibold">
            {"كل بنود التقارير"}
          </p>
          <div className="h-full">
            <div className="max-h-[700px] overflow-y-auto scrollbar-thin scrollbar-thumb-[#EFAA20] scrollbar-track-transparent">
              {isLoading && !isError ? (
                <div>
                  <Progress />
                </div>
              ) : !isLoading && !isError ? (
                <Fragment>
                  {data?.vacationCategory?.length ? (
                    data?.vacationCategory?.map(({ _id, name }) => (
                      <SubCategoryBtn
                        title={name}
                        index={_id}
                        active={active}
                        setActive={setActive}
                        key={_id}
                      />
                    ))
                  ) : (
                    <p className="text-center text-xl text-[#EFAA20] ">
                      لا يوجد خدمات لعرضها
                    </p>
                  )}
                </Fragment>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
