import { Fragment, useEffect, useRef, useState } from "react";
import PDF_HEADER from "../../../../assets/pdf-header.png";
import {
  clientTypeEnum,
  projectTypeEnum,
  requestsEnum,
} from "../../../../helper/utils";
import { toBlob } from "html-to-image";
import { Document, Page, Image, StyleSheet } from "@react-pdf/renderer";
import moment from "moment";
import SectionElement from "../../../../Components/Preview/components/SectionElement";
import PdfPreview from "../../../../Components/Preview/CustomPdfPreview";
import PdfDocument from "./PdfDocument";
const PrintFile = ({ show, onHide, data }) => {
  const refs = useRef([]);
  const [imageUrls, setImageUrls] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (show) {
      const captureImages = async () => {
        setLoading(true);
        const blobs = await Promise.all(
          refs.current.map((ref) => toBlob(ref, { pixelRatio: 2 }))
        );
        const urls = blobs.map((blob) => URL.createObjectURL(blob));
        setImageUrls(urls);
        setLoading(false);
      };

      captureImages();
    }
  }, [show]);
  // console.log("imageUrls",imageUrls);
  return (
    <>
      <PdfPreview show={show} onHide={onHide}>
        {imageUrls.length > 0 && (
          <PdfPreview.DownloadButton
            document={<PdfDocument imageUrls={imageUrls} />}
            loading={loading}
          />
        )}
        {data?.map((item, index) => (
          <Fragment key={index}>
            <div ref={(el) => (refs.current[index] = el)} className="rounded-t w-full">
              <PdfPreview.Header createdAt={`تاريخ الانشاء : ${moment(item?.createdAt).format("YYYY-MM-DD")}`} titleAr={"الطلبات"} titleEn={"Requests"} />

              <PdfPreview.Body>
                <div className="flex justify-between items-center">
                  <PdfPreview.SectionTitle title={"بيانات المشروع"} />
                  <SectionElement
                    title="الحالة"
                    disc={requestsEnum[+item?.status]}
                    className="w-1/2 gap-0 px-2"
                  />
                </div>

                <PdfPreview.SectionWrapper>
                  <SectionElement
                    title="اسم المشروع"
                    disc={item?.projectName}
                    className="col-span-2 gap-[7px]"
                  />
                  <SectionElement
                    title="نوع المشروع"
                    disc={projectTypeEnum[item?.projectType]}
                  />
                  <SectionElement title="رقم الطلب" disc={item?.orderNumber} />
                  <SectionElement title="اسم المالك" disc={item?.ownerName} />
                  <SectionElement
                    title="موقع المشروع"
                    disc={item?.buildingLocation}
                  />
                  <SectionElement title="رقم القطعه" disc={item?.pieceNumber} />
                  <SectionElement title="رقم المخطط" disc={item?.chartNumber} />
                  <SectionElement
                    title="رقم الخدمه"
                    disc={item?.servicenumber}
                  />
                  <SectionElement
                    title="رقم المعامله"
                    disc={item?.transactionNumber}
                  />
                </PdfPreview.SectionWrapper>

                <div className="">
                  <PdfPreview.SectionTitle title={"بيانات المالك"} />
                  <PdfPreview.SectionWrapper>
                    <SectionElement
                      title="نوع العميل"
                      disc={clientTypeEnum[item?.clientType]}
                    />
                    <SectionElement title="رقم الجوال" disc={item?.phone} />
                    <SectionElement
                      title="البريد الالكتروني "
                      disc={item?.email}
                    />
                    <SectionElement
                      title="رقم الشهادة الضريبية "
                      disc={item?.taxNumber}
                    />
                  </PdfPreview.SectionWrapper>
                </div>
                <div className="">
                <PdfPreview.SectionTitle title={"بيانات الوكاله"} />
                  <PdfPreview.SectionWrapper>
                    <SectionElement
                      title="رقم الرخصه"
                      disc={item?.licenseNumber}
                    />
                    <SectionElement
                      title="تاريخ الرخصه"
                      disc={moment(item?.licenseDate).format("YYYY-MM-DD")}
                    />
                    <SectionElement
                      title="سند الرخصه"
                      disc={item?.licenseDeed}
                    />
                    <SectionElement
                      title="الوصف"
                      disc={item?.notes || "لا يوجد وصف"}
                      className="col-span-2 gap-[7px]"
                    />
                  </PdfPreview.SectionWrapper>
                </div>
                <div className="">
                <PdfPreview.SectionTitle title={"المرفقات"} />
                  <PdfPreview.SectionWrapper>
                    <p className="text-base">
                      {item?.agencyAttachments?.length ||
                      item?.financialFiles?.length ||
                      item?.instrumentImage?.length
                        ? "يوجد مرفقات"
                        : "لا يوجد مرفقات"}
                    </p>
                  </PdfPreview.SectionWrapper>
                </div>
              </PdfPreview.Body>
            </div>
          </Fragment>
        ))}
      </PdfPreview>
    </>
  );
};



// const Item = ({ title = " ", disc = "", className = "" }) => {
//   return (
//     <>
//       <div className={twMerge("flex items-center gap-3", className)}>
//         <p className="w-20">{title}</p>
//         <div className="bg-[#F9EBD5] py-2 px-4 w-full min-h-[2rem]">
//           <p>{disc}</p>
//         </div>
//       </div>
//     </>
//   );
// };




export default PrintFile;
