import React, { Fragment, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Menu, MenuItem } from "@mui/material";
import myAxiosInstance, { myAxiosJson } from "../../../../helper/https";
import PreviewImage from "../../../../Components/Modals/PreviewImage";
import { staticImageSrc } from "../../../../Config/Config";
import SuccessfullModal from "../../../../Components/Modals/SuccessfullModal";
import CommentModel from "../../../../Components/Modals/CommentModel";
import CustomModal from "../../../../Components/Modals/CustomModal";
import {
  CalcIcon,
  DeleteIcon,
  DowloadIcon,
  EditIcon,
  PdfIcon,
  PeopleIcon,
  RefuseIcon,
  ResendIcon,
  StoppingIcon,
} from "../../../../utiltis/Icons";
import useCommentModel from "../../../../Components/Modals/hooks/useCommentModel";
import useSuccessfullModal from "../../../../Components/Modals/hooks/useSuccessfullModal";
import useCustomModal from "../../../../Components/Modals/hooks/useCustomModal";
import { FaCheck } from "react-icons/fa6";
import IconButton from "@mui/material/IconButton";

import { IoMdArrowDropright, IoMdMore } from "react-icons/io";
import SystemControler from "../../../../Components/System/SystemControler/SystemControler";
import { FormModal } from "../../PlanModel/components/FormModal";
import {
  CheckRole,
  approvementEnum,
  clientTypeEnum,
  convertDateFormat,
  projectTypeEnum,
  statusEnum,
} from "../../../../helper/utils";
import AssignToCard from "../components/AssignToCard";
import { useGetAuditTask } from "../hooks/useGetAuditTask";
import {
  useAuditAcceptTask,
  useAuditResendTask,
} from "../../../../hooks/fetchers/Tasks";
import moment from "moment";
import LoadingPage from "../../../../Components/feedback/LoadingPage";
import { roles } from "../../../../helper/fetchers/Tasks";
import { FaFileDownload } from "react-icons/fa";
import { Button, Modal } from "react-bootstrap";
import ResendModel from "../../../../Components/Modals/ResendModel";

export default function ShowExternalTask() {
  const { taskId } = useParams();

  const { data } = useGetAuditTask(taskId);
  // console.log("GetAuditTask ", data);
  return (
    <PageWrapper>
      <div className="flex justify-between p-4 border rounded-[19px] !border-[#EFAA20]">
        <div className="flex flex-col justify-center gap-4">
          <Item label={"انشآت بواسطة"} value={data?.createdBy?.firstName} />
          <Item
            label={"نوع المشروع"}
            value={
              data?.isExisting === false
                ? projectTypeEnum[+data?.projectType]
                : data?.planId?.isExist === true
                ? projectTypeEnum[data?.planId?.projectId?.projectType]
                : projectTypeEnum[data?.planId?.projectType]
            }
          />
          <Item
            label={"نوع العميل"}
            // value={
            //   data?.isExisting === false
            //     ? data?.clientType
            //     : data?.planId?.isExist === true
            //     ? clientTypeEnum[data?.planId?.projectId?.clientType]
            //     : data?.planId?.clientType
            // }
            value={clientTypeEnum[data?.clientType]}
          />
          {data?.isRejected && (
            <Item
              label={"سبب الرفض"}
              value={data?.reasonReject}
              isStatus={true}
            />
          )}
          {data?.resendReason && (
            <Item
              label={"سبب إعادة الإرسال"}
              value={data?.resendReason}
              isStatus={true}
            />
          )}
        </div>

        <ActionGroup id={taskId} data={data} />
      </div>

      <div className="flex flex-col gap-4 p-4 h-[700px] overflow-auto scrollbar-none">
        <FormModal title={"بيانات المشروع"}>
          <div className="grid grid-cols-2 gap-4">
            <Item
              label={"اسم المهمة"}
              value={data?.projectName || "لا يوجد مخطط"}
            />
          </div>
        </FormModal>
        <FormModal title={" المسؤلين"}>
          <p className="font-semibold text-base mb-3">المشرف العام :</p>

          <div className="grid grid-cols-2 gap-5">
            {/* {data?.planId ? (
              <>
                {data?.planId?.assignTo?.map((item) => (
                  <AssignToCard
                    title={item?.firstName}
                    desc={`${item?.role} - ${item?.country}`}
                  />
                ))}
              </>
            ) : (
              <AssignToCard
                title={data?.createdBy?.firstName}
                desc={`${data?.createdBy?.role} - ${data?.createdBy?.country}`}
              />
            )} */}
            <AssignToCard
              title={`${data?.createdBy?.firstName} ${data?.createdBy?.lastName}`}
              desc={`${data?.createdBy?.role} - ${data?.createdBy?.country}`}
              image={data?.createdBy?.image}
              userId={data?.createdBy?._id}
            />
          </div>
        </FormModal>
        <FormModal title={" الموظفين"}>
          <p className="font-semibold text-base mb-3">الموظفين :</p>

          <div className="grid grid-cols-2 gap-5">
            {data?.assignTo?.map((item) => (
              <AssignToCard
                title={`${item?.firstName} ${item?.lastName}`}
                desc={`${item?.role} - ${item?.country}`}
                userId={item?._id}
              />
            ))}
          </div>
        </FormModal>
        <FormModal title={"تفاصيل المهمه"}>
          <div className="grid grid-cols-2 gap-4 mb-4">
            <Item
              label={"تاريخ الاستلام"}
              value={moment(data?.startDate).format("YYYY-MM-DD")}
            />
            <Item
              label={"تاريخ التسليم"}
              value={moment(data?.endDate).format("YYYY-MM-DD")}
            />
          </div>
          <div className="grid grid-cols-2 gap-4 mb-4">
            <Item label={"وقت الاستلام"} value={data?.startTime} />
            <Item label={"وقت التسليم"} value={data?.endTime} />
          </div>

          <DescriptionItem label={"وصف المهمه"} value={data?.description} />
        </FormModal>
        {/* <FormModal title={"ملاحظات العميل"}>
          <DescriptionItem label={"ملاحظات العميل"} value={data?.notesClinte} />
        </FormModal> */}
        <FormModal title={"ملفات المشروع"}>
          <div className="relative w-full flex gap-6 overflow-y-hidden overflow-x-auto scrollbar-thin scrollbar-track-transparent scrollbar-thumb-[#EFAA20] pb-14">
            <ShowFiles files={data?.fileTask} />

            {/* {data?.fileTask?.map((file, index) => (
              <Files fileSrc={file} key={index} />
            ))} */}
          </div>
        </FormModal>
        <FormModal title={"المرفقات"}>
          <div className="relative w-full flex gap-6 overflow-y-hidden overflow-x-auto scrollbar-thin scrollbar-track-transparent scrollbar-thumb-[#EFAA20] pb-14">
            <ShowFiles files={data?.attachmentTask} />
            {/* {data?.attachmentTask?.map((file, index) => (
              <Files fileSrc={file} key={index} />
            ))} */}
          </div>
        </FormModal>
      </div>
    </PageWrapper>
  );
}

const ActionGroup = ({ id, data }) => {
  return (
    <div className="flex flex-col justify-center gap-3">
      <Item
        label={"وقت التسليم الفعلى"}
        value={
          data?.isLate ? (
            <span className="text-red-600">متأخرة</span>
          ) : (
            <span className="text-[#D59921]">غير متأخرة</span>
          )
        }
        isStatus={true}
      />
      <Item
        label={"الحالة"}
        value={
          data?.status == 2
            ? approvementEnum[+data?.approvement]?.title
            : statusEnum[+data?.status]?.title
        }
        isStatus={true}
      />

      <div className="flex items-center gap-2">
        {((CheckRole(roles["auditor"]) &&
          data?.status == 2 &&
          data?.approvement == 0) ||
          (CheckRole(roles["manager"]) &&
            data?.status == 2 &&
            data?.approvement < 2) ||
          (CheckRole(roles["admin"]) &&
            data?.status == 2 &&
            data?.approvement < 3)) && (
          <>
            <AcceptTask id={id} />
            <ResendTask id={id} />
          </>
        )}
      </div>
    </div>
  );
};

const OptionsButton = ({}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Fragment>
      <button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        className=""
      >
        <IoMdMore fontSize={25} />
      </button>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
          sx: {
            backgroundColor: "white",
            position: "relative",
          },
          // className: "before:absolute before:border-8 before:bg-red-200 before:top-0 before:left-0"
        }}
      >
        <MenuItem
          sx={{
            color: "black !important",
            boxShadow: `0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)`,
            display: "flex",
            gap: 1,
          }}
          onClick={handleClose}
        >
          <DowloadIcon />
          الطلب
        </MenuItem>
        <MenuItem
          sx={{
            my: 1,
            color: "black !important",
            boxShadow: `0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)`,
            display: "flex",
            gap: 1,
          }}
          onClick={handleClose}
        >
          <PeopleIcon />
          العميل
        </MenuItem>
        <MenuItem
          sx={{
            color: "black !important",
            boxShadow: `0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)`,
            display: "flex",
            gap: 1,
          }}
          onClick={handleClose}
        >
          <CalcIcon />
          الحسابات
        </MenuItem>
      </Menu>
    </Fragment>
  );
};

const Item = ({ label, value, isStatus }) => {
  return (
    <p className="font-normal text-lg">
      {label}:
      <span className={isStatus ? "me-1 text-[#D59921]" : "me-1 text-mainText"}>
        {value}
      </span>
    </p>
  );
};

const DescriptionItem = ({ label, value }) => {
  return (
    <div className="">
      {label && <p className="text-base font-normal mb-2">{label}</p>}
      <p
        className="border !border-[#D59921] h-44 p-2 text-mainText"
        dangerouslySetInnerHTML={{ __html: value }}
      ></p>
    </div>
  );
};

const PageWrapper = ({ children }) => {
  const navigate = useNavigate();
  return (
    <>
      <SystemControler
        child={
          <IconButton
            onClick={() => {
              navigate(-1);
            }}
          >
            <IoMdArrowDropright color="white" fontSize={25} />
          </IconButton>
        }
      />
      <div className="border rounded-[19px] bg-mainItems text-mainText !border-[#EFAA20] h-full">
        {children}
      </div>
    </>
  );
};

function AuditReassignTask(id, data, onSuccess, onFailure) {
  myAxiosJson
    .patch(`task/resend/${id}`, data)
    .then((res) => {
      // console.log(res);
      onSuccess();
    })
    .catch((err) => {
      console.log(err);
      onFailure();
    });
}

function AuditAcceptTask(id, onSuccess, onFailure) {
  myAxiosJson
    .patch(`task/assignmanager/${id}`)
    .then((res) => {
      // console.log(res);
      onSuccess();
    })
    .catch((err) => {
      console.log(err);
      onFailure();
    });
}

const ResendTask = ({ id }) => {
  const [message, setMessage] = useState("حدث خطا ما");
  const { handleShow, handleClose, show } = useCustomModal();
  const { showComment, handleCloseComment, handleShowComment } =
    useCommentModel();
  const {
    handleShowSuccess,
    handleCloseSuccess,
    showSuccess,
    showError,
    handleShowError,
    handleCloseError: handleCloseErrorResend,
  } = useSuccessfullModal();
  const { mutate, isLoading } = useAuditResendTask(
    id,
    handleShowSuccess,
    (err) => {
      handleShowError();

      setMessage(
        err?.response?.data?.validationErrors
          ? err?.response?.data?.validationErrors
          : err?.response?.data?.message
      );
    }
  );

  return (
    <>
      {isLoading ? <LoadingPage /> : null}
      <button
        onClick={handleShow}
        className="bg-[#EFAA20] rounded-[4px] flex justify-center items-center gap-2 py-1 px-3 text-sm"
      >
        اعادة ارسال
        <ResendIcon />
      </button>
      <CustomModal
        title={"التأكيد"}
        message={"هل انت متأكد من اعادة الارسال"}
        show={show}
        handleClose={handleClose}
        handleSave={() => {
          handleClose();
          handleShowComment();
          // window.location.reload();
        }}
      />
      <ResendModel
        show={showComment}
        message={"أضف تعليق"}
        name={"resendReason"}
        handleSave={(data) => {
          mutate(data);
          handleCloseComment();
        }}
        handleClose={handleCloseComment}
      />
      <SuccessfullModal
        message={"تم اعادة الارسال بنجاح"}
        handleClose={() => {
          handleCloseSuccess();
          // navigate("/System/tasks/external-tasks");
        }}
        show={showSuccess}
      />
      <SuccessfullModal
        message={message}
        handleClose={handleCloseErrorResend}
        show={showError}
        status="error"
      />
    </>
  );
};

const AcceptTask = ({ id }) => {
  const navigate = useNavigate();
  const {
    handleShow: handleShowConfirm,
    handleClose: handleCloseConfirm,
    show: showConfirm,
  } = useCustomModal();
  const {
    handleShowSuccess,
    handleCloseSuccess: handleCloseSuccessConfirm,
    showSuccess: showSuccessConfirm,
    showError: showErrorConfirm,
    handleShowError,
    handleCloseError: handleCloseErrorConfirm,
  } = useSuccessfullModal();
  const { mutate, isLoading } = useAuditAcceptTask(
    handleShowSuccess,
    handleShowError
  );
  return (
    <>
      {/* {isLoading ? <LoadingPage/> : null} */}
      <button
        onClick={handleShowConfirm}
        className="bg-[#19B159] rounded-[3px] flex justify-center items-center px-4 h-7"
      >
        الموافقة
        <FaCheck />
      </button>
      <CustomModal
        title={"التأكيد"}
        message={"هل انت متأكد"}
        show={showConfirm}
        handleClose={handleCloseConfirm}
        handleSave={() => {
          mutate(id);
          handleCloseConfirm();
        }}
      />
      <SuccessfullModal
        message={"تمت الموافقة بنجاح"}
        handleClose={() => {
          handleCloseSuccessConfirm();
          navigate("/System/tasks/external-tasks");
        }}
        show={showSuccessConfirm}
      />
      <SuccessfullModal
        message={"حدث خطأ ما"}
        handleClose={handleCloseErrorConfirm}
        show={showErrorConfirm}
        status="error"
      />
    </>
  );
};

const DeleteProject = ({ id }) => {
  const {
    handleShow: handleShowDelete,
    handleClose: handleCloseDelete,
    show: showDelete,
  } = useCustomModal();
  const {
    handleShowSuccess: handleShowSuccessDelete,
    handleCloseSuccess: handleCloseSuccessDelete,
    showSuccess: showSuccessDelete,
  } = useSuccessfullModal();
  const handleDelete = (id, cb) => {
    myAxiosJson
      .delete(`plan/${id}`)
      .then((data) => {
        cb();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <>
      <button
        onClick={handleShowDelete}
        className="bg-[#E40038] rounded-[3px] flex  justify-center items-center w-6 h-6"
      >
        <DeleteIcon />
      </button>
      <CustomModal
        title={"التأكيد"}
        message={"هل انت متأكد من الحذف"}
        show={showDelete}
        handleClose={handleCloseDelete}
        handleSave={() => {
          handleCloseDelete();

          handleDelete(id, handleShowSuccessDelete);
        }}
      />

      <SuccessfullModal
        message={"تم حذف المشروع بنجاح"}
        handleClose={handleCloseSuccessDelete}
        show={showSuccessDelete}
      />
    </>
  );
};

const StopProject = ({ id }) => {
  const [stopComment, setStopComment] = useState("");
  const {
    handleShow: handleShowStop,
    handleClose: handleCloseStop,
    show: showStop,
  } = useCustomModal();
  const {
    handleShowSuccess: handleShowSuccessStop,
    handleCloseSuccess: handleCloseSuccessStop,
    showSuccess: showSuccessStop,
  } = useSuccessfullModal();
  const {
    handleShowComment: handleShowCommentStop,
    handleCloseComment: handleCloseCommentStop,
    showComment: showCommentStop,
  } = useCommentModel();
  return (
    <>
      <button
        onClick={handleShowStop}
        className="bg-[#EFAA20] rounded-[3px] text-[11px] flex gap-1 justify-center items-center h-6 p-2"
      >
        توقف
        <StoppingIcon />
      </button>

      <CustomModal
        title={"التأكيد"}
        message={"هل انت متأكد من توقف هذا المشروع"}
        show={showStop}
        handleClose={handleCloseStop}
        handleSave={() => {
          handleCloseStop();
          handleShowCommentStop();
        }}
      />

      <CommentModel
        message={"اترك تعليقاً ...."}
        show={showCommentStop}
        setComment={setStopComment}
        handleClose={handleCloseCommentStop}
        handleSave={() => {
          // console.log({ reasonStopped: stopComment });
          myAxiosJson
            .patch(`plan/stoped/${id}`, {
              reasonStopped: stopComment,
            })
            .then((res) => {
              // console.log(res);
              handleShowSuccessStop();
            })
            .catch((err) => {
              console.log(err);
            });
          handleCloseCommentStop();
        }}
      />

      <SuccessfullModal
        message={"تم توقف المشروع بنجاح"}
        handleClose={handleCloseSuccessStop}
        show={showSuccessStop}
      />
    </>
  );
};

const RefuseProject = ({ id }) => {
  const {
    handleShow: handleShowRefuse,
    handleClose: handleCloseRefuse,
    show: showRefuse,
  } = useCustomModal();

  const {
    handleShowSuccess: handleShowSuccessRefuse,
    handleCloseSuccess: handleCloseSuccessRefuse,
    showSuccess: showSuccessRefuse,
  } = useSuccessfullModal();

  const {
    handleShowComment: handleShowCommentRefuse,
    handleCloseComment: handleCloseCommentRefuse,
    showComment: showCommentRefuse,
  } = useCommentModel();

  const [refuseComment, setRefuseComment] = useState("");
  return (
    <>
      <button
        onClick={handleShowRefuse}
        className="bg-[#EFAA20] rounded-[3px] text-[11px] flex gap-1 justify-center items-center h-6 p-2"
      >
        رفض
        <RefuseIcon />
      </button>

      <CustomModal
        title={"التأكيد"}
        message={"هل انت متأكد من رفض هذا المشروع"}
        show={showRefuse}
        handleClose={handleCloseRefuse}
        handleSave={() => {
          handleCloseRefuse();
          handleShowCommentRefuse();
        }}
      />
      <CommentModel
        message={"اترك تعليقاً ...."}
        show={showCommentRefuse}
        handleClose={handleCloseCommentRefuse}
        setComment={setRefuseComment}
        handleSave={() => {
          // console.log({ reasonRjected: refuseComment });
          myAxiosJson
            .patch(`plan/reject/${id}`, {
              reasonRjected: refuseComment,
            })
            .then((res) => {
              // console.log(res);
              handleShowSuccessRefuse();
            })
            .catch((err) => {
              console.log(err);
            });
          handleCloseCommentRefuse();
        }}
      />
      <SuccessfullModal
        message={"تم رفض المشروع بنجاح"}
        handleClose={handleCloseSuccessRefuse}
        show={showSuccessRefuse}
      />
    </>
  );
};

const Files = ({ fileSrc }) => {
  const [showImg, setShowImg] = useState(false);
  // console.log("fileSrc: ", fileSrc);
  return (
    <>
      <div className="pdfbg" onClick={() => setShowImg(true)}>
        <img
          src={process.env.PUBLIC_URL + "/icons/Pdf.png"}
          alt="pdf"
          className="pdfImage"
        />
        <div
          style={{ borderRadius: "7px" }}
          className="bg-[#252538] d-flex justify-content-center "
        ></div>
      </div>
      <PreviewImage
        imgSrc={`${fileSrc}`}
        onClose={() => setShowImg(false)}
        showImg={showImg}
      />
    </>
  );
};

const EditButton = ({ id }) => {
  const navigate = useNavigate();
  return (
    <button
      onClick={() => navigate(`/System/plans/edit-project/${id}`)}
      className="bg-[#19B159] rounded-[3px] flex justify-center items-center w-6 h-6"
    >
      <EditIcon />
    </button>
  );
};

const ShowFiles = ({ files }) => {
  const { show, handleClose, handleShow } = useOpenModal();
  const [content, setContent] = useState(null);

  // console.log("files: ", files);
  return (
    <>
      <Example show={show} handleClose={handleClose} content={content} />
      {files?.map((file, index) => {
        const fileType = getType(file);
        switch (fileType) {
          case "jpg":
          case "jpeg":
          case "png":
          case "gif":
            return (
              <div
                key={index}
                onClick={() => {
                  setContent(
                    <>
                      <Button
                        className="bg-mainItems"
                        download={true}
                        href={file}
                      >
                        <FaFileDownload fontSize={25} color="#EFAA20" />
                      </Button>
                      <img
                        className="w-96  rounded-xl border"
                        src={file}
                        alt=""
                      />
                    </>
                  );
                  handleShow();
                }}
                className="shrink-0"
              >
                <img
                  className="w-24 h-24 rounded-xl border"
                  src={file}
                  alt=""
                />
              </div>
            );
          case "pdf":
            return (
              <div
                key={index}
                onClick={() => {
                  setContent(
                    <>
                      <Button
                        className="bg-mainItems"
                        download={true}
                        href={file}
                      >
                        <FaFileDownload fontSize={25} color="#EFAA20" />
                      </Button>
                      <embed
                        src={file}
                        type="application/pdf"
                        width="400"
                        height="600"
                        className="w-96 relative rounded-xl border"
                      />
                    </>
                  );
                  handleShow();
                }}
                className="flex shrink-0 justify-center items-center w-24 h-24 rounded-xl border"
              >
                <PdfIcon />
              </div>
            );
          case "zip":
            return (
              <div
                key={index}
                onClick={() => {
                  setContent(
                    <>
                      <Button
                        className="bg-mainItems"
                        download={true}
                        href={file}
                      >
                        <FaFileDownload fontSize={25} color="#EFAA20" />
                      </Button>
                      <img
                        className="w-96   rounded-xl border"
                        src="https://p7.hiclipart.com/preview/258/300/47/computer-icons-zip-truetype-icon-png-file-zip.jpg"
                        alt=""
                      />
                    </>
                  );
                  handleShow();
                }}
                className="shrink-0"
              >
                <img
                  className="w-24 h-24 rounded-xl border"
                  src="https://p7.hiclipart.com/preview/258/300/47/computer-icons-zip-truetype-icon-png-file-zip.jpg"
                  alt=""
                />
              </div>
            );
          case "xlsx":
            return (
              <div
                key={index}
                onClick={() => {
                  setContent(
                    <>
                      <Button
                        className="bg-mainItems"
                        download={true}
                        href={file}
                      >
                        <FaFileDownload fontSize={25} color="#EFAA20" />
                      </Button>
                      <img
                        className="w-96   rounded-xl border"
                        src="https://logodownload.org/wp-content/uploads/2020/04/excel-logo-0.png"
                        alt=""
                      />
                    </>
                  );
                  handleShow();
                }}
                className="shrink-0"
              >
                <img
                  className="w-24 h-24 rounded-xl border"
                  src="https://logodownload.org/wp-content/uploads/2020/04/excel-logo-0.png"
                  alt=""
                />
              </div>
            );
          case "rar":
            return (
              <div
                key={index}
                onClick={() => {
                  setContent(
                    <>
                      <Button
                        className="bg-mainItems"
                        download={true}
                        href={file}
                      >
                        <FaFileDownload fontSize={25} color="#EFAA20" />
                      </Button>
                      <img
                        className="w-96  rounded-xl border"
                        src="https://cdn-icons-png.freepik.com/512/9158/9158683.png"
                        alt=""
                      />
                    </>
                  );
                  handleShow();
                }}
                className="shrink-0"
              >
                <img
                  className="w-24 h-24 rounded-xl border"
                  src="https://cdn-icons-png.freepik.com/512/9158/9158683.png"
                  alt=""
                />
              </div>
            );
          default:
            return null;
        }
      })}
    </>
  );
};
const getType = (fileName) => {
  const fileExtension = fileName.split(".").pop().toLowerCase();
  return fileExtension;
};
const useOpenModal = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return {
    show,
    handleClose,
    handleShow,
  };
};
function Example({ show, handleClose, content }) {
  return (
    <Modal
      size="lg"
      centered
      show={show}
      onHide={handleClose}
      contentClassName="p-0 bg-mainNavy w-fit mx-auto"
      className="bg-black/50"
    >
      <Modal.Body className="flex flex-col items-center">{content}</Modal.Body>
    </Modal>
  );
}
