/* eslint-disable no-sparse-arrays */
import { useCallback, useEffect, useMemo, useState } from "react";
import Input from "../../../FormHandler/Input";
import { useStepContext } from "../../../../Context/StepContext";
import { UseInput, UseSelect } from "../../../../hooks";
import KSACites from "../../../KSACItes";
import { Form, FormGroup } from "react-bootstrap";
import { toast } from "react-toastify";
import { getAllCategoriesService } from "../../../../helper/fetchers/Categories";

import { FormControl } from "@mui/material";
import Select from "../../../FormHandler/Select";
import { getAllServicesNew } from "../../../../helper/fetchers/Services";
import { FormModal } from "../../../../Pages/System/PlanModel/components/FormModal";
const projectTypeData = [
  {
    value: 1,
    label: "تصميم",
  },
  {
    value: 2,
    label: "الاشراف علي التنفيذ",
  },
];
const mappedCities = KSACites.map((city) => ({
  value: city.arLabel,
  label: city?.arLabel,
}));
const customStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: "var(--main-navy)",
    color: "var(--main-text)",
    height: 35,
    minHeight: 35,
    borderColor: "transparent",
    borderRadius: "0.25rem",
  }),
  placeholder: (base) => ({ ...base, color: "var(--main-text)" }), // Custom color for the placeholder text
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? "#efaa20"
        : isFocused
        ? "#efaa20"
        : null,
      color: isFocused ? "white" : "var(--main-text)",
      cursor: isDisabled ? "not-allowed" : "default",
      height: "37px",
      padding: "0 12px",
    };
  },
  singleValue: (styles) => ({
    ...styles,
    color: "var(--main-text)", // Change input value color
  }),
  menu: (styles) => ({
    ...styles,
    maxHeight: "180px",
    overflowY: "auto",
    backgroundColor: "var(--main-item-color)",
  }),
  menuList: (styles) => ({
    ...styles,
    maxHeight: "180px",
    overflowY: "auto",
    backgroundColor: "var(--main-item-color)",
  }),
  groupHeading: (styles) => ({
    ...styles,
    color: "#efaa20",
  }),
};
export const AddProjectStepOne = (props) => {
  const { userData, setUserData, setSelectedProjectType } = useStepContext();
  //define inupts
  const ownerName = UseInput(
    `${userData?.ownerName ? userData.ownerName : ""}`,
    "",
    true
  );
  const serviceNumber = UseInput(
    `${userData?.serviceNumber ? userData.serviceNumber : ""}`,
    "",
    true
  );
  const transactionNumber = UseInput(
    `${userData?.transactionNumber ? userData.transactionNumber : ""}`,
    "",
    true
  );
  const projectName = UseInput(
    `${userData?.projectName ? userData.projectName : ""}`,
    "",
    true
  );
  const buildingLocation = UseInput(
    `${userData?.buildingLocation ? userData.buildingLocation : ""}`,
    "",
    true
  );
  const locationMap = UseInput(
    `${userData?.locationMap ? userData.locationMap : ""}`,
    "",
    true
  );
  const city = UseSelect(
    userData?.city
      ? {
          value: userData?.city,
          label: userData?.city,
        }
      : "",
    "",
    true
  );
  const area = UseInput(`${userData?.area ? userData.area : ""}`, "", true);
  const pieceNumber = UseInput(
    `${userData?.pieceNumber ? userData.pieceNumber : ""}`,
    "",
    true
  );
  const chartNumber = UseInput(
    `${userData?.chartNumber ? userData.chartNumber : ""}`,
    "",
    true
  );

  const [projectCategory, setProjectCategory] = useState();
  const [projectServices, setProjectServices] = useState();

  const projectCategoryOptions =
    projectCategory &&
    projectCategory.length > 0 &&
    projectCategory?.map((category) => ({
      label: category.name,
      value: category._id,
      options: category.subcategories.map((sub) => ({
        label: sub.name,
        value: sub._id,
        categoryId: category._id,
      })),
    }));
  const projectServicesOptions =
    projectServices &&
    projectServices.length > 0 &&
    projectServices?.map((project) => ({
      label: project.name,
      value: project._id,
      options: project?.subservices?.map((sub) => ({
        label: sub.name,
        value: sub._id,
        projectId: project._id,
      })),
    }));
  const [categoryId, setCategoryId] = useState(
    userData?.categoryId ? userData.categoryId : ""
  );
  const [subcategoryId, setSubcategoryId] = useState(
    userData?.subcategoryId ? userData.subcategoryId : ""
  );
  const [servicesId, setServiceId] = useState(
    userData?.servicesId ? userData.servicesId : ""
  );
  const [subservicesId, setsubServicesId] = useState(
    userData?.subservicesId ? userData.subservicesId : ""
  );

  const [projectType, setProjectType] = useState(
    userData.projectType
      ? projectTypeData?.find((x) => x?.value === userData?.projectType)
      : ""
  );
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedServiceOption, setSelectedServiceOption] = useState(null);

  const setData = useCallback(() => {
    if (selectedOption) return;
    setSelectedOption(
      projectCategoryOptions
        ?.find((x) => x.value === userData?.categoryId)
        ?.options?.find((z) => z?.value === userData?.subcategoryId)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData, projectCategoryOptions]);

  const setDataService = useCallback(() => {
    if (selectedServiceOption) return;
    setSelectedServiceOption(
      projectServicesOptions
        ?.find((x) => x.value === userData?.servicesId)
        ?.options?.find((z) => z?.value === userData?.subservicesId)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData, projectCategoryOptions]);

  useEffect(() => {
    setData();
    setDataService();
  }, [setData, setDataService]);
  //Check vaildation

  const [IsVaildState, setIsVaildState] = useState(false);
  const signalParent = (isValid) => {
    setIsVaildState(isValid);
  };

  // checking Design Vaildation
  useMemo(() => {
    if (
      ownerName.value &&
      ownerName.isValid &&
      serviceNumber.value &&
      serviceNumber.isValid &&
      transactionNumber.value &&
      transactionNumber.isValid &&
      projectName.value &&
      projectName.isValid &&
      buildingLocation.value &&
      buildingLocation.isValid &&
      locationMap.value &&
      locationMap.isValid &&
      city.value?.label &&
      area.value &&
      area.isValid &&
      pieceNumber.value &&
      pieceNumber.isValid &&
      chartNumber.value &&
      chartNumber.isValid &&
      projectType?.value === 1 &&
      subcategoryId &&
      subservicesId
    ) {
      const updatedUserData = {
        ...userData,
        ownerName: ownerName?.value,
        serviceNumber: serviceNumber?.value,
        transactionNumber: transactionNumber?.value,
        buildingLocation: buildingLocation?.value,
        locationMap: locationMap?.value,
        city: city?.value?.label,
        area: area?.value,
        pieceNumber: pieceNumber?.value,
        chartNumber: chartNumber?.value,
        projectType: projectType?.value,
        categoryId,
        subcategoryId,
        servicesId,
        subservicesId,
        projectName: projectName.value,
      };
      setUserData(updatedUserData);
      signalParent(true);
    } else {
      const updatedUserData = {
        ...userData,
        ownerName: ownerName.value,
        serviceNumber: serviceNumber.value,
        transactionNumber: transactionNumber.value,
        buildingLocation: buildingLocation.value,
        locationMap: locationMap.value,
        city: city?.value?.label,
        area: area?.value,
        pieceNumber: pieceNumber?.value,
        chartNumber: chartNumber?.value,
        projectType: projectType?.value,
        categoryId,
        subcategoryId,
        servicesId,
        subservicesId,
        projectName: projectName.value,
      };
      setUserData(updatedUserData);
      signalParent(false);
    }
  }, [
    ownerName.value,
    ownerName.isValid,
    serviceNumber.value,
    serviceNumber.isValid,
    transactionNumber.value,
    transactionNumber.isValid,
    buildingLocation.value,
    buildingLocation.isValid,
    locationMap.value,
    locationMap.isValid,
    city.value?.label,
    city.isValid,
    area.value,
    area.isValid,
    pieceNumber.value,
    pieceNumber.isValid,
    pieceNumber.value,
    pieceNumber.isValid,
    chartNumber.value,
    chartNumber.isValid,
    categoryId,
    subcategoryId,
    servicesId,
    subservicesId,
    projectName.value,
    projectType?.value,
    ,
  ]);

  // checking review Vaildation
  useMemo(() => {
    if (
      ownerName.value &&
      ownerName.isValid &&
      serviceNumber.value &&
      serviceNumber.isValid &&
      transactionNumber.value &&
      transactionNumber.isValid &&
      buildingLocation.value &&
      buildingLocation.isValid &&
      locationMap.value &&
      locationMap.isValid &&
      city.value?.label &&
      city.isValid &&
      area.value &&
      area.isValid &&
      pieceNumber.value &&
      pieceNumber.isValid &&
      chartNumber.value &&
      chartNumber.isValid &&
      projectName.value &&
      projectName.isValid &&
      projectType?.value === 2
    ) {
      const updatedUserData = {
        ...userData,
        ownerName: ownerName.value,
        serviceNumber: serviceNumber.value,
        transactionNumber: transactionNumber.value,
        projectName: projectName.value,
        buildingLocation: buildingLocation.value,
        locationMap: locationMap.value,
        city: city?.value.label,
        area: area.value,
        pieceNumber: pieceNumber.value,
        chartNumber: chartNumber.value,
        projectType: projectType?.value,
      };
      setUserData(updatedUserData);
      signalParent(true);
    } else {
      const updatedUserData = {
        ...userData,
        ownerName: ownerName.value,
        serviceNumber: serviceNumber.value,
        transactionNumber: transactionNumber.value,
        buildingLocation: buildingLocation.value,
        locationMap: locationMap.value,
        city: city.value.label,
        area: area.value,
        pieceNumber: pieceNumber.value,
        chartNumber: chartNumber.value,
        projectType: projectType?.value,
        projectName: projectName.value,
      };
      setUserData(updatedUserData);
      signalParent(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    ownerName.value,
    ownerName.isValid,
    serviceNumber.value,
    serviceNumber.isValid,
    transactionNumber.value,
    transactionNumber.isValid,
    projectName.value,
    projectName.isValid,
    buildingLocation.value,
    buildingLocation.isValid,
    locationMap.value,
    locationMap.isValid,
    city.value?.label,
    city.isValid,
    area.value,
    area.isValid,
    pieceNumber.value,
    pieceNumber.isValid,
    chartNumber.value,
    chartNumber.isValid,
    projectType?.value,
  ]);

  const getCategories = async () => {
    try {
      const data = await getAllCategoriesService();
      if (data?.status === 200) {
        setProjectCategory(data?.data?.category);
      } else {
        // console.log("Data retrieval failed");
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };
  const getServices = async () => {
    try {
      const data = await getAllServicesNew();

      if (data?.status === 200) {
        setProjectServices(data?.data?.services);
      } else {
        // console.log("Data retrieval failed");
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };
  useEffect(() => {
    signalParent(IsVaildState);
  }, [IsVaildState]);
  useEffect(() => {
    getCategories();
    getServices();
  }, []);

  const handleCategories = (selectedOption) => {
    setSelectedOption(selectedOption);
    console.log(selectedOption);
    if (selectedOption) {
      const selectedSubcategoryId = selectedOption.value;
      const selectedCategoryId = selectedOption.categoryId;
      setCategoryId(selectedCategoryId);
      setSubcategoryId(selectedSubcategoryId);
    }
  };
  const handleServices = (selectedOption) => {
    setSelectedServiceOption(selectedOption);
    console.log(selectedOption);
    if (selectedOption) {
      const selectedSubcategoryId = selectedOption.value;
      const selectedCategoryId = selectedOption.projectId;
      setServiceId(selectedCategoryId);
      setsubServicesId(selectedSubcategoryId);
    }
  };

  

  return (
    <div className="px-4">
      <FormModal title={"اضافة بيانات المشروع"}>
      <FormControl sx={{ m: 1, minWidth: 120 }}>
        <div className="row p-3">
          <div className="col-md-6 mb-4">
            <Input
              placeholder=" ادخل اسم المالك"
              className="w-100 h-[37px]"
              label={"اسم المالك"}
              {...ownerName.bind}
              mandatory
            />
          </div>

          <div className=" col-md-6 mb-4">
            <Input
              placeholder=" ادخل اسم المشروع"
              className="w-100 h-[37px]"
              label={"اسم المشروع"}
              {...projectName.bind}
              mandatory
            />
          </div>
          <div className=" col-md-4 mb-4">
            <Input
              placeholder=" ادخل موقع المشروع  "
              label={" موقع المشروع "}
              {...buildingLocation.bind}
              className="h-[37px]"
              mandatory
            />
          </div>
          <div className=" col-md-4 mb-4">
            <Input
              placeholder=" ادخل الموقع الجغرافى  "
              label={" الموقع الجغرافى "}
              {...locationMap.bind}
              className="h-[37px]"
              mandatory
            />
          </div>
          <div className=" col-md-4 mb-4">
            <Select
              label={" المدينة"}
              placeholder="اخل اسم المدينة"
              OptionbackgroundColor="#efaa20"
              {...city.bind}
              options={mappedCities}
              mandatory
            />
          </div>

          <div className=" col-md-4    mb-4">
            <Input
              className="h-[37px]"
              placeholder="ادخل اسم الحى"
              label={" الحي"}
              {...area.bind}
              mandatory
            />
          </div>

          <div className=" col-md-4 mb-4">
            <Input
              className="h-[37px]"
              placeholder="اخل رقم القطعة"
              label={" رقم القطعة"}
              {...pieceNumber.bind}
              mandatory
            />
          </div>

          <div className=" col-md-4 mb-4">
            <Input
              className="h-[37px]"
              placeholder="اخل رقم المخطط"
              label={" رقم المخطط"}
              {...chartNumber.bind}
              mandatory
            />
          </div>
          <div className=" col-md-6 mb-4">
            <Input
              placeholder=" ادخل رقم طلب الخدمة"
              className="w-100 h-[37px]"
              label={"رقم طلب الخدمة"}
              {...serviceNumber.bind}
              mandatory
            />
          </div>
          <div className=" col-md-6 mb-4">
            <Input
              placeholder=" ادخل رقم المعاملة"
              className="w-100 h-[37px]"
              label={"رقم المعاملة"}
              {...transactionNumber.bind}
              mandatory
            />
          </div>
          <div className=" col-md-4 mb-4">
            <FormControl fullWidth>
              <Select
                label={"نوع المشروع"}
                options={projectTypeData}
                value={projectType}
                placeholder="اختر نوع المشروع"
                onChange={(e) => {
                  setSelectedProjectType(e);
                  setProjectType(e);
                }}
              />
            </FormControl>
          </div>
          {projectType?.value === 1 ? (
            <>
              <div className="col-md-4">
                <FormGroup className="flex flex-col">
                  <Form.Label className="text-sm">استخدام المشروع</Form.Label>
                  <Dropdown
                    options={projectCategoryOptions}
                    onChange={handleCategories}
                    value={selectedOption}
                  />
                </FormGroup>
              </div>

              <div className=" col-md-4 ">
                <FormGroup className="flex flex-col ">
                  <Form.Label className="text-sm"> نوع الخدمة</Form.Label>
                  <Dropdown
                    options={projectServicesOptions}
                    onChange={handleServices}
                    value={selectedServiceOption}
                  />
                </FormGroup>
              </div>
            </>
          ) : null}
        </div>
      </FormControl>
    </FormModal>
    </div>
  );
};
const Dropdown = ({ options, onChange, value }) => (
  <Select
    styles={customStyles}
    options={options}
    onChange={onChange}
    placeholder="اختر"
    value={value}
    getOptionLabel={(option) => option.label}
    getOptionValue={(option) => option.value}
  />
);