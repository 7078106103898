import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import { useUpdateVacationCategory } from "../../../../hooks/fetchers/VacationCategory";
import { useUpdateServiceCategory } from "../../../../hooks/fetchers/ServiceCategory";
import useSuccessfullModal from "../../../../Components/Modals/hooks/useSuccessfullModal";
import SuccessfullModal from "../../../../Components/Modals/SuccessfullModal";
import { CustomeInputFile } from "../../../../Components/FormUi";
import LoadingPage from "../../../../Components/feedback/LoadingPage";
// import CloseBtn from "/assets/icons/Rejected.svg"
function UpdateCitizenModal({ title, show, handleClose, id, type, data }) {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: async () => {
      // console.log("data is",data );
      return {
        name: data.name,
      };
    },
  });
  const {
    show: showImage,
    handleClose: handleCloseImage,
    handleShow,
  } = useOpenModal();
  const [content, setContent] = useState(null);
  const fileLength = watch(
    type === "vacations" ? "filesVaction" : "humanService"
  );
  const {
    handleShowSuccess,
    handleShowError,
    handleCloseSuccess,
    handleCloseError,
    showSuccess,
    showError,
  } = useSuccessfullModal();
  const { mutate: mutateVacation } = useUpdateVacationCategory(
    id,
    handleShowSuccess,
    handleShowError
  );
  const { mutate: mutateServiceHuman, isLoading: loadingServiceHumanMutation } =
    useUpdateServiceCategory(id, handleShowSuccess, handleShowError);

  const onSubmit = (data) => {
    const formData = new FormData();
    formData.append("name", data.name);
    if (type === "vacations") {
      for (const image of data.filesVaction) {
        formData.append("filesVaction", image);
      }
      mutateVacation(formData);
    } else if (type === "services") {
      for (const image of data.humanService) {
        formData.append("humanService", image);
      }
      mutateServiceHuman(formData);
    }

    handleClose();
  };

  return (
    <>
      {loadingServiceHumanMutation ? <LoadingPage /> : null}
      <Modal
        centered
        contentClassName="bg-mainItems border !border-[#EFAA20] !max-w-[700px] overflow-hidden !rounded-[20px] text-mainText"
        show={show}
        onHide={handleClose}
        size="lg"
      >
        <form
          onSubmit={handleSubmit(onSubmit)}
          encType="multipart/form-data"
          className="w-full"
        >
          <Modal.Header className="bg-mainItems !rounded-[0px] !justify-between border-none">
            <div className=""></div>
            <Modal.Title className="text-[#EFAA20] text-xl font-semibold border !border-[#EFAA20] py-2 px-7 rounded-md">
              {title}
            </Modal.Title>
            <Button
              className="p-0 bg-transparent hover:bg-transparent"
              onClick={handleClose}
            >
              <img src="/Rejected.svg" alt="" />
            </Button>
          </Modal.Header>
          <Modal.Body className="justify-start">
            <div className="w-full text-start">
              <p className="text-end py-3 px-2 font-semibold rounded-[7px] outline-none">
                اسم الخدمة
              </p>
              <input
                {...register("name", {
                  required: "يجب إدخال الاسم",
                  minLength: {
                    value: 5,
                    message: "يجب إدخال 5 حروف على الاقل",
                  },
                  maxLength: {
                    value: 200,
                    message: "لا يجب ان تزيد عن 200 حرف",
                  },
                })}
                type="text"
                placeholder="ادخل الاسم"
                className="w-full bg-mainNavy rounded-md px-2 py-3 mb-3"
              />
              {errors.name && (
                <span className="text-red-500">{errors.name.message}</span>
              )}

              <p className="text-end py-3 px-2 font-semibold rounded-[7px] outline-none">
                ارفاق صورة
              </p>

              <div className="!justify-start  gap-5 p-3 border !border-[#EFAA20] rounded-md">
                <CustomeInputFile
                  register={{
                    ...register(
                      type === "vacations" ? "filesVaction" : "humanService"
                    ),
                  }}
                  name={type === "vacations" ? "filesVaction" : "humanService"}
                  multiple={true}
                />

                {/* ********************* */}
                <Example
                  show={showImage}
                  handleClose={handleCloseImage}
                  content={content}
                />
                {/* {!fileLength?.length
                      ? userData?.data?.user?.fileUser.map((file, index) => {
                          const fileType = getType(file);
                          switch (fileType) {
                            case "jpg":
                            case "jpeg":
                            case "png":
                            case "gif":
                            case "jfif":
                              return (
                                <div
                                  key={index}
                                  onClick={() => {
                                    setContent(
                                      <>
                                        <DownloadButton file={file} />

                                        <img
                                          className="w-full  rounded-xl border"
                                          src={file}
                                          alt=""
                                        />
                                      </>
                                    );
                                    handleShow();
                                  }}
                                >
                                  <img
                                    className="w-20 h-20 rounded-xl border"
                                    src={file}
                                    alt=""
                                  />
                                </div>
                              );
                            case "pdf":
                              return (
                                <div
                                  key={index}
                                  onClick={() => {
                                    setContent(
                                      <>
                                        <DownloadButton file={file} />
                                        <PdfIcon />
                                        
                                      </>
                                    );
                                    handleShow();
                                  }}
                                  className="flex justify-center items-center w-24 h-24 rounded-xl border"
                                >
                                  <PdfIcon />
                                </div>
                              );
                            case "zip":
                              return (
                                <div
                                  key={index}
                                  onClick={() => {
                                    setContent(
                                      <>
                                        <DownloadButton file={file} />
                                        <img
                                          className="w-full rounded-xl border"
                                          src="https://p7.hiclipart.com/preview/258/300/47/computer-icons-zip-truetype-icon-png-file-zip.jpg"
                                          alt=""
                                        />
                                      </>
                                    );
                                    handleShow();
                                  }}
                                >
                                  <img
                                    className="w-24 h-24 rounded-xl border"
                                    src="https://p7.hiclipart.com/preview/258/300/47/computer-icons-zip-truetype-icon-png-file-zip.jpg"
                                    alt=""
                                  />
                                </div>
                              );
                            case "rar":
                              return (
                                <div
                                  key={index}
                                  onClick={() => {
                                    setContent(
                                      <>
                                        <DownloadButton file={file} />
                                        <img
                                          className="w-96  rounded-xl border"
                                          src="https://cdn-icons-png.freepik.com/512/9158/9158683.png"
                                          alt=""
                                        />
                                      </>
                                    );
                                    handleShow();
                                  }}
                                >
                                  <img
                                    className="w-24 h-24 rounded-xl border"
                                    src="https://cdn-icons-png.freepik.com/512/9158/9158683.png"
                                    alt=""
                                  />
                                </div>
                              );
                            default:
                              return null;
                          }
                        })
                      : null} */}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="border-none justify-center gap-2">
            <Button
              type="submit"
              className="mx-auto py-1 px-14 font-semibold text-[15px] border !border-[#EFAA20] text-white hover:text-mainText bg-[#EFAA20] hover:bg-[#EFAA20] "
            >
              حفظ
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
      <SuccessfullModal
        show={showSuccess}
        handleClose={() => {
          handleCloseSuccess();
          handleClose();
        }}
        message="تم التعديل بنجاح "
      />
      <SuccessfullModal
        show={showError}
        handleClose={handleCloseError}
        message="حدث خطأ ما"
        status="error"
      />
    </>
  );
}

export default UpdateCitizenModal;

const DownloadButton = ({ file }) => {
  return (
    <Button
      className="w-fit mx-auto text-mainText"
      download={true}
      variant="success"
      href={file}
    >
      {"تحميل"}
      {/* <FaFileDownload fontSize={25} color="#EFAA20" /> */}
    </Button>
  );
};
const useOpenModal = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return {
    show,
    handleClose,
    handleShow,
  };
};

function Example({ show, handleClose, content }) {
  return (
    <Modal
      centered
      show={show}
      onHide={handleClose}
      contentClassName="p-0 bg-mainNavy w-fit mx-auto"
      className="bg-black/50"
    >
      <Modal.Body className="flex flex-col items-center">{content}</Modal.Body>
    </Modal>
  );
}
const getType = (fileName) => {
  const fileExtension = fileName.split(".").pop().toLowerCase();
  return fileExtension;
};
