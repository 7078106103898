import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { getAllProjects } from "../../../../helper/fetchers/Projects";
import { useGetAllPlans } from "../../../../hooks/fetchers/Plans";
export const ProjectContext = createContext();

export default function ProjectContextProvier({ children }) {
  const [filterPlansParams, setFilterPlansParams] = useState({});
  const { data, isLoading , isError } = useGetAllPlans(filterPlansParams);
  const [selectedTab, setSelectedTab] = useState("كل المشاريع");
  return (
    <ProjectContext.Provider
      value={{
        projects: data,
        setFilterPlansParams,
        selectedTab,
        setSelectedTab,
        isLoading,
        isError
      }}
    >
      {children}
    </ProjectContext.Provider>
  );
}

export const useProjectContext = () => useContext(ProjectContext);
