import { Box } from "@mui/material";

export const Column = ({ children, ...props }) => {
  return (
    <Box
      className="bg-mainItems py-3 rounded-[19px] h-full flex flex-col gap-2 overflow-hidden"
      {...props}
    >
      {children}
    </Box>
  );
};
