import { useContext, useEffect, useState } from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import { Sidebar, Menu, MenuItem } from "react-pro-sidebar";
import "./AsideBar.css";
import { NavLink } from "react-router-dom";
import style from "./AsideBar.module.css";
import { FaBars } from "react-icons/fa6";
import { useSideBarProvider } from "../../../Context/SideBarProvider";
import { Avatar } from "@mui/material";
import { UserProvider } from "../../../Context/userProvider";

import { UsersParamsContext } from "../../../Context/UsersParamsContext";

import { useGetProfileGoals } from "../../../hooks/fetchers/profileProjects";
import { CheckRole } from "../../../helper/utils";
import { roles } from "../../../helper/fetchers/Tasks";
import { IoGridOutline } from "react-icons/io5";
import { RiInboxArchiveLine } from "react-icons/ri";
import ProjectIcons from "./logos/projectIcons";
import TasksIcon from "./logos/TasksIcon";
import MeetingIcon from "./logos/MeetingIcon";
import HrIcon from "./logos/HrIcon";
import AccountingIcon from "./logos/AccountingIcon";
import SettingIcon from "./logos/SettingIcon";
import ExitIcon from "./logos/ExitIcon";
import UserLogo from "./logos/UserLogo";

import CustomersIcon from "./logos/CustomersIcon";
import ChatIcon from "./logos/ChatIcon";
import AlertPageLogo from "./logos/AlertPageLogo";
import TechnicalSupport from "./logos/TechnicalSupport";
import { SettingsMenu } from "./components/SettingsMenu";
import { ProfileMenu } from "./components/ProfileMenu";
import SidebarProvider from "./SidebarContext";
import { menuRootStyles, rootStyles } from "./consts";
import useModal from "../../../hooks/useModal";
import ModuleCard from "./components/ModuleCard";
import ReportsLogo from "./logos/Reports";
import UnAuthorizedMessageModal from "../../UnAuthorizedMessageModal";
import { FaExclamationCircle } from "react-icons/fa";
import { ConnectedBadge } from "../../Users/StatusBadge";
const AsideBar = () => {
  const { data } = useGetProfileGoals();
  const [rtl, setRtl] = useState(true);
  // setting the width of the screen
  const [width, setWidth] = useState(window.innerWidth);
  const {
    collapsed,
    handleOpenCollapsed,
    handleCloseCollapsed,
    handleToggleCollapsed,
  } = useSideBarProvider();
  const { logOut } = useContext(UserProvider);
  const { user } = useContext(UsersParamsContext);

  const {
    open: isMenuOpen,
    handleOpenModal: handleOpenMenu,
    handleCloseModal: handleCloseMenu,
  } = useModal();
  const {
    open: isAuthOpen,
    handleOpenModal: handleOpenAuth,
    handleCloseModal: handleCloseAuth,
  } = useModal();
  const { open: openProfile, handleToggleModal: handleToggleProfileMenu } =
    useModal();

  // const [openProfile, setOpenProfile] = useState(false);
  // const handleOpenProfileMenu = () => setOpenProfile(!openProfile);
  // const handleToggleProfileMenu = () => setOpenProfile(!openProfile);

  function getSize() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener("resize", getSize);

    if (width <= 1440) {
      handleOpenCollapsed();
    } else {
      handleCloseCollapsed();
    }

    return () => {
      window.removeEventListener("resize", getSize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.innerWidth]);

  return (
    <SidebarProvider>
      <div
        className={`asidePar scrollbar-none rounded-[19px] flex flex-col h-full `}
        style={{ direction: rtl ? "rtl" : "ltr" }}
      >
        <div className="relative !overflow-x-visible !w-full h-full flex flex-col ">
          <Sidebar
            transitionDuration={800}
            width="320px"
            height="100%"
            collapsedWidth="100px !important"
            rootStyles={rootStyles}
            collapsed={width < 1025 ? true : collapsed}
            rtl={rtl}
            backgroundColor="var(--main-item-color)"
            className="flex-1"
            color="#FFF"
          >
            <div className="flex collapsed-handler mx-2 pt-4 px-2 justify-end">
              <FaBars
                color="#EFAA20"
                size={20}
                className="pointer"
                onClick={handleToggleCollapsed}
              />
            </div>
            <Menu
              transitionDuration={200}
              className="w-100 overflow-x-visible h-full flex-1"
              rootStyles={menuRootStyles}
            >
              <MenuItem
                className="pt-4 overflow-x-visible  center w-100"
                component={"div"}
              >
                <div className="d-flex justify-content-center flex-column align-items-center">
                  <div>
                    <ConnectedBadge
                      overlap="circular"
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      variant="dot"
                    >
                      <Avatar
                        src={user?.image}
                        alt={`${user?.firstName} ${user?.lastName}`}
                        className="user-icon"
                        onClick={handleToggleProfileMenu}
                      />
                    </ConnectedBadge>

                    <ProfileMenu
                      show={openProfile}
                      toggleMenu={handleToggleProfileMenu}
                    />
                  </div>
                  <div className="hidden-collapsed ">
                    <p className="text-xl text-center p-0 mx-0  my-2 text-mainText">
                      {user?.firstName}
                    </p>
                    <p className="golden-text "> {user?.role} </p>
                    <p className="text-sm text-mainText">{user?.email}</p>
                  </div>
                </div>
              </MenuItem>

              <MenuItem
                className="hidden-collapsed  d-flex flex-column "
                component={"div"}
              >
                <div className="goals">
                  <div className="flex flex-col gap-2">
                    <p className="text-mainText">الاهداف</p>
                    <ProgressBar
                      className="w-100"
                      variant="warning"
                      now={data?.goals}
                    />
                    <p
                      style={{ fontSize: "14px" }}
                      className="my-2 text-mainText"
                    >
                      وصلت إلى
                      <span className="text-[#D59921]">{` ${data?.goals} % `}</span>
                      من هدفك
                    </p>
                  </div>

                  <div className="grid grid-cols-2 gap-1.5">
                    <div className="cardBorder rounded-[7px] p-2 flex flex-col items-center justify-center">
                      <p className="text-mainText text-sm">
                        {CheckRole("مدير المكتب") || CheckRole("مدير")
                          ? "مشاريع منتهية"
                          : "مهمات منتهية"}
                      </p>
                      <p className="text-green-500">
                        {data?.completedGoals !== undefined
                          ? `${data?.completedGoals} %`
                          : "--------"}
                      </p>
                    </div>
                    <div className="cardBorder rounded-[7px] p-2 flex flex-col items-center justify-center">
                      <p className="text-mainText text-sm">
                        {CheckRole("مدير المكتب") || CheckRole("مدير")
                          ? "مشاريع قيد التنفيذ"
                          : "مهمات قيد التنفيذ"}
                      </p>
                      <p className="text-red-500">
                        {data?.progressGoals !== undefined
                          ? `${data?.progressGoals} %`
                          : "--------"}
                      </p>
                    </div>
                  </div>
                </div>
              </MenuItem>

              <MenuItem
                className="flex-1 overflow-y-hidden w-100"
                component={"div"}
              >
                <div className="row  system-card-container">
                  <div className="col-md-4 ">
                    <div className="system-item ">
                      <NavLink
                        to="/System/index"
                        className={({ isActive }) =>
                          isActive ? style["active-link"] : ""
                        }
                      >
                        <ModuleCard
                          icon={
                            <IoGridOutline className="aside-icon text-mainText" />
                          }
                          title={"الرئيسية"}
                        />
                      </NavLink>
                    </div>
                  </div>
                  {(CheckRole(roles["admin"]) ||
                    CheckRole(roles["administrator"])) && (
                    <div className="col-md-4 ">
                      <div className="system-item">
                        <NavLink
                          to={"/System/Clients"}
                          className={({ isActive }) =>
                            isActive ? style["active-link"] : ""
                          }
                        >
                          <ModuleCard
                            icon={<CustomersIcon />}
                            title={"العملاء"}
                          />
                        </NavLink>
                      </div>
                    </div>
                  )}
                  {(CheckRole(roles["HR"]) ||
                    CheckRole(roles["manager"]) ||
                    CheckRole(roles["accountant"]) ||
                    CheckRole(roles["admin"])) && (
                    <div className="col-md-4 ">
                      <div className="system-item">
                        <NavLink
                          to="/System/Users/index"
                          className={({ isActive }) =>
                            isActive ? style["active-link"] : ""
                          }
                        >
                          <ModuleCard
                            icon={<UserLogo />}
                            title={"المستخدمين"}
                          />
                        </NavLink>
                      </div>
                    </div>
                  )}
                  {(CheckRole(roles["administrator"]) ||
                    CheckRole(roles["accountant"]) ||
                    CheckRole(roles["admin"])) && (
                    <div className="col-md-4 ">
                      <div className="system-item">
                        <NavLink
                          to="/System/Requests/index"
                          className={({ isActive }) =>
                            isActive ? style["active-link"] : ""
                          }
                        >
                          <ModuleCard
                            icon={
                              <RiInboxArchiveLine className="aside-icon text-mainText" />
                            }
                            title={"الطلبات"}
                          />
                        </NavLink>
                      </div>
                    </div>
                  )}

                  {CheckRole(roles["admin"]) && (
                    <div className="col-md-4 ">
                      <div className="system-item">
                        <NavLink
                          to={"/System/Projects/index"}
                          className={({ isActive }) =>
                            isActive ? style["active-link"] : ""
                          }
                        >
                          <ModuleCard
                            icon={<ProjectIcons />}
                            title={"المشاريع"}
                          />
                        </NavLink>
                      </div>
                    </div>
                  )}

                  {/* {CheckRole(roles["admin"]) && ( */}
                  <UnAuthorizedMessageModal
                    show={isAuthOpen}
                    onHide={handleCloseAuth}
                    render={() => (
                      <div className="flex items-center gap-2">
                        <p className="text-3xl text-[#EFAA20]">
                          {"القسم تحت الانشاء"}
                        </p>
                        <span className="text-2xl text-[#EFAA20] mt-1">
                          <FaExclamationCircle />
                        </span>
                      </div>
                    )}
                  />
                  <div className="col-md-4 ">
                    <div className="system-item">
                      <NavLink
                        to={CheckRole(roles["admin"]) ? "/System/Reports" : "#"}
                        className={({ isActive }) =>
                          isActive ? style["active-link"] : ""
                        }
                        onClick={
                          !CheckRole(roles["admin"]) ? handleOpenAuth : null
                        }
                      >
                        <ModuleCard icon={<ReportsLogo />} title={"التقارير"} />
                      </NavLink>
                    </div>
                  </div>
                  {/* )} */}

                  {(CheckRole(roles["user"]) ||
                    CheckRole(roles["manager"]) ||
                    CheckRole(roles["administrator"]) ||
                    CheckRole(roles["HR"]) ||
                    CheckRole(roles["accountant"]) ||
                    CheckRole(roles["admin"]) ||
                    CheckRole(roles["auditor"])) && (
                    <div className="col-md-4 ">
                      <div className="system-item">
                        <NavLink
                          to={"/System/tasks"}
                          className={({ isActive }) =>
                            isActive ? style["active-link"] : ""
                          }
                        >
                          <ModuleCard icon={<TasksIcon />} title={"المهام"} />
                        </NavLink>
                      </div>
                    </div>
                  )}
                  {(CheckRole(roles["user"]) ||
                    CheckRole(roles["auditor"]) ||
                    CheckRole(roles["manager"]) ||
                    CheckRole(roles["administrator"]) ||
                    CheckRole(roles["HR"]) ||
                    CheckRole(roles["accountant"]) ||
                    CheckRole(roles["admin"])) && (
                    <div className="col-md-4 ">
                      <div className="system-item">
                        <NavLink
                          to={"/System/Meetings/index"}
                          className={({ isActive }) =>
                            isActive ? style["active-link"] : ""
                          }
                        >
                          <ModuleCard
                            icon={<MeetingIcon />}
                            title={"الاجتماعات"}
                          />
                        </NavLink>
                      </div>
                    </div>
                  )}
                  {(CheckRole(roles["user"]) ||
                    CheckRole(roles["auditor"]) ||
                    CheckRole(roles["administrator"]) ||
                    CheckRole(roles["HR"]) ||
                    CheckRole(roles["manager"]) ||
                    CheckRole(roles["accountant"]) ||
                    CheckRole(roles["admin"])) && (
                    <div className="col-md-4">
                      <div className="system-item">
                        <NavLink
                          onClick={(e) => {
                            if (
                              !(
                                // !CheckRole(roles["user"]) &&
                                (
                                  !CheckRole(roles["auditor"])
                                )
                              )
                            ) {
                              e.preventDefault();
                            }
                          }}
                          // to={
                          //   !CheckRole(roles["user"]) &&
                          //   !CheckRole(roles["auditor"]) &&
                          //   !CheckRole(roles["manager"])
                          //     ? "/System/Hr/index"
                          //     : "#"
                          // }
                          // hr - admin - manager all
                          // accept - reject [admin - manager]
                          to={
                            CheckRole(roles["admin"]) ||
                            CheckRole(roles["manager"]) ||
                            CheckRole(roles["HR"])
                              ? "/System/Hr/index"
                              : "/System/Hr/HolidayMangment"
                          }
                          className={({ isActive }) =>
                            isActive ? style["active-link"] : ""
                          }
                        >
                          <ModuleCard
                            icon={<HrIcon />}
                            title={"الموارد البشرية"}
                          />
                        </NavLink>
                      </div>
                    </div>
                  )}
                  {(CheckRole(roles["accountant"]) ||
                    CheckRole(roles["admin"])) && (
                    <div className="col-md-4 ">
                      <div className="system-item">
                        <NavLink
                          to={"/System/Accounating/index"}
                          className={({ isActive }) =>
                            isActive ? style["active-link"] : ""
                          }
                        >
                          <ModuleCard
                            icon={<AccountingIcon />}
                            title={"الحسابات"}
                          />
                        </NavLink>
                      </div>
                    </div>
                  )}
                  {/* {(
                    CheckRole(roles["admin"]) ||
                    CheckRole(roles["administrator"]) ||
                    CheckRole(roles["manager"])
                  ) && ( */}
                  <div className="col-md-4 ">
                    <div className="system-item">
                      <NavLink
                        to={"/System/Chat/index"}
                        className={({ isActive }) =>
                          isActive ? style["active-link"] : ""
                        }
                      >
                        <ModuleCard icon={<ChatIcon />} title={"محادثاتى"} />
                      </NavLink>
                    </div>
                  </div>
                  {/* )} */}

                  {(CheckRole(roles["admin"]) ||
                    CheckRole(roles["administrator"])) && (
                    <div className="col-md-4 ">
                      <div className="system-item">
                        <div
                          className="system-card border !border-transparent  hover:!border-[#EFAA20]"
                          onClick={handleOpenMenu}
                        >
                          <div className="card__content d-flex justify-content-center align-items-center  flex-column  ">
                            <SettingIcon />
                            <p className="text-mainText">الاعدادات </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {/* {CheckRole(roles["admin"]) && ( */}
                  <div className="col-md-4 ">
                    <div className="system-item">
                      <NavLink
                        to={"/System/reminder"}
                        className={({ isActive }) =>
                          isActive ? style["active-link"] : ""
                        }
                      >
                        <div className="system-card border !border-transparent hover:!border-[#EFAA20]">
                          <div className="card__content d-flex justify-content-center align-items-center  flex-column  ">
                            <AlertPageLogo />
                            <p className="text-mainText">تنبيه</p>
                          </div>
                        </div>
                      </NavLink>
                    </div>
                  </div>
                  {/* )} */}
                  <div className="col-md-4 ">
                    <div className="system-item">
                      <NavLink
                        to={"/System/technical-support"}
                        className={({ isActive }) =>
                          isActive ? style["active-link"] : ""
                        }
                      >
                        <div className="system-card border !border-transparent hover:!border-[#EFAA20]">
                          <div className="card__content d-flex justify-content-center align-items-center  flex-column  ">
                            <TechnicalSupport />
                            <p className="text-mainText">الدعم الفني</p>
                          </div>
                        </div>
                      </NavLink>
                    </div>
                  </div>
                  {/* خروج */}
                  <div className="col-md-4 ">
                    <div className="system-item" onClick={logOut}>
                      <div className="system-card border !border-transparent  hover:!border-[#EFAA20]">
                        <div className="card__content d-flex justify-content-center align-items-center  flex-column  ">
                          <ExitIcon />
                          <p className="text-mainText">خروج </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <SettingsMenu
                  handleCloseMenu={handleCloseMenu}
                  openSettingsMenu={isMenuOpen}
                />
              </MenuItem>
            </Menu>
          </Sidebar>
        </div>
      </div>
    </SidebarProvider>
  );
};

export default AsideBar;

// const MenuContainer = forwardRef(({ children }, ref) => {
//   return (
//     <div
//       className={`!absolute p-2 z-[100000] border !border-[#2B2B40] right-[62%] !top-[10%] transition-all ease-in-out duration-500 bg-[#1E1E2D] rounded-[14px] w-[0px] h-[0] opacity-0 ${
//         show && "!h-[310px] !w-[245px] !opacity-100"
//       } `}
//     >
//       {show ? (
//         <div className="relative">
//           <div className="mx-2 flex gap-2">
//             {/* should be user img */}
//             <Image
//               src={user?.image}
//               alt="user img"
//               className={"w-[51px] h-[51px] rounded-[50%]"}
//             />
//             <div className="flex flex-col">
//               <p className="text-white text-base">{user?.firstName} </p>
//               <p className="text-[#EFAA20] text-xs"> {user?.role} </p>
//               <span className="text-[#565674] text-xs"> {user?.email}</span>
//             </div>
//           </div>
//           <div className="bg-[#D5992133] h-[1px] w-full my-2"></div>
//           <div onClick={toggleMenu} className="mt-2 mb-3 mx-2">
//             <NavLink className="w-full " to="/Profile">
//               <div>
//                 <p className="text-sm text-white">صفحتى الشخصية</p>
//               </div>
//             </NavLink>
//           </div>
//           {/* "/System/Projects/index" */}
//           <NavLink
//             to={
//               data?.projectNumber === 50
//                 ? "/System/tasks/plans"
//                 : "/System/tasks/tasks"
//             }
//             onClick={toggleMenu}
//             className="mt-2  flex justify-between items-center pointer h-[33px] mb-3 mx-2"
//           >
//             <p className=" text-sm text-white">
//               {data?.projectNumber === 50 ? "مشاريعي" : "مهامى"}
//             </p>

//             <Badge badgeContent={data?.projects || 0} color="error"></Badge>
//           </NavLink>

//           <div
//             onClick={toggleMenu}
//             className="mt-2  pointer h-[33px] mb-3 mx-2"
//           >
//             <NavLink
//               className="flex justify-between items-center"
//               to="/System/Chat/index"
//             >
//               <p className="text-sm text-white"> محادثاتى</p>
//               {/* <Badge badgeContent={3} color="error"></Badge> */}
//             </NavLink>
//           </div>

//           <div className="mt-2 pointer relative h-[33px] mb-3 mx-1 flex items-center justify-between">
//             <p className="text-white">اللغة </p>

//             {lang === "ar" ? (
//               <div
//                 // onClick={handlelang}
//                 dir="rtl"
//                 className="w-full my-3 mx-2  flex   justify-end gap-3 "
//               >
//                 <p className="text-white">العربية (KSA)</p>
//                 <Image
//                   src="/saudiFlagicon.png"
//                   alt="sauida flag"
//                   className={"rounded-[50%]  w-[21.26px] h-[21.26px] "}
//                 />
//               </div>
//             ) : (
//               <div
//                 onClick={handlelang}
//                 dir="rtl"
//                 className="w-full my-3 mx-2  flex   justify-end gap-3 "
//               >
//                 <p className="text-white"> English (USA)</p>
//                 <Image
//                   src="/usa.png"
//                   alt="usa flag"
//                   className={"rounded-[50%]  w-[21.26px] h-[21.26px] "}
//                 />
//               </div>
//             )}

//             <div
//               className={`lang-meu absolute p-3  border !border-[#2B2B40] transition-all ease-in-out duration-500 right-[110%] top-[100%] w-[0px] h-[0] opacity-0  bg-[#1E1E2D] rounded-[14px]
//             ${showLangMenu && "!w-[172px] !h-[118px] !opacity-100"}
//             `}
//             >
//
//               <div
//                 onClick={() => {
//                   setLang("ar");
//                   setShowLangMenu(false);
//                 }}
//                 dir="rtl"
//                 className="w-full my-3 mx-2  flex   justify-end gap-3 "
//               >
//                 <p className="text-white">العربية (KSA)</p>
//                 <Image
//                   src="/saudiFlagicon.png"
//                   alt="sauida flag"
//                   className={"rounded-[50%]  w-[21.26px] h-[21.26px] "}
//                 />
//               </div>
//               <div
//                 onClick={() => {
//                   setLang("en");
//                   setShowLangMenu(false);
//                 }}
//                 dir="rtl"
//                 className="w-full my-3 mx-2  flex   justify-end gap-3 "
//               >
//                 <p className="text-white"> English (USA)</p>
//                 <Image
//                   src="/usa.png"
//                   alt="usa flag"
//                   className={"rounded-[50%]  w-[21.26px] h-[21.26px] "}
//                 />
//               </div>
//             </div>
//           </div>

//           <div
//             className=" w-full
//            bg-[#D5992133] h-[1px]  my-2"
//           ></div>
//           <div onClick={toggleMenu} className="mt-2 pointer h-[33px] mb-3 mx-2">
//             <p className="text-sm text-white"> تسجيل الخروج</p>
//           </div>
//         </div>
//       ) : null}
//     </div>
//   );
// };

/**
 * user => الرئيسية - المهام - الاجتماعات - موارد بشرية
 * user (إدارى)=> الطلبات - المشاريع - الاعدادات
 * administrator => الرئيسية - المهام - الاجتماعات  - الطلبات - المشاريع - الاعدادات
 * manager => الرئيسية - المهام - الاجتماعات - موارد بشرية
 */
