import { useState } from "react";
import SuccessfullModal from "../../../../../../Components/Modals/SuccessfullModal";
import {
  useGetServicesById,
  useUpdateService,
} from "../../../../../../hooks/fetchers/Services";
import AddModal from "../../../AddModal";
import useSuccessfullModal from "../../../../../../Components/Modals/hooks/useSuccessfullModal";
import LoadingPage from "../../../../../../Components/feedback/LoadingPage";
import { joiResolver } from "@hookform/resolvers/joi";
import { updateServiceSchema } from "../../../../../../utiltis/Schemas/Services/schema";

export const UpdateServiceModal = ({ id, show, handleClose }) => {
  const [newService, setNewService] = useState("");

  const {
    handleShowSuccess,
    handleShowError,
    handleCloseSuccess,
    handleCloseError,
    showSuccess,
    showError,
  } = useSuccessfullModal();
  const { mutate: updateMutation, isLoading } = useUpdateService(
    handleShowSuccess,
    handleShowError,
    id
  );
  const {
    data,
    isLoading: serviceIsLoading,
    isError: serviceIsError,
  } = useGetServicesById(id);
  return (
    <>
      {isLoading ? <LoadingPage /> : null}
      {!serviceIsError && !serviceIsLoading ? (
        <AddModal
          title={"تعديل"}
          show={show}
          handleClose={handleClose}
          name={"name"}
          isLoading={isLoading}
          handleSave={(data) => {
            updateMutation(data);
            handleClose();
          }}
          defaultValue={data?.name || ""}
          formOptions={{
            resolver: joiResolver(updateServiceSchema("ar")),
          }}
        />
      ) : null}

      <SuccessfullModal
        show={showSuccess}
        handleClose={() => {
          handleCloseSuccess();
          handleClose();
        }}
        message="تم التعديل بنجاح "
      />
      <SuccessfullModal
        show={showError}
        handleClose={handleCloseError}
        message="حدث خطأ ما"
        status="error"
      />
    </>
  );
};
