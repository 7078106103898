import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Progress from "../Progress";
import { IoCloudUploadOutline } from "react-icons/io5";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

// import CloseBtn from "/assets/icons/Rejected.svg"
function UploadFileModal({ show, handleClose, handleSave, loading, name }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    handleSave(data[name]);
  };
  return (
    <>
      <Modal
        centered
        contentClassName="bg-mainItems border py-3 !max-w-[700px] !border-[#EFAA20] !rounded-[20.27px] text-mainText"
        show={show}
        size="lg"
        onHide={handleClose}
      >
        <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
          <Modal.Body>
            <label className="bg-mainItems rounded-md flex flex-col justify-center items-center mb-1 gap-3 p-2 cursor-pointer border !border-mainBorder ">
              <input
                type="file"
                className="hidden"
                {...register(name, {
                  required: "يجب اضافة ملفات",
                })}
                multiple
              />
              <IoCloudUploadOutline fontSize={25} />
              <p>ارفق ملف المشروع</p>
            </label>
            <ErrorMessage
              errors={errors}
              name={name}
              render={({ message }) => (
                <p className="text-red-600">{message}</p>
              )}
            />
          </Modal.Body>
          <Modal.Footer className="border-none">
            <Button
              type="submit"
              className="mx-auto py-1 px-14 font-semibold text-[13px] border !border-[#EFAA20] text-white bg-[#EFAA20] hover:bg-[#EFAA20]"
              // onClick={handleClose}
            >
              {loading ? <Progress isSmall /> : "حفظ"}
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
}

export default UploadFileModal;
