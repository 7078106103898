import { useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import FormControl from "@mui/material/FormControl";
// import FormLabel from "@mui/material/FormLabel";
// import FormHelperText from "@mui/material/FormHelperText";
import { InputLabel } from "../../../Pages/System/PlanModel/components/InputLabel";
import { useForm } from "react-hook-form";

export default function UpdateModal({
  title,
  show,
  handleClose,
  onSave,
  setData,
  id,
  data,
}) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: data.name,
      descraption: data.descraption,
    },
  });

  const onSubmit = (data) => {
    // console.log(data);
    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("descraption", data.descraption);
    if (data.images) {
      for (let i = 0; i < data.images.length; i++) {
        formData.append("image", data.images[i]);
      }
    }
    // formData.append("image", fileInput.current.files[0], "/C:/Users/assets/analysis-img.png.png");
    // console.log({...data,"image":fileInput.current.files[0]});

    onSave(formData);
    handleClose();
  };
  return (
    <Modal
      centered
      contentClassName=" border !max-w-[700px] !border-[#EFAA20] !rounded-[20px] text-mainText"
      show={show}
      onHide={handleClose}
      size="lg"
    >
      <form onSubmit={handleSubmit(onSubmit)} className="w-full">
        <Modal.Header className="!justify-between border-none w-full">
          <div className=""></div>
          <Modal.Title className="text-xl text-[#EFAA20] border !border-[#EFAA20] p-2 rounded-[5px] font-medium ">
            {title}
          </Modal.Title>
          <Button
            className="p-0 bg-transparent hover:bg-transparent"
            onClick={handleClose}
          >
            <img src="/Rejected.svg" alt="" />
          </Button>
        </Modal.Header>
        <Modal.Body className="w-full">
          <FormControl fullWidth className="mb-3">
            <InputLabel size={18} label={"اسم البند"} className={"mb-3"} />
            <input
              className="w-full  bg-mainNavy py-3 px-2 rounded-[7px] outline-none"
              type="text"
              placeholder="ادخل الاسم"
              {...register("name", {
                required: true,
                minLength: 5,
                maxLength: 200,
              })}
            />
            {errors.name && errors.name.type === "required" && (
              <span className="text-red-500">This field is required</span>
            )}
            {errors.name && errors.name.type === "minLength" && (
              <span className="text-red-500">
                Name must be at least 5 characters long
              </span>
            )}
            {errors.name && errors.name.type === "maxLength" && (
              <span className="text-red-500">
                Name cannot exceed 200 characters
              </span>
            )}
          </FormControl>
          <FormControl fullWidth className="mb-3">
            <InputLabel size={18} label={"الوصف"} className={"mb-3"} />
            <textarea
              className="w-full bg-mainNavy py-3 px-2 rounded-[7px] outline-none"
              rows={5}
              placeholder="ادخل وصف البند"
              {...register("descraption", { minLength: 5, maxLength: 5000 })}
            ></textarea>
          </FormControl>
          <FormControl fullWidth className="mb-3">
            <InputLabel size={18} label={"ارفاق صورة"} className={"mb-3"} />
            <label className=" bg-mainNavy flex justify-center items-center rounded-[7px] py-5">
              <input
                type="file"
                {...register("images")}
                multiple
                className="hidden"
              />
              <img src="/upload-icon.svg" alt="upload" />
            </label>
          </FormControl>
        </Modal.Body>
        <Modal.Footer className="border-none w-full">
          <Button
            type="submit"
            className="mx-auto py-1 px-14 font-semibold text-[15px] border !border-[#EFAA20] text-white hover:text-white bg-[#EFAA20] hover:bg-[#EFAA20] "
          >
            حفظ
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
