import React from "react";
import { AllCategories } from "../../../../Components/System/AllCategories/AllCategories";
import { Accordion } from "react-bootstrap";
import { Link, Outlet, useLocation } from "react-router-dom";
import { MdKeyboardArrowDown } from "react-icons/md";
import AddProject from "../../../../Components/System/Projects/AddProject/AddProject";
import { useState } from "react";
import AddReviewReport from "../../../../Components/System/Projects/AddReviewReport/AddReviewReport";
import AddUpdateDesignReport from "../../../../Components/System/Projects/AddDesignReport/AddUpdateDesignReport";
import { ShowProjectProvider } from "../../../../Context/showProject";
import CustomHeader from "./customHeader";
import { useProjectContext } from "./ProjectContext";

const AllProjects = () => {
  const [active, setActive] = useState();
  // handling Add project
  const [view, setView] = useState(false);
  const { pathname } = useLocation();
  const pagePath = pathname.split("/System/Projects/Main")[1];

  const handleOpen = () => {
    setView(true);
  };
  const handleClose = () => {
    setView(false);
  };

  const { setTitle } = useProjectContext()


  return (
    <ShowProjectProvider>
      <CustomHeader
        view={view}
        active={active}
        setActive={setActive}
        handleOpen={handleOpen}
        handleClose={handleClose}
      />
      {view && pagePath === "/ReportManagement/DesignReports" ? (
        <AddUpdateDesignReport handleClose={handleClose} />
      ) : view && pagePath === "/ReportManagement/ReviewReports" ? (
        <AddReviewReport handleClose={handleClose} />
      ) : view ? (
        <AddProject />
      ) : (
        <div className="grid grid-cols-12 gap-2 h-full">
          <div className="hidden xl:flex col-span-3">
            <AllCategories
              child={
                <div className="d-flex  flex-column   align-items-center ">
                  <div className="mt-4 w-100">
                    <Link
                      className="!w-full pointer"
                      to={"/System/Projects/index"}
                    >
                      <p
                        onClick={() => {
                          setActive(null);
                        }}
                        className=" text-mainText "
                      >
                        كل المشاريع
                      </p>
                    </Link>
                  </div>

                  <div className="pointer !w-full mt-0">
                    
                    <div className=" d-flex  justify-content-center flex-column">
                      <Accordion defaultActiveKey={null}>
                        <Accordion.Item eventKey="0">
                          <Accordion.Header
                            className={`  ${
                              active === 0
                                ? " border-2  rounded-md hover:border-transparent  border-[#EFAA20] "
                                : "border-2 border-mainBorder rounded-md"
                            }`}
                            onClick={() => {
                              setActive(0);
                              setTitle("مشاريع قيد التنفيذ")
                            }}
                          >
                            <Link to={"System/Projects/Main/inProgress"}>
                              مشاريع قيد التنفيذ
                            </Link>

                            <MdKeyboardArrowDown size={20} />
                          </Accordion.Header>

                          <Accordion.Body>
                            <div className="tabs !w-full d-flex justify-content-center align-items-center flex-column">
                              <Link
                                className="!w-full pointer"
                                to={"System/Projects/Main/inProgress/Design"}
                                onClick={()=> {
                                  setTitle("مشاريع قيد التنفيذ (تصميم)")
                                }}
                              >
                                <div className="tab  text-end !w-full">
                                  تصميم
                                </div>
                              </Link>
                              <Link
                                className="!w-full pointer"
                                to={"System/Projects/Main/inProgress/Review"}
                                onClick={()=> {
                                  setTitle("مشاريع قيد التنفيذ (الاشراف علي التنفيذ)")
                                }}
                              >
                                <div className="tab  text-end !w-full">
                                  الاشراف علي التنفيذ
                                </div>
                              </Link>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                          <Accordion.Header
                            className={`  ${
                              active === 1
                                ? " border-2 rounded-md hover:border-transparent  border-[#EFAA20] "
                                : "border-2 border-mainBorder rounded-md"
                            }`}
                            onClick={() => {
                              setActive(1);
                              setTitle("مشاريع معلقة")
                            }}
                          >
                            <Link
                              className="!w-full !text-right pointer"
                              to={"System/Projects/Main/Waiting"}
                            >
                              
                              مشاريع معلقة
                            </Link>

                            <MdKeyboardArrowDown size={20} />
                          </Accordion.Header>

                          <Accordion.Body>
                            <div className="tabs !w-full d-flex justify-content-center align-items-center flex-column">
                              <Link
                                className="!w-full pointer"
                                to={"System/Projects/Main/Waiting/Design"}
                                onClick={()=> {
                                  setTitle("مشاريع معلقة (تصميم)")
                                }}
                              >
                                <div className="tab  text-end !w-full">
                                  تصميم
                                </div>
                              </Link>
                              <Link
                                className="!w-full pointer"
                                to={"System/Projects/Main/Waiting/Review"}
                                onClick={()=> {
                                  setTitle("مشاريع معلقة (الاشراف علي التنفيذ)")
                                }}
                              >
                                <div className="tab  text-end !w-full">
                                  الاشراف علي التنفيذ
                                </div>
                              </Link>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                          <Accordion.Header
                            className={`  ${
                              active === 2
                                ? " border-2 rounded-md hover:border-transparent  border-[#EFAA20] "
                                : "border-2 border-mainBorder rounded-md"
                            }`}
                            onClick={() => {
                              setActive(2);
                              setTitle("مشاريع منتهية")
                            }}
                          >
                            <Link
                              className="!w-full pointer  !text-right"
                              to={"System/Projects/Main/Done"}
                            >
                              
                              مشاريع منتهية
                            </Link>

                            <MdKeyboardArrowDown size={20} />
                          </Accordion.Header>

                          <Accordion.Body>
                            <div className="tabs !w-full d-flex justify-content-center align-items-center flex-column">
                              <Link
                                className="!w-full pointer"
                                to={"System/Projects/Main/Done/Design"}
                                onClick={()=> {
                                  setTitle("مشاريع منتهية (تصميم)")
                                }}
                              >
                                <div className="tab  text-end !w-full">
                                  تصميم
                                </div>
                              </Link>
                              <Link
                                className="!w-full pointer"
                                to={"System/Projects/Main/Done/Review"}
                                onClick={()=> {
                                  setTitle("مشاريع منتهية (الاشراف علي التنفيذ)")
                                }}
                              >
                                <div className="tab  text-end !w-full">
                                  الاشراف علي التنفيذ
                                </div>
                              </Link>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </div>
                  </div>
                </div>
              }
            />
          </div>
          <div className="col-span-12 xl:col-span-9">
            <Outlet />
          </div>
        </div>
      )}
    </ShowProjectProvider>
  );
};

export default AllProjects;
