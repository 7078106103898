import { useQuery, useMutation, useQueryClient } from "react-query";
import {
  addSubService,
  deleteSubService,
  getSubServiceById,
  getSubServices,
  updateSubService,
} from "../../helper/fetchers/SubServices";

export const useGetSubServices = (serviceId) => {
  const query = useQuery(["services", serviceId], () =>
  getSubServices(serviceId),
  {
    enabled: !!serviceId
  }
  );

  return query;
};
export const useGetSubServiceById = (serviceId,subId) => {
  const query = useQuery(["services", serviceId,subId], () =>
    getSubServiceById(serviceId,subId),
  {
    enabled: (!!serviceId && !!subId)
  }
  );

  return query;
};

export const useAddSubService = (onSuccess,onError, serviceId) => {
  const queryClient = useQueryClient();
  return useMutation(
    (subserviceData) => addSubService(serviceId, subserviceData),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("services");
        onSuccess();
      },
      onError: (error) => {
        onError()
      },
    }
  );
};
export const useUpdateSubService = (onSuccess,onError, serviceID, subId) => {
  const queryClient = useQueryClient();
  return useMutation((data) => updateSubService(serviceID, subId, data), {
    onSuccess: () => {
      queryClient.invalidateQueries("services");
      onSuccess();
    },
    onError: (error) => {
      onError()
    },
  });
};

export const useDeleteSubService = (onSuccess,onError) => {
  const queryClient = useQueryClient();
  return useMutation(deleteSubService, {
    onSuccess: () => {
      queryClient.invalidateQueries("services");
      onSuccess();
    },
    onError: (error) => {
      onError()
    },
  });
};
