import React from "react";

const UserLogo = () => {
  return (
    <svg
      width="27"
      height="18"
      viewBox="0 0 27 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.98228 12.6965C12.1662 12.6965 14.7473 10.1154 14.7473 6.9315C14.7473 3.74758 12.1662 1.1665 8.98228 1.1665C5.79836 1.1665 3.21729 3.74758 3.21729 6.9315C3.21729 10.1154 5.79836 12.6965 8.98228 12.6965Z"
        stroke="var(--main-text)"
        strokeWidth="1.32789"
        strokeMiterlimit="10"
      />
      <path
        d="M16.4546 1.37715C16.9642 1.23893 17.4898 1.16811 18.0178 1.1665C19.5468 1.1665 21.0131 1.77389 22.0943 2.85503C23.1754 3.93618 23.7828 5.40253 23.7828 6.9315C23.7828 8.46047 23.1754 9.92682 22.0943 11.008C21.0131 12.0891 19.5468 12.6965 18.0178 12.6965"
        stroke="var(--main-text)"
        strokeWidth="1.32789"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 16.843C1.90017 15.5621 3.0954 14.5166 4.48473 13.7948C5.87406 13.0731 7.41668 12.6963 8.9823 12.6963C10.5479 12.6963 12.0905 13.0731 13.4799 13.7948C14.8692 14.5166 16.0644 15.5621 16.9646 16.843"
        stroke="var(--main-text)"
        strokeWidth="1.32789"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.0186 12.6973C19.5843 12.6963 21.1272 13.0726 22.5167 13.7944C23.9062 14.5161 25.1013 15.5621 26.0009 16.8436"
        stroke="var(--main-text)"
        strokeWidth="1.32789"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default UserLogo;
