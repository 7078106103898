import React from "react";

import { UserImage } from "./UserImage";
import { CommentBody } from "./CommentBody";
import { ReplyIcon } from "./ReplyIcon";
const CommentCard = ({ isReply = false,rep={}, onOpenReply , comment = {}}) => {
  // console.log('comment',comment);
  // console.log('comment createdBy',comment.createdBy);
  return (
    <div className="flex items-start justify-between gap-3">
      <UserImage
        name={`${comment.createdBy.firstName} ${comment.createdBy.lastName}`}
        image={comment.createdBy.image}
      />
      <CommentBody isReply={isReply} comment={comment} rep={rep} />
      <ReplyIcon onClick={() => onOpenReply(comment._id)} />
    </div>
  );
};

export default CommentCard;
