import React, { useState } from "react";
import { Button, FormControl, Modal } from "react-bootstrap";
import { FormModal } from "../PlanModel/components/FormModal";
import { InputLabel } from "../PlanModel/components/InputLabel";
import { CustomInput } from "../../../Components/FormUi/CustomInput";
import { useForm } from "react-hook-form";
import { CustomDatePicker } from "../../../Components/FormUi/CustomDatePicker";
import { useUpdateMyProfile } from "../../../hooks/fetchers/Users";
import useSuccessfullModal from "../../../Components/Modals/hooks/useSuccessfullModal";
import SuccessfullModal from "../../../Components/Modals/SuccessfullModal";
import LoadingPage from "../../../Components/feedback/LoadingPage";
import { CustomeInputFile } from "../../../Components/FormUi/CustomeInputFile";
import { PhoneInput } from "react-international-phone";

function UpdateFormModal({ show, handleClose, userData }) {
  const [phone, setPhone] = useState(userData?.phone ? userData.phone : "");

  const {
    handleShowSuccess,
    handleShowError,
    handleCloseSuccess,
    handleCloseError,
    showSuccess,
    showError,
  } = useSuccessfullModal();
  const { mutate, isLoading } = useUpdateMyProfile(() => {
    handleShowSuccess();
    handleClose();
  }, handleShowError);
  const { control, handleSubmit, register } = useForm({
    defaultValues: {
      firstName: userData.firstName,
      lastName: userData.lastName,
      phone: phone,
      email: userData.email,
      idDate: new Date(userData.idDate),
      bio: userData.bio,
    },
  });
  const onSubmit = (data) => {
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      if (key === "personalAttach") {
        for (const iterator of data[key]) {
          formData.append(key, iterator);
        }
      } else if (key === "phone") {
        formData.append("phone", phone.length > 6 ? phone : "");
      } else {
        formData.append(key, data[key]);
      }
      // console.log(key,data[key])
    });
    mutate(formData);
  };
  console.log("userData:", userData);
  return (
    <ModalWrapper show={show} handleClose={handleClose}>
      {isLoading ? <LoadingPage /> : null}
      <SuccessfullModal
        handleClose={handleCloseSuccess}
        message={"تم التعديل بنجاح"}
        show={showSuccess}
      />
      <SuccessfullModal
        handleClose={handleCloseError}
        message={"حدث خطأ ما"}
        show={showError}
        status="error"
      />
      <form
        className="w-full"
        encType="multipart/form-data"
        onSubmit={handleSubmit(onSubmit)}
      >
        <FormModal>
          <div className="grid grid-cols-2 gap-3 ">
            <div className="flex flex-col">
              <InputLabel label={"الاسم الاول"} />
              <CustomInput name={"firstName"} control={control} />
            </div>
            <div className="flex flex-col">
              <InputLabel label={"الاسم الخير"} />
              <CustomInput name={"lastName"} control={control} />
            </div>
            <div className="flex flex-col">
              <InputLabel label={"رقم الجوال"} />
              <PhoneInput
                defaultCountry="sa"
                placeholder="ادخل رقم الجوال"
                value={phone}
                onChange={(phone) => setPhone(phone)}
              />
              {/* <CustomInput name={"phone"} control={control} /> */}
            </div>
            <div className="flex flex-col">
              <InputLabel label={"البريد الالكترونى"} />
              <CustomInput name={"email"} control={control} />
            </div>
            <div className="flex flex-col">
              <InputLabel label={"تاريخ الهوية"} />
              <CustomDatePicker name={"idDate"} control={control} />
            </div>
            <div className="col-span-2 flex flex-col">
              <InputLabel label={"نبذة عن"} />
              <CustomInput name={"bio"} control={control} multiline rows={5}  />
            </div>
          </div>
        </FormModal>
        <FormModal title={"مرفقاتى "}>
          <CustomeInputFile
            register={{ ...register("personalAttach") }}
            name="personalAttach"
            multiple={true}
          />
        </FormModal>
        <div className="w-full mt-2 flex justify-center">
          <Button
            type="submit"
            className="mx-auto py-1 px-14 font-semibold text-[15px] border !border-[#EFAA20] text-white bg-[#EFAA20] focus:bg-[#EFAA20] focus-visible:bg-[#EFAA20] active:!bg-[#EFAA20] hover:bg-[#EFAA20]"
          >
            حفظ التغيرات
          </Button>
        </div>
      </form>
    </ModalWrapper>
  );
}

const ModalWrapper = ({ children, show, handleClose }) => {
  return (
    <Modal
      size="lg"
      centered
      show={show}
      onHide={handleClose}
      contentClassName="p-3 bg-mainItems "
      className="bg-black/50"
    >
      <div className="mx-auto py-1 px-14 font-semibold rounded text-[15px] border !border-[#EFAA20] text-[#EFAA20] bg-mainItems">
        <h2>تعديل</h2>
      </div>

      <Modal.Body className="flex flex-col items-center my-2 w-full">
        {children}
      </Modal.Body>
    </Modal>
  );
};

export default UpdateFormModal;
