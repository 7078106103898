import { Fragment, useContext, useState } from "react";
import styles from "./MainProjects.module.css";
import "./MainProjects.css";

import ConfirmPoper from "../../../../Components/System/ConfirmPoper.jsx";
import PieChart from "../../../../Components/pieChart.jsx";
import { useEffect } from "react";
import ShowProjectComponent from "../../../../Components/System/Projects/ShowProjectComponent.jsx";
import EditProject from "../../../../Components/System/Projects/EditProject/EditProject.js";
import Image from "../../../../Components/Image.jsx";
import CustomTable from "../../../../Components/Table/index.jsx";
import { TableRow } from "../../../../Components/Table/TableRow.jsx";
import { TableCell } from "../../../../Components/Table/TableCell.jsx";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import { showProjectContext } from "../../../../Context/showProject.js";
import { CheckRole, GenerateColumnsNames } from "../../../../helper/utils.jsx";
import Progress from "../../../../Components/Progress.jsx";
import {
  useDeleteProject,
  useGetAllProjects,
  useGetAllProjectsStatus,
} from "../../../../hooks/fetchers/Projects.jsx";
import { SearchComponent } from "../../../../Components/SearchComponent/SearchComponent.jsx";
import { RiDeleteBin6Line } from "react-icons/ri";
import CustomCheckboxTable from "../../../../Components/CustomCheckboxTable.jsx";
import useSuccessfullModal from "../../../../Components/Modals/hooks/useSuccessfullModal.jsx";
import LoadingPage from "../../../../Components/feedback/LoadingPage.jsx";
import SuccessfullModal from "../../../../Components/Modals/SuccessfullModal.jsx";
import { useProjectContext } from "../AllProjects/ProjectContext.jsx";
import TableDeleteButton from "../../../../Components/Buttons/TableDeleteButton.jsx";
import { roles } from "../../../../helper/fetchers/Tasks.jsx";
const columns = GenerateColumnsNames(
  "تحديد",
  "م",
  "اسم المشروع",
  " رقم الطلب ",
  "نوع العميل",
  "تاريخ الانشاء",
  "نوع الدفعة",
  "عرض",
  "تعديل"
);
const MainProjects = () => {
  const { ProjectTime } = useParams();
  const [projectStatus, setProjectStatus] = useState(
    ProjectTime === "inProgress" ? 1 : ProjectTime === "Waiting" ? 0 : 2
  );
  useEffect(() => {
    setProjectStatus(
      ProjectTime === "inProgress" ? 1 : ProjectTime === "Waiting" ? 0 : 2
    );
  }, [ProjectTime]);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const {
    data: projects,
    isError: projectsIsError,
    isLoading: projectsIsLoading,
  } = useGetAllProjects({ search, page, projectStatus });
  const {
    data: projectsStates,
    isError: projectsStatesIsError,
    isLoading: projectsStatesIsLoading,
  } = useGetAllProjectsStatus();
  // const [projectStats, setProjectStats] = useState(null);
  // const [loading, setLoading] = useState(false);

  const [showProject, setShowProject] = useState(false);
  const [editProject, setEditProject] = useState(false);
  const [ConfirmUpdate, setConfirmUpdate] = useState(false);
  // const [projectData, setProjectData] = useState([]);

  const [selectedItem, setSelectedItem] = useState(null);
  const { showProjectFlag, setShowProjectFlag } =
    useContext(showProjectContext);

  useEffect(() => {
    setShowProject(false);
    setShowProjectFlag(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (event, value) => {
    setPage(value);
  };
  const handleSearch = (val) => {
    setSearch(val);
  };
  const [checked, setChecked] = useState([]);
  const {
    handleShowSuccess,
    handleShowError,
    handleCloseSuccess,
    handleCloseError,
    showSuccess,
    showError,
  } = useSuccessfullModal();
  const {
    mutate: deleteMutation,
    isLoading: deleteIsLoading,
    isError: deleteIsError,
  } = useDeleteProject(() => {
    handleShowSuccess();
    setChecked([]);
  }, handleShowError);

  const handleChecked = (val) => {
    if (checked.includes(val)) {
      setChecked(checked.filter((item) => item !== val));
    } else {
      setChecked([...checked, val]);
    }
  };
  // You have array of ids to delete

  const handleDeleteAll = () => {};
  const handleDeleteSelected = () => {
    deleteMutation({
      projects: checked,
    });
  };
  const { title } = useProjectContext();
  const navigate = useNavigate();
  return (
    <div className="rounded-[19px] h-full bg-mainItems">
      {deleteIsLoading ? <LoadingPage /> : null}
      <SuccessfullModal
        handleClose={handleCloseSuccess}
        message={"تم الحذف بنجاح"}
        show={showSuccess}
      />
      <SuccessfullModal
        handleClose={handleCloseError}
        message={"حدث خطأ ما"}
        show={showError}
        status="error"
      />
      {projectsStatesIsLoading ? (
        <Progress />
      ) : (
        <Fragment>
          {!showProjectFlag ? (
            <div className=" NestedMainProjects  ">
              <div
                className={` ${styles.pieChartProjects} flex flex-col justify-center items-center`}
              >
                {projectsStates?.data && (
                  <PieChart
                    // total={total}
                    height={350}
                    colors={["#EFAA20", "#E40038"]}
                    series={[
                      ProjectTime === "inProgress"
                        ? projectsStates?.data?.statistics?.DESIGN?.IN_PROGRESS
                        : ProjectTime === "Waiting"
                        ? projectsStates?.data?.statistics?.DESIGN?.PENDING
                        : ProjectTime === "Done"
                        ? projectsStates?.data?.statistics?.DESIGN?.COMPLETED
                        : projectsStates?.data?.statistics?.DESIGN?.COMPLETED,
                      ProjectTime === "inProgress"
                        ? projectsStates?.data?.statistics?.SUPERVISING
                            ?.IN_PROGRESS
                        : ProjectTime === "Waiting"
                        ? projectsStates?.data?.statistics?.SUPERVISING?.PENDING
                        : ProjectTime === "Done"
                        ? projectsStates?.data?.statistics?.SUPERVISING
                            ?.COMPLETED
                        : projectsStates?.data?.statistics?.SUPERVISING
                            ?.COMPLETED,
                    ]}
                    labels={["التصميم", "الاشراف علي التنفيذ"]}
                  />
                )}
              </div>
              <fieldset className="TableContainer  py-3 px-2 mx-auto mt-3">
                <legend className="text-center text-mainText">{title}</legend>
                <div className="">
                  <div className="w-3/4 mx-auto ">
                    <SearchComponent
                      handleChange={handleSearch}
                      value={search}
                      className={""}
                      border="border !border-[#E4A11B]"
                    />
                  </div>
                </div>

                {CheckRole(roles["admin"]) &&
                !projectsIsLoading &&
                !projectsIsError &&
                projects?.data?.projects?.length > 0 ? (
                  <TableDeleteButton
                    checked={checked}
                    dataLength={projects?.data?.projects?.length}
                    onClickDeleteAll={handleDeleteAll}
                    onClickDeleteSelected={handleDeleteSelected}
                  />
                ) : null}

                <div className="mt-3 !h-[400px] overflow-y-auto scrollbar-thin scrollbar-track-mainNavy scrollbar-thumb-[#E4A11B]">
                  {projectsIsError ? (
                    <p className="text-red-500 text-center text-lg">
                      يوجد خطأ ما
                    </p>
                  ) : null}
                  {projectsIsLoading ? <Progress /> : null}

                  {!projectsIsLoading &&
                  !projectsIsError &&
                  projects?.data?.projects ? (
                    <CustomTable
                      columns={
                        CheckRole(roles["admin"]) ? columns : columns.slice(1)
                      }
                      data={projects?.data?.projects}
                      paginationProps={{
                        count: projects?.data?.pages,
                        page: page,
                        onChange: handleChange,
                      }}
                    >
                      {projects?.data?.projects &&
                      projects?.data?.projects?.length > 0
                        ? projects?.data?.projects?.map((item, index) => (
                            <TableRow
                              className={`my-2 border !border-[#efaa207f] ${
                                index % 2 === 0 ? "bg-mainNavy" : ""
                              }`}
                              key={index}
                            >
                              {CheckRole(roles["admin"]) ? (
                                <TableCell>
                                  <CustomCheckboxTable
                                    isChecked={checked?.some(
                                      (x) => x === item?._id
                                    )}
                                    handleClick={() => handleChecked(item?._id)}
                                  />
                                </TableCell>
                              ) : null}
                              <TableCell>
                                {(page - 1) * 10 + (index + 1)}
                              </TableCell>
                              <TableCell>
                                {item?.requestId?.projectName ||
                                  "لا توجد بيانات"}
                              </TableCell>
                              <TableCell>
                                {item?.orderNumber || "لا توجد بيانات"}
                              </TableCell>
                              <TableCell>
                                {item?.requestId?.clientType === 1
                                  ? "حكومي او مستثمر"
                                  : item?.requestId?.clientType === 2
                                  ? "شركة او مؤسسة"
                                  : "تجاري"}
                              </TableCell>
                              <TableCell>
                                {moment(item?.createdAt).format("YYYY-MM-DD")}
                              </TableCell>
                              <TableCell>
                                {item?.batchStatus === "1"
                                  ? "تم الدفع"
                                  : "لم يتم الدفع"}
                              </TableCell>
                              <TableCell>
                                {
                                  <Image
                                    src={
                                      process.env.PUBLIC_URL + "/icons/view.svg"
                                    }
                                    onClick={() => {
                                      // setShowProject(true);
                                      // setSelectedItem(item);
                                      // setShowProjectFlag(true);
                                      navigate(
                                        `/System/Projects/project/${item?._id}`
                                      );
                                    }}
                                    className="display_project  rounded"
                                    alt=" display project"
                                  />
                                }
                              </TableCell>
                              <TableCell>
                                {
                                  <Image
                                    src={
                                      process.env.PUBLIC_URL + "/icons/edit.svg"
                                    }
                                    onClick={() => {
                                      setEditProject(true);
                                      setSelectedItem(item);
                                    }}
                                    className=" edit_project  rounded"
                                    alt=" edit project"
                                  />
                                }
                              </TableCell>
                            </TableRow>
                          ))
                        : null}
                    </CustomTable>
                  ) : null}
                </div>
              </fieldset>
            </div>
          ) : (
            <>
              <ShowProjectComponent
                showProject={showProject}
                setShowProject={setShowProject}
                selectedItem={selectedItem}
                // getAllProject={getAllProject}
              />
            </>
          )}
        </Fragment>
      )}

      {editProject && (
        <>
          <EditProject
            editProject={editProject}
            setEditProject={setEditProject}
            setConfirmUpdate={setConfirmUpdate}
            selectedItem={selectedItem}
            // getAllProject={getAllProject}
          />
        </>
      )}
      {ConfirmUpdate && (
        <ConfirmPoper
          confirmPoper={ConfirmUpdate}
          setConfirmPoper={setConfirmUpdate}
          setEditRequest={setEditProject}
          text={"تم تعديل الطلب فى المشاريع بنجاح  "}
        />
      )}
    </div>
  );
};

export default MainProjects;
