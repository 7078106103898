import { Menu, MenuItem } from "@mui/material";
import React, { useState } from "react";
import { IoIosArrowDown } from "react-icons/io";
const TableDropdown = ({ data }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    data?.length > 0 && (
      <div>
        <button onClick={handleClick}>
          <IoIosArrowDown className="text-sm text-mainText cursor-pointer" />
        </button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
            sx: {
              bgcolor: "white",
            },
          }}
        >
          {data?.map((x,index) => (
            <MenuItem
              className="border min-w-[133px] text-right text-black"
              sx={{ gap: 1 }}
              key={index}
            >
              {x?.name}
            </MenuItem>
          ))}
        </Menu>
      </div>
    )
  );
};

export default TableDropdown;
