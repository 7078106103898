import { useContext, useEffect, useState } from "react";
import { AddHrType } from "../../../../Context/AddHr";
import { Form, Modal, Button } from "react-bootstrap";
import FormControl from "@mui/material/FormControl";

import SaveButton from "../../../SaveButton";
import Select from "../../../FormHandler/Select";
import { getAllUsers } from "../../../../helper/fetchers/Users";
import { toast } from "react-toastify";
import { Controller, useForm } from "react-hook-form";
import SuccessfullModal from "../../../Modals/SuccessfullModal";
import { ErrorMessage } from "@hookform/error-message";
import Progress from "../../../Progress";
import { getAllServicesHumans } from "../../../../helper/fetchers/servicesHuman";
import Cookies from "js-cookie";
import {
  useGetManagersUsers,
  useGetSelectedUsers,
} from "../../../../hooks/fetchers/Users";
import { FormModal } from "../../../../Pages/System/PlanModel/components/FormModal";
import useSuccessfullModal from "../../../Modals/hooks/useSuccessfullModal";
import { InputLabel } from "../../../../Pages/System/PlanModel/components/InputLabel";
import {
  CustomDatePicker,
  CustomeInputFile
} from "../../../FormUi";
import moment from "moment";
import { useAddHumanResoursce } from "../../../../hooks/fetchers/HumanResoursce";
import LoadingPage from "../../../feedback/LoadingPage";

const validateFileSize = (val) =>
  val.length > 5 ? "لا تزيد عن 5 ملفات" : true;
const roles = ["مدير", "مدير المكتب", "مواردبشرية"];

const AddServices = () => {
  const [user, setUser] = useState({});
  useEffect(() => {
    setUser({
      role: Cookies.get("role"),
      name: `${Cookies.get("firstName")} ${Cookies.get("lastName")}`,
      id: Cookies.get("userId"),
    });
  }, []);
  const { data: managers } = useGetManagersUsers();
  const { data: employees } = useGetSelectedUsers(roles.includes(user?.role));
  const {
    handleShowSuccess,
    handleShowError,
    handleCloseSuccess,
    handleCloseError,
    showSuccess,
    showError,
  } = useSuccessfullModal();
  const { mutate, isLoading } = useAddHumanResoursce(
    handleShowSuccess,
    handleShowError
  );

  const { openHr, handleCloseHr } = useContext(AddHrType);
  const {
    register,
    handleSubmit,
    control,
    formState: { isSubmitting, errors },
  } = useForm();
  const servicesTypeRoles = [
    {
      value: "بيان راتب  ",
      label: "بيان راتب  ",
    },
    {
      value: "طلب توظيف  ",
      label: " طلب توظيف",
    },
    {
      value: "مخالصة ",
      label: "مخالصة",
    },
  ];

  const [users, setUsers] = useState([]);
  const [servicesHumanID, setServicesHumanId] = useState([]);
  const getUsers = async () => {
    try {
      const { data } = await getAllUsers();

      if (data?.allUsers) {
        let users = data?.allUsers?.map(function (user) {
          return { value: user._id, label: user.firstName };
        });
        setUsers(users);
      } else {
        // console.log("Data retrieval failed");
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  const gethumanServicesId = async () => {
    try {
      const { data } = await getAllServicesHumans();
      // console.log(data);
      if (data?.services) {
        let services = data?.services?.map(function (services) {
          return { value: services._id, label: services.name };
        });

        setServicesHumanId(services);
      } else {
        // console.log("Data retrieval failed");
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };
  const manipulateData = (data) => {
    data.serviceDate = moment(data?.serviceDate).format("YYYY-MM-DD");
    return data;
  };
  const onSubmit = (data) => {
    data = manipulateData(data);
    // console.log(data);
    const formData = new FormData();
    // formData.append("employId", data.employId);
    // formData.append("humanServicesId", data.humanServicesId);
    // formData.append("serviceDate", data.serviceDate);
    // formData.append("approvalManger", data.approvalManger);
    // if(data.image){
    //   for(const image of data.image){
    //     formData.append("image", image);
    //   }
    // }

    Object.keys(data).forEach((key) => {
      if (key !== "image") {
        if (key === "employId") {
          if (!roles.includes(user?.role)) {
            formData.append("employId", user?.id);
          } else {
            formData.append(key, data[key]);
          }
        } else {
          formData.append(key, data[key]);
        }
      }
    });

    if (data.image && data.image.length) {
      for (const img of data.image) {
        formData.append("image", img);
      }
    }
    mutate(formData);
  };
  useEffect(() => {
    getUsers();
    gethumanServicesId();
  }, []);

  return (
    <Modal
      className="systemModal addHoliday bg-black/50"
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      onHide={handleCloseHr}
      show={openHr}
    >
      {isLoading ? <LoadingPage /> : null}
      <Modal.Header className="w-full !justify-between border-none">
        <div className=""></div>
        <ModalTitle title={"طلب خدمة"} />
        <Button
          className="p-0 bg-transparent hover:bg-transparent"
          onClick={handleCloseHr}
        >
          <img src="/Rejected.svg" alt="" />
        </Button>
      </Modal.Header>

      <Modal.Body className="w-full p-2">
        <SuccessfullModal
          show={showSuccess}
          handleClose={() => {
            handleCloseSuccess();
            handleCloseHr();
          }}
          message="تم اضافه خدمة جديدة بنجاح "
        />
        <SuccessfullModal
          show={showError}
          handleClose={() => {
            handleCloseError();
            handleCloseHr();
          }}
          message="حدث خطأ ما"
          status="error"
        />

        <Form
          encType="multipart/form-data"
          className="flex flex-col gap-3 w-full"
          onSubmit={handleSubmit(onSubmit)}
        >
          <FormModal title={"مخصصة الى"}>
            <div className="col-md-6">
              <FormControl fullWidth>
                <InputLabel label={"اسم الموظف"} />
                {roles.includes(user?.role) ? (
                  <Controller
                    control={control}
                    name={`employId`}
                    rules={{
                      required: " من فضلك اختر اسم الموظف",
                    }}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Select
                        fullWidth
                        ref={ref}
                        value={value?.value}
                        onChange={(val) => onChange(val?.value)}
                        onBlur={onBlur}
                        OptionbackgroundColor="#414162"
                        placeholder="اختر اسم الموظف"
                        options={employees}
                      />
                    )}
                  />
                ) : (
                  <div className="">
                    <p>{user.name}</p>
                  </div>
                )}
                {/* <CustomInput
                    control={control}
                    name="employId"
                    rules={{
                      required: "اسم الموظف غير موجود",
                    }}
                    value={user?.name}
                  /> */}

                <ErrorMessage
                  errors={errors}
                  name="employeeId"
                  render={({ message }) => (
                    <p className="text-[#FF0000]">{message}</p>
                  )}
                />
              </FormControl>
            </div>
          </FormModal>
          <FormModal title={"تفاصيل الخدمة"}>
            <div className="row">
              <div className="col-md-6">
                <FormControl className="col-span-6" fullWidth>
                  <InputLabel label={"نوع الخدمة"} />
                  <Controller
                    name={`humanServicesId`}
                    control={control}
                    rules={{
                      required: "من فضلك ادخل نوع الخدمة",
                    }}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Select
                        value={value?.value}
                        onChange={(val) => onChange(val?.value)}
                        onBlur={onBlur}
                        ref={ref}
                        OptionbackgroundColor={"#414162"}
                        placeholder="اختر نوع الخدمة"
                        options={servicesHumanID}
                      />
                    )}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="vactionType"
                    render={({ message }) => (
                      <p className="text-[#FF0000]">{message}</p>
                    )}
                  />
                </FormControl>
              </div>
              <div className="col-md-6">
                <FormControl className="col-span-6" fullWidth>
                  <InputLabel label={"تاريخ الخدمة"} />
                  <CustomDatePicker
                    control={control}
                    name="serviceDate"
                    rules={{
                      required: "من فضلك ادخل تاريخ الخدمة",
                    }}
                  />

                  <ErrorMessage
                    errors={errors}
                    name="startDate"
                    render={({ message }) => (
                      <p className="text-[#FF0000]">{message}</p>
                    )}
                  />
                </FormControl>
              </div>
            </div>
          </FormModal>
          <FormModal title={"موجة الى"}>
            <div className="grid grid-cols-2">
              <FormControl className="col-span-6" fullWidth>
                <InputLabel label={"تعتمد من "} />
                <Controller
                  name={`approvalManger`}
                  control={control}
                  rules={{
                    required: " من فضلك ادخل البيانات",
                  }}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Select
                      value={value?.value}
                      onChange={(val) => onChange(val?.value)}
                      onBlur={onBlur}
                      ref={ref}
                      OptionbackgroundColor={"#414162"}
                      placeholder="اختر الشخص"
                      options={managers}
                    />
                  )}
                />
                <ErrorMessage
                  errors={errors}
                  name="approvalManager"
                  render={({ message }) => (
                    <p className="text-[#FF0000]">{message}</p>
                  )}
                />
              </FormControl>
            </div>
          </FormModal>
          <FormModal title={"المرفقات"}>
            {/* <div className="row">
            <div className="col-md-12 mb-4">
              <AddAttachment attachment={image} setAttachment={setImage} />
            </div>
          </div> */}
            <CustomeInputFile
              register={{ ...register("image") }}
              name="image"
              multiple={true}
            />
          </FormModal>

          <div className="my-3">
            <SaveButton
              text={isSubmitting ? <Progress isSmall /> : "حفظ"}
              type="submit"
            />
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

const ModalTitle = ({ title }) => {
  return (
    <Modal.Title className="text-xl text-[#EFAA20] border !border-[#EFAA20] p-2 rounded-[5px] font-medium ">
      {title}
    </Modal.Title>
  );
};

export default AddServices;
