import { useQuery, useMutation, useQueryClient } from "react-query";
import {
  getAllExpensesItemsReport,
  addExpensesItemReport,
  updateExpensesItemReport,
  deleteExpensesItemReport,
  getExpensesItemReport,
} from "../../helper/fetchers/Expenses-Items-reports";
import myAxiosInstance from "../../helper/https";

export const useGetAllExpensesItemsReport = (search) => {
  const query = useQuery(["expenses", search], () =>
    getAllExpensesItemsReport(search)
  );
  return query;
};
export const useGetExpensesItemReport = (id) => {
  const query = useQuery(["expenses", id], () => getExpensesItemReport(id), {
    enabled: !!id,
  });
  return query;
};

export const useAddExpensesItemReport = (onSuccess, onError) => {
  const queryClient = useQueryClient();
  return useMutation(addExpensesItemReport, {
    onSuccess: () => {
      queryClient.invalidateQueries("expenses");
      onSuccess();
    },
    onError: (error) => {
      onError();
    },
  });
};
export const useUpdateExpensesItemReport = (onSuccess, onError, id) => {
  const queryClient = useQueryClient();
  return useMutation((data) => updateExpensesItemReport(id, data), {
    onSuccess: () => {
      queryClient.invalidateQueries("expenses");
      onSuccess();
    },
    onError: (error) => {
      onError();
    },
  });
};
export const useDeleteExpensesItemReport = (onSuccess, onError) => {
  const queryClient = useQueryClient();
  return useMutation(deleteExpensesItemReport, {
    onSuccess: () => {
      onSuccess();
      queryClient.invalidateQueries("expenses");
    },
    onError: (error) => {
      onError();
      // Handle error
    },
  });
};

export const AcceptReportWithId = (id) =>
  myAxiosInstance.patch(`/expenses/accept/${id}`);

export const rejectReportWithId = (id, data) =>
  myAxiosInstance.patch(`/expenses/reject/${id}`, data);
