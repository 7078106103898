import ReactApexChart from "react-apexcharts";

const ApexColumn = ({data , type = "bar"}) => {
    const series = [{
      name: '',
      data: data?.map(emp => emp?.value)
    }];
  
    const options = {
      chart: {
        height: 350,
        type: type,
        sparkline: {
            enabled: false,
        },
        animations: {
            enabled: true,
            easing: 'easeinout',
            speed: 800,
            animateGradually: {
                enabled: true,
                delay: 150
            },
            dynamicAnimation: {
                enabled: true,
                speed: 350
            }
        },
        // brush: {
        //     enabled: false,
        //     target: undefined,
        //     autoScaleYaxis: false
        //   }
      },
      legend: {
        labels: {
          colors: "var(--main-text)",
        },
      },
      plotOptions: {
        bar: {
          borderRadius: 5,
          dataLabels: {
            position: 'top', // top, center, bottom
          },
          
        }
      },
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return val + "%";
        },
        offsetY: 0,
        style: {
          fontSize: '12px',
          colors: ["var(--main-text)"]
        }
      },
      colors: ["#EFAA20"],
      xaxis: {
        categories: data?.map(emp => emp?.label),
        position: 'bottom',
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
        // crosshairs: {
        //   fill: {
        //     type: 'gradient',
        //     gradient: {
        //         colorFrom: "var(--main-text)",
        //         colorTo: "var(--main-text)",
        //       stops: [0, 100],
        //       opacityFrom: 0.4,
        //       opacityTo: 0.5,
        //     }
        //   }
        // },
        tooltip: {
          enabled: false,
        },
        
      },
      yaxis: {
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
          formatter: function (val) {
            return val + "%";
          }
        }
      }
    };
  
    return (
      <div>
        <div id="chart" className="allUsersCoulmnChart custom-chart">
          <ReactApexChart options={options} series={series} type={type} height={350} />
        </div>
        <div id="html-dist"></div>
      </div>
    );
  };
export default ApexColumn