import { useGetSubCategoryById, useUpdateSubCategory } from "../../../../../../hooks/fetchers/SubCategory";
import AddModal from "../../../AddModal";
import SuccessfullModal from "../../../../../../Components/Modals/SuccessfullModal";
import useSuccessfullModal from "../../../../../../Components/Modals/hooks/useSuccessfullModal";
import LoadingPage from "../../../../../../Components/feedback/LoadingPage";
import { joiResolver } from "@hookform/resolvers/joi";
import { updateSubCategorySchema } from "../../../../../../utiltis/Schemas/SubCategories/schema";

export const UpdateSubModal = ({ categoryId, subId, show, handleClose }) => {
  const {
    handleShowSuccess,
    handleShowError,
    handleCloseSuccess,
    handleCloseError,
    showSuccess,
    showError,
  } = useSuccessfullModal();
    
    const { mutate,isLoading } = useUpdateSubCategory(
      handleShowSuccess,
      handleShowError,
      categoryId,
      subId
    );
    const {data , isError: subCategoryIsError,isLoading: subCategoryIsLoading} = useGetSubCategoryById(categoryId,subId)
    return (
      <>
      {isLoading ? <LoadingPage/> : null}

      {(!subCategoryIsError && !subCategoryIsLoading) ? 
      
        <AddModal
          title={"تعديل"}
          show={show}
          handleClose={handleClose}
          name={"name"}
          isLoading={isLoading}
          handleSave={(data) => {
            mutate(data);
            handleClose();
          }}
          defaultValue={data?.name || ""}
          formOptions={{
            resolver: joiResolver(updateSubCategorySchema('ar'))
          }}
        />
      : null}
        <SuccessfullModal
            show={showSuccess}
            message={"تمت التعديل بنجاح"}
            handleClose={handleCloseSuccess}
          />
          <SuccessfullModal
            show={showError}
            message={"حدث خطا ما"}
            handleClose={handleCloseError}
            status="error"
          />
      </>
    );
  };