import React, { useCallback, useEffect, useState } from "react";
import "./SystemUsers.css";

import Genralnformation from "../../../../Components/System/Users/Genralnformation/Genralnformation";
import { AccountaingInformation } from "../../../../Components/System/Users/AccountaingInformation/AccountaingInformation";

import { useParams, useNavigate } from "react-router-dom";
import ProfessinollInformation from "../../../../Components/System/Users/ProfessinollInformation/ProfessinollInformation";
import Image from "../../../../Components/Image";
import DownloadButton from "../../../../Components/Buttons/DownloadButton";
import Progress from "../../../../Components/Progress";
import { getUserById } from "../../../../helper/fetchers/Users";
import ProfileImage from "../../../../Components/ProfileImage";
const SystemUsers = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [userData, setUserData] = useState({});
  const [employeeDetails, setEmployeeDetails] = useState("aboutEmpolyee");

  // #region getUserData
  const getUserData = useCallback(async () => {
    const res = await getUserById(id);
    setUserData(res?.data?.user);
    console.log("res", res);
  }, [id]);

  useEffect(() => {
    getUserData();
  }, [getUserData]);
  // #endregion
  return (
    <div className="Users-component w-100 text-mainText h-full rounded-[19px] bg-mainItems py-2">
      <div className="w-full">
        <div className="show-employee py-4 px-2 w-100 scrollbar-none ">
          {userData ? (
            <>
              <div className="show-employee-header">
                <div className="d-flex justify-content-between">
                  <p className="text-[#D59921] mb-5 font-medium">
                    {userData?.role + " / " + userData?.department}
                  </p>
                  {/* <div className="flex gap-3">
                    <DownloadButton>تصدير CSV </DownloadButton>
                    <DownloadButton> تصدير Excel </DownloadButton>
                  </div> */}
                </div>
                <div className="d-flex justify-content-between w-100 px-3">
                  <div className="tab d-flex   ">
                    <ProfileImage
                      image={userData?.image}
                      imageClassName="user-Personal"
                      alt="user img"
                    />
                    <div className="flex flex-col gap-2 me-3 ">
                      <h2 className="name-header w-full">
                        {userData?.firstName + " " + userData?.lastName}
                      </h2>
                      <p className="text-mainCustomGray">{userData?.email}</p>
                      <p className="name-header">{userData?.phone}</p>
                    </div>
                  </div>

                  <div className="d-flex align-items-center ">
                    <Image
                      src={`${process.env.PUBLIC_URL + "/icons/more.png"}`}
                      alt="user Image "
                      className="action-buttons"
                      onClick={() =>
                        navigate(`/System/Hr/Users/${userData?._id}`)
                      }
                    />
                  </div>
                </div>
              </div>

              <div className="choose-inf text-mainCustomGray relative flex px-3 mb-3">
                <p
                  className={`genral-inf text-center border-b-2 flex-1 py-3 ${
                    employeeDetails === "aboutEmpolyee"
                      ? "inf-type !border-[#D59921]"
                      : "border-mainCustomGray"
                  }`}
                  onClick={() => {
                    setEmployeeDetails("aboutEmpolyee");
                  }}
                >
                  عن الموظف
                </p>
                <p
                  className={`genral-inf text-center border-b-2 flex-1 py-3 ${
                    employeeDetails === "ProfessinollInformation"
                      ? "inf-type !border-[#D59921]"
                      : "border-mainCustomGray"
                  }`}
                  onClick={() => {
                    setEmployeeDetails("ProfessinollInformation");
                  }}
                >
                  معلومات مهنية
                </p>
                <p
                  className={`genral-inf text-center border-b-2  flex-1 py-3 ${
                    employeeDetails === "AccountaingInformation"
                      ? "inf-type !border-[#D59921]"
                      : "border-mainCustomGray"
                  }`}
                  onClick={() => {
                    setEmployeeDetails("AccountaingInformation");
                  }}
                >
                  معلومات الراتب
                </p>
              </div>
              {employeeDetails === "aboutEmpolyee" ? (
                <Genralnformation user={userData} />
              ) : employeeDetails === "ProfessinollInformation" ? (
                <ProfessinollInformation user={userData} />
              ) : (
                <AccountaingInformation user={userData} />
              )}
            </>
          ) : (
            <Progress />
          )}
        </div>
      </div>
    </div>
  );
};

export default SystemUsers;
