import { Button, Form, Modal } from "react-bootstrap";
import "./index.css";
import KSACites from "../../../KSACItes";

import Select from "../../../FormHandler/Select";

import { useState } from "react";
import { FormModal } from "../../../../Pages/System/PlanModel/components/FormModal";
import { InputLabel } from "../../../../Pages/System/PlanModel/components/InputLabel";
import { getDesignRequestsWithid } from "../../../../helper/fetchers/Requests";
import Progress from "../../../Progress";
import { Controller, useForm } from "react-hook-form";
import SuccessfullModal from "../../../Modals/SuccessfullModal";
import AddAttachment from "../../AddAttachment";
import ShowLocalFiles from "../../../ShowLocalFiles";
import ShowFiles from "../../../ShowFiles";
import { CustomInput } from "../../../FormUi/CustomInput";
import LoadingPage from "../../../feedback/LoadingPage";
import { PhoneInput } from "react-international-phone";
import {
  useGetRequestsWithid,
  useUpdateRequestwithId,
} from "../../../../hooks/fetchers/Requests";
import useSuccessfullModal from "../../../Modals/hooks/useSuccessfullModal";
import { CustErrorMessage } from "../../../Errors/CustErrorMessage";

const clientTypeRoles = [
  {
    label: "فردي",
    value: 3,
  },
  {
    label: "شركه  او مؤسسة",
    value: 2,
  },
  {
    label: "حكومي او مستثمر",
    value: 1,
  },
];
const IdentityTypeRoles = [
  {
    label: "سجل تجاري",
    value: 2,
  },
  {
    label: "هوية",
    value: 1,
  },
];
const mappedCity = KSACites?.map((item) => {
  return {
    value: item?.arLabel,
    label: item?.arLabel,
  };
});
const EditDesignRequest = ({
  editRequest,
  setEditRequest,
  handleClose,
  id,
}) => {
  const [instrumentImage, setInstrumentImage] = useState();
  const [agencyAttachments, setAgencyAttachments] = useState(null);
  const [financialAttachments, setfinancialAttachments] = useState(null);
  const [techAttachments, setTechAttachments] = useState(null);
  const [idPhoto, setIdPhoto] = useState(null);

  const { data: request } = useGetRequestsWithid(id);

  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: id
      ? async () => {
          const {
            data: { request },
          } = await getDesignRequestsWithid(id);
          console.log("getDesignRequestsWithid: ", request);
          console.log(
            IdentityTypeRoles?.find(
              ({ value }) => value == request?.identityType
            )
          );

          return {
            ownerName: request?.ownerName,
            projectName: request?.projectName,
            buildingLocation: request?.buildingLocation,
            locationMap: request?.locationMap,
            city: mappedCity?.find(({ value }) => value === request?.city),
            area: request?.area,
            pieceNumber: request?.pieceNumber,
            chartNumber: request?.chartNumber,
            clientType: clientTypeRoles?.find(
              ({ value }) => value === request?.clientType
            ),
            identityType: IdentityTypeRoles?.find(
              ({ value }) => value === request?.identityType
            ),
            phone: request?.phone,
            commercialRegNo:
              +request?.identityType === 2 && request?.commercialRegNo
                ? request?.commercialRegNo
                : null,
            email: request?.email,
            taxNumber: request?.taxNumber,
            agent: request?.agent,
            agencyNumber: request?.agencyNumber,
            instrumentNumber: request?.instrumentNumber,
          };
        }
      : undefined,
  });
  const filesID = idPhoto ? [...idPhoto] : [];
  const filesLicense = instrumentImage ? [...instrumentImage] : [];
  const filesAgencyAttachments = agencyAttachments
    ? [...agencyAttachments]
    : [];
  const filesfinancialAttachments = financialAttachments
    ? [...financialAttachments]
    : [];
  const filestechAttachments = techAttachments ? [...techAttachments] : [];

  const manipulateData = (data) => {
    data.city = data?.city?.value;
    data.clientType = data?.clientType?.value;
    data.identityType = data?.identityType?.value;
    return data;
  };

  const {
    handleShowSuccess,
    handleShowError,
    handleCloseSuccess,
    handleCloseError,
    showSuccess,
    showError,
  } = useSuccessfullModal();
  const { mutate: updateMutation, isLoading: updateIsLoading } =
    useUpdateRequestwithId(id, handleShowSuccess, handleShowError);

  const onSubmit = async (data) => {
    data = manipulateData(data);
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      if (data[key]) {
        formData.append(key, data[key]);
      } else {
        formData.append(key, "");
      }
    });

    if (idPhoto && idPhoto?.length > 0) {
      filesID.forEach((file) => {
        formData.append(`idPhoto`, file);
      });
    }
    if (instrumentImage && instrumentImage?.length > 0) {
      filesLicense.forEach((file) => {
        formData.append(`instrumentImage`, file);
      });
    }
    if (agencyAttachments && agencyAttachments?.length > 0) {
      filesAgencyAttachments.forEach((file) => {
        formData.append(`agencyAttachments`, file);
      });
    }
    if (+request?.status !== 0) {
      if (financialAttachments && financialAttachments?.length > 0) {
        filesfinancialAttachments.forEach((file) => {
          formData.append(`financialFiles`, file);
        });
      }

      if (techAttachments && techAttachments?.length > 0) {
        filestechAttachments.forEach((file) => {
          formData.append(`techFiles`, file);
        });
      }
    }
    updateMutation(formData);
    // console.log("data");
  };
  // console.log("watch",watch());
  // console.log("Hello World");
  return (
    <ModalWrapper editRequest={editRequest} handleClose={handleClose}>
      {updateIsLoading ? <LoadingPage /> : null}
      <SuccessfullModal
        message={"تم تعديل  الطلب بنجاح "}
        show={showSuccess}
        handleClose={() => {
          handleCloseSuccess();
          setEditRequest(false);
        }}
      />
      <SuccessfullModal
        message={"حدث خطا ما"}
        show={showError}
        handleClose={handleCloseError}
        status="error"
      />

      <div className="mx-4">
        {request ? (
          <Form
            onSubmit={handleSubmit(onSubmit)}
            className="row flex-col gap-4 w-100 m-auto "
          >
            <FormModal title={"بيانات المشروع"}>
              <div className="row w-100">
                <div className=" col-md-7 mb-4">
                  <div className="flex flex-col">
                    <InputLabel label={"اسم المالك"} mandatory={true} />
                    <CustomInput control={control} name={"ownerName"} />
                  </div>
                </div>
                <div className=" col-md-6 mb-4">
                  <div className="flex flex-col">
                    <InputLabel label={"اسم المشروع"} mandatory={true} />
                    <CustomInput control={control} name={"projectName"} />
                  </div>
                </div>
                <div className=" col-md-6 mb-4">
                  <div className="flex flex-col">
                    <InputLabel label={" موقع المشروع "} mandatory={true} />
                    <CustomInput control={control} name={"buildingLocation"} />
                  </div>
                </div>
                <div className=" col-md-6 mb-4">
                  <div className="flex flex-col">
                    <InputLabel label={" الموقع الجغرافى "} mandatory={true} />
                    <CustomInput control={control} name={"locationMap"} />
                  </div>
                </div>
                {watch("identityType")?.value === 2 ? (
                  <div className=" col-md-6 mb-4">
                    <div className="flex flex-col">
                      <InputLabel
                        label={"رقم السجل التجارى"}
                        mandatory={true}
                      />
                      <CustomInput
                        control={control}
                        name={"commercialRegNo"}
                        rules={{ required: "يجب ادخال رقم السجل التجارى" }}
                      />
                      <CustErrorMessage
                        name="commercialRegNo"
                        errors={errors}
                      />
                    </div>
                  </div>
                ) : null}

                <div className=" col-md-6 mb-4">
                  <InputLabel label={" المدينة"} mandatory={true} />
                  <Controller
                    control={control}
                    name={`city`}
                    rules={{
                      required: " من فضلك اختر المدينة",
                    }}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Select
                        value={value}
                        fullWidth
                        ref={ref}
                        onChange={onChange}
                        onBlur={onBlur}
                        OptionbackgroundColor="var(--main-navy)"
                        placeholder="اختر المدينة"
                        options={mappedCity}
                      />
                    )}
                  />
                </div>

                <div className=" col-md-6 mb-4">
                  {/* <Input label={" الحي"} {...Area.bind} mandatory /> */}
                  <div className="flex flex-col">
                    <InputLabel label={" الحي"} mandatory={true} />
                    <CustomInput control={control} name={"area"} />
                  </div>
                </div>

                <div className=" col-md-6 mb-4">
                  <div className="flex flex-col">
                    <InputLabel label={" رقم القطعة"} mandatory={true} />
                    <CustomInput control={control} name={"pieceNumber"} />
                  </div>
                </div>

                <div className=" col-md-6 mb-4">
                  <div className="flex flex-col">
                    <InputLabel label={" رقم المخطط"} mandatory={true} />
                    <CustomInput control={control} name={"chartNumber"} />
                  </div>
                </div>
              </div>
            </FormModal>
            <FormModal title={"بيانات المالك"}>
              <div className="row  w-100">
                <div className="col-md-6 mb-4">
                  <InputLabel label={"نوع العميل"} mandatory={true} />
                  <Controller
                    control={control}
                    name={`clientType`}
                    rules={{
                      required: " من فضلك اختر نوع العميل",
                    }}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Select
                        fullWidth
                        value={value}
                        ref={ref}
                        onChange={onChange}
                        onBlur={onBlur}
                        OptionbackgroundColor="#414162"
                        placeholder="اختر نوع العميل"
                        options={clientTypeRoles}
                      />
                    )}
                  />
                </div>
                <div className="col-md-6 mb-4">
                  <InputLabel label={"نوع الهوية"} mandatory={true} />
                  <Controller
                    control={control}
                    name={`identityType`}
                    rules={{
                      required: " من فضلك اختر نوع الهوية",
                    }}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Select
                        value={value}
                        fullWidth
                        ref={ref}
                        onChange={onChange}
                        onBlur={onBlur}
                        OptionbackgroundColor="#414162"
                        placeholder="اختر نوع الهوية"
                        options={IdentityTypeRoles}
                      />
                    )}
                  />
                </div>
                <div className="col-md-6 mb-4">
                  <div className="flex flex-col">
                    <InputLabel label={"رقم الجوال"} />
                    <Controller
                      control={control}
                      name={`phone`}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <PhoneInput
                          defaultCountry="sa"
                          placeholder="ادخل رقم الجوال"
                          value={value}
                          className="bg-mainNavy"
                          onChange={onChange}
                          onBlur={onBlur}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="col-md-6 mb-4">
                  <div className="flex flex-col">
                    <InputLabel label={"البريد الالكتروني"} mandatory={true} />
                    <CustomInput control={control} name={"email"} />
                  </div>
                </div>

                <div className="col-md-6 mb-4">
                  <Form.Group controlId="formBasicImage">
                    <Form.Label className="d-flex gap-2 align-items-center text-mainText">
                      صورة الهويه
                    </Form.Label>

                    <div className="flex items-center gap-3">
                      <AddAttachment
                        attachment={idPhoto}
                        setAttachment={setIdPhoto}
                        multi={false}
                      />
                      {idPhoto ? (
                        <ShowLocalFiles files={Array.from(idPhoto)} />
                      ) : (
                        <ShowFiles files={request?.idPhoto} />
                      )}
                    </div>
                  </Form.Group>
                </div>

                <div className="col-md-6 mb-4">
                  <div className="flex flex-col">
                    <InputLabel
                      label={"رقم الشهادة الضربية"}
                      mandatory={true}
                    />
                    <CustomInput control={control} name={"taxNumber"} />
                  </div>
                </div>
              </div>
            </FormModal>
            <FormModal title={"بيانات الوكيل"}>
              <div className="row  w-100">
                <div className="col-md-6 mb-4">
                  <div className="flex flex-col">
                    <InputLabel label={"الوكيل"} mandatory={true} />
                    <CustomInput control={control} name={"agent"} />
                  </div>
                </div>
                <div className="col-md-6 mb-4">
                  <div className="flex flex-col">
                    <InputLabel label={"رقم الوكيل"} mandatory={true} />
                    <CustomInput control={control} name={"agencyNumber"} />
                  </div>
                </div>

                <div className="col-md-6 mb-4">
                  <div className="flex flex-col">
                    <InputLabel label={"رقم الصك"} mandatory={true} />
                    <CustomInput control={control} name={"instrumentNumber"} />
                  </div>
                </div>
                <div className="col-md-6 mb-4">
                  <Form.Group controlId="formBasicImage">
                    <Form.Label className="d-flex gap-2 align-items-center text-mainText">
                      ارفاق الوكالة
                    </Form.Label>
                    <div className="flex items-center gap-3">
                      <AddAttachment
                        attachment={agencyAttachments}
                        setAttachment={setAgencyAttachments}
                        multi={false}
                      />
                      {agencyAttachments ? (
                        <ShowLocalFiles files={Array.from(agencyAttachments)} />
                      ) : (
                        <ShowFiles files={request?.agencyAttachments} />
                      )}
                    </div>
                  </Form.Group>
                </div>
                <div className="col-md-6 mb-4">
                  <Form.Group controlId="formBasicImage">
                    <Form.Label className="d-flex gap-2 align-items-center text-mainText">
                      صورة الصك
                    </Form.Label>
                    <div className="flex items-center gap-3">
                      <AddAttachment
                        attachment={instrumentImage}
                        setAttachment={setInstrumentImage}
                        multi={false}
                      />
                      {instrumentImage ? (
                        <ShowLocalFiles files={Array.from(instrumentImage)} />
                      ) : (
                        <ShowFiles files={request?.instrumentImage} />
                      )}
                    </div>
                  </Form.Group>
                </div>
              </div>
            </FormModal>
            {+request?.status !== 0 ? (
              <>
                <FormModal title={"ملفات العرض المالى"}>
                  <div className="flex items-center gap-3">
                    <AddAttachment
                      attachment={financialAttachments}
                      setAttachment={setfinancialAttachments}
                      multi={false}
                    />
                    {financialAttachments ? (
                      <ShowLocalFiles
                        files={Array.from(financialAttachments)}
                      />
                    ) : (
                      <ShowFiles files={request?.financialFiles} />
                    )}
                  </div>
                </FormModal>
                <FormModal title={"ملفات العرض الفنى"}>
                  <div className="flex items-center gap-3">
                    <AddAttachment
                      attachment={techAttachments}
                      setAttachment={setTechAttachments}
                      multi={false}
                    />
                    {techAttachments ? (
                      <ShowLocalFiles files={Array.from(techAttachments)} />
                    ) : (
                      <ShowFiles files={request?.techFiles} />
                    )}
                  </div>
                </FormModal>
              </>
            ) : null}
            <Button type="submit" className="sumbmitAddUpdateUser self-center">
              {updateIsLoading ? <Progress isSmall /> : "حفظ"}
            </Button>
          </Form>
        ) : (
          <Progress />
        )}
      </div>
    </ModalWrapper>
  );
};

const ModalWrapper = ({ children, editRequest, handleClose }) => {
  return (
    <Modal
      size="lg"
      show={editRequest}
      onHide={handleClose}
      aria-labelledby="example-modal-sizes-title-lg"
      className="systemModal bg-black/50"
      contentClassName="scrollbar-none "
    >
      <Modal.Header className="w-full !justify-between border-none">
        <div className=""></div>
        <ModalTitle title={"تعديل فى طلب التصميم"} />
        <Button
          className="p-0 bg-transparent hover:bg-transparent"
          onClick={handleClose}
        >
          <img src="/Rejected.svg" alt="" />
        </Button>
      </Modal.Header>

      {children}
    </Modal>
  );
};

const ModalTitle = ({ title }) => {
  return (
    <Modal.Title className="text-xl text-[#EFAA20] border !border-[#EFAA20] p-2 rounded-[5px] font-medium ">
      {title}
    </Modal.Title>
  );
};

export default EditDesignRequest;
