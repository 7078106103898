import { createContext, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const ProjectContext = createContext(null);

export const ProjectProvider = ({ children }) => {
  const [title, setTitle] = useState("كل المشاريع");
  const { ProjectTime } = useParams();
  useEffect(() => {
    if (ProjectTime === "inProgress") {
      setTitle("مشاريع قيد التنفيذ");
    } else if (ProjectTime === "Waiting") {
      setTitle("مشاريع معلقة");
    } else if (ProjectTime === "Done") {
      setTitle("مشاريع منتهية");
    } else {
      setTitle("كل المشاريع");
    }
  }, [ProjectTime]);
  return (
    <ProjectContext.Provider value={{ title, setTitle }}>
      {children}
    </ProjectContext.Provider>
  );
};

export const useProjectContext = () => {
  const ctx = useContext(ProjectContext);
  if (ctx) {
    return ctx;
  } else {
    throw Error("Use inside project Context!");
  }
};
