import { forwardRef, useEffect } from "react";
import "../AsideBar.css";
export const MenuContainer = forwardRef(({ children ,toggleMenu}, ref) => {

  return (
    <div
      ref={ref}
      className=" absolute p-2 z-[100000]  right-[62%] top-[10%] transition-all ease-in-out duration-500 bg-mainNavy rounded-[14px] min-h-[310px] !w-[245px]"
    >
      <div className="profile-menu-backdrop z-0" onClick={toggleMenu} />
      <div className="relative">{children}</div>
    </div>
  );
});
