import React from "react";
import SearchCountryUsersProvider from "./Context";

const SearchCountryUsersWrapper = ({
  children,
  Country,
  role,
  departments,
}) => {
  return (
    <SearchCountryUsersProvider
      Country={Country}
      role={role}
      departments={departments}
    >
      <div className="search-users py-4 overflow-x-hidden overflow-y-auto scrollbar-none h-full w-full">
        {children}
      </div>
    </SearchCountryUsersProvider>
  );
};

export default SearchCountryUsersWrapper;
