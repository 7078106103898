import { Fragment, useEffect, useRef, useState } from "react";

import { toBlob } from "html-to-image";
import { Document, Page, Image, StyleSheet } from "@react-pdf/renderer";
import moment from "moment";
import SectionElement from "../../../../Components/Preview/components/SectionElement";
import PdfPreview from "../../../../Components/Preview/CustomPdfPreview";
import TableElement from "../../../../Components/Preview/components/Table";
import {
  clientTypeEnum,
  identityTypeEnum,
  projectTypeEnum,
  statusEnum,
} from "../../../../helper/utils";
import PdfDocument from "./PdfDocument";
import { columnsClientInfo } from "../consts";

const SliceData = (start = 0, sliceLength = 10, data = []) => {
  let result = [];
  for (let i = start; i < data.length; i += sliceLength) {
    result.push(data.slice(i, i + sliceLength));
  }
  return result;
};

const PrintClientDetailsFile = ({ show, onHide, data }) => {
  const [transformedData, setTransformedData] = useState([]);
  const refs = useRef([]);
  const [imageUrls, setImageUrls] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (show) {
      const captureImages = async () => {
        setLoading(true);
        const blobs = await Promise.all(
          refs.current.map((ref) => toBlob(ref, { pixelRatio: 2 }))
        );
        const urls = blobs.map((blob) => URL.createObjectURL(blob));
        setImageUrls(urls);
        setLoading(false);
      };

      captureImages();
    }
  }, [show]);
  useEffect(() => {
    setTransformedData(SliceData(10, 15, data?.projectsInfo));
  }, [data]);
  console.log("PrintClientDetailsFile", data);
  return (
    <>
      <PdfPreview show={show} onHide={onHide}>
        {imageUrls.length > 0 && (
          <PdfPreview.DownloadButton
            document={<PdfDocument imageUrls={imageUrls} />}
            loading={loading}
          />
        )}

        <div ref={(el) => (refs.current[0] = el)} className="rounded-t w-full">
          <PdfPreview.Header titleAr={"عميل"} titleEn={"Client"} />

          <PdfPreview.Body>
            <div className="flex justify-between items-center">
              <PdfPreview.SectionTitle title={"معلومات العميل"} />
              <SectionElement
                title="اسم العميل"
                disc={data?.clientInfo?.ownerName}
                className="w-1/2 gap-0 px-2"
              />
            </div>

            <PdfPreview.SectionWrapper>
              <SectionElement
                title="نوع العميل"
                disc={clientTypeEnum[data?.clientInfo?.clientType]}
              />
              <SectionElement
                title="رقم الجوال"
                disc={data?.clientInfo?.phone}
              />
              <SectionElement
                title="البريد الالكتروني"
                disc={data?.clientInfo?.email}
              />
              <SectionElement
                title="نوع الهويه"
                disc={identityTypeEnum[data?.clientInfo?.identityType]}
              />
              <SectionElement title="عدد المشاريع" disc={""} />
              <SectionElement
                title="رقم الهويه"
                disc={data?.clientInfo?.identityNumber}
              />
            </PdfPreview.SectionWrapper>

            <div className="">
              <PdfPreview.SectionTitle title={"بيانات العميل "} />
              <PdfPreview.SectionWrapper>
                <div className="col-span-2">
                  <TableElement
                    columns={columnsClientInfo}
                    data={data?.projectsInfo}
                  >
                    {data?.projectsInfo?.slice(0, 10)?.map((props, index) => (
                      <ClientTableRow {...props} index={index} key={index} />
                    ))}
                  </TableElement>
                </div>
              </PdfPreview.SectionWrapper>
            </div>
          </PdfPreview.Body>

          {transformedData?.map((group, groupIndex) => (
            <div
              ref={(el) => (refs.current[groupIndex + 1] = el)}
              className="rounded-t w-full"
            >
              <PdfPreview.Header titleAr={"عميل"} titleEn={"Client"} />

              <PdfPreview.Body>
                <div className="">
                  <PdfPreview.SectionTitle title={"بيانات العميل "} />
                  <PdfPreview.SectionWrapper>
                    <div className="col-span-2">
                      <TableElement columns={columnsClientInfo} data={group}>
                        {group?.map((props, index) => (
                          <ClientTableRow
                            {...props}
                            index={groupIndex * 15 + index + 10}
                            key={groupIndex * 15 + index + 10}
                          />
                        ))}
                      </TableElement>
                    </div>
                  </PdfPreview.SectionWrapper>
                </div>
              </PdfPreview.Body>
            </div>
          ))}
        </div>
      </PdfPreview>
    </>
  );
};

const ClientTableRow = ({
  index,
  requestId,
  projectName,
  deliveryDate,
  projectStatus,
  totalAmount,
  remainingAmount,
  batchCount,
  remainingBatch,
}) => {
  return (
    <TableElement.TableRow className={`my-2`}>
      <TableElement.TableCell>{index + 1}</TableElement.TableCell>
      <TableElement.TableCell>{`${projectName}`}</TableElement.TableCell>
      <TableElement.TableCell>{`${requestId?.orderNumber}`}</TableElement.TableCell>
      <TableElement.TableCell>
        {statusEnum[projectStatus]?.title}
      </TableElement.TableCell>
      <TableElement.TableCell>
        {moment(deliveryDate).format("YYYY-MM-DD")}
      </TableElement.TableCell>
      <TableElement.TableCell>{totalAmount}</TableElement.TableCell>
      <TableElement.TableCell>{remainingAmount}</TableElement.TableCell>
      <TableElement.TableCell>{batchCount}</TableElement.TableCell>
      <TableElement.TableCell>{remainingBatch}</TableElement.TableCell>
    </TableElement.TableRow>
  );
};

export default PrintClientDetailsFile;
