import { ErrorMessage } from "@hookform/error-message";

export const CustErrorMessage = ({ name, errors }) => {
  return (
    <ErrorMessage
      errors={errors}
      name={name}
      render={({ message }) => <p className="text-[#FF0000]">{message}</p>}
    />
  );
};
