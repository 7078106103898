import { toast } from "react-toastify";
import { myAxiosJson } from "../https";
import { CheckRole } from "../utils";
export const getDashboardStatistics = () =>
  myAxiosJson("statics/home").then((data) => data?.data?.stats);

export const getDashboardDepartmentStatistics = () => {
  if (!CheckRole("موظف")) {
    return myAxiosJson("user/statistics/department").then((data) => data?.data).catch(error=>{
      toast.error(error?.response?.data?.message)
  });
  }
};

export const plansPercentage = () => {
  if (!CheckRole("موظف")) {
    return myAxiosJson("statics/plans/percentage")
      .then((data) => data?.data)
      .catch(error=>{
        toast.error(error?.response?.data?.message)
    });
  }
};

export const tasksPercentage = () =>
  myAxiosJson("statics/tasks/percentage")
    .then((data) => data?.data)
    .catch(error=>{
      toast.error(error?.response?.data?.message)
  });
