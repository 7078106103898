import { createContext, useContext, useState } from "react";

const ReportContext = createContext(null);

const ReportProvider = ({ children }) => {
  const [projectType, setProjectType] = useState(null);
  const [isExternal,setIsExternal] = useState(null)
  return (
    <ReportContext.Provider value={{ projectType, setProjectType ,isExternal,setIsExternal}}>
      {children}
    </ReportContext.Provider>
  );
};

export const useReportContext = () => {
  const ctx = useContext(ReportContext);
  if (!ctx) {
    throw new Error("useReportContext must be used within a ReportProvider");
  }
  return ctx;
};

export default ReportProvider;
