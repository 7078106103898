import React, { useContext, useState } from "react";
import { useLocation, Link, Outlet } from "react-router-dom";
import SystemControler from "../../../Components/System/SystemControler/SystemControler";
import BackButton from "../../../Components/BackButton";
import { AllCategories } from "../../../Components/System/AllCategories/AllCategories";
import { Accordion } from "react-bootstrap";
import { MdKeyboardArrowDown } from "react-icons/md";
import { TechnicalSupportContext } from "./components/TechnicalSupportProvider";
import TechnicalSupportAddTasks from "./components/TechnicalSupportAddTasks";
import TechnicalSupportOrderAdd from "./components/TechnicalSupportOrderAdd";
import TechnicalSupportAddTerms from "./components/TechnicalSupportAddTerms";
import TechnicalSupportTermIssue from "./components/TechnicalSupportTermIssue";
import { CheckRole } from "../../../helper/utils";
import Cookies from "js-cookie";
const TechnicalSupport = () => {
  const [activeEventKey, setActiveEventKey] = useState(null);
  const location = useLocation();
  const {
    setAddTasksOpen,
    addTasksOpen,
    setShowTasksOpen,
    addOrdersOpen,
    setAddOrdersOpen,
    setShowOrdersOpen,
    addTermsOpen,
    setAddTermsOpen,
    setOpenTerm,
    openTerm,
    setOrdersTitle,
    
    setHelpRequestsTitle,
  } = useContext(TechnicalSupportContext);

  const accordionItemsManager = [
    {
      eventKey: "10",
      header: "طلبات المساعده",
      link: "System/technical-support/help-request",
      onClick: () => {
        setHelpRequestsTitle("كل الطلبات");
      },
      links: [
        {
          path: "/System/technical-support/help-request/outgoing",
          label: "صادره",
          onClick: () => {
            setHelpRequestsTitle("كل الطلبات الصادرة");
          },
          links: [
            {
              path: "/System/technical-support/help-request/outgoing/waiting",
              label: "في الانتظار الموافقه",
              onClick: () => {
                setHelpRequestsTitle("كل الطلبات الصادرة في الانتظار الموافقه");
              },
            },
            {
              path: "/System/technical-support/help-request/outgoing/inprogress",
              label: "قيد التنفيذ",
              onClick: () => {
                setHelpRequestsTitle("كل الطلبات الصادرة قيد التنفيذ");
              },
            },
            {
              path: "/System/technical-support/help-request/outgoing/done-check",
              label: "تم الفحص",
              onClick: () => {
                setHelpRequestsTitle("كل الطلبات الصادرة التى تم فحصها ");
              },
            },
            {
              path: "/System/technical-support/help-request/outgoing/complete",
              label: "مكتمله",
              onClick: () => {
                setHelpRequestsTitle("كل الطلبات الصادرة المكتملة");
              },
            },
          ],
        },
        {
          path: "/System/technical-support/help-request/ingoing ",
          label: "وارده",
          onClick: () => {
            setHelpRequestsTitle("كل الطلبات الواردة");
          },
          links: [
            {
              path: "/System/technical-support/help-request/ingoing/waiting",
              label: "في الانتظار الموافقه",
              onClick: () => {
                setHelpRequestsTitle("كل الطلبات الواردة في الانتظار الموافقه");
              },
            },
            {
              path: "/System/technical-support/help-request/ingoing/inprogress",
              label: "قيد التنفيذ",
              onClick: () => {
                setHelpRequestsTitle("كل الطلبات الواردة قيد التنفيذ");
              },
            },
            {
              path: "/System/technical-support/help-request/ingoing/done-check",
              label: "تم الفحص",
              onClick: () => {
                setHelpRequestsTitle("كل الطلبات الواردة التى تم فحصها");
              },
            },
            {
              path: "/System/technical-support/help-request/ingoing/complete",
              label: "مكتمله",
              onClick: () => {
                setHelpRequestsTitle("كل الطلبات الواردة المكتملة");
              },
            },
          ],
        },
      ],
    },
    {
      eventKey: "1",
      header: "طلبات الشراء",
      link: "System/technical-support/orders",
      onClick: () => {
        setOrdersTitle("كل الطلبات");
      },
      links: [
        {
          path: "/System/technical-support/orders/outgoing",
          label: "صادره",
          onClick: () => {
            setOrdersTitle("كل الطلبات الصادرة");
          },
          links: [
            {
              path: "/System/technical-support/orders/outgoing/waiting",
              label: "في الانتظار الموافقه",
              onClick: () => {
                setOrdersTitle("كل الطلبات الصادرة في الانتظار الموافقه");
              },
            },
            {
              path: "/System/technical-support/orders/outgoing/inprogress",
              label: "قيد التنفيذ",
              onClick: () => {
                setOrdersTitle("كل الطلبات الصادرة قيد التنفيذ");
              },
            },
            {
              path: "/System/technical-support/orders/outgoing/done-paid",
              label: "تم الشراء",
              onClick: () => {
                setOrdersTitle("كل الطلبات الصادرة التى تم شرائها ");
              },
            },
          ],
        },
        {
          path: "/System/technical-support/orders/ingoing",
          label: "وارده",
          onClick: () => {
            setOrdersTitle("كل الطلبات الواردة");
          },
          links: [
            {
              path: "/System/technical-support/orders/ingoing/waiting",
              label: "في الانتظار الموافقه",
              onClick: () => {
                setOrdersTitle("كل الطلبات الواردة في الانتظار الموافقه");
              },
            },
            {
              path: "/System/technical-support/orders/ingoing/inprogress",
              label: "قيد التنفيذ",
              onClick: () => {
                setOrdersTitle("كل الطلبات الواردة قيد التنفيذ");
              },
            },
            {
              path: "/System/technical-support/orders/ingoing/done-paid",
              label: "تم الشراء",
              onClick: () => {
                setOrdersTitle("كل الطلبات الواردة التى تم شرائها");
              },
            },
            {
              path: "/System/technical-support/orders/ingoing/rejected",
              label: "مرفوضه",
              onClick: () => {
                setOrdersTitle("كل الطلبات الواردة المرفوضه");
              },
            },
          ],
        },
      ],
    },

    {
      eventKey: "2",
      header: "البنود",
      links: [
        { path: "/System/technical-support/terms/terms-task", label: "المهام" },
        {
          path: "/System/technical-support/terms/terms-order",
          label: "طلبات الشراء",
        },
      ],
    },
  ];
  const accordionItems = [
    {
      eventKey: "10",
      header: "طلبات المساعده",
      link: "System/technical-support/help-request",
      onClick: () => {
        setHelpRequestsTitle("كل الطلبات");
      },
      links: [
        {
          path: "/System/technical-support/help-request/outgoing/waiting",
          label: "في الانتظار الموافقه",
          onClick: () => {
            setHelpRequestsTitle("كل الطلبات في الانتظار الموافقه");
          },
          
        },
        {
          path: "/System/technical-support/help-request/outgoing/inprogress",
          label: "قيد التنفيذ",
          onClick: () => {
            setHelpRequestsTitle("كل الطلبات قيد التنفيذ");
          },
        },
        {
          path: "/System/technical-support/help-request/outgoing/done-check",
          label: "تم الفحص",
          onClick: () => {
            setHelpRequestsTitle("كل الطلبات التى تم فحصها ");
          },
        },
        {
          path: "/System/technical-support/help-request/outgoing/complete",
          label: "مكتمله",
          onClick: () => {
            setHelpRequestsTitle("كل الطلبات المكتملة");
          },
        },
      ],
    },
  ];
  const accordionItemsIT = [
    {
      eventKey: "0",
      header: "المهام",
      link: "System/technical-support/tasks",
      links: [
        {
          path: "/System/technical-support/tasks/waiting",
          label: "في الانتظار الموافقه",
        },
        {
          path: "/System/technical-support/tasks/inprogress",
          label: "قيد التنفيذ",
        },
        {
          path: "/System/technical-support/tasks/done-check",
          label: "تم الفحص",
        },
        { path: "/System/technical-support/tasks/completed", label: "مكتمله" },
      ],
    },
    {
      eventKey: "1",
      header: "طلبات الشراء",
      link: "System/technical-support/orders",
      onClick: () => {
        setOrdersTitle("كل الطلبات");
      },
      links: [
        {
          path: "/System/technical-support/orders/outgoing",
          label: "صادره",
          onClick: () => {
            setOrdersTitle("كل الطلبات الصادرة");
          },
          links: [
            {
              path: "/System/technical-support/orders/outgoing/waiting",
              label: "في الانتظار الموافقه",
              onClick: () => {
                setOrdersTitle("كل الطلبات الصادرة في الانتظار الموافقه");
              },
            },
            {
              path: "/System/technical-support/orders/outgoing/inprogress",
              label: "قيد التنفيذ",
              onClick: () => {
                setOrdersTitle("كل الطلبات الصادرة قيد التنفيذ");
              },
            },
            {
              path: "/System/technical-support/orders/outgoing/done-paid",
              label: "تم الشراء",
              onClick: () => {
                setOrdersTitle("كل الطلبات الصادرة التى تم شرائها ");
              },
            },
            {
              path: "/System/technical-support/orders/outgoing/rejected",
              label: "مرفوضه",
              onClick: () => {
                setOrdersTitle("كل الطلبات الصادرة المرفوضه");
              },
            },
          ],
        },
        {
          path: "/System/technical-support/orders/ingoing ",
          label: "وارده",
          onClick: () => {
            setOrdersTitle("كل الطلبات الواردة");
          },
          links: [
            {
              path: "/System/technical-support/orders/ingoing/waiting",
              label: "في الانتظار الموافقه",
              onClick: () => {
                setOrdersTitle("كل الطلبات الواردة في الانتظار الموافقه");
              },
            },
            {
              path: "/System/technical-support/orders/ingoing/inprogress",
              label: "قيد التنفيذ",
              onClick: () => {
                setOrdersTitle("كل الطلبات الواردة قيد التنفيذ");
              },
            },
            {
              path: "/System/technical-support/orders/ingoing/done-paid",
              label: "تم الشراء",
              onClick: () => {
                setOrdersTitle("كل الطلبات الواردة التى تم شرائها");
              },
            },
          ],
        },
      ],
    },
    // {
    //   eventKey: "10",
    //   header: "طلبات المساعده",
    //   link: "System/technical-support/help-request",
    //   links: [
    //     {
    //       path: "/System/technical-support/help-request/outgoing",
    //       label: "صادره",
    //       links: [
    //         {
    //           path: "/System/technical-support/help-request/outgoing/waiting",
    //           label: "في الانتظار الموافقه",
    //         },
    //         {
    //           path: "/System/technical-support/help-request/outgoing/inprogress",
    //           label: "قيد التنفيذ",
    //         },
    //         {
    //           path: "/System/technical-support/help-request/outgoing/done-paid",
    //           label: "تم الشراء",
    //         },
    //         {
    //           path: "/System/technical-support/help-request/outgoing/rejected",
    //           label: "مرفوضه",
    //         },
    //       ],
    //     },
    //     {
    //       path: "/System/technical-support/help-request/incoming ",
    //       label: "وارده",
    //       links: [
    //         {
    //           path: "/System/technical-support/help-request/incoming/waiting",
    //           label: "في الانتظار الموافقه",
    //         },
    //         {
    //           path: "/System/technical-support/help-request/incoming/inprogress",
    //           label: "قيد التنفيذ",
    //         },
    //         {
    //           path: "/System/technical-support/help-request/incoming/done-check",
    //           label: "تم الشراء",
    //         },
    //         {
    //           path: "/System/technical-support/help-request/incoming/completed",
    //           label: "مرفوضه",
    //         },
    //       ],
    //     },
    //   ],
    // },
    {
      eventKey: "2",
      header: "البنود",
      links: [
        { path: "/System/technical-support/terms/terms-task", label: "المهام" },
        {
          path: "/System/technical-support/terms/terms-order",
          label: "طلبات الشراء",
        },
      ],
    },
  ];

  const handleOpen = () => {
    if (location.pathname.includes("orders")) {
      setAddOrdersOpen(true);
    } else if (location.pathname.includes("help-request")) {
      setAddTasksOpen(true);
    } else if (location.pathname.includes("tasks")) {
      setAddTasksOpen(true);
    } else if (location.pathname.includes("terms/terms-task")) {
      setOpenTerm(true);
    } else if (location.pathname.includes("terms/terms-order")) {
      setAddTermsOpen(true);
    } else {
    }
  };

  const reset = () => {
    setShowOrdersOpen(false);
    setShowTasksOpen(false);
  };

  const renderSubAccordion = (links, parentIndex) => {
    return (
      <Accordion className="w-100">
        {links.map((subItem, subIndex) => (
          <Accordion.Item
            eventKey={`${parentIndex}-${subIndex}`}
            key={subIndex}
          >
            <Link
              to={subItem.path}
              className={`${
                location.pathname === subItem.path
                  ? "text-[#EFAA20]"
                  : subItem.links?.length > 0
                  ? ""
                  : "technicalSupportTab"
              } w-full text-right`}
            >
              <Accordion.Header
                onClick={() => {
                  setActiveEventKey(`${parentIndex}-${subIndex}`);
                  reset();
                  if (subItem.onClick) {
                    subItem.onClick();
                  }
                }}
                className={` rounded-md  ${
                  activeEventKey === `${parentIndex}-${subIndex}`
                    ? "border-2 rounded-md border-[#EFAA20] hover:border-transparent"
                    : subItem.links?.length > 0
                    ? "border-2 border-mainBorder rounded-md"
                    : "hover:!border-[#EFAA20]"
                } pointer flex w-full justify-between`}
              >
                {subItem.label}
                {subItem.links?.length > 0 && <MdKeyboardArrowDown size={20} />}
              </Accordion.Header>
            </Link>
            {subItem.links && (
              <Accordion.Body>
                {renderSubAccordion(
                  subItem.links,
                  `${parentIndex}-${subIndex}`
                )}
              </Accordion.Body>
            )}
          </Accordion.Item>
        ))}
      </Accordion>
    );
  };

  // const newData = (CheckRole("مدير") || Cookies.get("department") === "IT") ? accordionItemsManager : accordionItems;
  const newData = CheckRole("مدير")
    ? accordionItemsManager
    : Cookies.get("department") === "IT"
    ? accordionItemsIT
    : accordionItems;
  return (
    <>
      {addTasksOpen && <TechnicalSupportAddTasks />}
      {addOrdersOpen && <TechnicalSupportOrderAdd />}
      {addTermsOpen && <TechnicalSupportAddTerms />}
      {openTerm && <TechnicalSupportTermIssue />}
      <SystemControler
        child={
          <div className="flex items-center gap-2 relative">
            <BackButton />
            {location.pathname !== "/System/technical-support" && (
              <div
                className="add-user-button cursor-pointer customButton"
                onClick={handleOpen}
              >
                إضافة جديدة
              </div>
            )}
          </div>
        }
      />
      <div className="grid grid-cols-12 gap-2 h-full">
        <div className="hidden xl:flex col-span-3 h-full">
          <AllCategories
            child={
              <Accordion className="!w-full" defaultActiveKey={null}>
                <div className="py-3">
                  <Link
                    to="/System/technical-support"
                    className="text-mainText text-right"
                  >
                    الدعم الفني
                  </Link>
                </div>

                {newData.map((item, index) => (
                  <Accordion.Item eventKey={item.eventKey} key={item.eventKey}>
                    {item.eventKey !== "2" ? (
                      <Link
                        to={`/${item.link}`}
                        className={`${
                          location.pathname.startsWith(`/${item.link}`)
                            ? "text-[#EFAA20]"
                            : "text-mainText"
                        }`}
                      >
                        <Accordion.Header
                          onClick={() => {
                            setActiveEventKey(item.eventKey);
                            reset();
                            if (item.onClick) {
                              item.onClick();
                            }
                          }}
                          className={`border-2 rounded-md hover:!border-[#EFAA20] ${
                            activeEventKey === item.eventKey
                              ? "border-2 rounded-md border-[#EFAA20] hover:border-transparent"
                              : "border-2 border-mainBorder rounded-md"
                          } pointer`}
                        >
                          {item.header}
                          <MdKeyboardArrowDown size={20} />
                        </Accordion.Header>
                      </Link>
                    ) : (
                      <Accordion.Header
                        onClick={() => {
                          setActiveEventKey(item.eventKey);
                          reset();
                        }}
                        className={`border-2 rounded-md hover:!border-[#EFAA20] ${
                          activeEventKey === item.eventKey
                            ? "border-2 rounded-md border-[#EFAA20] hover:border-transparent"
                            : "border-2 border-mainBorder rounded-md"
                        } pointer`}
                      >
                        {item.header}
                        <MdKeyboardArrowDown size={20} />
                      </Accordion.Header>
                    )}
                    <Accordion.Body>
                      {renderSubAccordion(item.links, index)}
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            }
          />
        </div>
        <div className="col-span-12 xl:col-span-9 h-full">
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default TechnicalSupport;
