import React, { useEffect, useState } from "react";
import { useFinalApprove } from "../../../../hooks/fetchers/Requests";
import LoadingPage from "../../../feedback/LoadingPage";
import useSuccessfullModal from "../../../Modals/hooks/useSuccessfullModal";
import { FaCheck } from "react-icons/fa";
import SuccessfullModal from "../../../Modals/SuccessfullModal";
import { useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";

const FinalApprove = ({ id, path = "", setShowProject }) => {
  const navigate = useNavigate();
  const [message, setMessage] = useState("حدث خطأ ما");
  const {
    handleCloseError,
    handleCloseSuccess,
    handleShowError,
    handleShowSuccess,
    showError,
    showSuccess,
  } = useSuccessfullModal();

  const {
    mutate: finalApproveMutation,
    isLoading,
    isError,
    error,
  } = useFinalApprove(id, handleShowSuccess, handleShowError);
  useEffect(() => {
    setMessage(error?.response?.data?.message);
  }, [isError, error]);
  console.log(path, "path");
  return (
    <>
      {isLoading ? <LoadingPage /> : null}

      <button
        onClick={finalApproveMutation}
        className="flex items-center gap-1 bg-[#19B159] rounded-[3px] text-white text-xs p-1"
      >
        {isLoading ? <Spinner /> : "تحويل الي مشروع"}
        <FaCheck />
      </button>

      <SuccessfullModal
        show={showSuccess}
        message={"تم تحويل هذا الطلب الى مشروع بنجاح"}
        handleClose={() => {
          navigate(path);
          handleCloseSuccess();
          setShowProject(false);
          //   handleCloseAcceptModal();
        }}
      />
      <SuccessfullModal
        show={showError}
        message={message}
        handleClose={handleCloseError}
        status="error"
      />
    </>
  );
};

export default FinalApprove;
