import { toast } from "react-toastify";
import myAxiosInstance, { myAxiosJson } from "../https";
import { CheckRole } from "../utils";

export const getAllReminder = (params = {}) =>
  myAxiosInstance("reminder", { params }).then((data) => data?.data).catch(error=>{
    toast.error(error?.response?.data?.message)
});
export const getUserReminderSelect = () => {
  if (!CheckRole("موظف")) {
    return myAxiosInstance("user/reminder/select").then((data) => data?.data).catch(error=>{
      toast.error(error?.response?.data?.message)
  });
  }
};

export const getReminder = (reminderId) =>
  myAxiosInstance(`reminder/${reminderId}`).then((data) => data?.data).catch(error=>{
    toast.error(error?.response?.data?.message)
});

export const addReminder = (data) => myAxiosJson.post("reminder", data);

export const updateReminder = (reminderId, data) =>
  myAxiosJson.patch(`reminder/${reminderId}`, data);

export const deleteReminder = (data) =>
  myAxiosJson.delete(`reminder`, { data });
