import { useQuery } from "react-query";
import myAxiosInstance from "../../helper/https";
import Cookies from "js-cookie";

const getProfileProject = () =>
  myAxiosInstance("profile/projects")
    .then(({ data }) => data)
    .catch((err) => {
      console.log(err);
    });
const getProfileGoals = () =>
  myAxiosInstance("profile/goals")
    .then(({ data }) => data)
    .catch((err) => {
      console.log(err);
    });

export const useGetProfileProject = () => {
  return useQuery("profile/projects", getProfileProject, {
    enabled: !!Cookies.get("accessToken"),
  });
};

export const useGetProfileGoals = () => {
  return useQuery("profile/goals", getProfileGoals);
};
