import { useEffect, useRef, useState } from "react";

import { toBlob } from "html-to-image";
import PdfPreview from "../../../../Components/Preview/CustomPdfPreview";
import PdfDocument from "../../../Modules/Reports/components/PdfDocument";
import SectionElement from "../../../../Components/Preview/components/SectionElement";
import {
  clientTypeEnum,
  identityTypeEnum,
  projectTypeEnum,
  statusEnum,
} from "../../../../helper/utils";
import moment from "moment";

const PrintProjectDetailsFile = ({ show, onHide, data }) => {
  const refs = useRef([]);
  const [imageUrls, setImageUrls] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (show) {
      const captureImages = async () => {
        setLoading(true);
        const blobs = await Promise.all(
          refs.current.map((ref) => toBlob(ref, { pixelRatio: 2 }))
        );
        const urls = blobs.map((blob) => URL.createObjectURL(blob));
        setImageUrls(urls);
        setLoading(false);
      };

      captureImages();
    }
  }, [show]);

  console.log("PrintProjectDetailsFile", data);
  return (
    <>
      <PdfPreview show={show} onHide={onHide}>
        {imageUrls.length > 0 && (
          <PdfPreview.DownloadButton
            document={<PdfDocument imageUrls={imageUrls} />}
            loading={loading}
          />
        )}

        <div ref={(el) => (refs.current[0] = el)} className="rounded-t w-full">
          <PdfPreview.Header titleAr={"مشروع"} titleEn={"Project"} createdAt={`تاريخ الانشاء : ${moment(data?.createdAt).format("YYYY-MM-DD")}`} />

          <PdfPreview.Body>
            <div className="flex justify-between items-center">
              <PdfPreview.SectionTitle title={"معلومات عامة"} />
              <SectionElement
                title="رقم الطلب"
                disc={data?.orderNumber || "لا يوجد بيانات"}
                className="w-1/2 gap-0 px-2"
              />
            </div>

            <PdfPreview.SectionWrapper>
              <SectionElement title="اسم العميل" disc={data?.requestId?.ownerName || "لا يوجد بيانات"} />
              <SectionElement title="نوع العميل" disc={clientTypeEnum[data?.requestId?.clientType] || "لا يوجد بيانات"} />
              <SectionElement title="رقم الجوال" disc={data?.requestId?.phone || "لا يوجد بيانات"} />
              <SectionElement title="نوع الهويه" disc={identityTypeEnum[data?.requestId?.identityType] || "لا يوجد بيانات"} />
            </PdfPreview.SectionWrapper>

            <div className="">
              <PdfPreview.SectionTitle title={"بيانات المشروع "} />
              <PdfPreview.SectionWrapper>
              <SectionElement title="اسم المشروع" disc={data?.projectName || "لا يوجد بيانات"} />
              <SectionElement title="نوع المشروع" disc={projectTypeEnum[data?.projectType] || "لا يوجد بيانات"} />
              <SectionElement title="موقع المشروع" disc={data?.buildingLocation || "لا يوجد بيانات"} />
              <SectionElement title="حالة" disc={statusEnum[data?.projectStatus]?.title || "لا يوجد بيانات"} />
            </PdfPreview.SectionWrapper>
            </div>
            <div className="">
              <PdfPreview.SectionTitle title={"معلومات الدفع "} />
              <PdfPreview.SectionWrapper>
              <SectionElement title="المبلغ الكلى" disc={data?.totalAmount ? data?.totalAmount + " ريال" :  "لا يوجد بيانات"} />
              <SectionElement title="المبلغ المتبقى" disc={data?.remainingAmount ? data?.remainingAmount + " ريال" : "لا يوجد بيانات"} />
              <SectionElement title="عدد الدفعات" disc={data?.batchCount ? data?.batchCount + " دفعة" : "لا يوجد بيانات"} />
              <SectionElement title="عدد الدفعات المتبقى" disc={data?.remainingBatch ? data?.remainingBatch + " دفعة" : "لا يوجد بيانات"} />
              <SectionElement title="نوع الدفعة" disc={data?.batcheId?.name || "لا يوجد بيانات"} />
            </PdfPreview.SectionWrapper>
            </div>
            <div className="">
              <PdfPreview.SectionTitle title={"بيانات الموظف"} />
              <PdfPreview.SectionWrapper>
              <SectionElement title="الاسم" disc={`${data?.createdBy?.firstName} ${data?.createdBy?.lastName}`} />
              <SectionElement title="البلد" disc={data?.createdBy?.country} />
              <SectionElement title="القسم" disc={data?.createdBy?.department} />
              <SectionElement title="الصلاحية" disc={data?.createdBy?.role} />
            </PdfPreview.SectionWrapper>
            </div>
          </PdfPreview.Body>
        </div>
      </PdfPreview>
    </>
  );
};



export default PrintProjectDetailsFile;
