import React from "react";
import TechnicalSupportTermsOrderSearch from "./components/TechnicalSupportTermsOrderSearch";
import TechnicalSupportTermsOrderTable from "./components/TechnicalSupportTermsOrderTable";

const TechnicalSupportTermsOrder = () => {
  return (
    <div className="flex w-full gap-2 h-full">
      <TechnicalSupportTermsOrderSearch />
      <TechnicalSupportTermsOrderTable />
    </div>
  );
};

export default TechnicalSupportTermsOrder;
