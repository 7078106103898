import myAxiosInstance, { myAxiosJson } from "../../helper/https";

export const getCommentById = (taskId, commentId) =>
  myAxiosInstance.get(`task/${taskId}/comment/${commentId}`);


export const addComment = (taskId, data) =>
  myAxiosJson.post(`task/${taskId}/comment`, data);
export const replyComment = (commentId, data) =>
  myAxiosJson.post(`comment/${commentId}/replay/`, data);
export const likeComment = (taskId,commentId) =>
  myAxiosJson.patch(`task/${taskId}/comment/${commentId}/like`);
export const unlikeComment = (taskId,commentId) =>
  myAxiosJson.patch(`task/${taskId}/comment/${commentId}/unlike`);
export const getMentions = (taskId) =>
    myAxiosInstance.get(`user/task/mention/${taskId}`);