import React, { Fragment, useEffect, useState } from "react";
import { FormControl, IconButton } from "@mui/material";
import { FormModal } from "../components/FormModal";
import { IoMdArrowDropright } from "react-icons/io";
import Modal from "react-bootstrap/Modal";
import { ModalTitle } from "../components/ModalTitle";
import { InputLabel } from "../components/InputLabel";
import { useNavigate, useParams } from "react-router-dom";
import SystemControler from "../../../../Components/System/SystemControler/SystemControler";

import { useForm, Controller } from "react-hook-form";
import {
  CustomDatePicker,
  CustomeInputFile,
  CustomInput,
  CustomTimePicker,
} from "../../../../Components/FormUi";
import { useGetSupervisors } from "../../Tasks/hooks/useGetSupervisors";

import { format } from "date-fns";
import { FaCheck } from "react-icons/fa6";
import { myAxiosJson } from "../../../../helper/https";
import useSuccessfullModal from "../../../../Components/Modals/hooks/useSuccessfullModal";
import SuccessfullModal from "../../../../Components/Modals/SuccessfullModal";
import { useQuery } from "react-query";
import Select from "../../../../Components/FormHandler/Select";
import { useGetTask, useUpdateTask } from "../../../../hooks/fetchers/Tasks";
import { clientTypeEnum } from "../../../../helper/utils";
import { FaFileDownload } from "react-icons/fa";
import { Button } from "react-bootstrap";
import { PdfIcon } from "../../../../utiltis/Icons";
import LoadingPage from "../../../../Components/feedback/LoadingPage";
import { ListErrorComponent } from "../../../../Components/Errors/ListErrorComponent";
import { CustErrorMessage } from "../../../../Components/Errors/CustErrorMessage";
import dayjs from "dayjs";
import CustomTextArea from "../../../../Components/FormUi/CustomTextArea";
const manipulateDataFormat = (data) => {
  data.isExisting = Boolean(parseInt(data.isExisting));
  data.startDate = format(data.startDate, "MM/dd/yyyy");
  data.endDate = format(data.endDate, "MM/dd/yyyy");
  data.startTime = format(data.startTime, "HH:mm:aa");
  data.endTime = format(data.endTime, "HH:mm:aa");
  if (data.projectType) {
    data.projectType = data.projectType.value;
  }
  if (data.projectId) {
    data.projectId = data.projectId.value;
  }
  data.owner = data.owner.map((person) => person.value);
  return data;
};

const convertTime = (time) => {
  if (time) {
    const formattedTime = dayjs(time, "hh:mm A");
    return formattedTime.toDate(); // Convert dayjs object to JavaScript Date object
  }
};

const projectTypes = [
  {
    value: "1",
    label: "تصميم",
  },
  {
    value: "2",
    label: "اشراف علي التنفيذ",
  },
];
const useGetPlans = () => {
  return useQuery("plan/name", () => {
    return myAxiosJson("plan/name").then((data) => {
      const plans = data.data.plans.map((plan) => {
        if (plan.isExist == false) {
          return {
            _id: plan._id,
            projectName: plan.projectName,
          };
        } else if (plan.isExist == true) {
          return {
            _id: plan._id,
            projectName: plan.projectId.projectName,
          };
        }
        return plan;
      });
      return plans;
    });
  });
};

const ValidateFileSize = (value) => {
  if (value.length > 4) {
    return "لا يجب ان تزيد عدد الملفات عن 4";
  }
  return true;
};

export default function EditTask() {
  const [errorMessage, setErrorMessage] = useState("حدث خطأ ما");
  const navigate = useNavigate();
  const { taskId } = useParams();
  const { data: projects } = useGetPlans();
  const { data: supervisors } = useGetSupervisors();
  const { data: task } = useGetTask(taskId);
  const {
    showSuccess,
    showError,
    handleShowSuccess,
    handleShowError,
    handleCloseSuccess,
    handleCloseError,
  } = useSuccessfullModal();
  const updatePlanMutation = useUpdateTask(
    taskId,
    handleShowSuccess,
    handleShowError
  );
  const {
    register,
    control,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: async () => {
      const res = await myAxiosJson(`task/${taskId}`);
      const data = res.data.task;
      let projectType;
      let clientType;
      let projectId;
      console.log("Hello Data", data);
      const startTimeString = convertTime(data?.startTime);
      const endTimeString = convertTime(data?.endTime);

      if (data?.isExisting === false) {
        projectId = { label: data?.projectName, value: data?._id };
      } else {
        // if(data?.planId?.isExist === true ){
        //   projectId = {label:data?.planId?.projectId?.projectName ,value:data?.planId?.projectId?.id}
        // }
        // else{
        //   projectId = {label:data?.planId?.projectName ,value:data?.planId?._id}
        // }
        projectId = {
          label: data?.planId?.projectName,
          value: data?.planId?._id,
        };
      }

      if (data?.isExisting === false) {
        clientType = data?.clientType;
      } else {
        if (data?.planId?.isExist === true) {
          clientType = clientTypeEnum[data?.planId?.projectId?.clientType];
        } else {
          clientType = data?.planId?.clientType;
        }
      }

      if (data?.isExisting === false) {
        projectTypes.find(
          (project) => project.value == data?.planId?.projectId?.projectType
        );

        projectType = projectTypes[+data?.projectType];
      } else {
        if (data?.planId?.isExist) {
          projectType = projectTypes[data?.planId?.projectId?.projectType];
        } else {
          projectType = projectTypes[data?.planId?.projectType];
        }
      }
      return {
        /**
         * isExisting
planId
projectName
projectType
clientType
description
startDate
endDate
startTime
endTime
assignTo
         */

        isExisting: `${+data?.isExisting}`,
        // projectId: data?._id,

        projectId: projectId,

        taskName: data?.projectName,
        projectType: projectTypes.find(
          (project) => project.value == data?.planId?.projectId?.projectType
        ),
        // projectTypes.find(project => project.value == data?.planId?.projectId?.projectType)
        clientType: clientType,
        buildingLocation: data?.buildingLocation,
        description: data?.description,
        startDate: new Date(data?.startDate),
        endDate: new Date(data?.endDate),
        startTime: startTimeString,
        endTime: endTimeString,
        owner: data?.assignTo?.map((item) => ({
          label: `${item.firstName} ${item.lastName}`,
          value: item._id,
        })),
      };
    },
  });
  const onSubmit = (data) => {
    data = manipulateDataFormat(data);

    var formdata = new FormData();
    formdata.append("isExisting", data.isExisting);
    if (data.isExisting === true) {
      formdata.append("planId", data.projectId);
    } else if (data.isExisting === false) {
      formdata.append("projectName", data.taskName);
      formdata.append("projectType", data.projectType);
      formdata.append("clientType", data.clientType);
      formdata.append(
        "buildingLocation",
        data.buildingLocation || "لا يوجد بيانات"
      );
    }
    formdata.append("description", data.description);
    formdata.append("startDate", data.startDate);
    formdata.append("endDate", data.endDate);
    formdata.append("startTime", data.startTime);
    formdata.append("endTime", data.endTime);
    for (let i = 0; i < data.owner.length; i++) {
      formdata.append(`assignTo[${i}]`, data.owner[i]);
    }
    for (let item of data.fileTask) {
      formdata.append("fileTask", item);
    }
    for (let item of data.attachmentTask) {
      formdata.append("attachmentTask", item);
    }

    updatePlanMutation.mutate(formdata);
    // console.log(data);
  };
  const selectedOption = watch("isExisting");
  const selectedFileTask = watch("fileTask");
  const selectedPapersProject = watch("attachmentTask");
  useEffect(() => {
    if (updatePlanMutation.isError) {
      if (updatePlanMutation?.error?.response?.data?.validationErrors) {
        setErrorMessage(
          <ListErrorComponent
            messages={updatePlanMutation?.error?.response?.data?.validationErrors?.split(
              ","
            )}
          />
        );
      } else if (updatePlanMutation?.error?.response?.data?.message) {
        setErrorMessage(updatePlanMutation?.error?.response?.data?.message);
      }
    }
  }, [updatePlanMutation.isError]);
  console.log("error", updatePlanMutation?.error);
  console.log("asdasdasdas");
  return (
    <EditProjectPageWrapper>
      {updatePlanMutation.isLoading ? <LoadingPage /> : null}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Form>
          <FormModal title={"بحث عن المشروع"}>
            <div className="grid grid-cols-2 mb-3">
              <div className="flex items-center gap-2">
                <label
                  style={{
                    borderWidth: "1px",
                    borderStyle: "solid",
                    borderColor: selectedOption === "1" ? "#EFAA20" : "#414162",
                  }}
                  className="bg-mainNavy rounded w-5 h-5 flex items-center justify-center"
                >
                  {selectedOption === "1" && (
                    <FaCheck color="var(--main-text)" />
                  )}
                  <input
                    className="appearance-none"
                    type="radio"
                    id="exist-project"
                    value={1}
                    {...register("isExisting")}
                  />
                </label>
                <label htmlFor="exist-project">مشروع متاح</label>
              </div>

              <div className="flex items-center gap-2">
                <label
                  style={{
                    borderWidth: "1px",
                    borderStyle: "solid",
                    borderColor: selectedOption === "0" ? "#EFAA20" : "#414162",
                  }}
                  className="bg-mainNavy rounded w-5 h-5 flex items-center justify-center"
                >
                  {selectedOption === "0" && (
                    <FaCheck color="var(--main-text)" />
                  )}
                  <input
                    className="appearance-none"
                    type="radio"
                    id="new-project"
                    value={0}
                    {...register("isExisting", {
                      required: "يجب اختيار حالة المشروع",
                    })}
                  />
                </label>
                <label htmlFor="new-project">مشروع جديد</label>
              </div>
            </div>

            {selectedOption === "1" ? (
              <div className="grid grid-cols-2">
                <FormControl fullWidth>
                  <Controller
                    control={control}
                    name="projectId"
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Select
                        value={value}
                        fullWidth
                        onChange={onChange}
                        onBlur={onBlur}
                        label={"اسم المشروع"}
                        placeholder="ادخل اسم المشروع"
                        OptionbackgroundColor="#414162"
                        options={projects?.map(({ _id, projectName }) => ({
                          label: projectName,
                          value: _id,
                        }))}
                        mandatory
                      />
                    )}
                  />
                </FormControl>
              </div>
            ) : selectedOption === "0" ? (
              <>
                <div className="grid grid-cols-2 gap-4 mb-3">
                  <FormControl fullWidth>
                    <InputLabel id="new-project" label={"اسم مهمه جديد"} />
                    <CustomInput
                      control={control}
                      name="taskName"
                      placeholder="اسم مهمه جديد"
                      rules={{
                        pattern: {
                          value: /^[A-Za-z\u0600-\u06FF ]{3,}$/i,
                          message:
                            "يجب ان تتكون من حروف عربى فقط او انجليزية فقط رقم",
                        },
                        minLength: {
                          value: 3,
                          message: "لا يقل عن 3 حروف",
                        },
                        maxLength: {
                          value: 100,
                          message: "لا يقل عن 100 حروف",
                        },
                      }}
                    />
                    <CustErrorMessage name={"taskName"} errors={errors} />
                  </FormControl>
                  <FormControl fullWidth>
                    <InputLabel id="new-project" label={"نوع العميل"} />
                    <CustomInput
                      name={"clientType"}
                      control={control}
                      rules={{
                        pattern: {
                          value: /^[A-Za-z\u0600-\u06FF ]{3,}$/i,
                          message:
                            "يجب ان تتكون من حروف عربى فقط او انجليزية فقط رقم",
                        },
                        minLength: {
                          value: 3,
                          message: "لا يقل عن 3 حروف",
                        },
                        maxLength: {
                          value: 100,
                          message: "لا يقل عن 100 حروف",
                        },
                      }}
                    />
                    <CustErrorMessage name={"clientType"} errors={errors} />
                  </FormControl>
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <FormControl fullWidth>
                    <Controller
                      control={control}
                      name="projectType"
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Select
                          fullWidth
                          value={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          label={"نوع المشروع جديد"}
                          placeholder="اخل نوع المشروع"
                          OptionbackgroundColor="#414162"
                          options={projectTypes}
                          mandatory
                        />
                      )}
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <InputLabel id="new-project" label={"موقع المشروع"} />
                    <CustomInput name={"buildingLocation"} control={control} />
                  </FormControl>
                </div>
              </>
            ) : null}
          </FormModal>

          <FormModal title={"تفاصيل المهمة"}>
            <div className="grid grid-cols-2 gap-4 mb-3">
              <FormControl fullWidth>
                <Controller
                  control={control}
                  name="owner"
                  multiple={true}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Select
                      value={value}
                      fullWidth
                      onChange={onChange}
                      onBlur={onBlur}
                      label={"اسم الموظف"}
                      placeholder="ادخل اسم الموظف"
                      OptionbackgroundColor="#414162"
                      isMulti={true}
                      options={supervisors?.map(
                        ({ _id, firstName, lastName }) => ({
                          label: `${firstName} ${lastName}`,
                          value: _id,
                        })
                      )}
                      mandatory
                    />
                  )}
                />
              </FormControl>
            </div>
            <div className="grid grid-cols-2 gap-4 mb-3">
              <FormControl fullWidth>
                <InputLabel id="endDate" label={"تاريخ التسليم"} />
                <CustomDatePicker control={control} name="endDate" />
              </FormControl>
              <FormControl fullWidth>
                <InputLabel id="startDate" label={"تاريخ الاستلام"} />
                <CustomDatePicker control={control} name="startDate" />
              </FormControl>
            </div>
            <div className="grid grid-cols-2 gap-4 mb-3">
              <FormControl fullWidth>
                <InputLabel id="startTime" label={"وقت التسليم"} />
                <CustomTimePicker control={control} name="endTime" />
              </FormControl>
              <FormControl fullWidth>
                <InputLabel id="startTime" label={"وقت الاستلام"} />
                <CustomTimePicker control={control} name="startTime" />
              </FormControl>
            </div>
            <FormControl fullWidth>
              <InputLabel label={"وصف المهمة"} />

              <CustomTextArea
                control={control}
                name="description"
                placeholder={"اكتب ملاحظات .................................."}
              />
            </FormControl>
          </FormModal>

          <FormModal title={"ملفات المشروع"}>
            <div className="flex items-start gap-2">
              <CustomeInputFile
                register={{
                  ...register("fileTask", {
                    validate: ValidateFileSize,
                  }),
                }}
                name="fileTask"
                multiple={true}
              />
              {!selectedFileTask?.length && task?.fileTask?.length ? (
                <ShowFiles files={task?.fileTask} />
              ) : null}
            </div>
            <CustErrorMessage name={"fileTask"} errors={errors} />
          </FormModal>
          <FormModal title={"مرفقات المشروع"}>
            <div className="flex items-start gap-2">
              <CustomeInputFile
                register={{
                  ...register("attachmentTask", {
                    validate: ValidateFileSize,
                  }),
                }}
                name="attachmentTask"
                multiple={true}
              />

              {!selectedPapersProject?.length &&
              task?.attachmentTask?.length ? (
                <ShowFiles files={task?.attachmentTask} />
              ) : null}
            </div>
            <CustErrorMessage name={"attachmentTask"} errors={errors} />
          </FormModal>
        </Form>
      </form>
      <SuccessfullModal
        handleClose={() => {
          handleCloseSuccess();
          navigate("/System/tasks/plans");
        }}
        message={"تمت تعديل المشروع بنجاح"}
        show={showSuccess}
      />
      <SuccessfullModal
        handleClose={() => {
          handleCloseError();
          //   navigate("/System/tasks/plans");
        }}
        message={errorMessage}
        show={showError}
        status="error"
      />
    </EditProjectPageWrapper>
  );
}

const Form = ({ children }) => {
  return (
    <>
      <div className="h-[800px] overflow-auto scrollbar-none flex flex-col gap-4 ">
        {children}
      </div>
      <div className="flex justify-end mt-4">
        <button
          type="submit"
          className="w-[140px] h-[30px]  bg-[#EFAA20] rounded-[6px] text-white text-[15px] font-medium"
        >
          حفظ
        </button>
      </div>
    </>
  );
};

const EditProjectPageWrapper = ({ children }) => {
  const navigate = useNavigate();
  return (
    <Fragment>
      <SystemControler
        child={
          <IconButton
            onClick={() => {
              navigate(-1);
            }}
          >
            <IoMdArrowDropright color="white" fontSize={25} />
          </IconButton>
        }
      />
      <div className="bg-mainItems mb-2 p-5 border rounded-[19px] text-mainText !border-[#EFAA20] h-full">
        <div className="my-3">
          <ModalTitle title={" تعديل مشروع "} />
        </div>
        {children}
      </div>
    </Fragment>
  );
};

const ShowFiles = ({ files }) => {
  const { show, handleClose, handleShow } = useOpenModal();
  const [content, setContent] = useState(null);

  // console.log("files: ", files);
  return (
    <>
      <Example show={show} handleClose={handleClose} content={content} />
      {files?.map((file, index) => {
        const fileType = getType(file);
        switch (fileType) {
          case "jpg":
          case "jpeg":
          case "png":
          case "gif":
            return (
              <div
                key={index}
                onClick={() => {
                  setContent(
                    <>
                      <Button
                        className="bg-mainItems"
                        download={true}
                        href={file}
                      >
                        <FaFileDownload fontSize={25} color="#EFAA20" />
                      </Button>
                      <img
                        className="w-96  rounded-xl border"
                        src={file}
                        alt=""
                      />
                    </>
                  );
                  handleShow();
                }}
              >
                <img
                  className="w-24 h-24 rounded-xl border"
                  src={file}
                  alt=""
                />
              </div>
            );
          case "pdf":
            return (
              <div
                key={index}
                onClick={() => {
                  setContent(
                    <>
                      <Button
                        className="bg-mainItems"
                        download={true}
                        href={file}
                      >
                        <FaFileDownload fontSize={25} color="#EFAA20" />
                      </Button>
                      <embed
                        src={file}
                        type="application/pdf"
                        width="400"
                        height="600"
                        className="w-96 relative rounded-xl border"
                      />
                    </>
                  );
                  handleShow();
                }}
                className="flex justify-center items-center w-24 h-24 rounded-xl border"
              >
                <PdfIcon />
              </div>
            );
          case "zip":
            return (
              <div
                key={index}
                onClick={() => {
                  setContent(
                    <>
                      <Button
                        className="bg-mainItems"
                        download={true}
                        href={file}
                      >
                        <FaFileDownload fontSize={25} color="#EFAA20" />
                      </Button>
                      <img
                        className="w-96   rounded-xl border"
                        src="https://p7.hiclipart.com/preview/258/300/47/computer-icons-zip-truetype-icon-png-file-zip.jpg"
                        alt=""
                      />
                    </>
                  );
                  handleShow();
                }}
              >
                <img
                  className="w-24 h-24 rounded-xl border"
                  src="https://p7.hiclipart.com/preview/258/300/47/computer-icons-zip-truetype-icon-png-file-zip.jpg"
                  alt=""
                />
              </div>
            );
          case "xlsx":
            return (
              <div
                key={index}
                onClick={() => {
                  setContent(
                    <>
                      <Button
                        className="bg-mainItems"
                        download={true}
                        href={file}
                      >
                        <FaFileDownload fontSize={25} color="#EFAA20" />
                      </Button>
                      <img
                        className="w-96   rounded-xl border"
                        src="https://logodownload.org/wp-content/uploads/2020/04/excel-logo-0.png"
                        alt=""
                      />
                    </>
                  );
                  handleShow();
                }}
              >
                <img
                  className="w-24 h-24 rounded-xl border"
                  src="https://logodownload.org/wp-content/uploads/2020/04/excel-logo-0.png"
                  alt=""
                />
              </div>
            );
          case "rar":
            return (
              <div
                key={index}
                onClick={() => {
                  setContent(
                    <>
                      <Button
                        className="bg-mainItems"
                        download={true}
                        href={file}
                      >
                        <FaFileDownload fontSize={25} color="#EFAA20" />
                      </Button>
                      <img
                        className="w-96  rounded-xl border"
                        src="https://cdn-icons-png.freepik.com/512/9158/9158683.png"
                        alt=""
                      />
                    </>
                  );
                  handleShow();
                }}
              >
                <img
                  className="w-24 h-24 rounded-xl border"
                  src="https://cdn-icons-png.freepik.com/512/9158/9158683.png"
                  alt=""
                />
              </div>
            );
          default:
            return null;
        }
      })}
    </>
  );
};
function Example({ show, handleClose, content }) {
  return (
    <Modal
      size="lg"
      centered
      show={show}
      onHide={handleClose}
      contentClassName="p-0 bg-mainNavy w-fit mx-auto"
      className="bg-black/50"
    >
      <Modal.Body className="flex flex-col items-center">{content}</Modal.Body>
    </Modal>
  );
}

const getType = (fileName) => {
  const fileExtension = fileName.split(".").pop().toLowerCase();
  return fileExtension;
};
const useOpenModal = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return {
    show,
    handleClose,
    handleShow,
  };
};
