import { useState } from 'react';

const useImageActions = () => {
    const [showImg, setShowImg] = useState(false);
    const handleStartShow = () => setShowImg(true);
    const handleStopShow = () => setShowImg(false);
    const handleToggleShow = () => setShowImg(!showImg);
    return { showImg, handleStartShow, handleStopShow, handleToggleShow };
  };

export default useImageActions