import { useEffect, useState } from "react";
import { myAxiosJson } from "../../../../helper/https";
import { useQuery } from "react-query";

const getStatistics = () =>
  myAxiosJson("statics/tasks")
    .then((data) => data?.data)
    .catch((err) => {
      console.log(err);
    });

export const useGetStatistics = () => {
  return useQuery("statics/tasks", getStatistics);
  const [statistics, setStatistics] = useState([]);
  useEffect(() => {
    myAxiosJson("statics/tasks").then((data) => {
      // console.log('statics/tasks',data?.data);
      setStatistics(data?.data);
    });
  }, []);
  return { statistics };
};
