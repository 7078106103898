import React, { useContext, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import Image from "../../../../Components/Image";
import LoadingPage from "../../../../Components/feedback/LoadingPage";
import { TechnicalSupportContext } from "./TechnicalSupportProvider";
import {
  CustomDatePicker,
  CustomeInputFile,
  CustomTimePicker,
} from "../../../../Components/FormUi";
import { Controller, useForm } from "react-hook-form";
import Select from "../../../../Components/FormHandler/Select";
import SuccessfullModal from "../../../../Components/Modals/SuccessfullModal";
import { InputLabel } from "../../PlanModel/components/InputLabel";
import { FormModal } from "../../PlanModel/components/FormModal";
import { FormControl } from "@mui/material";
import { CustErrorMessage } from "../../../../Components/Errors/CustErrorMessage";
import {
  useAddItTask,
  useAllAdminSelect,
  useAllEmployeeSelect,
  useGetAllTaskIssue,
  useGetAllTechnicalSupportTermsOrder,
} from "../../../../hooks/fetchers/TechnicalSupportPurcheseOrder";
import useSuccessfullModal from "../../../../Components/Modals/hooks/useSuccessfullModal";
import moment from "moment";
import { CheckRole } from "../../../../helper/utils";
import Cookies from "js-cookie";
const TechnicalSupportAddTasks = () => {
  const validateFiles = (value) => {
    if (value.length > 8) {
      return "يمكنك تحميل ما يصل إلى 8 صور فقط";
    }
    return true;
  };
  const { setAddTasksOpen, addTasksOpen } = useContext(TechnicalSupportContext);
  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({});
  const { data: employeeData, isLoading: isEmployeeDataLoading } =
    useAllEmployeeSelect();
  const { data: categoryData, isLoading: isCategoryDataLoading } =
    useGetAllTaskIssue();
  const status = [
    { label: "في انتظار الموافقه", value: "0" },
    { label: "قيد التنفيذ", value: "1" },
    { label: "مكتمله", value: "2" },
    { label: "تم الفحص", value: "70" },
  ];

  const {
    showSuccess,
    showError,
    handleShowSuccess,
    handleShowError,
    handleCloseSuccess,
    handleCloseError,
  } = useSuccessfullModal();

  const { mutate, isLoading: AddLoading } = useAddItTask(
    handleShowSuccess,
    handleShowError
  );

  const { data: adminData } = useAllAdminSelect();
  const [err, setError] = useState("");
  const onSubmit = (formData) => {
    // console.log(data);
    var newFormData = new FormData();
    formData.taskType?.forEach((item, index) => {
      newFormData.append(`taskType[${index}]`, item?.value);
    });
    if (!CheckRole("مدير")) {
      newFormData.append("knwoingly", formData.knwoingly?.value);
    }
    if (Cookies.get("department") === "IT") {
      newFormData.append("employee", formData.employee?.value);
      newFormData.append("status", formData.status?.value);
      newFormData.append("dateFrom", formData.dateFrom);
      newFormData.append("dateTo", formData.dateTo);
      newFormData.append(
        "timeFrom",
        moment(formData.timeFrom).format("HH:MM A")
      );
      newFormData.append("timeTo", moment(formData.timeTo).format("HH:MM A"));
    }
    newFormData.append("description", formData.description);

    if (formData.attachments) {
      for (let item of formData.attachments) {
        newFormData.append("attachments", item);
      }
    }
    mutate(newFormData, {
      onSuccess: () => {
        reset();
      },
      onError: (err) => {
        setError(
          err?.response?.data?.validationErrors || err?.response?.data?.message
        );
      },
    });
  };

  return (
    <>
      {isEmployeeDataLoading || isCategoryDataLoading || AddLoading ? (
        <LoadingPage />
      ) : (
        <AddTechnicalSupportWrapper
          show={addTasksOpen}
          onHide={() => setAddTasksOpen(false)}
        >
          <Form
            onSubmit={handleSubmit(onSubmit)}
            encType="multipart/form-data"
            className="row my-4 date-input w-100 mx-auto p-3"
          >
            {Cookies.get("department") === "IT" && (
              <FormModal title={"مخصصه الي"}>
                <div className="grid grid-cols-2 gap-4 mb-3">
                  <FormControl fullWidth>
                    <InputLabel id="new-project" label={"اسم الموظف"} />
                    <Controller
                      control={control}
                      name="employee"
                      render={({ field }) => (
                        <Select
                          {...field}
                          async={false}
                          placeholder="اختر الموظف"
                          options={employeeData?.map((x) => ({
                            value: x?._id,
                            label: `${x?.firstName} ${x?.lastName} `,
                          }))}
                          onChange={(selected) => {
                            field.onChange(selected);
                          }}
                        />
                      )}
                    />
                    <CustErrorMessage name={"projectName"} errors={errors} />
                  </FormControl>
                </div>
              </FormModal>
            )}
            <FormModal title={"تفاصيل المهمه"} className="my-4">
              <div className="grid grid-cols-2 gap-4 mb-3">
                <FormControl fullWidth>
                  <Controller
                    control={control}
                    name="taskType"
                    rules={{
                      required: "يجب اختيار نوع المهمه",
                    }}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Select
                        fullWidth
                        onChange={onChange}
                        onBlur={onBlur}
                        isMulti
                        label={"نوع المهمه"}
                        placeholder="ادخل نوع المهمه"
                        OptionbackgroundColor="#414162"
                        options={categoryData?.map((x) => ({
                          value: x?._id,
                          label: x.name,
                        }))}
                        mandatory
                      />
                    )}
                  />

                  <CustErrorMessage name={"projectName"} errors={errors} />
                </FormControl>
                {Cookies.get("department") === "IT" && (
                  <>
                    <FormControl fullWidth>
                      <Controller
                        control={control}
                        name="status"
                        rules={{
                          required: "يجب اختيار حاله المهمه",
                        }}
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <Select
                            fullWidth
                            onChange={onChange}
                            onBlur={onBlur}
                            label={"حاله المهمه"}
                            placeholder="ادخل حاله المهمه"
                            OptionbackgroundColor="#414162"
                            options={status}
                            mandatory
                          />
                        )}
                      />

                      <CustErrorMessage name={"projectName"} errors={errors} />
                    </FormControl>
                    <FormControl fullWidth>
                      <InputLabel id="startDate" label={"من تاريخ"} />
                      <CustomDatePicker
                        control={control}
                        name="dateFrom"
                        rules={{ required: "يجب اضافة من الاستلام" }}
                      />
                      <CustErrorMessage name={"startDate"} errors={errors} />
                    </FormControl>
                    <FormControl fullWidth>
                      <InputLabel id="startDate" label={"الي تاريخ"} />
                      <CustomDatePicker
                        control={control}
                        name="dateTo"
                        rules={{ required: "يجب اضافة الي الاستلام" }}
                      />
                      <CustErrorMessage name={"startDate"} errors={errors} />
                    </FormControl>
                    <FormControl fullWidth>
                      <InputLabel label={"من وقت"} />
                      <CustomTimePicker
                        control={control}
                        name="timeFrom"
                        rules={{
                          required: "من فضلك اكتب من وقت",
                        }}
                      />
                    </FormControl>
                    <FormControl fullWidth>
                      <InputLabel label={"الي وقت"} />
                      <CustomTimePicker
                        control={control}
                        name="timeTo"
                        rules={{
                          required: "من فضلك اكتب الي    وقت",
                        }}
                      />
                    </FormControl>
                  </>
                )}
              </div>
            </FormModal>
            <FormModal title={"وصف المهمه"}>
              <FormControl fullWidth className="mb-3">
                <InputLabel size={18} label={"وصف المهمه"} className={"mb-3"} />
                <textarea
                  className="w-full bg-mainNavy py-3 px-2 rounded-[7px] outline-none"
                  rows={5}
                  placeholder="ادخل وصف المهمه"
                  {...register("description", {
                    required: "يجب إدخال الإسم",
                    minLength: {
                      value: 5,
                      message: "يجب أدخال 5 حروف على الأقل",
                    },
                    maxLength: {
                      value: 255,
                      message: "لا يجب أن تزيد عن 255 حرف",
                    },
                  })}
                ></textarea>
                {errors.descraption && (
                  <span className="text-red-500">
                    {errors.descraption.message}
                  </span>
                )}
              </FormControl>
            </FormModal>
            {!CheckRole("مدير") && (
              <FormModal title={"بمعرفه"} className="my-4">
                <div className="grid grid-cols-2 gap-4 mb-3">
                  <FormControl fullWidth>
                    <Controller
                      control={control}
                      name="knwoingly"
                      rules={{
                        required: "يجب اختيار بمعرفه من",
                      }}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Select
                          fullWidth
                          onChange={onChange}
                          onBlur={onBlur}
                          label={"بمعرفه من"}
                          placeholder="ادخل بمعرفه من"
                          OptionbackgroundColor="#414162"
                          options={adminData?.map((x) => ({
                            label: `${x?.firstName} ${x?.lastName}`,
                            value: x._id,
                          }))}
                          mandatory
                        />
                      )}
                    />

                    <CustErrorMessage name={"projectName"} errors={errors} />
                  </FormControl>
                </div>
              </FormModal>
            )}
            <FormModal title={"المرفقات"}>
              <CustomeInputFile
                register={{
                  ...register("attachments", {
                    validate: validateFiles,
                  }),
                }}
                name="attachments"
                multiple={true}
              />
              <CustErrorMessage name={"attachments"} errors={errors} />
            </FormModal>
            <div className="d-flex justify-content-center">
              <button
                type="submit"
                className="mt-4 sumbmitAddUpdateUser border-0"
              >
                حفظ
              </button>
            </div>
          </Form>
        </AddTechnicalSupportWrapper>
      )}
      <SuccessfullModal
        handleClose={() => {
          handleCloseSuccess();
          setAddTasksOpen(false);
          // navigate("/System/Accounating/index");
        }}
        message={"تمت الاضافة بنجاح"}
        show={showSuccess}
      />
      <SuccessfullModal
        handleClose={handleCloseError}
        message={err}
        show={showError}
        status="error"
      />
    </>
  );
};

export default TechnicalSupportAddTasks;
const AddTechnicalSupportWrapper = ({ children, show, onHide }) => {
  return (
    <Modal
      size="lg"
      show={show}
      onHide={onHide}
      aria-labelledby=" example-modal-sizes-title-lg"
      className="systemModal mettingModal bg-black/50"
      contentClassName="scroll p-0"
    >
      <Modal.Header className="w-full flex items-center justify-center">
        <div className="w-[57%] flex justify-between items-center  ">
          <p className="text-xl text-[#efaa20] text-center border !border-[#efaa20] p-2 rounded-md">
            طلب مساعده
          </p>
          <Image
            src="/Rejected.svg"
            alt="close modal button"
            className="pointer"
            onClick={onHide}
          />
        </div>
      </Modal.Header>
      {children}
    </Modal>
  );
};
