import { useQuery, useMutation, useQueryClient } from "react-query";
import {
  addCategory,
  deleteCategory,
  getAllCategories,
  getCategory,
  updateCategory,
} from "../../helper/fetchers/Categories";

export const useGetAllCategories = (params) => {
  const query = useQuery(["category", params], () => getAllCategories(params));

  return query;
};
export const useGetCategory = (id) => {
  const query = useQuery(["category", id], () => getCategory(id), {
    enabled: !!id,
  });

  return query;
};

export const useAddCategory = (onSuccess, onError) => {
  const queryClient = useQueryClient();
  return useMutation(addCategory, {
    onSuccess: () => {
      queryClient.invalidateQueries("category");
      onSuccess();
    },
    onError: (error) => {
      onError();
    },
  });
};
export const useUpdateCategory = (onSuccess, onError, id) => {
  const queryClient = useQueryClient();
  return useMutation((data) => updateCategory(id, data), {
    onSuccess: () => {
      queryClient.invalidateQueries("category");
      onSuccess();
    },
    onError: (error) => {
      onError();
    },
  });
};
export const useDeleteCategory = (onSuccess, onError) => {
  const queryClient = useQueryClient();
  return useMutation(deleteCategory, {
    onSuccess: () => {
      queryClient.invalidateQueries("category");
      onSuccess();
    },
    onError: (error) => {
      onError();
    },
  });
};
