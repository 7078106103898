import React, { useContext } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import style from "./AddExpensesReports.module.css";

import { useParams } from "react-router-dom";
import Input from "../../../FormHandler/Input";
import Select from "../../../FormHandler/Select";
import { useState } from "react";
import DatePicker from "react-datepicker";
import SaveButton from "../../../SaveButton";
import { addAccountType } from "../../../../Context/AddAccountaing";
import { UseInput, UseSelect } from "../../../../hooks";
import AddAttachment from "../../AddAttachment";
import { useForm, Controller } from "react-hook-form";
import { InputLabel } from "../../../../Pages/System/PlanModel/components/InputLabel";
import {
  CustomDatePicker,
  CustomInput,
  CustomeInputFile,
} from "../../../FormUi";
import { ErrorMessage } from "@hookform/error-message";
import { useGetAllClauses } from "../../../../hooks/fetchers/Clause";
import { FormModal } from "../../../../Pages/System/PlanModel/components/FormModal";
import Progress from "../../../Progress";
import useSuccessfullModal from "../../../Modals/hooks/useSuccessfullModal";
import SuccessfullModal from "../../../Modals/SuccessfullModal";
import { CustErrorMessage } from "../../../Errors/CustErrorMessage";
import moment from "moment";
import { useAddExpensesItemReport } from "../../../../hooks/fetchers/Expenses-items-reports";
import LoadingPage from "../../../feedback/LoadingPage";

const clauseOptions = [
  {
    label: "اساسيه",
    value: " اساسيه",
  },
  {
    label: "ايجارات",
    value: " ايجارات",
  },
];

const AddExpensesReports = () => {
  const { setOpenAddAccountant } = useContext(addAccountType);

  // define variabules

  const handleCloseModal = () => {
    setOpenAddAccountant(false);
  };
  return (
    <div className={`AddExpensesReports p-3`}>
      <p className="text-xl  my-3 golden">إضافة مصاريف جديدة</p>
      <AddExpensesReportsForm onClose={handleCloseModal} />
    </div>
  );
};
const validateFileLength = (value) => {
  if (value.length > 5) {
    return "أقصى عدد 5 ملفات";
  }
  return true;
};
const AddExpensesReportsForm = ({ onClose }) => {
  const { setOpenAddAccountant } = useContext(addAccountType);

  const {
    handleSubmit,
    control,
    register,
    formState: { errors },
  } = useForm();

  const { data: clauses } = useGetAllClauses();
  const {
    handleShowSuccess,
    handleShowError,
    handleCloseSuccess,
    handleCloseError,
    showSuccess,
    showError,
  } = useSuccessfullModal();

  const { mutate, isLoading } = useAddExpensesItemReport(
    handleShowSuccess,
    handleShowError
  );

  const onSubmit = async (data) => {
    const formData = new FormData();
    data.dueDate = moment(data?.dueDate).format("YYYY-MM-DD");
    data.clauseId = data?.clauseId?.value;

    console.log("submitted Data:", data);
    Object.keys(data).forEach((item) => {
      if (data[item]) {
        if (item !== "image") {
          formData.append(item, data[item]);
        }
      }
      // console.log([item, data[item]]);
    });
    for (const iterator of data.image) {
      formData.append("image", iterator);
    }
    mutate(formData);
  };
  return isLoading ? (
    <LoadingPage />
  ) : (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormModal title={"الوصف"} className={"mb-4 flex flex-col gap-4"}>
          <div className="row p-3">
            <div className="col-md-7 mb-3">
              <div className="flex-col flex">
                <InputLabel
                  size={16}
                  label={"اسم التقرير"}
                  className={"mb-1"}
                />
                <CustomInput
                  control={control}
                  name={"name"}
                  placeholder={"ادخل اسم التقرير"}
                  rules={{
                    required: "يجب ادخال اسم التقرير",
                    pattern: {
                      value: /^([A-Za-z ]+|[\u0600-\u06FF ]+)$/,
                      message: "نمط غير صحيح",
                    },
                    minLength: {
                      value: 5,
                      message: "يجب ان لا يقل علي 5 احرف",
                    },
                    maxLength: {
                      value: 500,
                      message: "يجب ان لا تزيد علي 500 حرف",
                    },
                  }}
                />
                <ErrorMessage
                  name="name"
                  errors={errors}
                  render={({ message }) => (
                    <span className="text-red-500">{message}</span>
                  )}
                />
              </div>
            </div>

            <div className="col-md-7 mb-4">
              <div className="flex flex-col w-full">
                <InputLabel label={"البند"} mandatory={true} />
                <Controller
                  control={control}
                  name="clauseId"
                  rules={{
                    required: "يجب إدخال البند",
                  }}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Select
                      fullWidth
                      onChange={onChange}
                      onBlur={onBlur}
                      OptionbackgroundColor="#414162"
                      options={clauses?.map((x) => ({
                        label: x?.name,
                        value: x?._id,
                      }))}
                    />
                  )}
                />
                <CustErrorMessage errors={errors} name={"clauseId"} />
              </div>
            </div>
            <div className="flex flex-col w-full mb-3">
              <InputLabel size={16} label={"الوصف"} className={"mb-1"} />
              <CustomInput
                control={control}
                name={"description"}
                placeholder="اكتب الوصف المرفق ..."
                multiline
                rows={5}
                rules={{
                  pattern: {
                    value: /^([A-Za-z ]+|[\u0600-\u06FF ]+)$/,
                    message: "نمط غير صحيح",
                  },
                  minLength: {
                    value: 5,
                    message: "يجب ان لا يقل علي 5 احرف",
                  },
                  maxLength: {
                    value: 5000,
                    message: "يجب ان لا تزيد علي 5000 حرف",
                  },
                }}
              />
              <ErrorMessage
                name="description"
                errors={errors}
                render={({ message }) => (
                  <span className="text-red-500">{message}</span>
                )}
              />
            </div>
            <div className="flex flex-col w-full md:w-1/2 gap-2 mb-2">
              <InputLabel
                size={16}
                label={"تاريخ الاستحقاق"}
                className={"mb-1"}
              />
              <CustomDatePicker
                control={control}
                name={"dueDate"}
                rules={{
                  required: "يجب اختيار التاريخ",
                }}
              />
              <ErrorMessage
                name="dueDate"
                errors={errors}
                render={({ message }) => (
                  <span className="text-red-500">{message}</span>
                )}
              />
            </div>
            <div className="flex flex-col w-full md:w-1/2 gap-2 mb-2">
              <InputLabel size={16} label={"المبلغ"} className={"mb-1"} />
              <CustomInput
                control={control}
                name={"amount"}
                placeholder={"ادخل المبلغ"}
                rules={{
                  required: "يجب ادخال المبلغ",
                  // pattern: {
                  //   value: /^([A-Za-z ]+|[\u0600-\u06FF ]+)$/,
                  //   message: "نمط غير صحيح",
                  // },
                  minLength: {
                    value: 2,
                    message: "يجب ان لا يقل علي 5 احرف",
                  },
                  maxLength: {
                    value: 500,
                    message: "يجب ان لا تزيد علي 500 حرف",
                  },
                }}
              />
              <ErrorMessage
                name="amount"
                errors={errors}
                render={({ message }) => (
                  <span className="text-red-500">{message}</span>
                )}
              />
            </div>
            {/* <div className="flex flex-col w-full md:w-1/2 gap-2 mb-2">
              <InputLabel
                size={16}
                label={"اجمالى المبلغ"}
                className={"mb-1"}
              />
              <CustomInput
                control={control}
                name={"TotalAmount"}
                placeholder={"ادخل اجمالى المبلغ"}
                rules={{
                  required: "يجب ادخال اجمالى المبلغ",
                  // pattern: {
                  //   value: /^([A-Za-z ]+|[\u0600-\u06FF ]+)$/,
                  //   message: "نمط غير صحيح",
                  // },
                  minLength: {
                    value: 2,
                    message: "يجب ان لا يقل علي 5 احرف",
                  },
                  maxLength: {
                    value: 500,
                    message: "يجب ان لا تزيد علي 500 حرف",
                  },
                }}
              />
              <ErrorMessage
                name="TotalAmount"
                errors={errors}
                render={({ message }) => (
                  <span className="text-red-500">{message}</span>
                )}
              />
            </div> */}

            <div className="flex flex-col w-full gap-2">
              <InputLabel
                size={18}
                label={"اجمالى المبلغ كتابة"}
                className={"mb-2"}
              />
              <CustomInput
                control={control}
                name={"textAmount"}
                placeholder="اجمالى المبلغ كتابة ..."
                multiline
                rows={2}
                rules={{
                  pattern: {
                    value: /^([A-Za-z ]+|[\u0600-\u06FF ]+)$/,
                    message: "نمط غير صحيح",
                  },
                  minLength: {
                    value: 5,
                    message: "يجب ان لا يقل علي 5 احرف",
                  },
                  maxLength: {
                    value: 5000,
                    message: "يجب ان لا تزيد علي 5000 حرف",
                  },
                }}
              />
              <ErrorMessage
                name="textAmount"
                errors={errors}
                render={({ message }) => (
                  <span className="text-red-500">{message}</span>
                )}
              />
            </div>
          </div>
        </FormModal>

        <FormModal title={"الملف المرفق"}>
          <CustomeInputFile
            register={{
              ...register("image", {
                validate: validateFileLength,
              }),
            }}
            name="image"
            multiple={true}
            accept={"image/*"}
          />
          <ErrorMessage
            name="image"
            errors={errors}
            render={({ message }) => (
              <span className="text-red-500">{message}</span>
            )}
          />
        </FormModal>

        <div className="d-flex my-5 w-90  justify-content-end">
          <Button
            // disabled={isLoading}
            type="submit"
            className="sumbmitAddUpdateUser"
          >
            حفظ
          </Button>
        </div>
      </form>
      <SuccessfullModal
        handleClose={() => {
          handleCloseSuccess();
          setOpenAddAccountant(false);
          // navigate("/System/Accounating/index");
        }}
        message={"تمت الاضافة بنجاح"}
        show={showSuccess}
      />
      <SuccessfullModal
        handleClose={handleCloseError}
        message={"حدث خطأ ما"}
        show={showError}
        status="error"
      />
    </>
  );
};

export default AddExpensesReports;
