import { MentionCard } from "./MentionCard";

export const MentionMenu = ({
  mentionSuggestions = [],
  handleSelectMention,
}) => {
  return (
    <div className="absolute top-full inset-x-0 reaction-mention-menu divide-y divide-[#383854] rounded-md p-2 max-h-44 overflow-y-auto">
      {mentionSuggestions.length > 0 ? (
        mentionSuggestions.map((user, index) => (
          <MentionCard
            key={index}
            handleSelectMention={handleSelectMention}
            mention={user}
          />
        ))
      ) : (
        <div className="py-2 mention-menu-card text-sm text-center">
          لا يوجد بيانات
        </div>
      )}
    </div>
  );
};
