import React from "react";

const ResultComponent = ({ result }) => {
  return (
    <div className="flex items-center w-full h-24 p-1 bg-mainItems text-mainText">
      <p className="text-[40px] m-1">{result}</p>
    </div>
  );
};

export default ResultComponent;
