import React, { useContext, useState } from "react";
import { SearchComponent } from "../../../../Components/SearchComponent/SearchComponent";
import { ColumnTitle } from "../../Settings/Orders/components/ColumnTitle";
import { OptionsButton } from "../../Settings/OptionsButton";
import {
  useDeleteTechnicalSupportTermsOrder,
  useGetAllTechnicalSupportTermsOrder,
} from "../../../../hooks/fetchers/TechnicalSupportPurcheseOrder";
import { TechnicalSupportContext } from "./TechnicalSupportProvider";
import SuccessfullModal from "../../../../Components/Modals/SuccessfullModal";
import useSuccessfullModal from "../../../../Components/Modals/hooks/useSuccessfullModal";
import RemoveModal from "../../Settings/RemoveModal";
import LoadingPage from "../../../../Components/feedback/LoadingPage";
import List from "../../../../Components/List";

const TechnicalSupportTermsOrderSearch = () => {
  const [search, setSearch] = useState("");

  const {
    activeCategory,
    setActiveCategory,
    setAddTermsOpen,
    setUpdatedItem,
    updatedItem,
  } = useContext(TechnicalSupportContext);
  const { data, isLoading } = useGetAllTechnicalSupportTermsOrder({
    search,
  });

  const handleShow = (item) => {
    setAddTermsOpen(true);
    setActiveCategory(item?._id);
    setUpdatedItem(item);
  };

  const {
    showSuccess,
    showError,
    handleShowSuccess,
    handleShowError,
    handleCloseSuccess,
    handleCloseError,
  } = useSuccessfullModal();

  const [showDelete, setShowDelete] = useState(false);
  const { mutate: deleteMutation, isLoading: isDeleteLoading } =
    useDeleteTechnicalSupportTermsOrder(handleShowSuccess, handleShowError);

  const handleDelete = (item) => {
    setActiveCategory(item?._id);
    setUpdatedItem(item);
    setShowDelete(true);
  };
  const [err, setError] = useState("");

  return isDeleteLoading ? (
    <LoadingPage />
  ) : (
    <div className="bg-mainItems h-full rounded-xl w-[320px] p-2 ">
      <SearchComponent
        value={search}
        handleChange={(val) => {
          setSearch(val);
        }}
        border="border !border-[#E4A11B]"
      />
      <ColumnTitle title="انواع الطلبات" />
      <List
        isLoading={isLoading}
        withAvatar={false}
        data={data}
        renderItem={(item) => (
          <div
            className={`flex w-full justify-between items-center px-2 text-[#ffffff80] border hover:!border-[#EFAA20] text-base ${
              activeCategory === item?._id
                ? "!border-[#EFAA20]"
                : "!border-transparent"
            }`}
            key={item?._id}
          >
            <button
              onClick={() => {
                setActiveCategory(item?._id);
              }}
              className="w-full text-mainText text-right py-3"
            >
              {item?.name}
            </button>

            <OptionsButton
              id={item?._id}
              onUpdate={() => handleShow(item)}
              onDelete={() => handleDelete(item)}
            />
          </div>
        )}
      />

      <RemoveModal
        title={"التاكيد"}
        show={showDelete}
        handleClose={() => setShowDelete(false)}
        onSave={() => {
          deleteMutation(updatedItem?._id, {
            onError: (err) => {
              setError(
                err?.response?.data?.validationErrors ||
                  err?.response?.data?.message
              );
            },
          });
        }}
        stillOpen={true}
      />
      <SuccessfullModal
        handleClose={() => {
          setShowDelete(false);
          handleCloseSuccess();
        }}
        message={"تمت الحذف بنجاح"}
        show={showSuccess}
      />
      <SuccessfullModal
        handleClose={handleCloseError}
        message={err}
        show={showError}
        status="error"
      />
    </div>
  );
};

export default TechnicalSupportTermsOrderSearch;
