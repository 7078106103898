import React, { useCallback, useEffect, useState } from "react";
import { Button, Image, Modal } from "react-bootstrap";
import { SearchComponent } from "../../../SearchComponent/SearchComponent";
import { Checkbox } from "@mui/material";
import "./ChooseDeprtmnt.css";
import { getAllUsers } from "../../../../helper/fetchers/Users";
import { myAxios } from "../../../../helper/https";
import { useQuery } from "react-query";

const getDropdownUsers = () =>
  myAxios.get("user/select/events").then(({ data }) => data?.allUsers);
const useGetDropdownUsers = () => {
  return useQuery(["user", "dropdown"], getDropdownUsers);
};

const ChooseDepartmentComponent = ({
  show,
  onHide,
  selectedUsers,
  setSelectedUsers,
}) => {
  // const [allUser, setAllUser] = useState([]);
  const [search, setSearch] = useState("");

  // const getUsers = useCallback(async () => {
  //   const res = await getAllUsers();
  //   if (res?.allUsers) {
  //     setAllUser(res?.allUsers);
  //   }
  // }, []);

  const { data: allUser } = useGetDropdownUsers();

  const filteredUsers =
    allUser &&
    allUser?.filter(
      (user) =>
        user?.firstName?.toLowerCase().includes(search.toLowerCase()) ||
        user?.lastName?.toLowerCase().includes(search.toLowerCase()) ||
        user?.email?.toLowerCase().includes(search.toLowerCase())
    );

  const handleCheckboxChange = (selected) => {
    const updatedSelectedUsers = [...selectedUsers];
    const index = updatedSelectedUsers.findIndex((user) => user._id === selected._id);

    if (index !== -1) {
      // If the user is already selected, remove it
      updatedSelectedUsers.splice(index, 1);
    } else {
      // If the user is not selected, add it
      updatedSelectedUsers.push(selected);
    }

    setSelectedUsers(updatedSelectedUsers);
  };
  return (
    <div>
      <Modal
        size="md"
        show={show}
        onHide={onHide}
        aria-labelledby=" example-modal-sizes-title-lg"
        className=" chooseDepartment   "
      >
        <div className="row  mx-2">
          <div className="col-md-12">
            <div className="item">
              <div className="item ">
                <p className="my-3 text-xl text-mainText">المستخدمين</p>
                <div className="ChooseDeprtmant-according d-flex justify-content-start  align-items-center flex-column custom-scrollbar min-w-[350px] ">
                  <SearchComponent
                    className="m-auto w-[95%] focus-visible:border-[#E4A11B]"
                    background={"var(--main-navy)"}
                    border="border !border-[#E4A11B] focus-visible:border-[#E4A11B]"
                    value={search}
                    handleChange={setSearch}
                  />
                  {filteredUsers?.length > 0 ? (
                    filteredUsers.map((ele, index) => (
                      <div
                        key={index}
                        className="d-flex gap-3  show-meeting-Detials my-3 px-4  w-full items-center justify-between"
                      >
                        <div className="relative flex flex-row gap-2">
                          <Image
                            src={ele?.image}
                            alt="user img"
                            className="object-cover w-[40px] h-[40px] rounded-[40.5px]"
                          />
                          {ele.status === 0 ? (
                            <div className="not-connected absolute bottom-2 right-1" />
                          ) : (
                            <div className="connected absolute bottom-2 right-1" />
                          )}
                          <div className="w-fit">
                            <p className="text-mainText mx-2">
                              {ele?.firstName + " " + ele?.lastName}
                            </p>
                            <p className="text-mainText text-sm">
                              {ele?.email}
                            </p>
                          </div>
                        </div>
                        <div className="d-flex gap-2 ">
                          <Checkbox
                            checked={
                              selectedUsers &&
                              selectedUsers.some((user) => user._id === ele.id)
                            }
                            sx={{
                              color: "#E4A11B",
                              "&.Mui-checked": {
                                color: "#E4A11B",
                              },
                            }}
                            onChange={() => handleCheckboxChange(ele)}
                          />
                        </div>
                      </div>
                    ))
                  ) : (
                    <p className="text-center text-lg text-mainText py-1">
                      {"لا يوجد بيانات لعرضها"}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className=" d-flex justify-content-center">
          <Button onClick={onHide} className="sumbmitAddUpdateUser">
            حفظ
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default ChooseDepartmentComponent;
