import React, { useState } from "react";
import { Grid, Box } from "@mui/material";
import { Column } from "../components/Column";
import { ColumnTitle } from "../components/ColumnTitle";
import { CategoriesList } from "../components/CategoriesList";
import { useGetAllCategories } from "../../../../../hooks/fetchers/Categories";
import { CategoryProvider } from "../context/CategoryContext";
import { SubCategoriesDesign } from "../components/SubCategoriesDesign";
import Progress from "../../../../../Components/Progress";
import { SearchComponent } from "../../../../../Components/SearchComponent/SearchComponent";
function OrdersPage() {
  const [search, setSearch] = useState("");
  const {
    data: categories,
    isLoading,
    isSuccess,
  } = useGetAllCategories({ search: search });
  return (
    <CategoryProvider>
      <Grid item xs={4} lg={3} className="!h-full">
        <Column>
          <Box className="px-3">
            <SearchComponent
              value={search}
              handleChange={(val) => {
                setSearch(val);
              }}
              border="border !border-[#E4A11B]"
            />
          </Box>
          <ColumnTitle title={"كل استخدمات المشروع"} />
          {isLoading ? <Progress /> : null}

          {!isLoading && isSuccess ? (
            <CategoriesList categories={categories} />
          ) : null}
        </Column>
      </Grid>
      <Grid item xs={8} lg={6}>
        <Column>
          <SubCategoriesDesign />
        </Column>
      </Grid>
    </CategoryProvider>
  );
}

export default OrdersPage;
