import Button from "react-bootstrap/Button";
import { FormControl, TextField } from "@mui/material";
import Modal from "react-bootstrap/Modal";
import { InputLabel } from "../../Pages/System/PlanModel/components/InputLabel";
import { useForm } from "react-hook-form";
import { FormModal } from "../../Pages/System/PlanModel/components/FormModal";
import { CustomDatePicker, CustomInput, CustomTimePicker } from "../FormUi";
import moment from "moment";

function ResendModel({
  message,
  show,
  handleClose,
  handleSave,
  loading,
  name,
  setComment,
}) {
  const { register, handleSubmit ,control} = useForm();
  const manipulateData = (data) => {
    data.startTime = moment(data.startTime).format("hh:mm a")
    data.endTime = moment(data.endTime).format("hh:mm a")
    data.startDate = moment(data.startDate).format("YYYY-MM-DD")
    data.endDate = moment(data.endDate).format("YYYY-MM-DD")
    return data
  } 
  const onSubmit = (data) => {
    data = manipulateData(data)
    console.log("submitted data",data);
    
    handleSave(data);
  };
  return (
    <>
      {show && (
        <div
          className="fixed top-0 left-0 w-full h-full bg-black opacity-30 z-[892] "
          onClick={() => handleClose(false)}
        />
      )}
      <Modal
        centered
        contentClassName="bg-mainItems border p-2 !max-w-[700px] !border-[#EFAA20] !rounded-[20.27px] text-mainText"
        show={show}
        onHide={handleClose}
        size="lg"
      >
        <Modal.Header className="w-full border-0">
            <p className="w-full text-end text-lg font-medium">اعاده الارسال</p>
        </Modal.Header>
        <form onSubmit={handleSubmit(onSubmit)} className="w-full">
          <Modal.Body>
            <FormModal>
             <div className="grid grid-cols-2 gap-3">
             <FormControl fullWidth>
             <InputLabel label={"تاريخ التسليم"} size={18} className="mb-3" />
             <CustomDatePicker name={"endDate"} control={control}/>
             </FormControl>
             <FormControl fullWidth>
             <InputLabel label={"وقت التسليم "} size={18} className="mb-3" />
             <CustomTimePicker name={"endTime"} control={control}/>
             </FormControl>
             <FormControl fullWidth>
             <InputLabel label={"تاريخ الاستلام الجديد "} size={18} className="mb-3" />
             <CustomDatePicker name={"startDate"} control={control}/>
             </FormControl>
             <FormControl fullWidth>
             <InputLabel label={"وقت الاستلام الجديد"} size={18} className="mb-3" />
             <CustomTimePicker name={"startTime"} control={control}/>
             </FormControl>
              
            <FormControl  className="col-span-2">
              <InputLabel label={"اذكر سبب اعاده الارسال"} size={18} className="mb-3" />
              <CustomInput
              control={control}
              name={"resendReason"}
              multiline
              rows={4}
              placeholder="اكتب الاسباب :"
              />
              
            </FormControl>
              </div> 

            </FormModal>

          </Modal.Body>
          <Modal.Footer className="border-none">
            <Button
              className="mx-auto py-1 px-14 font-semibold text-[13px] text-white bg-[#EFAA20] hover:bg-[#EFAA20]"
              type="submit"
            >
              حفظ
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
}

export default ResendModel;
