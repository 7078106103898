import { toast } from "react-toastify";
import myAxiosInstance, { myAxiosJson } from "../https";

export const getAllReceptions = (params) => myAxiosInstance("reception",{params}).catch(error=>{
  toast.error(error?.response?.data?.message)
});
export const getReception = (receptionId) => myAxiosInstance(`reception/${receptionId}`).catch(error=>{
  toast.error(error?.response?.data?.message)
});
export const addReception = (data) => myAxiosInstance.post("reception", data);
export const updateReception = (receptionId, data) =>
  myAxiosInstance.patch(`reception/${receptionId}`, data);
export const deleteReception = (data) =>
  myAxiosJson.delete(`reception`,{ data });
