import { toast } from "react-toastify";
import { myAxiosJson } from "../https";

export const fetcher = () =>
  myAxiosJson("notification").then((data) => data?.data).catch(error=>{
    toast.error(error?.response?.data?.message)
});
export const readNotification = (id) => myAxiosJson.patch(`notification/${id}`);

export const readMessage = (id) => myAxiosJson.patch(`chat/${id}`);
