import React, { Fragment, useEffect, useRef, useState } from "react";
import { IoIosArrowDown } from "react-icons/io";
import Image from "../Image";
import { useNavigate } from "react-router-dom";
export default function CustomTableMenu({
  items,
  maxItems,
  renderItem = null,
}) {
  console.log("items", items);

  const [open, setOpen] = useState(false);
  const menuRef = useRef(null);
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setOpen(false);
      }
    };
    if (open) {
      document.addEventListener("click", handleOutsideClick);
    }
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [open]);
  const handleOpen = () => setOpen(!open);
  const displayedItems = items?.filter(
    (item, index) => index < maxItems && <div key={index}>{item.value}</div>
  );

  return (
    <div
      style={{
        display: "flex",
        flexDirection: items.length > maxItems ? "row" : "column",
        alignItems: items.length > maxItems ? "center" : "",
      }}
      className="relative  gap-1"
      ref={menuRef}
    >
      {displayedItems.map((item, index) => (
        <div key={index}>{item.value}</div>
      ))}
      {items.length > maxItems ? (
        <Fragment>
          <button onClick={handleOpen}>
            <IoIosArrowDown />
          </button>
          {open ? (
            <div className="absolute z-50 w-max top-full left-0 flex flex-col gap-1.5 p-2 bg-white text-black rounded shadow max-h-[80px] overflow-auto">
              {!renderItem
                ? items.map((item, index) => (
                    <UserCard item={item} key={index} />
                  ))
                : renderItem}
            </div>
          ) : null}
        </Fragment>
      ) : null}
    </div>
  );
}

const UserCard = ({ item }) => {
  const navigate = useNavigate();
  const handleClick = () => {
    if (item?.path) {
      navigate(item?.path);
    }
  };
  return (
    <div
      className="flex items-center justify-between gap-4"
      role="button"
      onClick={handleClick}
    >
      <Image
        src={item?.profileImage}
        alt="userImage"
        className="w-8 h-8 object-contain"
      />
      <div className="flex justify-between gap-2 text-xs font-semibold">
        <p>{item.title}</p>
        <p>{item.value}</p>
      </div>
    </div>
  );
};
