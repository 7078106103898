import { toast } from "react-toastify";
import myAxiosInstance from "../https";

export const getAllBatches = () =>
  myAxiosInstance("batches")
    .then((data) => data.data.batches)
    .catch(error=>{
      toast.error(error?.response?.data?.message)
  })
export const addBatche = (data) => myAxiosInstance.post("batches", data);

export const updateBatche = (batcheId, data) =>
  myAxiosInstance.patch(`batches/${batcheId}`, data);

export const deleteBatche = (batcheId) =>
  myAxiosInstance.delete(`batches/${batcheId}`);
